import { request } from '.';
import { EconomicValue, ProductStage, ProductType } from './productV3';
import { Amount, Entity } from './shared';

export interface ProductImportValue<T> {
  extracted?: string;
  mapped?: T;
}

export interface ProductImportPayload {
  stage: ProductImportValue<ProductStage>;
  name: ProductImportValue<string>;
  skuId: ProductImportValue<string>;
  gtin: ProductImportValue<string>;
  amount: ProductImportValue<Amount>;
  foodType: ProductImportValue<Entity>;
  category: ProductImportValue<Entity>;
  conservation: ProductImportValue<Entity>;
  economicValue: ProductImportValue<EconomicValue>;
  productionLocation: ProductImportValue<Entity>;
  consumptionLocation: ProductImportValue<Entity>;
  warehouseLocation: ProductImportValue<Entity>;
  storeLocation: ProductImportValue<Entity>;
  servings: ProductImportValue<number>;
  ingredients: {
    ingredient: ProductImportValue<Entity>;
    amount: ProductImportValue<Amount>;
    locations: ProductImportValue<Entity[]>;
  }[];
  packagings: {
    packaging: ProductImportValue<Entity>;
    amount: ProductImportValue<Amount>;
    locations: ProductImportValue<Entity[]>;
  }[];
}

export enum ExtractionStatus {
  Pending = 'pending',
  Summarising = 'summarising',
  Extracting = 'extracting',
  Refining = 'refining',
  Complete = 'complete',
  Failed = 'failed',
}

export interface ExtractionStep {
  status: ExtractionStatus;
  message: string;
}

export interface ExtractionResult {
  sid: string;
  status: ExtractionStatus;
  summary?: string;
  product?: ProductImportPayload;
}

export const scheduleSpecificationExtraction = (payload: { productType: ProductType; file?: File; ingredients?: string }) => {
  const formData = new FormData();
  formData.append('type', payload.productType);

  if (payload.file) {
    formData.append('spec', payload.file);
  }

  if (payload.ingredients) {
    formData.append('ingredients', payload.ingredients);
  }

  return request<{ sid: string; status: ExtractionStatus; steps: ExtractionStep[]; errorCode?: string; message?: string }>(
    'POST',
    '/v3/spec-process',
    { formData },
  );
};

export const pollSpecificationExtraction = (sid: string) => request<ExtractionResult>('GET', `/v3/spec-process/${sid}`);

export const submitSpecification = (payload: ProductImportPayload) =>
  request<{ id: string; errorCode?: 'sku-id-exists'; message?: string }>('POST', '/v3/spec-import', { body: payload });
