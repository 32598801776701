import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { Fragment, ReactNode, RefObject, SetStateAction, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  BaselinePefReport,
  DashboardStatusProduct,
  HistoricalPefReport,
  PortfolioJourney,
  PortfolioState,
  ProductState,
  createProductModelV3,
  getPefBaseline,
  getPefHistoricalReport,
  resetBaseline,
} from '../../../api';
import { LimitTooltip } from '../../../components/LimitTooltip';
import { ModalV3 } from '../../../components/ModalV3';
import { createProduct } from '../Products/Details/dataModel';
import { simplify, roundToLong, roundToShort } from '../shared';
import { useProfile } from '../../../hooks/useProfile';
import { Buttons } from './Buttons';
import { ListOfProducts } from './ListOfProducts';
import { SkeletonLoader } from './SkeletonLoader';
import { AppRoutes } from '../index';

interface Props {
  data: Partial<PortfolioJourney>;
  targetWidgetRef: RefObject<HTMLDivElement>;
  baselineReport?: BaselinePefReport;
  setBaselineReport: (v: SetStateAction<BaselinePefReport | undefined>) => void;
  latestHistoricalReport?: HistoricalPefReport;
  setLatestHistoricalReport: (v: SetStateAction<HistoricalPefReport | undefined>) => void;
  selectedStep: string;
  setSelectedStep: (v: SetStateAction<string | undefined>) => void;
}

export const ReductionFlow = (props: Props) => {
  const [productCreateLimit, setProductCreateLimit] = useState(false);
  const [productCreating, setProductCreating] = useState(false);
  const [creatingModel, setCreatingModel] = useState(false);
  const [modelsCreateLimit, setModelsCreateLimit] = useState(false);
  const navigate = useNavigate();
  const profile = useProfile();

  const createNewProduct = () => {
    setProductCreating(true);
    createProduct().call({
      ok: ({ id, errorCode }) => {
        setProductCreating(false);
        if (errorCode) {
          setProductCreateLimit(true);
        } else {
          navigate(`/products/${id}`);
        }
      },
    });
  };

  const wrapper = (subcategory: string, title: string, body: ReactNode) => {
    return (
      <div className='flex flex-col gap-3 my-3 text-[92%]'>
        <div className='text-sm font-semibold uppercase'>{subcategory}</div>
        <div className='text-2xl'>{title}</div>
        {body}
      </div>
    );
  };

  return (
    <>
      {(() => {
        switch (props.selectedStep) {
          case `1.1`:
            return (
              props.data.completion &&
              (() => {
                const { complete, drafts, missing, target } = props.data.completion;

                return wrapper(
                  `Capture all products`,
                  `${complete}/${target} products complete`,
                  <Fragment>
                    <ul className='list-disc ml-5'>
                      <li>
                        Focus on entering the minimum amount of information required first{' '}
                        <span className='font-semibold'>(don’t focus on primary data just yet)</span>. This will help refine what data you
                        should get first.
                      </li>
                      <li>
                        Don’t hesitate to <span className='font-semibold'>apply our defaults</span> if you don’t have all the data available
                        yet.
                      </li>
                      <li>
                        If there are any complex ingredients you reuse across products, try creating{' '}
                        <span className='font-semibold'>internal products</span>. If anything changes in the way they are made, you’ll only
                        have to edit them once and the products using them as raw materials will get updated!
                      </li>
                    </ul>
                    {(() => {
                      if (props.data.completion) {
                        return (
                          <>
                            <div className='flex overflow-hidden h-5 w-80 rounded-full border shadow-sm'>
                              <div
                                title={String(complete)}
                                className='flex items-center justify-center text-sm font-semibold bg-[#9C75FA]'
                                style={{
                                  width: `${(complete / target) * 100}%`,
                                }}
                              />
                              <div
                                title={String(drafts)}
                                className='flex items-center justify-center text-sm font-semibold bg-[#DACEFD]'
                                style={{
                                  width: `${(drafts / target) * 100}%`,
                                }}
                              />
                              <div
                                title={String(missing)}
                                className='flex items-center justify-center text-sm font-semibold bg-[#F5F5F5]'
                                style={{
                                  width: `${(missing / target) * 100}%`,
                                }}
                              />
                            </div>
                            <div className='flex gap-6 w-full'>
                              <div className='flex items-center gap-2'>
                                <div className='h-2 aspect-square bg-[#9C75FA] rounded-full' />
                                Complete products ({complete})
                              </div>
                              <div className='flex items-center gap-2'>
                                <div className='h-2 aspect-square bg-[#DACEFD] rounded-full' />
                                Drafts ({drafts})
                              </div>
                            </div>

                            <LimitTooltip
                              placement='top-start'
                              enabled={productCreateLimit}
                              entityName='products limit'
                              valueKey='maxProductSkuCount'
                              onDismiss={() => setProductCreateLimit(false)}
                            >
                              <div>
                                <Buttons
                                  primary={{
                                    label: 'Create new product',
                                    waiting: productCreating,
                                    disabled: !profile.selectedWorkspace.permissions.productManagement,
                                    readOnlyWarning: !profile.selectedWorkspace.permissions.productManagement,
                                    onClick: createNewProduct,
                                  }}
                                  secondary={{
                                    label: 'Go to drafts',
                                    onClick: () => navigate(`/products?state=${ProductState.Draft}`),
                                  }}
                                />
                              </div>
                            </LimitTooltip>
                          </>
                        );
                      }
                    })()}
                  </Fragment>,
                );
              })()
            );

          case '1.2':
            return (
              props.data.firstParty &&
              (() => {
                return wrapper(
                  'Remove all defaults',
                  `You have ${parseFloat((100 - props.data.firstParty.averageFirstParty).toFixed(2))}% defaults across all products`,
                  <Fragment>
                    <div>
                      Defaults were created to speed up the product creation process but product-specific data is required before we’d
                      recommend using our results to make any major decision. Focus on those products with the most defaults overall.
                    </div>
                    <div className='flex flex-col gap-4 self-start border p-4 mt-3 rounded-lg'>
                      <div className='font-semibold'>Missing data by product</div>
                      <div className='flex flex-col gap-1'>
                        {props.data.firstParty?.products.slice(0, 3).map((item, i) => (
                          <NavLink
                            to={`/products/${item.id}/report/production/overview`}
                            target='_blank'
                            title={item.name}
                            className={cn(
                              'group grid grid-cols-[1fr_60px] items-center justify-between gap-x-6',
                              'rounded-lg bg-slate-50 hover:bg-brand hover:text-white px-2 py-1.5 box-border border',
                            )}
                            key={i}
                          >
                            <div className='w-40 truncate text-zinc-500 group-hover:text-white'>{item.name}</div>
                            <div className='flex gap-4 items-center'>
                              <div>{parseFloat((100 - item.firstParty).toFixed(2))}%</div>
                              <FontAwesomeIcon className='invisible group-hover:visible' icon={light('arrow-right')} />
                            </div>
                          </NavLink>
                        ))}
                      </div>
                      <Buttons
                        secondary={{
                          label: 'Product list',
                          onClick: () => navigate('/products?sortBy=firstPartyDataPercentage&sortAscending=true'),
                        }}
                      />
                    </div>
                  </Fragment>,
                );
              })()
            );

          case '1.3':
            return props.data.baselineImpact
              ? wrapper(
                  'Identify highest impact products',
                  `Total baseline impact: ${simplify(props.data.baselineImpact.impactPoints)} impact points`,
                  <Fragment>
                    <div className='text-base'>
                      {`Timeframe: between
                  ${format(new Date(props.data.baselineImpact.startDate), 'dd/MM/yy')}
                  and
                  ${format(new Date(props.data.baselineImpact.endDate), 'dd/MM/yy')}`}
                    </div>
                    <div>
                      Your baseline is your journey’s starting point, or the overall impact of your manufacturing over this given timeframe.
                      This will be the starting point for all progress tracking going forward in the Sustained platform and it should be
                      what you refer to when setting your targets.
                    </div>
                    <div className='flex gap-4'>
                      <Buttons
                        primary={{
                          label: 'Detailed report',
                          onClick: () => navigate('/manufacturing/baseline/overview'),
                        }}
                      />
                      <ModalV3
                        size='narrow-'
                        title='Are you sure you want to reset your baseline?'
                        body={
                          <div className='flex self-center text-center -mt-6'>
                            <span>
                              This will reset it for your <span className='text-brand font-semibold'>entire workspace</span> and all
                              progress tracking against this baseline will be lost
                            </span>
                          </div>
                        }
                        confirmLabel='Reset'
                        onConfirm={() =>
                          resetBaseline().call({
                            ok: () => navigate('/manufacturing/baseline'),
                          })
                        }
                      >
                        <button className='mt-3 rounded-lg px-3 py-1 font-semibold border border-[#4a2e82] text-[#4a2e82]'>
                          Reset baseline
                        </button>
                      </ModalV3>
                    </div>
                  </Fragment>,
                )
              : wrapper(
                  'Identify highest impact products',
                  'You haven’t set a baseline yet',
                  <Fragment>
                    <div>
                      Your baseline is your starting point, to understand what your impact today is at scale given how many units you’ve
                      sold of your various products. The worst impact product won’t necessarily be the one contributing the most to your
                      overall impact when taking into account volumes. It will help you identify where to focus your reduction efforts.
                    </div>
                    <div>Before you start, make sure you have the following data at hand:</div>
                    <ul className='list-disc ml-5 font-semibold'>
                      <li>List of all products sold over a one-year period (reference year)</li>
                      <li>Sold volume data for each of the above products for that same year</li>
                    </ul>
                    <Buttons
                      primary={{
                        label: 'Set baseline',
                        onClick: () => navigate('/manufacturing/baseline/overview'),
                      }}
                    />
                  </Fragment>,
                );

          case '2.1':
            return props.data.targets && props.data.targets.totalTargets > 0
              ? wrapper(
                  'Set targets',
                  `${props.data.targets.totalTargets} target${props.data.targets.totalTargets === 1 ? '' : 's'} set`,
                  <Fragment>
                    <div>
                      Targets are a useful means to ensure you all work towards a common goal. You’ll be able to visualise your progress
                      against those whenever new historical data is uploaded and in your progress tracking chart in the section above.
                      Indications as to the likelihood of you meeting those targets will also be provided looking at the trends in your
                      data.
                    </div>
                    <Buttons
                      primary={{
                        label: 'Set new targets',
                        onClick: () => navigate('/manufacturing/targets'),
                      }}
                      secondary={{
                        label: 'View targets',
                        onClick: () => props.targetWidgetRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' }),
                      }}
                    />
                  </Fragment>,
                )
              : wrapper(
                  'Set targets',
                  'You haven’t set targets yet',
                  <Fragment>
                    <div>
                      Any target you set will help you track your progress against a specific goal, whether it’s a target for your overall
                      environmental impact, or any underlying impact categories such as climate change or water deprivation. This should be
                      a reduction target from your baseline impact by a certain year, or a year-on-year reduction goal.{' '}
                    </div>
                    <Buttons
                      primary={{
                        label: 'Set new targets',
                        onClick: () => navigate('/manufacturing/targets'),
                      }}
                      secondary={{
                        label: 'Skip this step',
                        onClick: () => props.setSelectedStep('2.2'),
                      }}
                    />
                  </Fragment>,
                );

          case '2.2':
            const productTiles = (products: DashboardStatusProduct[]) => (
              <>
                <LimitTooltip
                  placement='top-start'
                  enabled={modelsCreateLimit}
                  entityName='models limit'
                  valueKey='maxProductModelCount'
                  onDismiss={() => setModelsCreateLimit(false)}
                >
                  <div />
                </LimitTooltip>
                <div className='flex gap-3'>
                  {products.slice(0, 3).map((product) => (
                    <div key={product.id} className='border shadow-sm rounded-xl p-4 flex flex-col gap-6 w-56'>
                      <div className='font-semibold uppercase truncate'>{product.name}</div>
                      <div className='flex flex-col gap-1'>
                        <div className='flex gap-1.5 items-center'>
                          <div className='text-xl text-red-500 font-semibold'>{simplify(product.impactPoints)}</div>
                          <div className='text-xs uppercase text-zinc-500 mt-0.5 truncate'>impact points</div>
                        </div>
                        <div className='text-xl'>{roundToShort(product.contribution)}% of total</div>
                      </div>
                      <button
                        disabled={creatingModel}
                        onClick={() => {
                          setCreatingModel(true);
                          createProductModelV3(product.id).call({
                            ok: ({ model, errorCode }) => {
                              setCreatingModel(false);
                              if (errorCode) {
                                setModelsCreateLimit(true);
                              } else {
                                navigate(`/products/${product.id}/models/${model.id}`);
                              }
                            },
                          });
                        }}
                        className='flex self-start px-3 py-1.5 bg-slate-200 font-semibold text-[#4a2e82] rounded-lg shadow-sm'
                      >
                        Start modelling
                      </button>
                    </div>
                  ))}
                </div>
              </>
            );

            return (() => {
              if (props.data.state === PortfolioState.BaselineSet && props.data.baselineImpact) {
                return wrapper(
                  'Identify reduction opportunities',
                  'Improve your highest impact products',
                  <Fragment>
                    <div className='text-base leading-tight text-dark'>
                      {`Baseline: ${format(new Date(props.data.baselineImpact.startDate), 'dd/MM/yy')} to ${format(
                        new Date(props.data.baselineImpact.endDate),
                        'dd/MM/yy',
                      )}`}
                    </div>
                    <div>
                      For those highest impact products identified in your baseline, try our modelling tool to see if you can identify
                      impact reduction opportunities in the way the products are made. Be aware that considering making changes to how a
                      product is made without enough primary data holds a lot of uncertainty. This might be a good time to continue your
                      primary data gathering efforts in parallel!
                    </div>
                    <div>{productTiles(props.data.baselineImpact?.products)}</div>
                    <div className='flex gap-4'>
                      <ModalV3
                        size='wide+'
                        hideConfirm
                        onOpenChange={(isOpen) => {
                          if (isOpen) {
                            getPefBaseline().ok((data) => {
                              props.setBaselineReport && props.setBaselineReport(data);
                            });
                          }
                        }}
                        title={
                          <div className='flex flex-col gap-3'>
                            <div>Product contribution to the overall baseline impact</div>
                            {props.baselineReport && (
                              <div className='text-base font-normal'>
                                <div className='text-base'>
                                  {`Timeframe: between ${format(new Date(props.baselineReport.startDate), 'dd/MM/yy')} and ${format(
                                    new Date(props.baselineReport.endDate),
                                    'dd/MM/yy',
                                  )}`}
                                </div>
                                <div title={roundToLong(props.baselineReport.impactPoints)}>
                                  Total impact:{' '}
                                  <span className='font-semibold'>{simplify(props.baselineReport.impactPoints)} impact points</span>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        body={
                          props.baselineReport ? (
                            <ListOfProducts showModellingButton showReportButton={false} products={props.baselineReport.products} />
                          ) : (
                            <SkeletonLoader />
                          )
                        }
                      >
                        <button className='mt-3 rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'>
                          See more
                        </button>
                      </ModalV3>
                      <Buttons
                        secondary={{
                          label: 'Products list',
                          onClick: () => navigate('/products'),
                        }}
                      />
                    </div>
                  </Fragment>,
                );
              }

              if (props.data.state === PortfolioState.HistoricalCreated && props.data.historicalImpact) {
                return wrapper(
                  'Identify reduction opportunities',
                  'Improve your highest impact products',
                  <Fragment>
                    <div className='text-base leading-tight text-dark'>
                      {`Last data point: ${format(new Date(props.data.historicalImpact.startDate), 'dd/MM/yy')} to ${format(
                        new Date(props.data.historicalImpact.endDate),
                        'dd/MM/yy',
                      )}`}
                    </div>
                    <div>
                      Time to focus on your current highest impact products, as determined by your latest historical data report. Try
                      modelling changes to the products below that would result in meaningful impact reductions. And don’t forget that
                      considering making changes to how a product is made without enough primary data holds a lot of uncertainty. This might
                      be a good time to continue your primary data gathering efforts in parallel!
                    </div>
                    <div>{productTiles(props.data.historicalImpact?.products)}</div>
                    <div className='flex gap-4'>
                      <ModalV3
                        size='wide+'
                        hideConfirm
                        onOpenChange={(isOpen) => {
                          const id = props.data.historicalImpact?.latestReportId;
                          if (isOpen && id) {
                            getPefHistoricalReport(id).ok((data) => {
                              props.setLatestHistoricalReport && props.setLatestHistoricalReport(data);
                            });
                          }
                        }}
                        title={
                          <div className='flex flex-col gap-3'>
                            <div>Product contribution to the latest entry</div>
                            {props.latestHistoricalReport && (
                              <div className='text-base font-normal'>
                                <div className='text-base'>
                                  {`Timeframe: between ${format(new Date(props.latestHistoricalReport.startDate), 'dd/MM/yy')} and ${format(
                                    new Date(props.latestHistoricalReport.endDate),
                                    'dd/MM/yy',
                                  )}`}
                                </div>
                                <div title={roundToLong(props.latestHistoricalReport.impactPoints)}>
                                  Total impact:{' '}
                                  <span className='font-semibold'>{simplify(props.latestHistoricalReport.impactPoints)} impact points</span>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        body={
                          props.latestHistoricalReport ? (
                            <ListOfProducts showModellingButton showReportButton={false} products={props.latestHistoricalReport.products} />
                          ) : (
                            <SkeletonLoader />
                          )
                        }
                      >
                        <button className='mt-3 rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'>
                          See more
                        </button>
                      </ModalV3>
                      <Buttons
                        secondary={{
                          label: 'Products list',
                          onClick: () => navigate('/products'),
                        }}
                      />
                    </div>
                  </Fragment>,
                );
              }

              if (props.data.state === PortfolioState.Empty || props.data.state === PortfolioState.BaselineNotSet) {
                return wrapper(
                  'Identify reduction opportunities',
                  'Complete your initial impact assessment',
                  <Fragment>
                    <div>
                      To identify impact reduction opportunities in your entire product range, you need to complete your initial impact
                      assessment and set a baseline. Once we’ve identified the highest impact products, you can try our modelling tool to
                      see if you can identify impact reduction opportunities in the way the products are made.
                    </div>
                    <div className='flex gap-4'>
                      {['#1 Most impactful product', '#2 Most impactful product', '#3 Most impactful product'].map((item, i) => (
                        <div key={i} className='p-4 w-56 aspect-square border rounded-xl shadow-sm'>
                          <div className='flex items-center justify-center text-center text-lg h-full w-full bg-zinc-100 rounded-xl text-zinc-400'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Fragment>,
                );
              }
            })();

          case '2.3':
            return wrapper(
              'Forecast reduction at scale',
              'Create a new volume forecast',
              <Fragment>
                <div>
                  Forecasting your impact at scale is important to understand what could happen if you implemented a change across your
                  entire production. Select the products and/or models you would like to include in the volume forecast, specify how many
                  units you’d be producing of each over the selected timeframe and see the resulting impact at scale.
                </div>
                <NavLink
                  to={AppRoutes().report.newForecast}
                  className='mt-3 flex self-start rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'
                >
                  Create a forecast
                </NavLink>
              </Fragment>,
            );

          case '2.4':
            return wrapper(
              'Implement changes',
              'Time to make those changes a reality',
              <Fragment>
                <div>
                  The next step in your impact reduction journey is not something Sustained Impact can help you with. It’s about
                  implementing changes to how your products are made to improve your environmental impact but you should have all the tools
                  available to make this a reality!
                </div>
                <Buttons
                  primary={{
                    label: 'Next',
                    onClick: () => props.setSelectedStep('3.1'),
                  }}
                />
              </Fragment>,
            );

          case '3.1':
            return (() => {
              if (props.data.historicalImpact) {
                const moreThenOneMonth = differenceInMonths(new Date(), new Date(props.data.historicalImpact.endDate)) > 0;
                const moreThenOneYear = differenceInYears(new Date(), new Date(props.data.historicalImpact.endDate)) > 0;
                const diffInMonths = differenceInMonths(new Date(), new Date(props.data.historicalImpact.endDate));
                const diffInYears = differenceInYears(new Date(), new Date(props.data.historicalImpact.endDate));

                return wrapper(
                  'Measure achieved reduction',
                  'Upload more historical data',
                  <Fragment>
                    <div>
                      Last data point: {format(new Date(props.data.historicalImpact.startDate), 'dd/MM/yy')} to{' '}
                      {format(new Date(props.data.historicalImpact.endDate), 'dd/MM/yy')}
                    </div>
                    {moreThenOneMonth ? (
                      moreThenOneYear ? (
                        <div>
                          Your last actual impact assessment is {diffInYears} year{diffInYears > 1 ? 's' : ''} old. The insights section
                          above will show you all you have to know about how you were doing at the time. Do you have more recent data you
                          could upload?
                        </div>
                      ) : (
                        <div>
                          Your last actual impact assessment is {diffInMonths} month{diffInMonths > 1 ? 's' : ''} old. The insights section
                          above will show you all you have to know about you were doing at the time. Do you have more recent data you could
                          upload?
                        </div>
                      )
                    ) : (
                      <div>
                        The latest historical impact assessment is very recent. The insights section above will show you all you have to
                        know about you progress and further improvement opportunities.
                      </div>
                    )}
                    <div className='flex items-center gap-4 mt-3'>
                      <NavLink
                        to={AppRoutes().report.newHistorical}
                        className='flex self-start rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'
                      >
                        Upload new data
                      </NavLink>
                      <NavLink
                        to={AppRoutes().report.historicalList}
                        className='rounded-lg px-3 py-1 font-semibold border border-[#4a2e82] text-[#4a2e82]'
                      >
                        Historical report list
                      </NavLink>
                    </div>
                  </Fragment>,
                );
              }

              if (!props.data.baselineImpact) {
                return wrapper(
                  'Measure achieved reduction',
                  'You have not uploaded any historical data yet',
                  <Fragment>
                    <div>
                      We suggest you start by creating a baseline to have a clear starting point to your journey. However, you can always
                      upload new data for a given period of time and see the results compared to any previous historical datasets uploaded.
                    </div>
                    <div>Before you start, make sure you have the following data at hand:</div>
                    <ul className='list-disc ml-5 font-semibold'>
                      <li>List of all products sold over the chosen timeframe</li>
                      <li>Sold volume data for each of the above products for that same timeframe</li>
                    </ul>
                    <NavLink
                      to={AppRoutes().report.newHistorical}
                      className='mt-3 flex self-start rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'
                    >
                      Upload historical data
                    </NavLink>
                  </Fragment>,
                );
              }

              if (!props.data.historicalImpact) {
                return wrapper(
                  'Measure achieved reduction',
                  'You have not uploaded any historical data yet',
                  <Fragment>
                    <div>
                      After you’ve made changes to your products and some time has gone by, re-assess your impact to see how it has evolved.
                      Historical reports allow to upload a new set of data for a given period of time and see the results compared to your
                      baseline impact and any previous historical datasets uploaded. It’s the only way you can reliably track progress over
                      time.
                    </div>
                    <div>Before you start, make sure you have the following data at hand:</div>
                    <ul className='list-disc ml-5 font-semibold'>
                      <li>List of all products sold over a the chosen timeframe</li>
                      <li>Sold volume data for each of the above products for that same timeframe</li>
                    </ul>
                    <NavLink
                      to={AppRoutes().report.newHistorical}
                      className='mt-3 flex self-start rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white'
                    >
                      Upload historical data
                    </NavLink>
                  </Fragment>,
                );
              }
            })();

          case '4':
            return wrapper(
              'Repeat the process',
              'This is an iterative process',
              <Fragment>
                <div>
                  Sustainability is a journey and as such it is all about continuous improvements. More recent data becomes available? let’s
                  assess how this might have changed the picture. And don’t forget about primary data. The more the data reflects how your
                  specific products are made (not everyone else’s) the better when informing decision-making.
                </div>
                <Buttons
                  primary={{
                    label: 'Back to start',
                    onClick: () => props.setSelectedStep('1.1'),
                  }}
                />
              </Fragment>,
            );
          default:
            return <div />;
        }
      })()}
    </>
  );
};
