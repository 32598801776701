import cn from 'classnames';
import { ForwardedRef, PropsWithChildren, forwardRef, useState } from 'react';
import { ModalV3 } from '../../../../../components/ModalV3';
import { ModalFormApi } from '../../../../../components/ModalForm';
import { TagBadge } from './TagBadge';
import { Tag } from '../../../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

export const skipManageBadgesOnboarding = 'skipManageBadgesOnboarding';

export const TagsOnboarding = forwardRef(
  (
    props: PropsWithChildren<{
      onOpenChange?: (open: boolean) => void;
      showSkip?: boolean;
    }>,
    ref: ForwardedRef<ModalFormApi>,
  ) => {
    const [skip, setSkip] = useState(false);
    return (
      <ModalV3
        ref={ref}
        title='Helping you understand the badges'
        body={
          <div className='flex flex-col gap-4 -mt-6'>
            <div className='text-sm text-zinc-900'>
              Your mappings may include five types of badges, describing the nature of each property. Each row can present up to four
              different states that refer to different matched fields inside each packaging or ingredient, organised by priority.
            </div>
            <div>Here is what they mean:</div>
            <HelpContent />
            {props.showSkip && (
              <div className='flex gap-4'>
                <input
                  type='checkbox'
                  checked={skip}
                  onChange={() => {
                    const newSkip = !skip;
                    setSkip(newSkip);
                    localStorage.setItem(skipManageBadgesOnboarding, newSkip.toString());
                  }}
                />
                <div className='flex items-center'>
                  Don’t show this message again (you can always get back to it by clicking on{' '}
                  <div className='h-5 aspect-square flex items-center justify-center bg-lime-200 mx-0.5 rounded-full'>
                    <FontAwesomeIcon icon={light('lightbulb')} />
                  </div>
                  )
                </div>
              </div>
            )}
          </div>
        }
        confirmLabel='Close'
        hideCancel
        {...props}
      />
    );
  },
);

const HelpContent = (props: { small?: boolean }) => (
  <div className='flex flex-col gap-2'>
    {[
      {
        component: <TagBadge state={Tag.Unmatched} />,
        text: 'Requires user intervention. Unmatched from customer documents. Will be ignored until resolved when calculating the product impact.',
      },
      {
        component: <TagBadge state={Tag.ExactMatch} />,
        text: 'A perfect match in theory, AI-matched from customer documents.',
      },
      {
        component: <TagBadge state={Tag.BestMatch} />,
        text: 'Requires user verification. AI-matched from customer documents.',
      },
      {
        component: <TagBadge state={Tag.Default} />,
        text: 'System-generated default to fill in the gaps where data is missing. To be replaced with first-party data.',
      },
      {
        component: <TagBadge state={Tag.UserInput} />,
        text: 'Edited manually by user.',
      },
    ].map(({ component, text }) => (
      <div key={text} className='flex gap-5 rounded-lg p-2 pl-3 border border-zinc-300'>
        <div className='flex shrink-0 justify-center items-center w-20'>{component}</div>
        <div
          className={cn('text-zinc-700', {
            'text-xs': props.small,
          })}
        >
          {text}
        </div>
      </div>
    ))}
  </div>
);
