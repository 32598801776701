import { useEffect, useState } from 'react';
import { ForecastReport, ImpactTracking, ReportType, Targets } from '../../../../../api';
import { SingleSelect } from '../../../../../components/SingleSelect';
import { ViewToggle } from '../../../../../components/ViewToggle';
import { CollapsibleSection } from '../../../Products/Report/CollapsibleSection';
import { ImpactValueType } from '../../../Products/Report/Sku/Overview';
import { NavigationButtons } from '../../../Products/Report/Sku/components/NavigationButtons';
import { ProgressChart } from '../../../../../components/charts/ProgressTracking/ProgressChart';
import { ProgressChartLegend } from '../../../../../components/charts/ProgressTracking/ProgressChartLegend';
import { convert } from '../../../Prototype/sections/trackigDataConverterV2';
import { ImpactTargetHints } from '../../../../../components/ImpactTargetHints';

interface Props {
  data: ForecastReport;
  targets: Targets;
}

export const BaselineComparison = (props: Props) => {
  const [selectedImpact, setSelectedImpact] = useState<ImpactTracking>(props.data.tracking.impacts[0]);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);

  useEffect(() => {
    if (selectedImpact?.id === 'overall') {
      setSelectedImpactUnit(ImpactValueType.Points);
    }
  }, [selectedImpact]);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5'>
          An view of how these forecasted production volumes would get you closer to your impact reduction targets, with a direct comparison
          with your baseline impact.
        </div>
        <NavigationButtons type='icons' back={{ path: '../volumes' }} next={{ path: '../lcia' }} />
      </div>

      <CollapsibleSection open title='Baseline Comparison'>
        <div className='flex flex-col gap-y-6'>
          <div>
            A graphical representation of the total impact of these forecasted volumes as compared to the baseline and any existing
            historical data. If a target was set, this is also visible in the chart. You can toggle to see this for any specific impact
            category, in final environmental impact (impact points) or physical impact.
          </div>
          <div className='flex flex-col items-center gap-y-4 print:hidden'>
            <div className='w-60'>
              <SingleSelect
                options={props.data.tracking.impacts.map((item) => ({ ...item, disabled: false, renderIcon: item.target !== undefined }))}
                value={{
                  value: selectedImpact.id,
                  label: selectedImpact.name,
                }}
                setSelectedId={(v) => {
                  if (v === 'overall') {
                    setSelectedImpactUnit(ImpactValueType.Points);
                  }
                  setSelectedImpact(props.data.tracking.impacts.find((impact) => impact.id === v)!);
                }}
                selectedId={selectedImpact.id}
              />
            </div>

            <ViewToggle
              theme='light'
              button1={{
                label: 'Final environmental impact',
                active: selectedImpactUnit === ImpactValueType.Points,
                disabled: selectedImpact.id === 'overall',
              }}
              button2={{
                label: 'Physical Impact',
                active: selectedImpactUnit === ImpactValueType.Physical,
                disabled: selectedImpact.id === 'overall',
              }}
              toggleView={() => {
                setSelectedImpactUnit((current) =>
                  current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                );
              }}
            />
          </div>
          <div className='flex flex-col items-center shadow-regular rounded-2xl'>
            <div className='text-lg border-b border-zinc-300 font-semibold text-center py-4 w-full'>
              Actual and estimated volume impact over time
            </div>
            <div className='p-6'>
              <ImpactTargetHints
                reportType={ReportType.Forecast}
                selectedUnit={selectedImpactUnit}
                selectedImpact={selectedImpact}
                targetData={convert(selectedImpact).getTargetData()}
                targetHint={convert(selectedImpact).getTargetHint()}
                targets={props.targets}
              />
            </div>
            <div className='flex w-full gap-6 p-6 pb-2'>
              <div className='w-full h-64 text-xs ml-20'>
                <ProgressChart
                  targets={props.targets}
                  selectedImpact={selectedImpact}
                  selectedType={selectedImpactUnit}
                  impacts={props.data.tracking.impacts}
                />
              </div>
              <div className='text-sm leading-4'>
                <ProgressChartLegend showForecast />
              </div>
            </div>
          </div>
        </div>
      </CollapsibleSection>
      <NavigationButtons back={{ path: '../volumes', label: 'Volumes' }} next={{ path: '../lcia', label: 'LCIA' }} type='buttons' />
    </div>
  );
};
