import { ReportType } from '../../../../../../../api';
import { CreateReport } from '../../../../components/Lists/CreateReport';
import { format } from 'date-fns';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  reportTitle: string;
  onSubmitClick: () => void;
}

export const Step4 = (props: Props) => {
  return (
    <>
      <div className='font-semibold text-xl'>Adding actual manufactured quantities</div>
      <div>
        How many of each product did you manufacture over the given time period{' '}
        <span className='font-semibold'>{format(props.startDate!, 'dd/MM/yyyy')}</span> and{' '}
        <span className='font-semibold'>{format(props.endDate!, 'dd/MM/yyyy')}</span>? Add those in the table below.
      </div>
      <CreateReport
        dataFromCsv={false}
        reportType={ReportType.Baseline}
        dateRange={{ startDate: props.startDate!, endDate: props.endDate! }}
        reportTitle={props.reportTitle}
        onNext={props.onSubmitClick}
      />
    </>
  );
};
