export const categories = {
  gases: [
    {
      flow: 'Carbon dioxide',
      formula: 'CO2',
      category: 'CO2',
      value: 1,
    },
    {
      flow: 'Methane',
      formula: 'CH4',
      category: 'CH4',
      value: 27.9,
    },
    {
      flow: 'Dinitrogen monoxide',
      formula: 'N2O',
      category: 'N2O',
      value: 273,
    },
    {
      flow: 'Nitrogen fluoride',
      formula: 'NF3',
      category: 'NF3',
      value: 17400,
    },
    {
      flow: 'Sulfur hexafluoride',
      formula: 'SF6',
      category: 'SF6',
      value: 25200,
    },
  ],
  hydrofluorocarbons: [
    {
      flow: '2,3,3,3-Tetrafluoropropene',
      formula: 'C3H2F4',
      category: 'HFC',
      value: 0.501,
    },
    {
      flow: '3,3,3-trifluoro-2- (trifluoromethyl)prop-1-ene',
      formula: 'C4H2F6',
      category: 'HFC',
      value: 0.377,
    },
    {
      flow: '3,3,4,4,5,5,6,6,6-nonafluorohex-1-ene',
      formula: 'C6H3F9',
      category: 'HFC',
      value: 0.204,
    },
    {
      flow: '3,3,4,4,5,5,6,6,7,7,8,8,8-tridecafluorooct-1-ene',
      formula: 'C8H2BrF13',
      category: 'HFC',
      value: 0.162,
    },
    {
      flow: '3,3,4,4,5,5,6,6,7,7,8,8,9,9,10,10,10-heptadecafluorodec-1-ene',
      formula: 'C10HF',
      category: 'HFC',
      value: 0.141,
    },
    {
      flow: '3,3,4,4-tetrafluorocyclobutene',
      formula: 'C4H2F4',
      category: 'HFC',
      value: 25.6,
    },
    {
      flow: 'Butane, 1,1,1,2,2,3,3,4,4-nonafluoro-, HFC-329p',
      formula: 'C4HF9',
      category: 'HFC',
      value: 2890,
    },
    {
      flow: 'Butane, 1,1,1,3,3-pentafluoro-, HFC-365mfc',
      formula: 'C4H5F5',
      category: 'HFC',
      value: 914,
    },
    {
      flow: 'Ethane, 1,1,1,2-tetrafluoro-, HFC-134a',
      formula: 'C2H2F4',
      category: 'HFC',
      value: 1530,
    },
    {
      flow: 'Ethane, 1,1,1-trifluoro-, HFC-143a',
      formula: 'C2H3F3',
      category: 'HFC',
      value: 5810,
    },
    {
      flow: 'Ethane, 1,1,2,2-tetrafluoro-, HFC-134',
      formula: 'C2H2F4',
      category: 'HFC',
      value: 1260,
    },
    {
      flow: 'Ethane, 1,1,2-trifluoro-, HFC-143',
      formula: 'C2H3F3',
      category: 'HFC',
      value: 364,
    },
    {
      flow: 'Ethane, 1,1-difluoro-, HFC-152a',
      formula: 'C2H4F2',
      category: 'HFC',
      value: 164,
    },
    {
      flow: 'Ethane, 1,2-difluoro-, HFC-152',
      formula: 'C2H4F2',
      category: 'HFC',
      value: 21.5,
    },
    {
      flow: 'Ethane, fluoro-, HFC-161',
      formula: 'C2H5F',
      category: 'HFC',
      value: 4.84,
    },
    {
      flow: 'Ethane, hexafluoro-, HFC-116',
      formula: 'C2F6',
      category: 'HFC',
      value: 12400,
    },
    {
      flow: 'Ethane, pentafluoro-, HFC-125',
      formula: 'C2HF5',
      category: 'HFC',
      value: 3740,
    },
    {
      flow: 'Ethene, 1,1-difluoro-, HFC-1132a',
      formula: 'C2H2F2',
      category: 'HFC',
      value: 0.052,
    },
    {
      flow: 'HFO-1123',
      formula: 'C2HF3',
      category: 'HFC',
      value: 0.005,
    },
    {
      flow: 'HFO-1336mzz(E)',
      formula: 'C4H2F6',
      category: 'HFC',
      value: 17.9,
    },
    {
      flow: 'HFO-1345zfc',
      formula: 'C4H3F5',
      category: 'HFC',
      value: 0.182,
    },
    {
      flow: 'HFO-1438ezy(E)',
      formula: 'C5H2F8',
      category: 'HFC',
      value: 8.22,
    },
    {
      flow: 'HFO-1447fz',
      formula: 'C5H3F7',
      category: 'HFC',
      value: 0.235,
    },
    {
      flow: 'Methane, difluoro-, HFC-32',
      formula: 'CH2F2',
      category: 'HFC',
      value: 771,
    },
    {
      flow: 'Methane, fluoro-, HFC-41',
      formula: 'CH3F',
      category: 'HFC',
      value: 135,
    },
    {
      flow: 'Methane, trifluoro-, HFC-23',
      formula: 'CHF3',
      category: 'HFC',
      value: 14600,
    },
    {
      flow: 'Pentane, 2,3-dihydroperfluoro-, HFC-4310mee',
      formula: 'C5H2F10',
      category: 'HFC',
      value: 1600,
    },
    {
      flow: 'Propane, 1,1,1,2,2,3,3-heptafluoro-, HFC-227ca',
      formula: 'C3HF7',
      category: 'HFC',
      value: 2980,
    },
    {
      flow: 'Propane, 1,1,1,2,2,3-hexafluoro-, HFC-236cb',
      formula: 'C3H2F6',
      category: 'HFC',
      value: 1350,
    },
    {
      flow: 'Propane, 1,1,1,2,2-pentafluoro-, HFC-245cb',
      formula: 'C3H3F5',
      category: 'HFC',
      value: 4550,
    },
    {
      flow: 'Propane, 1,1,1,2,3,3,3-heptafluoro-, HFC-227ea',
      formula: 'C3HF',
      category: 'HFC',
      value: 3600,
    },
    {
      flow: 'Propane, 1,1,1,2,3,3-hexafluoro-, HFC-236ea',
      formula: 'C3H2F6',
      category: 'HFC',
      value: 1500,
    },
    {
      flow: 'Propane, 1,1,1,2,3-pentafluoro-, HFC-245eb',
      formula: 'C3H3F5',
      category: 'HFC',
      value: 325,
    },
    {
      flow: 'Propane, 1,1,1,3,3,3-hexafluoro-, HCFC-236fa',
      formula: 'C3H2F6',
      category: 'HFC',
      value: 8690,
    },
    {
      flow: 'Propane, 1,1,1,3,3-pentafluoro-, HFC-245fa',
      formula: 'C3H3F5',
      category: 'HFC',
      value: 962,
    },
    {
      flow: 'Propane, 1,1,1-trifluoro-, HFC-263fb',
      formula: 'C3H5F3',
      category: 'HFC',
      value: 74.8,
    },
    {
      flow: 'Propane, 1,1,2,2,3-pentafluoro-, HFC-245ca',
      formula: 'C3H3F',
      category: 'HFC',
      value: 787,
    },
    {
      flow: 'Propane, 1,1,2,3,3-pentafluoro-, HFC-245ea',
      formula: 'C3H3F5',
      category: 'HFC',
      value: 255,
    },
    {
      flow: 'Propane, 2,2-difluoro-, HFC-272ca',
      formula: 'C3H6F2',
      category: 'HFC',
      value: 599,
    },
    {
      flow: 'trans-1,3,3,3-Tetrafluoropropene',
      formula: 'C3H2F4',
      category: 'HFC',
      value: 1.37,
    },
    {
      flow: 'Trifluoropropene, HFC-1243zf',
      formula: 'C3H3F3',
      category: 'HFC',
      value: 0.261,
    },
    {
      flow: 'Vinylfluoride',
      formula: 'C2H3F',
      category: 'HFC',
      value: 0.024,
    },
    {
      flow: '(4s,5s)-1,1,2,2,3,3,4,5-octafluorocyclopentane',
      formula: 'C5H2F8',
      category: 'PFC',
      value: 258,
    },
  ],
  perfluorochemicals: [
    {
      flow: '(4s,5s)-1,1,2,2,3,3,4,5-octafluorocyclopentane',
      formula: 'C5H2F8',
      category: 'PFC',
      value: 258,
    },
    {
      flow: '(E)-1,2,3,3,3-Pentafluoroprop-1-ene',
      formula: 'C3HF5',
      category: 'PFC',
      value: 0.118,
    },
    {
      flow: '(Z)-1,1,1,4,4,4-Hexafluorobut-2-ene',
      formula: 'C4H2F6',
      category: 'PFC',
      value: 2.08,
    },
    {
      flow: '(Z)-1,2,3,3,3-Pentafluoroprop-1-ene',
      formula: 'C3HF5',
      category: 'PFC',
      value: 0.344,
    },
    {
      flow: '(Z)-1,3,3,3-Tetrafluoroprop-1-ene',
      formula: 'C3H2F4',
      category: 'PFC',
      value: 0.315,
    },
    {
      flow: '1,1,2,2,3,3,4-heptafluorocyclopentane',
      formula: 'C5H3F7',
      category: 'PFC',
      value: 231,
    },
    {
      flow: '1,1,2,2,3,3-hexafluorocyclopentane',
      formula: 'C5H4F6',
      category: 'PFC',
      value: 120,
    },
    {
      flow: '1,3,3,4,4,5,5-heptafluorocyclopentene',
      formula: 'C5HF7',
      category: 'PFC',
      value: 45.1,
    },
    {
      flow: '1,3,3,4,4-pentafluorocyclobutene',
      formula: 'C4HF5',
      category: 'PFC',
      value: 92.4,
    },
    {
      flow: 'Butane, perfluoro-',
      formula: 'C4F10',
      category: 'PFC',
      value: 10000,
    },
    {
      flow: 'Butane, perfluorocyclo-, PFC-318',
      formula: 'c-C4F8',
      category: 'PFC',
      value: 10200,
    },
    {
      flow: 'Cis-perfluorodecalin',
      formula: 'C10F18',
      category: 'PFC',
      value: 7800,
    },
    {
      flow: 'Hexafluorocyclobutene',
      formula: 'C4F6',
      category: 'PFC',
      value: 126,
    },
    {
      flow: 'Hexane, perfluoro-',
      formula: 'C6F14',
      category: 'PFC',
      value: 8620,
    },
    {
      flow: 'Methane, tetrafluoro-, CFC-14/R-14',
      formula: 'CF4',
      category: 'PFC',
      value: 7380,
    },
    {
      flow: 'Octa deca fluoro octane',
      formula: 'C8F18',
      category: 'PFC',
      value: 8260,
    },
    {
      flow: 'Pentane, perfluoro-',
      formula: 'C5F12',
      category: 'PFC',
      value: 9220,
    },
    {
      flow: 'Perfluorobut-1-ene',
      formula: 'C4F8',
      category: 'PFC',
      value: 0.102,
    },
    {
      flow: 'Perfluorobut-2-ene',
      formula: 'C4F8',
      category: 'PFC',
      value: 1.97,
    },
    {
      flow: 'Perfluorobuta-1,3-diene',
      formula: 'C4F6',
      category: 'PFC',
      value: 0.004,
    },
    {
      flow: 'Perfluorocyclopentene',
      formula: 'C5F8',
      category: 'PFC',
      value: 78.1,
    },
    {
      flow: 'Perfluorodecalin (mixed)',
      formula: 'C10F18',
      category: 'PFC',
      value: 7480,
    },
    {
      flow: 'Perfluorodecalin (trans)',
      formula: 'C10F18',
      category: 'PFC',
      value: 7120,
    },
    {
      flow: 'Perfluoroheptane',
      formula: 'C7F16',
      category: 'PFC',
      value: 8410,
    },
    {
      flow: 'Perfluoropentane',
      formula: 'C5F12',
      category: 'PFC',
      value: 9220,
    },
    {
      flow: 'Perfluoropropene',
      formula: 'C3F6',
      category: 'PFC',
      value: 0.09,
    },
    {
      flow: 'PFC-1114',
      formula: 'C2F4',
      category: 'PFC',
      value: 0.004,
    },
    {
      flow: 'Propane, perfluoro-',
      formula: 'C3F8',
      category: 'PFC',
      value: 9290,
    },
  ],
};
