import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import sortBy from 'lodash/sortBy';
import { Fragment } from 'react';
import { GhgProductReport, IngredientNode, NodeType, OutputType, ProductReport, ProductType, StepInput } from '../../../../../../api';
import {
  getConsumptionLocationsFromAll,
  getDisposalsFromAll,
  getIngredientsFromAll,
  getOutputsFromAll,
  getPackagingsFromAll,
  getPrimaryAndMaterialSuppliersFromAll,
  getProductionFacilitiesFromAll,
  getTransportsFromAll,
} from '../../../Details/dataModel';

interface Props {
  data: ProductReport | GhgProductReport;
}

export const SystemBoundariesGraph = (props: Props) => {
  const hideOutputsArrows = (data: ProductReport) =>
    getProductionFacilitiesFromAll(data.product.nodes).find((facility) =>
      facility.steps.some((step) => step.outputs.find((output) => output.emission)),
    );

  const consumerIngredientIncluded =
    getConsumptionLocationsFromAll(props.data.product.nodes).find((node) =>
      node.steps.find((step) =>
        step.inputs.find((input) => (input as unknown as { item: { type: string } }).item?.type === 'consumer_ingredient'),
      ),
    ) !== undefined;
  const arrow = (label: string, offset: string, position: 'before' | 'after', textSize: string) => (
    <div style={{ top: offset }} className={cn(`${position} absolute print:hidden`, { hidden: position === 'after' && hideOutputsArrows })}>
      <svg width='163' height='101' viewBox='0 0 163 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_0_1)'>
          <path
            d='M157.858 44.1088L101.299 0.521007C100.408 -0.0854895 99.2615 -0.16888 98.2923 0.304485C97.3232 0.777853 96.6815 1.73399 96.6091 2.81117V20.3434L7.00679 20.342C5.34635 20.342 4 21.6909 4 23.3544V69.5345C4 70.3333 4.31679 71.0985 4.88056 71.6646C5.44432 72.2294 6.20946 72.5468 7.00679 72.5468H96.6091V90.079V90.0776C96.6292 91.1844 97.2521 92.1903 98.2333 92.6987C98.6333 92.9004 99.0776 93.0039 99.5259 92.9999C100.166 92.9932 100.788 92.782 101.299 92.3974L157.858 48.7167C158.566 48.1559 158.987 47.3033 159 46.3969C158.977 45.5026 158.56 44.6635 157.858 44.1081L157.858 44.1088Z'
            fill='#000000'
          />
        </g>
        <foreignObject x={4} y={0} width={155} height={93}>
          <div className={cn('ml-2 h-full flex items-center text-white font-semibold', textSize)}>{label}</div>
        </foreignObject>
        <defs>
          <filter id='filter0_d_0_1' x='0' y='0' width='163' height='101' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='2' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_0_1' />
            <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_0_1' result='shape' />
          </filter>
        </defs>
      </svg>
    </div>
  );

  return (
    <div className='flex gap-2'>
      <div className='relative w-2/5'>
        {(() => {
          const offsets = consumerIngredientIncluded ? ['20%', '40%', '60%', '80%'] : ['25%', '50%', '75%'];

          return (
            <>
              {arrow('Raw Materials', offsets[0], 'before', 'text-base')}
              {arrow('Energy', offsets[1], 'before', 'text-base')}
              {arrow('Other inputs', offsets[2], 'before', 'text-base')}
              {consumerIngredientIncluded && arrow('Additional at use ingredients', offsets[3], 'before', 'text-sm')}
            </>
          );
        })()}
      </div>
      <div className='flex flex-col text-dark bg-[#E3E3E3] rounded-regular py-4'>
        <div className='flex self-center text-xl font-semibold mb-8'>System boundaries</div>
        <div className='p-6 pt-4 mx-6 mb-0 rounded-regular bg-white border border-gray-400'>
          <div className='text-lg font-semibold'>Raw Materials</div>
          <div className='bg-[#E8EAF5] p-6 mt-6 rounded-regular'>
            <div className='font-semibold'>Ingredients</div>
            <div className='flex justify-center mt-4'>
              <div className='grid grid-cols-[300px_300px] print:flex print:flex-wrap gap-4'>
                {getIngredientsFromAll(props.data.product.nodes).map((ingredient) => (
                  <div key={ingredient.id} className='flex flex-col bg-white rounded-md p-3'>
                    <div className='font-semibold text-center mb-2'>
                      {ingredient.displayName} ({ingredient.amount.value}
                      {ingredient.amount.unit.name})
                    </div>
                    <div className='flex justify-center text-sm'>
                      {ingredient.nodes.map((node) => (
                        <div
                          key={node.id}
                          title={`${node.splitPercent}% from ${node.supplier.name} (${node.location.name})`}
                          className='flex gap-1'
                        >
                          {`${node.splitPercent}% from ${node.supplier.name} (${node.location.name})`}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className='bg-[#E8EAF5] p-6 mt-6 rounded-regular'>
            <div className='font-semibold'>Packaging</div>
            <div className='flex justify-center mt-4'>
              <div className='grid grid-cols-[300px_300px] print:flex print:flex-wrap gap-4'>
                {(() => {
                  const packagings = getPackagingsFromAll(props.data.product.nodes);
                  return packagings.length > 0 ? (
                    packagings.map((packaging) => (
                      <div key={packaging.id} className='flex flex-col text-center bg-white rounded-md p-3'>
                        <div className='flex flex-col text-sm h-full justify-between'>
                          <div className='flex flex-col'>
                            <div className='font-semibold text-center mb-2 text-base'>
                              {packaging.displayName} ({packaging.amount.value}
                              {packaging.amount.unit.name})
                            </div>
                            <div className='flex flex-col gap-2'>
                              {packaging.materials.map((material) => {
                                return (
                                  <div
                                    key={material.id}
                                  >{`${material.compositionPercent}% ${material.subType.name} (${material.name})`}</div>
                                );
                              })}
                            </div>
                          </div>
                          <div className='flex self-center w-32 h-px my-2 bg-gray-400' />
                          <div className='flex gap-2 justify-center'>
                            {packaging.nodes.map((node) => `${node.splitPercent}% from ${node.displayName}`)}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No packaging added</div>
                  );
                })()}
              </div>
            </div>
          </div>
        </div>
        <FontAwesomeIcon className='my-2 h-12' icon={regular('arrow-down-long')} />
        <div className='p-6 pt-4 mx-6 rounded-regular bg-white border border-gray-400'>
          <div className='text-lg font-semibold'>Production</div>
          {getProductionFacilitiesFromAll(props.data.product.nodes).map((production) => (
            <div key={production.id} className='flex flex-col gap-4 bg-[#E8EAF5] p-6 mt-6 rounded-regular'>
              <div className='flex flex-col gap-1'>
                <div className='font-semibold'>Production at {production.displayName}</div>
                <div title={`${production.facility.address?.text}, ${production.facility.location?.name}`} className='maw-w-[616px]'>
                  Location: {production.facility.address?.text}, {production.facility.location?.name}
                </div>
              </div>
              {production.steps.length === 0 && <div>No production steps</div>}
              {(() => {
                const ingredientProductionSteps = production.steps.filter(({ type }) => type === NodeType.ProductionIngredient);
                return (
                  ingredientProductionSteps.length > 0 && (
                    <div className='flex flex-col bg-white rounded-md p-3'>
                      <div className='font-semibold'>Food Production Steps</div>
                      <ul className='list-disc ml-6'>
                        {ingredientProductionSteps.map((step, i) => {
                          const inputsOfCurrentProductionStep = step.inputs
                            .map(({ id }) => props.data.product.nodes.find((node) => node.id === id))
                            .filter((node) => node)
                            .map((node) => ({ ...node, displayName: node?.displayName, amount: (node as IngredientNode).amount }));
                          const outputsOfCurrentProductionStep = step.inputs
                            .map(({ id }) => getOutputsFromAll(props.data.product.nodes).find((node) => node.id === id))
                            .filter((node) => node)
                            .map((node) => ({ ...node, displayName: node?.name, amount: node?.amount }));

                          return (
                            <li key={i}>
                              {`${step.process.name} of `}
                              {[...outputsOfCurrentProductionStep, ...inputsOfCurrentProductionStep].map(
                                ({ displayName, amount }, i, arr) => (
                                  <span key={i}>{`${displayName} (${amount?.value}${amount?.unit.name})${
                                    i < arr.length - 1 ? ', ' : ''
                                  }`}</span>
                                ),
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )
                );
              })()}

              {(() => {
                const packagingProductionSteps = production.steps.filter(({ type }) => type === NodeType.ProductionPackaging);
                return (
                  packagingProductionSteps.length > 0 && (
                    <div className='flex flex-col bg-white rounded-md p-3'>
                      <div className='font-semibold'>Packaging Production Steps</div>
                      <ul className='list-disc ml-6'>
                        {packagingProductionSteps.map((step, i) => {
                          const inputsOfCurrentPackage = step.inputs
                            .map(({ id }) => props.data.product.nodes.find((node) => node.id === id))
                            .filter((node) => node?.type === NodeType.Ingredient)
                            .map((ingredientNode) => ({
                              name: ingredientNode?.displayName,
                              amount: `${(ingredientNode as IngredientNode)?.amount.value}${
                                (ingredientNode as IngredientNode)?.amount.unit.name
                              }`,
                            }));
                          const outputsOfCurrentPackage = step.inputs
                            .map((input) => getOutputsFromAll(props.data.product.nodes).find(({ id }) => input.id === id))
                            .filter((node) => node?.outputType.type === OutputType.IntermediateProduct)
                            .map((outputNode) => ({
                              name: outputNode?.name,
                              amount: `${outputNode?.amount?.value}${outputNode?.amount?.unit.name}`,
                            }));

                          return (
                            <li key={i}>
                              {`${step.process.name} of `}
                              {[...inputsOfCurrentPackage, ...outputsOfCurrentPackage].map(({ name, amount }, i, arr) => (
                                <span key={i}>{`${name} (${amount})${i < arr.length - 1 ? ', ' : ''}`}</span>
                              ))}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )
                );
              })()}
            </div>
          ))}
          <div className='bg-[#E8EAF5] p-6 mt-4 rounded-regular'>
            <div className='font-semibold'>Transport links</div>
            <ul className='flex flex-col mt-4 list-disc gap-1 ml-6'>
              {(() => {
                const transportLinksBetweenProductions = getTransportsFromAll(props.data.product.nodes).map((transport, i) => {
                  const idFrom = getPrimaryAndMaterialSuppliersFromAll(props.data.product.nodes).find(({ edges }) =>
                    edges.includes(transport.id),
                  )?.id;
                  const idTo = transport.edges[0];

                  const fromNode = getPrimaryAndMaterialSuppliersFromAll(props.data.product.nodes).find((node) => node.id === idFrom);
                  const toNode = getPrimaryAndMaterialSuppliersFromAll(props.data.product.nodes).find((node) => node.id === idTo);

                  if (fromNode?.type === NodeType.Production && toNode?.type === NodeType.Production) {
                    return (
                      <li key={i}>
                        Intermediate product{' '}
                        {
                          fromNode.steps
                            .find(({ finalStep }) => !finalStep)
                            ?.outputs.find((output) => output.outputType.type === OutputType.IntermediateProduct)?.name
                        }{' '}
                        {`From ${fromNode?.displayName} to ${toNode?.displayName}`}
                      </li>
                    );
                  }

                  return undefined;
                });

                if (transportLinksBetweenProductions.filter((link) => link).length > 0) {
                  return transportLinksBetweenProductions;
                } else {
                  return <div className='-ml-6'>No transport links between production facilities</div>;
                }
              })()}
            </ul>
          </div>
        </div>

        {props.data.product.productType !== ProductType.Internal && (
          <>
            <FontAwesomeIcon className='my-2 h-12' icon={regular('arrow-down-long')} />

            <div className='p-6 mx-6 mb-0 rounded-regular bg-white border border-gray-400'>
              <div className='text-lg font-semibold'>Distribution</div>
              <div className='mt-4'>Conservation requirements: {props.data.product.conservation.requirement.name}</div>
              <div className='bg-[#E8EAF5] p-6 mt-4 rounded-regular'>
                <div className='font-semibold'>Transport links</div>
                <ul className='flex flex-col mt-3 list-disc gap-1 ml-6'>
                  {getTransportsFromAll(props.data.product.nodes).map((transport, i) => {
                    const from = getPrimaryAndMaterialSuppliersFromAll(props.data.product.nodes).find(({ edges }) =>
                      edges.includes(transport.id),
                    )?.id;
                    const to = transport.edges[0];

                    const fromNode = getPrimaryAndMaterialSuppliersFromAll(props.data.product.nodes).find((node) => node.id === from);
                    const toNode = getPrimaryAndMaterialSuppliersFromAll(props.data.product.nodes).find((node) => node.id === to);

                    if (!(fromNode?.type === NodeType.Production && toNode?.type === NodeType.Production)) {
                      return <li key={i}>{`From ${fromNode?.displayName} to ${toNode?.displayName}`}</li>;
                    }

                    return undefined;
                  })}
                </ul>
              </div>
            </div>
          </>
        )}
        {getConsumptionLocationsFromAll(props.data.product.nodes).length > 0 && (
          <Fragment>
            <FontAwesomeIcon className='my-2 h-12' icon={regular('arrow-down-long')} />
            <div className='p-6 mx-6 mb-0 rounded-regular bg-white border border-gray-400'>
              <div className='text-lg font-semibold'>Use</div>
              {getConsumptionLocationsFromAll(props.data.product.nodes).map((node) => (
                <div key={node.id} className='bg-[#E8EAF5] p-6 mt-4 rounded-regular'>
                  <div className='font-semibold'>Consumption location {node.displayName}</div>
                  {node.steps.length > 0 ? (
                    <ul className='list-disc ml-6 mt-1'>
                      {node.steps.map((step, i) => {
                        return (
                          <li key={i}>
                            {`${step.displayName} of `}
                            <span>
                              {sortBy(
                                step.inputs,
                                (input) => input.item && (input.item as unknown as StepInput & { type?: string })?.type === 'final_product',
                              )
                                .filter((input) => (input.item as unknown as StepInput & { type?: string })?.type !== 'consumer_ingredient')
                                .map((input) => {
                                  if (input.item?.name) {
                                    return input.item?.name;
                                  } else if (props.data.product.nodes.find((node) => node.id === input.id)) {
                                    return props.data.product.nodes.find((node) => node.id === input.id)?.displayName;
                                  } else {
                                    return node.steps
                                      .find((step) => step.outputs.find((output) => output.id === input.id))
                                      ?.outputs.find(({ id }) => input.id === id)?.name;
                                  }
                                })
                                .join(', ')}
                              {step.inputs.some(
                                (input) => (input.item as unknown as StepInput & { type?: string })?.type === 'consumer_ingredient',
                              ) && (
                                <span>
                                  {' '}
                                  with an additional{' '}
                                  {step.inputs
                                    .filter(
                                      (input) => (input.item as unknown as StepInput & { type?: string })?.type === 'consumer_ingredient',
                                    )
                                    .map((input) => `${input.item?.name} of ${input.amountValue}${input.item?.unit?.name}`)}{' '}
                                  (purchased separately by the consumer).
                                </span>
                              )}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div className='mt-4'>No preparation steps required</div>
                  )}
                </div>
              ))}
            </div>
          </Fragment>
        )}
        {getConsumptionLocationsFromAll(props.data.product.nodes).length > 0 && (
          <Fragment>
            <FontAwesomeIcon className='my-2 h-12' icon={regular('arrow-down-long')} />
            <div className='p-6 mx-6 mb-0 rounded-regular bg-white border border-gray-400'>
              <div className='text-lg font-semibold'>End of life</div>
              {getConsumptionLocationsFromAll(props.data.product.nodes).map((node, i) => (
                <div key={i} className='bg-[#E8EAF5] p-6 mt-4 rounded-regular'>
                  <div className='font-semibold'>Consumption location {node.displayName}</div>
                  {(() => {
                    const disposals = getDisposalsFromAll(props.data.product.nodes).filter((disposalNode) =>
                      node.edges.find((id) => disposalNode.id === id),
                    );

                    return disposals.length > 0 ? (
                      <>
                        <div className='mt-3'>Waste treatment of:</div>
                        <ul className='list-disc ml-6 mt-1'>
                          {disposals.map((node, i2) => {
                            return <li key={i2}>{node?.displayName}</li>;
                          })}
                        </ul>
                      </>
                    ) : (
                      <div className='mt-4'>No packaging added hence no disposability requirements</div>
                    );
                  })()}
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </div>
      <div className='w-2/5 relative'>
        {arrow('Emissions', '33%', 'after', 'text-base')}
        {arrow('Waste', '66%', 'after', 'text-base')}
      </div>
    </div>
  );
};
