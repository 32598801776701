import { Navigate, Route, Routes, useLocation, useParams } from 'react-router';
import { Overview } from './Overview';
import { Main } from './Main';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { ForecastReport, getForecast, getManufacturingTargets } from '../../../../../api';
import { useApiQuery } from '../../../../../hooks/useApiQuery';

export const Report = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const response = useApiQuery(getForecast(id!), { cancel: state !== null });
  const data = (state || response.data) as ForecastReport;
  const targets = useApiQuery(getManufacturingTargets());

  if (!data || !targets.data) {
    return <ManufacturingSkeletonLoader />;
  }

  return (
    <Routes>
      <Route
        path='production/*'
        element={
          <Routes>
            <Route path='overview' element={<Overview warning={[]} data={data} targets={targets.data} />} />
            <Route path='*' element={<Main data={data} targets={targets.data} />} />
          </Routes>
        }
      />
      <Route path='*' element={<Navigate to='production/overview' replace />} />
    </Routes>
  );
};
