export const trackingData = {
  impacts: [
    {
      id: 'overall',
      name: 'Total environmental impact',
      unit: 'Impact points',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 3024.557367424346,
        },
      },
      target: {
        estimate: '-12% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 5104.496776044031,
          },
        },
        end: {
          date: '2025-05-24',
          dailyImpact: {
            points: 1798.46105588839,
          },
        },
      },
      delta: {
        raw: -40.74719800747199,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_sufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 5104.496776044031,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 5104.496776044031,
          },
        },
        totalImpact: {
          points: 1868245.8200321156,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 4420.506921620199,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 4420.506921620199,
            },
          },
          totalImpact: {
            points: 1613485.0263913726,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 4176.406453126934,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 4176.406453126934,
            },
          },
          totalImpact: {
            points: 1528564.761844458,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 3024.557367424346,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 3024.557367424346,
            },
          },
          totalImpact: {
            points: 1103963.4391098863,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2025-05-24',
      },
      yAxisMaxValue: {
        points: 5104.496776044031,
      },
      estimate: {
        type: 'sufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 3024.557367424346,
          },
        },
        end: {
          date: '2025-05-24',
          dailyImpact: {
            points: 2041.767988267979,
          },
        },
      },
    },
    {
      id: 'pef_acidification',
      name: 'Acidification',
      unit: 'mol H+ eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 274.70614832095276,
          physical: 0.2462144296008981,
        },
      },
      target: {
        estimate: '-50% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 463.61714397167947,
            physical: 0.41553212898175945,
          },
        },
        end: {
          date: '2021-12-31',
          dailyImpact: {
            points: 0,
            physical: 0,
          },
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_insufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 463.61714397167947,
            physical: 0.41553212898175945,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 463.61714397167947,
            physical: 0.41553212898175945,
          },
        },
        totalImpact: {
          points: 169683.8746936347,
          physical: 152.08475920732397,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 401.49360139216185,
              physical: 0.3598518586474665,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 401.49360139216185,
              physical: 0.3598518586474665,
            },
          },
          totalImpact: {
            points: 146545.16450813907,
            physical: 131.34592840632527,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 379.32311779501055,
              physical: 0.3399808328032578,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 379.32311779501055,
              physical: 0.3399808328032578,
            },
          },
          totalImpact: {
            points: 138832.26111297385,
            physical: 124.43298480599236,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 274.70614832095276,
              physical: 0.2462144296008981,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 274.70614832095276,
              physical: 0.2462144296008981,
            },
          },
          totalImpact: {
            points: 100267.74413714778,
            physical: 89.8682668043278,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 463.61714397167947,
        physical: 0.41553212898175945,
      },
      estimate: {
        type: 'insufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 274.70614832095276,
            physical: 0.2462144296008981,
          },
        },
        end: {
          date: '2030-05-13',
          dailyImpact: {
            points: 0.07715973841823143,
            physical: 0,
          },
        },
      },
    },
    {
      id: 'pef_climate_change',
      name: 'Climate Change',
      unit: 'kg CO2 eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 761.6403643757546,
          physical: 27.315921236598754,
        },
      },
      target: {
        estimate: '-75% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 1285.4081811553824,
            physical: 46.10064050649179,
          },
        },
        end: {
          date: '2021-05-01',
          dailyImpact: {
            points: 1.7608331248704872,
            physical: 0,
          },
        },
      },
      delta: {
        raw: -40.747198007471965,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_insufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 1285.4081811553824,
            physical: 46.10064050649179,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 1285.4081811553824,
            physical: 46.10064050649179,
          },
        },
        totalImpact: {
          points: 470459.3943028699,
          physical: 16872.834425375997,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 1113.1666863953337,
              physical: 39.92326949964433,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 1113.1666863953337,
              physical: 39.92326949964433,
            },
          },
          totalImpact: {
            points: 406305.84053429676,
            physical: 14571.99336737018,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 1051.6976027634944,
              physical: 37.71870586894783,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 1051.6976027634944,
              physical: 37.71870586894783,
            },
          },
          totalImpact: {
            points: 384921.32261143904,
            physical: 13805.046348034906,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 761.6403643757546,
              physical: 27.315921236598754,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 761.6403643757546,
              physical: 27.315921236598754,
            },
          },
          totalImpact: {
            points: 277998.7329971505,
            physical: 9970.311251358546,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 1285.4081811553824,
        physical: 46.10064050649179,
      },
      estimate: {
        type: 'insufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 761.6403643757546,
            physical: 27.315921236598754,
          },
        },
        end: {
          date: '2030-05-13',
          dailyImpact: {
            points: 0.21393030932597412,
            physical: 0,
          },
        },
      },
    },
    {
      id: 'pef_ecotoxicity_freshwater',
      name: 'Ecotoxicity, freshwater',
      unit: 'CTUe',
      isToxicityRelated: true,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 178.5953199467789,
          physical: 527.56858769174,
        },
      },
      target: {
        estimate: '-25% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 301.4124462321635,
            physical: 890.369012014433,
          },
        },
        end: {
          date: '2023-08-17',
          dailyImpact: {
            points: 28.076775813406982,
            physical: 82.93848331093363,
          },
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_insufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 301.4124462321635,
            physical: 890.369012014433,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 301.4124462321635,
            physical: 890.369012014433,
          },
        },
        totalImpact: {
          points: 110316.95532097187,
          physical: 325875.0583972825,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 261.0239291529845,
              physical: 771.0617820110048,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 261.0239291529845,
              physical: 771.0617820110048,
            },
          },
          totalImpact: {
            points: 95273.73414083935,
            physical: 281437.55043401674,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 246.61018328086112,
              physical: 728.483737102718,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 246.61018328086112,
              physical: 728.483737102718,
            },
          },
          totalImpact: {
            points: 90259.32708079516,
            physical: 266625.0477795948,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 178.5953199467789,
              physical: 527.56858769174,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 178.5953199467789,
              physical: 527.56858769174,
            },
          },
          totalImpact: {
            points: 65187.29178057428,
            physical: 192562.5345074851,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2023-08-17',
      },
      yAxisMaxValue: {
        points: 301.4124462321635,
        physical: 890.369012014433,
      },
      estimate: {
        type: 'insufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 178.5953199467789,
            physical: 527.56858769174,
          },
        },
        end: {
          date: '2023-08-17',
          dailyImpact: {
            points: 163.45649676030024,
            physical: 482.8486724655989,
          },
        },
      },
    },
    {
      id: 'pef_eutrophication_freshwater',
      name: 'Eutrophication, freshwater',
      unit: 'kg P eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 65.29530362916282,
          physical: 0.0037471391994616573,
        },
      },
      target: {
        estimate: '-12% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 110.19783273269809,
            physical: 0.006323986500982999,
          },
        },
        end: {
          date: '2025-04-04',
          dailyImpact: {
            points: 37.73898381256784,
            physical: 0.0021657488017065053,
          },
        },
      },
      delta: {
        raw: -40.74719800747199,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_sufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 110.19783273269809,
            physical: 0.006323986500982999,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 110.19783273269809,
            physical: 0.006323986500982999,
          },
        },
        totalImpact: {
          points: 40332.406780167505,
          physical: 2.3145790593597777,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 95.43159761185333,
              physical: 0.005476588060751652,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 95.43159761185333,
              physical: 0.005476588060751652,
            },
          },
          totalImpact: {
            points: 34832.53312832647,
            physical: 1.9989546421743531,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 90.16186314493478,
              physical: 0.005174170773531544,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 90.16186314493478,
              physical: 0.005174170773531544,
            },
          },
          totalImpact: {
            points: 32999.241911046134,
            physical: 1.893746503112545,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 65.29530362916282,
              physical: 0.0037471391994616573,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 65.29530362916282,
              physical: 0.0037471391994616573,
            },
          },
          totalImpact: {
            points: 23832.785824644427,
            physical: 1.367705807803505,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2025-04-04',
      },
      yAxisMaxValue: {
        points: 110.19783273269809,
        physical: 0.006323986500982999,
      },
      estimate: {
        type: 'sufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 65.29530362916282,
            physical: 0.0037471391994616573,
          },
        },
        end: {
          date: '2025-04-04',
          dailyImpact: {
            points: 45.29224752760394,
            physical: 0.002599212297201183,
          },
        },
      },
    },
    {
      id: 'pef_eutrophication_marine',
      name: 'Eutrophication, marine',
      unit: 'kg N eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 137.21989032496265,
          physical: 0.09060769150920553,
        },
      },
      target: {
        estimate: '-5% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 231.58379977079656,
            physical: 0.1529171422486171,
          },
        },
        end: {
          date: '2030-02-22',
          dailyImpact: {
            points: 114.04709043507025,
            physical: 0.07530645566901083,
          },
        },
      },
      delta: {
        raw: -40.747198007471965,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_sufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 231.58379977079656,
            physical: 0.1529171422486171,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 231.58379977079656,
            physical: 0.1529171422486171,
          },
        },
        totalImpact: {
          points: 84759.67071611153,
          physical: 55.96767406299386,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 200.5521473980223,
              physical: 0.13242662605191574,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 200.5521473980223,
              physical: 0.13242662605191574,
            },
          },
          totalImpact: {
            points: 73201.53380027815,
            physical: 48.33571850894924,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 189.47765435792448,
              physical: 0.12511402547614128,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 189.47765435792448,
              physical: 0.12511402547614128,
            },
          },
          totalImpact: {
            points: 69348.82149500035,
            physical: 45.79173332426771,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 137.21989032496265,
              physical: 0.09060769150920553,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 137.21989032496265,
              physical: 0.09060769150920553,
            },
          },
          totalImpact: {
            points: 50085.25996861137,
            physical: 33.07180740086002,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2030-02-22',
      },
      yAxisMaxValue: {
        points: 231.58379977079656,
        physical: 0.1529171422486171,
      },
      estimate: {
        type: 'sufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 137.21989032496265,
            physical: 0.09060769150920553,
          },
        },
        end: {
          date: '2030-02-22',
          dailyImpact: {
            points: 4.119802344804896,
            physical: 0.0027203474587609994,
          },
        },
      },
    },
    {
      id: 'pef_eutrophication_terrestrial',
      name: 'Eutrophication, terrestrial',
      unit: 'mol N eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 208.5764480593543,
          physical: 0.9937177907012367,
        },
      },
      target: {
        estimate: '-50% by 2025',
        type: 'long_term',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 176.00555673776958,
            physical: 0.8385407586518423,
          },
        },
        end: {
          date: '2027-01-01',
          dailyImpact: {
            points: 176.00555673776958,
            physical: 0.8385407586518423,
          },
        },
      },
      delta: {
        raw: -40.74719800747199,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'long_term_sufficient',
        impactValueDiff: {
          points: 32.570891321584725,
          physical: 0.15517703204939437,
        },
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 352.01111347553916,
            physical: 1.6770815173036846,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 352.01111347553916,
            physical: 1.6770815173036846,
          },
        },
        totalImpact: {
          points: 128836.06753204732,
          physical: 613.8118353331486,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 304.8425010098256,
              physical: 1.4523567710248846,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 304.8425010098256,
              physical: 1.4523567710248846,
            },
          },
          totalImpact: {
            points: 111267.51286858633,
            physical: 530.1102214240829,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 288.00909284362297,
              physical: 1.372157605066651,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 288.00909284362297,
              physical: 1.372157605066651,
            },
          },
          totalImpact: {
            points: 105411.32798076599,
            physical: 502.2096834543943,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 208.5764480593543,
              physical: 0.9937177907012367,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 208.5764480593543,
              physical: 0.9937177907012367,
            },
          },
          totalImpact: {
            points: 76130.40354166433,
            physical: 362.70699360595137,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2027-01-01',
      },
      yAxisMaxValue: {
        points: 352.01111347553916,
        physical: 1.6770815173036846,
      },
      estimate: {
        type: 'sufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 208.5764480593543,
            physical: 0.9937177907012367,
          },
        },
        end: {
          date: '2027-01-01',
          dailyImpact: {
            points: 95.28355192949532,
            physical: 0.45395806475043765,
          },
        },
      },
      targetDateBadge: {
        name: 'Target date',
        point: {
          date: '2026-01-01',
          dailyImpact: {
            points: 176.00555673776958,
            physical: 0.8385407586518423,
          },
        },
      },
    },
    {
      id: 'pef_human_toxicity_cancer',
      name: 'Human Toxicity, Cancer',
      unit: 'CTUh',
      isToxicityRelated: true,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 68.77971954761587,
          physical: 5.57112499240874e-8,
        },
      },
      target: {
        estimate: '-1% per year',
        type: 'year_over_year',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 116.07842538195784,
            physical: 9.402297959025259e-8,
          },
        },
        end: {
          date: '2062-09-19',
          dailyImpact: {
            points: 66.46046393512724,
            physical: 5.383266376649602e-8,
          },
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'annual_sufficient',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 116.07842538195784,
            physical: 9.402297959025259e-8,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 116.07842538195784,
            physical: 9.402297959025259e-8,
          },
        },
        totalImpact: {
          points: 42484.703689796566,
          physical: 0.00003441241053003245,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 100.52420549266931,
              physical: 8.142413450443542e-8,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 100.52420549266931,
              physical: 8.142413450443542e-8,
            },
          },
          totalImpact: {
            points: 36691.33500482431,
            physical: 0.00002971980909411893,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 94.97325713069279,
              physical: 7.692789239202486e-8,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 94.97325713069279,
              physical: 7.692789239202486e-8,
            },
          },
          totalImpact: {
            points: 34760.21210983356,
            physical: 0.0000281556086154811,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 68.77971954761587,
              physical: 5.57112499240874e-8,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 68.77971954761587,
              physical: 5.57112499240874e-8,
            },
          },
          totalImpact: {
            points: 25104.597634879792,
            physical: 0.0000203346062222919,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2062-09-19',
      },
      yAxisMaxValue: {
        points: 116.07842538195784,
        physical: 9.402297959025259e-8,
      },
      estimate: {
        type: 'sufficient',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 68.77971954761587,
            physical: 5.57112499240874e-8,
          },
        },
        end: {
          date: '2030-05-13',
          dailyImpact: {
            points: 0.0193189165994454,
            physical: 0,
          },
        },
      },
    },
    {
      id: 'pef_human_toxicity_non_cancer',
      name: 'Human Toxicity, non-cancer',
      unit: 'CTU',
      isToxicityRelated: true,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 182.28135436127735,
          physical: 0.0000012753354584268152,
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 307.6333071713024,
            physical: 0.000002152363121304608,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 307.6333071713024,
            physical: 0.000002152363121304608,
          },
        },
        totalImpact: {
          points: 112593.79042469668,
          physical: 0.0007877649023974865,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 266.4112102203284,
              physical: 0.000001863951823854576,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 266.4112102203284,
              physical: 0.000001863951823854576,
            },
          },
          totalImpact: {
            points: 97240.09173041987,
            physical: 0.0006803424157069203,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 251.699978594702,
              physical: 0.0000017610243719764979,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 251.699978594702,
              physical: 0.0000017610243719764979,
            },
          },
          totalImpact: {
            points: 92122.19216566093,
            physical: 0.0006445349201433982,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 182.28135436127735,
              physical: 0.0000012753354584268152,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 182.28135436127735,
              physical: 0.0000012753354584268152,
            },
          },
          totalImpact: {
            points: 66532.69434186621,
            physical: 0.00046549744232578753,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 307.6333071713024,
        physical: 0.000002152363121304608,
      },
    },
    {
      id: 'pef_ionising_radiation',
      name: 'Ionising radiation, human health',
      unit: 'kBq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 59.69947819755994,
          physical: 5.02857880226952,
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 100.75384824010219,
            physical: 8.48665148848765,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 100.75384824010219,
            physical: 8.48665148848765,
          },
        },
        totalImpact: {
          points: 36875.908455877405,
          physical: 3106.1144447864804,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 87.25308351951068,
              physical: 7.3494613263939135,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 87.25308351951068,
              physical: 7.3494613263939135,
            },
          },
          totalImpact: {
            points: 31847.375484621396,
            physical: 2682.5533841337783,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 82.43496674190182,
              physical: 6.9436239451262605,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 82.43496674190182,
              physical: 6.9436239451262605,
            },
          },
          totalImpact: {
            points: 30171.197827536063,
            physical: 2541.3663639162114,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 59.69947819755994,
              physical: 5.02857880226952,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 59.69947819755994,
              physical: 5.02857880226952,
            },
          },
          totalImpact: {
            points: 21790.309542109375,
            physical: 1835.4312628283747,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 100.75384824010219,
        physical: 8.48665148848765,
      },
    },
    {
      id: 'pef_land_use',
      name: 'Land Use',
      unit: 'Pt',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 125.2797177136185,
          physical: 1293.0289801074975,
        },
      },
      delta: {
        raw: -40.747198007471965,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 211.43256268187395,
            physical: 2182.224192993527,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 211.43256268187395,
            physical: 2182.224192993527,
          },
        },
        totalImpact: {
          points: 77384.31794156587,
          physical: 798694.0546356309,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 183.1011258891347,
              physical: 1889.8115863109574,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 183.1011258891347,
              physical: 1889.8115863109574,
            },
          },
          totalImpact: {
            points: 66831.91094953417,
            physical: 689781.2290034995,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 172.9902785578969,
              physical: 1785.4561579037952,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 172.9902785578969,
              physical: 1785.4561579037952,
            },
          },
          totalImpact: {
            points: 63314.44195219027,
            physical: 653476.953792789,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 125.2797177136185,
              physical: 1293.0289801074975,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 125.2797177136185,
              physical: 1293.0289801074975,
            },
          },
          totalImpact: {
            points: 45727.09696547075,
            physical: 471955.5777392366,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 211.43256268187395,
        physical: 2182.224192993527,
      },
    },
    {
      id: 'pef_ozone_depletion',
      name: 'Ozone Depletion',
      unit: 'kg CFC11 eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 4.384763015829715,
          physical: 0.0000036376426896495873,
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 7.400093950633167,
            physical: 0.0000061391910041795265,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 7.400093950633167,
            physical: 0.0000061391910041795265,
          },
        },
        totalImpact: {
          points: 2708.434385931739,
          physical: 0.0022469439075297067,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 6.408499792366505,
              physical: 0.000005316554700257088,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 6.408499792366505,
              physical: 0.000005316554700257088,
            },
          },
          totalImpact: {
            points: 2339.102424213774,
            physical: 0.001940542465593837,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 6.054622323245317,
              physical: 0.000005022974457965065,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 6.054622323245317,
              physical: 0.000005022974457965065,
            },
          },
          totalImpact: {
            points: 2215.991770307786,
            physical: 0.0018384086516152138,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 4.384763015829715,
              physical: 0.0000036376426896495873,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 4.384763015829715,
              physical: 0.0000036376426896495873,
            },
          },
          totalImpact: {
            points: 1600.4385007778458,
            physical: 0.0013277395817220994,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 7.400093950633167,
        physical: 0.0000061391910041795265,
      },
    },
    {
      id: 'pef_ozone_formation',
      name: 'Photochemical ozone formation, human health',
      unit: 'kg NMVOC eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 82.00606249444269,
          physical: 0.07009836657989897,
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 138.40031143975932,
            physical: 0.11830388474917795,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 138.40031143975932,
            physical: 0.11830388474917795,
          },
        },
        totalImpact: {
          points: 50654.513986951904,
          physical: 43.29922181819913,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 119.8550144149547,
              physical: 0.10245145884754464,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 119.8550144149547,
              physical: 0.10245145884754464,
            },
          },
          totalImpact: {
            points: 43747.08026145847,
            physical: 37.39478247935379,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 113.23661845071214,
              physical: 0.09679408752205468,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 113.23661845071214,
              physical: 0.09679408752205468,
            },
          },
          totalImpact: {
            points: 41444.602352960654,
            physical: 35.426636033072015,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 82.00606249444269,
              physical: 0.07009836657989897,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 82.00606249444269,
              physical: 0.07009836657989897,
            },
          },
          totalImpact: {
            points: 29932.212810471585,
            physical: 25.585903801663125,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 138.40031143975932,
        physical: 0.11830388474917795,
      },
    },
    {
      id: 'pef_particulate_matter',
      name: 'Particulate Matter',
      unit: 'disease inc.',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 257.58076439236567,
          physical: 0.0000017115523097543478,
        },
      },
      delta: {
        raw: -40.74719800747199,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 434.714909220407,
            physical: 0.0000028885592785471655,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 434.714909220407,
            physical: 0.0000028885592785471655,
          },
        },
        totalImpact: {
          points: 159105.65677466895,
          physical: 0.0010572126959482626,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 376.464194111919,
              physical: 0.0000025014995296409697,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 376.464194111919,
              physical: 0.0000025014995296409697,
            },
          },
          totalImpact: {
            points: 137409.43085085045,
            physical: 0.0009130473283189539,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 355.67583481669664,
              physical: 0.000002363366682447681,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 355.67583481669664,
              physical: 0.000002363366682447681,
            },
          },
          totalImpact: {
            points: 130177.35554291095,
            physical: 0.0008649922057758512,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 257.58076439236567,
              physical: 0.0000017115523097543478,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 257.58076439236567,
              physical: 0.0000017115523097543478,
            },
          },
          totalImpact: {
            points: 94016.97900321348,
            physical: 0.0006247165930603369,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 434.714909220407,
        physical: 0.0000028885592785471655,
      },
    },
    {
      id: 'pef_resource_use_fossils',
      name: 'Resource use, fossils',
      unit: 'MJ',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 323.45946259713065,
          physical: 252.71926557870123,
        },
      },
      delta: {
        raw: -40.74719800747199,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 545.8973275861621,
            physical: 426.5102359388338,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 545.8973275861621,
            physical: 426.5102359388338,
          },
        },
        totalImpact: {
          points: 199798.42189653532,
          physical: 156102.74635361315,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 472.7484453342679,
              physical: 369.35892661502487,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 472.7484453342679,
              physical: 369.35892661502487,
            },
          },
          totalImpact: {
            points: 172553.18254700777,
            physical: 134816.00821448409,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 446.64326802504183,
              physical: 348.9629203135913,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 446.64326802504183,
              physical: 348.9629203135913,
            },
          },
          totalImpact: {
            points: 163471.4360971653,
            physical: 127720.42883477442,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 323.45946259713065,
              physical: 252.71926557870123,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 323.45946259713065,
              physical: 252.71926557870123,
            },
          },
          totalImpact: {
            points: 118062.7038479527,
            physical: 92242.53193622595,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 545.8973275861621,
        physical: 426.5102359388338,
      },
    },
    {
      id: 'pef_resource_use_minerals',
      name: 'Resource use, minerals & metals',
      unit: 'kg Sb eq',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 168.27043256638797,
          physical: 0.0001417987410205929,
        },
      },
      delta: {
        raw: -40.74719800747199,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 283.9873000227187,
            physical: 0.00023931145237397248,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 283.9873000227187,
            physical: 0.00023931145237397248,
          },
        },
        totalImpact: {
          points: 103939.35180831506,
          physical: 0.08758799156887392,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 245.93370913549012,
              physical: 0.00020724431379932808,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 245.93370913549012,
              physical: 0.00020724431379932808,
            },
          },
          totalImpact: {
            points: 89765.80383445388,
            physical: 0.07564417453675475,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 232.35324547313346,
              physical: 0.00019580027921506837,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 232.35324547313346,
              physical: 0.00019580027921506837,
            },
          },
          totalImpact: {
            points: 85041.28784316685,
            physical: 0.07166290219271503,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 168.27043256638797,
              physical: 0.0001417987410205929,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 168.27043256638797,
              physical: 0.0001417987410205929,
            },
          },
          totalImpact: {
            points: 61418.707886731616,
            physical: 0.05175654047251641,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 283.9873000227187,
        physical: 0.00023931145237397248,
      },
    },
    {
      id: 'pef_water_scarcity',
      name: 'Water Scarcity',
      unit: 'm3 depriv.',
      isToxicityRelated: false,
      latestDailyImpact: {
        name: 'Latest historical daily impact',
        dailyImpact: {
          points: 126.7821378811516,
          physical: 17.086103409109693,
        },
      },
      delta: {
        raw: -40.74719800747198,
        formatted: '41',
        display: '-41%',
        type: 'lower',
      },
      targetHint: {
        type: 'unknown_no_target',
      },
      baseline: {
        name: '2019/2020 Baseline',
        start: {
          date: '2019-01-01',
          dailyImpact: {
            points: 213.96817301085483,
            physical: 28.83594165093544,
          },
        },
        end: {
          date: '2020-01-01',
          dailyImpact: {
            points: 213.96817301085483,
            physical: 28.83594165093544,
          },
        },
        totalImpact: {
          points: 78312.35132197286,
          physical: 10553.95464424237,
        },
        days: 366,
      },
      reports: [
        {
          name: 'Historical: 2020-01-02/2020-12-31',
          start: {
            date: '2020-01-02',
            dailyImpact: {
              points: 185.29697074937545,
              physical: 24.971997290237244,
            },
          },
          end: {
            date: '2020-12-31',
            dailyImpact: {
              points: 185.29697074937545,
              physical: 24.971997290237244,
            },
          },
          totalImpact: {
            points: 67633.39432352202,
            physical: 9114.779010936594,
          },
          days: 365,
        },
        {
          name: 'Historical: 2021-01-01/2022-01-01',
          start: {
            date: '2021-01-01',
            dailyImpact: {
              points: 175.06486882706307,
              physical: 23.593043168947176,
            },
          },
          end: {
            date: '2022-01-01',
            dailyImpact: {
              points: 175.06486882706307,
              physical: 23.593043168947176,
            },
          },
          totalImpact: {
            points: 64073.741990705064,
            physical: 8635.053799834666,
          },
          days: 366,
        },
        {
          name: 'Historical: 2022-01-02/2023-01-01',
          start: {
            date: '2022-01-02',
            dailyImpact: {
              points: 126.7821378811516,
              physical: 17.086103409109693,
            },
          },
          end: {
            date: '2023-01-01',
            dailyImpact: {
              points: 126.7821378811516,
              physical: 17.086103409109693,
            },
          },
          totalImpact: {
            points: 46275.48032662033,
            physical: 6236.427744325038,
          },
          days: 365,
        },
      ],
      xAxis: {
        startDate: '2019-01-01',
        endDate: '2033-01-01',
      },
      yAxisMaxValue: {
        points: 213.96817301085483,
        physical: 28.83594165093544,
      },
    },
  ],
};
