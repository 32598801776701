import { useEffect, useRef } from 'react';
import Sunburst from 'sunburst-chart';
import './style.css';
import {
  Component,
  ImpactStageMatrix,
  InternalComponent,
  LifecycleStageImpact,
  MatrixComponent,
  MatrixStage,
  ProductWithAmount,
} from '../../../api';
import { simplify, roundToShort } from '../../../Root/Main/shared';
import { lifeCycleStagesColorRgbaSchema } from '../shared';

interface Props {
  data: InternalComponent[];
  size: number;
  selectedCategory?: ImpactStageMatrix;
  showPhysicalImpact?: boolean;
  selectedCategoryPhysicalUnit?: string;
  onlySharePercent?: boolean;
  renderRelevantProduct?: boolean;
  products?: ProductWithAmount[];
}

export const convertFromLifecycleStageImpacts = (stages: LifecycleStageImpact[]): InternalComponent[] => {
  let index = 0;

  const convertChild = (components: Component[], stageName: string, index: number): InternalComponent[] => {
    index = index + 1;

    return components.map((component) => ({
      ...component,
      value: component.absImpactValue,
      sharePercent: component.absImpactSharePercent,
      color: lifeCycleStagesColorRgbaSchema(1 - index / 10)[stageName],
      children: convertChild(component.components, stageName, index),
      relevantProduct: component.components.length === 0 ? component.id : undefined,
      isMajorStageOrImpact: false,
    }));
  };

  return stages.map((impact) => ({
    ...impact,
    value: impact.absImpactValue,
    sharePercent: impact.absImpactSharePercent,
    color: lifeCycleStagesColorRgbaSchema(0.9)[impact.name],
    children: convertChild(impact.components, impact.name, index),
    isMajorStageOrImpact: impact.isMajorImpact,
  }));
};

export const convertFromImpactStagesMatrix = (stages: MatrixStage[]): InternalComponent[] => {
  let index = 0;

  const convertIntoSunburstChildren = (components: MatrixComponent[], stageName: string, index: number): InternalComponent[] => {
    index = index + 1;

    return components.map((component) => {
      return {
        ...component,
        value: component.absPhysicalValue,
        sharePercent: component.absSharePercent,
        color: lifeCycleStagesColorRgbaSchema(1 - index / 10)[stageName],
        children: convertIntoSunburstChildren(component.components, stageName, index),
        relevantProduct: component.components.length === 0 ? component.id : undefined,
        isMajorStageOrImpact: false,
      };
    });
  };

  return stages.map((stage) => {
    return {
      ...stage,
      value: stage.absPhysicalValue,
      sharePercent: stage.absSharePercent,
      color: lifeCycleStagesColorRgbaSchema(0.9)[stage.name],
      children: convertIntoSunburstChildren(stage.components, stage.name, index),
      isMajorStageOrImpact: stage.isMajorStage,
    };
  });
};

const convertIntoSunburstData = (data: InternalComponent[]) => {
  const convertIntoSunburstChildren = (data: InternalComponent[], layer: number): any => {
    const childLayer = layer + 1;
    return data.map((component) => ({
      ...component,
      value: component.children.length === 0 ? component.value : undefined,
      strokeColor: component.isMajor && component.children.length === 0 ? '#fbbf23' : '#ffffff50',
      children: convertIntoSunburstChildren(component.children, childLayer),
      relevantProduct: component.children.length === 0 ? component.relevantProduct : undefined,
    }));
  };

  return {
    name: '',
    color: '#ffffff00',
    children: data.map((stage) => ({
      ...stage,
      value: stage.children.length === 0 ? stage.value : undefined,
      children: convertIntoSunburstChildren(stage.children, 0),
      strokeColor: stage.isMajorStageOrImpact ? '#fbbf23' : '#ffffff50',
    })),
  };
};

export const SunburstChart = (props: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = chartRef.current;

    const sunburst = Sunburst();

    sunburst(chartRef.current!)
      .centerRadius(0.25)
      .excludeRoot(true)
      .labelOrientation('auto')
      .data(convertIntoSunburstData(props.data))
      .label('name')
      .size('value')
      .color('color')
      .strokeColor('strokeColor')
      .width(props.size)
      .height(props.size)
      .onClick((node) => sunburst.focusOnNode(node))
      .showTooltip((v) => v.name !== '')
      .tooltipContent(
        (d, node) =>
          `
          <div class='tooltip-wrapper'>
            ${
              props.renderRelevantProduct &&
              node.data.relevantProduct !== undefined &&
              !['other_majors', 'other'].includes(node.data.relevantProduct)
                ? `<div class='tooltip-content'>
                    <span class='tooltip-text-highlight font-semibold'>
                      ${(() => {
                        const product = props.products?.find((product) => product.id === node.data.relevantProduct);
                        return `${product?.name} (${product?.amount.value}${product?.amount.unit.name})`;
                      })()}
                    </span>
                  </div>`
                : ''
            }
            ${
              !props.showPhysicalImpact && !props.onlySharePercent
                ? `<div class='tooltip-content flex justify-between gap-4'>
                <div>
                  Impact:
                </div>
                <div class='tooltip-text-highlight'><span class='font-semibold'>${simplify(
                  node.data.impactPoints,
                )}</span> Impact points</div>
              </div>`
                : ''
            }
            ${
              props.showPhysicalImpact && !props.onlySharePercent
                ? `<div class='tooltip-content flex justify-between gap-4'>
                    <div>Physical Value:</div>
                    <div>
                      <span class='tooltip-text-highlight font-semibold'>
                        ${simplify(node.value)}
                      </span>
                      ${props.selectedCategoryPhysicalUnit ? props.selectedCategoryPhysicalUnit : ''}
                    </div>
                  </div>`
                : ''
            }
            <div class='tooltip-content flex gap-4 justify-between'>
              <div>Contribution to total:</div>
              <div><span class='font-semibold'>${roundToShort(
                node.data.absSharePercent ? node.data.absSharePercent : node.data.sharePercent,
              )}</span>%</div>
            </div>
          </div>`,
      );

    return () => {
      ref!.innerHTML = '';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCategory, props.showPhysicalImpact]);

  return <div className='w-full flex justify-center items-center' ref={chartRef} />;
};
