import { ImpactTracking, TrackingPoint } from '../../../../api';

export const trackingDataV2 = (numberOfWorkspaces: number): { impacts: ImpactTracking[] }[] => {
  const multiplier = [...[1, 0.1, 0.27, 0.05, 0.09], ...new Array(numberOfWorkspaces).fill(0.01)];

  return new Array(numberOfWorkspaces)
    .fill({
      impacts: [
        {
          id: 'overall',
          name: 'Total environmental impact',
          unit: 'Impact points',
          isToxicityRelated: false,
          latestDailyImpact: {
            name: 'Latest historical daily impact',
            dailyImpact: {
              points: 1511701.4541700971,
            },
          },
          target: {
            estimate: '-25% per year',
            type: 'year_over_year',
            start: {
              date: '2019-01-01',
              dailyImpact: {
                points: 4079332.764090843,
              },
              totalPoints: 1493035791.6572485, // TODO: not in api. have to be added
            },
            end: {
              date: '2022-12-30',
              dailyImpact: {
                points: 0,
              },
              totalPoints: 0, // TODO: not in api. have to be added
            },
          },
          delta: {
            raw: -62.942433442126685,
            formatted: '63',
            display: '-63%',
            type: 'lower',
          },
          targetHint: {
            type: 'annual_insufficient',
          },
          baseline: {
            name: '2018/2019 Baseline',
            start: {
              date: '2018-01-01',
              dailyImpact: {
                points: 4079332.764090843,
              },
            },
            end: {
              date: '2019-01-01',
              dailyImpact: {
                points: 4079332.764090843,
              },
            },
            totalImpact: {
              points: 1493035791.6572485,
            },
            days: 366,
          },
          reports: [
            {
              name: 'Historical: 2019-01-01/2019-04-01',
              start: {
                date: '2019-01-01',
                dailyImpact: {
                  points: 3896659.676117414,
                },
              },
              end: {
                date: '2019-04-01',
                dailyImpact: {
                  points: 3896659.676117414,
                },
              },
              totalImpact: {
                points: 354596030.5266847,
              },
              days: 91,
            },
            {
              name: 'Historical: 2019-04-02/2019-10-01',
              start: {
                date: '2019-04-02',
                dailyImpact: {
                  points: 3836613.201196169,
                },
              },
              end: {
                date: '2019-10-01',
                dailyImpact: {
                  points: 3836613.201196169,
                },
              },
              totalImpact: {
                points: 702100215.8188989,
              },
              days: 183,
            },
            {
              name: 'Historical: 2019-10-02/2020-06-01',
              start: {
                date: '2019-10-02',
                dailyImpact: {
                  points: 4186706.7683551074,
                },
              },
              end: {
                date: '2020-06-01',
                dailyImpact: {
                  points: 4186706.7683551074,
                },
              },
              totalImpact: {
                points: 1021556451.4786462,
              },
              days: 244,
            },
            {
              name: 'Historical: 2020-07-01/2020-11-30',
              start: {
                date: '2020-07-01',
                dailyImpact: {
                  points: 4184153.0288971337,
                },
              },
              end: {
                date: '2020-11-30',
                dailyImpact: {
                  points: 4184153.0288971337,
                },
              },
              totalImpact: {
                points: 640175413.4212614,
              },
              days: 153,
            },
            {
              name: 'Historical: 2021-01-01/2022-01-01',
              start: {
                date: '2021-01-01',
                dailyImpact: {
                  points: 2623668.8711660705,
                },
              },
              end: {
                date: '2022-01-01',
                dailyImpact: {
                  points: 2623668.8711660705,
                },
              },
              totalImpact: {
                points: 960262806.8467818,
              },
              days: 366,
            },
            {
              name: 'Historical: 2022-01-02/2022-06-01',
              start: {
                date: '2022-01-02',
                dailyImpact: {
                  points: 3084281.6473707105,
                },
              },
              end: {
                date: '2022-06-01',
                dailyImpact: {
                  points: 3084281.6473707105,
                },
              },
              totalImpact: {
                points: 465726528.7529773,
              },
              days: 151,
            },
            {
              name: 'Historical: 2022-06-02/2022-12-31',
              start: {
                date: '2022-06-02',
                dailyImpact: {
                  points: 2164644.2591794757,
                },
              },
              end: {
                date: '2022-12-31',
                dailyImpact: {
                  points: 2164644.2591794757,
                },
              },
              totalImpact: {
                points: 461069227.2052283,
              },
              days: 213,
            },
            {
              name: 'Historical: 2023-01-01/2024-01-01',
              start: {
                date: '2023-01-01',
                dailyImpact: {
                  points: 1511701.4541700971,
                },
              },
              end: {
                date: '2024-01-01',
                dailyImpact: {
                  points: 1511701.4541700971,
                },
              },
              totalImpact: {
                points: 553282732.2262555,
              },
              days: 366,
            },
          ],
          xAxis: {
            startDate: '2018-01-01',
            endDate: '2034-01-01',
          },
          yAxisMaxValue: {
            points: 4186706.7683551074,
          },
          estimate: {
            type: 'insufficient',
            start: {
              date: '2024-01-01',
              dailyImpact: {
                points: 1511701.4541700971,
              },
              totalPoints: 553282732.2262555, // TODO: not in api. have to be added
            },
            end: {
              date: '2031-12-11',
              dailyImpact: {
                points: 0,
              },
              totalPoints: 0, // TODO: not in api. have to be added
            },
          },
        },
      ],
    })
    .map((data, index): { impacts: ImpactTracking[] } => {
      return {
        impacts: data.impacts.map((impact: ImpactTracking) => {
          return {
            ...impact,
            reports: impact.reports.map((report: TrackingPoint) => {
              return {
                ...report,
                totalImpact: {
                  ...report.totalImpact,
                  points: report.totalImpact.points * multiplier[index],
                },
              };
            }),
            latestDailyImpact: {
              ...impact.latestDailyImpact,
              dailyImpact: {
                ...impact.latestDailyImpact.dailyImpact,
                points: impact.latestDailyImpact.dailyImpact.points * multiplier[index],
              },
            },
            baseline: {
              ...impact.baseline,
              totalImpact: {
                ...impact.baseline?.totalImpact,
                points: impact.baseline ? impact.baseline?.totalImpact.points * multiplier[index] : undefined,
              },
            },
            yAxisMaxValue: {
              ...impact.yAxisMaxValue,
              points: impact.yAxisMaxValue.points * multiplier[index],
            },
          };
        }),
      };
    });
};
