import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Facility,
  FacilityType,
  ImpactDelta,
  ModellingPayload,
  NodeType,
  ProductModelV3,
  ProductionNode,
  ProductionStepNode,
  facilityOptions,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { Toggle } from '../../../../components/Toggle';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { defaultGrids } from '../Details/dataModel';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { OriginalAwareField } from './OriginalAwareField';
import { getProductionFacilities, newNodeId } from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';
import { OriginalAwareProvider } from './useOriginalAware';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data?: ProductionNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<ProductionNode>;
  onOpenChange?: (open: boolean) => void;
}>;

export const ProductionFacilityDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm
      size='narrow'
      formRef={formRef}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add production facility'}
      body={
        <Body
          payload={props.payload}
          productFormik={formik}
          formRef={formRef}
          edit={!!props.data}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      onOpenChange={props.onOpenChange}
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Where is your product being made? Production facilities are the places where ingredients are assembled into your end product and
            everything is then packaged for distribution. You may have one or many for different steps of the production process.
          </div>
          <div>
            Make sure you specify which facility is the ‘final production facility’ or the one resulting in your product all packaged and
            ready for distribution. You could have many of course, that works too.
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.Production,
        flagged: false,
        edges: new Array<string>(),
        steps: new Array<ProductionStepNode>(),
        facility: undefined as any as Facility,
        finalFacility: false,
        layoutGrid: defaultGrids.productionFacility,
      }}
      data={props.data}
      validationSchema={yup.object().shape({
        facility: yup.object().required(),
      })}
      entityName='facility'
      onSave={props.onSave}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const { payload, productFormik } = props;
  const formik = useFormikContext<ProductionNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  useInteractiveImpact<ProductionNode>({
    payload,
    productFormik,
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  return (
    <OriginalAwareProvider nodeId={formik.values.id} payload={payload}>
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Select a facility</div>
          <div className='w-2/3'>
            <Field name='facility'>
              {(model: FieldProps<Facility>) => (
                <SelectV3<Facility>
                  autoFocus
                  model={model}
                  disabled={props.edit}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    setNewFacilityName(input);
                    getFacilities({
                      contains: input,
                      type: FacilityType.Production,
                    }).ok(({ facilities }) =>
                      callback(
                        facilities.filter(
                          ({ id }) =>
                            !getProductionFacilities(props.productFormik)
                              .filter(({ id }) => id !== formik.values.id)
                              .some(({ facility }) => id === facility.id),
                        ),
                      ),
                    );
                  }}
                  menuFooter={
                    !newFacilityForm && (
                      <SelectFooterAddButton
                        onClick={() => setNewFacilityForm(true)}
                        name={newFacilityName}
                        label={facilityOptions.find(({ id }) => id === FacilityType.Production)!.name.toLowerCase()}
                      />
                    )
                  }
                />
              )}
            </Field>
          </div>
        </div>
        {newFacilityForm && (
          <NewFacilityForm
            name={newFacilityName}
            type={FacilityType.Production}
            formRef={props.formRef}
            onCancel={() => setNewFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', newFacility);
              setNewFacilityForm(false);
            }}
          />
        )}
        {!props.edit && (
          <div className='p-4 rounded-lg bg-[#E8EAF5] text-neutral-900 border border-brand'>
            Please remember to fill out the Production Facility Board by adding all the processes happening in this facility.
          </div>
        )}
        <OriginalAwareField name='finalFacility'>
          {(model: FieldProps) => (
            <div className='flex flex-col gap-4 text-neutral-900'>
              <div className='flex flex-col gap-1'>
                <div className='font-semibold'>Final production facility</div>
                <div>
                  Please let us know if this is the last facility in your entire production process, the one resulting in the final product
                  ready to be distributed to retailers and consumers.
                </div>
              </div>
              <Toggle model={model} />
            </div>
          )}
        </OriginalAwareField>
        <div className='self-start flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
          <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
          Please note that only facilities set to “final” can be linked to facilities in the distribution section.
        </div>
      </div>
    </OriginalAwareProvider>
  );
};
