import {
  Amount,
  Facility,
  Impact,
  ImpactDelta,
  ImpactId,
  LifeCycleStageName,
  MajorProcess,
  MethodFactors,
  MultipleProductsGhgAnalysis,
  Pagination,
  ProductType,
  request,
  WarningType,
} from '.';
import { CreateResponse, Entity, ImpactStageMatrix, Interpretation, LifecycleStageImpact } from './shared';

export enum ManufacturingCycle {
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export enum TargetHintType {
  ForecastAnnualReachable = 'forecast_annual_reachable',
  ForecastLongTermReachable = 'forecast_long_term_reachable',
  ForecastAnnualReachedInHistory = 'forecast_annual_reached_in_history',
  LongTermSufficient = 'long_term_sufficient',
  LongTermInsufficient = 'long_term_insufficient',
  LongTermMeet = 'long_term_meet',
  AnnualSufficient = 'annual_sufficient',
  AnnualInsufficient = 'annual_insufficient',
  UnknownNoTarget = 'unknown_no_target',
  UnknownNoBaseline = 'unknown_no_baseline',
  UnknownNoHistorical = 'unknown_no_historical',
  LongTermOnTrack = 'long_term_on_track',
}

export enum ReportType {
  Historical = 'historical',
  Forecast = 'forecast',
  Baseline = 'baseline',
  ProductReport = 'product_report',
}

export enum TargetType {
  LONG_TERM = 'long_term',
  YEAR_OVER_YEAR = 'year_over_year',
  DISABLED = 'disabled',
}

export interface ReportSearchParams {
  pageSize?: number;
  pageToken?: string;
}

export interface ProductWithAmount extends Entity {
  count: number;
  overallImpact: number;
  sku: string;
  imageUrl: string;
  amount: Amount;
  impactPoints: number;
  sharePercent: number;
  parentId?: string;
  parentName?: string;
  author: string;
  isMajor: boolean;
  productType: ProductType;
}

export interface BasicProductInfo {
  id?: string;
  name: string;
  value: number;
  impactValue: number;
  impactPoints: number;
}

export interface ImpactByProducts {
  impactId: string;
  impactName: string;
  products: BasicProductInfo[];
  unit: string;
}

export interface LifecycleStagesByProduct {
  products: BasicProductInfo[];
  stageName: LifeCycleStageName;
}

export interface HighestImpactProducts extends BasicProductInfo {
  skuId: string;
  units: number;
}

interface Analysis {
  highestImpactProducts: HighestImpactProducts[];
  lowestImpactProducts: BasicProductInfo[];
  lifecycleStageImpacts: LifecycleStageImpact[];
  impactStageMatrix: ImpactStageMatrix[];
  impactsByProduct: ImpactByProducts[];
  lifecycleStagesByProduct: LifecycleStagesByProduct[];
  majorProcesses: MajorProcess[];
  impacts: Impact[];
}

export interface ManufacturingImprovementTips {
  id: string;
  text: string;
  iconId: string;
  products: ProductWithAmount[];
}

export interface ProductImprovementTips {
  text: string;
  iconId: string;
}

export interface ManufacturingPefReport {
  id: string;
  state: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  productCount: number;
  totalUnitCount: number;
  overallImpact: number;
  impactPoints: number;
  interpretation: Interpretation;
  products: ProductWithAmount[];
  analysis: Analysis;
  excludedProductCount: number;
  improvementTips: ManufacturingImprovementTips[];
  metadata: {
    methodFactors: MethodFactors;
    methodologyVersion: string;
    webAppVersion: string;
  };
}

export interface ForecastTrackingData {
  name: string;
  days: number;
  dailyImpact: {
    points: number;
    physical?: number;
  };
  totalImpact: {
    points: number;
    physical?: number;
  };
}

export interface DateWithValues {
  date: string;
  dailyImpact: {
    points: number;
    physical?: number;
  };
  totalPoints: number;
}

export interface TrackingPoint {
  name: string;
  days: number;
  start: DateWithValues;
  end: DateWithValues;
  totalImpact: {
    points: number;
    physical?: number;
  };
}

export interface TrackingTarget {
  type: TargetType;
  estimate: string;
  start: DateWithValues;
  end: DateWithValues;
}

export interface ImpactTracking {
  id: ImpactId;
  name: string;
  baseline?: TrackingPoint; // if no baseline set
  delta?: ImpactDelta; // if no baseline set
  forecast: ForecastTrackingData;
  isToxicityRelated: boolean;
  latestDailyImpact: {
    dailyImpact: {
      points: number;
      physical?: number; // missed for eco toxicity related and overall impact
    };
    totalImpact: number;
    name: string;
  };
  reports: TrackingPoint[];
  estimate?: {
    type: 'sufficient' | 'insufficient' | 'not_specified';
    start: DateWithValues & { totalPoints: number };
    end: DateWithValues & { totalPoints: number };
  };
  target?: TrackingTarget; // if no target set
  targetHint?: {
    type: TargetHintType;
    impactValueDiff?: {
      points: number;
      physical?: number;
    };
  };
  targetBadge?: {
    name: string;
    point: DateWithValues;
  };
  targetDateBadge: {
    name: string | '';
    point: DateWithValues;
  };
  xAxis: {
    startDate: string;
    endDate: string;
  };
  yAxisMaxValue: {
    points: number;
    physical?: number;
  };
  unit: string;
}

export interface Tracking {
  impacts: ImpactTracking[];
}

export interface MultipleProductsFacility {
  facility: Facility;
  productIds: string[];
  productsCount: number;
}

export interface HistoricalGhgReport extends ManufacturingGhgReport {}

export interface HistoricalPefReport extends ManufacturingPefReport {
  name: string;
  startDate: string;
  endDate: string;
  tracking: Tracking;
  impacts: Impact[];
  warnings: { id: WarningType }[];
}

export interface ForecastReport extends ManufacturingPefReport {
  name: string;
  cycle: string;
  tracking: Tracking;
  warnings: { id: WarningType }[];
}

export interface BaselinePreview {
  id: string;
  author: string;
  createdAt: string;
  productCount: number;
  totalUnitCount: number;
  startDate: string;
  endDate: string;
  overallImpact: number;
  impactPoints: number;
  impacts: BaselineImpact[];
}

export interface BaselineImpact {
  id: string;
  name: string;
  weightedNormalisedValue: number;
  impactPoints: number;
  physicalValue: number;
  isToxicityRelated: boolean;
  unit: string;
}

export interface ProductModel {
  id: string;
  skuId: string;
  name: string;
  author: string;
  parentId: string;
  count: number;
}

export interface ManufacturingProduct {
  id: string;
  skuId: string;
  name: string;
  count: number;
  selected: boolean;
  resolved: boolean;
  models: ProductModel[];
}

export interface CreateManufacturingReportPayload {
  name: string;
  cycle: ManufacturingCycle;
  products: ManufacturingProduct[];
}

export interface Manufacturing {
  baseline?: BaselinePreview;
  latestHistorical?: HistoricalPefReport;
  targets: Targets;
  tracking: Tracking;
  hasCompleteProducts: boolean;
}

export const createHistoricalReport = (payload: { startDate: Date; endDate: Date; products: { count: number; id: string }[] }) =>
  request<HistoricalPefReport>('POST', '/v3/manufacturing-history', {
    body: payload,
  });

export const createManufacturingForecast = (payload: {
  name: string;
  cycle: ManufacturingCycle;
  products: { count: number; id: string; parentId?: string }[];
}) =>
  request<CreateResponse<ForecastReport>>('POST', '/v3/manufacturing-reports', {
    body: payload,
  });

export const createManufacturingBaseline = (payload: { startDate: Date; products: { count: number; id: string }[] }) =>
  request<BaselinePefReport>('POST', '/v3/manufacturing-baseline', {
    body: payload,
  });

export const getBaselinePreview = () =>
  request<BaselinePreview & { errorCode?: string; message?: string }>('GET', '/v3/manufacturing-baseline');

export interface ManufacturingGhgReport extends Entity {
  author: string;
  analysis: MultipleProductsGhgAnalysis;
  impactPoints: number;
  overallImpact: number;
  productCount: number;
  products: ProductWithAmount[];
  createdAt: string;
  endDate: string;
  startDate: string;
  metadata: {
    methodologyVersion: string;
    webAppVersion: string;
  };
  totalUnitCount: number;
}

export interface BaselineGhgReport extends ManufacturingGhgReport {}
export interface BaselinePefReport extends ManufacturingPefReport {
  startDate: string;
  endDate: string;
  warnings?: { id: WarningType }[]; // optional since possible get baseline not set error
}

export interface TargetImpact {
  id: ImpactId;
  name: string;
  type: TargetType;
  target?: Date;
  reduction?: number | null;
  estimate: string;
  description?: string;
  isMajorCategory: boolean;
  dirty: boolean;
  method?: string;
  status: string;
  internalType: TargetType;
}

export interface Targets {
  hasCompleteProducts: boolean;
  assertImpacts?: null;
  totalTargets: number;
  targetImpacts: TargetImpact[];
}

export interface ManufacturingTargetsPayload {
  targetImpacts: {
    id: string;
    type: TargetType;
    target?: Date;
    reduction: number;
  }[];
}

interface LookupResultProduct<N, S> {
  id: S;
  skuId: string;
  name: string;
  count: N;
}

export interface LookupResult {
  resolvedProducts: LookupResultProduct<number, string>[];
  unresolvedProducts: LookupResultProduct<undefined, undefined>[];
  omittedProductCount: number;
}

export interface ReportsListPagination<T> extends Pagination {
  reports: T;
  hasCompleteProducts: boolean;
}

export const getManufacturingTargets = () => request<Targets>('GET', '/v3/manufacturing-targets');
export const updateManufacturingTarget = (payload: { id: string; type: TargetType; target?: Date; reduction: number }) =>
  request<TargetImpact>('POST', '/v3/manufacturing-targets/resolve', {
    body: payload,
  });
export const saveManufacturingTargets = (payload: ManufacturingTargetsPayload) =>
  request<Targets>('POST', '/v3/manufacturing-targets', {
    body: payload,
  });
export const getPefBaseline = () =>
  request<BaselinePefReport & { errorCode?: string; message?: string }>('GET', '/v3/manufacturing-baseline/report?lens=pef');
export const getGhgBaseline = () =>
  request<BaselineGhgReport & { errorCode?: string; message?: string }>('GET', '/v3/manufacturing-baseline/report?lens=ghg');
export const getManufacturingBaselineReportCsv = (fileName: string) =>
  request('GET', '/v3/manufacturing-baseline/report', { csv: fileName });
export const resetBaseline = () => request('DELETE', '/v3/manufacturing-baseline');
export const getForecast = (id: string) => request<ForecastReport>('GET', `/v3/manufacturing-reports/${id}`);
export const getManufacturingForecastReportCsv = (id: string, fileName: string) =>
  request('GET', `/v3/manufacturing-reports/${id}`, { csv: fileName });
export const deleteManufacturingForecast = (id: string) => request<void>('DELETE', `/v3/manufacturing-reports/${id}`);
export const getForecastReports = (params: ReportSearchParams) =>
  request<ReportsListPagination<ForecastReport[]>>('GET', '/v3/manufacturing-reports', { search: params });
export const getManufacturingHistoricalReports = (params: ReportSearchParams) =>
  request<ReportsListPagination<HistoricalPefReport[]>>('GET', '/v3/manufacturing-history', { search: params });
export const getPefHistoricalReport = (id: string) => request<HistoricalPefReport>('GET', `/v3/manufacturing-history/${id}?lens=pef`);
export const getGhgHistoricalReport = (id: string) => request<HistoricalGhgReport>('GET', `/v3/manufacturing-history/${id}?lens=ghg`);
export const getManufacturingHistoricalReportCsv = (id: string, fileName: string) =>
  request('GET', `/v3/manufacturing-history/${id}`, { csv: fileName });
export const deleteManufacturingHistoricalReport = (id: string) => request<void>('DELETE', `/v3/manufacturing-history/${id}`);
export const getManufacturingData = () => request<Manufacturing>('GET', '/v3/dashboards/manufacturing');

export const getProductsByCsvData = (payload: { file: File; reportType: 'forecast' | 'historical' | 'baseline' }) => {
  const formData = new FormData();

  formData.append('file', payload.file);

  formData.append('report', payload.reportType);

  return request<LookupResult & { errorCode?: string; message?: string }>('POST', '/v3/manufacturing-upload', { formData });
};
