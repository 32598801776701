import { offset, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Supplier, getSuppliers } from '../../../../api';
import { SelectV3 } from '../../../../components/SelectV3';
import { useLists } from '../../../../hooks/useLists';

interface Props {
  owner?: string;
  setOwner: (v?: Supplier) => void;
  location?: string;
  setLocation: (v?: string) => void;
}

export const AsyncFilters = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | undefined>(undefined);
  const [selectedCountry, setSelectedCountry] = useState<{ id: string; name: string } | undefined>(undefined);
  const lists = useLists();

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-end',
    open,
    onOpenChange: setOpen,
    middleware: [offset(8)],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  return (
    <>
      <button
        type='button'
        className={cn(
          'relative border-2 box-border bg-[#E8EAF5] text-[#330099] text-sm rounded-full flex items-center gap-2 px-4 h-full',
          selectedSupplier !== undefined || selectedCountry !== undefined ? 'border-[#330099]' : 'border-transparent',
        )}
        {...getReferenceProps({ ref: reference })}
      >
        Filters
        <FontAwesomeIcon icon={light('sliders-v')} />
        {(selectedSupplier !== undefined || selectedCountry !== undefined) && (
          <div className='absolute -top-1.5 right-0.5 flex justify-center items-center h-3.5 aspect-square rounded-full bg-[#330099] text-white text-[10px]'>
            {[selectedSupplier, selectedCountry].filter((entity) => entity !== undefined).length}
          </div>
        )}
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
              },
            })}
            className='z-10 antialiased shadow-[0_1px_10px_rgba(0,0,0,0.15)] text-neutral-900 bg-[#E8EAF5] rounded-lg p-5 flex flex-col gap-4'
          >
            <div className='font-semibold'>Filters</div>
            <div className='-mx-1 h-px bg-zinc-300' />
            <div className='flex'>
              <div className='grid grid-cols-[max-content_250px] items-center gap-x-12 gap-y-2.5 text-sm'>
                <div>Owner</div>
                <div className='flex flex-col'>
                  <SelectV3<Supplier>
                    placeholder='All'
                    value={selectedSupplier}
                    loadOptions={(value, callback) => {
                      getSuppliers({ contains: value }).ok(({ suppliers }) => callback(suppliers));
                    }}
                    onActions={{
                      add: (supplier) => {
                        setSelectedSupplier(supplier);
                        props.setOwner(supplier);
                      },
                      remove: () => {
                        setSelectedSupplier(undefined);
                        props.setOwner(undefined);
                      },
                      clear: () => {
                        setSelectedSupplier(undefined);
                        props.setOwner(undefined);
                      },
                    }}
                  />
                </div>

                <div>Country</div>
                <SelectV3
                  placeholder='All'
                  value={selectedCountry}
                  options={lists.countries}
                  onActions={{
                    add: (country) => {
                      setSelectedCountry(country);
                      props.setLocation(country.id);
                    },
                    remove: () => {
                      setSelectedCountry(undefined);
                      props.setLocation(undefined);
                    },
                    clear: () => {
                      setSelectedCountry(undefined);
                      props.setLocation(undefined);
                    },
                  }}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
