import cn from 'classnames';
import { Tag } from '../../../../../api';

export const TagBadge = ({ state }: { state: Tag }) => {
  return (
    <div
      className={cn(
        'flex justify-self-start text-[8px] font-semibold leading-none px-1.5 py-1 rounded whitespace-nowrap uppercase',
        {
          [Tag.BestMatch]: 'bg-orange-100',
          [Tag.LowQualityMatch]: 'bg-orange-100',
          [Tag.ExactMatch]: 'bg-emerald-100',
          [Tag.Unmatched]: 'bg-red-100',
          [Tag.UserInput]: 'bg-transparent',
          [Tag.Default]: 'bg-[#E8EAF5]',
        }[state],
      )}
      title={
        {
          [Tag.BestMatch]: 'Best match',
          [Tag.LowQualityMatch]: 'Low quality match',
          [Tag.ExactMatch]: 'Exact match',
          [Tag.Unmatched]: 'Unmatched',
          [Tag.UserInput]: 'User edited',
          [Tag.Default]: 'Default',
        }[state]
      }
    >
      {
        {
          [Tag.BestMatch]: 'Best match',
          [Tag.LowQualityMatch]: 'Low quality match',
          [Tag.ExactMatch]: 'Exact match',
          [Tag.Unmatched]: 'Unmatched',
          [Tag.UserInput]: 'User edited',
          [Tag.Default]: 'Default',
        }[state]
      }
    </div>
  );
};
