import cn from 'classnames';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lifeCycleStagesColorHexSchema } from './shared';

interface Props {
  renderMajor?: boolean;
  inline?: boolean;
}

export const LifeCycleStagesLegend = (props: Props) => {
  return (
    <ul
      className={cn({
        'grid grid-cols-3 gap-4': !props.inline,
        'flex justify-evenly': props.inline,
      })}
    >
      {Object.keys(lifeCycleStagesColorHexSchema).map((name, i) => {
        return (
          <li className='flex items-center gap-2' key={i}>
            <div style={{ backgroundColor: lifeCycleStagesColorHexSchema[name] }} className='h-[20px] aspect-square rounded-[5px]' />
            <div>{name}</div>
          </li>
        );
      })}
      {props.renderMajor && (
        <li className='flex items-center gap-2'>
          <div className='flex items-center justify-center h-[20px] aspect-square rounded-[5px] bg-white border-2 border-amber-400'>
            <FontAwesomeIcon className='text-amber-400 text-sm' icon={solid('seal-exclamation')} />
          </div>
          <div>Most relevant</div>
        </li>
      )}
    </ul>
  );
};
