import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { ManufacturingPefReport } from '../api';

interface Props {
  data?: ManufacturingPefReport;
}

export const useCsvByProductHref = (props: Props) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    if (props.data) {
      const rows = props.data.products.map((product) => [
        `"${product.name}"`,
        `${product.sku ?? ''}`,
        product.count,
        product.overallImpact,
        product.impactPoints,
        product.sharePercent,
      ]);

      setHref(
        URL.createObjectURL(
          new Blob(
            [
              [
                ['Product name', 'ID', 'Production volumes', 'Impact (weighted person.year)', 'Impact (impact points)', 'Contribution (%)']
                  .map((label) => `"${label}"`)
                  .join(','),
                ...[
                  ...rows,
                  ['TOTAL', '', 'sum', 'sum', 'sum', '100%'].map((column, c) =>
                    column === 'sum' ? sumBy(rows, (row) => row[c] as number) : column,
                  ),
                ].map((row) => row.join(',')),
              ].join('\n'),
            ],
            { type: 'text/csv' },
          ),
        ),
      );
    }
  }, [props.data]);

  return href;
};
