import { format } from 'date-fns';
import { CreateReport } from '../../../../components/Lists/CreateReport';
import { ReportType } from '../../../../../../../api';

interface Props {
  dateRange: { start: Date | null; end: Date | null };
  onSubmitClick: () => void;
}

export const Step4 = (props: Props) => {
  return (
    <>
      <div className='flex flex-col gap-y-4'>
        <div className='text-lg font-semibold'>Add actual manufactured quantities</div>
        <div>
          How many of each products did you manufacture over the given time period (
          <span className='font-semibold'>{format(props.dateRange.start!, 'dd/MM/yy')}</span> -{' '}
          <span className='font-semibold'>{format(props.dateRange.end!, 'dd/MM/yy')}</span>)? Add those in the table below.
        </div>
      </div>
      <CreateReport
        dataFromCsv={false}
        reportType={ReportType.Historical}
        reportTitle={`Historical Volume Report ${format(props.dateRange.start!, 'dd/MM/yy')} - ${format(props.dateRange.end!, 'dd/MM/yy')}`}
        dateRange={{ startDate: props.dateRange.start!, endDate: props.dateRange.end! }}
        onNext={props.onSubmitClick}
      />
    </>
  );
};
