import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import range from 'lodash/range';
import sumBy from 'lodash/sumBy';
import { Fragment, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Bar, BarChart, Cell, Text, Tooltip, XAxis, YAxis } from 'recharts';
import { Lens, ModellingReport, ProductReport, ProductStage, ProductType, getModellingReportV3, getProductReport } from '../../../../api';
import { GradeBadge } from '../../../../components/GradeBadge';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { RadarChart } from '../../../../components/charts/RadarChart';
import { lifeCycleStagesColorHexSchema } from '../../../../components/charts/shared';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { simplify, roundToShort } from '../../shared';

enum Layout {
  Graph,
  Table,
}

enum ImpactScale {
  Points = 'points',
  Physical = 'physical',
}

export const Compare = () => {
  const { product1Id, model1Id, product2Id, model2Id } = useParams<{
    product1Id: string;
    model1Id: string;
    product2Id: string;
    model2Id: string;
  }>();
  const [layout, setLayout] = useState(Layout.Graph);
  const [impactByCategoryScale, setImpactByCategoryScale] = useState(ImpactScale.Points);
  const [impactByLifeCycleStageScale, setImpactByLifeCycleStageScale] = useState(ImpactScale.Points);
  const [impactByLifeCycleStageCategoryId, setImpactByLifeCycleStageCategoryId] = useState('');
  const [impactByCategoryCsvHref, setImpactByCategoryCsvHref] = useState('');
  const [impactByLifeCycleStageCsvHref, setImpactByLifeCycleStageCsvHref] = useState('');
  const [majorProcessesCsvHref, setMajorProcessesCsvHref] = useState('');
  const firstIsProduct = model1Id === '-';
  const secondIsProduct = model2Id === '-';

  const product1Query = useApiQuery(getProductReport(product1Id!, Lens.Production), {
    cancel: !firstIsProduct,
  });
  const model1Query = useApiQuery(getModellingReportV3({ productId: product1Id!, modelId: model1Id! }), {
    cancel: firstIsProduct,
  });
  const product2Query = useApiQuery(getProductReport(product2Id!, Lens.Production), {
    cancel: !secondIsProduct,
  });
  const model2Query = useApiQuery(getModellingReportV3({ productId: product2Id!, modelId: model2Id! }), {
    cancel: secondIsProduct,
  });
  const firstQuery = firstIsProduct ? product1Query : model1Query;
  const secondQuery = secondIsProduct ? product2Query : model2Query;
  const waiting = firstQuery.waiting || secondQuery.waiting;

  const product1 = product1Query.data;
  const product2 = product2Query.data;
  const model1 = model1Query.data;
  const model2 = model2Query.data;

  const comparedObjects = [
    { product: product1, model: model1 },
    { product: product2, model: model2 },
  ];
  const comparedNames = [firstQuery.data, secondQuery.data].map((data) => data?.product.name!);
  const title = comparedNames.join(' vs ');

  const getProductOrModel = (product: ProductReport | undefined, model: ModellingReport | undefined) => (product ?? model)!;

  const getCalculations = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    product ? product : model!.calculations.model;

  const getImpactPoints = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getCalculations(product, model).impactPoints;

  const getGrade = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getCalculations(product, model).consumerView.overallGrade;

  const getAmount = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getProductOrModel(product, model).product.amount;

  const getCategory = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getProductOrModel(product, model).product.category?.name;

  const getConservation = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getProductOrModel(product, model).product.conservation?.requirement?.name;

  const getStage = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    ({
      [ProductStage.Production]: 'In production',
      [ProductStage.Development]: 'In development',
    }[getProductOrModel(product, model).product.stage]);

  const getImpactsByCategory = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getCalculations(product, model).analysis.impactStagesMatrix;

  const getImpactCategory = (product: ProductReport | undefined, model: ModellingReport | undefined, id: string) =>
    getImpactsByCategory(product, model).find(({ impactId }) => impactId === id);

  const getImpactsByLifeCycleStage = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getCalculations(product, model).analysis.lifecycleStageImpacts;

  const getMajorProcesses = (product: ProductReport | undefined, model: ModellingReport | undefined) =>
    getCalculations(product, model).analysis.majorProcesses;

  const impactByCategoryRows = waiting
    ? []
    : getImpactsByCategory(product1, model1).map((impact1) => ({
        ...impact1,
        comparedItems: [impact1, getImpactCategory(product2, model2, impact1.impactId)!],
      }));
  const impactByLifeCycleStageRows = waiting
    ? []
    : getImpactsByLifeCycleStage(product1, model1).map((lifeCycle) => ({
        ...lifeCycle,
        comparedItems: comparedObjects
          .map(({ product, model }) => ({
            total: getImpactsByLifeCycleStage(product, model).find(({ id }) => id === lifeCycle.id)!,
            category: getImpactsByCategory(product, model)
              .filter(({ impactId }) => impactId === impactByLifeCycleStageCategoryId)
              .flatMap(({ stages, unit }) => stages.map((stage) => ({ ...stage, unit })))
              .find(({ name }) => name === lifeCycle.name),
          }))
          .map(({ total, category }) => ({
            ...total,
            ...category,
            impactPoints: (category ?? total).impactPoints,
            isMajor: category ? category.isMajorStage : total.isMajorImpact,
            absSharePercent: category?.absSharePercent ?? total.absImpactSharePercent,
          })),
      }));
  const majorProcessesRows = waiting
    ? []
    : range(0, Math.max(...comparedObjects.map(({ product, model }) => getMajorProcesses(product, model).length))).map((index) => ({
        name: `Process #${index + 1}`,
        comparedItems: comparedObjects.map(({ product, model }) => getMajorProcesses(product, model)[index]),
      }));

  useEffect(() => {
    const rows = impactByCategoryRows.map(({ impactName, comparedItems }) => [
      `"${impactName}"`,
      ...comparedItems.flatMap((item) => [item.impactPoints, item.physicalValue, item.unit, item.absSharePercent]),
    ]);

    setImpactByCategoryCsvHref(
      URL.createObjectURL(
        new Blob(
          [
            [
              [
                'Impact category',
                ...range(0, comparedNames.length).flatMap((i) => [
                  `${comparedNames[i]} - Final env. impact (impact points)`,
                  `${comparedNames[i]} - Physical impact`,
                  `${comparedNames[i]} - Physical impact unit`,
                  `${comparedNames[i]} - Contribution (%)`,
                ]),
              ]
                .map((label) => `"${label}"`)
                .join(','),
              ...[
                ...rows,
                ['TOTAL', ...range(0, comparedNames.length).flatMap(() => ['sum', '', '', '100%'])].map((column, c) =>
                  column === 'sum' ? sumBy(rows, (row) => row[c] as number) : column,
                ),
              ].map((row) => row.join(',')),
            ].join('\n'),
          ],
          { type: 'text/csv' },
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waiting]);

  useEffect(() => {
    const rows = impactByLifeCycleStageRows.map(({ name, comparedItems }) => [
      `"${name}"`,
      ...comparedItems.flatMap((item) => [
        item.impactPoints,
        ...(impactByLifeCycleStageCategoryId ? [item.physicalValue, item.unit] : []),
        item.absSharePercent,
      ]),
    ]);

    setImpactByLifeCycleStageCsvHref(
      URL.createObjectURL(
        new Blob(
          [
            [
              [
                'Life cycle stage',
                ...range(0, comparedNames.length).flatMap((i) => [
                  `${comparedNames[i]} - Final env. impact (impact points)`,
                  ...(impactByLifeCycleStageCategoryId
                    ? [`${comparedNames[i]} - Physical impact`, `${comparedNames[i]} - Physical impact unit`]
                    : []),
                  `${comparedNames[i]} - Contribution (%)`,
                ]),
              ]
                .map((label) => `"${label}"`)
                .join(','),
              ...[
                ...rows,
                [
                  'TOTAL',
                  ...range(0, comparedNames.length).flatMap(() => [
                    'sum',
                    ...(impactByLifeCycleStageCategoryId ? ['sum', ''] : []),
                    '100%',
                  ]),
                ].map((column, c) => (column === 'sum' ? sumBy(rows, (row) => row[c] as number) : column)),
              ].map((row) => row.join(',')),
            ].join('\n'),
          ],
          { type: 'text/csv' },
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waiting, impactByLifeCycleStageCategoryId]);

  useEffect(() => {
    setMajorProcessesCsvHref(
      URL.createObjectURL(
        new Blob(
          [
            [
              [
                'Top processes',
                ...range(0, comparedNames.length).flatMap((i) => [
                  `${comparedNames[i]} - Process`,
                  `${comparedNames[i]} - Contribution (%)`,
                ]),
              ]
                .map((label) => `"${label}"`)
                .join(','),
              ...majorProcessesRows
                .map(({ name, comparedItems }) => [
                  `"${name}"`,
                  ...comparedItems.map((item) => [`"${item?.name ?? ''}"`, item?.sharePercent ?? '']),
                ])
                .map((row) => row.join(',')),
            ].join('\n'),
          ],
          { type: 'text/csv' },
        ),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waiting]);

  if (waiting) {
    return <></>;
  }

  return (
    <div className='flex flex-col text-zinc-700'>
      <Helmet title={title} />
      <div className='flex justify-center sticky top-0 z-[2] -mt-10 bg-white border-b border-gray-300 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='flex justify-between gap-6 w-full max-w-screen-xl px-12 py-6 whitespace-nowrap'>
          <div className='flex gap-4 truncate'>
            <NavLink
              to='/products'
              className='shrink-0 flex justify-center items-center w-8 aspect-square rounded-md text-lg text-brand bg-[#E8EAF5] active:scale-90'
            >
              <FontAwesomeIcon icon={solid('chevron-left')} />
            </NavLink>
            <div className='flex items-center gap-2 truncate'>
              <NavLink to='/products' className='text-zinc-500'>
                Products
              </NavLink>
              <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
              <div className='font-semibold text-zinc-800 truncate'>Product comparison</div>
            </div>
          </div>

          <div className='flex gap-x-6 items-center text-sm font-semibold'>
            <button
              type='button'
              className='grid grid-cols-2 items-stretch w-72 h-full rounded-full bg-white text-sm shadow-[inset_0_0_4px_rgba(0,0,0,0.25)]'
              onClick={() => setLayout((value) => (value === Layout.Graph ? Layout.Table : Layout.Graph))}
            >
              {[Layout.Graph, Layout.Table].map((layoutForButton) => (
                <div
                  key={layoutForButton}
                  className={cn(
                    'flex gap-2 justify-center items-center rounded-full transition',
                    layoutForButton === layout ? 'bg-[#330099] text-white font-semibold' : 'text-zinc-500',
                  )}
                >
                  <FontAwesomeIcon
                    icon={
                      {
                        [Layout.Graph]: regular('chart-simple'),
                        [Layout.Table]: regular('table'),
                      }[layoutForButton]
                    }
                  />
                  {
                    {
                      [Layout.Graph]: 'Graph view',
                      [Layout.Table]: 'Table view',
                    }[layoutForButton]
                  }
                </div>
              ))}
            </button>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-8 py-12'>
        <div className='grid grid-cols-3 gap-y-2 gap-x-10 mb-4 mx-5 sticky z-[1] -top-4'>
          <div />
          {comparedObjects
            .map(({ product, model }) => ({ productOrModel: getProductOrModel(product, model), model }))
            .map(({ productOrModel: { product }, model }, i) => (
              <NavLink
                key={i}
                to={model ? `/products/${product.id}/models/${model.model.id}` : `/products/${product.id}`}
                target='_blank'
                className='-ml-4 w-64 h-52 flex flex-col rounded-[20px] bg-white shadow-[0_1px_10px_rgba(0,0,0,0.15)]'
              >
                {product.imageUrl ? (
                  <img src={product.imageUrl ?? ''} alt='' className='h-[45%] object-cover rounded-t-[20px]' />
                ) : (
                  <div className='h-[45%] rounded-t-[20px] flex justify-center items-center text-gray-300 bg-gray-50'>
                    <FontAwesomeIcon size='2x' icon={duotone('box-circle-check')} />
                  </div>
                )}
                <div className='p-4 flex-1 flex flex-col justify-between gap-y-2'>
                  <div className='font-semibold truncate'>{product.name}</div>
                  <div className='flex justify-between gap-4'>
                    {[
                      { label: 'Product ID', value: product.skuId },
                      {
                        label: 'Product type',
                        value: {
                          [ProductType.Final]: 'Final',
                          [ProductType.Intermediate]: 'Intermediate',
                          [ProductType.Internal]: 'Internal',
                        }[product.productType],
                      },
                    ].map(({ label, value }, i) => (
                      <div
                        key={label}
                        className={cn('flex flex-col', {
                          truncate: i === 0,
                        })}
                      >
                        <div className='uppercase tracking-wider text-xs text-gray-400 whitespace-nowrap'>{label}</div>
                        <div
                          className={cn({
                            truncate: i === 0,
                          })}
                          title={value}
                        >
                          {value}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </NavLink>
            ))}
        </div>
        <Section expanded title='Overall impact'>
          <div className='grid grid-cols-3 items-center gap-x-10 gap-y-8'>
            <div className='pl-7'>Total environmental impact</div>
            {comparedObjects.map(({ product, model }, i) => (
              <div key={i}>{simplify(getImpactPoints(product, model))} impact points</div>
            ))}
            {getProductOrModel(product1, model1).product.productType === ProductType.Final && (
              <>
                <div className='pl-7'>Consumer rating</div>
                {comparedObjects.map(({ product, model }, i) => (
                  <GradeBadge key={i} grade={getGrade(product, model)} />
                ))}
              </>
            )}
          </div>
        </Section>
        <Section title='Product description'>
          <div className='grid grid-cols-3 items-center gap-x-10 gap-y-8'>
            <div className='pl-7'>Net amount</div>
            {comparedObjects.map(({ product, model }, i) => (
              <div key={i}>
                {getAmount(product, model)?.value}
                {getAmount(product, model)?.unit.name}
              </div>
            ))}
            <div className='pl-7'>Category</div>
            {comparedObjects.map(({ product, model }, i) => (
              <div key={i}>{getCategory(product, model)}</div>
            ))}
            <div className='pl-7'>Conservation requirements</div>
            {comparedObjects.map(({ product, model }, i) => (
              <div key={i}>{getConservation(product, model)}</div>
            ))}
            <div className='pl-7'>Status</div>
            {comparedObjects.map(({ product, model }, i) => (
              <div key={i}>{getStage(product, model)}</div>
            ))}
          </div>
        </Section>
        <Section
          title='Impact by category'
          rightWidget={(sectionProps) =>
            layout === Layout.Table && (
              <div className='flex gap-4'>
                <ExportCsvButton name={`${title} - ${sectionProps.title}`} href={impactByCategoryCsvHref} />
                <ImpactScaleSwitch value={impactByCategoryScale} setValue={setImpactByCategoryScale} />
              </div>
            )
          }
        >
          {layout === Layout.Graph && (
            <div className='grid grid-cols-3 gap-x-10'>
              <div className='-ml-36 col-start-2 col-span-2 flex flex-col items-center gap-6 mb-8'>
                <RadarChart
                  height={450}
                  meta={[
                    { dataKey: 'original', label: firstQuery.data!.product.name, color: '#000000' },
                    { dataKey: 'modelled', label: secondQuery.data!.product.name, color: '#4f00ff' },
                  ]}
                  payload={getImpactsByCategory(product1, model1).map((impact) => ({
                    impactName: impact.impactName,
                    original: impact.impactPoints,
                    originalAbsSharePercent: impact.absSharePercent,
                    isMajorOriginal: impact.isMajorImpact,
                    modelled: getImpactCategory(product2, model2, impact.impactId)!.impactPoints,
                    modelledAbsSharePercent: getImpactCategory(product2, model2, impact.impactId)!.absSharePercent,
                    isMajorModelled: getImpactCategory(product2, model2, impact.impactId)!.isMajorImpact,
                    keyName: undefined,
                    isMajor: impact.isMajorImpact,
                  }))}
                  tooltipType='multiple'
                />
                <div className='flex gap-20 text-sm'>
                  {[
                    { name: getProductOrModel(product1, model1).product.name, color: 'bg-black' },
                    { name: getProductOrModel(product2, model2).product.name, color: 'bg-brand' },
                  ].map(({ name, color }, i) => (
                    <div key={i} className='flex items-center gap-2'>
                      <div className={cn('h-2 aspect-square rounded-full', color)} />
                      <div>{name}</div>
                    </div>
                  ))}
                  <div className='flex items-center gap-2'>
                    <div className='h-2.5 aspect-square rounded-full border-[3px] border-amber-400' />
                    <div>Most relevant</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {layout === Layout.Table && (
            <table>
              <thead>
                <tr className='whitespace-nowrap uppercase tracking-wider bg-white border-y border-zinc-300 text-zinc-500 text-xs'>
                  <th className='px-6 py-4 w-[390px]'>Impact category</th>
                  {range(0, comparedNames.length).map((i) => (
                    <Fragment key={i}>
                      <th
                        className={cn('px-6', {
                          'w-[260px]': i === 0,
                        })}
                      >
                        {impactByCategoryScale === ImpactScale.Points && <>Final env. impact</>}
                        {impactByCategoryScale === ImpactScale.Physical && <>Physical impact</>}
                      </th>
                      <th
                        className={cn('px-6', {
                          'w-[150px]': i === 0,
                        })}
                      >
                        Contribution
                      </th>
                    </Fragment>
                  ))}
                </tr>
              </thead>
              <tbody>
                {impactByCategoryRows.map(({ impactName, impactId, comparedItems }) => (
                  <tr key={impactId}>
                    <td className='px-6 py-4'>{impactName}</td>
                    {comparedItems.map((item) => (
                      <Fragment key={item.impactId}>
                        {impactByCategoryScale === ImpactScale.Points && (
                          <td className='px-6'>{roundToShort(item.impactPoints)} impact points</td>
                        )}
                        {impactByCategoryScale === ImpactScale.Physical && (
                          <td className='px-6'>
                            {roundToShort(item.physicalValue)} {item.unit}
                          </td>
                        )}
                        <td className='px-6'>
                          <TooltipV3
                            disabled={!item.isMajorImpact}
                            content={
                              <div className='flex flex-col gap-2 bg-[#330099] text-white text-xs rounded-lg p-2 w-72'>
                                <div className='uppercase tracking-wider text-[10px]'>Impact hotspot</div>
                                <div>Cumulatively contributing to at least 80% of the total environmental impact.</div>
                              </div>
                            }
                          >
                            <div className='inline-flex items-center gap-2'>
                              <div className='w-12'>{roundToShort(item.absSharePercent)}%</div>
                              {item.isMajorImpact && (
                                <FontAwesomeIcon size='lg' icon={solid('seal-exclamation')} className='text-amber-400' />
                              )}
                            </div>
                          </TooltipV3>
                        </td>
                      </Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className='text-[#330099]'>
                  <td className='font-semibold px-6 py-4'>Total</td>
                  {comparedObjects
                    .map(({ product, model }) => getImpactPoints(product, model))
                    .map((points, i) => (
                      <Fragment key={i}>
                        <td className='px-6'>
                          {impactByCategoryScale === ImpactScale.Points && <>{roundToShort(points)} impact points</>}
                        </td>
                        <td className='px-6'>100%</td>
                      </Fragment>
                    ))}
                </tr>
              </tfoot>
            </table>
          )}
        </Section>
        <Section
          title='Impact by life cycle stage'
          rightWidget={(sectionProps) => (
            <div className='flex gap-4'>
              {layout === Layout.Table && (
                <ExportCsvButton
                  name={[
                    title,
                    sectionProps.title,
                    ...(impactByLifeCycleStageCategoryId
                      ? [getImpactCategory(product1, model1, impactByLifeCycleStageCategoryId)!.impactName]
                      : []),
                  ].join(' - ')}
                  href={impactByLifeCycleStageCsvHref}
                />
              )}
              <div className='relative flex items-center text-brand text-sm font-semibold'>
                <select
                  className='appearance-none pl-4 pr-10 py-2 -my-1 rounded-md bg-white'
                  value={impactByLifeCycleStageCategoryId}
                  onChange={(event) => setImpactByLifeCycleStageCategoryId(event.target.value)}
                >
                  <option value='' disabled={impactByLifeCycleStageScale === ImpactScale.Physical}>
                    Total environmental impact
                  </option>
                  {getImpactsByCategory(product1, model1).map((impact) => (
                    <option key={impact.impactId} value={impact.impactId}>
                      {impact.impactName}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={solid('chevron-down')} className='absolute right-4 text-zinc-600' />
              </div>
            </div>
          )}
        >
          <div className='flex flex-col gap-6'>
            <div className='self-end -mt-7 h-7 pr-4'>
              <ImpactScaleSwitch
                value={impactByLifeCycleStageScale}
                setValue={(value) => {
                  setImpactByLifeCycleStageScale(value);

                  if (!impactByLifeCycleStageCategoryId && value === ImpactScale.Physical) {
                    setImpactByLifeCycleStageCategoryId(getImpactsByCategory(product1, model1)[0].impactId);
                  }
                }}
              />
            </div>
            {layout === Layout.Graph &&
              (() => {
                const products = comparedObjects.map(({ product, model }) =>
                  impactByLifeCycleStageCategoryId
                    ? getImpactsByCategory(product, model)
                        .filter(({ impactId }) => impactId === impactByLifeCycleStageCategoryId)
                        .flatMap(({ stages }) => stages)
                        .map(({ name, impactPoints, physicalValue, isMajorStage }) => ({
                          name,
                          value: {
                            [ImpactScale.Points]: impactPoints,
                            [ImpactScale.Physical]: physicalValue,
                          }[impactByLifeCycleStageScale],
                          major: isMajorStage,
                        }))
                    : getImpactsByLifeCycleStage(product, model).map(({ name, impactPoints, isMajorImpact }) => ({
                        name,
                        value: impactPoints,
                        major: isMajorImpact,
                      })),
                );
                const unit = {
                  [ImpactScale.Points]: 'impact points',
                  [ImpactScale.Physical]: getImpactCategory(product1, model1, impactByLifeCycleStageCategoryId)?.unit,
                }[impactByLifeCycleStageScale];
                return (
                  <div className='grid grid-cols-3 gap-x-10'>
                    <div className='-ml-48 col-span-2 col-start-2 flex flex-col items-center gap-10 my-8'>
                      <BarChart
                        width={900}
                        height={400}
                        data={products.map((product) => product.reduce((object, { name, value }) => ({ ...object, [name]: value }), {}))}
                      >
                        <XAxis
                          tickSize={0}
                          axisLine
                          tick={(props) => (
                            <Text {...props} fill={props.payload.index === 0 ? 'black' : '#4f00ff'} fontSize={14} y={props.y + 20}>
                              {
                                comparedObjects.map(({ product, model }) => getProductOrModel(product, model))[props.payload.index].product
                                  .name
                              }
                            </Text>
                          )}
                        />
                        <YAxis
                          tickSize={0}
                          axisLine={false}
                          label={
                            <Text x={0} y={0} dx={20} dy={220} offset={0} angle={-90} fontSize={14}>
                              {unit}
                            </Text>
                          }
                          tick={(props) => (
                            <Text {...props} fontSize={10}>
                              {roundToShort(props.payload.value)}
                            </Text>
                          )}
                        />
                        {getImpactsByLifeCycleStage(product1, model1).map((impact) => (
                          <Bar
                            key={impact.name}
                            isAnimationActive={false}
                            stackId='1'
                            barSize={100}
                            dataKey={impact.name}
                            fill={lifeCycleStagesColorHexSchema[impact.name]}
                          >
                            {products.map((product, i) => (
                              <Cell
                                key={i}
                                {...(product.find(({ name }) => impact.name === name)!.major ? { stroke: '#fbbf24', strokeWidth: 3 } : {})}
                              />
                            ))}
                          </Bar>
                        ))}
                        <Tooltip
                          wrapperStyle={{ zIndex: 1 }}
                          cursor={{ fill: 'none' }}
                          content={({ payload }) => (
                            <div className='bg-white rounded-2xl px-6 py-4 border shadow-md grid grid-cols-[repeat(2,max-content)] gap-x-4 gap-y-2'>
                              {payload &&
                                payload.map(({ name, color, payload }) => (
                                  <Fragment key={name}>
                                    <div className='font-semibold pr-2' style={{ color }}>
                                      {name}:
                                    </div>
                                    <div className='text-right'>
                                      {roundToShort(payload[name!])} {unit}
                                    </div>
                                  </Fragment>
                                ))}
                            </div>
                          )}
                        />
                      </BarChart>
                      <div className='grid grid-cols-[repeat(3,max-content)] gap-x-10 gap-y-1.5 text-sm'>
                        {Object.entries(lifeCycleStagesColorHexSchema)
                          .filter(
                            (_, i) =>
                              getProductOrModel(product1, model1).product.productType === ProductType.Final ||
                              (getProductOrModel(product1, model1).product.productType === ProductType.Intermediate && i < 3) ||
                              (getProductOrModel(product1, model1).product.productType === ProductType.Internal && i < 2),
                          )
                          .map(([name, backgroundColor], i) => (
                            <div key={i} className='flex items-center gap-3.5'>
                              <div style={{ backgroundColor }} className='w-4 aspect-square rounded' />
                              <div>{name}</div>
                            </div>
                          ))}
                        <div className='flex items-center gap-3.5'>
                          <div className='w-4 aspect-square rounded border-[3px] border-amber-400' />
                          <div>Most relevant</div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })()}
            {layout === Layout.Table && (
              <table>
                <thead>
                  <tr className='whitespace-nowrap uppercase tracking-wider bg-white border-y border-zinc-300 text-zinc-500 text-xs'>
                    <th className='px-6 py-4 w-[390px]'>Life cycle stage</th>
                    {range(0, comparedNames.length).map((i) => (
                      <Fragment key={i}>
                        <th
                          className={cn('px-6', {
                            'w-[260px]': i === 0,
                          })}
                        >
                          {impactByLifeCycleStageScale === ImpactScale.Points && <>Final env. impact</>}
                          {impactByLifeCycleStageScale === ImpactScale.Physical && <>Physical impact</>}
                        </th>
                        <th
                          className={cn('px-6', {
                            'w-[150px]': i === 0,
                          })}
                        >
                          Contribution
                        </th>
                      </Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {impactByLifeCycleStageRows.map(({ id, name, comparedItems }) => (
                    <tr key={id}>
                      <td className='px-6 py-4'>{name}</td>
                      {comparedItems.map((item) => (
                        <Fragment key={item.id}>
                          {impactByLifeCycleStageScale === ImpactScale.Points && (
                            <td className='px-6'>{roundToShort(item.impactPoints)} impact points</td>
                          )}
                          {impactByLifeCycleStageScale === ImpactScale.Physical && (
                            <td className='px-6'>
                              {roundToShort(item.physicalValue!)} {item.unit}
                            </td>
                          )}
                          <td className='px-6'>
                            <TooltipV3
                              disabled={!item.isMajor}
                              content={
                                <div className='flex flex-col gap-2 bg-[#330099] text-white text-xs rounded-lg p-2 w-72'>
                                  <div className='uppercase tracking-wider text-[10px]'>Impact hotspot</div>
                                  <div>Cumulatively contributing to at least 80% of the total environmental impact.</div>
                                </div>
                              }
                            >
                              <div className='inline-flex items-center gap-2'>
                                <div className='w-12'>{roundToShort(item.absSharePercent)}%</div>
                                {item.isMajor && <FontAwesomeIcon size='lg' icon={solid('seal-exclamation')} className='text-amber-400' />}
                              </div>
                            </TooltipV3>
                          </td>
                        </Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className='text-[#330099]'>
                    <td className='font-semibold px-6 py-4'>Total</td>
                    {(impactByLifeCycleStageCategoryId
                      ? comparedObjects.map(({ product, model }) => getImpactCategory(product, model, impactByLifeCycleStageCategoryId)!)
                      : comparedObjects.map(({ product, model }) => ({
                          impactPoints: getImpactPoints(product, model),
                          physicalValue: undefined,
                          unit: undefined,
                        }))
                    ).map(({ impactPoints, physicalValue, unit }, i) => (
                      <Fragment key={i}>
                        {impactByLifeCycleStageScale === ImpactScale.Points && (
                          <td className='px-6'>{roundToShort(impactPoints)} impact points</td>
                        )}
                        {impactByLifeCycleStageScale === ImpactScale.Physical && (
                          <td className='px-6'>
                            {roundToShort(physicalValue!)} {unit}
                          </td>
                        )}
                        <td className='px-6'>100%</td>
                      </Fragment>
                    ))}
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </Section>
        <Section
          title='Most relevant processes'
          rightWidget={(sectionProps) => <ExportCsvButton name={`${title} - ${sectionProps.title}`} href={majorProcessesCsvHref} />}
        >
          <table>
            <thead>
              <tr className='whitespace-nowrap uppercase tracking-wider bg-white border-y border-zinc-300 text-zinc-500 text-xs'>
                <th className='px-6 py-4 w-[390px]'>Top processes</th>
                {range(0, comparedNames.length).map((i) => (
                  <Fragment key={i}>
                    <th
                      className={cn('px-6', {
                        'w-[260px]': i === 0,
                      })}
                    >
                      Process
                    </th>
                    <th
                      className={cn('px-6', {
                        'w-[150px]': i === 0,
                      })}
                    >
                      Contribution
                    </th>
                  </Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {majorProcessesRows.map(({ name, comparedItems }) => (
                <tr key={name}>
                  <td className='px-6'>{name}</td>
                  {comparedItems.map((item, i) => (
                    <Fragment key={i}>
                      <td className='px-6 py-4'>{item && <>{item.name}</>}</td>
                      <td className='px-6'>{item && <>{roundToShort(item.sharePercent)}%</>}</td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Section>
      </div>
    </div>
  );
};

const Section = (
  props: PropsWithChildren<{
    title: string;
    rightWidget?: (sectionProps: { title: string }) => ReactNode;
    expanded?: boolean;
  }>,
) => {
  const [expanded, setExpanded] = useState(props.expanded ?? false);
  return (
    <div className='flex flex-col bg-zinc-50 rounded-xl shadow-[0_0_3px_rgba(0,0,0,0.25)]'>
      <div className='flex'>
        <button
          type='button'
          className='flex-1 flex items-center gap-2.5 px-5 py-6 text-lg text-zinc-900 font-semibold'
          onClick={() => setExpanded((value) => !value)}
        >
          <FontAwesomeIcon icon={expanded ? light('circle-chevron-down') : light('circle-chevron-right')} />
          {props.title}
        </button>
        {props.rightWidget && expanded && <div className='flex flex-col justify-center px-5 py-6'>{props.rightWidget(props)}</div>}
      </div>
      {expanded && <div className='flex flex-col px-5 py-6'>{props.children}</div>}
    </div>
  );
};

const ImpactScaleSwitch = (props: { value: ImpactScale; setValue: (value: ImpactScale) => void }) => (
  <button
    type='button'
    className='grid grid-cols-2 items-stretch w-80 h-7 rounded-full bg-white text-sm shadow-[0_0_2px_rgba(0,0,0,0.25)]'
    onClick={() => props.setValue(props.value === ImpactScale.Points ? ImpactScale.Physical : ImpactScale.Points)}
  >
    {[ImpactScale.Points, ImpactScale.Physical].map((impactScale) => (
      <div
        key={impactScale}
        className={cn(
          'flex justify-center items-center rounded-full transition',
          impactScale === props.value ? 'bg-[#E8EAF5] text-brandDarkPurple2 font-semibold' : '',
        )}
      >
        {
          {
            [ImpactScale.Points]: 'Final env. impact',
            [ImpactScale.Physical]: 'Physical impact',
          }[impactScale]
        }
      </div>
    ))}
  </button>
);

const ExportCsvButton = (props: { name: string; href: string }) => (
  <a
    className='print:hidden flex gap-2 items-center px-4 text-brandDarkPurple2 text-sm font-semibold'
    download={`${props.name}.csv`}
    href={props.href}
  >
    <FontAwesomeIcon icon={solid('download')} />
    Export as CSV
  </a>
);
