import { TargetType, TargetImpact } from '../../../api';
import { ForwardedRef, forwardRef, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from 'date-fns';

interface Props {
  items: TargetImpact[];
}

export const TargetsCarousel = forwardRef<HTMLDivElement, Props>((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const items = props.items;
  const [startIndex, setStartIndex] = useState(0);

  const totalCols = items.length >= 3 ? 'grid-cols-3' : items.length === 2 ? 'grid-cols-2' : 'grid-cols-1';
  return (
    <div ref={ref}>
      <div className='flex gap-2 items-center -mx-3'>
        <button
          className={cn('text-brand disabled:text-zinc-400', { invisible: startIndex === 0 || items.length <= 3 })}
          disabled={startIndex === 0}
          onClick={() => setStartIndex((startIndex - 3 + items.length) % items.length)}
        >
          <FontAwesomeIcon icon={regular('arrow-left')} />
        </button>
        <div className={cn('grid gap-3', totalCols)}>
          {items.slice(startIndex, startIndex + 3).map((target, i) => (
            <div key={i} className='p-3 bg-slate-50 rounded-md border'>
              <div className='flex flex-col items-center justify-center h-full gap-y-1 w-24'>
                <div title={target.name} className='h-[40px] text-center text-sm line-clamp-2'>
                  {target.name}
                </div>
                <div className='flex items-center justify-center gap-1'>
                  <div className='font-semibold whitespace-nowrap'>
                    <div className='flex gap-x-0.5 items-center pb-1.5'>
                      <div className='text-xl font-semibold'>{target.reduction}</div>
                      <div className='text-sm'>%</div>
                    </div>
                  </div>
                </div>
                <div className='text-xs truncate'>
                  {target.type === TargetType.LONG_TERM ? `BY ${format(new Date(target.target!), 'yyyy')}` : 'PER YEAR'}
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className={cn('disabled:text-zinc-400 text-brand', { invisible: startIndex + 3 >= items.length })}
          disabled={startIndex + 3 >= items.length}
          onClick={() => setStartIndex((startIndex + 3) % items.length)}
        >
          <FontAwesomeIcon icon={regular('arrow-right')} />
        </button>
      </div>
    </div>
  );
});
