import { createContext, PropsWithChildren, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Account, getAccount, getSelectedWorkspaceId, Workspace } from '../api';
import { useApiQuery } from './useApiQuery';

interface Profile extends Account {
  selectedWorkspace: Workspace;
  onWorkspaceChanged: () => void;
}

const Context = createContext<Profile>({} as any);

export const useProfile = () => useContext(Context);

export const ProfileProvider = (props: PropsWithChildren<{}>) => {
  const { data, waiting, reload } = useApiQuery(getAccount());
  const navigate = useNavigate();

  return data && !waiting ? (
    <Context.Provider
      value={{
        ...data,
        selectedWorkspace:
          data.workspaces.find(({ workspaceSid }) => workspaceSid === getSelectedWorkspaceId()) ??
          data.workspaces.find(({ isDefault }) => isDefault)!,
        onWorkspaceChanged: () => {
          reload();
          navigate('/');
        },
      }}
    >
      {props.children}
    </Context.Provider>
  ) : (
    <></>
  );
};
