import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import { Entity, Methodology, ProductStage, ProductType, ProductV3, StaticEntity, getProductUploadUrlsV3 } from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { SelectV3 } from '../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { useControlEvents } from '../../../../hooks/useControlEvents';
import { useLists } from '../../../../hooks/useLists';
import { useProfile } from '../../../../hooks/useProfile';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { TitleBadges, Props as TitleBadgesProps } from './TitleBadges';
import { ValidationErrors } from './ValidationErrors';
import { getIngredientsFromAll, getPackagingsFromAll, getStepsFromAll } from './dataModel';

interface Props extends TitleBadgesProps {
  title: string;
}

export const BasicInfo = (props: Props) => {
  const productFormik = useFormikContext<ProductV3>();
  const profile = useProfile();
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const location = useLocation();
  const rescaleModal = useRef<ModalApi>(null);
  const [newValues, setNewValues] = useState(productFormik.values);
  const [originalValues] = useState(productFormik.values);
  const [duplicated] = useState(new URLSearchParams(location.search).has('duplicated'));
  const productStage = new URLSearchParams(location.search).get('stage') as ProductStage | null;

  return (
    <div className='flex flex-col gap-4 mb-8'>
      <ModalV3
        ref={rescaleModal}
        size='narrow'
        title='Automatic rescaling'
        cancelLabel='Skip'
        confirmLabel='Rescale'
        onCancel={() => {
          productFormik.setValues(newValues);
        }}
        onConfirm={() => {
          const values = cloneDeep(newValues);
          const scale = newValues.amount.value / originalValues.amount.value;

          getIngredientsFromAll(values.nodes).forEach((ingredient) => {
            ingredient.amount.value = round(ingredient.amount.value * scale, 1);
          });
          getPackagingsFromAll(values.nodes).forEach((packaging) => {
            packaging.amount.value = round(packaging.amount.value * scale, 1);
          });
          getStepsFromAll(values.nodes).forEach((step) => {
            step.inputs.forEach((input) => {
              input.amountValue = round(input.amountValue * scale, 1);
            });
            step.outputs.forEach((output) => {
              output.amount!.value = round(output.amount!.value * scale, 1);
            });
          });

          productFormik.setValues(values);
        }}
        body={
          <>
            The net amount you just entered ({newValues.amount?.value}
            {newValues.amount?.unit.name}) is different from that of the original product you duplicated ({originalValues.amount?.value}
            {originalValues.amount?.unit.name}). Do you want us to automatically rescale all of the product’s content to match the new net
            amount?
          </>
        }
      />
      <div className='flex justify-between gap-4 px-6'>
        <div className='flex items-center gap-4 truncate'>
          <NavLink
            to='/products'
            className='shrink-0 flex justify-center items-center w-8 aspect-square rounded-md text-lg text-brand bg-[#E8EAF5] active:scale-90'
          >
            <FontAwesomeIcon icon={solid('chevron-left')} />
          </NavLink>
          <div className='flex gap-4 truncate'>
            <div className='flex items-center gap-2 text-base truncate'>
              <NavLink to='/products' className='text-zinc-500'>
                Products
              </NavLink>
              <FontAwesomeIcon size='xs' icon={solid('chevron-right')} className='text-zinc-500' />
              <div className='font-semibold truncate'>{props.title}</div>
            </div>
            <TitleBadges {...props} />
          </div>
        </div>
        <ValidationErrors ignoreLessImportant />
      </div>
      <Formik
        initialValues={{
          ...productFormik.values,
          stage: productStage ?? productFormik.values.stage,
          conservation: productFormik.values.conservation ?? undefined,
          amount: productFormik.values.amount ?? undefined,
          economicValue: productFormik.values.economicValue ?? undefined,
        }}
        validateOnBlur={triedSubmitting}
        validateOnChange={triedSubmitting}
        validationSchema={yup.object().shape({
          name: yup.string().required(),
          skuId: yup.string().required(),
          category: yup.object().when('productType', {
            is: ProductType.Internal,
            then: (schema) => schema.nullable(),
            otherwise: (schema) => schema.required(),
          }),
          foodType: yup.object().when('productType', {
            is: ProductType.Internal,
            then: (schema) => schema.nullable(),
            otherwise: (schema) => schema.required(),
          }),
          conservation: yup.object().shape({
            requirement: yup.object().required(),
          }),
          amount: yup.object().shape({
            value: yup.number().positive().required(),
            unit: yup.object().required(),
          }),
          economicValue: yup.object().when('productType', {
            is: ProductType.Internal,
            then: (schema) => schema.nullable(),
            otherwise: (schema) =>
              schema.shape({
                price: yup.number().positive().required(),
                currency: yup.object().required(),
              }),
          }),
          servings: yup
            .number()
            .integer()
            .positive()
            .when('productType', {
              is: ProductType.Final,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.nullable(),
            }),
          ...(profile.selectedWorkspace.methodology.type === Methodology.FoundationEarth
            ? {
                rawToCookedRatio: yup.number().positive().required(),
              }
            : {}),
        })}
        onSubmit={(values, form) => {
          if (duplicated && typeof originalValues.amount?.value === 'number' && originalValues.amount.value !== values.amount.value) {
            setNewValues(values);
            rescaleModal.current!.open();
          } else {
            productFormik.setValues(values);
          }

          form.setSubmitting(false);
        }}
      >
        <Content {...props} triedSubmitting={() => setTriedSubmitting(true)} />
      </Formik>
    </div>
  );
};

const Content = (props: Props & { triedSubmitting: () => void }) => {
  const lists = useLists();
  const profile = useProfile();

  const formik = useFormikContext<ProductV3>();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageUploadError, setImageUploadError] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const saving = props.waiting || formik.isValidating || formik.isSubmitting;

  const final = formik.values.productType === ProductType.Final;
  const internal = formik.values.productType === ProductType.Internal;

  useEffect(() => {
    if (formik.values.foodType && !formik.values.amount?.unit) {
      formik.setFieldValue('amount.unit', lists.foodTypes.find(({ type }) => type === formik.values.foodType.type)?.unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.foodType]);

  return (
    <TaggableFieldsContainer>
      <Form className='flex flex-col gap-8'>
        <div className='flex justify-center bg-neutral-50 border-y border-zinc-300 -mx-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
          <div className='grid grid-cols-[460px_1fr] gap-[calc(theme(spacing.1)*38)] pt-10 pb-16 max-w-screen-xl'>
            <LeftSide />
            <div className='flex flex-col gap-6'>
              <div className='font-semibold text-lg'>Tell us more about your product</div>
              <div className='grid grid-cols-2 gap-x-14 gap-y-8'>
                <TaggableField name='name'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>Product name *</div>
                      <InputV3 autoFocus model={model} placeholder='Name your product…' />
                    </div>
                  )}
                </TaggableField>
                <TaggableField name='gtin'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>GTIN</div>
                      <InputV3 model={model} placeholder='Specify or leave empty…' />
                    </div>
                  )}
                </TaggableField>
                <TaggableField name='category'>
                  {(model: FieldProps<Entity>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>Category{!internal && <> *</>}</div>
                      <SelectV3<Entity>
                        model={model}
                        options={lists.categories}
                        placeholder={internal ? 'Choose or leave empty…' : undefined}
                      />
                    </div>
                  )}
                </TaggableField>
                {final ? (
                  <TaggableField name='servings'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-xs'>Number of servings *</div>
                        <InputV3 integer positive model={model} />
                      </div>
                    )}
                  </TaggableField>
                ) : (
                  <div />
                )}
                <TaggableField name='foodType'>
                  {(model: FieldProps<StaticEntity>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>Food type{!internal && <> *</>}</div>
                      <SelectV3<StaticEntity>
                        model={model}
                        options={lists.foodTypes}
                        placeholder={internal ? 'Choose or leave empty…' : undefined}
                      />
                    </div>
                  )}
                </TaggableField>
                <TaggableField name='conservation.requirement'>
                  {(model: FieldProps<Entity>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>Conservation requirements *</div>
                      <SelectV3<Entity> model={model} options={lists.conservationRequirements} />
                    </div>
                  )}
                </TaggableField>
                <TaggableField name='skuId'>
                  {(model: FieldProps<string>) => (
                    <div className='flex flex-col gap-1.5'>
                      <div className='font-semibold text-xs'>Product ID *</div>
                      <InputV3 model={model} placeholder='Your internal ID…' />
                    </div>
                  )}
                </TaggableField>
                <div className='flex flex-col gap-1.5'>
                  <div className='font-semibold text-xs'>Image upload</div>
                  <Field name='imageUrl'>
                    {(model: FieldProps<string>) => (
                      <label
                        className={cn('flex rounded-lg bg-white border cursor-pointer', imageUploadError ? 'border-f' : 'border-zinc-400', {
                          'cursor-wait': uploadingImage,
                        })}
                      >
                        <div className='w-full h-full px-3 py-1.5'>
                          {(() => {
                            if (imageUploadError) {
                              return <span className='text-f'>Maximum file size is 2MB</span>;
                            }

                            if (uploadingImage) {
                              return <span>Uploading…</span>;
                            }

                            if (!model.field.value) {
                              return <span className='text-neutral-400'>Upload file…</span>;
                            }

                            return <span className='text-dark'>File uploaded</span>;
                          })()}
                        </div>
                        <input
                          type='file'
                          accept='.jpg, .jpeg, .png'
                          disabled={uploadingImage}
                          className='invisible w-0 h-0'
                          onChange={(event) => {
                            const file = event.target.files![0];

                            if (file) {
                              formik.setFieldValue(model.field.name, null);
                              if (file.size > 1024 * 1024 * 2) {
                                setImageUploadError(true);
                                return;
                              }

                              setImageUploadError(false);
                              setUploadingImage(true);
                              const reader = new FileReader();
                              reader.readAsArrayBuffer(file);
                              reader.onload = () => {
                                getProductUploadUrlsV3(formik.values.id, file.name).call({
                                  ok: async (urls) => {
                                    await fetch(urls.uploadUrl, {
                                      method: 'PUT',
                                      body: reader.result,
                                    });
                                    formik.setFieldValue(model.field.name, urls.fileUrl);
                                    setUploadingImage(false);
                                  },
                                  fail: () => {
                                    setUploadingImage(false);
                                  },
                                });
                              };
                            }
                          }}
                        />
                        <div className='flex items-center justify-center h-full w-14'>
                          {uploadingImage ? (
                            <FontAwesomeIcon className='h-4 aspect-square text-yellow-400' icon={duotone('loader')} />
                          ) : imageUploadError ? (
                            <FontAwesomeIcon className='h-4 aspect-square text-red-500' icon={light('triangle-exclamation')} />
                          ) : model.field.value ? (
                            <FontAwesomeIcon className='h-4 aspect-square text-green-600' icon={regular('circle-check')} />
                          ) : (
                            <FontAwesomeIcon className='h-4 aspect-square text-neutral-400' icon={regular('inbox-out')} />
                          )}
                        </div>
                      </label>
                    )}
                  </Field>
                </div>
                <TaggableField name='amount.value'>
                  {(model: FieldProps<number>) => (
                    <Field name='amount.unit'>
                      {(unitModel: FieldProps<Entity>) => (
                        <div className='flex flex-col gap-1.5'>
                          <div className='font-semibold text-xs'>Net amount *</div>
                          <UnitInputV3 model={model} unit={{ model: unitModel, options: lists.units }} />
                        </div>
                      )}
                    </Field>
                  )}
                </TaggableField>
                <div className='row-span-2'>
                  <Field name='imageUrl'>
                    {(model: FieldProps<string>) =>
                      model.field.value && (
                        <div
                          className={cn('flex items-center justify-center transition-opacity duration-300 opacity-0', {
                            'opacity-100': showImage,
                          })}
                        >
                          <div className='flex h-[144px] w-[auto] relative'>
                            <img
                              onLoad={() => setShowImage(true)}
                              src={model.field.value}
                              alt={formik.values.name ?? ''}
                              className={cn('rounded-xl h-[144px] w-[auto]', {
                                invisible: !showImage,
                              })}
                            />
                            <button
                              type='button'
                              className={cn(
                                'absolute -top-2 -right-2',
                                'flex justify-center items-center rounded-full w-5 h-5 aspect-square',
                                'text-white bg-brand active:scale-90',
                                { invisible: !showImage },
                              )}
                              onClick={() => {
                                formik.setFieldValue(model.field.name, null);
                              }}
                            >
                              <FontAwesomeIcon icon={regular('times')} />
                            </button>
                          </div>
                        </div>
                      )
                    }
                  </Field>
                </div>
                {!internal && (
                  <TaggableField name='economicValue.price'>
                    {(model: FieldProps<number>) => (
                      <Field name='economicValue.currency'>
                        {(unitModel: FieldProps<Entity>) => (
                          <div className='flex flex-col gap-1.5'>
                            <div className='font-semibold text-xs'>Economic value *</div>
                            <UnitInputV3 model={model} unit={{ model: unitModel, options: lists.currencies }} />
                          </div>
                        )}
                      </Field>
                    )}
                  </TaggableField>
                )}
                {profile.selectedWorkspace.methodology.type === Methodology.FoundationEarth && (
                  <Field name='rawToCookedRatio'>
                    {(model: FieldProps<number>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-xs'>Raw to Cooked (R2C) ratio *</div>
                        <InputV3 number positive model={model} placeholder='0.0 … 100.0' />
                      </div>
                    )}
                  </Field>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end px-6'>
          <button
            onClick={props.triedSubmitting}
            disabled={saving || !formik.isValid}
            className={cn(
              'w-40 flex justify-center border-2 border-transparent bg-brand rounded-full px-4 py-1 text-white font-semibold',
              '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300',
              {
                'disabled:cursor-wait': saving,
              },
            )}
          >
            Save &amp; Next
          </button>
        </div>
      </Form>
    </TaggableFieldsContainer>
  );
};

const LeftSide = () => {
  const formik = useFormikContext<ProductV3>();
  const controlEvents = useControlEvents();
  const initialProductType = useRef(formik.values.productType);

  return (
    <div className='flex flex-col justify-between gap-16'>
      <TaggableField name='productType'>
        {(model: FieldProps<ProductType>) => (
          <div className='flex flex-col gap-6'>
            <div className='font-semibold text-lg'>What kind of product would you like to create?</div>
            <div className='flex flex-col gap-3'>
              {[
                { value: ProductType.Final, label: 'Final product', text: 'Products sold to consumers.' },
                {
                  value: ProductType.Intermediate,
                  label: 'Intermediate product',
                  text: 'Products sold for further processing, usually to other businesses.',
                },
                {
                  value: ProductType.Internal,
                  label: 'Internal product (not sold)',
                  text: 'Products made internally for use as input to other products.',
                },
              ].map(({ value, label, text }) => (
                <button
                  key={value}
                  type='button'
                  className={cn(
                    'text-zinc-800 rounded-lg border px-5 py-3',
                    model.field.value === value ? 'border-brand bg-slate-50' : 'border-zinc-300 bg-white',
                  )}
                  onClick={() => {
                    controlEvents!.touched(model);
                    formik.setFieldValue(model.field.name, value);
                  }}
                >
                  <div className='font-semibold'>{label}</div>
                  <div>{text}</div>
                </button>
              ))}
            </div>
            {initialProductType.current !== model.field.value && (
              <div className='flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
                <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
                Changing the product type may result in automatic deletion of parts of the original product graph to fit with the
                requirements of the selected type
              </div>
            )}
          </div>
        )}
      </TaggableField>
      <TaggableField name='stage'>
        {(model: FieldProps<ProductStage>) => (
          <div className='flex flex-col gap-6'>
            <div className='font-semibold text-lg'>What’s the stage of your product?</div>
            <div className='grid grid-cols-2 gap-3'>
              {[
                { value: ProductStage.Production, label: 'In production' },
                { value: ProductStage.Development, label: 'In development' },
              ].map(({ value, label }) => (
                <button
                  key={value}
                  type='button'
                  className={cn(
                    'flex justify-center items-center text-zinc-800 font-semibold rounded-lg border px-5 py-3',
                    model.field.value === value ? 'border-brand bg-slate-50' : 'border-zinc-300 bg-white',
                  )}
                  onClick={() => {
                    controlEvents!.touched(model);
                    formik.setFieldValue(model.field.name, value);
                  }}
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        )}
      </TaggableField>
    </div>
  );
};
