import { forwardRef, SetStateAction } from 'react';
import { Lens, ProductReport } from '../../../../../../api';
import { PefOnePagerReport } from '../components/PefOnePagerReport';

interface Props {
  data: ProductReport;
  setData: (data: ProductReport | undefined) => void;
  setLoading: (loading: SetStateAction<boolean>) => void;
  img?: HTMLImageElement | null;
}

export const ProductionLensOnePagerReport = forwardRef((props: Props, ref: any) => (
  <PefOnePagerReport ref={ref} lens={Lens.Production} data={props.data} setData={props.setData} setLoading={props.setLoading} />
));
