import { useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { ProductionOverview } from './Production/Overview';
import { GhgOverview } from './Ghg/Overview';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { lifeCycleStagesColorHexSchema, setColor } from '../../../../../components/charts/shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ReportWarning } from '../../../../../components/ReportWarning';
import { Report as PefReport } from './Production';
import { Report as GhgReport } from './Ghg';
import { getGhgBaseline, getPefBaseline, WarningType } from '../../../../../api';
import { useApiQuery } from '../../../../../hooks/useApiQuery';

export const Report = () => {
  const params = useParams();
  const lens = params['*']?.split('/')[0] === 'production' ? 'pef' : 'ghg';

  const pefLoaded = useRef(false);
  const ghgLoaded = useRef(false);
  const pefReport = useApiQuery(getPefBaseline(), { cancel: lens !== 'pef' || (lens === 'pef' && pefLoaded.current) });
  const ghgReport = useApiQuery(getGhgBaseline(), { cancel: lens !== 'ghg' || (lens === 'ghg' && ghgLoaded.current) });

  const hasError = (pefReport.data?.errorCode || ghgReport.data?.errorCode) !== undefined;

  useEffect(() => {
    if (pefReport.data) {
      pefLoaded.current = true;
    }
    if (ghgReport.data) {
      ghgLoaded.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pefReport.data, ghgReport.data]);

  if ((lens === 'pef' && !pefReport.data) || (lens === 'ghg' && !ghgReport.data)) {
    return <ManufacturingSkeletonLoader />;
  }

  if (pefReport.data && !pefReport.data.errorCode) {
    pefReport.data.analysis.lifecycleStageImpacts.forEach((entity) => ({
      ...entity,
      bgColor: setColor({ key: entity.name }, lifeCycleStagesColorHexSchema),
    }));
  }

  return (
    <Routes>
      <Route
        path='production/*'
        element={
          <Routes>
            <Route
              path='overview'
              element={
                <ProductionOverview
                  warning={
                    pefReport.data?.warnings &&
                    pefReport.data.warnings.length > 0 &&
                    pefReport.data.warnings.find(({ id }) => id === WarningType.ScreeningLcaNotMeet) && (
                      <ReportWarning
                        theme='red'
                        icon={
                          <div className='size-9 bg-rose-500 rounded-full flex items-center justify-center p-2'>
                            <FontAwesomeIcon className='size-5 text-white' icon={solid('percent')} />
                          </div>
                        }
                        title='Preliminary Baseline - Screening LCA threshold not met'
                        text='Not enough first-party data was provided for your products to meet the screening LCA threshold level (first-party data < 100%). A lot of defaults were automatically made by the system and as such, all results presented on this page hold a lot of uncertainty and should be interpreted with caution.'
                      />
                    )
                  }
                  data={pefReport.data!}
                  hasError={hasError}
                  reload={() => {
                    pefLoaded.current = false;
                    ghgLoaded.current = false;
                    pefReport.reload();
                    ghgReport.reload();
                  }}
                />
              }
            />
            <Route path='*' element={<PefReport data={pefReport.data!} hasError={hasError} />} />
          </Routes>
        }
      />
      <Route
        path='ghg/*'
        element={
          <Routes>
            <Route
              path='overview'
              element={
                <GhgOverview
                  data={ghgReport.data!}
                  hasError={hasError}
                  reload={() => {
                    pefLoaded.current = false;
                    ghgLoaded.current = false;
                    ghgReport.reload();
                    pefReport.reload();
                  }}
                />
              }
            />
            <Route path='*' element={<GhgReport hasError={hasError} data={ghgReport.data!} />} />
          </Routes>
        }
      />
      <Route path='*' element={<Navigate to='production/overview' replace />} />
    </Routes>
  );
};
