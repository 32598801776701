import { ReportSummary } from '../../../components/ReportSummary';
import { BaselinePefReport } from '../../../../../../api';
import { format } from 'date-fns';
import { simplify, roundToLong } from '../../../../shared';

interface Props {
  data: BaselinePefReport;
}

export const BaselineSummary = (props: Props) => {
  const records = [
    {
      field: 'Reporting timeframe',
      value: `${format(new Date(props.data.startDate), 'dd/MM/yy')} - ${format(new Date(props.data.endDate), 'dd/MM/yy')}`,
    },
    { field: 'Number of products produced', value: props.data.productCount },
    { field: 'Total number of manufactured units', value: props.data.totalUnitCount },
    {
      field: 'Total baseline impact',
      value: (
        <div className='text-sm'>
          <span title={roundToLong(props.data.overallImpact)} className='font-semibold'>
            {simplify(props.data.overallImpact)}
          </span>{' '}
          weighted person.year OR{' '}
          <span title={roundToLong(props.data.impactPoints)} className='font-semibold'>
            {simplify(props.data.impactPoints)}
          </span>{' '}
          Impact points
        </div>
      ),
    },
    { field: 'Report creator', value: props.data.author },
    { field: 'Date of report creation', value: format(new Date(props.data.createdAt), 'dd/MM/yy hh:mm') },
    {
      field: 'Versioning',
      value: (
        <ul className='list-disc ml-3.5'>
          <li>Methodology v.{props.data.metadata.methodologyVersion}</li>
          <li>Web application v.{props.data.metadata.webAppVersion}</li>
        </ul>
      ),
    },
  ];

  return <ReportSummary tabDescription='A brief overview of the baseline assessment carried out' records={records} />;
};
