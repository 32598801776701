import { Overview } from '../Overview';
import { BasicProductDetailsTile } from '../components/BasicProductDetailsTile';
import { GhgProtocol } from '../../../../Manufacturing/components/GhgProtocol.tsx';
import { ProductSkeletonLoader } from '../components/ProductSkeletonLoader';
import { TooltipV3 } from '../../../../../../components/TooltipV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { GhgProductReport, Lens, ReportType } from '../../../../../../api';
import { simplify, roundToLong } from '../../../../shared';
import { RefObject } from 'react';

interface Props {
  data?: GhgProductReport;
  lens: Lens;
  setSelectedReportType: (value: Lens) => void;
  isFoundationEarthWorkspace: boolean;
  productImageRef: RefObject<HTMLImageElement>;
}

export const GhgOverview = (props: Props) =>
  props.data ? (
    <Overview
      data={props.data}
      lens={props.lens}
      setSelectedReportType={props.setSelectedReportType}
      isFoundationEarthWorkspace={props.isFoundationEarthWorkspace}
    >
      <>
        <div className='mt-6 font-semibold flex gap-x-4 bg-amber-50 border border-amber-400 rounded-lg p-4'>
          <FontAwesomeIcon className='mt-1 h-5 aspect-square text-amber-400' icon={regular('exclamation-triangle')} />
          Results presented in this section cover emissions from production-related facilities, goods or services only. Everything
          non-production related needs to be added before final reporting. In addition, scope 1 and 2 calculation rely mostly on secondary
          data and as such should be used as reference only.
        </div>
        <div className='grid grid-cols-3 gap-x-4 mt-6 text-dark'>
          <BasicProductDetailsTile selectedReportType={Lens.Ghg} data={props.data} />
          <div className='flex flex-col gap-4 col-span-2'>
            <div className='grid grid-cols-2 gap-x-4 text-lg'>
              <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 bg-slate-50'>
                <div className='font-semibold'>First Party Data</div>
                <div className='text-[32px]'>{props.data.firstPartyDataPercentage}%</div>
              </div>
              <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 bg-slate-50'>
                <div className='font-semibold'>Total emissions</div>
                <div className='flex flex-col gap-y-4 justify-center flex-1'>
                  <div className='flex items-center gap-x-2'>
                    <div title={roundToLong(props.data.analysis.totalEmission.value)} className='text-[32px]'>
                      {simplify(props.data.analysis.totalEmission.value)}
                    </div>
                    <div className='text-xs text-zinc-500 pt-1 uppercase'>{props.data.analysis.totalEmission.unit}</div>
                  </div>
                  <div className='flex items-center gap-x-2'>
                    <div className='text-[13px] text-zinc-500'>Why the difference with PEF climate change impact?</div>
                    <TooltipV3
                      placement='bottom-start'
                      content={
                        <div className='max-w-[360px] flex flex-col gap-y-4 text-sm bg-[#e8eaf5] p-3 rounded-md shadow-regular'>
                          <div>
                            The GHG Protocol and PEF have slightly different system boundaries and handle certain allocations differently.
                          </div>
                          <div>
                            For example, the Circular Footprint Formula used for packaging end of life impact calculations in PEF includes
                            credits for material that is recycled and credits for energy that is provided e.g. due to waste incineration. In
                            the GHG Protocol, these credits are not to be reported in the overall emissions, but rather, reported separately
                            as ‘avoided emissions’. The same goes with biogenic emissions.
                          </div>
                          <div>
                            Another example is the system boundary used for intermediate products. Packaging end of life is excluded under
                            PEF while it is required under GHG protocol.
                          </div>
                          <div>
                            Furthermore, while both methods use the characterisations factors from the IPCC reports, the update cycle of the
                            respective methods might be different.
                          </div>
                        </div>
                      }
                    >
                      <div>
                        <FontAwesomeIcon className='text-violet-400' icon={solid('info-circle')} />
                      </div>
                    </TooltipV3>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 h-full text-lg'>
              <div className='font-semibold'>Emissions breakdown</div>
              <div className='grid grid-cols-3 gap-x-4 h-full'>
                {props.data.analysis.scopeEmissions.map((item, index) => (
                  <div key={index} className='flex flex-col bg-slate-50 justify-between gap-y-4 rounded-2xl border py-6 h-full text-center'>
                    <div>
                      <div className='font-semibold'>{item.name}</div>
                      <div className='uppercase text-zinc-400 font-normal text-xs'>emissions</div>
                    </div>
                    <div className='flex justify-center items-center gap-x-2'>
                      <div className='flex justify-center items-center gap-1'>
                        <div title={roundToLong(item.totalEmission.value)} className='text-2xl font-normal'>
                          {simplify(item.totalEmission.value)}
                        </div>
                        <div className='text-zinc-500 text-xs uppercase pt-1 font-normal'>{item.totalEmission.unit}</div>
                      </div>
                      <div className='h-full w-[1px] bg-zinc-200' />
                      <div title={item.totalEmission.sharePercent.toString()} className='text-xl'>
                        {item.totalEmission.sharePercent.toFixed(2)}%
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <GhgProtocol analysis={props.data.analysis} id={props.data?.product.id} reportType={ReportType.ProductReport} />
      </>
    </Overview>
  ) : (
    <ProductSkeletonLoader lens={props.lens} />
  );
