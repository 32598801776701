import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { optionalReportingData } from '../../../../Manufacturing/components/GhgProtocol.tsx/dataModel';
import { GasEmission, GhgAnalysis, GhgValues } from '../../../../../../api';
import { simplify } from '../../../../shared';

interface Props {
  data?: GhgAnalysis;
}

export const OptionalReportingEmissionsTable = (props: Props) => {
  const [expanded, setExpanded] = useState(['Individual gas emissions', 'Avoided emissions']);
  const data: {
    gasEmissions: GasEmission[];
    avoidedEmissions: GhgValues;
  } = props.data ?? {
    gasEmissions: optionalReportingData.gasEmissions,
    avoidedEmissions: optionalReportingData.avoidedEmissions,
  };

  const handleExpandedState = (id1: string) =>
    setExpanded((current) =>
      current.includes(id1) ? current.filter((id2) => !(id2.startsWith(id1) && id2.length >= id1.length)) : [...current, id1],
    );

  const gridFrames = 'grid-cols-[4fr_3fr_3fr]';

  return (
    <div className='flex flex-col mx-3 gap-1.5 divide-y border-y'>
      <div className='flex flex-col'>
        <div>
          <button onClick={() => handleExpandedState('Individual gas emissions')} className='flex py-2.5 pl-3 hover:text-brandDark'>
            <div className='size-5'>
              <FontAwesomeIcon
                size='sm'
                className={cn({ 'rotate-90': expanded.includes('Individual gas emissions') })}
                icon={light('chevron-circle-right')}
              />
            </div>
            <div className='font-semibold'>Individual gas emissions - Scope 3 only</div>
          </button>
        </div>

        {(() => {
          const content = (
            <div className='gap-x-2 text-sm text-dark divide-y border-t'>
              <div className={cn('col-span-3 grid gap-2 text-xs uppercase py-3 text-zinc-500', gridFrames)}>
                <div className='pl-8'>Individual Gases</div>
                <div className='col-span-2'>amount</div>
              </div>

              {data.gasEmissions.map((item, index) => (
                <div key={index}>
                  <div className={cn('col-span-3 grid gap-2', gridFrames)}>
                    <div className='py-2.5 pl-8'>{item.name}</div>
                    <div className='py-2.5 col-span-2'>
                      {simplify(item.totalEmission.value)} {item.totalEmission.unit}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
          return (
            <>
              <div className='print:hidden'>{expanded.includes('Individual gas emissions') && content}</div>
              <div className='hidden print:block'>{content}</div>
            </>
          );
        })()}
      </div>
      <div className='flex flex-col'>
        <div>
          <button onClick={() => handleExpandedState('Avoided emissions')} className='flex py-2.5 pl-3 hover:text-brandDark'>
            <div className='size-5'>
              <FontAwesomeIcon
                size='sm'
                className={cn({ 'rotate-90': expanded.includes('Avoided emissions') })}
                icon={light('chevron-circle-right')}
              />
            </div>
            <div className='font-semibold'>Avoided emissions - Scope 3 only</div>
          </button>
        </div>

        {(() => {
          const content = (
            <div className='gap-x-2 text-sm text-dark divide-y border-t'>
              <div className={cn('col-span-3 grid gap-2 text-xs uppercase py-3', gridFrames)}>
                <div className='pl-8'>Emission types</div>
                <div>Total emissions (excl, Biogenic)</div>
                <div>Biogenic Emissions</div>
              </div>

              <div className={cn('col-span-3 grid gap-2', gridFrames)}>
                <div className='py-2.5 pl-8'>Avoided emissions from packaging recycling</div>
                <div className='py-2.5'>
                  {simplify(data.avoidedEmissions.totalEmission.value)} {data.avoidedEmissions.totalEmission.unit}
                </div>
                <div className='py-2.5'>
                  {simplify(data.avoidedEmissions.totalBiogenic.value)} {data.avoidedEmissions.totalBiogenic.unit}
                </div>
              </div>
            </div>
          );
          return (
            <div>
              <div className='print:hidden'>{expanded.includes('Avoided emissions') && content}</div>
              <div className='hidden print:block'>{content}</div>
            </div>
          );
        })()}
      </div>
    </div>
  );
};
