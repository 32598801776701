import { NavigationButtons } from '../Sku/components/NavigationButtons';
import { BasicDetails } from '../BasicDetails';
import { ImpactDeltaType, ModellingReport, ProductType } from '../../../../../api';
import { GradeBadge } from '../../../../../components/GradeBadge';
import { format } from 'date-fns';
import { simplify, roundToLong } from '../../../shared';
import cn from 'classnames';

interface Props {
  data: ModellingReport;
}

export const Summary = (props: Props) => {
  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>A brief overview of the product being modelled, key results and technical information.</div>
        <NavigationButtons type='icons' next={{ path: '../proposed-changes' }} />
      </div>
      <BasicDetails
        title='Modelled product'
        data={props.data.product}
        fields={[
          { name: 'Product name', value: props.data.product.name },
          {
            name: 'Product type',
            value: {
              [ProductType.Final]: 'Final',
              [ProductType.Intermediate]: 'Intermediate',
              [ProductType.Internal]: 'Internal',
            }[props.data.product.productType],
          },
          { name: 'ID', value: props.data.product.skuId ?? '—' },
          { name: 'GTIN', value: props.data.product.gtin ?? '—' },
          { name: 'Category', value: props.data.product.category?.name ?? '—' },
          { name: 'Net amount', value: `${props.data.product.amount.value}${props.data.product.amount.unit.name}` },
          { name: 'Number of servings', value: props.data.product.servings ?? '—' },
          { name: 'Food type', value: props.data.product.foodType.name },
          ...(() => {
            return props.data.product.economicValue
              ? [
                  {
                    name: 'Economic value',
                    value: `${props.data.product.economicValue.price} ${props.data.product.economicValue.currency.name}/kg`,
                  },
                ]
              : [];
          })(),
          { name: 'Conservation requirements', value: props.data.product.conservation.requirement.name },
        ]}
      />
      <div className='grid grid-cols-2 items-center gap-4 p-8 w-full border rounded-regular shadow-regular print:shadow-none print:flex print:flex-col'>
        <div className='col-span-2 text-2xl font-semibold mb-3'>Model result summary</div>

        <div className='font-semibold'>Number of proposed changes</div>
        <div>{props.data.model.proposedChanges}</div>

        <div className='font-semibold'>Original Product Impact (per net amount)</div>
        <div className='flex items-center gap-1'>
          <div title={roundToLong(props.data.calculations.product.overallImpact)} className='text-sm'>
            {simplify(props.data.calculations.product.overallImpact)}
          </div>
          <div className='text-xs'>weighted person.year</div>
          <div className='uppercase text-xs mx-2'>or</div>
          <div title={roundToLong(props.data.calculations.product.impactPoints)} className='font-semibold text-sm'>
            {simplify(props.data.calculations.product.impactPoints)}
          </div>
          <div className='font-semibold text-xs'>Impact points</div>
        </div>

        <div className='font-semibold'>Modelled Product Impact (per net amount)</div>
        <div className='flex items-center gap-1'>
          <div title={roundToLong(props.data.calculations.model.overallImpact)} className='text-sm'>
            {simplify(props.data.calculations.model.overallImpact)}
          </div>
          <div className='text-xs'>weighted person.year</div>
          <div className='uppercase text-xs mx-2'>or</div>
          <div title={roundToLong(props.data.calculations.model.impactPoints)} className='font-semibold text-sm'>
            {simplify(props.data.calculations.model.impactPoints)}
          </div>
          <div className='font-semibold text-xs'>Impact points</div>
        </div>

        <div className='font-semibold'>Overall Change</div>
        <div
          title={roundToLong(props.data.model.impactDelta.raw)}
          className={cn(
            'font-semibold',
            { 'text-zinc-400': props.data.model.impactDelta.type === ImpactDeltaType.Zero },
            { 'text-lime-700': props.data.model.impactDelta.type === ImpactDeltaType.Lower },
            { 'text-red-500': props.data.model.impactDelta.type === ImpactDeltaType.Higher },
          )}
        >
          {props.data.model.impactDelta.type !== ImpactDeltaType.Zero
            ? `${props.data.model.impactDelta.display} ${props.data.model.impactDelta.type} impact`
            : 'No change'}
        </div>

        {props.data.product.productType === ProductType.Final && (
          <>
            <div className='font-semibold'>Original Product Rating (per 1kg)</div>
            <div className='flex'>
              <GradeBadge grade={props.data.calculations.product.consumerView.overallGrade} />
            </div>
          </>
        )}

        {props.data.product.productType === ProductType.Final && (
          <>
            <div className='font-semibold'>Modelled Product Rating (per 1kg)</div>
            <div className='flex'>
              <GradeBadge grade={props.data.calculations.model.consumerView.overallGrade} />
            </div>
          </>
        )}

        <div className='font-semibold'>Report creator</div>
        <div>{props.data.metadata.author}</div>

        <div className='font-semibold'>Date of report creation</div>
        <div>{format(new Date(props.data.metadata.reportDate), 'dd/MM/yyyy hh:mm')}</div>

        <div className='font-semibold'>Versioning</div>
        <ul className='list-disc ml-3'>
          <li>Methodology v.{props.data.metadata.methodologyVersion}</li>
          <li>Web application v.{props.data.metadata.webAppVersion}</li>
        </ul>
      </div>
      <NavigationButtons next={{ path: '../proposed-changes', label: 'Proposed Changes' }} type='buttons' />
    </div>
  );
};
