import { createContext, PropsWithChildren, useContext } from 'react';
import { getLists, Lists } from '../api';
import { useApiQuery } from './useApiQuery';

const Context = createContext<Lists>({} as any);

export const useLists = () => useContext(Context);

export const ListsProvider = (props: PropsWithChildren<{}>) => {
  const { data } = useApiQuery(getLists());
  return data ? <Context.Provider value={data}>{props.children}</Context.Provider> : <></>;
};
