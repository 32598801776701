import { AgreementType, Facility, ModellingReport, MultipleProductsFacility, request, Supplier } from '.';
import { Amount, Entity, Grade, Impact, ImpactStageMatrix, LifecycleStageImpact } from './shared';

export interface ListKey {
  key?: string;
}

interface ValueWithUnit {
  value: number;
  unit: string;
}

export interface GhgValues {
  totalBiogenic: ValueWithUnit;
  totalEmission: ValueWithUnit;
}

export interface SystemIngredient extends Entity {
  unit: Entity;
}

export interface PackagingType extends Entity {
  packagingMaterials: string[];
  packagingProcesses: string[];
}

export interface SystemMaterial extends Entity {
  materialSubTypes: string[];
}

export enum LifeCycleStageName {
  RawMaterials = 'Raw Materials',
  Production = 'Production',
  Distribution = 'Distribution',
  Use = 'Use',
  EndOfLife = 'End Of Life',
}

export enum ProductState {
  Draft = 'draft',
  Complete = 'complete',
}

export enum ProcessType {
  Industrial = 'industrial',
  Household = 'household',
}

export interface Process extends Entity {
  type: ProcessType;
}

enum Delta {
  Added = 'added',
  Removed = 'removed',
  Updated = 'updated',
  Unmodified = 'unmodified',
}

export interface Ingredient extends Entity, ListKey {
  country?: Entity;
  region?: Entity;
  amount: Amount;
  delta?: Delta;
}

export interface Packaging extends Entity, ListKey {
  country?: Entity;
  region?: Entity;
  material: Material;
  amount: Amount;
  recycledPercent: number;
  disposal: Entity;
  delta?: Delta;
}

export interface Material extends Entity {
  subType: Entity;
}

export interface ConsumerViewImpact {
  id: string;
  name: string;
  grade: Grade;
  svgIconUrl: string;
  iconId: string;
}

export interface MajorProcess extends Entity {
  sharePercent: number;
}

export interface ResourcePayload {
  iconId: string;
  name: string;
  unit: string;
  value: number;
}

export interface Resources {
  electricity: ResourcePayload;
  natural_gas: ResourcePayload;
  water: ResourcePayload;
  water_treatment: ResourcePayload;
}

export interface StageResourceUse extends Entity {
  resources: Resources;
}

export interface Analysis {
  impactStagesMatrix: ImpactStageMatrix[];
  lifecycleStageImpacts: LifecycleStageImpact[];
  majorProcesses: MajorProcess[];
  stageResourceUse: StageResourceUse[];
}

export type GhgCategory = GhgValues & Entity & { isOutOfScope: boolean };

export interface GasEmission {
  id: string;
  name: string;
  totalEmission: ValueWithUnit;
}

export interface SingleProductSupplier {
  supplier: Supplier;
  category: string;
  overrides: {
    from: string;
    to: string;
    agreement: {
      type: AgreementType;
      name: string;
      default: boolean;
    };
    category: string;
  }[];
}

export interface MultipleProductsTransportSupplier {
  productIds: string[];
  productsCount: number;
  supplier: Supplier;
}

export interface SingleProductGhgAnalysis extends GhgAnalysis {
  facilities: ({ category: string } & Facility)[];
  transportSuppliers: SingleProductSupplier[];
}

export interface MultipleProductsGhgAnalysis extends GhgAnalysis {
  facilities: MultipleProductsFacility[];
  transportSuppliers: MultipleProductsTransportSupplier[];
}

export interface GhgAnalysis {
  avoidedEmissions: GhgValues;
  categories: GhgCategory[];
  gasEmissions: GasEmission[];
  //totalBiogenic: ValueWithUnit;
  totalEmission: ValueWithUnit;
  scopeEmissions: ({
    totalEmission: ValueWithUnit & { sharePercent: number };
    totalBiogenic: ValueWithUnit;
  } & Entity)[];
}

export interface ConsumerView {
  overallGrade: Grade;
  impacts: ConsumerViewImpact[];
}

export interface CalculatedEntity {
  analysis: Analysis;
  consumerView: ConsumerView;
  impacts: Impact[];
  overallImpact: number;
  appleIndex: number;
  impactPoints: number;
}

export interface Calculations {
  product: CalculatedEntity;
  model: CalculatedEntity;
  overallChange: {
    value: number;
    changeIsUp: boolean;
  };
  proposedChanges: number;
}

export const getModellingReportV3 = ({ productId, modelId }: { productId: string; modelId: string }) => {
  return request<ModellingReport>('GET', `/v3/products/${productId}/models/${modelId}/report`);
};

export interface ProductRevisionPreview {
  author: string;
  comment: string;
  createdAt: string;
  id: string;
  revision: number;
}

export const getProductUploadUrlsV3 = (productId: string, fileName: string) =>
  request<{ fileUrl: string; uploadUrl: string }>('POST', `/v3/products/${productId}/upload`, { body: { fileName } });
