import { Route, Routes } from 'react-router';
import { Main } from './Main';

export const Targets = () => {
  return (
    <Routes>
      <Route path='*' element={<Main />} />
    </Routes>
  );
};
