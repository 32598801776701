interface Props {
  title: string;
}

export const SkeletonLoader = (props: Props) => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
      <div className='flex gap-x-6'>
        <div
          className='loading-skeleton'
          style={{
            height: '134px',
            width: '150px',
            borderRadius: '1rem',
          }}
        />
        <div
          className='loading-skeleton'
          style={{
            height: '134px',
            width: '677px',
            borderRadius: '1rem',
          }}
        />
      </div>
    </div>
  );
};
