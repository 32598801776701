import { createContext, PropsWithChildren, useContext } from 'react';
import { GenericNode, ModellingPayload } from '../../../../api';

interface Api {
  nodeId: string;
  originalNodes?: { id: string }[];
  payload: ModellingPayload;
  toFormData?: (node: GenericNode) => any;
}

const Context = createContext<Api>(null as any);

export const useOriginalAware = () => useContext(Context);

type Props = PropsWithChildren<Api>;

export const OriginalAwareProvider = ({ children, ...otherProps }: Props) => (
  <Context.Provider value={otherProps}>{children}</Context.Provider>
);
