import { Methodology, ModellingReport } from '../../../../../api';
import { useProfile } from '../../../../../hooks/useProfile';
import { NavigationButtons } from '../Sku/components/NavigationButtons';
import { ProductDetailsConsumerView } from '../Sku/components/ProductDetailsConsumerView';
import bottle from '../bottle.png';

interface Props {
  data: ModellingReport;
}

export const ConsumerView = (props: Props) => {
  const profile = useProfile();
  const isFoundationEarthWorkspace = profile.selectedWorkspace.methodology.type === Methodology.FoundationEarth;

  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm w-full'>
      <div className='flex justify-between gap-8'>
        <div className='text-zinc-500 ml-3 flex flex-col gap-6'>
          {isFoundationEarthWorkspace ? (
            <>
              <div>
                Both the Original and the Modelled products were graded through Foundation Earth. The grading uses a different functional
                unit from that of the rest of the report. Where the report’s functional unit is based on the amount of product sold to the
                consumer, the functional unit for the grade is based on that of the product after preparation by the consumer (as defined by
                the Raw to Cooked ratio of [xx] provided during product creation), scaled up to 1 kilogram to enable comparison between
                products at time of consumption.
              </div>
              <div>
                Simply put, if for example the report were to present the impact of a 1kg box of pasta sold in a store (which would be
                heavier after boiling it in water), the grade will be for 1kg of boiled pasta, ready to eat (only a fraction of what was in
                the pack to start with). This total impact per kilogram is then translated into an A+ to G label by Foundation Earth.
              </div>
            </>
          ) : (
            <>
              <div>
                An overview of how the consumer-facing interface would change based on the proposed changes to the original product.
              </div>
              <div>
                Sustained consumer experiences translate the underlying impact assessment to an estimated product impact rating from A to G,
                where A is great and G is bad. This rating is calculated per kilogram of product to ensure it becomes a means of comparison
                for consumers (apple-to-apple comparison). We are working on adding serving size ratings as well to provide a different view
                to consumers of the environmental impacts of products.
              </div>
              <div>
                The Sustained consumer experience also groups impact categories into 6 overarching categories (from the 16 environmental
                categories impacts are calculated for) and displayed from best to worst. This was done to ensure consumers' understanding of
                the impacts. Our eco-rating pills, showing the product's average environmental rating, can be seamlessly integrated in your
                consumer experiences, physical or digital, as demonstrated here.
              </div>
              <div className='text-violet-800'>
                NB: The information provided in this section is indicative only, and represents the ‘estimated’ impact of your product given
                the assumptions and limitations presented in previous sections. the estimated nature of this rating should be clearly
                displayed if you wish to use it in your own experiences, and any marketing claims you wish to make should be proportional to
                the evidence you’re able to provide to back up your claims.
              </div>
            </>
          )}
        </div>
        <NavigationButtons type='icons' back={{ path: '../interpretation' }} next={{ path: '../appendix' }} />
      </div>

      {!isFoundationEarthWorkspace ? (
        <div className='grid grid-cols-2 gap-6'>
          <div className='flex flex-col gap-6'>
            <div className='text-center font-semibold text-xl'>Original</div>
            <ProductDetailsConsumerView
              scale='scale-100'
              name={props.data.product.name}
              impacts={props.data.calculations.product.consumerView.impacts}
              overallGrade={props.data.calculations.product.consumerView.overallGrade}
              amountValue={props.data.product.amount.value}
              amountUnit={props.data.product.amount.unit.name}
              image={props.data.product.imageUrl ? props.data.product.imageUrl : bottle}
            />
          </div>
          <div className='flex flex-col gap-6'>
            <div className='text-center font-semibold text-xl'>Modelled</div>
            <ProductDetailsConsumerView
              scale='scale-100'
              name={props.data.model.title}
              impacts={props.data.calculations.model.consumerView.impacts}
              overallGrade={props.data.calculations.model.consumerView.overallGrade}
              amountValue={props.data.product.amount.value}
              amountUnit={props.data.product.amount.unit.name}
              image={props.data.product.imageUrl ? props.data.product.imageUrl : bottle}
            />
          </div>
        </div>
      ) : (
        <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
          <div className='text-xl font-semibold p-6 group-hover:text-brand'>Environmental label</div>
          <div className='grid grid-cols-2 px-8 print:px-0 pb-6 mb-6'>
            {[
              { name: 'Original', grade: props.data.calculations.product.consumerView.overallGrade },
              { name: 'Modelled', grade: props.data.calculations.model.consumerView.overallGrade },
            ].map(({ name, grade }) => (
              <div key={name} className='flex flex-col justify-self-center gap-6'>
                <div className='text-xl font-semibold text-center'>{name}</div>
                <img src={`https://cdn.foundation-earth.org/v1/certificate/grade/long/${grade}.svg`} width='300' alt={grade} />
              </div>
            ))}
          </div>
        </div>
      )}

      <NavigationButtons
        type='buttons'
        back={{ path: '../interpretation', label: 'Interpretation' }}
        next={{ path: '../appendix', label: 'Appendix' }}
      />
    </div>
  );
};
