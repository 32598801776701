import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { BaselinePefReport } from '../../../../../../api';
import { ReportTabNavigation } from '../../../../../../components/ReportTabNavigation';
import { simplify } from '../../../../shared';
import { BaselineSummary } from './1_Summary';
import { Volumes } from './2_Volumes';
import { BaselineLcia } from './3_Lcia';
import { BaselinePefInterpretation } from './4_Interpretation';
import { BaselineAppendix } from './5_Appendix';
import { StickyHeader } from '../../../../../../components/StickyHeader';

interface Props {
  data: BaselinePefReport;
  hasError: boolean;
}

export const Report = (props: Props) => {
  const tabs = [
    { path: 'summary', Component: BaselineSummary, label: 'Summary' },
    { path: 'volumes', Component: Volumes, label: 'Volumes' },
    { path: 'lcia', Component: BaselineLcia, label: 'LCIA' },
    { path: 'interpretation', Component: BaselinePefInterpretation, label: 'Interpretation' },
    { path: 'appendix', Component: BaselineAppendix, label: 'Appendix' },
  ];

  const location = useLocation();
  const tab = tabs.find((tab) => location.pathname.endsWith(`/${tab.path}`))!;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.scrollIntoView({ block: 'start' });
  }, [location.pathname]);

  return (
    <div ref={containerRef} className='flex flex-col px-6'>
      <Helmet title='Baseline Annual Volume Footprint Overview' />
      <StickyHeader className='-mx-6 px-6'>
        <div className='text-xl font-semibold'>Baseline Annual Volume Footprint - Full Report</div>
        <NavLink
          to='../overview'
          className='text-base font-semibold shadow-md active:scale-95 border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full print:hidden'
        >
          Return to overview
        </NavLink>
      </StickyHeader>

      <div className='py-6'>
        This Sustained Baseline Volume Footprint report presents the details of the Life Cycle Analysis (LCA) carried out largely following
        the PEF framework on the volumes of products (<span className='font-semibold'>{simplify(props.data.totalUnitCount)}</span> units
        across <span className='font-semibold'>{props.data.productCount}</span> different products) manufactured over the 1-year timeframe
        chosen as baseline (
        <span className='font-semibold'>
          {format(new Date(props.data.startDate), 'dd/MM/yy')} - {format(new Date(props.data.endDate), 'dd/MM/yy')}
        </span>
        ). A summary, an overview of the volumes of each product produced, detailed impact results and interpretations are included in this
        report.
      </div>

      <ReportTabNavigation tabs={tabs} />

      <div className='hidden print:block'>
        {tabs.map(({ path, Component, label }) => (
          <div className='mb-10 break-after-page' key={path}>
            <div className='text-2xl mb-3 font-semibold'>{label}</div>
            {Component(props)}
          </div>
        ))}
      </div>

      <div key={tab.label} className='flex flex-col mb-20 print:hidden pt-6 text-zinc-500'>
        <Routes>
          {tabs.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component data={props.data} />} />
          ))}
        </Routes>
      </div>
    </div>
  );
};
