import { duotone, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { Fragment, PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import { ConsumptionNode, ProductType, ProductV3, ProductionNode, bulkRemovableTags, createProductModelV3 } from '../../../../api';
import { LimitTooltip } from '../../../../components/LimitTooltip';
import { ModalForm, ModalFormApi } from '../../../../components/ModalForm';
import { Toggle } from '../../../../components/Toggle';
import { BestMatchBadge, DefaultBadge, ExactMatchBadge, PlaceholderBadge, UnmatchedBadge } from './Badges';
import { getConsumptionLocations, getPackagings, getProductionFacilities, getStores, getWarehouses } from './dataModel';

interface FormValues {
  untag: boolean;
}

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  draft: boolean;
  addPackagingModal: RefObject<ModalFormApi>;
  addWarehouseModal: RefObject<ModalFormApi>;
  addStoreModal: RefObject<ModalFormApi>;
  goToProductionFacility: (node: ProductionNode) => void;
  goToConsumptionLocation: (node: ConsumptionNode) => void;
  onConfirm: (values: FormValues) => void;
}>;

export const getOptionalChecks = (formik: FormikContextType<ProductV3>, props?: Props) => [
  ...(getPackagings(formik).length > 0
    ? []
    : [
        {
          label: 'Packaging materials',
          icon: {
            icon: duotone('box-open'),
            fg: 'text-lime-900',
            bg: 'bg-lime-50',
          },
          onEdit: () => {
            props!.addPackagingModal.current!.open();
          },
        },
      ]),
  ...getProductionFacilities(formik)
    .filter(({ steps }) => steps.length === 0)
    .map((node) => ({
      label: `Production steps (${node.displayName})`,
      icon: {
        icon: duotone('industry-windows'),
        fg: 'text-rose-900',
        bg: 'bg-rose-50',
      },
      onEdit: () => {
        props!.goToProductionFacility(node);
      },
    })),
  ...(formik.values.productType === ProductType.Final
    ? [
        ...(getWarehouses(formik).length > 0
          ? []
          : [
              {
                label: 'Storage',
                icon: {
                  icon: duotone('warehouse-full'),
                  fg: 'text-fuchsia-900',
                  bg: 'bg-fuchsia-50',
                },
                onEdit: () => {
                  props!.addWarehouseModal.current!.open();
                },
              },
            ]),
        ...(getStores(formik).length > 0
          ? []
          : [
              {
                label: 'Selling via stores',
                icon: {
                  icon: duotone('store'),
                  fg: 'text-blue-900',
                  bg: 'bg-blue-50',
                },
                onEdit: () => {
                  props!.addStoreModal.current!.open();
                },
              },
            ]),
        ...getConsumptionLocations(formik)
          .filter(({ steps }) => steps.length === 0)
          .map((node) => ({
            label: `Consumer preparation (${node.displayName})`,
            icon: {
              icon: duotone('house-user'),
              fg: 'text-cyan-900',
              bg: 'bg-cyan-50',
            },
            onEdit: () => {
              props!.goToConsumptionLocation(node);
            },
          })),
      ]
    : []),
];

export const SaveModal = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm
      ref={props.modalRef}
      formRef={formRef}
      title={props.draft ? 'View report' : 'Saving changes'}
      body={<Body productFormik={formik} {...props} />}
      data={{
        untag: false,
      }}
      validationSchema={yup.object()}
      saveLabel='Confirm'
      onSave={({ values, closeModal }) => {
        props.onConfirm(values);
        closeModal();
      }}
    />
  );
};

interface BodyProps extends Props {
  productFormik: FormikContextType<ProductV3>;
}

const Body = (props: BodyProps) => {
  const { productFormik } = props;
  const formik = useFormikContext<FormValues>();
  const [creatingModel, setCreatingModel] = useState(false);
  const [createLimit, setCreateLimit] = useState(false);

  return (
    <div className='flex flex-col gap-6'>
      {[
        {
          condition: !props.draft,
          render: () => (
            <div className='flex flex-col gap-3 text-neutral-900'>
              <div className='text-base font-semibold'>Are you sure?</div>
              <div>
                Remember that product SKUs are intended as snapshots of how your product actually gets manufactured today. Saving your edits
                will automatically update all reports within your workspace, which may invalidate some existing models.
              </div>
            </div>
          ),
        },
        {
          condition: getOptionalChecks(productFormik, props).length > 0,
          render: () => (
            <div className='flex flex-col gap-3'>
              <div className='text-base font-semibold text-neutral-900'>Empty fields</div>
              <div className='text-neutral-900'>
                We noticed you left some fields empty and we want to confirm that this is intended. Please check through the following list
                to confirm that your product life cycle does not involve:
              </div>
              <div className='mt-2 grid grid-cols-[max-content_1fr] items-center gap-x-10 gap-y-4'>
                {getOptionalChecks(productFormik, props).map((item) => (
                  <Fragment key={item.label}>
                    <div className='flex gap-3 items-center'>
                      <div className={cn('flex justify-center items-center rounded-md h-8 aspect-square', item.icon.fg, item.icon.bg)}>
                        <FontAwesomeIcon size='lg' icon={item.icon.icon} />
                      </div>
                      <div className='text-neutral-600 font-semibold'>{item.label}</div>
                    </div>
                    <div>
                      <button
                        type='button'
                        onClick={() => {
                          props.modalRef!.current!.close();
                          item.onEdit();
                        }}
                        className='text-[#220066] bg-[#E8EAF5] font-semibold rounded-full px-4 py-1.5'
                      >
                        Edit
                      </button>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
          ),
        },
        {
          condition: productFormik.values.metadata.user.flatMap(({ tags }) => tags).some((tag) => bulkRemovableTags.includes(tag)),
          render: () => (
            <div className='flex flex-col gap-3'>
              <div className='text-base font-semibold text-neutral-900'>One final check</div>
              <div className='flex flex-col gap-2 text-neutral-900'>
                <div>Is everything in this graph a true representation of this product’s life cycle?</div>
                <Field name='untag'>{(model: FieldProps) => <Toggle model={model} />}</Field>
              </div>
              {formik.values.untag && (
                <div className='mt-1 flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
                  <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
                  <div>
                    Toggling this to ‘Yes’ would essentially remove all <BestMatchBadge className='inline relative bottom-px' />{' '}
                    <DefaultBadge className='inline relative bottom-px' /> <ExactMatchBadge className='inline relative bottom-px' /> badges
                    that may be present on from the nodes and fields. Only <UnmatchedBadge className='inline relative bottom-px' /> and{' '}
                    <PlaceholderBadge className='inline relative bottom-px' /> badges would remain as these must be manually handled to
                    become valid.
                  </div>
                </div>
              )}
            </div>
          ),
        },
        {
          condition: !props.draft,
          render: () => (
            <div className='flex items-center gap-4 px-6 py-5 text-zinc-800 shadow-[0_0_3px_rgba(0,0,0,0.25)] rounded-2xl'>
              <div className='flex justify-center items-center rounded-full text-zinc-700 bg-[#D6FF00] h-10 aspect-square'>
                <FontAwesomeIcon size='lg' icon={light('lightbulb')} />
              </div>
              <div className='flex flex-col text-neutral-900'>
                <div className='text-base font-semibold'>Did you know?</div>
                <div>
                  You can use our{' '}
                  <LimitTooltip
                    enabled={createLimit}
                    entityName='models limit'
                    valueKey='maxProductModelCount'
                    onDismiss={() => setCreateLimit(false)}
                  >
                    <button
                      type='button'
                      disabled={creatingModel}
                      className={cn('font-semibold underline disabled:cursor-wait', {
                        'text-f': createLimit,
                      })}
                      onClick={() => {
                        setCreatingModel(true);
                        createProductModelV3(productFormik.values.id).call({
                          ok: (response) => {
                            setCreatingModel(false);
                            if (response.errorCode) {
                              setCreateLimit(true);
                            } else {
                              window.open(`/products/${productFormik.values.id}/models/${response.model.id}`, '_blank');
                            }
                          },
                          fail: () => {
                            setCreatingModel(false);
                          },
                        });
                      }}
                    >
                      modelling tool
                    </button>
                  </LimitTooltip>{' '}
                  to test how changing your product properties may affect its impact.
                </div>
              </div>
            </div>
          ),
        },
      ]
        .filter(({ condition }) => condition)
        .map(({ render }, i) => (
          <Fragment key={i}>
            {i > 0 && <div className='h-px bg-zinc-300'></div>}
            {render()}
          </Fragment>
        ))}
    </div>
  );
};
