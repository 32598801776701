import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { RefObject } from 'react';
import { SelectProducts } from '../../../../components/Lists/SelectProducts';
import { ReportType } from '../../../../../../../api';

interface Props {
  parentRef: RefObject<HTMLDivElement | null>;
  dateRange: { start: Date | null; end: Date | null };
  onNext: () => void;
  step: number;
}

export const Step3 = (props: Props) => (
  <>
    <div className='flex flex-col gap-y-4'>
      <div className='font-semibold text-lg'>
        Select all products manufactured between {format(props.dateRange.start!, 'dd/MM/yy')} - {format(props.dateRange.end!, 'dd/MM/yy')}
      </div>
      <div>
        Remember, for this historical assessment to be accurate, you should make sure all the products your company manufactured over this
        time period have been previously created under the{' '}
        <NavLink className='underline' target='_blank' to='/products'>
          Product tab
        </NavLink>
        . You will later need to provide accurate volume data to assess your impact.
      </div>
    </div>
    <SelectProducts onNext={props.onNext} reportType={ReportType.Historical} />
  </>
);
