import { ManufacturingGhgReport } from '../../../../../../api';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { ProductionVolumes } from '../../../components/ProductionVolumes';
import { format } from 'date-fns';

interface Props {
  data: ManufacturingGhgReport;
}

export const Volumes = (props: Props) => {
  return (
    <div className='flex flex-col gap-8 print:text-sm text-dark'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>A summary of the production volumes for the 1-year timeframe chosen as a baseline.</div>
        <NavigationButtons type='icons' back={{ path: '../summary' }} next={{ path: '../goal-and-scope' }} />
      </div>
      <ProductionVolumes
        data={props.data}
        description='A detailed view of the input data, including a list of all products included in the assessment, their names and IDs, and the number of units manufactured over the timeframe selected. The impact of each unit of product listed below is assessed and aggregated according to GHG protocol categorisation to provide the results of this scope 3 assessment.'
        tableProps={{
          title: `Production volumes per product during reporting timeframe (${format(
            new Date(props.data.startDate),
            'dd/MM/yy',
          )} - ${format(new Date(props.data.endDate), 'dd/MM/yy')})`,
          columnName: 'Production volumes',
        }}
      />
      <NavigationButtons
        type='buttons'
        back={{ path: '../summary', label: 'Summary' }}
        next={{ path: '../goal-and-scope', label: 'Goal & Scope' }}
      />
    </div>
  );
};
