import { NavigationButtons } from '../components/NavigationButtons';
import { CollapsibleSection } from '../../CollapsibleSection';
import { GhgProductReport, ProductType } from '../../../../../../api';
import cn from 'classnames';
import { SystemBoundariesGraph } from '../components/SystemBoundariesGraph';

interface Props {
  data: GhgProductReport;
}

export const GoalAndScope = (props: Props) => {
  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>
          An overview of the goal and scope of the assessment carried out including the functional unit, system boundaries, data quality,
          the methodology used, and the underlying assumptions made for each life cycle stage.
        </div>
        <NavigationButtons type='icons' back={{ path: '../details' }} next={{ path: '../results' }} />
      </div>
      <CollapsibleSection title='Goal and scope'>
        <div className='flex flex-col gap-6'>
          <div>
            The goal of this analysis is to provide an estimate of the scope 3 contribution of a given product based on the underlying
            product-level assessment carried out. Scope 1 and 2 emissions are provided as reference but rely mostly on secondary data. A
            detailed list of all scope 3 categories is provided here with details on whether they are in or out of scope of this assessment:
          </div>
          <div className='divide-y'>
            {props.data.analysis.categories.map((category, i) => (
              <div key={i} className='grid grid-cols-2 py-3'>
                <div>
                  Category {i + 1}: {category.name}
                </div>
                <div className={cn({ 'text-gray-400': category.isOutOfScope })}>{category.isOutOfScope ? 'Out of scope' : 'In scope'}</div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Functional Unit'>
        <div className='flex flex-col gap-y-3'>
          <div>The functional unit is:</div>
          <div>
            1 unit of product ({props.data.product.name} - {props.data.product.bruttoAmount.value}
            {props.data.product.bruttoAmount.unit.name}) at the customer’s gate
          </div>
          <div>The results displayed in this report will be valid for the specified functional unit only.</div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title='Methodology'>
        <div className='flex flex-col gap-y-3'>
          <div>
            The results presented in this report were obtained by following the{' '}
            <a
              rel='noreferrer'
              className='underline hover:text-brand'
              target='_blank'
              href='https://ghgprotocol.org/sites/default/files/standards/Corporate-Value-Chain-Accounting-Reporing-Standard_041613_2.pdf'
            >
              Corporate Value Chain (Scope 3) Accounting and Reporting Standard
            </a>
            , using the OpenLCA v.1.11.0 software for emission calculations. Details on the Global Warming Potential (GWP) values assumed
            for each individual gas are presented in the appendix.
          </div>
          <div>The final analysis was made with the following Sustained Impact web application and methodology versions:</div>
          <ul>
            <li>Sustained Impact product v.{props.data.metadata.webAppVersion}</li>
            <li>Sustained Impact methodology v.{props.data.metadata.methodologyVersion}</li>
          </ul>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='System boundaries'>
        <div className='flex flex-col gap-y-6'>
          <div className='flex flex-col gap-y-3'>
            <div>
              The analysis is performed from ‘cradle-to-customer’, or up to the final point of sale with the customer, with the following
              life cycle stages considered:
            </div>
            <ul className='ml-6 list-disc'>
              <li>Extraction and production of raw materials with associated transportation to production facility</li>
              <li>Production of the product by the manufacturer including packaging</li>
              {props.data.product.productType !== ProductType.Internal && (
                <li>Distribution from production facility to the business customer for further processing</li>
              )}
            </ul>
            <div>The diagram below presents the system boundaries considered for this analysis:</div>
          </div>
          <SystemBoundariesGraph data={props.data} />
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Data requirements and quality'>
        <div className='flex flex-col gap-y-3'>
          <div>
            A combination of the self-reported foreground data provided by the client via the Sustained Impact system interface (as
            presented in the Product Details section), and background data from a number of databases was used to estimate the GHG emissions
            to fulfil the functional unit. The following databases were used in the analysis
          </div>
          <ul className='list-disc ml-6'>
            <li className='underline hover:text-brand'>
              <a href='https://ecoinvent.org/the-ecoinvent-database/data-releases/ecoinvent-3-7-1/' rel='noreferrer' target='_blank'>
                Ecoinvent v.3.7.1
              </a>
            </li>
            <li className='underline hover:text-brand'>
              <a href='https://agribalyse.ademe.fr/' rel='noreferrer' target='_blank'>
                Agribalyse v.3.1
              </a>
            </li>
            <li className='underline hover:text-brand'>
              <a href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                Sustained Database v.1.0
              </a>
            </li>
          </ul>

          <div>
            Additionally, Sustained is continuously working on database improvements by creating new datasets for specific processes. These
            new datasets are created based on the databases aforementioned.
          </div>

          <div>
            It should be noted that the self-reported foreground data has not been verified by Sustained and as such, all results presented
            in this report should be interpreted accordingly.
          </div>

          <div>
            With the current Sustained Impact software version, the food manufacturer is given the option to provide through the online
            interface only limited foreground data. This includes the raw materials (ingredients and packaging), their amounts and country
            of origins, production processes and distribution model. Future versions of the Sustained Impact product will allow for
            additional foreground data input.
          </div>

          <div>
            Generic data have been used where foreground data couldn’t be provided: for transportation assumptions throughout each life
            cycle stage or industrial production processes. These data were collected from available background processes in our databases
            or from custom processes created by our team of experts. Bear in mind that both our own and the datasets from the databases come
            with an intrinsic ‘quality’ and uncertainty. The data quality and uncertainty for both background and foreground data will be
            quantified and displayed in future report versions.
          </div>

          <div>
            Finally, where there is uncertainty from the part of the client on any of the foreground data assumptions, such as the specific
            type of plastic wrap, the Sustained defaults are used to approximate the emissions of the product.
          </div>
        </div>
      </CollapsibleSection>
      <NavigationButtons
        type='buttons'
        back={{ path: '../details', label: 'Product Details' }}
        next={{ path: '../results', label: 'Results' }}
      />
    </div>
  );
};
