import { forwardRef } from 'react';
import { PieChart } from '../../../../components/charts/PieChart';
import { RadarChart } from '../../../../components/charts/RadarChart';
import { GhgGraph } from '../../../../components/charts/GhgGraph';
import { TimeframeDisplay } from '../components/TimeframeDisplay';
import { Methodology, Timeframe } from '../types';

interface Props {
  title: string;
  methodology: Methodology;
  timeframe: Timeframe;
}

export const ImpactDetails = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        <TimeframeDisplay value={props.timeframe} />
      </div>
      {props.methodology === Methodology.Ghg ? (
        <div className='bg-white border rounded-2xl'>
          <GhgGraph />
        </div>
      ) : (
        <div className='grid grid-cols-10 gap-4'>
          <div className='col-span-5 flex flex-col gap-8 p-4 bg-white border border-zinc-200 rounded-lg'>
            <div className='font-semibold text-base text-zinc-800'>By impact category</div>
            <div className='flex justify-center items-center pb-8'>
              <RadarChart
                height={230}
                width={440}
                meta={[{ dataKey: 'impactPoints', color: '#4f00ff' }]}
                tooltipType='single'
                payload={
                  [
                    {
                      impactName: 'Climate Change',
                      impactPoints: 23.883246029853165,
                      absSharePercent: 23.88,
                      isMajor: true,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Particulate Matter',
                      impactPoints: 8.657585364622026,
                      absSharePercent: 8.65,
                      isMajor: true,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Resource use, fossils',
                      impactPoints: 7.609419856183753,
                      absSharePercent: 7.61,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Acidification',
                      impactPoints: 7.736906070027465,
                      absSharePercent: 7.74,
                      isMajor: true,
                      keyName: 'Impact',
                    },

                    {
                      impactName: 'Human Toxicity, non-cancer',
                      impactPoints: 7.026200887399235,
                      absSharePercent: 7.03,
                      isMajor: true,
                      keyName: 'Impact',
                    },

                    {
                      impactName: 'Ecotoxicity, freshwater',
                      impactPoints: 5.226802325769769,
                      absSharePercent: 5.23,
                      isMajor: true,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Eutrophication, terrestrial',
                      impactPoints: 5.4819672546865865,
                      absSharePercent: 5.48,
                      isMajor: true,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Land Use',
                      impactPoints: 4.287126119273636,
                      absSharePercent: 4.29,
                      isMajor: true,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Eutrophication, marine',
                      impactPoints: 3.352933505238297,
                      absSharePercent: 3.35,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Resource use, minerals & metals',
                      impactPoints: 2.954927149850019,
                      absSharePercent: 2.95,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Eutrophication, freshwater',
                      impactPoints: 2.6480412561283693,
                      absSharePercent: 2.65,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Photochemical ozone formation, human health',
                      impactPoints: 2.4018397353931547,
                      absSharePercent: 2.4,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Water Scarcity',
                      impactPoints: 1.8170004877044927,
                      absSharePercent: 1.82,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Ionising radiation, human health',
                      impactPoints: 1.0851929818910009,
                      absSharePercent: 1.09,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Human Toxicity, Cancer',
                      impactPoints: 0.9746907903703211,
                      absSharePercent: 0.97,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                    {
                      impactName: 'Ozone Depletion',
                      impactPoints: 0.5365283146938853,
                      absSharePercent: 0.54,
                      isMajor: false,
                      keyName: 'Impact',
                    },
                  ] as any
                }
              />
            </div>
          </div>
          <div className='col-span-3 flex flex-col p-4 bg-white border border-zinc-200 rounded-lg'>
            <div className='font-semibold text-base text-zinc-800'>By life cycle stage</div>
            <div className='flex-1 flex justify-center items-center'>
              <PieChart
                showTicks
                size={250}
                outerRadius={80}
                data={[
                  {
                    name: 'Raw Materials',
                    bgColor: '#5BB7D0',
                    value: 72.70593,
                    isMajor: true,
                  },
                  {
                    name: 'Production',
                    bgColor: '#31A383',
                    value: 6.52438,
                    isMajor: true,
                  },
                  {
                    name: 'Distribution',
                    bgColor: '#96BF73',
                    value: 5.27833,
                    isMajor: false,
                  },
                  {
                    name: 'Use',
                    bgColor: '#8F7DB3',
                    value: 5.54621,
                    isMajor: false,
                  },
                  {
                    name: 'End Of Life',
                    bgColor: '#9BB8DF',
                    value: 9.94515,
                    isMajor: true,
                  },
                ]}
              />
            </div>
          </div>
          <div className='col-span-2 flex flex-col gap-4 p-4 bg-white border border-zinc-200 rounded-lg'>
            <div className='font-semibold text-base text-zinc-800'>Product ranking</div>
            <div className='flex flex-col flex-1'>
              <div className='h-[30%] flex gap-3'>
                <div className='w-16 bg-purple-200 flex justify-center items-center rounded-t'>
                  <div className='inline-flex items-center'>
                    <div className='text-zinc-900 text-base'>30</div>
                    <div className='text-zinc-600 text-[10px]'>%</div>
                  </div>
                </div>
                <div className='flex items-center text-xs text-zinc-500'>Sausage roll</div>
              </div>
              <div className='h-[20%] flex gap-3'>
                <div className='w-16 bg-purple-50 flex justify-center items-center'>
                  <div className='inline-flex items-center'>
                    <div className='text-zinc-900 text-base'>20</div>
                    <div className='text-zinc-600 text-[10px]'>%</div>
                  </div>
                </div>
                <div className='flex items-center text-xs text-zinc-500'>Mince meat</div>
              </div>
              <div className='h-[50%] flex gap-3'>
                <div className='w-16 bg-yellow-200 flex justify-center items-center rounded-b'>
                  <div className='inline-flex items-center'>
                    <div className='text-zinc-900 text-base'>50</div>
                    <div className='text-zinc-600 text-[10px]'>%</div>
                  </div>
                </div>
                <div className='flex items-center text-xs text-zinc-500'>Pork steak</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
