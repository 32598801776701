import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as yup from 'yup';
import { authenticate, updatePasswordFromLink } from '../api';
import { Input } from '../components/Input';
import { LoginFrame } from './LoginFrame';

export const ResetPassword = () => {
  const { email, code } = useParams<{ email: string; code: string }>();
  const navigate = useNavigate();
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const [expired, setExpired] = useState(false);

  return (
    <LoginFrame>
      {expired ? (
        <div className='flex flex-col gap-12 items-center'>
          <div className='w-32 aspect-square flex justify-center items-center rounded-full bg-brandDarkPurple3 text-brandLime'>
            <FontAwesomeIcon icon={regular('times')} size='3x' />
          </div>
          <div className='flex flex-col gap-4 text-lg text-center text-dark'>
            <div>Sorry, this password reset link has expired.</div>
            <div>
              Please{' '}
              <NavLink to='/forgot-password' className='underline text-brandDark'>
                request a new one
              </NavLink>
              .
            </div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            code: code!,
            email: email!,
            newPassword: '',
            password2: '',
          }}
          validationSchema={yup.object().shape({
            newPassword: yup
              .string()
              .min(8, 'Must be at least 8 characters long')
              .test('lower', 'Must contain at least 1 lowercase letter', (value) => /[a-z]/.test(value as string))
              .test('upper', 'Must contain at least 1 uppercase letter', (value) => /[A-Z]/.test(value as string))
              .test('number', 'Must contain at least 1 number', (value) => /[0-9]/.test(value as string)),
            password2: yup.string().test('matching', 'Passwords must match', (value, context) => context.parent.newPassword === value),
          })}
          validateOnBlur={triedSubmitting}
          validateOnChange={triedSubmitting}
          onSubmit={(values, { setSubmitting }) => {
            updatePasswordFromLink(values).call({
              ok: ({ errorCode }) => {
                if (errorCode) {
                  setExpired(true);
                } else {
                  authenticate({ username: values.email, password: values.newPassword }).call(
                    () => navigate('/'),
                    () => setSubmitting(false),
                  );
                }
              },
              fail: () => setSubmitting(false),
            });
          }}
        >
          {({ isSubmitting, isValidating, values }) => (
            <Form className='flex flex-col items-center gap-8'>
              <Helmet title='Reset your password' />
              <div className='text-2xl font-semibold text-black pl-4'>{values.email}</div>
              <div className='text-dark w-80'>
                Create a new password that is at least 8 characters long, with at least 1 uppercase, 1 lowercase and 1 number.
              </div>
              <div className='flex flex-col items-center gap-4'>
                <Field name='newPassword'>
                  {(model: FieldProps<string>) => (
                    <div className='w-80 flex flex-col gap-1.5'>
                      <div className='text-dark ml-4 text-sm'>New password</div>
                      <div className='flex flex-col flex-1 gap-1'>
                        <Input model={model} autoFocus password placeholder='Type in your new password…' />
                        <div className='ml-4 text-red-500 text-xs'>{model.meta.error}</div>
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='password2'>
                  {(model: FieldProps<string>) => (
                    <div className='w-80 flex flex-col gap-1.5'>
                      <div className='text-dark ml-4 text-sm'>Confirm new password</div>
                      <div className='flex flex-col flex-1 gap-1'>
                        <Input model={model} password placeholder='Type in your new password again…' />
                        <div className='ml-4 text-red-500 text-xs'>{model.meta.error}</div>
                      </div>
                    </div>
                  )}
                </Field>
                <button
                  type='submit'
                  disabled={isSubmitting || isValidating}
                  className={cn(
                    'mt-4 flex justify-center border-2 border-[#050505] bg-[#050505] rounded-full px-8 py-2 text-white font-semibold',
                    '[&:active:not(:disabled)]:scale-95',
                    'disabled:cursor-wait',
                  )}
                  onClick={() => setTriedSubmitting(true)}
                >
                  Reset my password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </LoginFrame>
  );
};
