export enum Grade {
  AA = 'A+',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum ImpactId {
  Overall = 'overall',
  PefAcidification = 'pef_acidification',
  PefClimateChange = 'pef_climate_change',
  PefEcotoxicityFreshwater = 'pef_ecotoxicity_freshwater',
  PefEutrophicationFreshwater = 'pef_eutrophication_freshwater',
  PefEutrophicationMarine = 'pef_eutrophication_marine',
  PefEutrophicationTerrestrial = 'pef_eutrophication_terrestrial',
  PefHumanToxicityCancer = 'pef_human_toxicity_cancer',
  PefHumanToxicityNonCancer = 'pef_human_toxicity_non_cancer',
  PefIonisingRadiation = 'pef_ionising_radiation',
  PefLandUse = 'pef_land_use',
  PefOzoneDepletion = 'pef_ozone_depletion',
  PefOzoneFormation = 'pef_ozone_formation',
  PefParticulateMatter = 'pef_particulate_matter',
  PefResourceUseFossils = 'pef_resource_use_fossils',
  PefResourceUseMinerals = 'pef_resource_use_minerals',
  PefWaterScarcity = 'pef_water_scarcity',
}

export interface Pagination {
  nextPageToken: string;
  page: number;
  pageSize: number;
  previousPageToken: string;
  totalResults: number;
}

export interface Entity {
  id: string;
  name: string;
  default?: boolean;
  placeholder?: boolean;
  comment?: string;
}

export interface Amount {
  value: number;
  unit: Entity;
}

export type CreateResponse<T> = T & {
  errorCode?: string;
};

interface AnnotatedText {
  text: string;
  highlight: boolean;
}

export interface ContextualExample {
  id: string;
  imageUrl: string;
  title: string;
  iconId: string;
  changeIsUp?: boolean;
  raw: AnnotatedText[];
  example: AnnotatedText[];
  popUp: AnnotatedText[];
}

export interface Interpretation {
  contextualExamples: ContextualExample[];
}

export interface Impact {
  id: ImpactId;
  name: string;
  contributionPercent: number;
  isMajorImpact: boolean;
  isToxicityRelated: boolean;
  unit: string;
  weightedNormalisedValue: number;
  physicalValue: number;
  normalisedValue: number;
  impactPoints: number;
}

export interface Component {
  id: string;
  name: string;
  absImpactSharePercent: number;
  absImpactValue: number;
  impactSharePercent: number;
  impactValue: number;
  isMajor: boolean;
  impactPoints: number;
  reference?: {
    type: 'intermediate_product';
    id: string;
    amount: number;
    unit: string;
  };
  comment?: string;
  components: Component[];
}

export interface LifecycleStageImpact {
  id: string;
  absImpactSharePercent: number;
  absImpactValue: number;
  impactSharePercent: number;
  impactValue: number;
  name: string;
  isMajorImpact: boolean;
  isMajor: boolean;
  bgColor?: string;
  impactPoints: number;
  components: Component[];
}

export interface ImpactStageMatrix {
  impactId: ImpactId;
  impactName: string;
  impactPoints: number;
  unit: string;
  absPhysicalValue: number;
  absSharePercent: number;
  physicalValue: number;
  sharePercent: number;
  normalisedValue: number;
  weightedNormalisedValue: number;
  isMajorImpact: boolean;
  isToxicityRelated: boolean;
  stages: MatrixStage[];
}

export interface InternalComponent {
  name: string;
  value: number;
  color: string;
  children: InternalComponent[];
  sharePercent: number;
  relevantProduct?: string;
  impactPoints: number;
  isMajor: boolean;
  isMajorStageOrImpact: boolean;
}

export interface MatrixComponent {
  id: string;
  name: string;
  absPhysicalValue: number;
  absSharePercent: number;
  impactValue: number;
  impactPoints: number;
  physicalValue: number;
  sharePercent: number;
  normalisedValue: number;
  weightedNormalisedValue: number;
  comment: string;
  reference?: {
    id: string;
    type: string;
    amount: number;
    unit: string;
  };
  bgColor?: string;
  isMajor: boolean;
  components: MatrixComponent[];
}

export interface MatrixStage {
  name: string;
  impactPoints: number;
  absPhysicalValue: number;
  absSharePercent: number;
  physicalValue: number;
  sharePercent: number;
  normalisedValue: number;
  weightedNormalisedValue: number;
  hue: number;
  isMajor: boolean;
  isMajorStage: boolean;
  bgColor?: string;
  components: MatrixComponent[];
}

export const grades = [Grade.A, Grade.B, Grade.C, Grade.D, Grade.E, Grade.F, Grade.G];
