import cn from 'classnames';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import objectPath from 'object-path';
import { PropsWithChildren, ReactNode, createContext, useContext } from 'react';
import { GenericNode, Tag } from '../../../../api';
import { ModalFormMetadata } from '../../../../components/ModalForm';
import { ControlEventsProvider, useControlEvents } from '../../../../hooks/useControlEvents';
import { renderBadgeForTag } from './Badges';
import { getPrimaryTagFromItems } from './dataModel';

const joinMetadataPathSegments = (segments: string[]) => segments.join('/');

const Context = createContext<{
  getTag: (path: string) => Tag | undefined;
  hasChildrenWithMetadata: () => boolean;
}>(null as any);

export const TaggableFieldsContainer = (
  props: PropsWithChildren<{
    pathPrefix?: string;
  }>,
) => {
  const formik = useFormikContext<GenericNode & ModalFormMetadata>();
  const metadata = formik.values.metadata?.user ?? [];
  const metadataPathPrefix = joinMetadataPathSegments(props.pathPrefix ? [props.pathPrefix, formik.values.id] : []);

  const toMetadataPath = (path: string) => {
    const pathSegments = new Array<string>();
    return joinMetadataPathSegments([
      ...(metadataPathPrefix ? [metadataPathPrefix] : []),
      ...path.split('.').map((segment) => {
        pathSegments.push(segment);
        return (isNaN(parseInt(segment)) ? null : objectPath.get(formik.values, pathSegments.join('.')).id) ?? segment;
      }),
    ]);
  };

  const getMetadataItems = (path: string) => {
    const metadataPath = toMetadataPath(path);
    return metadata.filter((item) => [metadataPath, joinMetadataPathSegments([metadataPath, 'id'])].includes(item.path));
  };

  const getMetadataItemsToRemoveOnTouch = (touchedPath: string) => {
    const metadataPath = toMetadataPath(touchedPath);
    return metadata.filter(({ path }) => path.startsWith(metadataPath));
  };

  const getTag = (path: string) => getPrimaryTagFromItems(getMetadataItems(path));

  const hasChildrenWithMetadata = () => metadata.some(({ path }) => path.startsWith(metadataPathPrefix));

  return (
    <ControlEventsProvider
      onTouched={(touchedPath) => {
        const metadataItemsToRemove = getMetadataItemsToRemoveOnTouch(touchedPath);

        if (metadataItemsToRemove.length > 0) {
          formik.setValues((values) => {
            const newValues = cloneDeep(values);
            newValues.metadata!.user = newValues.metadata!.user.filter(
              (item) => !metadataItemsToRemove.some(({ path }) => item.path === path),
            );
            return newValues;
          });
        }
      }}
    >
      <Context.Provider value={{ getTag, hasChildrenWithMetadata }}>{props.children}</Context.Provider>
    </ControlEventsProvider>
  );
};

export const TaggableField = <T extends any = any>(props: {
  name: string;
  card?: boolean;
  children: (props: FieldProps<T>) => ReactNode;
}) => {
  const { getTag, hasChildrenWithMetadata } = useContext(Context);
  const tag = getTag(props.name);
  const alwaysRenderBadge = props.card && hasChildrenWithMetadata();

  return (
    <div className='flex flex-col gap-2'>
      <Field name={props.name}>{props.children}</Field>
      {(alwaysRenderBadge || tag) && (
        <div
          className={cn('flex ml-1.5', {
            invisible: alwaysRenderBadge && !tag,
          })}
        >
          {renderBadgeForTag(tag ?? Tag.Default)}
        </div>
      )}
    </div>
  );
};

export const TouchedAwareFieldArray = (props: { name: string; children: (props: FieldArrayRenderProps) => ReactNode }) => {
  const controlEvents = useControlEvents();
  return (
    <FieldArray name={props.name}>
      {(model) =>
        props.children({
          ...model,
          remove(index) {
            controlEvents!.touchedPath(`${props.name}.${index}`);
            return model.remove(index);
          },
          replace(index, value) {
            controlEvents!.touchedPath(`${props.name}.${index}`);
            model.replace(index, value);
          },
          handleRemove(index) {
            return () => {
              controlEvents!.touchedPath(`${props.name}.${index}`);
              model.handleRemove(index)();
            };
          },
          handleReplace(index, value) {
            return () => {
              controlEvents!.touchedPath(`${props.name}.${index}`);
              model.handleReplace(index, value)();
            };
          },
        })
      }
    </FieldArray>
  );
};
