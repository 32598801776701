import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatTimeframe } from '../functions';
import { Timeframe } from '../types';

interface Props {
  value: Timeframe;
}

export const TimeframeDisplay = (props: Props) => (
  <div className='flex items-center gap-2 px-2 py-0.5 -my-0.5 border border-slate-200 bg-slate-100 rounded'>
    <FontAwesomeIcon icon={light('calendar')} className='text-brand' />
    <div className='text-zinc-700'>{formatTimeframe(props.value)}</div>
  </div>
);
