import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GhgProductReport, Lens, ProductReport, ProductType } from '../../../../../../api';
import sortBy from 'lodash/sortBy';
import cn from 'classnames';

interface Props {
  data: ProductReport | GhgProductReport;
  selectedReportType: Lens;
}

export const BasicProductDetailsTile = (props: Props) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <div className='flex flex-col rounded-2xl border overflow-hidden'>
      <div className='flex justify-center items-center h-[200px] overflow-hidden p-2'>
        {props.data.product.imageUrl ? (
          <img
            onLoad={() => setShowImage(true)}
            className={cn('max-h-[90%] max-w-full transition-opacity duration-300 opacity-0 m-3', {
              invisible: !showImage,
              'opacity-100': showImage,
            })}
            src={props.data.product.imageUrl}
            alt='_'
          />
        ) : (
          <div className='py-10'>
            <FontAwesomeIcon className='text-neutral-300 text-5xl' icon={solid('box-circle-check')} />
          </div>
        )}
      </div>
      <div className='flex flex-col gap-6 p-6 pt-4'>
        <div className='flex flex-col gap-2'>
          <div title={props.data.product.name} className='font-semibold truncate'>
            {props.data.product.name}
          </div>
          <div className='truncate'>
            {(() => {
              const productType = {
                [ProductType.Final]: 'Final product',
                [ProductType.Intermediate]: 'Intermediate product',
                [ProductType.Internal]: 'Internal product',
              }[props.data.product.productType];

              return props.data.metadata.functionalUnit === 'sku'
                ? `${props.data.product.amount.value}${props.data.product.amount.unit.name} - ${props.data.product.servings} serving${
                    props.data.product.servings === 1 ? '' : 's'
                  } - ${productType}`
                : '1000g';
            })()}
          </div>
        </div>
        <div>ID: {props.data.product.skuId}</div>
        {props.selectedReportType !== Lens.Ghg && (
          <>
            <div className='flex flex-col gap-2'>
              <div className='text-xs text-neutral-500 uppercase'>produced in</div>
              <div
                title={sortBy(props.data.locations.production, 'name')
                  .map((value) => value.name)
                  .join(', ')}
                className='truncate'
              >
                {sortBy(props.data.locations.production, 'name').map((value, i, arr) => (
                  <span key={i}>
                    {value.name}
                    {arr.length - 1 > i ? ', ' : ''}
                  </span>
                ))}
              </div>
            </div>
            {props.data.product.productType === ProductType.Final && (
              <div className='flex flex-col gap-2'>
                <div className='text-xs text-neutral-500 uppercase'>consumed in</div>
                <div
                  title={sortBy(props.data.locations.sale, 'name')
                    .map((value) => value.name)
                    .join(', ')}
                  className='truncate'
                >
                  {sortBy(props.data.locations.sale, 'name').map((value, i, arr) => (
                    <span key={i}>
                      {value.name}
                      {arr.length - 1 > i ? ', ' : ''}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
