import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ModalV3 } from '../../../../../components/ModalV3';
import { GradeBadge } from '../../../../../components/GradeBadge';
import { ContextualExampleTile } from '../../../../../components/ContextualExampleTile';
import { ModellingStackedBarChart } from './components/ModellingStackedBarChart';
import { RadarChart } from '../../../../../components/charts/RadarChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getLifecycleIcon } from '../../../../../icons/Lifecycle';
import { ImpactValueType } from '../Sku/Overview';
import { simplify, roundToLong, roundToShort } from '../../../shared';
import {
  Component,
  ImpactDeltaType,
  ImpactStageMatrix,
  LifecycleStageImpact,
  MatrixStage,
  ModellingReport,
  ProductStage,
  ProductType,
} from '../../../../../api';
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { SingleSelect } from '../../../../../components/SingleSelect';

interface Props {
  data: ModellingReport;
}

enum View {
  Graph = 'Graph view',
  Table = 'Table view',
}

export const Overview = (props: Props) => {
  const [showImage, setShowImage] = useState(false);
  const navigate = useNavigate();
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact' };
  const allImpactCategories: { id: string; name: string; unit?: string }[] = [
    overallImpact,
    ...props.data.calculations.product.impacts.map((impact) => ({
      id: impact.id,
      name: impact.name,
      unit: impact.unit,
    })),
  ];
  const [selectedImpact, setSelectedImpact] = useState(allImpactCategories[0]);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);

  return (
    <div className='-mt-10 text-dark'>
      <Helmet title={`${props.data.model.title} Overview`} />
      <div className='p-6'>
        <div className='flex gap-x-6 items-center justify-between'>
          <div className='flex gap-x-4 items-center truncate'>
            <button
              type='button'
              onClick={() => navigate(-1)}
              className='h-8 aspect-square flex items-center justify-center bg-[#E8EAF5] rounded-lg hover:bg-white hover:border-2 hover:border-brand'
            >
              <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
            </button>
            <div className='text-xl font-semibold truncate'>{props.data.model.title} Overview</div>
          </div>

          <div className='flex items-center gap-x-4'>
            <NavLink
              to={`/products/${props.data.product.id}/models/${props.data.model.id}/graph`}
              className='whitespace-nowrap bg-slate-200 px-4 py-2 rounded-full text-sm font-semibold text-violet-950 active:scale-95'
            >
              Edit Model
            </NavLink>
            <NavLink
              to='../summary'
              className='whitespace-nowrap bg-brand px-4 py-2 rounded-full font-semibold text-sm text-white active:scale-95'
            >
              See full report
            </NavLink>
          </div>
        </div>

        <div className='grid grid-cols-[2fr_1px_3fr] gap-x-8 py-10'>
          <div className='flex gap-x-6'>
            {props.data.product.imageUrl ? (
              <div className='flex items-center justify-items-center'>
                <div className='w-28 h-auto'>
                  <img
                    onLoad={() => setShowImage(true)}
                    className={cn('flex flex-1 rounded-xl', {
                      invisible: !showImage,
                    })}
                    src={props.data.product.imageUrl}
                    alt='_'
                  />
                </div>
              </div>
            ) : (
              <div className='shrink-0 size-28 flex items-center justify-items-center'>
                <FontAwesomeIcon className='text-neutral-300 text-5xl mx-auto' icon={solid('box-circle-check')} />
              </div>
            )}
            <div className='grid grid-cols-[1fr_auto] gap-x-4 items-center whitespace-nowrap'>
              <div className='font-semibold col-span-2 truncate'>{props.data.product.name}</div>
              <div className='col-span-2'>&nbsp;</div>
              <div className='text-sm text-zinc-500'>Product id:</div>
              <div className='truncate'>{props.data.product.id}</div>
              <div className='text-sm text-zinc-500'>Product type:</div>
              <div>
                {
                  {
                    [ProductType.Final]: 'Final product',
                    [ProductType.Intermediate]: 'Intermediate product',
                    [ProductType.Internal]: 'Internal product',
                  }[props.data.product.productType]
                }
              </div>
            </div>
          </div>
          <div className='border-r' />
          <div className='grid grid-cols-[1fr_2fr] gap-x-4 items-center'>
            <div className='text-sm text-zinc-500'>Net amount</div>
            <div>
              {props.data.product.amount.value}
              {props.data.product.amount.unit.name}
            </div>

            <div className='text-sm text-zinc-500'>Category</div>
            <div>{props.data.product.category?.name ?? '—'}</div>

            <div className='text-sm text-zinc-500'>Conservation requirements</div>
            <div>{props.data.product.conservation.requirement.name}</div>

            <div className='text-sm text-zinc-500'>Status</div>
            <div>
              {
                {
                  [ProductStage.Development]: 'In development',
                  [ProductStage.Production]: 'In production',
                }[props.data.product.stage]
              }
            </div>
          </div>
        </div>
      </div>

      <div className='flex justify-center bg-slate-50 border-y border-zinc-300 -mx-6 pb-20 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='px-12 py-6 w-full items-center justify-center max-w-screen-xl'>
          <div className='flex flex-col gap-y-6'>
            <div className='uppercase text-zinc-500 text-xs pl-2'>proposed changes summary</div>
            <div className='flex gap-x-6'>
              <div className='bg-white border rounded-2xl p-6'>
                <div className='font-semibold'>Total</div>
                <div className='text-3xl mt-4'>{props.data.calculations.proposedChanges}</div>
                <div className='uppercase text-[10px] text-zinc-500 mt-2'>modelled changes</div>
              </div>

              <div className='flex flex-col bg-white border rounded-2xl p-6'>
                <div className='flex justify-between gap-x-6'>
                  <div className='font-semibold'>Most positively impactful changes</div>
                  <ModalV3
                    hideConfirm
                    cancelLabel='close'
                    size='wide'
                    title={<div>Positively impactful changes (impact reducing), high to low</div>}
                    body={
                      <div className='-mt-6 text-base'>
                        <table className='table-fixed w-full'>
                          <thead>
                            <tr className='border-y'>
                              <th className='py-3'>Proposed change</th>
                            </tr>
                          </thead>
                          <tbody className=''>
                            {props.data.proposedChanges.map((item, i) => (
                              <tr className='even:bg-slate-50' key={i}>
                                <td className='py-3'>
                                  <div className='flex items-center gap-x-3'>
                                    <div className='size-6'>{getLifecycleIcon(item.iconId)}</div>
                                    <div>{item.text}</div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    }
                  >
                    <button
                      className={cn(
                        { hidden: props.data.proposedChanges.length <= 3 },
                        'size-8 hover:text-brand active:scale-95 hover:outline text-zinc-400 flex items-center justify-center rounded-md',
                      )}
                    >
                      <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
                    </button>
                  </ModalV3>
                </div>
                {props.data.calculations.proposedChanges > 0 && props.data.proposedChanges.length === 0 && (
                  <div className='m-auto text-xs uppercase text-zinc-500'>No Impact-reducing change</div>
                )}
                {props.data.calculations.proposedChanges === 0 && props.data.proposedChanges.length === 0 && (
                  <div className='m-auto text-xs uppercase text-zinc-500'>No Changes made</div>
                )}
                {props.data.proposedChanges.length > 0 &&
                  props.data.proposedChanges.slice(0, 3).map((item, i) => (
                    <div key={i} className='flex gap-x-2 items-center'>
                      <div className='size-6'>{getLifecycleIcon(item.iconId)}</div>
                      <div>{item.text}</div>
                    </div>
                  ))}
              </div>
            </div>

            <div className='uppercase text-zinc-500 text-xs pl-2'>overall impact comparison</div>

            <div className='grid grid-cols-2 gap-x-6'>
              <div className='flex flex-col gap-y-3 bg-white border rounded-2xl p-6'>
                <div className='font-semibold'>Total environmental impact</div>
                <div className='grid grid-cols-[1fr_1px_1fr] gap-x-6'>
                  <div className='flex flex-col gap-y-3'>
                    <div className='flex flex-col gap-y-1'>
                      <div className='text-3xl' title={roundToLong(props.data.calculations.product.impactPoints)}>
                        {simplify(props.data.calculations.product.impactPoints)}
                      </div>
                      <div className='uppercase text-zinc-500 text-[10px]'>impact points</div>
                    </div>
                    <div>Original product</div>
                  </div>
                  <div className='border-r' />
                  <div className='flex flex-col gap-y-3'>
                    <div className='flex flex-col gap-y-1'>
                      <div className='flex items-center gap-x-4'>
                        <div className='text-3xl' title={roundToLong(props.data.calculations.model.impactPoints)}>
                          {simplify(props.data.calculations.model.impactPoints)}
                        </div>
                        <div
                          className={cn('flex items-center justify-items-center gap-2 pl-2 pr-3 py-1 rounded-full', {
                            'bg-emerald-50': props.data.model.impactDelta.type === ImpactDeltaType.Lower,
                            'bg-red-50': props.data.model.impactDelta.type === ImpactDeltaType.Higher,
                            'bg-gray-100': props.data.model.impactDelta.type === ImpactDeltaType.Zero,
                          })}
                        >
                          {props.data.model.impactDelta.type !== ImpactDeltaType.Zero ? (
                            <>
                              <div
                                className={cn('flex items-center size-4 rounded-full', {
                                  'bg-emerald-700': props.data.model.impactDelta.type === ImpactDeltaType.Lower,
                                  'bg-f': props.data.model.impactDelta.type === ImpactDeltaType.Higher,
                                })}
                              >
                                <FontAwesomeIcon
                                  className='text-white mx-auto text-xs'
                                  icon={
                                    props.data.model.impactDelta.type === ImpactDeltaType.Lower ? solid('arrow-down') : solid('arrow-up')
                                  }
                                />
                              </div>
                              <div
                                className={cn({
                                  'text-emerald-700': props.data.model.impactDelta.type === ImpactDeltaType.Lower,
                                  'text-f': props.data.model.impactDelta.type === ImpactDeltaType.Higher,
                                })}
                              >
                                {props.data.model.impactDelta.display}
                              </div>
                            </>
                          ) : (
                            <div className='text-zinc-500 text-xs'>No impact change</div>
                          )}
                        </div>
                      </div>
                      <div className='uppercase text-zinc-500 text-[10px]'>impact points</div>
                    </div>
                    <div className='text-brand'>Modelled product</div>
                  </div>
                </div>
              </div>
              {props.data.product.productType === ProductType.Final ? (
                <div className='flex flex-col gap-y-3 bg-white border rounded-2xl p-6'>
                  <div className='font-semibold'>Consumer grading</div>
                  <div className='grid grid-cols-[1fr_1px_1fr] gap-6 h-full'>
                    <div className='flex flex-col justify-between'>
                      <div className='flex'>
                        <GradeBadge grade={props.data.calculations.product.consumerView.overallGrade} />
                      </div>
                      <div>Original product</div>
                    </div>
                    <div className='border-r' />
                    <div className='flex flex-col justify-between'>
                      <div className='flex'>
                        <GradeBadge grade={props.data.calculations.model.consumerView.overallGrade} />
                      </div>
                      <div className='text-brand'>Modelled product</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>

            <div className='flex flex-col gap-y-2'>
              <Collapsible<(view: View) => ReactNode>
                showToggle
                title='Impact by category'
                content={(view) =>
                  view === View.Graph ? (
                    <div className='grid grid-cols-[1fr_2fr_1fr]'>
                      <div className='flex flex-col gap-y-4 text-zinc-500'>
                        <div className='text-[10px] uppercase'>key</div>
                        <div className='flex flex-col gap-y-2'>
                          {[
                            { name: 'Original', color: 'bg-dark' },
                            { name: 'Modelled', color: 'bg-brand' },
                            { name: 'Most relevant', color: 'bg-amber-400' },
                          ].map(({ name, color }, i) => (
                            <div className='flex items-center gap-x-1 text-sm' key={i}>
                              <div className={cn('size-2.5 rounded-full', color)} />
                              {name}
                            </div>
                          ))}
                        </div>
                      </div>
                      <RadarChart
                        meta={[
                          { dataKey: 'original', color: '#000000' },
                          { dataKey: 'modelled', color: '#4f00ff' },
                        ]}
                        payload={props.data.calculations.product.impacts.map((impact) => ({
                          impactName: impact.name,
                          original: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)!.impactPoints,
                          originalAbsSharePercent: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)!
                            .contributionPercent,
                          isMajorOriginal: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)?.isMajorImpact,
                          modelled: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)!.impactPoints,
                          modelledAbsSharePercent: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)!
                            .contributionPercent,
                          isMajorModelled: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)?.isMajorImpact,
                          keyName: undefined,
                          isMajor: impact.isMajorImpact,
                        }))}
                        tooltipType='multiple'
                      />
                      <div />
                    </div>
                  ) : (
                    (() => {
                      const impacts = props.data.calculations.product.analysis.impactStagesMatrix.map((productImpact) => ({
                        name: productImpact.impactName,
                        product: productImpact,
                        model: props.data.calculations.model.analysis.impactStagesMatrix.find(
                          (modelImpact) => modelImpact.impactId === productImpact.impactId,
                        )!,
                      }));

                      return (
                        <div className='grid grid-cols-3 gap-x-10'>
                          <div />
                          <div className='pb-3'>Original Product</div>
                          <div className='text-brand pb-3'>Modelled Product</div>

                          <div className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3 border-y uppercase text-xs text-zinc-500'>
                            <div>Impact category</div>

                            <div className='grid grid-cols-2 gap-x-2'>
                              <div>Physical impact</div>
                              <div>Contribution</div>
                            </div>

                            <div className='grid grid-cols-2 gap-x-2'>
                              <div>Physical impact</div>
                              <div>Contribution</div>
                            </div>
                          </div>

                          {impacts.map((impact, i) => {
                            const renderCell = (item: ImpactStageMatrix) => (
                              <div className='grid grid-cols-2 gap-x-3 items-center'>
                                <div title={roundToLong(item.physicalValue)}>
                                  {simplify(item.physicalValue)} {item.unit}
                                </div>
                                <div className='grid grid-cols-2 gap-x-2 items-center'>
                                  <div>{roundToShort(item.sharePercent)}%</div>
                                  <div>
                                    <FontAwesomeIcon
                                      className={cn('text-xl text-amber-400', { hidden: !item.isMajorImpact })}
                                      icon={solid('seal-exclamation')}
                                    />
                                  </div>
                                </div>
                              </div>
                            );

                            return (
                              <div key={i} className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3'>
                                <div>{impact.name}</div>
                                {renderCell(impact.product)}
                                {renderCell(impact.model)}
                              </div>
                            );
                          })}

                          <div className='grid grid-cols-subgrid gap-x-10 col-span-3 pt-3'>
                            <div className='text-brandDark font-semibold'>Total</div>
                            <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                              <div></div>
                              <div>100%</div>
                            </div>
                            <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                              <div></div>
                              <div>100%</div>
                            </div>
                          </div>
                        </div>
                      );
                    })()
                  )
                }
              />
              <Collapsible<(view: View) => ReactNode>
                showToggle
                title='Impact by Life Cycle Stage'
                content={(view) =>
                  view === View.Graph ? (
                    <ImpactByLifeCycleStage data={props.data} />
                  ) : (
                    (() => {
                      const stages = props.data.calculations.product.analysis.lifecycleStageImpacts.map((productStage) => ({
                        name: productStage.name,
                        product: productStage,
                        model: props.data.calculations.model.analysis.lifecycleStageImpacts.find(
                          (modelStage) => modelStage.id === productStage.id,
                        )!,
                      }));

                      return (
                        <div className='-mt-8'>
                          <div className='flex items-center justify-center gap-x-6 pb-6'>
                            <div className='w-60'>
                              <SingleSelect
                                selectedId={selectedImpact.id}
                                options={allImpactCategories.map(({ id, name }) => ({ id, name }))}
                                value={{
                                  value: selectedImpact.id,
                                  label: selectedImpact.name,
                                }}
                                setSelectedId={(v) => {
                                  if (v === 'overall_impact') {
                                    setSelectedImpactValueType(ImpactValueType.Points);
                                  }
                                  setSelectedImpact(allImpactCategories.find((impact) => impact.id === v)!);
                                }}
                              />
                            </div>

                            <button
                              type='button'
                              disabled={selectedImpact.id === overallImpact.id}
                              className='flex items-center gap-2'
                              onClick={() =>
                                setSelectedImpactValueType((current) =>
                                  current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                                )
                              }
                            >
                              <div
                                className={cn(
                                  'flex w-7 h-4 p-0.5 rounded-full',
                                  selectedImpactValueType === 'physical' ? 'justify-end bg-brand' : 'bg-gray-300',
                                )}
                              >
                                <div className='bg-white rounded-full w-3 aspect-square'></div>
                              </div>
                              <div className={cn({ 'text-zinc-400': selectedImpact.id === overallImpact.id })}>Physical impact</div>
                            </button>
                          </div>

                          <div className='grid grid-cols-[1fr_2fr_2fr] gap-x-10'>
                            <div />
                            <div className='pb-3'>Original Product</div>
                            <div className='text-brand pb-3'>Modelled Product</div>

                            <div className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3 border-y uppercase text-xs text-zinc-500'>
                              <div>Life cycle stage</div>

                              <div className='grid grid-cols-2 gap-x-2'>
                                <div>Final ENV. impact</div>
                                <div>Contribution</div>
                              </div>

                              <div className='grid grid-cols-2 gap-x-2'>
                                <div>Final ENV. impact</div>
                                <div>Contribution</div>
                              </div>
                            </div>

                            {selectedImpact.id === 'overall_impact' ? (
                              <>
                                {stages.map((stage, i) => {
                                  const renderCell = (item: LifecycleStageImpact) => (
                                    <div className='grid grid-cols-2 gap-x-3 items-center'>
                                      <div title={roundToLong(item.impactPoints)}>{simplify(item.impactPoints)} Impact points</div>
                                      <div className='grid grid-cols-2 gap-x-2 items-center'>
                                        <div title={roundToLong(item.impactSharePercent)}>{roundToShort(item.impactSharePercent)}%</div>
                                        <div>
                                          <FontAwesomeIcon
                                            className={cn('text-xl text-amber-400', { hidden: !item.isMajorImpact })}
                                            icon={solid('seal-exclamation')}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );

                                  return (
                                    <div key={i} className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3'>
                                      <div>{stage.name}</div>
                                      {renderCell(stage.product)}
                                      {renderCell(stage.model)}
                                    </div>
                                  );
                                })}

                                <div className='grid grid-cols-subgrid gap-x-10 col-span-3 pt-3'>
                                  <div className='text-brandDark font-semibold'>Total</div>
                                  <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                                    <div>{simplify(props.data.calculations.product.impactPoints)} Impact points</div>
                                    <div>100%</div>
                                  </div>
                                  <div className='text-brandDark grid grid-cols-2 gap-x-3'>
                                    <div>{simplify(props.data.calculations.model.impactPoints)} Impact points</div>
                                    <div>100%</div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              (() => {
                                const productStagesOfSelectedImpact = props.data.calculations.product.analysis.impactStagesMatrix.find(
                                  ({ impactId }) => selectedImpact.id === impactId,
                                );

                                const stages = productStagesOfSelectedImpact?.stages.map((productStage) => ({
                                  name: productStage.name,
                                  product: productStage,
                                  model: props.data.calculations.model.analysis.impactStagesMatrix
                                    .find(({ impactId }) => selectedImpact.id === impactId)!
                                    .stages.find(({ name }) => name === productStage.name)!,
                                }))!;

                                return (
                                  <div className='grid grid-cols-subgrid gap-x-10 col-span-3 pt-3'>
                                    {stages.map((stage, i) => {
                                      const renderValue = () => {
                                        if (selectedImpactValueType === ImpactValueType.Points) {
                                          return `${simplify(stage.product.impactPoints)} Impact points`;
                                        } else {
                                          const selectedImpactUnit = allImpactCategories.find(
                                            (impact) => impact.id === selectedImpact.id,
                                          )!.unit;
                                          return `${simplify(stage.product.physicalValue)} ${selectedImpactUnit}`;
                                        }
                                      };

                                      const renderCell = (item: MatrixStage) => {
                                        return (
                                          <div className='grid grid-cols-2 gap-x-3 items-center'>
                                            <div>{renderValue()}</div>
                                            <div className='grid grid-cols-2 gap-x-2 items-center'>
                                              <div>{roundToShort(item.sharePercent)}%</div>
                                              <div>
                                                <FontAwesomeIcon
                                                  className={cn('text-xl text-amber-400', { hidden: !item.isMajorStage })}
                                                  icon={solid('seal-exclamation')}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      };

                                      return (
                                        <div key={i} className='grid grid-cols-subgrid gap-x-10 col-span-3 py-3'>
                                          <div>{stage.name}</div>
                                          <div>{renderCell(stage.product)}</div>
                                          <div>{renderCell(stage.model)}</div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              })()
                            )}
                          </div>
                        </div>
                      );
                    })()
                  )
                }
              />
              {(() => {
                const resolveProcessNames = (component: Component, path: string = '', result: { name: string; value: number }[] = []) => {
                  if (component.components.length > 0) {
                    component.components
                      .filter(({ isMajor }) => isMajor)
                      .flatMap((component: Component) => {
                        const newPath = path ? `${path} > ${component.name}` : component.name;
                        return resolveProcessNames(component, newPath, result);
                      });
                  } else {
                    result.push({
                      name: path,
                      value: component.impactSharePercent,
                    });
                  }
                  return result;
                };

                const executeProcesses = (lifecycleStageImpacts: LifecycleStageImpact[]) => {
                  return lifecycleStageImpacts
                    .filter(({ isMajor }) => isMajor)
                    .flatMap((stage) =>
                      stage.components.filter(({ isMajor }) => isMajor).flatMap((component) => resolveProcessNames(component)),
                    );
                };

                const productMostRelevantProcesses = executeProcesses(props.data.calculations.product.analysis.lifecycleStageImpacts);
                const modelMostRelevantProcesses = executeProcesses(props.data.calculations.model.analysis.lifecycleStageImpacts);

                const totalRows = Math.max(productMostRelevantProcesses.length, modelMostRelevantProcesses.length);

                const href = URL.createObjectURL(
                  new Blob(
                    [
                      [
                        [
                          'Original Product - Process',
                          'Original Product - Contribution (%)',
                          'Modelled Product - Process',
                          'Modelled Product - Contribution (%)',
                        ].join(','),
                        new Array(totalRows)
                          .fill(null)
                          .map((_, i) =>
                            [
                              productMostRelevantProcesses[i]?.name,
                              productMostRelevantProcesses[i]?.value,
                              modelMostRelevantProcesses[i]?.name,
                              modelMostRelevantProcesses[i]?.value,
                            ].join(','),
                          )
                          .join('\n'),
                      ].join('\n'),
                    ],
                    {
                      type: 'text/csv',
                    },
                  ),
                );

                return (
                  <Collapsible
                    showToggle={false}
                    title='Most Relevant Processes'
                    exportButton={
                      <a
                        className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base'
                        download={`${props.data.model.title} - Most relevant processes.csv`}
                        href={href}
                      >
                        <FontAwesomeIcon icon={solid('download')} />
                        Export as CSV
                      </a>
                    }
                    content={() => {
                      const tableOfProcesses = (mostRelevantProcesses: { name: string; value: number }[]) => (
                        <table className='w-full table-fixed'>
                          <thead className='uppercase text-xs text-zinc-500'>
                            <tr className='border-y'>
                              <th colSpan={2} className='p-3'>
                                process
                              </th>
                              <th className='p-3'>contribution</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mostRelevantProcesses.map((process, i) => (
                              <tr key={i}>
                                <td title={process.name} colSpan={2} className='p-3 truncate'>
                                  {process.name}
                                </td>
                                <td title={`${roundToLong(process.value)}%`} className='p-3'>
                                  {roundToShort(process.value)}%
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      );

                      return (
                        <div className='grid grid-cols-2 gap-x-6'>
                          <div className='flex flex-col gap-y-3'>
                            <div className='pl-3'>Original Product</div>
                            {tableOfProcesses(productMostRelevantProcesses)}
                          </div>

                          <div className='flex flex-col gap-y-3'>
                            <div className='pl-3 text-brand'>Modelled Product</div>
                            {tableOfProcesses(modelMostRelevantProcesses)}
                          </div>
                        </div>
                      );
                    }}
                  />
                );
              })()}
              <Collapsible
                showToggle={false}
                title='Difference in physical impact'
                content={() => (
                  <div className='flex flex-col gap-y-6'>
                    <div>
                      To help you compare these two products with more realistic numbers the difference in impact should be considered on
                      more than a single product. As such, the results below show the difference for 100 units of these two products.
                    </div>
                    <div className='grid grid-cols-3 gap-6 print:block'>
                      {props.data.interpretation.contextualExamples.map((contextualExample, i) => (
                        <ContextualExampleTile key={i} contextualExample={contextualExample} />
                      ))}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Collapsible = <T extends (view: View) => ReactNode>(props: {
  title: string;
  content: T;
  showToggle: boolean;
  exportButton?: ReactNode;
}) => {
  const [view, setView] = useState<View>(View.Graph);
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='bg-white text-dark border print:border-none rounded-2xl print:rounded-none print:shadow-none'>
      <div className='flex justify-between items-center px-6'>
        <button onClick={() => setExpanded(!expanded)} className='flex gap-x-2 items-center justify-between  py-4 hover:text-brand'>
          <FontAwesomeIcon
            className={cn('size-4 print:hidden transition-transform duration-75', { 'rotate-90': expanded })}
            icon={light('chevron-circle-right')}
          />
          <div className='font-semibold'>{props.title}</div>
        </button>

        <select
          value={view}
          onChange={({ target }) => setView(target.value as View)}
          className={cn('border border-dark rounded-full text-sm px-2 pr-3 py-1.5 focus:ring-0 hover:cursor-pointer', {
            hidden: !expanded || !props.showToggle,
          })}
        >
          <option value={View.Graph}>{View.Graph}</option>
          <option value={View.Table}>{View.Table}</option>
        </select>
        {expanded && props.exportButton}
      </div>
      {expanded && <div className='p-8 print:hidden'>{props.content(view)}</div>}
      <div className='hidden print:block p-8'>{props.content(view)}</div>
    </div>
  );
};

const ImpactByLifeCycleStage = (props: { data: ModellingReport }) => {
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact' };
  const allImpactCategories: { id: string; name: string; unit?: string }[] = [
    overallImpact,
    ...props.data.calculations.product.impacts.map((impact) => ({
      id: impact.id,
      name: impact.name,
      unit: impact.unit,
    })),
  ];
  const [selectedImpact, setSelectedImpact] = useState(allImpactCategories[0]);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);

  return (
    <div className='grid grid-cols-[1fr_2fr_1fr]'>
      <div className='flex flex-col gap-y-6'>
        <SingleSelect
          selectedId={selectedImpact.id}
          options={allImpactCategories.map(({ id, name }) => ({ id, name }))}
          value={{
            value: selectedImpact.id,
            label: selectedImpact.name,
          }}
          setSelectedId={(v) => {
            if (v === 'overall_impact') {
              setSelectedImpactValueType(ImpactValueType.Points);
            }
            setSelectedImpact(allImpactCategories.find((impact) => impact.id === v)!);
          }}
        />

        <button
          type='button'
          disabled={selectedImpact.id === overallImpact.id}
          className='flex items-center gap-2'
          onClick={() =>
            setSelectedImpactValueType((current) =>
              current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
            )
          }
        >
          <div
            className={cn(
              'flex w-7 h-4 p-0.5 rounded-full',
              selectedImpactValueType === 'physical' ? 'justify-end bg-brand' : 'bg-gray-300',
            )}
          >
            <div className='bg-white rounded-full w-3 aspect-square'></div>
          </div>
          <div className={cn({ 'text-zinc-400': selectedImpact.id === overallImpact.id })}>Physical impact</div>
        </button>

        <div className='flex flex-col gap-y-4 text-zinc-500'>
          <div className='text-[10px] uppercase'>key</div>
          <div className='grid grid-cols-2 gap-2'>
            {props.data.calculations.product.analysis.impactStagesMatrix[0].stages.map(({ name, bgColor }, i) => (
              <div className='flex items-center gap-x-1 text-sm' key={i}>
                <div style={{ backgroundColor: bgColor }} className={cn('size-2.5 rounded-full')} />
                {name}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='col-span-2'>
        <ModellingStackedBarChart
          data={props.data.calculations}
          selectedImpact={selectedImpact}
          selectedImpactValueType={selectedImpactValueType}
        />
      </div>
    </div>
  );
};
