import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingReportCreationMethod } from '../../../types';

interface Props {
  selectedMethod: ManufacturingReportCreationMethod | undefined;
  setSelectedMethod: (flow: ManufacturingReportCreationMethod) => void;
  onNext: () => void;
}

export const Step1 = (props: Props) => {
  return (
    <>
      <div className='flex flex-col gap-y-4'>
        <div className='font-semibold text-lg'>How would you like to enter products and volumes?</div>
        <div className=''>
          Choose if you want to select your products and add production volumes manually or if you would prefer us to extract this from an
          existing CSV file.
        </div>
      </div>
      <div className='grid grid-cols-2 gap-x-4 my-4'>
        {[
          {
            type: ManufacturingReportCreationMethod.Manual,
            title: 'Enter manually',
            description: 'Choose manually all products that you’ve produced over the selected timeframe and assign a quantity to each.',
          },
          {
            type: ManufacturingReportCreationMethod.Import,
            title: 'Import CSV file',
            description: 'Upload a CSV file with products, product IDs and volumes.',
          },
        ].map(({ title, description, type }, index) => (
          <button
            key={index}
            onClick={() => props.setSelectedMethod(type)}
            className={cn('flex flex-col gap-y-4 p-4 rounded-lg border hover:bg-slate-50', {
              'bg-slate-50 outline outline-brand': props.selectedMethod === type,
            })}
          >
            <div className='font-semibold'>{title}</div>
            <div className='text-sm'>{description}</div>
          </button>
        ))}
      </div>
      <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
        <div className='px-12 py-4 flex justify-end w-full max-w-screen-xl'>
          <button
            type='button'
            disabled={!props.selectedMethod}
            className={cn(
              'flex self-end text-lg items-center gap-2.5 border-2 rounded-full px-4 py-1 font-semibold',
              '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
              'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
            )}
            onClick={props.onNext}
          >
            Next
            <FontAwesomeIcon className='text-base' icon={solid('chevron-right')} />
          </button>
        </div>
      </div>
    </>
  );
};
