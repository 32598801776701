import {
  cloneElement,
  CSSProperties,
  HTMLProps,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import InfiniteScroll from '../../../components/InfiniteScroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getProductsV3, ProductGeneral, ProductType } from '../../../api';
import { simplify, roundToLong } from '../shared';
import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { useDebounce } from '../../../hooks/useDebounce';
import cn from 'classnames';

type ProductListModalProps = {
  filter: { supplierId: string } | { facilityId: string };
  name: string;
  usedInProducts: number;
};

export const InfiniteScrollProductListModal = (props: PropsWithChildren<ProductListModalProps>) => {
  const firstRender = useRef(true);
  const [data, setData] = useState<{
    totalResults: number;
    nextPageToken: string;
    list: ProductGeneral[];
  }>({
    totalResults: 0,
    nextPageToken: '',
    list: [],
  });

  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebounce(searchString, 300);

  const defaultSearchParams = {
    pageSize: 25,
    sortBy: 'totalImpact',
    sortAscending: 'false',
  };
  const [modalBodyRef, setModalBodyRef] = useState();

  const getParentReference = useCallback((node) => {
    if (node) {
      setModalBodyRef(node);
    }
  }, []);

  const onNext = () =>
    getProductsV3({ ...props.filter, ...{ pageToken: data.nextPageToken, ...defaultSearchParams } }).ok((data) =>
      setData((current) => ({
        nextPageToken: data.nextPageToken,
        list: [...current.list, ...data.products],
        totalResults: data.totalResults,
      })),
    );

  useEffect(() => {
    if (!firstRender.current) {
      getProductsV3({ ...props.filter, ...{ contains: debouncedSearchString, ...defaultSearchParams } }).ok((data) =>
        setData({
          nextPageToken: data.nextPageToken,
          list: data.products,
          totalResults: data.totalResults,
        }),
      );
    }
    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  return (
    <Modal
      scrollableContainer={getParentReference}
      title={
        <div className='flex flex-col'>
          <div>Products using {props.name}</div>
          <div className='flex items-center gap-6 self-end'>
            <div className='text-base text-zinc-500 bg-slate-100 py-1.5 px-2.5 rounded-lg'>
              {data.totalResults} Product SKU{data.totalResults === 1 ? '' : "'s"}
            </div>
            <div className='flex items-center relative text-base'>
              <input
                type='search'
                className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                autoFocus
                placeholder='Find…'
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
              />
              <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
            </div>
          </div>
        </div>
      }
      onOpenChange={(open) => {
        if (open) {
          getProductsV3({ ...props.filter, ...defaultSearchParams }).ok((data) =>
            setData({
              totalResults: data.totalResults,
              nextPageToken: data.nextPageToken,
              list: data.products,
            }),
          );
        } else {
          setSearchString('');
          setData({
            totalResults: 0,
            nextPageToken: '',
            list: [],
          });
        }
      }}
      body={
        data.list.length > 0 ? (
          <>
            <div className='grid gap-x-2 grid-cols-[4fr_2fr_2fr_3fr_2fr_1fr] sticky top-0 py-3 bg-white border-b border-b-zinc-200 font-semibold text-base'>
              <div className='pl-3'>Product name</div>
              <div>Product type</div>
              <div>Net amount</div>
              <div>Sku id</div>
              <div>Impact points</div>
              <div />
            </div>
            {data.list.length > 0 && (
              <InfiniteScroll
                next={onNext}
                loader={<></>}
                hasMore={data.nextPageToken !== ''}
                dataLength={data.list.length}
                scrollableTarget={modalBodyRef}
              >
                <div className='divide-y'>
                  {data.list.map((product, i) => (
                    <div key={i} className='grid gap-x-2 grid-cols-[4fr_2fr_2fr_3fr_2fr_1fr] items-center'>
                      <div title={product.name} className='truncate pl-3'>
                        {product.name}
                      </div>
                      <div>
                        {
                          {
                            [ProductType.Final]: 'Final',
                            [ProductType.Intermediate]: 'Intermediate',
                            [ProductType.Internal]: 'Internal',
                          }[product.productType]
                        }
                      </div>
                      <div>
                        {product.amount.value}
                        {product.amount.unit.name}
                      </div>
                      <div title={product.skuId} className='truncate'>
                        {product.skuId}
                      </div>
                      <div className='font-semibold' title={roundToLong(product.impactPoints)}>
                        {simplify(product.impactPoints)}
                      </div>
                      <NavLink
                        to={`/products/${product.id}/report/production/overview`}
                        target='_blank'
                        className={cn(
                          'flex items-center justify-center size-7 rounded-lg my-2',
                          'bg-slate-200 hover:bg-white hover:border active:scale-95',
                        )}
                      >
                        <FontAwesomeIcon icon={light('file-chart-column')} />
                      </NavLink>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}
          </>
        ) : (
          <div className='py-8 text-center'>No results found</div>
        )
      }
    >
      {props.children}
    </Modal>
  );
};

interface ModalProps {
  title: ReactNode;
  open?: boolean;
  onClose?: () => void;
  onOpenChange?: (open: boolean) => void;
  preventDismiss?: boolean;
  children: ReactNode;
  body: ReactNode;
  scrollableContainer: (node: ReactNode) => void;
}

const Modal = (props: ModalProps) => {
  const [open, setOpen] = useState(props.open ?? false);

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);

    if (!newOpen && props.onClose) {
      props.onClose();
    }

    if (props.onOpenChange) {
      props.onOpenChange(newOpen);
    }
  };

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, { outsidePointerDown: !props.preventDismiss }),
  ]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && <Content {...props} floating={floating} getFloatingProps={getFloatingProps} setOpen={context.onOpenChange} />}
      </FloatingPortal>
    </>
  );
};

interface ModalContentProps {
  title: ReactNode;
  floating: (node: HTMLElement | null) => void;
  getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
  setOpen: (open: boolean) => void;
  body: ReactNode;
  scrollableContainer: (node: ReactNode) => void;
}

const Content = (props: ModalContentProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  return (
    <FloatingOverlay lockScroll className='flex justify-center items-center bg-neutral-400/75 z-50'>
      <div
        {...props.getFloatingProps({
          ref: props.floating,
        })}
        className='antialiased text-sm text-body m-8 bg-white shadow-2xl border rounded-xl w-full max-w-6xl'
      >
        <div className='flex flex-col'>
          <div ref={headerRef} className='flex flex-col font-semibold text-xl text-neutral-900 p-6'>
            {props.title}
          </div>
          <div
            ref={props.scrollableContainer}
            style={
              {
                '--header-height': `${headerRef.current ? headerRef.current.getBoundingClientRect().height : 0}px`,
                '--footer-height': `${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}px`,
              } as CSSProperties
            }
            className={cn(
              'overflow-y-auto border rounded-2xl mx-6',
              'max-h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height)_-_var(--footer-height))]',
            )}
          >
            {props.body}
          </div>
          <div ref={footerRef} className='flex justify-between p-6'>
            <button
              type='button'
              className={cn(
                'flex justify-center border-2 border-brand rounded-full px-4 py-1 text-brand font-semibold whitespace-nowrap',
                'active:scale-95',
              )}
              onClick={() => props.setOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </FloatingOverlay>
  );
};
