import { ProductionVolumes } from '../../../components/ProductionVolumes';
import { HistoricalPefReport } from '../../../../../../api';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { format } from 'date-fns';

interface Props {
  data: HistoricalPefReport;
}

export const Volumes = (props: Props) => {
  return (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5'>
          A detailed look at the production volumes provided during report creation for each product, with product names, type, ID and
          volumes in number of units manufactured. These volumes represent the number of units manufactured over the chosen timeframe.
        </div>
        <NavigationButtons type='icons' back={{ path: '../summary' }} next={{ path: '../progress-tracking' }} />
      </div>
      <ProductionVolumes
        description='A detailed view of the input data, including a list of all products included in the assessment, their names and IDs, and the number
        of units manufactured over the timeframe selected. The impact of each unit of product listed below is assessed and aggregated to
        provide the results of the life cycle assessment.'
        tableProps={{
          title: `Production volumes per product during reporting timeframe (${format(new Date(props.data.startDate), 'dd/MM/yy')} -
          ${format(new Date(props.data.endDate), 'dd/MM/yy')})`,
          columnName: 'production volumes',
        }}
        data={props.data}
      />
      <NavigationButtons
        back={{ path: '../summary', label: 'Summary' }}
        next={{ path: '../progress-tracking', label: 'Progress Tracking' }}
        type='buttons'
      />
    </div>
  );
};
