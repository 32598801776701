import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  showForecast?: boolean;
}

export const ProgressChartLegend = (props: Props) => (
  <div className='flex flex-col justify-between'>
    <div className='flex flex-col gap-y-2'>
      <div className='flex gap-x-2 p-3 bg-slate-100 rounded-lg mb-3'>
        <FontAwesomeIcon className='text-sm text-brand mt-0.5' icon={light('info-circle')} />
        Click on columns, baseline and target to see more info.
      </div>
      <div className='uppercase text-zinc-400 text-[10px]'>key</div>
      <div className='grid grid-cols-2 gap-3'>
        <div className='flex items-center gap-x-2'>
          <div className='relative -top-[5px] w-10'>
            <div className='absolute border-l-[5px] border-l-brandDarkPurple2 border-t-[4px] border-b-[4px] border-r-brandDarkPurple2 border-transparent' />
            <div className='absolute top-[3px] h-[2px] bg-brandDarkPurple2 w-full' />
            <div className='absolute right-0 border-r-[5px] border-r-brandDarkPurple2 border-t-[4px] border-b-[4px] border-transparent' />
          </div>
          <div className='text-xs'>Baseline</div>
        </div>
        <div className='flex items-center gap-x-2 w-full'>
          <div className='h-[2px] bg-fuchsia-400 w-10' />
          <div className='text-xs'>Target</div>
        </div>

        {props.showForecast && (
          <div className='col-span-2 flex items-center gap-x-2 w-full'>
            <div className='w-10 border-t-2 border-dashed border-amber-400'></div>
            <div className='text-xs'>Forecasted impact</div>
          </div>
        )}

        <div className='flex items-center gap-x-2 w-full'>
          <div className='relative w-10 h-3 bg-gray-100'>
            <svg className='absolute' width='100%' height='100%'>
              <pattern width='6' height='6' id='pattern_1' patternTransform='rotate(45)' patternUnits='userSpaceOnUse'>
                <line x1='0' y='0' x2='0' y2='6' strokeWidth='4' stroke='#e9d5ff' />
              </pattern>
              <rect fill='url(#pattern_1)' width='100%' height='100%' />
            </svg>
          </div>
          <div className='text-xs'>Missing data</div>
        </div>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='relative w-10 h-3 bg-zinc-100'></div>
          <div className='text-xs'>Estimation</div>
        </div>
      </div>
    </div>
  </div>
);
