import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Fragment, useState } from 'react';
import { ConsumptionNode, ConsumptionPreparationNode, Model, ProductV3, ProductionProcess, StepInput } from '../../../../../../../api';
import { useLists } from '../../../../../../../hooks/useLists';
import { getConsumptionLocationsFromAll } from '../../../../Details/dataModel';

interface Props {
  product: ProductV3;
  model: Model;
}

export const Use = (props: Props) => {
  const [expandedWrapper, setExpandedWrapper] = useState(true);
  const [expandedConsumptionLocations, setExpandedConsumptionLocations] = useState(true);

  const content = (() => {
    const productConsumptionLocations = getConsumptionLocationsFromAll(props.product.nodes).map((node) => node);
    const modelConsumptionLocations = getConsumptionLocationsFromAll(props.model.nodes);
    const uniqConsumptionLocations = uniqBy([...productConsumptionLocations, ...modelConsumptionLocations], 'id');

    return (
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-3'>
          <button onClick={() => setExpandedConsumptionLocations(!expandedConsumptionLocations)} className='flex items-center gap-2'>
            <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedConsumptionLocations })} icon={regular('chevron-right')} />
            <div className='h-7 aspect-square flex items-center justify-center text-cyan-900 bg-cyan-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('house-user')} />
            </div>
            <div>Consumption locations</div>
          </button>
          {expandedConsumptionLocations && (
            <div className='flex flex-col gap-3'>
              {uniqConsumptionLocations.map((consumptionLocation, i) => (
                <Fragment key={i}>
                  <ConsumptionLocation
                    product={props.product}
                    model={props.model}
                    productConsumptionLocation={productConsumptionLocations.find((node) => node.id === consumptionLocation.id)}
                    modelConsumptionLocation={modelConsumptionLocations.find((node) => node.id === consumptionLocation.id)}
                  />
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  })();

  return (
    <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
      <button
        disabled
        onClick={() => {
          setExpandedWrapper(!expandedWrapper);
          if (expandedWrapper) {
          }
        }}
        className='grid grid-cols-3 items-center w-full px-8 print:px-0'
      >
        <div className='grid grid-cols-3 col-span-2 items-center'>
          <div className='text-xl font-semibold py-6'>Use</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden invisible'
          icon={expandedWrapper ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {expandedWrapper && <div className='px-8 pb-8 text-dark print:hidden'>{content}</div>}
      <div className='px-8 print:px-0 pb-8 hidden print:block'>{content}</div>
    </div>
  );
};

type ConsumptionLocationPayload = ConsumptionNode;

interface ConsumptionLocationProps {
  product: ProductV3;
  model: Model;
  productConsumptionLocation?: ConsumptionLocationPayload;
  modelConsumptionLocation?: ConsumptionLocationPayload;
}

const ConsumptionLocation = (props: ConsumptionLocationProps) => {
  const [expanded, setExpanded] = useState(true);

  const productConsumptionLocationSteps = props.productConsumptionLocation?.steps;
  const modelConsumptionLocationSteps = props.modelConsumptionLocation?.steps;

  const allUniqConsumptionLocationSteps = uniqBy(
    [...(productConsumptionLocationSteps ?? []), ...(modelConsumptionLocationSteps ?? [])],
    'id',
  );

  return (
    <div className='flex flex-col gap-y-3 rounded-xl shadow bg-neutral-50 p-3'>
      <button onClick={() => setExpanded(!expanded)} className='flex w-full items-center justify-between gap-2'>
        <div className='flex items-center gap-2 pl-1 font-semibold'>
          <div className='h-7 aspect-square flex items-center justify-center text-cyan-900 bg-cyan-50 rounded-md border'>
            <FontAwesomeIcon icon={duotone('house-user')} />
          </div>
          <div>{props.productConsumptionLocation?.displayName ?? props.modelConsumptionLocation?.displayName}</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden'
          icon={expanded ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {expanded &&
        allUniqConsumptionLocationSteps.map((step, i) => {
          const productStep = props.productConsumptionLocation?.steps.find(({ id }) => step.id === id);
          const modelStep = props.modelConsumptionLocation?.steps.find(({ id }) => step.id === id);

          return (
            <Fragment key={i}>
              <ConsumptionProcessStep
                processName={productStep?.displayName ?? modelStep?.displayName}
                productStep={productStep}
                modelStep={modelStep}
                product={props.product}
                model={props.model}
              />
            </Fragment>
          );
        })}
    </div>
  );
};

interface ConsumptionProcessStepProps {
  processName?: string;
  product: ProductV3;
  model: Model;
  productStep?: ConsumptionPreparationNode;
  modelStep?: ConsumptionPreparationNode;
}

const ConsumptionProcessStep = (props: ConsumptionProcessStepProps) => {
  const [expanded, setExpanded] = useState(true);
  const lists = useLists();

  return (
    <div
      className={cn('p-3 bg-white rounded-xl shadow border', {
        'border-red-500': props.productStep && !props.modelStep,
        'border-emerald-700': !props.productStep && props.modelStep,
      })}
    >
      <div className='flex'>
        <div className='flex self-start items-center gap-2'>
          <button onClick={() => setExpanded(!expanded)} className='flex gap-2 items-center pl-1 text-sm font-semibold'>
            <div className='h-7 aspect-square flex items-center justify-center text-cyan-900 bg-cyan-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('pan-frying')} />
            </div>
            <div>{props.processName}</div>
          </button>
        </div>
      </div>
      {expanded && (
        <div className='grid grid-cols-3 gap-y-3'>
          <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
            <div className='col-span-2'></div>
            <div>
              {!props.productStep && props.modelStep && <div className='text-emerald-700 text-sm'>Added</div>}
              {props.productStep && !props.modelStep && <div className='text-red-500 text-sm'>Removed</div>}
            </div>

            <div></div>
            <div className='text-violet-900 font-semibold'>Original</div>
            <div className='text-violet-900 font-semibold'>Modelled</div>

            <div>Final production step</div>
            <div>
              {(() => {
                if (
                  (props.productStep && !props.modelStep) ||
                  (props.productStep && props.modelStep && props.productStep.finalStep === props.modelStep.finalStep)
                ) {
                  return props.productStep.finalStep ? 'Yes' : 'No';
                }

                return '';
              })()}
            </div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productStep && props.modelStep) ||
                  (props.productStep && props.modelStep && props.productStep.finalStep !== props.modelStep.finalStep)
                ) {
                  return (
                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelStep.finalStep ? 'Yes' : 'No'}
                    </div>
                  );
                }
                return '';
              })()}
            </div>
          </div>

          {(() => {
            const productIngredientStepInputs = props.productStep?.inputs;
            const modelIngredientStepInputs = props.modelStep?.inputs;

            const allIngredientInputs = [
              ...(productIngredientStepInputs ? productIngredientStepInputs : []),
              ...(modelIngredientStepInputs ? modelIngredientStepInputs : []),
            ];

            const uniqIngredientInputs = uniqBy(allIngredientInputs, 'id');

            const getProductInputData = (input: StepInput) => {
              const finalProduct = input.item;
              const nodeInput = props.product.nodes.find(({ id }) => id === input.id);
              getConsumptionLocationsFromAll(props.product.nodes).find((node) =>
                node.steps.find((step) => step.outputs.find((output) => output.id === input.id)),
              );
              const inputFromOtherStepOutput = getConsumptionLocationsFromAll(props.product.nodes)
                .flatMap((node) => node.steps.flatMap((step) => step.outputs.find((output) => output.id === input.id)))
                .filter((output) => output)
                .find((output) => output?.id === input.id);

              if (finalProduct) {
                return {
                  ...finalProduct,
                  id: finalProduct.id,
                  name: finalProduct.name,
                  amount: props.product.amount,
                };
              } else if (nodeInput) {
                return {
                  ...nodeInput,
                  id: nodeInput.id,
                  name: nodeInput.displayName,
                  amount: (nodeInput as { amount: { unit: { name: string }; value: number } }).amount,
                };
              } else if (inputFromOtherStepOutput) {
                return {
                  ...inputFromOtherStepOutput,
                  id: inputFromOtherStepOutput.id,
                  name: inputFromOtherStepOutput.name,
                  amount: inputFromOtherStepOutput.amount,
                };
              }
            };

            const getModelInputData = (input: StepInput) => {
              const finalProduct = input.item && input;
              const nodeInput = props.model.nodes.find(({ id }) => id === input.id);

              const inputFromOtherStepOutput = getConsumptionLocationsFromAll(props.model.nodes)
                .flatMap((node) => node.steps.flatMap((step) => step.outputs.find((output) => output.id === input.id)))
                .filter((output) => output)
                .find((output) => output?.id === input.id);

              if (finalProduct) {
                return {
                  ...finalProduct.item,
                  id: finalProduct.item?.id,
                  name: finalProduct.item?.name,
                  amount: {
                    unit: {
                      name: '',
                    },
                    value: finalProduct.amountValue,
                  },
                };
              } else if (nodeInput) {
                return {
                  ...nodeInput,
                  id: nodeInput.id,
                  name: nodeInput.displayName,
                  amount: (nodeInput as { amount: { unit: { name: string }; value: number } }).amount,
                };
              } else if (inputFromOtherStepOutput) {
                return {
                  ...inputFromOtherStepOutput,
                  id: inputFromOtherStepOutput.id,
                  name: inputFromOtherStepOutput.name,
                  amount: inputFromOtherStepOutput.amount,
                };
              }
            };

            return (
              <>
                <div className='col-span-3 border-t'></div>
                <div className='col-span-3'>
                  <div className='flex items-center gap-2 pl-1.5'>
                    <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
                    <div className='font-semibold'>Inputs</div>
                  </div>
                </div>
                <div className='col-span-3 p-3 w-full bg-slate-100 uppercase text-xs font-semibold'>required</div>
                <div className='col-span-3 grid grid-cols-3 gap-y-3'>
                  <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
                    <div>Input(s)</div>
                    <div className='flex flex-wrap gap-2'>
                      {(() => {
                        if (productIngredientStepInputs?.length === 0) {
                          return '';
                        } else if (productIngredientStepInputs && productIngredientStepInputs.length > 0) {
                          return productIngredientStepInputs.map((input, i) => {
                            const relatedInput = getProductInputData(input);
                            return (
                              <div
                                title={relatedInput?.name}
                                key={i}
                                className='truncate text-center py-0.5 px-2 rounded-full bg-slate-100 font-semibold'
                              >
                                {relatedInput?.name}
                              </div>
                            );
                          });
                        }
                      })()}
                    </div>
                    <div className='flex flex-wrap gap-2'>
                      {(() => {
                        if (modelIngredientStepInputs && modelIngredientStepInputs?.length > 0) {
                          return modelIngredientStepInputs
                            .filter((modelInput) => !productIngredientStepInputs?.find(({ id }) => modelInput.id === id))
                            .map((input, i) => {
                              const relatedInput = getModelInputData(input);
                              return (
                                <div
                                  title={relatedInput?.name}
                                  key={i}
                                  className='truncate text-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'
                                >
                                  {relatedInput?.name}
                                </div>
                              );
                            });
                        }
                      })()}
                    </div>
                  </div>
                  {uniqIngredientInputs.map((input, i) => {
                    const fromProductArray = productIngredientStepInputs?.find(({ id }) => id === input.id);
                    const fromModelArray = modelIngredientStepInputs?.find(({ id }) => id === input.id);

                    const productInputData = fromProductArray && getProductInputData(fromProductArray);
                    const modelInputData = fromModelArray && getModelInputData(fromModelArray);

                    return (
                      <div
                        key={i}
                        className={cn('grid grid-cols-3 gap-2 col-span-3 border rounded-xl p-3', {
                          'border-red-500': productInputData && !modelInputData,
                          'border-emerald-700': !productInputData && modelInputData,
                        })}
                      >
                        <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
                          <div className='col-span-3 grid grid-cols-3'>
                            <div className='col-span-2 flex items-center gap-x-2 self-center text-base'>
                              <div className='h-7 aspect-square flex items-center justify-center text-purple-800 bg-purple-50 rounded-md border'>
                                <FontAwesomeIcon icon={duotone('box-circle-check')} />
                              </div>
                              <div>{productInputData?.name ?? modelInputData?.name}</div>
                            </div>
                            {!productInputData && modelInputData && <div className='text-emerald-600'>Added</div>}
                            {productInputData && !modelInputData && <div className='text-red-500'>Removed</div>}
                          </div>

                          <div>Quantity used in this step ({productInputData?.amount?.unit.name ?? modelInputData?.amount?.unit.name})</div>
                          <div>
                            {(() => {
                              if (props.productStep && productInputData?.amount) {
                                return productInputData?.amount?.value;
                              }

                              return '';
                            })()}
                          </div>
                          <div className='flex'>
                            {(() => {
                              if (
                                (!props.productStep && props.modelStep) ||
                                (props.productStep &&
                                  props.modelStep &&
                                  productInputData &&
                                  modelInputData &&
                                  productInputData?.amount?.value !== modelInputData?.amount?.value)
                              ) {
                                return (
                                  <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                    {modelInputData?.amount?.value}
                                  </div>
                                );
                              }

                              return '';
                            })()}
                          </div>
                        </div>
                        <div></div>
                      </div>
                    );
                  })}
                </div>
                {(() => {
                  const productStepProcessId = props.productStep?.process.id;
                  const modelStepProcessId = props.modelStep?.process.id;

                  const productProcessDuration = (props.productStep?.process as ProductionProcess & { duration?: number })?.duration;
                  const modelProcessDuration = (props.modelStep?.process as ProductionProcess & { duration?: number })?.duration;

                  const productProcessDurationDefault = lists.processes.find(({ id }) => id === productStepProcessId)?.duration;
                  const modelProcessDurationDefault = lists.processes.find(({ id }) => id === modelStepProcessId)?.duration;

                  return (
                    (productProcessDuration || modelProcessDuration || productProcessDurationDefault || modelProcessDurationDefault) && (
                      <>
                        <div className='col-span-3 p-3 w-full bg-slate-100 uppercase text-xs font-semibold'>optional</div>
                        <div className='col-span-3 grid grid-cols-3 gap-y-3'>
                          <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm px-3 items-center'>
                            <div>Duration</div>
                            <div>
                              {(() => {
                                if (
                                  (!productProcessDuration && !modelProcessDuration && productProcessDurationDefault) ||
                                  productProcessDurationDefault === modelProcessDurationDefault
                                ) {
                                  return `Default ${productProcessDurationDefault} min`;
                                } else if (productProcessDuration && !modelProcessDuration) {
                                  return `${productProcessDuration} min`;
                                }

                                return '';
                              })()}
                            </div>
                            <div className='flex'>
                              {(() => {
                                if (
                                  !productProcessDuration &&
                                  !modelProcessDuration &&
                                  modelProcessDurationDefault &&
                                  modelProcessDurationDefault !== productProcessDurationDefault
                                ) {
                                  return (
                                    <div className='flex self-start py-0.5 px-2 rounded-full bg-gray-400 text-white font-semibold'>
                                      Default {modelProcessDurationDefault} min
                                    </div>
                                  );
                                } else if (modelProcessDuration && !productProcessDuration) {
                                  return (
                                    <div className='flex self-start truncate text-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                      {modelProcessDuration} min
                                    </div>
                                  );
                                }

                                return '';
                              })()}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </>
                    )
                  );
                })()}
              </>
            );
          })()}

          <div className='col-span-3 border-t' />
          <div className='col-span-3'>
            <div className='flex items-center gap-2 pl-1.5'>
              <FontAwesomeIcon icon={regular('arrow-down-to-bracket')} />
              <div className='font-semibold'>Outputs</div>
            </div>
          </div>

          {(() => {
            if (props.productStep?.finalStep || props.modelStep?.finalStep) {
              return (
                <div className='col-span-3 grid grid-cols-3 gap-y-3 text-sm'>
                  <div className='grid grid-cols-3 gap-2 col-span-3 border rounded-xl p-3'>
                    <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
                      <div className='col-span-3 font-semibold'>Output 1</div>

                      <div>Name</div>
                      <div>
                        {(() => {
                          if (
                            (props.productStep && !props.modelStep) ||
                            (props.productStep && props.modelStep && props.productStep.id === props.modelStep.id)
                          ) {
                            return 'Ready to eat';
                          }
                        })()}
                      </div>

                      <div className='flex'>
                        {(() => {
                          if (
                            (!props.productStep && props.modelStep) ||
                            (props.productStep && props.modelStep && props.productStep.id !== props.modelStep.id)
                          ) {
                            return (
                              <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                Ready to eat
                              </div>
                            );
                          }
                        })()}
                      </div>

                      <div>Amount</div>
                      <div>
                        {(() => {
                          if (props.productStep) {
                            return `${props.product.amount.value} ${props.product.amount.unit.name}`;
                          }
                        })()}
                      </div>
                      <div className='flex'>
                        {(() => {
                          if (!props.productStep && props.modelStep) {
                            return (
                              <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                {props.product.amount.value} {props.product.amount.unit.name}
                              </div>
                            );
                          }
                        })()}
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
              );
            }
          })()}

          {(() => {
            const productOutputs = props.productStep?.outputs;
            const modelOutputs = props.modelStep?.outputs;

            const allUniqOutputs = uniqBy([...(productOutputs ?? []), ...(modelOutputs ?? [])], 'id');

            return allUniqOutputs.map((output, i) => {
              const productOutput = productOutputs?.find(({ id }) => id === output.id);
              const modelOutput = modelOutputs?.find(({ id }) => id === output.id);

              return (
                <div key={i} className='col-span-3 grid grid-cols-3 gap-y-3 text-sm'>
                  <div className='grid grid-cols-3 gap-2 col-span-3 border rounded-xl p-3'>
                    <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
                      <div className='col-span-3 font-semibold'>Output {i + 1}</div>

                      <div>Name</div>
                      <div>{productOutput?.name}</div>
                      <div className='flex'>
                        {(() => {
                          if (
                            (!productOutput && modelOutput) ||
                            (productOutput && modelOutput && productOutput.name !== modelOutput.name)
                          ) {
                            return (
                              <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>{modelOutput?.name}</div>
                            );
                          }

                          return '';
                        })()}
                      </div>

                      <div>Amount</div>
                      <div>
                        {productOutput?.amount?.value} {productOutput?.amount?.unit.name}
                      </div>
                      <div className='flex'>
                        {(() => {
                          if (
                            (modelOutput && !productOutput) ||
                            (modelOutput && productOutput && modelOutput.amount?.value !== productOutput.amount?.value)
                          ) {
                            return (
                              <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                {modelOutput?.amount?.value} {modelOutput?.amount?.unit.name}
                              </div>
                            );
                          }

                          return '';
                        })()}
                      </div>
                    </div>
                    <div />
                  </div>
                </div>
              );
            });
          })()}
        </div>
      )}
    </div>
  );
};
