import { ForecastReport } from '../../../../../api';
import { ReportSummary } from '../../components/ReportSummary';
import { simplify, roundToLong } from '../../../shared';
import { format } from 'date-fns';

interface Props {
  data: ForecastReport;
}

export const ForecastSummary = (props: Props) => {
  const totalImpact = (
    <div className='text-sm'>
      <span title={roundToLong(props.data.overallImpact)} className='font-semibold'>
        {simplify(props.data.overallImpact)}
      </span>{' '}
      weighted person.year OR{' '}
      <span title={roundToLong(props.data.impactPoints)} className='font-semibold'>
        {simplify(props.data.impactPoints)}
      </span>{' '}
      Impact points
    </div>
  );

  const records = [
    { field: 'Forecast cycle length', value: `1 ${props.data.cycle}` },
    { field: 'Number of products produced', value: props.data.productCount },
    { field: 'Total number of manufactured units', value: props.data.totalUnitCount },
    { field: 'Total impact', value: totalImpact },
    { field: 'Report creator', value: props.data.author },
    { field: 'Date of report creation', value: format(new Date(props.data.createdAt), 'dd/MM/yy') },
    {
      field: 'Versioning',
      value: (
        <ul className='list-disc ml-3.5'>
          <li>Methodology v.{props.data.metadata.methodologyVersion}</li>
          <li>Web application v.{props.data.metadata.webAppVersion}</li>
        </ul>
      ),
    },
  ];

  return <ReportSummary records={records} tabDescription='A brief overview of the forecast parameters, main results and inputs.' />;
};
