import { useRef } from 'react';
import { FieldProps } from 'formik';
import { TooltipV3 } from '../TooltipV3';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isWithinInterval, subMonths } from 'date-fns';
import { convertLocalToUTCDate, convertUTCDateToLocal, years } from './utils';
import isEqual from 'lodash/isEqual';
import endOfMonth from 'date-fns/endOfMonth';
import getYear from 'date-fns/getYear';
import setMonth from 'date-fns/setMonth';
import sub from 'date-fns/sub';
import cn from 'classnames';

interface Props {
  model: FieldProps<{ startDate: Date | null; endDate: Date | null } | undefined>;
  disabled?: boolean;
  existedReports:
    | {
        reportName: string;
        dateRange: {
          start: Date;
          end: Date;
        };
      }[]
    | null;
}

export const MonthPicker = (props: Props) => {
  const ref = useRef<ReactDatePicker>(null);
  const onChange = async (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates.map((date) => (date ? convertLocalToUTCDate(date) : null));

    if (isEqual(props.model.field.value?.startDate, startDate) && !endDate) {
      props.model.form.setFieldValue('range', {
        ...props.model.field.value,
        startDate: startDate,
        endDate: startDate ? endOfMonth(startDate) : null,
      });
    } else {
      props.model.form.setFieldValue('range', {
        ...props.model.field.value,
        startDate: startDate,
        endDate: endDate ? endOfMonth(endDate) : null,
      });
    }
  };

  const renderMonthContent = (monthIndex: number, shortMonthText: string, fullMonthText: string, day: Date) => {
    const preSelection = setMonth(day, monthIndex);
    const dataAlreadySet = props.existedReports?.find((report) =>
      isWithinInterval(preSelection, {
        start: convertUTCDateToLocal(report.dateRange.start),
        end: convertUTCDateToLocal(report.dateRange.end),
      }),
    );

    return dataAlreadySet ? (
      <TooltipV3
        content={
          <div className='p-1.5 px-3 text-sm whitespace-nowrap bg-violet-900 text-white rounded-lg shadow'>{dataAlreadySet.reportName}</div>
        }
      >
        <button onClick={(e) => e.stopPropagation()} className='text-zinc-300'>
          {fullMonthText}
        </button>
      </TooltipV3>
    ) : (
      <>{fullMonthText}</>
    );
  };

  return (
    <div
      className={cn('month-picker flex flex-col rounded-full text-sm', props.model.meta.error ? 'border-f focus:ring-focusRingError' : '')}
    >
      <DatePicker
        ref={ref}
        isClearable
        selectsRange
        showMonthYearPicker
        showFullMonthYearPicker
        showFourColumnMonthYearPicker
        disabled={props.disabled}
        onChange={onChange}
        maxDate={endOfMonth(subMonths(new Date(), 1))}
        dateFormat='MM/yyyy'
        endDate={props.model.field.value ? props.model.field.value.endDate : undefined}
        startDate={props.model.field.value ? props.model.field.value.startDate : undefined}
        shouldCloseOnSelect={false}
        className={cn(
          'py-1.5 rounded-full w-32 pl-4 text-sm text-dark border',
          props.model.meta.error && !props.disabled && 'border-f focus:ring-focusRingError',
        )}
        renderMonthContent={renderMonthContent as any}
        renderCustomHeader={({ date, increaseYear, decreaseYear, changeYear }) => {
          return (
            <div className='flex justify-between items-center w-full px-6 py-2'>
              <button type='button' onClick={decreaseYear}>
                <FontAwesomeIcon className='hover:cursor-pointer' icon={solid('chevron-left')} />
              </button>
              <select
                className='focus-visible:ring-0 font-semibold text-base rounded-md hover:cursor-pointer text-center'
                value={getYear(date)}
                onChange={({ target: { value } }) => {
                  return changeYear(getYear(new Date(value)));
                }}
              >
                {years(sub(date, { years: 10 }).getFullYear(), new Date().getFullYear() + 1).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <button type='button' onClick={increaseYear}>
                <FontAwesomeIcon className='hover:cursor-pointer' icon={solid('chevron-right')} />
              </button>
            </div>
          );
        }}
      >
        <div className='flex justify-between items-center px-4 pb-3 pt-0 text-sm text-brand'>
          <button
            onClick={() => {
              props.model.form.setFieldValue('range', { startDate: null, endDate: null });
            }}
          >
            Clear
          </button>
          <button
            onClick={() => {
              props.model.form.setFieldValue('range', {
                startDate: props.model.field.value?.startDate,
                endDate: props.model.field.value?.endDate
                  ? props.model.field.value.endDate
                  : endOfMonth(props.model.field.value?.startDate!),
              });

              ref.current!.setOpen(false);
            }}
          >
            Ok
          </button>
        </div>
      </DatePicker>
    </div>
  );
};
