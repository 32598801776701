import { forwardRef, Fragment, useState } from 'react';
import { TimeframeDisplay } from '../../../components/TimeframeDisplay';
import { Methodology } from '../../../../Prototype/types';
import { simplify, roundToShort } from '../../../../shared';
import cn from 'classnames';
import { BaselinePefReport, HistoricalPefReport, ImpactId, ImpactStageMatrix, ImpactTracking } from '../../../../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SkeletonLoader } from './SkeletonLoader';

interface Props {
  title: string;
  methodology: Methodology;
  baseline?: BaselinePefReport;
  historical?: HistoricalPefReport;
  selectedImpact: { id: string; name: string };
  selectedType: 'physical' | 'impactPoints';
}

export const HistoricalOverview = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [categories, setCategories] = useState(true);

  if (!props.baseline || !props.historical) return <SkeletonLoader title={props.title} />;

  return (
    <div className='flex flex-col gap-4' ref={ref}>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        <TimeframeDisplay start={new Date(props.historical.startDate)} end={new Date(props.historical.endDate)} />
      </div>
      <div className='flex flex-1 gap-x-3'>
        {(() => {
          const getTotalImpactValue = () => {
            const impact = props.historical!.analysis.impactStageMatrix.find((impact) => impact.impactId === props.selectedImpact.id)!;

            return {
              value:
                props.selectedImpact.id === 'overall'
                  ? props.historical!.impactPoints
                  : props.selectedType === 'impactPoints'
                  ? impact.impactPoints
                  : impact.physicalValue,
              unit: props.selectedType === 'impactPoints' ? 'Impact p.' : impact?.unit,
            };
          };

          const renderImpactDiffBetweenBaselineAndLatestReport = () => {
            if (props.baseline && props.historical) {
              const baselineData =
                props.selectedImpact.id === 'overall'
                  ? {
                      points: props.baseline.impactPoints,
                    }
                  : {
                      points: props.baseline.analysis.impactStageMatrix.find((impact) => impact.impactId === props.selectedImpact.id)!
                        .impactPoints,
                      physicalValue: props.baseline.analysis.impactStageMatrix.find(
                        (impact) => impact.impactId === props.selectedImpact.id,
                      )!.physicalValue,
                    };

              const latestHistoricalDataImpact =
                props.selectedImpact.id === 'overall'
                  ? {
                      points: props.historical.impactPoints,
                    }
                  : {
                      points: props.historical.analysis.impactStageMatrix.find((impact) => impact.impactId === props.selectedImpact.id)!
                        .impactPoints,
                      physicalValue: props.historical?.analysis.impactStageMatrix.find(
                        (impact) => impact.impactId === props.selectedImpact.id,
                      )!.physicalValue,
                    };

              const diff =
                Math.round(baselineData.points) === Math.round(latestHistoricalDataImpact.points)
                  ? 'equal'
                  : 100 - (latestHistoricalDataImpact.points / baselineData.points) * 100 > 0
                  ? 'decrease'
                  : 'increase';

              switch (diff) {
                case 'equal':
                  return <div className='flex flex-1 justify-center p-2 rounded-full bg-zinc-50 text-zinc-700'>No change</div>;
                case 'decrease':
                  return (
                    <div className='flex flex-1 gap-x-2 items-center bg-emerald-50 px-2 py-1 rounded-full'>
                      <FontAwesomeIcon className='p-1 size-3 rounded-full bg-emerald-700 text-white' icon={solid('arrow-down')} />
                      <div className='text-base text-emerald-700'>
                        -{Math.round(100 - (latestHistoricalDataImpact.points / baselineData.points) * 100)}% total
                      </div>
                    </div>
                  );
                case 'increase':
                  return (
                    <div className='flex flex-1 gap-x-2 items-center bg-red-50 px-2 py-1 rounded-full'>
                      <FontAwesomeIcon className='p-1 size-3 rounded-full bg-red-500 text-white' icon={solid('arrow-up')} />
                      <div className='text-base text-red-500'>
                        +{Math.abs(Math.round(100 - (latestHistoricalDataImpact.points / baselineData.points) * 100))}% total
                      </div>
                    </div>
                  );
              }
            }
          };

          const renderTargetTrend = (impactTracking: ImpactTracking) => {
            if (impactTracking.target) {
              const [substr1, substr2, substr3] = impactTracking.target?.estimate.split(' ');

              return (
                <div className='flex flex-col gap-y-2'>
                  <div className='text-3xl text-zinc-900'>{substr1}</div>
                  <div className='uppercase tracking-uppercase text-zinc-500 text-xs'>{`${substr2} ${substr3}`}</div>
                  <div
                    className={cn('flex flex-1 gap-x-2 items-center px-2 py-1.5 rounded-full text-sm capitalize', {
                      'bg-emerald-50 text-emerald-700': impactTracking.estimate!.type === 'sufficient',
                      'bg-amber-50 text-amber-600': impactTracking.estimate!.type === 'insufficient',
                    })}
                  >
                    {impactTracking.estimate!.type === 'insufficient' && <FontAwesomeIcon icon={regular('exclamation-triangle')} />}
                    {impactTracking.estimate!.type === 'sufficient' && <FontAwesomeIcon icon={regular('check')} />}
                    {
                      {
                        sufficient: 'Sufficient',
                        insufficient: 'Insufficient',
                        not_specified: '',
                      }[impactTracking.estimate!.type]
                    }
                  </div>
                </div>
              );
            }
          };

          return (
            <div className='flex gap-x-3 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)] p-4'>
              <div className='whitespace-nowrap flex flex-col justify-between self-start gap-4'>
                <div className='h-6 text-zinc-800 text-base font-semibold'>Latest impact</div>
                <div className='flex flex-col gap-y-2'>
                  <div className='text-3xl text-zinc-900'>{simplify(getTotalImpactValue().value)}</div>
                  <div className='uppercase tracking-uppercase text-zinc-500 text-xs'>{getTotalImpactValue().unit}</div>
                  {renderImpactDiffBetweenBaselineAndLatestReport()}
                </div>
              </div>
              {(() => {
                const impactTracking = props.historical?.tracking.impacts.find((impact) => impact.id === props.selectedImpact.id)!;

                return (
                  impactTracking.estimate?.type !== 'not_specified' && (
                    <>
                      <div className='w-px h-full bg-zinc-200'></div>
                      <div className='whitespace-nowrap flex flex-col justify-between self-start gap-4'>
                        <div className='h-6'></div>
                        <div className='flex flex-col gap-y-2'>
                          {(() => {
                            const impactTracking = props.historical?.tracking.impacts.find(
                              (impact) => impact.id === props.selectedImpact.id,
                            )!;

                            return renderTargetTrend(impactTracking);
                          })()}
                        </div>
                      </div>
                    </>
                  )
                );
              })()}
            </div>
          );
        })()}

        {(() => {
          const getDiffBetweenBaselineAndLastReport = (impact: ImpactStageMatrix) => {
            const baselineImpact = props.baseline?.analysis.impactStageMatrix.find(({ impactId }) => impactId === impact.impactId)!;

            const trend = () =>
              Math.round(baselineImpact.physicalValue) === Math.round(impact.physicalValue)
                ? 'equal'
                : baselineImpact.physicalValue > impact.physicalValue
                ? 'decrease'
                : 'increase';

            switch (trend()) {
              case 'equal':
                return <div className='flex justify-center p-2 rounded-full bg-neutral-50 text-zinc-700'>No change</div>;
              case 'decrease':
                return (
                  <div className='flex gap-x-2 items-center bg-neutral-50 px-2 py-1 rounded-full'>
                    <FontAwesomeIcon
                      className='shrink-0 p-1 size-3 rounded-full border border-emerald-700 text-emerald-700'
                      icon={solid('arrow-down')}
                    />
                    <div className='text-base text-emerald-700 flex items-center gap-x-1'>
                      <div title={simplify(baselineImpact.physicalValue - impact.physicalValue).toString()} className='truncate'>
                        {simplify(baselineImpact.physicalValue - impact.physicalValue)}
                      </div>
                      <div className='text-xs uppercase whitespace-nowrap'>{impact.unit}</div>
                    </div>
                  </div>
                );
              case 'increase':
                return (
                  <div className='flex gap-x-2 items-center bg-red-50 px-2 py-1 rounded-full'>
                    <FontAwesomeIcon className='shrink-0 p-1 size-3 rounded-full bg-red-500 text-white' icon={solid('arrow-up')} />

                    <div className='flex items-center text-base text-red-500 gap-x-1'>
                      <div title={simplify(baselineImpact.physicalValue - impact.physicalValue).toString()} className='truncate'>
                        {simplify(baselineImpact.physicalValue - impact.physicalValue)}
                      </div>
                      <div className='text-xs uppercase whitespace-nowrap'>{impact.unit}</div>
                    </div>
                  </div>
                );
            }
          };

          return (
            <div className='flex flex-1 gap-4 p-4 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
              <button
                className='max-w-32 shrink-0 leading-tight grid grid-cols-1 items-stretch gap-2 -m-2 mr-0'
                onClick={() => setCategories((current) => !current)}
              >
                <div
                  className={cn(
                    'flex text-center items-center rounded-2xl transition',
                    categories ? 'text-brandDarkPurple2 bg-indigo-50' : 'text-zinc-500 bg-slate-50',
                  )}
                >
                  Category top contributors
                </div>
                <div
                  className={cn(
                    'flex text-center items-center rounded-2xl transition',
                    !categories ? 'text-orange-900 bg-orange-100' : 'text-zinc-500 bg-slate-50',
                  )}
                >
                  Process top contributors
                </div>
              </button>
              <div className='grid w-full grid-cols-[1fr_1px_1fr_1px_1fr] gap-4'>
                {categories
                  ? props.historical.analysis.impactStageMatrix
                      .filter(({ impactId }) =>
                        [ImpactId.PefClimateChange, ImpactId.PefWaterScarcity, ImpactId.PefLandUse].includes(impactId),
                      )
                      .map((impact, i) => (
                        <Fragment key={i}>
                          {i > 0 && <div className='bg-zinc-200' />}
                          <div className='flex flex-col justify-between'>
                            <div className='font-semibold text-zinc-800 line-clamp-3'>{impact.impactName}</div>
                            <div className='text-2xl text-zinc-800'>{roundToShort(impact.absSharePercent)}%</div>
                            <div className='whitespace-nowrap flex gap-1 items-center'>
                              <div className='text-zinc-600 text-base'>{simplify(impact.physicalValue)}</div>
                              <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>{impact.unit}</div>
                            </div>
                            {getDiffBetweenBaselineAndLastReport(impact)}
                          </div>
                        </Fragment>
                      ))
                  : props.historical.analysis.lifecycleStageImpacts
                      .flatMap((stage) =>
                        stage.components.flatMap((component) =>
                          component.components.flatMap((contributor) => ({
                            name: contributor.name,
                            type: component.name,
                            share: contributor.impactSharePercent,
                          })),
                        ),
                      )
                      .sort((a, b) => b.share - a.share)
                      .slice(0, 3)
                      .map((process, i) => (
                        <Fragment key={i}>
                          {i > 0 && <div className='w-px shrink-0 bg-zinc-200' />}
                          <div className='grid grid-rows-3 justify-between'>
                            <div className='font-semibold text-zinc-800'>{process.type}</div>
                            <div className='text-zinc-600 line-clamp-2'>{process.name}</div>
                            <div className='text-2xl text-zinc-800'>{roundToShort(process.share)}%</div>
                          </div>
                        </Fragment>
                      ))}
              </div>
            </div>
          );
        })()}
      </div>
    </div>
  );
});
