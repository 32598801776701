import cn from 'classnames';
import { ReactNode } from 'react';
import { GasEmission, GhgValues } from '../../../../../api';

interface Coords {
  x: number;
  y: number;
}

export interface Activity {
  id: string;
  angle: number;
  isOutOfScope: boolean;
  tooltip: {
    element: ReactNode;
    coords: Coords;
    offset?: number;
  };
  icon: {
    element: ReactNode;
    coords: Coords;
  };
}

export const tooltip = (name: string, value: ReactNode, transparentBackground: boolean) => (
  <div
    className={cn(
      'p-2 rounded-md border border-transparent flex flex-col max-w-[126px]',
      'group-hover:bg-white group-hover:border group-hover:border-zinc-300 group-hover:shadow-regular',
    )}
  >
    <div className='bg-transparent'>
      <div className={cn('inline text-xs', transparentBackground ? 'bg-transparent' : 'bg-white')}>{name}</div>
    </div>
    <div className='hidden group-hover:flex justify-center items-center whitespace-nowrap'>{value}</div>
  </div>
);

interface RequiredReportingItem {
  id: string;
  name: string;
  isOutOfScope: boolean;
  totalEmission: {
    value: number;
    unit: string;
  };
  totalBiogenic: {
    value: number;
    unit: string;
  };
  content: {
    description?: string;
    includes?: {
      items: {
        item: string;
      }[];
    };
    excludes?: {
      items: {
        item: string;
      }[];
    };
  };
}

export const requiredReportingDataOverall = {
  totalEmission: {
    value: 0.10255787793489796,
    unit: 'kg CO2 eq',
  },
  totalBiogenic: {
    value: 0.028947231090421154,
    unit: 'kg CO2',
  },
};

export const requiredReportingData: RequiredReportingItem[] = [
  {
    id: 'category_01',
    name: 'Purchased goods and services',
    totalEmission: {
      value: 0.022096635291986404,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.008329917514643436,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      includes: {
        items: [
          { item: 'Raw materials purchased for product manufacturing' },
          { item: 'Production impact in non-owned/rented facilities' },
        ],
      },
      excludes: {
        items: [
          { item: 'Non-production related goods and services purchased (eg. office supplies, etc.)' },
          { item: 'Overheads of non-owned/rented production facilities' },
        ],
      },
    },
  },
  {
    id: 'category_02',
    name: 'Capital goods',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      includes: { items: [{ item: 'Fridge equipment in storage facilities and stores' }] },
      excludes: { items: [{ item: 'All other capital goods ' }] },
    },
  },
  {
    id: 'category_03',
    name: 'Fuel and energy related services',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description: 'To be calculated separately based on Scope 2 emissions',
    },
  },
  {
    id: 'category_04',
    name: 'Upstream transportation and distribution',
    totalEmission: {
      value: 0.011981928866162724,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.0017427162471849984,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      includes: {
        items: [{ item: 'Transportation of all raw materials ' }, { item: 'Transportation paid for by the Customer' }],
      },
      excludes: {
        items: [
          { item: 'Transportation in company-owned vehicles (Scope 1) ' },
          { item: 'Transportation not paid for by the company (Scope 3 category 9)' },
          { item: 'Non-production related transportation' },
        ],
      },
    },
  },
  {
    id: 'category_05',
    name: 'Waste generated in operations',
    totalEmission: {
      value: 0.0014883727553394123,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.001628671283447179,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      description: 'Assumes all waste generated in operations is treated off-site by a third-party',
      includes: {
        items: [
          { item: 'Waste generated in production facilities owned/controlled by the Customer' },
          { item: 'Food waste generated in storage facilities/stores owned/controlled by the Customer' },
        ],
      },
      excludes: {
        items: [{ item: 'Non-food waste generated in storage facilities/stores owned/controlled by the Customer' }],
      },
    },
  },
  {
    id: 'category_06',
    name: 'Business travel',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description: 'To be calculated separately',
    },
  },
  {
    id: 'category_07',
    name: 'Employee commuting',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description: 'To be calculated separately',
    },
  },
  {
    id: 'category_08',
    name: 'Upstream Leased Assets',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      description: 'Assumes all waste generated in operations is treated off-site by a third-party',
      includes: {
        items: [
          { item: 'Waste generated in production facilities owned/controlled by the Customer' },
          { item: 'Food waste generated in storage facilities/stores owned/controlled by the Customer' },
        ],
      },
      excludes: {
        items: [{ item: 'Non-food waste generated in storage facilities/stores owned/controlled by the Customer' }],
      },
    },
  },
  {
    id: 'category_09',
    name: 'Downstream transportation and distribution',
    totalEmission: {
      value: 0.018292206740251286,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.0024082854598743765,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      description:
        'For intermediate products, transportation is considered down to the business customer, not the final consumer given the final use is unknown.',
      includes: {
        items: [{ item: 'Transportation not paid for by the Customer' }],
      },
      excludes: {
        items: [
          { item: 'Transportation in company-owned vehicles (Scope 1)' },
          { item: 'Transportation paid for by the company (Scope 3 category 4)' },
          { item: 'Non-production related transportation' },
        ],
      },
    },
  },
  {
    id: 'category_10',
    name: 'Processing of sold goods',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description:
        'Emissions associated with further processing of co-products and intermediate products sold are not captured by Sustained Impact and should be added separately into this category',
    },
  },
  {
    id: 'category_11',
    name: 'Use of sold product',
    totalEmission: {
      value: 0.0350885734986936,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.008053219197638599,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      description:
        'Given sold products don’t have any direct emissions, indirect emissions presented here are optional to report. They do not include the use of intermediate products sold since their final use is unknown.',
      includes: {
        items: [{ item: 'Preparation of sold products by the consumer' }],
      },
      excludes: {
        items: [{ item: 'Use of intermediate products by the end consumer' }, { item: 'Use of co-products by the end consumer' }],
      },
    },
  },
  {
    id: 'category_12',
    name: 'EOL treatment of sold products',
    totalEmission: {
      value: 0.013610160782464456,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.00678442138763255,
      unit: 'kg CO2',
    },
    isOutOfScope: false,
    content: {
      includes: {
        items: [{ item: 'Packaging end of life for both final and intermediate products' }, { item: 'Food loss at consumer ' }],
      },
      excludes: {
        items: [{ item: 'Co-products EOL treatment ' }],
      },
    },
  },
  {
    id: 'category_13',
    name: 'Downstream leased assets',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description: 'If any facility owned by the Customer is leased to a third-party, the impact is to be calculated separately',
    },
  },
  {
    id: 'category_14',
    name: 'Franchises',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description: 'If the Customer owns any franchises, the impact is to be calculated separately',
    },
  },
  {
    id: 'category_15',
    name: 'Investments',
    totalEmission: {
      value: 0,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0,
      unit: 'kg CO2',
    },
    isOutOfScope: true,
    content: {
      description: 'The impact of any investments made by the Customer is to be calculated separately ',
    },
  },
];

export const optionalReportingData: {
  gasEmissions: GasEmission[];
  avoidedEmissions: GhgValues;
} = {
  gasEmissions: [
    {
      id: 'ghg_gwp_co2',
      name: 'Global Warming Potential, only CO2',
      totalEmission: {
        value: 0.07807280103724619,
        unit: 'kg CO2',
      },
    },
    {
      id: 'ghg_gwp_ch4',
      name: 'Global Warming Potential, only CH4',
      totalEmission: {
        value: 0.01082571376871091,
        unit: 'kg CO2 eq',
      },
    },
    {
      id: 'ghg_gwp_hfc',
      name: 'Global Warming Potential, only HFCs',
      totalEmission: {
        value: 0.009075042866534787,
        unit: 'kg CO2 eq',
      },
    },
    {
      id: 'ghg_gwp_n2o',
      name: 'Global Warming Potential, only N2O',
      totalEmission: {
        value: 0.004327903269217284,
        unit: 'kg CO2 eq',
      },
    },
    {
      id: 'ghg_gwp_nf3',
      name: 'Global Warming Potential, only NF3',
      totalEmission: {
        value: 1.359760121018888e-13,
        unit: 'kg CO2 eq',
      },
    },
    {
      id: 'ghg_gwp_pfc',
      name: 'Global Warming Potential, only PFCs',
      totalEmission: {
        value: 0.00003285762383026815,
        unit: 'kg CO2 eq',
      },
    },
    {
      id: 'ghg_gwp_sf6',
      name: 'Global Warming Potential, only SF6',
      totalEmission: {
        value: 0.00022355936922236256,
        unit: 'kg CO2 eq',
      },
    },
  ],
  avoidedEmissions: {
    totalEmission: {
      value: 0.007962583923329592,
      unit: 'kg CO2 eq',
    },
    totalBiogenic: {
      value: 0.00011888562623045955,
      unit: 'kg CO2',
    },
  },
};
