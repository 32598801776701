import { Lens, ProductReport } from '../../../../../../../api';
import { Summary as SustainedSummary } from '../../components/Summary';

interface Props {
  data: ProductReport;
}

export const Summary = (props: Props) => {
  return <SustainedSummary type={Lens.Consumer} data={props.data} />;
};
