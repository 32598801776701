import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { getMonth, getYear } from 'date-fns';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import first from 'lodash/first';
import last from 'lodash/last';
import { SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { setSelectedWorkspaceId } from '../../../api';
import { Popover } from '../../../components/Popover';
import { convertLocalToUTCDate, months, years } from '../../../components/datepicker/utils';
import { useProfile } from '../../../hooks/useProfile';
import { PopoverFrame } from './components/PopoverFrame';
import { formatPeriod, formatTimeframe } from './functions';
import { Methodology, SectionId, Timeframe } from './types';

interface Props {
  methodology: Methodology;
  setMethodology: (methodology: Methodology) => void;
  timeframe: Timeframe;
  setTimeframe: (timeframe: Timeframe) => void;
  baseline: { from: Date; to: Date };
  setBaseline: (baseline: SetStateAction<{ from: Date; to: Date }>) => void;
  sections: { id: SectionId; label: string }[];
  sectionRefs: Map<SectionId, HTMLDivElement>;
}

export const Sidebar = (props: Props) => {
  const profile = useProfile();
  const [sectionsExpanded, setSectionsExpanded] = useState(true);

  return (
    <div>
      <div className='z-20 sticky top-0 h-screen w-[calc(theme(width.1)*58)] flex flex-col gap-4 px-5 py-6 bg-white border-x border-zinc-200'>
        <div className='flex items-center gap-2'>
          <img src={profile.user.logoUrl} alt='Logo' className='size-8 rounded-full' />
          <div className='text-zinc-500 truncate'>{profile.user.company}</div>
        </div>
        <div className='h-px bg-zinc-200 shrink-0' />
        <div className='flex flex-col gap-2'>
          <button
            type='button'
            className='flex justify-between items-center p-1.5 pr-3 rounded-lg bg-slate-100'
            onClick={() => setSectionsExpanded((current) => !current)}
          >
            <div className='flex items-center gap-2'>
              <div className='flex justify-center items-center rounded-sm size-7 bg-white'>
                <FontAwesomeIcon icon={light('chart-line')} className='text-brand' />
              </div>
              <div className='text-zinc-800 font-semibold'>Dashboard</div>
            </div>
            <FontAwesomeIcon icon={sectionsExpanded ? regular('chevron-down') : regular('chevron-right')} className='text-zinc-500' />
          </button>
          {sectionsExpanded && <SectionButtons {...props} />}
        </div>
        <div className='h-px bg-zinc-200 shrink-0' />
        <div className='flex flex-col gap-4'>
          <div className='text-zinc-800 font-semibold'>Settings</div>
          <div className='flex flex-col gap-3 text-zinc-700'>
            <Popover
              placement='right-start'
              offset={2}
              offsetCross={-46}
              content={({ close }) => (
                <PopoverFrame className='whitespace-nowrap gap-2 p-4'>
                  <div className='font-semibold text-zinc-800'>Methodology</div>
                  <div className='h-px shrink-0 bg-zinc-200' />
                  {[Methodology.Production, Methodology.Consumer, Methodology.Ghg].map((methodology) => (
                    <button
                      key={methodology}
                      type='button'
                      className={cn('flex items-center gap-2 rounded-lg px-2 py-1', {
                        'text-zinc-700 bg-indigo-50': methodology === props.methodology,
                      })}
                      onClick={() => {
                        props.setMethodology(methodology);
                        close();
                      }}
                    >
                      {
                        {
                          [Methodology.Production]: 'PEF — Production impact',
                          [Methodology.Consumer]: 'PEF — Consumer impact',
                          [Methodology.Ghg]: 'GHG Protocol',
                        }[methodology]
                      }
                    </button>
                  ))}
                </PopoverFrame>
              )}
            >
              {({ open }) => (
                <button
                  type='button'
                  className={cn('flex items-center gap-2 pr-2 rounded-lg', {
                    'bg-indigo-50 text-zinc-700': open,
                  })}
                >
                  <div className='size-8 shrink-0 flex justify-center items-center bg-indigo-50 rounded-lg'>
                    <FontAwesomeIcon icon={light('book-open-cover')} className='text-brandDarkPurple2' />
                  </div>
                  {
                    {
                      [Methodology.Production]: 'PEF — Production',
                      [Methodology.Consumer]: 'PEF — Consumer',
                      [Methodology.Ghg]: 'GHG Protocol',
                    }[props.methodology]
                  }
                </button>
              )}
            </Popover>
            <Popover
              placement='right-start'
              offset={2}
              offsetCross={-90}
              content={({ close }) => (
                <PopoverFrame className='min-w-[450px] gap-4 p-4'>
                  <div className='font-semibold text-zinc-800'>Baseline period</div>
                  <div className='text-zinc-800'>
                    Changing your baseline year will change it for all underlying workspaces, making this the new reference point when it
                    comes to targets and progress.
                  </div>
                  <div className='h-px shrink-0 bg-zinc-200' />
                  <TimeframeSelect value={props.baseline} setValue={props.setBaseline} onConfirm={close} />
                </PopoverFrame>
              )}
            >
              {({ open }) => {
                const disabled = false; /* is baseline set */
                return (
                  <button
                    type='button'
                    disabled={disabled}
                    className={cn('flex items-center gap-2 pr-2 rounded-lg disabled:cursor-not-allowed', {
                      'bg-indigo-50': open,
                    })}
                  >
                    <div
                      className={cn(
                        'size-8 shrink-0 flex justify-center items-center rounded-lg',
                        disabled ? 'bg-neutral-100' : 'bg-indigo-50',
                      )}
                    >
                      <FontAwesomeIcon icon={light('flag-checkered')} className={disabled ? 'text-zinc-500' : 'text-brandDarkPurple2'} />
                    </div>
                    {disabled ? (
                      <div className='text-zinc-400'> No baseline found</div>
                    ) : (
                      formatPeriod(props.baseline.from!, props.baseline.to!, { compact: true })
                    )}
                  </button>
                );
              }}
            </Popover>
            <Popover
              placement='right-start'
              offset={2}
              offsetCross={-134}
              content={({ close }) => (
                <PopoverFrame className='min-w-96 gap-2 p-4'>
                  <div className='font-semibold text-zinc-800'>Time window</div>
                  <div className='text-zinc-800'>
                    Changing the time window will affect the widgets on your dashboard and will give you an overview of your company’s
                    impact over a 1, 3, 6 or 12 month timeframe.
                  </div>
                  <div className='h-px shrink-0 bg-zinc-200' />
                  {[Timeframe.Month, Timeframe.Quarter, Timeframe.HalfYear, Timeframe.Year].map((timeframe) => (
                    <button
                      key={timeframe}
                      type='button'
                      className={cn('whitespace-nowrap flex items-center gap-2 rounded-lg px-2 py-1', {
                        'text-zinc-700 bg-[rgba(218,206,253,0.5)]': timeframe === props.timeframe,
                      })}
                      onClick={() => {
                        props.setTimeframe(timeframe);
                        close();
                      }}
                    >
                      Last{' '}
                      {
                        {
                          [Timeframe.Month]: '1 month',
                          [Timeframe.Quarter]: '3 months',
                          [Timeframe.HalfYear]: '6 months',
                          [Timeframe.Year]: 'year',
                        }[timeframe]
                      }{' '}
                      of data: {formatTimeframe(timeframe)}
                    </button>
                  ))}
                </PopoverFrame>
              )}
            >
              {({ open }) => (
                <button
                  type='button'
                  className={cn('flex items-center gap-2 pr-2 rounded-lg', {
                    'bg-indigo-50': open,
                  })}
                >
                  <div className='size-8 shrink-0 flex justify-center items-center bg-indigo-50 rounded-lg'>
                    <FontAwesomeIcon icon={light('calendar')} className='text-brandDarkPurple2' />
                  </div>
                  {formatTimeframe(props.timeframe, { compact: true })}
                </button>
              )}
            </Popover>
          </div>
        </div>
        <div className='h-px bg-zinc-200 shrink-0' />
        <div className='flex flex-col gap-4'>
          <div className='text-zinc-800 font-semibold'>Workspaces</div>
          <div
            className={cn('flex flex-col gap-2', {
              'max-h-[calc(theme(height.8)*4+theme(spacing.2)*3)] overflow-y-scroll overscroll-contain': profile.workspaces.length > 4,
            })}
          >
            {profile.workspaces.map((workspace) => (
              <button
                key={workspace.workspaceSid}
                type='button'
                className='flex items-center justify-between gap-4 p-1 pr-2 rounded-lg group hover:bg-indigo-50'
                onClick={() => {
                  setSelectedWorkspaceId(workspace.workspaceSid);
                  window.open('/', '_blank');
                }}
              >
                <div className='flex items-center gap-2 rounded truncate'>
                  {workspace.logoUrl ? (
                    <img src={workspace.logoUrl} alt='Logo' className='size-6 rounded-full' />
                  ) : (
                    <div className='size-6 flex justify-center items-center bg-[#E8EAF5] rounded-full'>
                      <FontAwesomeIcon icon={regular('building')} size='sm' />
                    </div>
                  )}
                  <div className='truncate text-zinc-800'>{workspace.name}</div>
                </div>
                <FontAwesomeIcon icon={light('arrow-up-right')} className='text-zinc-500 group-hover:text-brand text-xl' />
              </button>
            ))}
          </div>
        </div>
        <div className='h-px bg-zinc-200 shrink-0' />
        <button type='button' className='flex justify-center text-xs font-semibold py-2 rounded-full bg-brand text-white'>
          Resume where you left off
        </button>
      </div>
    </div>
  );
};

const SectionButtons = (props: Props) => {
  const [scrollTop, setScrollTop] = useState(document.documentElement.scrollTop);

  useEffect(() => {
    const onScroll = () => setScrollTop(document.documentElement.scrollTop);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className='flex flex-col'>
      {props.sections.map((section) => (
        <button
          key={section.id}
          type='button'
          className={cn(
            'p-2 rounded',
            (() => {
              if (scrollTop === 0) {
                return first(props.sections)!.id;
              }

              if (scrollTop + window.innerHeight === document.documentElement.scrollHeight) {
                return last(props.sections)!.id;
              }

              let current = { distance: Infinity, id: null as SectionId | null };

              if (props.sectionRefs.size > 0) {
                props.sections.forEach(({ id }) => {
                  const ref = props.sectionRefs.get(id);
                  let distance = Infinity;

                  if (ref) {
                    distance = Math.min(
                      Math.abs(ref.getBoundingClientRect().top - window.innerHeight / 2.0),
                      Math.abs(ref.getBoundingClientRect().bottom - window.innerHeight / 2.0),
                    );
                  }

                  if (distance < current.distance) {
                    current = { distance, id };
                  }
                });
              }

              return current.id;
            })() === section.id
              ? 'text-zinc-700 bg-slate-100'
              : 'text-zinc-500',
          )}
          onClick={() => props.sectionRefs.get(section.id)?.scrollIntoView({ behavior: 'smooth', block: 'center' })}
        >
          {section.label}
        </button>
      ))}
    </div>
  );
};

const TimeframeSelect = (props: {
  value: {
    from: Date;
    to: Date;
  };
  setValue: (value: { from: Date; to: Date }) => void;
  onConfirm: () => void;
}) => {
  const [value, setValue] = useState<{ from: Date | null; to: Date | null }>(props.value);

  return (
    <>
      <div className='grid grid-cols-2 gap-x-4'>
        <div className='double-datepicker flex flex-col gap-y-2'>
          <div>From</div>
          <div className='relative'>
            <DatePicker
              selected={value.from}
              calendarStartDay={1}
              dateFormat='MMM dd, yyyy'
              maxDate={sub(new Date(), { years: 1, days: 1 })}
              placeholderText='Select a date'
              className='w-full px-2 py-1.5 text-sm text-zinc-600 border border-brand rounded-lg relative'
              onChange={(date) => {
                setValue({
                  from: convertLocalToUTCDate(date),
                  to: date ? convertLocalToUTCDate(add(date, { years: 1 })) : null,
                });
              }}
              selectsStart
              showYearDropdown
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className='flex w-full justify-between items-center px-3 py-2'>
                  <FontAwesomeIcon
                    className='hover:cursor-pointer hover:text-brand text-base'
                    icon={regular('chevron-left')}
                    onClick={() => !prevMonthButtonDisabled && decreaseMonth()}
                  />
                  <select
                    className='focus-visible:ring-0 font-semibold text-base rounded-md hover:cursor-pointer text-center'
                    value={getYear(date)}
                    onChange={({ target: { value } }) => {
                      return changeYear(getYear(new Date(value)));
                    }}
                  >
                    {years(sub(new Date(), { years: 10 }).getFullYear(), new Date().getFullYear() + 1).map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    className='focus-visible:ring-0 font-semibold text-base rounded-md hover:cursor-pointer text-center'
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <FontAwesomeIcon
                    className='hover:cursor-pointer hover:text-brand text-base'
                    icon={regular('chevron-right')}
                    onClick={() => !nextMonthButtonDisabled && increaseMonth()}
                  />
                </div>
              )}
            />
            {value.from && (
              <FontAwesomeIcon
                onClick={() => setValue({ from: null, to: null })}
                className='text-brand text-xs absolute hover:cursor-pointer right-2.5 top-2.5'
                icon={regular('xmark-large')}
              />
            )}
          </div>
        </div>

        <div className='double-datepicker flex flex-col gap-y-2'>
          <div>To</div>
          <DatePicker
            disabled
            selectsEnd
            dateFormat='MMM dd, yyyy'
            className='w-full px-2 py-1.5 text-sm text-zinc-500 border border-brand rounded-lg'
            selected={value.to}
            startDate={value.from}
            endDate={value.to}
            onChange={() => {}}
          />
        </div>
      </div>
      <button
        disabled={!value.from}
        onClick={() => {
          props.setValue({ from: value.from!, to: value.to! });
          props.onConfirm();
        }}
        type='button'
        className={cn(
          'self-end font-semibold text-white rounded-full px-4 py-2',
          value.from ? 'bg-brand' : 'bg-neutral-300 cursor-not-allowed',
        )}
      >
        Confirm
      </button>
    </>
  );
};
