import { ProductReport, ProductType } from '../../../../../../api';
import { CollapsibleSection } from '../../CollapsibleSection';
import { GoalAndScope as GoalAndScopeProduction } from '../components/GoalAndScope';
import { NavigationButtons } from '../components/NavigationButtons';

interface Props {
  data: ProductReport;
}

export const GoalAndScope = (props: Props) => (
  <GoalAndScopeProduction
    data={props.data}
    description={
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>
          An overview of the goal and scope of the LCA assessment carried out including the functional unit, system boundaries, data
          quality, the methodology used, and the underlying assumptions made for each life cycle stage.
        </div>
        <NavigationButtons type='icons' back={{ path: '../details' }} next={{ path: '../lcia' }} />
      </div>
    }
    goal={
      <CollapsibleSection title='Goal'>
        {[ProductType.Final, ProductType.Intermediate].includes(props.data.product.productType) ? (
          <div>
            The goal of this analysis is to provide the user and the producer an overview of the environmental impact of a given product, to
            provide an understanding of the various life cycle stages, and to help map some initial improvement opportunities that the
            client can further explore. Rather than to compare the assessed products to other products, this report is intended to
            understand the assessed product and identify ways of improving its environmental performance.
          </div>
        ) : (
          <div>
            The goal of this analysis is to provide an overview of the environmental impact of a given product, to provide an understanding
            of the various life cycle stages, and to help map some initial improvement opportunities that the client can further explore.
          </div>
        )}
      </CollapsibleSection>
    }
    functionalUnit={
      <CollapsibleSection title='Functional Unit'>
        <div className='flex flex-col gap-6'>
          <div>The functional unit is:</div>
          <div className='text-brandDark'>
            {props.data.product.productType === ProductType.Final &&
              `1 unit of sold Product (${props.data.product.name} - ${props.data.product.amount.value}${props.data.product.amount.unit.name}) ready for consumption.`}
            {props.data.product.productType === ProductType.Intermediate &&
              `1 unit of product (${props.data.product.name} - ${props.data.product.amount.value}${props.data.product.amount.unit.name}) at the customer’s gate.`}
            {props.data.product.productType === ProductType.Internal &&
              `1 unit of Product (${props.data.product.name} - ${props.data.product.bruttoAmount.value}${props.data.product.bruttoAmount.unit.name}) at factory gate`}
          </div>
          <div>The results displayed in this report will be valid for the specified functional unit only.</div>
        </div>
      </CollapsibleSection>
    }
    methodology={
      <CollapsibleSection title='Methodology'>
        <div className='flex flex-col gap-6'>
          {[ProductType.Final, ProductType.Intermediate].includes(props.data.product.productType) ? (
            <div>
              The results of the life cycle impact assessment presented in this report are in line with ISO 14044 and were obtained by
              largely following the Product Environmental Footprint (PEF) framework v.3.0, using the OpenLCA v.1.11.0 software to compute
              the environmental impact calculations.
            </div>
          ) : (
            <div>
              The results of the life cycle impact assessment presented in this report were obtained by largely following the Product
              Environmental Footprint (PEF) framework v.3.0, using the OpenLCA v.1.11.0 software to compute the environmental impact
              calculations.
            </div>
          )}
          <div>
            This framework was selected because it is comprehensive and aligns with the aim to harmonise European environmental footprint
            studies. PEF recommends the calculation of 16 environmental impact categories (details of which can be found on our Methodology
            page). After calculation of each impact category’s physical value ( in their specific units), these were divided by average
            annual EU27 per capita burdens to generate normalised scores as per PEF framework. Normalised scores (expressed as person.year)
            for all impact categories were then weighted using the PEF recommended weightings and the final results are displayed from
            highest to lowest impact (See LCIA section). The main goal of this framework is to facilitate understanding, communication and
            reporting of environmental impacts.
          </div>
          <div>
            The final analysis was made with the following Sustained Impact software and methodology versions:
            <ul className='list-disc ml-6 my-2'>
              <li>Sustained Impact web application v.{props.data.metadata.webAppVersion}</li>
              <li>Sustained Impact methodology v.{props.data.metadata.methodologyVersion}</li>
            </ul>
          </div>
        </div>
      </CollapsibleSection>
    }
    systemBoundaries={
      <>
        {(() => {
          switch (props.data.product.productType) {
            case ProductType.Final:
              return (
                <>
                  <div>The analysis is performed from ‘cradle-to-grave’ with the following life cycle stages considered:</div>
                  <ul className='list-disc ml-6 my-2'>
                    <li>Extraction and production of raw materials with associated transportation to production facility</li>
                    <li>Production of the product by the manufacturer including packaging</li>
                    <li>Distribution from production facility to the end consumer</li>
                    <li>Use phase of the product ready for consumption</li>
                    <li>End-of-life through packaging waste processing</li>
                  </ul>
                  <div>The diagram below presents the system boundaries considered for this analysis:</div>
                </>
              );
            case ProductType.Intermediate:
              return (
                <>
                  <div>
                    The analysis is performed from ‘cradle-to-customer’, or up to the final point of sale with the customer, with the
                    following life cycle stages considered:
                  </div>
                  <ul className='list-disc ml-6 my-2'>
                    <li>Extraction and production of raw materials with associated transportation to production facility</li>
                    <li>Production of the product by the manufacturer including packaging</li>
                    <li>Distribution from production facility to the business customer for further processing</li>
                  </ul>
                  <div>The diagram below presents the system boundaries considered for this analysis:</div>
                </>
              );
            case ProductType.Internal:
              return (
                <>
                  <div>The analysis is performed from ‘cradle-to-gate’ with the following life cycle stages considered:</div>
                  <ul className='list-disc ml-6 my-2'>
                    <li>Extraction and production of raw materials with associated transportation to production facility</li>
                    <li>Production of the product by the manufacturer including packaging</li>
                  </ul>
                  <div>The diagram below presents the system boundaries considered for this analysis:</div>
                </>
              );
            default:
              return undefined;
          }
        })()}
      </>
    }
    bottomNavigationButtons={
      <NavigationButtons type='buttons' back={{ path: '../details', label: 'Product Details' }} next={{ path: '../lcia', label: 'LCIA' }} />
    }
  />
);
