import cn from 'classnames';
import { FieldProps } from 'formik';
import { components, OptionProps } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import { searchIngredientsV3 } from '../../../../../api';
import { Components } from '../../../../../components/SelectV3';

interface MappingIngredientSelectProps {
  model: FieldProps<any>;
  hideInputBorder?: boolean;
}

export const MappingIngredientSelect = (props: MappingIngredientSelectProps) => {
  return (
    <ReactSelectAsync
      isClearable
      placeholder='Choose…'
      loadingMessage={() => 'Searching…'}
      noOptionsMessage={() => 'No matches found…'}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ name }) => name}
      value={props.model.field.value}
      onChange={(value) => props.model.form.setFieldValue(props.model.field.name, value)}
      defaultOptions
      loadOptions={(input, callback) => {
        searchIngredientsV3(input).ok(({ customer, sustained }) =>
          callback([
            {
              label: 'Internal Products',
              options: customer,
            },
            {
              label: 'Generic Ingredients',
              options: sustained,
            },
          ]),
        );
      }}
      formatGroupLabel={(data) => (
        <div className='flex items-center gap-1.5'>
          <div>{data.label}</div>
        </div>
      )}
      components={{
        ...Components,
        Option: IngredientSelectOption,
      }}
      {...{ model: props.model, hideInputBorder: props.hideInputBorder }}
    />
  );
};

const IngredientSelectOption = (props: OptionProps<{}>) => (
  <components.Option
    {...props}
    getStyles={() => ({
      ...props.getStyles('option', props),
      padding: '',
      cursor: 'pointer',
      color: '',
      backgroundColor: '',
      ':active': {},
    })}
    className={cn(
      'px-2 py-1',
      (() => {
        if (props.isSelected) {
          if (props.isFocused) {
            return 'bg-brandDark text-white';
          }

          return 'bg-brand text-white';
        }

        if (props.isFocused) {
          return 'bg-neutral-100';
        }

        return '';
      })(),
    )}
  >
    <div className='flex items-center justify-between gap-4 px-2 py-1 rounded-xl'>
      <div>{props.children}</div>
    </div>
  </components.Option>
);
