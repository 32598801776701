import { forwardRef } from 'react';
import { ProgressChart } from '../../../../../../components/charts/ProgressTracking/ProgressChart';
import { PortfolioHistoricalCreated } from '../../../../../../api';
import { ProgressChartLegend } from '../../../../../../components/charts/ProgressTracking/ProgressChartLegend';
import { ImpactValueType } from '../../../../Products/Report/Sku/Overview';

interface Props {
  title: string;
  portfolio: PortfolioHistoricalCreated;
  selectedImpact: { id: string; name: string };
  selectedType: ImpactValueType;
}

export const ProgressTracking = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref} className='flex flex-col gap-4 text-dark'>
      <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
      <div className='grid grid-cols-[8fr_3fr] bg-white rounded-lg border z-[0] p-6 pb-2 w-full h-72'>
        <div className='ml-20 mr-6'>
          <ProgressChart
            selectedType={props.selectedType}
            targets={props.portfolio.targets}
            selectedImpact={props.selectedImpact}
            impacts={props.portfolio.tracking.impacts}
          />
        </div>
        <ProgressChartLegend />
      </div>
    </div>
  );
});
