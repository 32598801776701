import { ProductReport } from '../../../../../../../api';
import { Appendix as FeAppendix } from '../../components/Appendix';
import { NavigationButtons } from '../../components/NavigationButtons';
import { NormalisationFactor } from '../../components/NormalisationFactor';

interface Props {
  data: ProductReport;
}

export const Appendix = (props: Props) => {
  return (
    <FeAppendix
      data={props.data}
      description={
        <div className='flex justify-between gap-8'>
          <div className='text-brandGray5 ml-3 flex flex-col gap-6'>
            <div>This section of the report contains additional information on the methodology.</div>
            <div>
              If you are left with some questions pertaining to your product’s environmental impact after reading this report, please
              contact us and we’ll be happy to help you in any way we can!
            </div>
          </div>
          <NavigationButtons type='icons' back={{ path: '../environmental-label' }} />
        </div>
      }
      normalisationFactors={
        <div className='px-6 rounded-regular shadow-regular border'>
          <div className='text-xl font-semibold text-dark my-6'>Normalisation and weighting factors</div>
          <div className='text-brandGray5'>
            <NormalisationFactor data={props.data.metadata.methodFactors} />
          </div>
        </div>
      }
    />
  );
};
