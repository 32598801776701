import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { HistoricalGhgReport, HistoricalPefReport, Lens } from '../../../../../api';
import ghg from '../../Baseline/Report/ghg.png';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { StickyHeader } from '../../../../../components/StickyHeader';

interface Props {
  data?: HistoricalPefReport | HistoricalGhgReport;
  lens: Lens;
}

export const Overview = (props: PropsWithChildren<Props>) => {
  const navigate = useNavigate();

  return (
    <div className='mb-20 print:mb-0'>
      {props.data ? (
        <>
          <Helmet title={props.data.name} />
          <StickyHeader className='px-6'>
            <div className='text-xl font-semibold text-dark truncate'>{props.data.name}</div>
            <NavLink
              to='../summary'
              className={cn(
                'print:hidden',
                'text-base font-semibold [&:not(:disabled)]:shadow-md [&:active:not(:disabled)]:scale-95',
                'border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full whitespace-nowrap',
              )}
            >
              See Full Report
            </NavLink>
          </StickyHeader>

          <div className='divide-y'>
            <div className='grid grid-cols-2 gap-x-3 p-6 print:hidden'>
              {[
                {
                  label: 'PEF - Production impact',
                  description: 'Cradle-to-grave impact of your volume output',
                  icon: <FontAwesomeIcon size='lg' icon={solid('industry-windows')} />,
                  isActive: props.lens === Lens.Production,
                  onClick: () => navigate(`../../production/overview`),
                },
                {
                  label: 'GHG Protocol emissions',
                  description: 'Est. GHG emissions of your volume output',
                  icon: <img alt='' src={ghg} className='h-9 aspect-square' />,
                  isActive: props.lens === Lens.Ghg,
                  onClick: () => navigate(`../../ghg/overview`),
                },
              ].map((tab) => (
                <button
                  onClick={tab.onClick}
                  className={cn(
                    'flex gap-3 border rounded-lg py-2 px-2 cursor-pointer',
                    tab.isActive ? 'border-brand' : 'border-slate-100 bg-slate-100',
                  )}
                  key={tab.label}
                >
                  <div
                    className={cn(
                      'flex self-center items-center justify-center w-10 aspect-square rounded-full',
                      tab.isActive ? 'bg-slate-100 text-brand' : '',
                    )}
                  >
                    {tab.icon}
                  </div>
                  <div className='flex flex-col gap-0.5'>
                    <div className={cn('font-semibold', { 'text-zinc-500': !tab.isActive })}>{tab.label}</div>
                    <div className={cn('text-sm', tab.isActive ? 'text-zinc-500' : 'text-zinc-400')}>{tab.description}</div>
                  </div>
                </button>
              ))}
            </div>
            {props.children}
          </div>
        </>
      ) : (
        <ManufacturingSkeletonLoader />
      )}
    </div>
  );
};
