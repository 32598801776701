import { RefObject, SetStateAction } from 'react';
import { ReductionFlow } from './ReductionFlow';
import { CreationFlow } from './CreationFlow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { Flow, BaselinePefReport, HistoricalPefReport, PortfolioJourney } from '../../../api';

interface Props {
  data: Partial<PortfolioJourney>;
  selectedFlow: Flow;
  selectedStep: string;
  setSelectedStep: (v: SetStateAction<string | undefined>) => void;
  targetWidgetRef: RefObject<HTMLDivElement>;
  latestHistoricalReport?: HistoricalPefReport;
  setLatestHistoricalReport: (v: SetStateAction<HistoricalPefReport | undefined>) => void;
  baselineReport?: BaselinePefReport;
  setBaselineReport: (v: SetStateAction<BaselinePefReport | undefined>) => void;
}

export const Journey = (props: Props) => {
  const reductionImpactMenuItems = [
    {
      id: '1',
      icon: <FontAwesomeIcon icon={light('chart-line')} />,
      name: 'Understand your initial impact',
      subCategories: props.data.firstParty
        ? [
            { id: '1.1', name: 'Capture all products' },
            { id: '1.2', name: 'Remove all defaults' },
            { id: '1.3', name: 'Identify highest impact products' },
          ]
        : [
            { id: '1.1', name: 'Capture all products' },
            { id: '1.3', name: 'Identify highest impact products' },
          ],
    },
    {
      id: '2',
      icon: <FontAwesomeIcon icon={light('square-poll-vertical')} />,
      name: 'Identify improvements',
      subCategories: [
        { id: '2.1', name: 'Set targets' },
        { id: '2.2', name: 'Identify reduction opportunities' },
        { id: '2.3', name: 'Forecast reduction at scale' },
        { id: '2.4', name: 'Implement changes' },
      ],
    },
    {
      id: '3',
      icon: <FontAwesomeIcon icon={light('chart-line-up')} />,
      name: 'Monitor progress over time',
      subCategories: [{ id: '3.1', name: 'Measure achieved reductions' }],
    },
    {
      id: '4',
      icon: <FontAwesomeIcon icon={light('code-compare')} />,
      name: 'Repeat the process',
      subCategories: [],
    },
  ];

  const newLowImpactProductsMenuItems = [
    {
      id: '1',
      icon: <FontAwesomeIcon icon={light('pencil')} />,
      name: 'Create a new product concept',
      subCategories: [],
    },
    {
      id: '2',
      icon: <FontAwesomeIcon icon={light('square-poll-vertical')} />,
      name: 'Identify improvements',
      subCategories: [
        { id: '2.1', name: 'Set targets' },
        { id: '2.2', name: 'Identify reduction opportunities' },
        { id: '2.3', name: 'Forecast reduction at scale' },
      ],
    },
    {
      id: '3',
      icon: <FontAwesomeIcon icon={light('conveyor-belt-boxes')} />,
      name: 'Start production',
      subCategories: [],
    },
    {
      id: '4',
      icon: <FontAwesomeIcon icon={light('code-compare')} />,
      name: 'Repeat the process',
      subCategories: [],
    },
  ];

  if (!props.selectedStep) {
    return <></>;
  }

  const menu = () => {
    return (
      <div className='flex flex-col gap-y-1.5 w-[310px] text-sm'>
        {(props.selectedFlow === Flow.ReduceExisting ? reductionImpactMenuItems : newLowImpactProductsMenuItems).map((item, i) => (
          <div className={cn('border rounded-lg', props.selectedStep?.startsWith(item.id) ? 'border-brand' : 'border-slate-100')} key={i}>
            <button
              className={cn(
                'flex items-center gap-x-3 w-full p-2 rounded-lg font-semibold',
                props.selectedStep?.startsWith(item.id) ? 'bg-white' : 'bg-slate-100',
              )}
              onClick={() => {
                if (item.subCategories.length > 0) {
                  props.setSelectedStep(item.subCategories[0].id);
                } else {
                  props.setSelectedStep(item.id);
                }
              }}
            >
              <div className={cn('flex p-2 rounded-full', props.selectedStep?.startsWith(item.id) ? 'bg-slate-100' : 'bg-white')}>
                {item.icon}
              </div>
              <div className='w-full flex items-center justify-between'>
                <div>{item.name}</div>
                <FontAwesomeIcon
                  className={cn(
                    'text-brand',
                    props.selectedStep?.startsWith(item.id) && item.subCategories.length === 0 ? 'visible' : 'invisible',
                  )}
                  icon={light('arrow-right')}
                />
              </div>
            </button>
            {props.selectedStep?.startsWith(item.id) && item.subCategories.length > 0 && (
              <div className='ml-10 mt-1 p-1 flex flex-col gap-y-1 mb-3'>
                {item.subCategories.map((subItem, i) => (
                  <button
                    key={i}
                    onClick={() => props.setSelectedStep(subItem.id)}
                    className={cn(
                      'flex items-center justify-between gap-x-3 w-full p-2 rounded-lg border border-white',
                      props.selectedStep?.startsWith(subItem.id) ? 'bg-slate-100' : 'bg-slate-50',
                      {
                        'font-semibold shadow-[inset_0_0_10px_1px_rgba(0,0,0,0.07)]': props.selectedStep?.startsWith(subItem.id),
                      },
                    )}
                  >
                    {subItem.name}
                    <FontAwesomeIcon
                      className={cn('text-brand', props.selectedStep?.startsWith(subItem.id) ? 'visible' : 'invisible')}
                      icon={light('arrow-right')}
                    />
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='flex flex-col gap-y-6 mx-6'>
      <div className='grid grid-cols-[310px_1px_auto] w-full gap-6'>
        {menu()}
        <div className='border-r h-full' />
        {props.selectedFlow === Flow.ReduceExisting && props.selectedStep && (
          <ReductionFlow
            data={props.data}
            targetWidgetRef={props.targetWidgetRef}
            selectedStep={props.selectedStep!}
            setSelectedStep={props.setSelectedStep}
            baselineReport={props.baselineReport}
            setBaselineReport={props.setBaselineReport}
            latestHistoricalReport={props.latestHistoricalReport}
            setLatestHistoricalReport={props.setLatestHistoricalReport}
          />
        )}
        {props.selectedFlow === Flow.DesignNew && props.selectedStep && (
          <CreationFlow
            data={props.data}
            targetWidgetRef={props.targetWidgetRef}
            selectedStep={props.selectedStep}
            setSelectedStep={props.setSelectedStep}
          />
        )}
      </div>
    </div>
  );
};
