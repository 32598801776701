import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { BaselinePefReport, ForecastReport, HistoricalPefReport } from '../../../../api';
import { ByProductContributionTable } from '../../../../components/ByProductContributionTable';
import { ImpactSelect } from '../../../../components/ImpactSelect';
import { ProductTile } from '../../../../components/ProductTile';
import { ViewToggle } from '../../../../components/ViewToggle';
import { HorizontalBarChart } from '../../../../components/charts/HorizontalBarChart';
import { HorizontalShareBarChart } from '../../../../components/charts/HorizontalShareBarChart';
import { useCsvByProductHref } from '../../../../hooks/useCsvByProductHref';
import { ImpactValueType } from '../../Products/Report/Sku/Overview';

interface Props {
  data: ForecastReport | HistoricalPefReport | BaselinePefReport;
}

enum View {
  Graph = 'Graph view',
  Table = 'Table view',
}

export const WorstOffenders = (props: Props) => {
  const [selectedView, setSelectedView] = useState<View>(View.Graph);
  const [selectedImpactGraph, setSelectedImpactGraph] = useState<{ id: string; name: string }>({
    id: props.data.analysis.impactsByProduct[0].impactId,
    name: props.data.analysis.impactsByProduct[0].impactName,
  });
  const [selectedImpactGraphUnit, setSelectedImpactGraphUnit] = useState<ImpactValueType>(ImpactValueType.Points);
  const csvHref = useCsvByProductHref(props);

  return (
    <>
      <div className='print:hidden flex flex-col bg-lightBg border-t border-neutral-300/50 py-6 px-6 gap-6'>
        <div className='flex'>
          <div className='flex-1' />
          <ViewToggle
            theme='dark'
            button1={{
              label: 'Graph view',
              icon: regular('chart-simple'),
              active: selectedView === View.Graph,
            }}
            button2={{
              label: 'Table view',
              icon: regular('table'),
              active: selectedView === View.Table,
            }}
            toggleView={() => setSelectedView((current) => (current === View.Graph ? View.Table : View.Graph))}
          />
          <div className='print:hidden flex-1 flex justify-end'>
            <a
              className='flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold text-base'
              download={`${(props.data as { name?: string }).name ?? 'Baseline Annual Volume Footprint Overview'} - Worst offenders.csv`}
              href={csvHref}
            >
              <FontAwesomeIcon icon={solid('download')} />
              Export as CSV
            </a>
          </div>
        </div>

        {selectedView === View.Graph ? (
          <>
            <div className='flex flex-col gap-y-6'>
              <div className='flex flex-col self-center w-2/3 bg-white border border-lightBgBorder2 rounded-2xl shadow-lg'>
                <div className='flex self-center px-3 py-1 rounded-md text-brandDark font-semibold mb-3 mt-6 bg-brand/10'>
                  Contribution to the overall baseline impact
                </div>
                <div className='p-6 pr-12'>
                  <HorizontalShareBarChart
                    data={props.data.analysis.highestImpactProducts.map((product) => {
                      const product2 = props.data.products.find((product2) => product2.id === product.id);
                      return {
                        ...product,
                        skuId: product2?.sku,
                        count: product2?.count,
                        parentId: product2?.parentId,
                      };
                    })}
                    fixedHeight
                    withTooltip
                  />
                </div>
              </div>
              <div className='flex flex-col self-center gap-6 w-2/3'>
                {(() => {
                  const highestImpactProduct = props.data.products[0];
                  const lowestImpactProduct = props.data.products[props.data.products.length - 1];
                  return [
                    {
                      title: 'Highest Impact Product',
                      product: {
                        id: highestImpactProduct.id,
                        parentId: highestImpactProduct.parentId,
                        name: highestImpactProduct.name,
                        overallImpact: highestImpactProduct.overallImpact,
                        impactPoints: highestImpactProduct.impactPoints,
                        amount: highestImpactProduct.amount,
                        imageUrl: highestImpactProduct.imageUrl,
                        count: highestImpactProduct.count,
                      },
                    },
                    {
                      title: 'Lowest Impact Product ',
                      product: {
                        id: lowestImpactProduct.id,
                        parentId: lowestImpactProduct.parentId,
                        name: lowestImpactProduct.name,
                        overallImpact: lowestImpactProduct.overallImpact,
                        impactPoints: lowestImpactProduct.impactPoints,
                        amount: lowestImpactProduct.amount,
                        imageUrl: lowestImpactProduct.imageUrl,
                        count: lowestImpactProduct.count,
                      },
                    },
                  ].map((product, i) => <ProductTile bgWhite key={i} arrowDirection={i === 0 ? 'up' : 'down'} data={product} />);
                })()}
              </div>
            </div>

            <div className='flex flex-col gap-6'>
              <div className='text-center text-lg font-semibold'>Highest impact products by impact category</div>
              <ImpactSelect
                theme='light'
                impacts={props.data.analysis.impactsByProduct.map(({ impactId, impactName }) => ({ id: impactId, name: impactName }))}
                selectedImpact={selectedImpactGraph}
                setSelectedImpact={setSelectedImpactGraph}
                selectedImpactValueType={selectedImpactGraphUnit}
                setSelectedImpactValueType={setSelectedImpactGraphUnit}
              />
              <div className='flex self-center w-full xl:w-2/3 flex-col bg-white border border-lightBgBorder2 rounded-2xl shadow-lg'>
                <div className='flex self-center px-3 py-1 rounded-md text-brandDark font-semibold mb-3 mt-6 bg-brand/10'>
                  Highest impact products by impact category
                </div>
                <div className='p-6'>
                  <HorizontalBarChart
                    impact={props.data.analysis.impactsByProduct.find(({ impactId }) => impactId === selectedImpactGraph.id)!}
                    valueType={selectedImpactGraphUnit}
                    products={props.data.products}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <ByProductContributionTable
            overallImpact={props.data.overallImpact}
            impactPoints={props.data.impactPoints}
            products={props.data.products}
          />
        )}
      </div>
      <div className='hidden print:block'>
        <ByProductContributionTable
          overallImpact={props.data.overallImpact}
          impactPoints={props.data.impactPoints}
          products={props.data.products}
        />
      </div>
    </>
  );
};
