import { createContext, PropsWithChildren, useContext } from 'react';

interface ControlEvents {
  lastTouched: string;
  emitTouched: (path: string) => void;
  emitTouchedInput: (path: string) => void;
}

const Context = createContext<ControlEvents | null>(null);

export const useControlEvents = () => useContext(Context);

export const ControlEventsProvider = (
  props: PropsWithChildren<{
    lastTouched: string;
    onTouched: (params: { path: string; input: boolean }) => void;
  }>,
) => (
  <Context.Provider
    value={{
      lastTouched: props.lastTouched,
      emitTouched: (path) => props.onTouched({ path, input: false }),
      emitTouchedInput: (path) => props.onTouched({ path, input: true }),
    }}
  >
    {props.children}
  </Context.Provider>
);
