import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { TargetType, TargetImpact, getManufacturingTargets, saveManufacturingTargets } from '../../../../api';
import { LimitTooltip } from '../../../../components/LimitTooltip';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { createProduct } from '../../Products/Details/dataModel';
import { useProfile } from '../../../../hooks/useProfile';
import { TargetTiles } from './TargetTiles';
import { StickyHeader } from '../../../../components/StickyHeader';
import { ReadOnlyWarning } from '../../../../components/ReadOnlyWarning';

export const targetValidationSchema = yup.object().shape({
  reduction: yup.number().when('type', {
    is: (type: TargetType) => TargetType.DISABLED !== type,
    then: yup.number().positive().min(0.01).max(100).required(),
    otherwise: yup
      .number()
      .transform((_) => null)
      .nullable(),
  }),
  target: yup.date().when('type', {
    is: (type: TargetType) => TargetType.LONG_TERM === type,
    then: yup.date().min(new Date()).required(),
    otherwise: yup
      .date()
      .transform((_) => null)
      .nullable(),
  }),
});

const formValidationSchema = yup.object().shape({
  targetImpacts: yup.array().of(targetValidationSchema),
});

export const Main = () => {
  const response = useApiQuery(getManufacturingTargets());
  const [targets, setTargets] = useState<{ targetImpacts: TargetImpact[] }>({ targetImpacts: [] });
  const [totalTargets, setTotalTargets] = useState<number>();
  const [hasCompleteProducts, setHasCompleteProducts] = useState<boolean>(false);
  const [createLimit, setCreateLimit] = useState(false);
  const navigate = useNavigate();
  const profile = useProfile();
  const ref = useRef<HTMLDivElement>(null);
  const previousFormSnapshot = useRef<{ targetImpacts: TargetImpact[] }>();

  useEffect(() => {
    if (response.data) {
      setTotalTargets(response.data.totalTargets);
      setHasCompleteProducts(response.data.hasCompleteProducts);

      const data = {
        targetImpacts: cloneDeep(
          sortBy(
            sortBy(
              response.data.targetImpacts.map((item) => {
                return {
                  ...item,
                  status: item.type === TargetType.DISABLED ? 'disabled' : 'idle',
                  internalType: item.type === TargetType.DISABLED ? TargetType.LONG_TERM : item.type,
                };
              }),
              (item) => !item.isMajorCategory,
            ),
            (item) => item.type === TargetType.DISABLED,
          ),
        ).map((item) => ({
          ...item,
          reduction: item.reduction === 0 ? null : item.reduction,
        })),
      };
      data.targetImpacts.forEach((item) => {
        delete item.method;
      });

      setTargets(data);
      previousFormSnapshot.current = data;
    }
  }, [response.data]);

  if (!targets) {
    return <></>;
  }

  const createNewProduct = () =>
    createProduct().call({
      ok: ({ id, errorCode }) => {
        if (errorCode) {
          setCreateLimit(true);
        } else {
          navigate(`/products/${id}`);
        }
      },
    });

  return (
    <Formik<{ targetImpacts: TargetImpact[] }>
      enableReinitialize
      validateOnBlur
      validateOnChange
      validationSchema={formValidationSchema}
      initialValues={targets}
      onSubmit={(values) => {
        previousFormSnapshot.current = cloneDeep(values);

        const payload = values.targetImpacts.map((item) =>
          item.status === 'updated'
            ? {
                ...item,
                type: TargetType.DISABLED,
                reduction: item.reduction === null ? 0 : item.reduction!,
              }
            : {
                ...item,
                reduction: item.reduction === null ? 0 : item.reduction!,
              },
        );

        saveManufacturingTargets({ targetImpacts: payload }).ok((targets) => {
          const data = {
            targetImpacts: cloneDeep(
              sortBy(
                sortBy(targets.targetImpacts, (item) => !item.isMajorCategory),
                (item) => item.type === TargetType.DISABLED,
              ),
            ),
          };
          data.targetImpacts.forEach((item) => {
            delete item.method;
          });

          setTotalTargets(targets.totalTargets);
          setTargets({
            targetImpacts: data.targetImpacts.map((item) => ({
              ...item,
              reduction: item.reduction === 0 ? null : item.reduction,
              status: item.type === TargetType.DISABLED ? 'disabled' : 'idle',
              internalType: item.type === TargetType.DISABLED ? TargetType.LONG_TERM : item.type,
              ...(() => {
                return previousFormSnapshot.current?.targetImpacts.find(
                  (prevItem) => prevItem.id === item.id && prevItem.status === 'updated',
                );
              })(),
            })),
          });
          ref.current?.scrollIntoView({ block: 'start' });
        });
      }}
    >
      {(formik) => (
        <div ref={ref} className='flex flex-col gap-6 text-base mb-20'>
          <StickyHeader className='px-6'>
            <div className='text-lg font-semibold text-zinc-800 flex items-center'>Targets</div>
          </StickyHeader>

          <div className='px-6 flex flex-col gap-y-6'>
            <div>
              Targets are enablers of progress. Whether you have internal targets dictated by management or you want to set some yourself,
              here is the place to do it. You can set targets for your overall environmental impact or for individual impact categories. You
              can also choose to set them for a target year or year-over-year depending on your goals. Don’t forget that targets are
              relative to your baseline, so make sure you set your baseline first.
            </div>

            {(!hasCompleteProducts || totalTargets === 0) && (
              <div className='flex flex-col gap-y-6'>
                {!hasCompleteProducts && totalTargets === 0 && (
                  <div className='flex gap-x-4 rounded-2xl border p-4'>
                    <div className='flex self-center items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                      <FontAwesomeIcon className='h-5 aspect-square' icon={light('lightbulb')} />
                    </div>
                    <div className='flex flex-col gap-y-4'>
                      <div>
                        <span className='font-semibold'>You need to create some products before setting a new target.</span> Once you create
                        your first products, you’ll be able to add your reduction targets, for your overall impact or specific impact
                        categories, in this section.
                      </div>
                      <LimitTooltip
                        placement='top-start'
                        enabled={createLimit}
                        entityName='products limit'
                        valueKey='maxProductSkuCount'
                        onDismiss={() => setCreateLimit(false)}
                      >
                        <div>
                          <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                            <button
                              onClick={createNewProduct}
                              disabled={!profile.selectedWorkspace.permissions.productManagement}
                              className='flex self-start shadow-regular [&:active:not(:disabled)]:scale-95 bg-brand text-white font-semibold px-3.5 py-1.5 rounded-full disabled:bg-neutral-300 disabled:cursor-not-allowed'
                            >
                              Create new product
                            </button>
                          </ReadOnlyWarning>
                        </div>
                      </LimitTooltip>
                    </div>
                  </div>
                )}
                {totalTargets === 0 && hasCompleteProducts && (
                  <div className='flex flex-col gap-y-4 rounded-2xl border p-4'>
                    <div className='flex self-center items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                      <FontAwesomeIcon className='h-5 aspect-square' icon={light('lightbulb')} />
                    </div>
                    <div className='flex flex-col gap-2'>
                      <div className='text-lg font-semibold text-center'>Set your first target.</div>
                      <div className='text-center'>
                        You can set targets for overall environmental impact reduction or for each impact category separately. Choose
                        whether you want to set a target for a specific year or a year-over-year reduction target.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className='flex flex-col gap-y-2'>
              <TargetTiles
                targets={targets}
                setTargets={setTargets}
                disabled={!hasCompleteProducts}
                submitForm={formik.submitForm}
                previousFormSnapshot={previousFormSnapshot}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
