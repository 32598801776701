import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef } from 'react';
import * as yup from 'yup';
import { ModalForm, ModalFormApi } from '../../../../components/ModalForm';
import { Toggle } from '../../../../components/Toggle';
import { BestMatchBadge, DefaultBadge, ExactMatchBadge, PlaceholderBadge, UnmatchedBadge } from './Badges';

interface FormValues {
  untag: boolean;
}

type Props = PropsWithChildren<{
  modalRef?: RefObject<ModalFormApi>;
  onConfirm: (values: FormValues) => void;
}>;

export const StepsUntaggingModal = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <ModalForm
      ref={props.modalRef}
      size='narrow-'
      formRef={formRef}
      title='Validating'
      body={<Body />}
      data={{
        untag: false,
      }}
      validationSchema={yup.object()}
      saveLabel='Confirm'
      onSave={({ values, closeModal }) => {
        props.onConfirm(values);
        closeModal();
      }}
    />
  );
};

const Body = () => {
  const formik = useFormikContext<FormValues>();

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex flex-col gap-3 text-neutral-900'>
        <div>Is everything in this graph a true representation of this facility’s production flow?</div>
        <Field name='untag'>{(model: FieldProps) => <Toggle model={model} />}</Field>
      </div>
      {formik.values.untag && (
        <div className='flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
          <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
          <div>
            Toggling this to ‘Yes’ would essentially remove all <BestMatchBadge className='inline relative bottom-px' />{' '}
            <DefaultBadge className='inline relative bottom-px' /> <ExactMatchBadge className='inline relative bottom-px' /> badges that may
            be present on from the nodes and fields. Only <UnmatchedBadge className='inline relative bottom-px' /> and{' '}
            <PlaceholderBadge className='inline relative bottom-px' /> badges would remain as these must be manually handled to become
            valid.
          </div>
        </div>
      )}
    </div>
  );
};
