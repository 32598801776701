import { forwardRef } from 'react';
import cn from 'classnames';
import {
  PortfolioBaselineNotSet,
  PortfolioBaselineSet,
  PortfolioEmpty,
  PortfolioHistoricalCreated,
  PortfolioState,
} from '../../../../../../api';

interface Props {
  title: string;
  portfolio: PortfolioEmpty | PortfolioBaselineSet | PortfolioHistoricalCreated | PortfolioBaselineNotSet;
}

export const DataCompletion = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const completion = props.portfolio.completion;
  /* TODO: doesn't return it in case of FE workspace */
  const firstParty =
    props.portfolio.state !== PortfolioState.Empty && props.portfolio.firstParty ? props.portfolio.firstParty : { averageFirstParty: 0 };

  const totalProducts = completion.complete + completion.drafts + completion.missing;
  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex items-center justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      </div>
      <div className='grid grid-cols-2 gap-3'>
        {(() => {
          const data = {
            title: 'Product entries',
            total: completion.complete + completion.drafts + completion.missing,
            items: [
              {
                name: 'Complete',
                value: completion.complete,
                showOnBar: (completion.complete / totalProducts) * 100 > 10,
                color: 'bg-indigo-200',
              },
              {
                name: 'Drafts',
                value: completion.drafts,
                color: 'bg-blue-400',
                showOnBar: (completion.drafts / totalProducts) * 100 > 10,
              },
              {
                name: 'Missing',
                value: completion.missing,
                color: 'bg-slate-100',
                showOnBar: (completion.missing / totalProducts) * 100 > 10,
              },
            ],
          };

          return (
            <div key={data.title} className='flex flex-col gap-4 p-4 border border-zinc-200 bg-white rounded-2xl'>
              <div className='px-1 text-zinc-800 font-semibold text-base'>{data.title}</div>
              <div className='flex flex-col gap-2'>
                <div className='px-1 text-2xl text-zinc-700'>
                  {completion.complete + completion.drafts}/{completion.target}
                </div>
                <div className='flex rounded-full overflow-clip text-base text-zinc-500 bg-slate-100'>
                  {data.items.map(({ name, value, showOnBar, color }) => (
                    <div key={name} className={cn('h-8 flex justify-center items-center', color)} style={{ width: `${value * 100}%` }}>
                      {showOnBar && <>{Math.round((value / data.total) * 100)}%</>}
                    </div>
                  ))}
                </div>
                <div className='px-1 flex justify-between'>
                  {data.items.map(({ name, color }) => (
                    <div key={name} className='flex items-center gap-1'>
                      <div
                        className={cn('size-2.5 border rounded-full', color, name === 'Missing' ? 'border-zinc-300' : 'border-transparent')}
                      />
                      <div className='text-zinc-500 text-xs'>{name}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })()}
        <div className='flex flex-col gap-4 p-4 border border-zinc-200 bg-white rounded-2xl'>
          <div className='px-1 text-zinc-800 font-semibold text-base'>First party data</div>
          <div className='flex flex-col gap-2'>
            <div className='px-1 text-2xl text-zinc-700'>{firstParty?.averageFirstParty}%</div>
            <div className='flex rounded-full overflow-clip text-base text-zinc-500 bg-slate-100'>
              <div
                className={cn('h-8 flex justify-center items-center bg-purple-200')}
                style={{ width: `${firstParty?.averageFirstParty}%` }}
              />
            </div>
            <div className='px-1 flex justify-between'>
              <div className='flex items-center gap-1'>
                <div className={cn('size-2.5 border rounded-full bg-purple-200 border-transparent')} />
                <div className='text-zinc-500 text-xs'>Complete</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
