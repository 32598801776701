import { Fragment, ReactNode } from 'react';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingGhgReport } from '../../../../../../api';
import { simplify } from '../../../../shared';
import { format } from 'date-fns';

interface Props {
  data: ManufacturingGhgReport;
}

export const Summary = (props: Props) => {
  const scope3totalEmission = props.data.analysis.scopeEmissions.find(({ id }) => id === 'scope_3')?.totalEmission!;
  const categoriesInScope = {
    count: props.data.analysis.categories.filter(({ isOutOfScope }) => !isOutOfScope).length,
    total: props.data.analysis.categories.length,
  };

  const { data } = props;
  const records: { label: string | ReactNode; value: number | string | ReactNode }[] = [
    {
      label: 'Reporting timeframe',
      value: `${format(new Date(data.startDate), 'dd/MM/yy')} - ${format(new Date(data.endDate), 'dd/MM/yy')}`,
    },
    {
      label: 'Number of products produced',
      value: data.productCount,
    },
    {
      label: 'Total number of manufactured units',
      value: data.totalUnitCount,
    },
    {
      label: 'Estimated total emissions (scope 1/2/3)',
      value: `${simplify(props.data.analysis.totalEmission.value)} ${props.data.analysis.totalEmission.unit}`,
    },
    {
      label: 'Estimated Scope 3 contribution',
      value: `${simplify(scope3totalEmission.value)} ${scope3totalEmission.unit}`,
    },
    {
      label: (
        <div className='flex gap-x-1.5 border border-amber-400 rounded-lg bg-amber-50 px-2 p-1 mr-16'>
          <FontAwesomeIcon className='text-amber-400 mt-1' icon={regular('exclamation-triangle')} />
          <div className='text-sm'>
            Only {categoriesInScope.count} out of {categoriesInScope.total} Scope 3 categories are in scope of this assessment.
          </div>
        </div>
      ),
      value: '',
    },
    {
      label: 'Report creator',
      value: data.author,
    },
    {
      label: 'Date of report creation',
      value: format(new Date(data.createdAt), 'dd/MM/yy'),
    },
    {
      label: 'Versioning',
      value: (
        <>
          <ul className='list-disc ml-3.5'>
            <li>Methodology v.{data.metadata.methodologyVersion}</li>
            <li>Web application v.{data.metadata.webAppVersion}</li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <div className='flex flex-col gap-8 print:text-sm text-dark'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>A brief overview of the historical assessment carried out</div>
        <NavigationButtons type='icons' next={{ path: '../volumes' }} />
      </div>
      <div className='grid grid-cols-[1fr_2fr] gap-y-3 gap-x-10 border rounded-2xl shadow-regular p-8'>
        {records.map((record, index) => (
          <Fragment key={index}>
            <div className='font-semibold'>{record.label}</div>
            <div>{record.value}</div>
          </Fragment>
        ))}
      </div>
      <NavigationButtons type='buttons' next={{ path: '../volumes', label: 'Volumes' }} />
    </div>
  );
};
