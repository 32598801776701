import { getPefBaseline, getPefHistoricalReport, ManufacturingCycle, ProductModel, ProductWithAmount } from '../../../../../../../api';
import { useEffect, useState } from 'react';
import { useApiQuery } from '../../../../../../../hooks/useApiQuery';
import { useFormikContext } from 'formik';
import { SetProductsCount } from '../../../../components/Lists/SetProductsCount';
import { SelectProductsModal } from '../../../../components/Lists/SelectProductsModal';
import { simplify } from '../../../../../shared';
import cn from 'classnames';
import { differenceInCalendarMonths } from 'date-fns';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { convertUTCDateToLocal } from '../../../../../../../components/datepicker/utils';
import { useDynamicHeight } from '../../../../../../../hooks/useDynamicHeight';

interface Props {
  reportTitle: string;
  selectedReportId: string;
  setTriedSubmitting: (v: boolean) => void;
}

export const Step3 = (props: Props) => {
  const isBaseline = props.selectedReportId === 'baseline';
  const historicalReport = useApiQuery(getPefHistoricalReport(props.selectedReportId), { cancel: isBaseline });
  const baselineReport = useApiQuery(getPefBaseline(), { cancel: !isBaseline });
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const height = useDynamicHeight(containerRef, undefined, true);
  const data = historicalReport.data ?? baselineReport.data;
  const formik = useFormikContext<{
    cycle: ManufacturingCycle;
    products: (ProductWithAmount & { models: ProductModel[] })[];
  }>();

  useEffect(() => {
    if (data) {
      formik.setFieldValue(
        'products',
        data.products.map((product) => ({ ...product, models: [], selected: true })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const differenceInMonths =
    data && differenceInCalendarMonths(new Date(convertUTCDateToLocal(data.endDate)), new Date(convertUTCDateToLocal(data.startDate)));

  const diff1year =
    differenceInMonths && differenceInMonths === 11 && startOfMonth(new Date(data!.startDate)) && endOfMonth(new Date(data!.endDate));

  const diff1quarter =
    differenceInMonths && differenceInMonths === 2 && startOfMonth(new Date(data!.startDate)) && endOfMonth(new Date(data!.endDate));

  const diff1month = differenceInMonths === 0 && startOfMonth(new Date(data!.startDate)) && endOfMonth(new Date(data!.endDate));

  useEffect(() => {
    if (diff1year) {
      formik.setFieldValue('cycle', ManufacturingCycle.Year);
    } else if (diff1quarter) {
      formik.setFieldValue('cycle', ManufacturingCycle.Quarter);
    } else if (diff1month) {
      formik.setFieldValue('cycle', ManufacturingCycle.Month);
    } else {
      formik.setFieldValue('cycle', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [differenceInMonths]);

  return (
    <div className='flex flex-col gap-y-6 min-h-[calc(100vh_-_theme(spacing.20)_-_80px)]'>
      <>
        <div className='flex flex-col gap-y-2'>
          <div className='text-lg font-semibold'>Confirm or edit</div>
          <div>Adjust products and volumes to understand your potential impact reduction at scale.</div>
        </div>
        {data ? (
          <div className='grid grid-cols-[1fr_1px_8fr] gap-x-6 h-full'>
            <div className='flex flex-col gap-y-6'>
              <div className='font-semibold'>Timeframe</div>
              <div className='flex flex-col gap-y-4'>
                {[
                  {
                    cycle: ManufacturingCycle.Year,
                  },
                  {
                    cycle: ManufacturingCycle.Quarter,
                  },
                  {
                    cycle: ManufacturingCycle.Month,
                  },
                ].map((item) => (
                  <button
                    key={item.cycle}
                    onClick={() => {
                      formik.setFieldValue('cycle', item.cycle);
                    }}
                    className={cn(
                      'p-4 border rounded-[8px] capitalize',
                      {
                        'bg-slate-100 border-brand font-semibold': formik.values.cycle === item.cycle,
                      },
                      {
                        'border-red-500': formik.errors.cycle,
                      },
                    )}
                  >
                    {item.cycle}
                  </button>
                ))}
              </div>
            </div>
            <div className='bg-zinc-200 h-full w-px'></div>
            <div className='flex flex-col gap-y-6'>
              <div className='font-semibold'>Products and volumes</div>
              <SetProductsCount />
            </div>
          </div>
        ) : (
          <div ref={setContainerRef} style={{ height }} className='grid grid-cols-[1fr_1px_8fr] gap-x-6 h-full'>
            <div className='flex flex-col gap-y-6'>
              <div className='h-6 w-full loading-skeleton'></div>
              <div className='flex flex-col gap-y-4'>
                {new Array(3).fill(0).map((_, i) => (
                  <div key={i} className='loading-skeleton h-[58px] w-full'></div>
                ))}
              </div>
            </div>
            <div className='bg-zinc-200 h-full w-px' />
            <div className='flex flex-col gap-y-6'>
              <div className='flex self-start w-48'>
                <div className='flex self-start h-6 loading-skeleton'></div>
              </div>
              <div className='h-full w-full loading-skeleton'></div>
            </div>
          </div>
        )}
        <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
          <div className='px-12 py-4 flex justify-between w-full max-w-screen-xl'>
            <div className='flex items-center gap-x-4'>
              <div>Total volumes added:</div>
              {[
                {
                  label: 'Products',
                  count: formik.values.products.length,
                },
                {
                  label: 'Units',
                  count:
                    formik.values.products.reduce((acc, { count }) => acc + count, 0) +
                    formik.values.products.flatMap(({ models }) => models).reduce((acc, { count }) => acc + count, 0),
                },
              ].map((item, i) => (
                <div key={i} className='flex items-center gap-x-1 p-2 pr-4 border rounded-full'>
                  <div className='text-[12px] text-brand px-[6px] py-[2px] bg-slate-100 rounded-full'>{simplify(item.count)}</div>
                  <div className='text-sm text-zinc-500'>{item.label}</div>
                </div>
              ))}
              <SelectProductsModal>
                <button className='bg-[#e8eaf5] text-[#220065] text-sm font-semibold py-2 px-4 rounded-full'>
                  Add more products manually
                </button>
              </SelectProductsModal>
            </div>

            <button
              className={cn(
                'flex text-sm items-center gap-2.5 border-2 rounded-full px-4 py-1 font-semibold',
                '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
                'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
              )}
              onClick={async () => {
                props.setTriedSubmitting(true);
                await formik.submitForm();
              }}
            >
              Create report
            </button>
          </div>
        </div>
      </>
    </div>
  );
};
