import { useEffect, useRef, useState } from 'react';
import { ExactDatePicker } from '../../../../../../components/datepicker/ExactDatePicker';
import { YearPicker } from '../../../../../../components/datepicker/YearPicker';
import { convertLocalToUTCDate } from '../../../../../../components/datepicker/utils';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps, Form, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import getYear from 'date-fns/getYear';
import setDay from 'date-fns/setDay';
import setMonth from 'date-fns/setMonth';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import cn from 'classnames';
import { BaselineTimeframeType } from '../../../../../../components/charts/ProgressTracking/converter';

interface Props {
  startDate: Date | null;
  setStartDate: (value: Date | null) => void;
  endDate: Date | null;
  setEndDate: (value: Date | null) => void;
  step: number;
  onNext: () => void;
}

export const Step2 = (props: Props) => {
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  return (
    <>
      <Formik<{
        startDate: Date | null;
        endDate: Date | null;
      }>
        validateOnBlur={triedToSubmit}
        validateOnChange={triedToSubmit}
        initialValues={{
          startDate: props.startDate,
          endDate: props.endDate,
        }}
        validationSchema={yup.object().shape({
          startDate: yup
            .date()
            .max(sub(new Date(), { years: 1, days: 1 }))
            .required('Field is required'),
        })}
        onSubmit={(payload) => {
          props.setStartDate(payload.startDate);
          props.onNext();
        }}
      >
        <Content setStartDate={props.setStartDate} setEndDate={props.setEndDate} setTriedToSubmit={setTriedToSubmit} />
      </Formik>
    </>
  );
};

interface ContentProps {
  setStartDate: (value: Date | null) => void;
  setEndDate: (value: Date | null) => void;
  setTriedToSubmit: (value: boolean) => void;
}

const Content = (props: ContentProps) => {
  const [checked, setChecked] = useState(false);
  const [selectedType, setSelectedType] = useState<BaselineTimeframeType>(BaselineTimeframeType.Calendar);
  const [showWarning1, setShowWarning1] = useState(false);
  const [showWarning2, setShowWarning2] = useState(false);
  const defaultTaxStartDateYear = new Date(getYear(new Date()) - 1, 3, 1);
  const defaultCalendarStartDate = new Date(getYear(new Date()) - 1, 0, 1);
  const formik = useFormikContext<{ startDate: Date; endDate: Date }>();
  const firstRender = useRef(true);

  useEffect(() => {
    if (selectedType === BaselineTimeframeType.Calendar) {
      formik.setFieldValue('startDate', defaultCalendarStartDate);
      props.setStartDate(defaultCalendarStartDate);
    } else {
      formik.setFieldValue('startDate', defaultTaxStartDateYear);
      props.setStartDate(defaultTaxStartDateYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    if (formik.values.startDate) {
      formik.setFieldValue('endDate', sub(add(formik.values.startDate, { years: 1 }), { days: 1 }));
      props.setEndDate(sub(add(formik.values.startDate, { years: 1 }), { days: 1 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.startDate]);

  useEffect(() => {
    if (!firstRender.current) {
      if (checked) {
        formik.setFieldValue('startDate', null);
        formik.setFieldValue('endDate', null);
        props.setStartDate(null);
        props.setEndDate(null);
      } else {
        if (selectedType === BaselineTimeframeType.Calendar) {
          formik.setFieldValue('startDate', defaultCalendarStartDate);
          props.setStartDate(defaultCalendarStartDate);
        } else {
          formik.setFieldValue('startDate', defaultTaxStartDateYear);
          props.setStartDate(defaultTaxStartDateYear);
        }
      }
    } else {
      firstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  return (
    <Form className='flex flex-col gap-y-4'>
      <div className='font-semibold text-lg'>Choosing the time period</div>
      <div className='flex flex-col gap-y-4'>
        <div>
          Choose a single year for which you have real data on your production volumes. You can pick either a calendar year (January to
          December) or a tax year (April to March). You can choose a different 1-year timeframe if you only have data available for a
          different period, but we won't be able to compare your progress year by year against that baseline.
        </div>
        <div className='flex flex-col gap-y-2'>
          <div className='flex gap-4 items-center text-sm'>
            Year
            <Field name='startDate'>
              {(model: FieldProps<string | undefined>) => (
                <div>
                  <YearPicker
                    inputWidth='w-20'
                    textSize='text-sm'
                    disabled={checked}
                    specificMonth={selectedType === BaselineTimeframeType.Tax ? 3 : 0}
                    maxDate={sub(new Date(), { years: 1, days: 1 })}
                    model={!checked ? model : { ...model, field: { ...model.field, value: undefined } }}
                    border='border border-zinc-200'
                  />
                  <div className='relative'>
                    {model.meta.error && !checked && <div className='absolute  w-32 top-1.5 left-1 text-f text-xs'>Field is required</div>}
                  </div>
                </div>
              )}
            </Field>
            <div className='grid grid-cols-2 rounded-full border divide-x overflow-hidden'>
              {[BaselineTimeframeType.Calendar, BaselineTimeframeType.Tax].map((type) => (
                <button
                  key={type}
                  disabled={checked}
                  type='button'
                  className={cn(
                    'relative py-1 px-7 text-center',
                    'disabled:bg-neutral-100 disabled:text-zinc-400 disabled:cursor-not-allowed',
                    { 'bg-slate-100 text-violet-900': selectedType === type },
                  )}
                  onClick={() => {
                    setSelectedType(type);
                    if (type === BaselineTimeframeType.Calendar) {
                      formik.setFieldValue('startDate', convertLocalToUTCDate(sub(setDay(formik.values.startDate, 1), { years: 1 })));
                    } else {
                      formik.setFieldValue('startDate', convertLocalToUTCDate(sub(setMonth(formik.values.startDate, 3), { years: 1 })));
                    }
                  }}
                >
                  <FontAwesomeIcon
                    className={cn('absolute left-1 translate-y-1/4 w-6', { invisible: checked || selectedType !== type })}
                    icon={light('check')}
                  />
                  {
                    {
                      [BaselineTimeframeType.Calendar]: 'Calendar year',
                      [BaselineTimeframeType.Tax]: 'Tax year',
                    }[type]
                  }
                </button>
              ))}
            </div>
          </div>
          <div className='flex gap-x-2 items-center'>
            I want to specify a starting date
            <div className='relative flex items-center gap-4 mt-0.5'>
              <input
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                className='opacity-0 absolute h-4 w-7 z-10 hover:cursor-pointer'
                type='checkbox'
              />
              <div
                className={cn(
                  'px-0.5 outline flex items-center rounded-full relative h-4 w-7',
                  checked ? 'bg-brand outline-brand' : 'bg-zinc-300 outline-zinc-300',
                )}
              >
                <div
                  className={cn('absolute rounded-full size-3 transition-[left] duration-25', {
                    'left-3.5 bg-white': checked,
                    'left-0.5 bg-white': !checked,
                  })}
                />
              </div>
            </div>
            <FontAwesomeIcon
              onMouseEnter={() => setShowWarning1(true)}
              onMouseLeave={() => setShowWarning1(false)}
              className={cn('text-xl text-amber-400', { hidden: isFirstDayOfMonth(formik.values.startDate) && checked })}
              icon={regular('exclamation-triangle')}
            />
            <div className={cn('w-72 border border-amber-400 rounded-xl bg-amber-50 py-2 pl-2.5 text-sm', { invisible: !showWarning1 })}>
              By choosing this option, we won't be able to compare your progress year by year against this baseline.
            </div>
          </div>

          {checked && (
            <div className='mb-6'>
              <Field name='startDate'>
                {(model: FieldProps<string | undefined>) => (
                  <>
                    <ExactDatePicker model={model} />
                    <div className='relative ml-10'>
                      {model.meta.error && <div className='absolute w-32 top-1.5 left-1 text-f text-xs'>Field is required</div>}
                    </div>
                  </>
                )}
              </Field>
            </div>
          )}
          <div className='h-[1px] bg-slate-200 w-full' />
        </div>
        <div className='flex justify-between'>
          <div className='flex gap-x-3'>
            <div className='flex flex-col gap-y-2'>
              <div className='text-sm'>Selected time period</div>
              {formik.values.startDate && formik.values.endDate && (
                <div className='text-xl'>
                  {format(new Date(formik.values.startDate), 'dd MMMM yyyy')} - {format(formik.values.endDate, 'dd MMMM yyyy')}
                </div>
              )}
            </div>

            {formik.values.startDate && formik.values.endDate && checked && (
              <>
                <FontAwesomeIcon
                  onMouseEnter={() => setShowWarning2(true)}
                  onMouseLeave={() => setShowWarning2(false)}
                  className={cn('text-xl text-amber-400 self-center mt-1', {
                    hidden: isFirstDayOfMonth(formik.values.startDate),
                  })}
                  icon={regular('exclamation-triangle')}
                />
                <div
                  className={cn('w-72 border border-amber-400 rounded-xl bg-amber-50 py-2 pl-2.5 text-sm', { invisible: !showWarning2 })}
                >
                  By choosing this option, we won't be able to compare your progress year by year against this baseline.
                </div>
              </>
            )}
          </div>

          <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
            <div className='px-12 py-4 flex justify-end w-full max-w-screen-xl'>
              <button
                type='button'
                className={cn(
                  'flex self-end text-lg items-center gap-2.5 border-2 rounded-full px-4 py-1 font-semibold',
                  '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
                  'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
                )}
                onClick={async () => {
                  props.setTriedToSubmit(true);
                  await formik.submitForm();
                }}
              >
                Next
                <FontAwesomeIcon className='text-base' icon={solid('chevron-right')} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
