import { SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaselinePefReport, PortfolioDashboard, TrackingTarget } from '../../../api';
import { Methodology } from './types';
import { SingleSelect } from '../../../components/SingleSelect';
import { ImpactValueType } from '../Products/Report/Sku/Overview';
import { simplify } from '../shared';
import cn from 'classnames';

interface Props {
  methodology: Methodology;
  portfolio: PortfolioDashboard;
  selectedImpact: { id: string; name: string };
  setSelectedImpact: (v: SetStateAction<{ id: string; name: string }>) => void;
  selectedType: ImpactValueType;
  setSelectedType: (v: SetStateAction<ImpactValueType>) => void;
  baseline?: BaselinePefReport;
  targets?: {
    impactId: string;
    target?: TrackingTarget;
  }[];
}

export const Header = (props: Props) => {
  const data = props.baseline;

  const getTotalImpactValue = () => {
    const impact = data?.analysis?.impactStageMatrix?.find((impact) => impact.impactId === props.selectedImpact.id)!;

    return {
      value:
        props.selectedImpact.id === 'overall'
          ? data?.impactPoints
          : props.selectedType === 'impactPoints'
          ? impact.impactPoints
          : impact.physicalValue,
      unit: props.selectedType === 'impactPoints' ? 'Impact p.' : impact.unit,
    };
  };

  return (
    <div className='flex justify-center h-20 z-10 sticky top-0 bg-white border-b border-zinc-200'>
      <div className='grid grid-cols-3 items-center mx-6 w-[calc(theme(screens.xl)-theme(spacing.24))]'>
        <div className='flex items-center gap-x-3 text-zinc-800 text-base font-semibold'>
          <NavLink
            to='/'
            className='size-8 flex items-center justify-center rounded-lg bg-slate-200 hover:outline outline-brand active:scale-95'
          >
            <FontAwesomeIcon className='text-brand' icon={regular('chevron-left')} />
          </NavLink>
          <div>Insights dashboard</div>
        </div>
        {props.methodology === Methodology.Production && (
          <>
            {data?.analysis?.impacts && (
              <div className='justify-self-center w-72'>
                <SingleSelect
                  selectedId={props.selectedImpact.id}
                  options={[
                    ...[{ id: 'overall', name: 'Total Environmental Impact' }, ...data?.analysis.impacts].map((item) => ({
                      id: item.id,
                      name: item.name,
                      renderIcon: props.targets?.find((target) => target.impactId === item.id)?.target !== undefined,
                    })),
                  ]}
                  value={{
                    value: props.selectedImpact.id,
                    label: props.selectedImpact.name,
                  }}
                  setSelectedId={(v) => {
                    if (v === 'overall') {
                      props.setSelectedType(ImpactValueType.Points);
                    }
                    props.setSelectedImpact(
                      [{ id: 'overall', name: 'Total Environmental Impact' }, ...data?.analysis.impacts]!.find(
                        (impact) => impact.id === v,
                      )!,
                    );
                  }}
                />
              </div>
            )}
            {data?.analysis?.impacts && (
              <div className='flex flex-col justify-self-end w-full gap-y-1.5'>
                <div className='flex self-end items-center pl-2.5 pr-2 h-full rounded-lg bg-indigo-50'>
                  <FontAwesomeIcon className='text-brandDarkPurple2 text-xs' icon={light('flag-checkered')} />
                  <div className='flex gap-x-1 text-zinc-700 text-base ml-3'>
                    <div className='text-zinc-700 text-base'>{simplify(getTotalImpactValue().value)}</div>
                    <div className={cn('text-zinc-500 text-[10px]', { uppercase: props.selectedType === 'impactPoints' })}>
                      {getTotalImpactValue().unit}
                    </div>
                  </div>
                  <div className='uppercase tracking-uppercase text-zinc-500 text-[10px] ml-1'></div>
                </div>

                <div
                  className={cn('relative flex self-end items-center gap-2 pr-3', {
                    'opacity-50 pointer-events-none': props.selectedImpact.id === 'overall',
                  })}
                >
                  <input
                    disabled={props.selectedImpact.id === 'overall'}
                    value={props.selectedType}
                    checked={props.selectedType === 'physical'}
                    onChange={() => {
                      if (props.selectedImpact.id === 'overall') {
                        props.setSelectedImpact({ id: 'overall', name: 'Total Environmental Impact' });
                      } else {
                        props.setSelectedType(
                          props.selectedType === ImpactValueType.Physical ? ImpactValueType.Points : ImpactValueType.Physical,
                        );
                      }
                    }}
                    className='opacity-0 absolute h-3 w-6 aspect-square z-10 hover:cursor-pointer disabled:opacity-0'
                    type='checkbox'
                  />
                  <div
                    className={cn(
                      'border border-white px-0.5 outline flex items-center rounded-full relative h-3 w-6',
                      props.selectedType === 'physical' ? 'bg-brand' : 'bg-lightBg',
                    )}
                  >
                    <div
                      className={cn(
                        'absolute rounded-full h-2.5 aspect-square transition-[left] duration-25',
                        props.selectedType === 'physical' ? 'left-3 bg-white' : 'left-px bg-brand',
                      )}
                    />
                  </div>
                  <div className='text-zinc-600 text-sm'>Physical impact</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
