import { NavigationButtons } from './NavigationButtons';
import { Lens, ProductReport, ProductType } from '../../../../../../api';
import { GradeBadge } from '../../../../../../components/GradeBadge';
import { format } from 'date-fns';
import { simplify, roundToLong } from '../../../../shared';
import { BasicDetails } from '../../BasicDetails';

interface Props {
  data: ProductReport;
  type: Lens;
}

export const Summary = (props: Props) => {
  const impactValueWithPoints = (
    <div className='text-sm'>
      <span title={roundToLong(props.data.overallImpact)} className='font-semibold'>
        {simplify(props.data.overallImpact)}
      </span>{' '}
      weighted person.year OR{' '}
      <span title={roundToLong(props.data.impactPoints)} className='font-semibold'>
        {simplify(props.data.impactPoints)}
      </span>{' '}
      Impact points
    </div>
  );

  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>A brief overview of the product and results obtained</div>
        <NavigationButtons type='icons' next={{ path: '../details' }} />
      </div>
      <BasicDetails
        data={props.data.product}
        fields={[
          { name: 'Product name', value: props.data.product.name },
          {
            name: 'Product type',
            value: {
              [ProductType.Final]: 'Final (Sold to consumers)',
              [ProductType.Intermediate]: 'Intermediate (B2B)',
              [ProductType.Internal]: 'Internal (not for sale)',
            }[props.data.product.productType],
          },
          { name: 'Country of final production', value: props.data.locations.production.map(({ name }) => name).join(', ') },
          ...(() => {
            return props.data.product.productType === ProductType.Final
              ? [
                  {
                    name: 'Country of sale',
                    value: props.data.locations.sale.length > 0 ? props.data.locations.sale.map(({ name }) => name).join(', ') : '—',
                  },
                ]
              : [];
          })(),
          { name: 'Total environmental impact', value: impactValueWithPoints },
          ...(() => {
            return props.type === Lens.Consumer
              ? [
                  {
                    name: 'Consumer view rating',
                    value: (
                      <div className='p-1'>
                        <GradeBadge grade={props.data.consumerView.overallGrade!} />
                      </div>
                    ),
                  },
                ]
              : [];
          })(),
          { name: 'Report creator', value: props.data.metadata.author },
          { name: 'Date of report creation', value: format(new Date(props.data.product.createdAt!), 'dd/MM/yyyy hh:mm') },
          {
            name: 'Versioning',
            value: (
              <ul className='list-disc ml-3.5'>
                <li>Methodology v.{props.data.metadata.methodologyVersion}</li>
                <li>Web application v.{props.data.metadata.webAppVersion}</li>
              </ul>
            ),
          },
        ]}
      />
      <NavigationButtons next={{ path: '../details', label: 'Product Details' }} type='buttons' />
    </div>
  );
};
