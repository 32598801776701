import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { ForecastReport, ImpactTracking, ReportType, Targets } from '../../../../../api';
import { ContextualExampleTile } from '../../../../../components/ContextualExampleTile';
import { SingleSelect } from '../../../../../components/SingleSelect';
import { ViewToggle } from '../../../../../components/ViewToggle';
import { ImpactValueType } from '../../../Products/Report/Sku/Overview';
import { ImpactAnalysisResultSummary } from '../../components/ImpactAnalysisResultSummary';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { PefDashboardSummary } from '../../components/PefDashboardSummary';
import { WorstOffenders } from '../../components/WorstOffenders';
import { ProgressChart } from '../../../../../components/charts/ProgressTracking/ProgressChart';
import { ProgressChartLegend } from '../../../../../components/charts/ProgressTracking/ProgressChartLegend';
import { ProgressChartPlaceholder } from '../../../../../components/charts/ProgressTracking/ProgressChartPlaceholder';
import { useProgressChartEnabled } from '../../../../../hooks/useProgressChartEnabled';
import cn from 'classnames';
import { StickyHeader } from '../../../../../components/StickyHeader';
import { ImpactTargetHints } from '../../../../../components/ImpactTargetHints';
import { convert } from '../../../Prototype/sections/trackigDataConverterV2';

interface Props {
  data?: ForecastReport;
  targets?: Targets;
  warning: ReactNode;
}

export const Overview = (props: PropsWithChildren<Props>) => {
  const [selectedImpact, setSelectedImpact] = useState<ImpactTracking>(props.data!.tracking.impacts[0]);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);

  const [focused, setFocused] = useState(false);
  const [expanded, setExpanded] = useState(new Array<string>());

  const enabled = useProgressChartEnabled({
    isBaselineSet: props.data?.tracking.impacts[0].baseline !== undefined,
    data: props.data?.tracking.impacts.map((impact) => ({
      id: impact.id,
      reports: impact.reports,
    })),
    selectedImpact: selectedImpact,
  });

  const handleExpandedState = (item1: string) => {
    setExpanded((current) => (current.includes(item1) ? current.filter((item2) => item2 !== item1) : [...current, item1]));
  };

  useEffect(() => {
    if (!expanded.includes('Impact Analysis Results')) {
      setFocused(false);
    }

    return () => setFocused(false);
  }, [expanded]);

  const collapsible = (title: string, description: string, icon: ReactNode, children: ReactNode) => (
    <div className='print:mt-6'>
      <button
        onClick={() => handleExpandedState(title)}
        className='py-6 w-full flex justify-between items-center px-10 hover:cursor-pointer hover:bg-lightBg/75'
      >
        <div className='flex gap-4 items-center'>
          <div>{icon}</div>
          <div className='flex flex-col gap-2'>
            <div className='text-lg font-semibold'>{title}</div>
            <div>{description}</div>
          </div>
        </div>
        <FontAwesomeIcon
          className='text-xl print:hidden'
          icon={expanded.includes(title) ? solid('chevron-down') : solid('chevron-right')}
        />
      </button>
      <div className='print:hidden'>{expanded.includes(title) && children}</div>
      <div className='hidden print:block'>{children}</div>
    </div>
  );

  return props.data && props.targets ? (
    <div className='flex flex-col mb-20 px-6'>
      <Helmet title={props.data.name} />

      <StickyHeader className='-mx-6 px-6 bg-white'>
        <div className='flex items-center gap-x-2'>
          <NavLink
            to='/manufacturing/forecasts'
            className='flex items-center justify-center bg-[#E8EAF5] rounded-lg size-8 hover:bg-white hover:border-2 hover:border-brand'
          >
            <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
          </NavLink>
          <div className='text-xl font-semibold'>
            <div className='font-semibold text-dark truncate'>{props.data.name} Overview</div>
          </div>
        </div>
        <NavLink
          to='../summary'
          className='text-base font-semibold shadow-md active:scale-95 border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full'
        >
          See Full Report
        </NavLink>
      </StickyHeader>

      <div className='-mx-6 divide-y'>
        <PefDashboardSummary
          data={props.data}
          reportTimeframe={`1 ${props.data.cycle} cycle`}
          setFocused={setFocused}
          expanded={expanded}
          handleExpandedState={handleExpandedState}
          warning={props.warning}
        />

        {collapsible(
          'Baseline comparison',
          'How this hypothetical volume throughput would compare to your baseline impact and get you closer to your impact reduction targets ',
          <FontAwesomeIcon className='h-8 aspect-square' icon={duotone('bars-progress')} />,
          <div className='bg-lightBg p-6'>
            <div className='border-t flex flex-col gap-6 items-center p-6 rounded-2xl shadow-regular bg-white'>
              {enabled ? (
                <>
                  <div className='w-60'>
                    <SingleSelect
                      options={props.data.tracking.impacts.map((item) => ({
                        ...item,
                        disabled: false,
                        renderIcon: item.target !== undefined,
                      }))}
                      value={{
                        value: selectedImpact.id,
                        label: selectedImpact.name,
                      }}
                      setSelectedId={(v) => {
                        if (v === 'overall') {
                          setSelectedImpactUnit(ImpactValueType.Points);
                        }
                        setSelectedImpact(props.data!.tracking.impacts.find((impact) => impact.id === v)!);
                      }}
                      selectedId={selectedImpact.id}
                    />
                  </div>
                  <ViewToggle
                    theme='light'
                    button1={{
                      label: 'Final environmental impact',
                      active: selectedImpactUnit === ImpactValueType.Points,
                      disabled: selectedImpact.id === 'overall',
                    }}
                    button2={{
                      label: 'Physical Impact',
                      active: selectedImpactUnit === ImpactValueType.Physical,
                      disabled: selectedImpact.id === 'overall',
                    }}
                    toggleView={() => {
                      setSelectedImpactUnit((current) =>
                        current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                      );
                    }}
                  />
                  <ImpactTargetHints
                    reportType={ReportType.Forecast}
                    selectedUnit={selectedImpactUnit}
                    selectedImpact={selectedImpact}
                    targetData={convert(selectedImpact).getTargetData()}
                    targetHint={convert(selectedImpact).getTargetHint()}
                    targets={props.targets}
                  />
                  <div className='flex w-full gap-6 border rounded-lg p-6 pb-2'>
                    <div className='w-full h-64 text-xs ml-20'>
                      <ProgressChart
                        targets={props.targets}
                        selectedImpact={selectedImpact}
                        selectedType={selectedImpactUnit}
                        impacts={props.data.tracking.impacts}
                      />
                    </div>
                    <div className='text-sm leading-4'>
                      <ProgressChartLegend showForecast />
                    </div>
                  </div>
                </>
              ) : (
                <div className='flex flex-col gap-y-10'>
                  <div className='text-base flex items-center gap-x-4 bg-white p-4 rounded-lg border'>
                    <div className='size-10 bg-brandLime flex shrink-0 items-center justify-center rounded-full'>
                      <FontAwesomeIcon className='text-[18px] text-zinc-700' icon={light('lightbulb')} />
                    </div>
                    <div>
                      This graph shows progress throughout the years. In order to view it, the baseline needs to be created using one year
                      (either a calendar or tax year) as time frame not custom dates. Uploaded historical data should cover full months as
                      well.
                    </div>
                  </div>
                  <div className={cn('ml-24 mr-6 h-56', { 'pointer-events-none opacity-50': !enabled })}>
                    <ProgressChartPlaceholder />
                  </div>
                </div>
              )}
            </div>
          </div>,
        )}

        {collapsible(
          'Worst offenders',
          'Which products have the highest overall environmental impact across your entire volume output',
          <FontAwesomeIcon className='h-8 aspect-square' icon={duotone('light-emergency-on')} />,
          <WorstOffenders data={props.data} />,
        )}

        {collapsible(
          'Impact Analysis Results',
          'Your product’s impact by category, life cycle stage and process as well as the major contributors to each',
          <FontAwesomeIcon className='h-8 aspect-square' icon={duotone('spinner-third')} />,
          <ImpactAnalysisResultSummary data={props.data} focused={focused} setFocused={setFocused} type={ReportType.Forecast} />,
        )}
        {collapsible(
          'Physical Impact - Equivalence in real life',
          'What’s your impact, translated into relatable measures',
          <FontAwesomeIcon className='h-8 aspect-square' icon={duotone('leaf')} />,
          <div className='bg-lightBg border-b border-neutral-300/50 py-8 flex flex-col items-center gap-6 px-6'>
            <div className='flex flex-col gap-6'>
              <div className='px-6 text-xl font-semibold text-dark'>Physical Impacts Equivalent to:</div>
            </div>
            <div className='grid grid-cols-3 gap-6 print:block'>
              {props.data?.interpretation.contextualExamples.map((contextualExample, i) => (
                <ContextualExampleTile key={i} contextualExample={contextualExample} />
              ))}
            </div>
          </div>,
        )}
      </div>
    </div>
  ) : (
    <ManufacturingSkeletonLoader />
  );
};
