import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { HistoricalGhgReport } from '../../../../../../api';
import { ReportTabNavigation } from '../../../../../../components/ReportTabNavigation';
import { Summary } from './1_Summary';
import { Volumes } from './2_Volumes';
import { GoalAndScope } from './3_GoalAndScope';
import { Results } from './4_Results';
import { Appendix } from './5_Appendix';
import { StickyHeader } from '../../../../../../components/StickyHeader';

interface Props {
  data: HistoricalGhgReport;
}

export const Report = (props: Props) => {
  const tabs = [
    { path: 'summary', Component: Summary, label: 'Summary' },
    { path: 'volumes', Component: Volumes, label: 'Volumes' },
    { path: 'goal-and-scope', Component: GoalAndScope, label: 'Goal & Scope' },
    { path: 'results', Component: Results, label: 'Results' },
    { path: 'appendix', Component: Appendix, label: 'Appendix' },
  ];

  const location = useLocation();
  const tab = tabs.find((tab) => location.pathname.endsWith(`/${tab.path}`))!;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.scrollIntoView({ block: 'start' });
  }, [location.pathname]);

  return (
    <div ref={containerRef} className='flex flex-col px-6'>
      <Helmet title={`${props.data.name} - Scope 3 assessment`} />
      <StickyHeader>
        <div className='text-xl font-semibold'>{props.data.name} - Scope 3 assessment</div>
        <NavLink
          to='../overview'
          className='text-base font-semibold shadow-md active:scale-95 border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full print:hidden'
        >
          Return to overview
        </NavLink>
      </StickyHeader>

      <div className='py-6'>
        This Sustained Historical Volume report presents the results of the Greenhouse Gas (GHG) Protocol Scope 3 assessment carried out on
        the volumes of products ({props.data.totalUnitCount} units across {props.data.productCount} different products) manufactured over
        the timeframe selected ({format(new Date(props.data.startDate), 'dd/MM/yy')} - {format(new Date(props.data.endDate), 'dd/MM/yy')}).
        A summary, an overview of the volumes of each product produced, detailed impact results and interpretations are included in this
        report.
      </div>

      <ReportTabNavigation tabs={tabs} />

      <div className='hidden print:block'>
        {tabs.map(({ path, Component, label }) => (
          <div className='mb-10 break-after-page' key={path}>
            <div className='text-2xl mb-3 font-semibold'>{label}</div>
            {Component(props)}
          </div>
        ))}
      </div>

      <div key={tab.label} className='flex flex-col mb-20 print:hidden pt-6 text-zinc-500'>
        <Routes>
          {tabs.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component data={props.data} />} />
          ))}
        </Routes>
      </div>
    </div>
  );
};
