import { MultipleProductsGhgAnalysis } from '../../api';

export const fakeGhgData: MultipleProductsGhgAnalysis = {
  gasEmissions: [
    {
      id: 'ghg_gwp_co2',
      name: 'CO₂ emissions',
      totalEmission: {
        value: 7929.879373830882,
        unit: 'MT',
      },
    },
    {
      id: 'ghg_gwp_ch4',
      name: 'CH₄ emissions',
      totalEmission: {
        value: 440.83849233504117,
        unit: 'MT',
      },
    },
    {
      id: 'ghg_gwp_hfc',
      name: 'HFCs emissions',
      totalEmission: {
        value: 78.60090229544534,
        unit: 'MT CO₂e',
      },
    },
    {
      id: 'ghg_gwp_n2o',
      name: 'N2O emissions',
      totalEmission: {
        value: 9.792327973312931,
        unit: 'MT',
      },
    },
    {
      id: 'ghg_gwp_nf3',
      name: 'NF₃ emissions',
      totalEmission: {
        value: 2.7728275541105805e-13,
        unit: 'MT',
      },
    },
    {
      id: 'ghg_gwp_pfc',
      name: 'PFCs emissions',
      totalEmission: {
        value: 2.272117163099393,
        unit: 'MT CO₂e',
      },
    },
    {
      id: 'ghg_gwp_sf6',
      name: 'SF₆ emissions',
      totalEmission: {
        value: 0.00026281893896245705,
        unit: 'MT',
      },
    },
  ],
  categories: [
    {
      id: 'category_01',
      name: 'Purchased goods and services',
      totalEmission: {
        value: 21066.59936010686,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 391.21502831714486,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_02',
      name: 'Capital goods',
      totalEmission: {
        value: 17.612595436441318,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0.758423662140738,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_03',
      name: 'Fuel and energy related services',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
    {
      id: 'category_04',
      name: 'Upstream transportation and distribution',
      totalEmission: {
        value: 1712.1182917748638,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 12.371360879378443,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_05',
      name: 'Waste generated in operations',
      totalEmission: {
        value: 46.16473017752225,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 44.87046090131762,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_06',
      name: 'Business travel',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
    {
      id: 'category_07',
      name: 'Employee commuting',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
    {
      id: 'category_08',
      name: 'Upstream Leased Assets',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_09',
      name: 'Downstream transportation and distribution',
      totalEmission: {
        value: 10.802121165903138,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 1.3470228380362883,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_10',
      name: 'Processing of sold goods',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
    {
      id: 'category_11',
      name: 'Use of sold product',
      totalEmission: {
        value: 67.79202909507707,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 10.640683478858637,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_12',
      name: 'EOL treatment of sold products',
      totalEmission: {
        value: 68.98577566151737,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 35.23485377752873,
        unit: 'MT CO₂e',
      },
      isOutOfScope: false,
    },
    {
      id: 'category_13',
      name: 'Downstream leased assets',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
    {
      id: 'category_14',
      name: 'Franchises',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
    {
      id: 'category_15',
      name: 'Investments',
      totalEmission: {
        value: 0,
        unit: 'MT CO₂e',
      },
      totalBiogenic: {
        value: 0,
        unit: 'MT CO₂e',
      },
      isOutOfScope: true,
    },
  ],
  avoidedEmissions: {
    totalEmission: {
      value: 66.50726370037253,
      unit: 'MT CO₂e',
    },
    totalBiogenic: {
      value: 17.573046243005837,
      unit: 'MT CO₂e',
    },
  },
  totalEmission: {
    value: 30115.44104850691,
    unit: 'MT CO₂e',
  },
  scopeEmissions: [
    {
      id: 'scope_1',
      name: 'Scope 1',
      totalEmission: {
        value: 1714.5277563030047,
        unit: 'MT CO₂e',
        sharePercent: 5.69318,
      },
      totalBiogenic: {
        value: 508.60301683427286,
        unit: 'MT CO₂e',
      },
    },
    {
      id: 'scope_2',
      name: 'Scope 2',
      totalEmission: {
        value: 5410.838388785712,
        unit: 'MT CO₂e',
        sharePercent: 17.96699,
      },
      totalBiogenic: {
        value: 292.7832429903557,
        unit: 'MT CO₂e',
      },
    },
    {
      id: 'scope_3',
      name: 'Scope 3',
      totalEmission: {
        value: 22990.07490341819,
        unit: 'MT CO₂e',
        sharePercent: 76.33982,
      },
      totalBiogenic: {
        value: 496.4378338544053,
        unit: 'MT CO₂e',
      },
    },
  ],
  facilities: [],
  transportSuppliers: [],
};
