import isSameDay from 'date-fns/isSameDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { useEffect, useState } from 'react';

interface Props {
  isBaselineSet: boolean;
  data?: {
    id: string;
    reports: {
      start: {
        date: string;
      };
      end: {
        date: string;
      };
    }[];
  }[];
  selectedImpact?: {
    id: string;
  } | null;
}

export const useProgressChartEnabled = (props: Props) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (props.data && props.selectedImpact && props.isBaselineSet) {
      setEnabled(
        !props.data
          ?.find((impact) => impact.id === props.selectedImpact?.id)
          ?.reports.some(({ start, end }) => {
            return !(
              isSameDay(new Date(start.date), startOfMonth(new Date(start.date))) &&
              isSameDay(new Date(end.date), endOfMonth(new Date(end.date)))
            );
          }),
      );
    }
  }, [props.data, props.selectedImpact, props.isBaselineSet]);

  return enabled;
};
