import { Helmet } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ForecastReport, Targets } from '../../../../../api';
import { ReportTabNavigation } from '../../../../../components/ReportTabNavigation';
import { ForecastSummary } from './1_Summary';
import { Volumes } from './2_Volumes';
import { BaselineComparison } from './3_BaselineComparison';
import { ForecastLcia } from './4_Lcia';
import { ForecastInterpretation } from './5_Interpretation';
import { ForecastAppendix } from './6_Appendix';
import { StickyHeader } from '../../../../../components/StickyHeader';

interface Props {
  data: ForecastReport;
  targets: Targets;
}

const tabs = [
  { path: 'summary', Component: ForecastSummary, label: 'Summary' },
  { path: 'volumes', Component: Volumes, label: 'Volumes' },
  { path: 'baseline-comparison', Component: BaselineComparison, label: 'Baseline Comparison' },
  { path: 'lcia', Component: ForecastLcia, label: 'LCIA' },
  { path: 'interpretation', Component: ForecastInterpretation, label: 'Interpretation' },
  { path: 'appendix', Component: ForecastAppendix, label: 'Appendix' },
];

export const Main = (props: Props) => {
  const location = useLocation();
  const tab = tabs.find((tab) => location.pathname.endsWith(`/${tab.path}`))!;

  return (
    <div className='flex flex-col'>
      <Helmet title={`${props.data.name} Overview`} />
      <StickyHeader className='px-6'>
        <div className='text-xl font-semibold'>{props.data.name} Overview</div>
        <NavLink
          to='../overview'
          className='text-base font-semibold shadow-md active:scale-95 border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full'
        >
          Return to overview
        </NavLink>
      </StickyHeader>
      <div className='p-6'>
        This Sustained Volume Impact Forecast presents the details of the Life Cycle Analysis (LCA) carried out largely following the PEF
        framework on the forecasted production volumes{' '}
        <span>
          ({props.data.totalUnitCount} units {props.data.productCount} different products)
        </span>
        . This forecast is meant to provide a view of the total environmental impact of said volume output over the cycle length specified
        (a {props.data.cycle}). Summary, volume units overview, results, baseline comparison and interpretations sections are included.
      </div>

      <div className='mx-6'>
        <ReportTabNavigation tabs={tabs} />
      </div>

      <div className='hidden print:block'>
        {tabs.map(({ path, Component, label }, i) => (
          <div key={i} className='mb-10 break-after-page'>
            <div className='text-2xl mb-3 mx-6 font-semibold'>{label}</div>
            {Component(props)}
          </div>
        ))}
      </div>

      <div className='flex flex-col mb-20 print:hidden p-6 text-zinc-500'>
        <div key={tab.label} className='flex flex-col mb-20'>
          <Routes>
            {tabs.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component data={props.data} targets={props.targets} />} />
            ))}
          </Routes>
        </div>
      </div>
    </div>
  );
};
