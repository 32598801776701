import { forwardRef, SetStateAction } from 'react';
import { Lens, ProductReport } from '../../../../../../api';
import { PefOnePagerReport } from '../components/PefOnePagerReport';

interface Props {
  data: ProductReport;
  setData: (data: ProductReport | undefined) => void;
  setLoading: (loading: SetStateAction<boolean>) => void;
}

export const ConsumerLensOnePagerReport = forwardRef((props: Props, ref: any) => (
  <PefOnePagerReport ref={ref} data={props.data} setData={props.setData} setLoading={props.setLoading} lens={Lens.Consumer} />
));
