import { PropsWithChildren } from 'react';
import { ReportTabNavigation } from '../../../../components/ReportTabNavigation';
import { Route, Routes } from 'react-router';
import { GhgProductReport, ModellingReport, ProductReport } from '../../../../api';

interface Props {
  data?: ProductReport | GhgProductReport | ModellingReport;
  tabs: { path: string; Component: any; label: string; title?: string }[];
}

export const Report = (props: PropsWithChildren<Props>) => {
  if (!props.data) {
    return <></>;
  }

  return (
    <div className='flex flex-col -mt-10 print:m-6 mb-20'>
      {props.children}

      <div className='mx-6 '>
        <ReportTabNavigation tabs={props.tabs} />
      </div>

      <div className='hidden print:block'>
        {props.tabs.map(({ Component, label }, i) => (
          <div key={i} className='mb-10 break-after-page'>
            <div className='text-2xl mb-3 mx-6 font-semibold'>{label}</div>
            {Component(props)}
          </div>
        ))}
      </div>

      <div className='flex flex-col mb-20 print:hidden pt-6 text-zinc-500'>
        <Routes>
          {props.tabs.map(({ path, Component }, i) => (
            <Route key={i} path={path} element={<Component data={props.data} />} />
          ))}
        </Routes>
      </div>
    </div>
  );
};
