import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldProps, Formik, useFormikContext } from 'formik';
import { ForwardedRef, RefObject, SetStateAction, forwardRef, useImperativeHandle, useState } from 'react';
import { AgreementType, StaticEntity, Supplier, SupplierService, createSupplier, supplierServices } from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { ModalApi } from '../../../../components/ModalV3';
import { RadioInputs } from '../../../../components/RadioInputs';
import { SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { useLists } from '../../../../hooks/useLists';
import { useProfile } from '../../../../hooks/useProfile';
import { supplierValidationSchema } from './index';

export interface SubmitButtonApi {
  submit: () => void;
}

interface Props {
  name: string;
  requiredServices: SupplierService[];
  formRef?: RefObject<HTMLDivElement>;
  modalRef?: RefObject<ModalApi>;
  onCancel: () => void;
  onCreated: (newSupplier: Supplier) => void;
  formWide?: boolean;
  waiting?: boolean;
  setWaiting?: (v: SetStateAction<boolean>) => void;
}

export const NewSupplierForm = forwardRef((props: Props, ref: ForwardedRef<{ submit: () => void }> | undefined) => {
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const [waiting, setWaiting] = useState(props.waiting ?? false);

  return (
    <Formik<Supplier>
      initialValues={
        {
          name: props.name,
          services: props.requiredServices,
          controlledByCustomer: false,
        } as unknown as Supplier
      }
      validationSchema={supplierValidationSchema}
      validateOnBlur={triedSubmitting}
      validateOnChange={triedSubmitting}
      onSubmit={(values) => {
        setWaiting(true);
        props.setWaiting?.(true);
        createSupplier(values).ok((data) => {
          props.onCreated(data);
          setWaiting(false);
          props.setWaiting?.(false);
        });
      }}
    >
      <Content ref={ref} {...props} setTriedSubmitting={setTriedSubmitting} waiting={waiting} />
    </Formik>
  );
});

const Content = forwardRef(
  (
    props: Props & { setTriedSubmitting: (v: SetStateAction<boolean>) => void; waiting: boolean },
    ref: ForwardedRef<{ submit: () => void }>,
  ) => {
    const profile = useProfile();
    const formik = useFormikContext<Supplier>();
    const lists = useLists();

    useImperativeHandle(ref, () => ({
      submit: () => {
        props.setTriedSubmitting(true);
        formik.handleSubmit();
      },
    }));

    const isAgreementRequired = formik.values.services.includes(SupplierService.Transport) && !formik.values.controlledByCustomer;

    return (
      <div className='flex flex-col gap-4 col-span-2'>
        <div className={cn('grid gap-4', props.formWide ? 'grid-cols-3' : 'grid-cols-2')}>
          <div className='flex flex-col gap-2'>
            <div className='pl-1.5 font-semibold'>Provider name&#42;</div>
            <Field name='name'>{(model: FieldProps<string>) => <InputV3 autoFocus model={model} placeholder='Type…' />}</Field>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='pl-1.5 font-semibold'>Provider ID</div>
            <Field name='alias'>{(model: FieldProps<string>) => <InputV3 model={model} placeholder='Type…' />}</Field>
          </div>
          {props.formWide && <div />}
          <FieldArray
            name='services'
            render={(arrayModel) => (
              <Field name={arrayModel.name}>
                {(model: FieldProps<SupplierService[]>) => (
                  <div className='flex flex-col gap-2'>
                    <div className='pl-1.5 font-semibold'>Type of provider&#42;</div>
                    <SelectV3
                      multi
                      model={model}
                      options={supplierServices}
                      menuPortalTarget={props.formRef?.current}
                      onActions={{
                        add: (value) => model.form.setFieldValue(model.field.name, [...model.field.value, value.id]),
                        remove: (value) => {
                          if (value.id === SupplierService.Transport) {
                            formik.setFieldValue('agreement', undefined);
                          }
                          model.form.setFieldValue(
                            model.field.name,
                            model.field.value.filter((item) => item !== value.id),
                          );
                        },
                        clear: () => {},
                      }}
                      convertOptions={{
                        toModel: (values: { id: SupplierService; name: string }[]) => values.map(({ id }) => id),
                        fromModel: (values: SupplierService[]) =>
                          values ? values?.map((id) => ({ id, name: supplierServices.find((item) => item.id === id)?.name })) : [],
                      }}
                      isOptionDisabled={({ id }) => props.requiredServices.includes(id)}
                    />
                  </div>
                )}
              </Field>
            )}
          />
          <Field name='controlledByCustomer'>
            {(model: FieldProps) => (
              <div className='flex flex-col gap-2 pl-1.5 font-semibold'>
                Controlled by {profile.selectedWorkspace.name}&#42;
                <div className='relative flex items-center gap-4 mt-1.5'>
                  <input
                    {...model.field}
                    onChange={(event) => {
                      model.field.onChange(event);
                      if (event.target.value) {
                        formik.setFieldValue('agreement', undefined);
                      }
                    }}
                    className='opacity-0 absolute h-3 w-6 aspect-square z-10 hover:cursor-pointer disabled:opacity-0'
                    type='checkbox'
                  />
                  <div
                    className={cn('border border-white px-0.5 outline flex items-center rounded-full relative h-3 w-6', {
                      'bg-brand': model.field.value,
                      'bg-lightBg': !model.field.value,
                    })}
                  >
                    <div
                      className={cn('absolute rounded-full h-2.5 aspect-square transition-[left] duration-25', {
                        'left-3 bg-white': model.field.value,
                        'left-px bg-brand': !model.field.value,
                      })}
                    />
                  </div>
                  <div>{model.field.value ? 'Yes' : 'No'}</div>
                </div>
              </div>
            )}
          </Field>

          {isAgreementRequired && (
            <div className='flex flex-col gap-2 pl-1.5'>
              <div className='flex items-center gap-x-1 font-semibold'>
                Agreement&#42;{' '}
                <TooltipV3
                  content={
                    <div className='bg-[#330099] rounded-lg shadow px-2 py-1 text-white text-xs'>
                      Specify if you have a formal contractual agreement directly with the facility for products transitioning through it
                      (‘Direct contract’ option) or if you’re not explicitly paying for the services in question (‘Indirect agreement’).
                      This will affect which scope 3 sub-category to attribute those emissions to.
                    </div>
                  }
                >
                  <div className='flex'>
                    <FontAwesomeIcon icon={light('info-circle')} />
                  </div>
                </TooltipV3>
              </div>
              <div className='flex flex-col gap-2'>
                <Field name='agreement'>
                  {(model: FieldProps<StaticEntity<AgreementType>>) => <RadioInputs model={model} options={lists.agreements} />}
                </Field>
              </div>
            </div>
          )}
        </div>

        {!ref && (
          <div className='flex justify-between font-semibold border-t border-zinc-300 pt-6'>
            <button
              type='button'
              onClick={props.onCancel}
              className='flex border bg-white border-brandDarkPurple2 text-brandDarkPurple2 rounded-md px-3 py-1.5'
            >
              Cancel
            </button>
            <button
              type='button'
              disabled={props.waiting}
              onClick={() => {
                props.setTriedSubmitting(true);
                formik.handleSubmit();
              }}
              className={cn('flex bg-lightBg2 text-brandDarkPurple2 rounded-md px-3 py-1.5', { 'cursor-wait': props.waiting })}
            >
              Create provider
            </button>
          </div>
        )}
      </div>
    );
  },
);
