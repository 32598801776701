import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { ReactNode } from 'react';

interface Props {
  tabs: { path: string; Component: ReactNode; label: string }[];
}

export const ReportTabNavigation = (props: Props) => {
  return (
    <div className='print:hidden flex pt-3'>
      {props.tabs.map((tab) => (
        <NavLink
          key={tab.path}
          to={tab.path}
          className={(props) => cn('flex flex-col gap-2 items-center transition hover:text-inherit', { 'text-gray-500': !props.isActive })}
        >
          {(props) => (
            <>
              <div className='px-6 text-lg font-semibold whitespace-nowrap'>{tab.label}</div>
              <div className='flex w-full items-end h-1.5'>
                <div
                  className={cn('flex justify-end w-full', {
                    'bg-brand h-[3px]': props.isActive,
                    'bg-gray-200 h-px': !props.isActive,
                  })}
                />
              </div>
            </>
          )}
        </NavLink>
      ))}
      <div className='h-px bg-gray-200 w-full flex self-end' />
    </div>
  );
};
