import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingReportCreationMethod } from '../../../types';
import { getBaselinePreview, getManufacturingHistoricalReports } from '../../../../../../api';
import { useApiQuery } from '../../../../../../hooks/useApiQuery';
import { TooltipV3 } from '../../../../../../components/TooltipV3';

interface Props {
  selectedMethod: ManufacturingReportCreationMethod | undefined;
  setSelectedMethod: (value: ManufacturingReportCreationMethod) => void;
  onNext: () => void;
}

export const Step1 = (props: Props) => {
  const isBaselineSet = useApiQuery(getBaselinePreview());
  const isHistoricalReportsExist = useApiQuery(getManufacturingHistoricalReports({ pageSize: 1, pageToken: '' }));
  const notEnoughData =
    !isBaselineSet.data ||
    !isHistoricalReportsExist.data ||
    !!isBaselineSet.data?.errorCode ||
    (isHistoricalReportsExist.data ? isHistoricalReportsExist.data.reports.length === 0 : false);

  return (
    <div className='flex flex-col gap-y-2'>
      <div className='font-semibold text-lg'>How would you like to enter products and volumes?</div>
      <div>
        Choose if you want to start from scratch or use one of the reports that you previously created to pre-populate products and
        quantities for the time period of production. All options allow you to edit values before creating the forecast report.
      </div>

      <div className='grid grid-cols-3 gap-x-4 my-4'>
        {[
          {
            disabled: notEnoughData,
            type: ManufacturingReportCreationMethod.FromReport,
            title: 'Start from previous report',
            description:
              'Select one of the previous historical reports or your baseline to pre-populate timeframe, products and volumes of production.',
          },
          {
            disabled: false,
            type: ManufacturingReportCreationMethod.Manual,
            title: 'Enter manually',
            description:
              'Choose manually all products that you expect to produce over the selected timeframe and assign a quantity to each.',
          },
          {
            disabled: false,
            type: ManufacturingReportCreationMethod.Import,
            title: 'Import CSV file',
            description: 'Upload a CSV file with products, product IDs and volumes.',
          },
        ].map(({ title, description, type, disabled }, index) => (
          <div className='flex flex-col' key={index}>
            <TooltipV3
              placement='top'
              disabled={!disabled}
              content={
                <div className='px-2 py-1 bg-violet-950 text-white w-56 text-xs border rounded-lg shadow'>
                  You need to have your baseline or some historical reports to use this option.
                </div>
              }
            >
              <button
                disabled={disabled}
                onClick={() => props.setSelectedMethod(type)}
                className={cn('flex flex-col gap-y-4 h-full p-4 rounded-lg border [&:not(:disabled)]:hover:bg-slate-50', {
                  'bg-slate-50 outline outline-brand': props.selectedMethod === type,
                  'text-zinc-400 cursor-not-allowed': disabled,
                })}
              >
                <div className='font-semibold'>{title}</div>
                <div className='text-sm'>{description}</div>
              </button>
            </TooltipV3>
          </div>
        ))}
      </div>

      <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
        <div className='px-12 py-4 flex justify-end w-full max-w-screen-xl'>
          <button
            type='button'
            disabled={!props.selectedMethod}
            className={cn(
              'flex self-end text-sm items-center gap-2.5 border-2 rounded-full px-4 py-2 font-semibold',
              '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
              'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
            )}
            onClick={props.onNext}
          >
            Next
            <FontAwesomeIcon className='text-base' icon={solid('chevron-right')} />
          </button>
        </div>
      </div>
    </div>
  );
};
