import { useState } from 'react';
import { GradeBadge } from '../../../../../../components/GradeBadge';
import { ModalV3 } from '../../../../../../components/ModalV3';
import { ByImpactContributionTable } from '../../../../../../components/ByImpactContributionTable';
import { BasicProductDetailsTile } from '../components/BasicProductDetailsTile';
import { ProductSkeletonLoader } from '../components/ProductSkeletonLoader';
import { Overview } from '../Overview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getProductReportCsv, ImpactId, Lens, ProductReport } from '../../../../../../api';
import { getImpactIcon } from '../../../../../../icons/Impact';
import { simplify, roundToLong } from '../../../../shared';
import { format } from 'date-fns';

interface Props {
  data?: ProductReport;
  lens: Lens;
  setSelectedReportType: (value: Lens) => void;
  isFoundationEarthWorkspace: boolean;
}

export const ConsumerOverview = (props: Props) => {
  const [exporting, setExporting] = useState(false);

  const exportImpactByCategoryAsCsvButton = (lens: Lens) => (
    <button
      type='button'
      disabled={exporting}
      className='print:hidden flex gap-2 items-center px-4 text-brandDarkPurple2 font-semibold disabled:cursor-wait text-base'
      onClick={() => {
        setExporting(true);
        getProductReportCsv(
          props.data!.product.id,
          `${props.data!.product.name} - ${props.data!.product.skuId} - Impact by category (${format(new Date(), 'dd.MM.yy')})`,
          lens,
        ).call({
          ok: () => setExporting(false),
          fail: () => setExporting(false),
        });
      }}
    >
      <FontAwesomeIcon icon={solid('download')} />
      Export as CSV
    </button>
  );

  if (!props.data) {
    return <ProductSkeletonLoader lens={props.lens} />;
  }

  return (
    <Overview
      lens={props.lens}
      setSelectedReportType={props.setSelectedReportType}
      isFoundationEarthWorkspace={props.isFoundationEarthWorkspace}
      data={props.data}
    >
      <div className='grid grid-cols-3 gap-x-4 mt-6'>
        <BasicProductDetailsTile selectedReportType={Lens.Consumer} data={props.data} />
        <div className='flex flex-col gap-y-4 col-span-2'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 bg-slate-50'>
              <div className='font-semibold text-lg'>Average impact</div>
              <div className='flex items-center gap-x-2' title={roundToLong(props.data.impactPoints)}>
                <div className='text-[32px]'>{simplify(props.data.impactPoints)}</div>
                <div className='uppercase text-zinc-500 text-xs'>impact points</div>
              </div>
            </div>
            <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 bg-slate-50'>
              <div className='font-semibold text-lg'>Consumer rating</div>
              <GradeBadge grade={props.data.consumerView.overallGrade} />
            </div>
          </div>

          <div className='flex flex-col gap-6 col-span-2 border rounded-xl p-4'>
            <div className='flex justify-between items-center'>
              <div className='text-lg font-semibold pl-3'>Physical Impacts</div>
              <ModalV3
                size='wide+'
                hideConfirm={props.data.firstPartyDataPercentage < 100}
                confirmLabel='View full report'
                onConfirm={() => window.open(`/products/${props.data!.product.id}/report/${props.lens}/summary`, '_blank')}
                title={
                  <div className='flex justify-between items-center'>
                    <div className='flex flex-col gap-3'>
                      <div>Consumer lens physical impacts</div>
                      <div className='text-lg'>{props.data.product.name}</div>
                      <div className='flex items-center gap-1 uppercase text-xs'>
                        <div className='font-normal'>functional unit:</div>
                        <div className='font-semibold'>1kg of consumed product</div>
                      </div>
                    </div>
                    {exportImpactByCategoryAsCsvButton(Lens.Consumer)}
                  </div>
                }
                body={
                  <div className='flex flex-col gap-6 -mt-6 text-base'>
                    <div className='h-full overflow-y-scroll'>
                      <ByImpactContributionTable
                        data={props.data.analysis.impactStagesMatrix.map((impact) => ({
                          ...impact,
                          contributionPercent: impact.absSharePercent,
                          id: impact.impactId,
                          name: impact.impactName,
                        }))}
                        totalImpact={props.data.overallImpact}
                        totalPoints={props.data.impactPoints}
                      />
                    </div>
                  </div>
                }
              >
                <button>
                  <FontAwesomeIcon
                    className='hover:text-brand cursor-pointer bg-white rounded-md border p-2 active:scale-95'
                    icon={regular('arrow-up-right-and-arrow-down-left-from-center')}
                  />
                </button>
              </ModalV3>
            </div>
            <div className='grid grid-cols-3 gap-3'>
              {props.data.analysis.impactStagesMatrix
                .filter((impact) => ['pef_climate_change', 'pef_land_use', 'pef_water_scarcity'].includes(impact.impactId))
                .map((impact) => (
                  <div key={impact.impactId} className='p-6 w-full bg-slate-50 rounded-2xl'>
                    <div className='flex flex-col gap-y-3 h-full justify-between'>
                      <div className='mx-auto size-12 p-1 rounded-full bg-indigo-50 text-[#330099]'>
                        {getImpactIcon(
                          {
                            [ImpactId.PefClimateChange]: 'impact-climate-change',
                            [ImpactId.PefLandUse]: 'impact-land-use',
                            [ImpactId.PefWaterScarcity]: 'impact-water-use',
                          }[impact.impactId as string]!,
                        )}
                      </div>
                      <div title={impact.impactName} className=' mt-2 text-center line-clamp-2'>
                        {impact.impactName}
                      </div>
                      <div
                        title={`${roundToLong(impact.physicalValue)} ${impact.unit}`}
                        className='flex items-center justify-center gap-x-2'
                      >
                        <div className='text-[32px] whitespace-nowrap'>{simplify(impact.physicalValue)}</div>
                        <div className='text-sm truncate'>{impact.unit}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Overview>
  );
};
