import { Field, FieldProps, useFormikContext } from 'formik';
import { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { ProductModelV3 } from '../../../../api';
import { InputV3 } from '../../../../components/InputV3';
import { ModalForm } from '../../../../components/ModalForm';

type Props = PropsWithChildren<{
  onSave: (title: string) => void;
}>;

export const RenameModal = (props: Props) => {
  const formik = useFormikContext<ProductModelV3>();
  return (
    <ModalForm
      size='narrow-'
      title='Rename model'
      saveLabel='Rename'
      body={
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>New model name</div>
          <Field name='title'>{(model: FieldProps) => <InputV3 model={model} />}</Field>
        </div>
      }
      data={formik.values}
      validationSchema={yup.object({
        title: yup.string().required(),
      })}
      onSave={({ values, closeModal }) => {
        props.onSave(values.title);
        closeModal();
      }}
    >
      {props.children}
    </ModalForm>
  );
};
