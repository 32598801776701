import { ReportType } from '../../../../../../../api';
import { SelectProducts } from '../../../../components/Lists/SelectProducts';

interface Props {
  step: number;
  onNext: () => void;
}

export const Step2 = (props: Props) => (
  <>
    <div className='flex flex-col gap-y-2'>
      <div className='font-semibold text-lg'>Select all products you foresee producing</div>
      <div>
        Adding all product and/or models you may want to produce over the selected timeframe will allow you to understand your potential
        volume impact at scale.
      </div>
    </div>
    <SelectProducts onNext={props.onNext} reportType={ReportType.Forecast} />
  </>
);
