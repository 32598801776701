import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Amount } from '../api';
import { simplify, roundToLong } from '../Root/Main/shared';

interface Props {
  data: {
    title: string;
    product: {
      id: string;
      parentId?: string;
      imageUrl: string;
      name: string;
      amount: Amount;
      overallImpact: number;
      impactPoints?: number;
      count?: number;
    };
  };
  arrowDirection: 'up' | 'down';
  bgWhite?: boolean;
}

export const ProductTile = (props: Props) => (
  <NavLink
    to={
      props.data.product.parentId
        ? `/products/${props.data.product.parentId}/models/${props.data.product.id}/report/overview`
        : `/products/${props.data.product.id}/report/overview`
    }
    target='_blank'
    className={cn('h-full w-full py-6 px-8 border border-lightBgBorder2 hover:border-brand rounded-2xl shadow-md', {
      'bg-white': props.bgWhite,
      'bg-lightBg': !props.bgWhite,
    })}
  >
    <div className='flex flex-col gap-4 justify-evenly h-full'>
      <div className='text-dark text-xl font-semibold flex items-center gap-3'>
        <FontAwesomeIcon icon={props.arrowDirection === 'up' ? regular('arrow-up') : regular('arrow-down')} />
        <div>{props.data.title}</div>
      </div>
      <div className='flex gap-4 items-center'>
        {props.data.product.imageUrl !== '' ? (
          <img className='h-16 max-w-[115px] rounded-xl' src={props.data.product.imageUrl} alt={props.data.product.name} />
        ) : (
          <div className='flex justify-center items-center bg-neutral-100 border border-neutral-200 h-16 aspect-square rounded-xl'>
            <FontAwesomeIcon className='text-3xl text-neutral-300 flex self-center' icon={solid('box-circle-check')} />
          </div>
        )}
        <div className='flex flex-col gap-4 truncate'>
          <div className='flex items-center gap-2 font-semibold'>
            <div className='flex truncate'>
              <div title={props.data.product.name} className='truncate text-right'>
                {props.data.product.name}
              </div>
              <div>
                ({props.data.product.amount.value}
                {props.data.product.amount.unit.name})
              </div>
            </div>
            {props.data.product.count && (
              <div className='flex gap-0.5 items-center text-brandDark'>
                <FontAwesomeIcon className='h-2.5 aspect-square' icon={solid('xmark-large')} />
                <div>{props.data.product.count}</div>
                <div>units</div>
              </div>
            )}
          </div>
          {props.data.product.impactPoints && (
            <div className='flex w-auto items-end gap-2'>
              <div className='text-2xl text-brandDark font-semibold' title={roundToLong(props.data.product.impactPoints)}>
                {simplify(props.data.product.impactPoints)}
              </div>
              <div className='text-lg'>Impact points</div>
            </div>
          )}
        </div>
      </div>
    </div>
  </NavLink>
);
