import { PropsWithChildren, ReactNode } from 'react';
import { Top3ImpactsAndContributors } from './Top3ImpactsAndContributors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingPefReport } from '../../../../api';
import { ManufacturingImprovementTipsV3 } from '../../../../components/ManufacturingImprovementTipsV3';
import { simplify, roundToLong } from '../../shared';

interface Props {
  data: ManufacturingPefReport;
  setFocused: (focused: boolean) => void;
  expanded: string[];
  handleExpandedState: (value: string) => void;
  reportTimeframe: string;
  warning?: ReactNode;
}

export const PefDashboardSummary = (props: PropsWithChildren<Props>) => {
  return (
    <div className='flex flex-col gap-y-6 bg-slate-50 print:bg-transparent p-6 pt-8'>
      {props.warning}
      <div className='flex items-stretch gap-x-6'>
        <div className='flex flex-col gap-y-4 py-4 px-5 rounded-2xl border bg-white'>
          <div className='font-semibold text-lg'>Total impact</div>
          <div className='flex items-center gap-x-2'>
            <div title={roundToLong(props.data.impactPoints)} className='text-3xl font-semibold'>
              {simplify(props.data.impactPoints)}
            </div>
            <div className='text-zinc-500 uppercase text-sm'>impact points</div>
          </div>
        </div>

        <div className='flex flex-col justify-between gap-y-4 py-4 px-5 rounded-2xl border bg-white'>
          <div className='flex items-center justify-between gap-x-10'>
            <div className='flex items-center gap-x-2'>
              <div className='size-8 flex items-center justify-center bg-indigo-50 rounded-lg'>
                <FontAwesomeIcon icon={light('flag-checkered')} />
              </div>
              <div className='font-semibold text-sm'>{props.reportTimeframe}</div>
            </div>

            {props.children}
          </div>

          <div className='flex gap-x-6 items-center'>
            <div>{props.data.productCount} products</div>
            <div className='h-full w-[1px] bg-zinc-300' />
            <div>{props.data.totalUnitCount} units</div>
          </div>
        </div>
      </div>

      <Top3ImpactsAndContributors
        totalImpactPoints={props.data.impactPoints}
        impactStageMatrix={props.data.analysis.impactStageMatrix}
        lifecycleStageImpacts={props.data.analysis.lifecycleStageImpacts}
      />

      <ManufacturingImprovementTipsV3
        data={props.data.improvementTips}
        setFocused={props.setFocused}
        expanded={props.expanded}
        handleExpandedState={props.handleExpandedState}
      />

      <div className='flex self-end text-sm text-zinc-500'>Methodology Version: V.{props.data.metadata.methodologyVersion}</div>
    </div>
  );
};
