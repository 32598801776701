import { format } from 'date-fns';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { HistoricalPefReport, Targets } from '../../../../../../api';
import { ReportTabNavigation } from '../../../../../../components/ReportTabNavigation';
import { simplify } from '../../../../shared';
import { HistoricalSummary } from './1_Summary';
import { Volumes } from './2_Volumes';
import { ProgressTracking } from './3_ProgressTracking';
import { HistoricalLcia } from './4_Lcia';
import { HistoricalInterpretation } from './5_Interpretation';
import { HistoricalAppendix } from './6_Appendix';
import { StickyHeader } from '../../../../../../components/StickyHeader';

interface Props {
  data: HistoricalPefReport;
  targets: Targets;
}

const tabs = [
  {
    path: 'summary',
    Component: HistoricalSummary,
    label: 'Summary',
  },
  {
    path: 'volumes',
    Component: Volumes,
    label: 'Volumes',
  },
  {
    path: 'progress-tracking',
    Component: ProgressTracking,
    label: 'Progress Tracking',
  },
  {
    path: 'lcia',
    Component: HistoricalLcia,
    label: 'LCIA',
  },
  {
    path: 'interpretation',
    Component: HistoricalInterpretation,
    label: 'Interpretation',
  },
  {
    path: 'appendix',
    Component: HistoricalAppendix,
    label: 'Appendix',
  },
];

export const Report = (props: Props) => {
  const location = useLocation();
  const tab = tabs.find((tab) => location.pathname.endsWith(`/${tab.path}`))!;
  const containerRef = useRef<HTMLDivElement>(null);

  const title = `${props.data.name} - Full Report`;
  const reportTimeframe = format(new Date(props.data.startDate), 'dd/MM/yy') + ' - ' + format(new Date(props.data.endDate), 'dd/MM/yy');
  const manufacturedUnits = `${simplify(props.data.totalUnitCount)} units across ${props.data.productCount} different products`;

  useEffect(() => {
    containerRef.current?.scrollIntoView({ block: 'start' });
  }, [location.pathname]);

  return (
    <div ref={containerRef} className='flex flex-col px-6'>
      <Helmet title={title} />
      <StickyHeader className='-mx-6 px-6'>
        <div className='text-xl font-semibold'>{title}</div>
        <NavLink
          to='overview'
          className='text-base font-semibold shadow-md active:scale-95 border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full print:hidden'
        >
          Return to overview
        </NavLink>
      </StickyHeader>

      <div className='py-6'>
        This Sustained Historical Volume report presents the details of the Life Cycle Analysis (LCA) carried out largely following the PEF
        framework on the volumes of products ({manufacturedUnits}) manufactured over the chosen timeframe ({reportTimeframe}). A summary, an
        overview of the volumes of each product produced, detailed impact results and interpretations are included in this report.
      </div>

      <ReportTabNavigation tabs={tabs} />

      <div className='hidden print:block'>
        {tabs.map(({ path, Component, label }) => (
          <div className='mb-10 break-after-page' key={path}>
            <div className='text-2xl mb-3 font-semibold'>{label}</div>
            {Component(props)}
          </div>
        ))}
      </div>

      <div key={tab.label} className='flex flex-col mb-20 print:hidden pt-6 text-zinc-500'>
        <Routes>
          {tabs.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component data={props.data} targets={props.targets} />} />
          ))}
        </Routes>
      </div>
    </div>
  );
};
