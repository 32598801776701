import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { thin, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router';
import cn from 'classnames';

interface Props {
  type: 'icons' | 'buttons';
  back?: { path: string; label?: string };
  next?: { path: string; label?: string };
}

export const NavigationButtons = (props: Props) => {
  const navigate = useNavigate();

  return props.type === 'icons' ? (
    <div className='flex justify-center items-center gap-6 h-full print:hidden'>
      {[
        {
          icon: <FontAwesomeIcon className='h-9 aspect-square' icon={thin('circle-chevron-left')} />,
          disabled: !props.back,
          onClick: () => props.back && navigate(props.back.path),
        },
        {
          icon: <FontAwesomeIcon className='h-9 aspect-square' icon={thin('circle-chevron-right')} />,
          disabled: !props.next,
          onClick: () => props.next && navigate(props.next.path),
        },
      ].map((button, i) => (
        <button
          key={i}
          className={cn('text-brand h-9 aspect-square rounded-full active:scale-95', {
            'text-gray-400 pointer-events-none': button.disabled,
          })}
          disabled={button.disabled}
          onClick={button.onClick}
        >
          {button.icon}
        </button>
      ))}
    </div>
  ) : (
    <div className={cn('flex justify-between items-center mt-2.5 print:hidden')}>
      <div>
        {props.back && (
          <button
            type='button'
            className={cn(
              'relative flex items-center justify-center self-end gap-2 pr-4 pl-7',
              'border-2 border-brand rounded-full py-1 text-brand font-semibold active:scale-95 print:hidden',
            )}
            onClick={() => {
              if (props.back) {
                navigate(props.back.path);
                window.scrollTo(0, 0);
              }
            }}
          >
            <FontAwesomeIcon className='absolute left-3 text-xs' icon={solid('chevron-left')} />
            <div className='flex justify-self-center self-center'>{props.back.label}</div>
          </button>
        )}
      </div>
      <div>
        {props.next && (
          <button
            type='button'
            className={cn(
              'relative flex items-center justify-center self-end gap-2 pl-4 pr-7',
              'border-2 border-brand rounded-full py-1 text-brand font-semibold active:scale-95 print:hidden',
            )}
            onClick={() => {
              if (props.next) {
                navigate(props.next.path);
                window.scrollTo(0, 0);
              }
            }}
          >
            <div className='flex justify-self-center self-center'>{props.next.label}</div>
            <FontAwesomeIcon className='absolute right-3 text-xs' icon={solid('chevron-right')} />
          </button>
        )}
      </div>
    </div>
  );
};
