import {
  flip,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Form, Formik, FormikErrors } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneDeep } from 'lodash';
import {
  cloneElement,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  HTMLProps,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useImperativeHandle,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Metadata } from '../api';

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null,
  ): (props: PropsWithoutRef<P> & RefAttributes<T>) => ReactElement | null;
}

export interface ModalFormMetadata {
  metadata?: Metadata;
}

export type ModalFormSaveParams<FV extends object, EF = {}> = {
  values: FV;
  metadata?: Metadata;
  closeModal: () => void;
} & EF;

export type ModalFormSaveCallback<FV extends object, EF = {}> = (params: ModalFormSaveParams<FV, EF>) => void;

export interface ModalFormApi {
  open: () => void;
  close: () => void;
}

type Props<FV extends object> = PropsWithChildren<{
  formRef?: RefObject<HTMLDivElement>;
  title: ReactNode;
  body: ReactNode;
  waiting?: boolean;
  emptyData?: FV;
  data?: FV;
  metadata?: Metadata;
  validationSchema: any;
  entityName?: string;
  saveLabel?: string;
  onSave: ModalFormSaveCallback<FV>;
  hideSave?: boolean;
  headerRight?: ReactNode;
  instructions?: ReactNode;
  getCustomErrors?: (errors: FormikErrors<FV>) => { message: string; expected: string; actual?: string | object }[];
  onOpenChange?: (open: boolean) => void;
  size?: 'narrow-' | 'narrow' | 'wide' | 'wide+';
}>;

const ModalFormComponent = <FV extends object>(props: Props<FV>, ref: ForwardedRef<ModalFormApi>) => {
  const [open, setOpen] = useState(false);

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);

    if (props.onOpenChange) {
      props.onOpenChange(newOpen);
    }
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenAndNotify(true);
    },
    close: () => {
      setOpenAndNotify(false);
    },
  }));

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, { outsidePointerDown: false }),
  ]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && <Content {...props} floating={floating} getFloatingProps={getFloatingProps} setOpen={context.onOpenChange} />}
      </FloatingPortal>
    </>
  );
};

export const ModalForm = forwardRef(ModalFormComponent);

const Content = <FV extends object>(
  props: Props<FV> & {
    floating: (node: HTMLElement | null) => void;
    getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
    setOpen: (open: boolean) => void;
  },
) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const initialValues = (props.data ?? props.emptyData!) as FV & ModalFormMetadata;
  initialValues.metadata = props.metadata;

  // to ensure header and footer heights are set to CSS vars
  useLayoutEffect(forceUpdate, [forceUpdate]);

  return (
    <FloatingOverlay
      lockScroll
      className={cn('flex justify-center items-center bg-neutral-400/75 z-[70]', {
        invisible: !headerRef.current || !footerRef.current,
      })}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={props.validationSchema}
        validateOnBlur={triedSubmitting}
        validateOnChange={triedSubmitting}
        onSubmit={(submittedValues) => {
          const values = cloneDeep(submittedValues);
          const metadata = values.metadata;
          delete values.metadata;
          props.onSave({ values, metadata, closeModal: () => props.setOpen(false) });
        }}
      >
        {(formik) => (
          <Form
            {...props.getFloatingProps({
              ref: props.floating,
            })}
            className={cn(
              'antialiased text-sm text-body m-8 bg-white shadow-2xl border rounded-xl w-full',
              props.size
                ? {
                    'narrow-': 'max-w-lg',
                    narrow: 'max-w-xl',
                    wide: 'max-w-4xl',
                    'wide+': 'max-w-6xl',
                  }[props.size]
                : 'max-w-2xl',
            )}
          >
            <div ref={props.formRef} className='flex flex-col'>
              <div ref={headerRef} className='mx-6 py-6 flex items-center justify-between gap-4 text-neutral-900 border-b border-zinc-300'>
                <div className='font-semibold text-xl text-neutral-900 truncate'>{props.title}</div>
                <div className='flex gap-4'>
                  {props.headerRight}
                  {props.instructions && <InstructionsTooltip instructions={props.instructions} />}
                </div>
              </div>
              <div
                style={
                  {
                    '--header-height': `${headerRef.current ? headerRef.current.getBoundingClientRect().height : 0}px`,
                    '--footer-height': `${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}px`,
                  } as CSSProperties
                }
                className={cn(
                  'px-6 py-8 overflow-y-auto',
                  'max-h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height)_-_var(--footer-height))]',
                )}
              >
                {props.body}
              </div>
              <div ref={footerRef} className='mx-6 py-6 flex justify-between border-t border-zinc-300'>
                <button
                  type='button'
                  className={cn(
                    'w-28 flex justify-center border-2 border-brand rounded-full px-4 py-1 text-brand font-semibold',
                    'active:scale-95',
                  )}
                  onClick={() => props.setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  disabled={!formik.isValid || formik.isValidating || formik.isSubmitting}
                  className={cn(
                    'min-w-28 flex justify-center border-2 border-transparent bg-brand rounded-full px-4 py-1 text-white font-semibold',
                    '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300',
                    {
                      invisible: props.hideSave,
                      'disabled:cursor-wait': formik.isValidating || formik.isSubmitting,
                    },
                  )}
                  onClick={() => setTriedSubmitting(true)}
                >
                  {(() => {
                    if (props.data) {
                      return props.saveLabel ?? `Save${props.entityName ? ` ${props.entityName}` : ''}`;
                    }

                    return `Add${props.entityName ? ` ${props.entityName}` : ''}`;
                  })()}
                </button>
              </div>
            </div>
            {(() => {
              const list =
                props.getCustomErrors && props.getCustomErrors(formik.errors).filter(({ actual, expected }) => actual === expected);
              return (
                list &&
                list.length > 0 && (
                  <div className='relative'>
                    <div
                      style={
                        {
                          '--modal-width': props.formRef?.current ? `${props.formRef.current.getBoundingClientRect().width}px` : 0,
                        } as CSSProperties
                      }
                      className={cn(
                        'absolute bottom-0 -right-4 translate-x-full flex flex-col items-start gap-1',
                        'w-[calc((100vw_-_(var(--modal-width)_+_theme(spacing.4)))/2_-_theme(spacing.5))]',
                      )}
                    >
                      {list.map((error) => (
                        <div key={error.expected} className='flex gap-2 items-center bg-red-500 text-white font-semibold rounded-lg p-2'>
                          <FontAwesomeIcon size='lg' icon={regular('triangle-exclamation')} />
                          {error.message}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              );
            })()}
          </Form>
        )}
      </Formik>
    </FloatingOverlay>
  );
};

const InstructionsTooltip = (props: { instructions: ReactNode }) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-start',
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 8, crossAxis: 12 }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  return (
    <>
      <div className='cursor-pointer select-none flex items-center' {...getReferenceProps({ ref: reference })}>
        <FontAwesomeIcon className='h-6 aspect-square text-[#9C75FA]' icon={solid('question-circle')} />
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                maxHeight: '75%',
                overflowY: 'auto',
                position: strategy,
                left: x ? x + 20 : '',
                top: y ? y - 36 : '',
              },
            })}
            className='antialiased shadow-xl bg-[#E8EAF5] text-dark border rounded-xl relative w-[370px] z-10 p-2'
          >
            {props.instructions}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
