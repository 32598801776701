import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { simplify, roundToLong, roundToShort } from '../Root/Main/shared';
import { ProductWithAmount } from '../api';
import { TooltipV3 } from './TooltipV3';

interface Props {
  title?: ReactNode;
  overallImpact: number;
  impactPoints: number;
  impactUnit?: string;
  products: (ProductWithAmount & { physicalValue?: number; impactUnit?: string })[];
}

export const ByProductContributionTable = (props: Props) => {
  return (
    <div className='flex flex-col text-dark border rounded-2xl shadow-regular bg-white overflow-hidden print:shadow-none'>
      {props.title && props.title}
      <table className='w-full rounded-b-2xl overflow-hidden table-fixed'>
        <thead className='text-xs'>
          <tr className='text-zinc-500 uppercase'>
            <th colSpan={2} className='py-3 pl-6'>
              product name
            </th>
            <th className='pl-3'>id</th>
            <th className='pl-3'>production volumes</th>
            <th className='pl-3 w-52'>final environmental impact</th>
            <th className='pl-3'>
              <TooltipV3
                content={
                  <div className='flex flex-col gap-3 normal-case rounded-lg font-normal text-zinc-800 bg-[#DACEFD] px-2 py-1 max-w-[250px] shadow-[0_0_4px_rgba(0,0,0,0.15)]'>
                    <div>
                      Contribution to the total impact is calculated using the absolute values of the impacts (ignoring the
                      negatives/positives) according to PEF.
                    </div>
                    <div>This is why any negative impact process will appear as having a positive contribution.</div>
                  </div>
                }
              >
                <div className='uppercase'>contribution</div>
              </TooltipV3>
            </th>
            <th className='w-10' />
          </tr>
        </thead>
        <tbody className='text-sm divide-y border-t'>
          {props.products
            .sort((a, b) => b.sharePercent - a.sharePercent)
            .map((product, i) => (
              <tr key={i} className={cn({ 'bg-amber-50': product.isMajor })}>
                <td colSpan={2}>
                  <div title={product.name} className='py-3 pl-6 print:max-w-[100px] truncate'>
                    {product.name}
                  </div>
                </td>
                <td className='pl-3' title={product.sku}>
                  <div className='truncate'>{product.sku}</div>
                </td>
                <td className='pl-3'>{simplify(product.count)} units</td>
                <td title={roundToLong(product.impactPoints)} className='pl-3'>
                  {simplify(product.impactPoints)} impact points
                </td>
                <td className='pl-3'>
                  <div className='grid grid-cols-2 items-center'>
                    <div>{product.sharePercent < 0 ? '<0%' : `${roundToShort(product.sharePercent)}%`}</div>
                    <TooltipV3
                      disabled={!product.isMajor}
                      content={
                        <div className='whitespace-nowrap rounded-lg text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 shadow-[0_0_4px_rgba(0,0,0,0.15)]'>
                          Most relevant
                        </div>
                      }
                    >
                      <div className='flex justify-self-center'>
                        <FontAwesomeIcon
                          className={cn('text-amber-400 text-xl', { hidden: !product.isMajor })}
                          icon={solid('seal-exclamation')}
                        />
                      </div>
                    </TooltipV3>
                  </div>
                </td>
                <td className='h-full pr-3'>
                  <NavLink
                    to={`/products/${product.id}/report/production/overview`}
                    target='_blank'
                    className='flex self-center print:hidden'
                  >
                    <FontAwesomeIcon className='text-lg rotate-45 text-brand' icon={light('arrow-up')} />
                  </NavLink>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr className='bg-slate-50 text-brand text-sm'>
            <td colSpan={2} className='pl-6 py-3 font-semibold'>
              Total
            </td>
            <td />
            <td className='pl-3'>{simplify(props.products.reduce((acc, curr) => acc + curr.count, 0))} units</td>
            <td title={roundToLong(props.impactPoints)} className='pl-3 text-brandDark'>
              {simplify(props.impactPoints)} impact points
            </td>
            <td className='pl-3'>100%</td>
            <td className='pl-3'></td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
