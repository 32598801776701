import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { ProductType } from '../../../api';
import { Popover } from '../../../components/Popover';
import { useLists } from '../../../hooks/useLists';
import { useProfile } from '../../../hooks/useProfile';
import { PopoverFrame } from './components/PopoverFrame';

interface Props {
  impactCategories: string[];
  setImpactCategories: (value: string[]) => void;
  workspaceIds: string[];
  setWorkspaceIds: (value: string[]) => void;
  brandTypes: string[];
  setBrandTypes: (value: string[]) => void;
  categoryIds: string[];
  setCategoryIds: (value: string[]) => void;
  productTypes: string[];
  setProductTypes: (value: string[]) => void;
  countryIds: string[];
  setCountryIds: (value: string[]) => void;
  physicalImpact: boolean;
  setPhysicalImpact: (value: boolean) => void;
}

export const Header = (props: Props) => {
  const profile = useProfile();
  const lists = useLists();

  const primaryFilters = [
    {
      label: 'Impact category',
      icon: light('earth-europe'),
      values: props.impactCategories,
      setValues: props.setImpactCategories,
      allItemLabel: 'Total env. impact (no filter selected)',
      shownCount: 1,
      items: [
        { value: '1', label: 'Climate change' },
        { value: '2', label: 'Particulate matter' },
        { value: '3', label: 'Resource use, fossils' },
        { value: '4', label: 'Acidification' },
        { value: '5', label: 'Human toxicity, non-cancer' },
        { value: '6', label: 'Ecotoxicity, freshwater' },
        { value: '7', label: 'Eutrophication, terrestrial' },
        { value: '8', label: 'Land use' },
        { value: '9', label: 'Eutrophication, marine' },
        { value: '10', label: 'Resource use, minerals & metals' },
        { value: '11', label: 'Photochemical ozone formation, human health' },
        { value: '12', label: 'Water scarcity' },
        { value: '13', label: 'Ionising radiation, human health' },
        { value: '15', label: 'Human toxicity, cancer' },
        { value: '16', label: 'Ozone depletion' },
      ],
    },
    {
      label: 'Brand',
      icon: light('building'),
      values: props.workspaceIds,
      setValues: props.setWorkspaceIds,
      allItemLabel: 'All brands',
      shownCount: 2,
      items: profile.workspaces.map(({ workspaceSid, name }) => ({ value: workspaceSid, label: name })),
    },
    {
      label: 'Brand type',
      icon: light('buildings'),
      values: props.brandTypes,
      setValues: props.setBrandTypes,
      allItemLabel: 'All types',
      shownCount: Infinity,
      items: lists.brands.map(({ type, name }) => ({ value: type, label: name })),
    },
    {
      label: 'Product category',
      icon: light('carrot'),
      values: props.categoryIds,
      setValues: props.setCategoryIds,
      allItemLabel: 'All categories',
      shownCount: 2,
      items: lists.categories.map(({ id, name }) => ({ value: id, label: name })),
    },
  ];
  const additionalFilters = [
    {
      label: 'Product type',
      icon: light('box-circle-check'),
      values: props.productTypes,
      setValues: props.setProductTypes,
      allItemLabel: 'All',
      shownCount: Infinity,
      items: [
        { value: ProductType.Final, label: 'Final' },
        { value: ProductType.Intermediate, label: 'Intermediate' },
      ],
    },
    {
      label: 'Production country',
      icon: light('location-dot'),
      values: props.countryIds,
      setValues: props.setCountryIds,
      allItemLabel: 'All',
      shownCount: 2,
      items: [
        { value: '1', label: 'France' },
        { value: '2', label: 'Spain' },
        { value: '3', label: 'United Kingdom' },
        { value: '4', label: 'Italy' },
        { value: '5', label: 'Germany' },
      ],
    },
  ];
  const filters = [...primaryFilters, ...additionalFilters];

  return (
    <div className='z-10 sticky top-0 flex flex-col gap-2 px-5 py-6 bg-white border-b border-r border-zinc-200 shadow-[0_1px_10px_rgba(0,0,0,0.15)]'>
      <div className='flex justify-between'>
        <div className='flex gap-1'>
          {primaryFilters.map(({ label, values, setValues, allItemLabel, items }) => (
            <Popover
              key={label}
              placement='bottom-start'
              offset={2}
              content={() => (
                <PopoverFrame className='whitespace-nowrap max-h-96 overflow-y-scroll gap-1 p-2'>
                  {[{ value: '', label: allItemLabel }, ...items].map(({ value, label }) => {
                    const selected = values.includes(value) || (value === '' && values.length === 0);
                    return (
                      <button
                        key={value}
                        type='button'
                        className={cn('flex items-center gap-2 px-2 py-1.5 rounded-lg', {
                          'text-zinc-700 bg-[rgba(218,206,253,0.5)]': selected,
                        })}
                        disabled={value === '' && values.length === 0}
                        onClick={() => {
                          if (value === '') {
                            setValues([]);
                          } else {
                            setValues(
                              values.includes(value) ? values.filter((selectedValue) => selectedValue !== value) : [...values, value],
                            );
                          }
                        }}
                      >
                        <div
                          className={cn(
                            'size-[18px] flex justify-center items-center border text-white rounded-sm',
                            selected ? 'bg-brand border-brand' : 'border-zinc-400',
                          )}
                        >
                          {selected && <FontAwesomeIcon icon={solid('check')} />}
                        </div>
                        {label}
                      </button>
                    );
                  })}
                </PopoverFrame>
              )}
            >
              {({ open }) => (
                <button
                  type='button'
                  className={cn(
                    'rounded-full text-zinc-600 border py-2 pl-4 pr-3.5 flex items-center gap-2',
                    values.length > 0 ? 'border-zinc-500' : 'border-zinc-200',
                    {
                      'bg-[#E8EAF5]': open,
                    },
                  )}
                >
                  {label}
                  <FontAwesomeIcon icon={open ? regular('chevron-up') : regular('chevron-down')} className='text-zinc-500' />
                </button>
              )}
            </Popover>
          ))}
          <Popover
            placement='bottom-start'
            offset={2}
            content={() => (
              <PopoverFrame className='whitespace-nowrap max-h-96 w-96 overflow-y-scroll gap-4 p-4'>
                <div className='font-semibold text-zinc-800'>Additional filters</div>
                <div className='h-px shrink-0 bg-zinc-200' />
                {additionalFilters.map(({ label, values, setValues, allItemLabel, items }) => (
                  <div key={label} className='flex flex-col gap-2'>
                    <div>{label}</div>
                    <div className='flex flex-wrap gap-2'>
                      {[{ value: '', label: allItemLabel }, ...items].map(({ value, label }) => {
                        const selected = values.includes(value) || (value === '' && values.length === 0);
                        return (
                          <button
                            key={value}
                            type='button'
                            className={cn(
                              'flex items-center gap-2 px-2 py-1.5 rounded-lg border',
                              selected ? 'border-transparent' : 'border-zinc-200',
                              {
                                'text-zinc-700 bg-[rgba(218,206,253,0.5)]': selected,
                              },
                            )}
                            disabled={value === '' && values.length === 0}
                            onClick={() => {
                              if (value === '') {
                                setValues([]);
                              } else {
                                setValues(
                                  values.includes(value) ? values.filter((selectedValue) => selectedValue !== value) : [...values, value],
                                );
                              }
                            }}
                          >
                            <div
                              className={cn(
                                'size-[18px] flex justify-center items-center border text-white rounded-sm',
                                selected ? 'bg-brand border-brand' : 'border-zinc-400',
                              )}
                            >
                              {selected && <FontAwesomeIcon icon={solid('check')} />}
                            </div>
                            {label}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </PopoverFrame>
            )}
          >
            {({ open }) => (
              <button
                type='button'
                className={cn(
                  'relative rounded-full text-zinc-600 border border-zinc-200 py-2 pl-3.5 pr-4 flex items-center gap-2',
                  additionalFilters.some(({ values }) => values.length > 0) ? 'border-zinc-500' : 'border-zinc-200',
                  {
                    'bg-[#E8EAF5]': open,
                  },
                )}
              >
                <FontAwesomeIcon icon={light('sliders-up')} className='text-zinc-500' />
                Additional filters
                {additionalFilters.some(({ values }) => values.length > 0) && (
                  <div className='absolute -top-[5px] -right-[5px] flex justify-center items-center size-4 rounded-full text-[10px] font-semibold text-white bg-brandDarkPurple2'>
                    {additionalFilters.filter(({ values }) => values.length > 0).length}
                  </div>
                )}
              </button>
            )}
          </Popover>
        </div>
        <div className='flex items-center pl-2.5 pr-2 h-full rounded-lg bg-indigo-50'>
          <FontAwesomeIcon icon={light('flag-checkered')} className='text-brandDarkPurple2' />
          <div className='text-zinc-700 text-base ml-3'>
            {props.impactCategories.length === 1 && props.impactCategories[0] === '1'
              ? props.physicalImpact
                ? '13.19'
                : '842M'
              : props.workspaceIds.length === 1
              ? '95M'
              : '2.11B'}
          </div>
          <div className='uppercase tracking-uppercase text-zinc-500 text-[10px] ml-1'>
            {props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? 'mt co₂ eq' : 'Impact P.'}
          </div>
        </div>
      </div>
      {filters.some(({ values }) => values.length > 0) && (
        <div className='flex gap-8 justify-between'>
          <div className='flex flex-wrap gap-2'>
            {filters
              .filter(({ values }) => values.length > 0)
              .map(({ values, setValues, items, shownCount, icon }, i) => (
                <button
                  key={i}
                  type='button'
                  className='flex items-center gap-2 px-3 py-2 rounded-full text-xs bg-indigo-50 text-zinc-600'
                  onClick={() => setValues([])}
                >
                  <FontAwesomeIcon icon={icon} />
                  <span className='text-zinc-700'>
                    {values
                      .filter((_, i) => i < shownCount)
                      .flatMap((value) => items.filter((item) => item.value === value))
                      .map(({ label }) => label)
                      .join(', ')}
                    {values.length > shownCount && ` +${values.length - shownCount}`}
                  </span>
                  <FontAwesomeIcon icon={light('times')} size='lg' />
                </button>
              ))}
            <button type='button' className='p-2 text-zinc-500' onClick={() => filters.forEach(({ setValues }) => setValues([]))}>
              Reset all filters
            </button>
          </div>
          <button
            type='button'
            className={cn('shrink-0 self-start flex items-center gap-2 p-2 text-zinc-600', {
              invisible: props.impactCategories.length !== 1,
            })}
            onClick={() => props.setPhysicalImpact(!props.physicalImpact)}
          >
            <div className={cn('flex w-7 p-0.5 rounded-full', props.physicalImpact ? 'justify-end bg-brand' : 'bg-zinc-300')}>
              <div className='size-3 bg-white rounded-full' />
            </div>
            Physical impact
          </button>
        </div>
      )}
    </div>
  );
};
