import { ProductReport } from '../../../../../../api';
import { ReactNode } from 'react';
import { simplify, roundToLong } from '../../../../shared';
import { CollapsibleSection } from '../../CollapsibleSection';
import { ByImpactContributionTable } from '../../../../../../components/ByImpactContributionTable';
import { NavigationButtons } from './NavigationButtons';

interface Props {
  data: ProductReport;
  description: ReactNode;
}

export const ImpactAnalysis = (props: Props) => {
  return (
    <div className='flex flex-col gap-6 mx-6'>
      {props.description}
      <CollapsibleSection title='Total Environmental Impact'>
        <div className='mt-3'>
          For all 16 impact categories, impacts in person years are calculated and added together to obtain the overall impact of your
          product, or the total environmental impact the production of your product has as compared to that of an average person over one
          year. This is presented below.
        </div>
        <div className='mt-3'>
          To help you more easily grasp the primary outputs of the analysis, the result in 'weighted person.year', we have created the
          impact points, which are simply the results multiplied by 1 million to scale up the results and remove the exponents which can be
          hard to easily interpret. This full report shows both for completeness wherever possible.
        </div>
        <div className='flex flex-col my-6'>
          <div className='items-center flex flex-col gap-4'>
            <div className='text-center text-dark font-semibold'>Total Environmental Impact</div>
            <div className='p-6 rounded-3xl bg-lightBg border border-lightBgBorder2 text-dark'>
              <div className='flex flex-col justify-center items-center gap-2'>
                <div className='flex items-end gap-2'>
                  <div title={roundToLong(props.data.overallImpact)} className='text-2xl text-brandDark leading-7'>
                    {simplify(props.data.overallImpact)}
                  </div>{' '}
                  weighted person.year
                </div>
                <div className='text-lg'>or</div>
                <div className='flex items-end gap-2'>
                  <div title={roundToLong(props.data.impactPoints)} className='text-2xl text-brandDark leading-7'>
                    {simplify(props.data.impactPoints)}
                  </div>{' '}
                  Impact points
                </div>
              </div>
            </div>
          </div>
        </div>
      </CollapsibleSection>

      <CollapsibleSection title='By Impact category breakdown'>
        <div>
          The breakdown by impact categories is displayed in the form of a table showing from highest to lowest impact contribution, the
          physical outputs (in their respective units), the final environmental impacts in weighted person years (after normalisation and
          weighting), and the contribution (%) of each impact category to the total impact.
        </div>
        <div className='mt-6 rounded-2xl shadow-regular overflow-hidden'>
          <div className='text-lg font-semibold text-center p-4 text-dark'>
            Physical impact, final environmental impact and contribution per impact category
          </div>
          <ByImpactContributionTable
            data={props.data.analysis.impactStagesMatrix.map((impact) => ({
              ...impact,
              id: impact.impactId,
              name: impact.impactName,
              contributionPercent: impact.absSharePercent,
            }))}
            totalImpact={props.data.overallImpact}
            totalPoints={props.data.impactPoints}
          />
        </div>
      </CollapsibleSection>
      <NavigationButtons
        type='buttons'
        next={{ path: '../environmental-label', label: 'Environmental Label' }}
        back={{ path: '../goal-and-scope', label: 'Goal & Scope' }}
      />
    </div>
  );
};
