export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const years = (startYear: number, endYear: number) => {
  return new Array<number>(endYear - startYear).fill(0).map((_, i) => startYear + i);
};

export const convertLocalToUTCDate = (date: Date | null) => {
  if (!date) {
    return null;
  }
  date = new Date(date);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};

export const convertUTCDateToLocal = (date: Date | string) => {
  const newDate = new Date(date);
  return new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60000);
};
