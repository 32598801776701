import { Interpretation } from '../../components/Interpretation';
import { ForecastReport, ReportType } from '../../../../../api';
import { NavigationButtons } from '../../../Products/Report/Sku/components/NavigationButtons';

interface Props {
  data: ForecastReport;
}

export const ForecastInterpretation = (props: Props) => {
  return (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-6'>
          An additional interpretation of the Life Cycle Impact Assessment results presented in the previous section, providing some further
          contextualisation and looking at some potential options if you want to further improve this forecasted impact.
        </div>
        <NavigationButtons type='icons' back={{ path: '../lcia' }} next={{ path: '../appendix' }} />
      </div>
      <Interpretation data={props.data} reportType={ReportType.Forecast} />
      <NavigationButtons back={{ path: '../lcia', label: 'LCIA' }} next={{ path: '../appendix', label: 'Appendix' }} type='buttons' />
    </div>
  );
};
