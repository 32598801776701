import { useId } from '@floating-ui/react-dom-interactions';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Amount,
  Entity,
  ImpactDelta,
  ModellingPayload,
  NodeType,
  PackagingNode,
  PackagingNodeMaterial,
  PackagingSupplierNode,
  ProductModelV3,
  Supplier,
  SupplierService,
  getSuppliers,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { useEffectOnNextRenders } from '../../../../hooks/useEffectOnNextRenders';
import { NewSupplierForm } from '../../Manage/Suppliers/NewSupplierForm';
import { useLists } from '../../../../hooks/useLists';
import { CardBadge } from './Badge';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { LocationSelect } from './LocationSelect';
import { OriginalAwareDiffedItem, OriginalAwareField, joinWithDiff } from './OriginalAwareField';
import {
  StepInputAmountSideEffect,
  UpdateSideEffects,
  adjustMaterialSplits,
  adjustSupplierSplits,
  getPackagingsFromAll,
  getProductionFacilities,
  is100Percent,
  newNodeId,
  shouldAutoAdjustMaterialSplit,
  shouldAutoAdjustSupplierSplit,
} from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';
import { OriginalAwareProvider } from './useOriginalAware';

const toSupplierOption = (supplier: Supplier, config?: { noId?: boolean }): SupplierNode => ({
  id: config?.noId ? '' : newNodeId(),
  type: NodeType.PackagingSupplier,
  displayName: supplier.name,
  flagged: false,
  edges: new Array<string>(),
  supplier,
  location: null as any as Entity,
  splitPercent: null as any as number,
  autoAdjustSplit: true,
});

interface SupplierNode extends PackagingSupplierNode {
  autoAdjustSplit: boolean;
}

interface MaterialNode extends PackagingNodeMaterial {
  autoAdjustSplit: boolean;
}

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data?: PackagingNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<PackagingNode, { sideEffects: UpdateSideEffects }>;
  onOpenChange?: (open: boolean) => void;
}>;

export const PackagingDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const bodyRef = useRef<BodyApi>(null);
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm
      formRef={formRef}
      title={props.data ? `${props.data.amount.value}g of ${props.data.displayName}` : 'New packaging'}
      body={
        <Body
          ref={bodyRef}
          payload={props.payload}
          productFormik={formik}
          edit={!!props.data}
          formRef={formRef}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      onOpenChange={props.onOpenChange}
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            In what packaging is your product sold to your customers? Select the type of packaging (ie. bottle, box, wrap etc.) from the
            dropdown, the amount you are procuring, as well as which supplier(s) you’re getting it from.
          </div>
          <div>
            We know that you don’t always get raw materials from the same supplier year round so here you can specify what we call the
            split, or the percentage of time you got the packaging from one supplier versus another when looking 3 years back.
          </div>
          <div>
            On the other hand, if you always get a different amount of the packaging from different suppliers, just add it again in the
            graph, as many times as you need.
          </div>
          <div>
            The only thing left is to specify the composition of this packaging. Which material(s) is it made of? Add as many materials as
            needed, whether they are virgin or recycled materials, and specify what the breakdown is, making sure it all adds up to 100%!
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        displayName: '',
        type: NodeType.Packaging,
        flagged: false,
        edges: new Array<string>(),
        materials: new Array<PackagingNodeMaterial>(),
        nodes: new Array<PackagingSupplierNode>(),
        packaging: undefined as any as Entity,
        amount: undefined as any as Amount,
      }}
      data={
        props.data
          ? {
              ...props.data,
              nodes: props.data.nodes.map(
                (node) =>
                  ({
                    ...node,
                    autoAdjustSplit: shouldAutoAdjustSupplierSplit(node, props.payload),
                  } as SupplierNode),
              ),
              materials: props.data.materials.map(
                (material) =>
                  ({
                    ...material,
                    autoAdjustSplit: shouldAutoAdjustMaterialSplit(material, props.payload),
                  } as MaterialNode),
              ),
            }
          : undefined
      }
      validationSchema={yup.object().shape({
        packaging: yup.object().required(),
        amount: yup.object().shape({
          value: yup.number().positive().required(),
        }),
        materials: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              subType: yup.object().required(),
              compositionPercent: yup.number().positive().max(100).required(),
            }),
          )
          .test('', 'compositionNot100', function () {
            const parent = this.parent as PackagingNode;
            return (
              parent.materials.length === 0 ||
              (parent.materials.every(({ compositionPercent }) => typeof compositionPercent === 'number') &&
                is100Percent(parent.materials.map(({ compositionPercent }) => compositionPercent)))
            );
          }),
        nodes: yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              location: yup.object().required(),
              splitPercent: yup.number().positive().max(100).required(),
            }),
          )
          .test('', 'splitsNot100', function () {
            const parent = this.parent as PackagingNode;
            return (
              parent.nodes.length === 0 ||
              (parent.nodes.every(({ splitPercent }) => typeof splitPercent === 'number') &&
                is100Percent(parent.nodes.map(({ splitPercent }) => splitPercent)))
            );
          }),
      })}
      getCustomErrors={(errors) => [
        { message: 'The suppliers split must add up to 100%.', expected: 'splitsNot100', actual: errors.nodes },
        { message: 'Packaging composition must add up to 100%.', expected: 'compositionNot100', actual: errors.materials },
      ]}
      entityName='packaging'
      onSave={({ values, ...rest }) => {
        props.onSave({
          values: values as PackagingNode,
          sideEffects: { stepInputAmounts: bodyRef.current!.getSideEffects() },
          ...rest,
        });
      }}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  edit: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

interface BodyApi {
  getSideEffects: () => StepInputAmountSideEffect[];
}

const Body = forwardRef<BodyApi, BodyProps>((props, ref) => {
  const lists = useLists();
  const { payload, productFormik } = props;
  const formik = useFormikContext<PackagingNode>();
  const originalAmountValue = useRef(formik.values.amount?.value);
  const updateStepInputCheckboxId = useId();
  const [updateStepInput, setUpdateStepInput] = useState(true);
  const packaging = lists.packagingTypes.find((type) => type.id === formik.values.packaging?.id);
  const [showSupplierForm, setShowSupplierForm] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');

  const getSubTypes = (materialId: string) =>
    lists.materialSubTypes.filter((subType) =>
      lists.packagingMaterials.find(({ id }) => id === materialId)!.materialSubTypes.includes(subType.id),
    );

  const getSingleStepInputUsingPackaging = () => {
    const inputs = getProductionFacilities(props.productFormik)
      .flatMap(({ steps }) => steps)
      .flatMap((step) => step.inputs.map((input) => ({ step, input })))
      .filter(({ input: { id } }) => id === formik.values.id);
    return inputs.length === 1 ? inputs[0] : undefined;
  };

  const canUpdateStepInput =
    props.edit &&
    typeof originalAmountValue.current === 'number' &&
    typeof formik.values.amount?.value === 'number' &&
    formik.values.amount.unit &&
    originalAmountValue.current !== formik.values.amount?.value &&
    getSingleStepInputUsingPackaging();

  useImperativeHandle(ref, () => ({
    getSideEffects: () => {
      if (canUpdateStepInput && updateStepInput) {
        const { step, input } = getSingleStepInputUsingPackaging()!;
        return [{ stepId: step.id, inputId: input.id, value: formik.values.amount.value }];
      }

      return [];
    },
  }));

  useInteractiveImpact<PackagingNode>({
    payload,
    productFormik,
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  useEffectOnNextRenders(() => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      delete newValues.index;
      newValues.displayName = '';
      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.packaging?.id]);

  useEffectOnNextRenders(() => {
    if ((formik.values.nodes as SupplierNode[]).some(({ autoAdjustSplit }) => autoAdjustSplit)) {
      adjustSupplierSplits(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.nodes.length]);

  useEffectOnNextRenders(() => {
    if ((formik.values.materials as MaterialNode[]).some(({ autoAdjustSplit }) => autoAdjustSplit)) {
      adjustMaterialSplits(formik);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.materials.length]);

  return (
    <OriginalAwareProvider nodeId={formik.values.id} payload={payload}>
      <div className='grid grid-cols-2 gap-4'>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Type</div>
          <div>
            <Field name='packaging'>
              {(model: FieldProps<Entity>) => (
                <SelectV3<Entity>
                  autoFocus
                  model={model}
                  disabled={props.edit}
                  menuPortalTarget={props.formRef.current}
                  options={lists.packagingTypes}
                />
              )}
            </Field>
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Amount</div>
          <div>
            <OriginalAwareField name='amount.value'>
              {(model: FieldProps<number>) => (
                <UnitInputV3
                  model={model}
                  unit={{
                    options: [{ id: '', name: 'g' }],
                  }}
                />
              )}
            </OriginalAwareField>
          </div>
        </div>
        {canUpdateStepInput && (
          <div className='col-span-2 flex gap-2 ml-2'>
            <input
              id={updateStepInputCheckboxId}
              type='checkbox'
              checked={updateStepInput}
              onChange={() => setUpdateStepInput((value) => !value)}
            />
            <label htmlFor={updateStepInputCheckboxId} className='select-none'>
              Automatically change input amount of the production step ({getSingleStepInputUsingPackaging()!.step.displayName}) to{' '}
              {formik.values.amount!.value}
              {formik.values.amount!.unit.name}
            </label>
          </div>
        )}
        <FieldArray
          name='nodes'
          render={(arrayModel) => (
            <>
              <div className='col-span-2 flex flex-col gap-1'>
                <div className='pl-1.5'>Suppliers</div>
                <Field name={arrayModel.name}>
                  {(model: FieldProps<PackagingSupplierNode[]>) => (
                    <SelectV3<PackagingSupplierNode>
                      multi
                      multiRepeated
                      model={model}
                      getOptionValue={({ supplier }) => supplier.id}
                      getOptionLabel={({ supplier }) => supplier.name}
                      menuPortalTarget={props.formRef.current}
                      loadOptions={(input, callback) => {
                        setNewSupplierName(input);
                        getSuppliers({
                          contains: input,
                          service: SupplierService.Packaging,
                        }).ok(({ suppliers }) => callback(suppliers.map((supplier) => toSupplierOption(supplier, { noId: true }))));
                      }}
                      adjustChange={(value: PackagingSupplierNode[]) =>
                        value.map((option) => ({ ...option, id: option.id || newNodeId() }))
                      }
                      menuFooter={
                        !showSupplierForm && (
                          <SelectFooterAddButton onClick={() => setShowSupplierForm(true)} name={newSupplierName} label='new provider' />
                        )
                      }
                    />
                  )}
                </Field>
              </div>
              {showSupplierForm && (
                <div className='bg-[#F5F7FA] col-span-2 p-3 rounded-xl shadow-regular mt-3'>
                  <NewSupplierForm
                    name={newSupplierName}
                    formRef={props.formRef}
                    requiredServices={[SupplierService.Packaging]}
                    onCancel={() => setShowSupplierForm(false)}
                    onCreated={(newSupplier) => {
                      formik.setFieldValue('nodes', [...formik.values.nodes, toSupplierOption(newSupplier)]);
                      setShowSupplierForm(false);
                    }}
                  />
                </div>
              )}
              <div className='col-span-2 grid grid-cols-2 gap-4 my-4'>
                {joinWithDiff(
                  formik.values.nodes,
                  getPackagingsFromAll(payload.product.nodes).find(({ id }) => id === formik.values.id)?.nodes,
                ).map((item, index) => (
                  <SupplierCard
                    key={item.node.id}
                    index={index}
                    item={item as OriginalAwareDiffedItem<SupplierNode>}
                    arrayModel={arrayModel}
                    {...props}
                  />
                ))}
              </div>
            </>
          )}
        />
        {packaging && (
          <FieldArray
            name='materials'
            render={(arrayModel) => (
              <>
                <div className='col-span-2 flex flex-col gap-1'>
                  <div className='pl-1.5'>Materials</div>
                  <div>
                    <Field name={arrayModel.name}>
                      {(model: FieldProps<PackagingNodeMaterial[]>) => (
                        <SelectV3
                          multi
                          multiRepeated
                          model={model}
                          menuPortalTarget={props.formRef.current}
                          options={lists.packagingMaterials
                            .filter(({ id }) => packaging.packagingMaterials.includes(id))
                            .map((material) => ({
                              ...material,
                              id: newNodeId(),
                              materialId: material.id,
                              ...(getSubTypes(material.id).length === 1 ? { subType: getSubTypes(material.id)[0] } : {}),
                              compositionPercent: null as any as number,
                              autoAdjustSplit: true,
                            }))}
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <div className='col-span-2 grid grid-cols-2 gap-4 mt-4'>
                  {joinWithDiff(
                    formik.values.materials,
                    getPackagingsFromAll(payload.product.nodes).find(({ id }) => id === formik.values.id)?.materials,
                  ).map((item, index) => (
                    <MaterialCard
                      key={item.node.id}
                      index={index}
                      item={item as OriginalAwareDiffedItem<MaterialNode>}
                      arrayModel={arrayModel}
                      getSubTypes={getSubTypes}
                      {...props}
                    />
                  ))}
                </div>
              </>
            )}
          />
        )}
      </div>
    </OriginalAwareProvider>
  );
});

const SupplierCard = (
  props: {
    index: number;
    item: OriginalAwareDiffedItem<SupplierNode>;
    arrayModel: FieldArrayRenderProps;
  } & BodyProps,
) => {
  const formik = useFormikContext<PackagingNode>();
  const splitPercentRef = useRef<HTMLInputElement>(null);
  const willReset = useRef(false);
  const { item, arrayModel } = props;

  useEffectOnNextRenders(() => {
    if (willReset.current || document.activeElement === splitPercentRef.current) {
      adjustSupplierSplits(formik, props.item.node);
    }

    willReset.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.node.splitPercent]);

  return (
    <div
      key={item.node.id}
      className='flex flex-col justify-between gap-8 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'
    >
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-4 font-semibold text-lg text-neutral-900'>
          {item.deleted ? (
            <div className='truncate opacity-50'>{item.node.supplier.name}</div>
          ) : (
            <>
              <div className='-ml-3 flex-1'>
                <OriginalAwareField
                  itemName={{
                    arrayModel,
                    field: 'supplier',
                    ...item,
                  }}
                  badgeMarginStyles='mx-3'
                >
                  {(model: FieldProps<Entity>) => (
                    <SelectV3
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      loadOptions={(input, callback) => {
                        getSuppliers({
                          contains: input,
                          service: SupplierService.Packaging,
                        }).ok(({ suppliers }) => callback(suppliers));
                      }}
                      hideInputBorder
                      noClear
                    />
                  )}
                </OriginalAwareField>
              </div>
              <button
                type='button'
                className='flex justify-center rounded-sm w-7 aspect-square pt-2.5'
                onClick={arrayModel.handleRemove(item.index.current)}
              >
                <FontAwesomeIcon icon={regular('times')} size='lg' />
              </button>
            </>
          )}
        </div>
        <CardBadge item={item} />
      </div>
      {!item.deleted && (
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Split</div>
            <div>
              <OriginalAwareField
                itemName={{
                  arrayModel,
                  field: 'splitPercent',
                  ...item,
                }}
                card
                onBeforeReset={() => (willReset.current = true)}
              >
                {(model: FieldProps<number>) => (
                  <UnitInputV3 inputRef={splitPercentRef} model={model} unit={{ options: [{ id: '', name: '%' }] }} />
                )}
              </OriginalAwareField>
            </div>
          </div>
          <LocationSelect
            itemName={{
              arrayModel,
              field: 'location',
              ...item,
            }}
            includeOption={(option) =>
              !formik.values.nodes
                .filter(({ id }) => id !== item.node.id)
                .filter(({ supplier }) => supplier.id === item.node.supplier.id)
                .some((node) => node?.location?.id === option.id)
            }
            formRef={props.formRef}
          />
        </div>
      )}
    </div>
  );
};

const MaterialCard = (
  props: {
    index: number;
    item: OriginalAwareDiffedItem<MaterialNode>;
    arrayModel: FieldArrayRenderProps;
    getSubTypes: (materialId: string) => Entity[];
  } & BodyProps,
) => {
  const formik = useFormikContext<PackagingNode>();
  const compositionPercentRef = useRef<HTMLInputElement>(null);
  const willReset = useRef(false);
  const { item, arrayModel } = props;

  useEffectOnNextRenders(() => {
    if (willReset.current || document.activeElement === compositionPercentRef.current) {
      adjustMaterialSplits(formik, props.item.node);
    }

    willReset.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item.node.compositionPercent]);

  return (
    <div
      key={item.node.id}
      className='flex flex-col justify-between gap-8 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'
    >
      <div className='flex flex-col gap-2'>
        <div className='flex justify-between gap-4 font-semibold text-lg text-neutral-900'>
          <div className={cn('truncate', { 'opacity-50': item.deleted })}>{item.node.name}</div>
          {!item.deleted && (
            <button
              type='button'
              className='flex justify-center items-center rounded-sm w-7 aspect-square'
              onClick={arrayModel.handleRemove(item.index.current)}
            >
              <FontAwesomeIcon icon={regular('times')} size='lg' />
            </button>
          )}
        </div>
        <CardBadge item={item} />
      </div>
      {!item.deleted && (
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Type</div>
            <div>
              <OriginalAwareField
                itemName={{
                  arrayModel,
                  field: 'subType',
                  ...item,
                }}
                card
              >
                {(model: FieldProps<Entity>) => (
                  <SelectV3 model={model} menuPortalTarget={props.formRef.current} options={props.getSubTypes(item.node.materialId)} />
                )}
              </OriginalAwareField>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Composition</div>
            <div>
              <OriginalAwareField
                itemName={{
                  arrayModel,
                  field: 'compositionPercent',
                  ...item,
                }}
                card
                onBeforeReset={() => (willReset.current = true)}
              >
                {(model: FieldProps<number>) => (
                  <UnitInputV3 inputRef={compositionPercentRef} model={model} unit={{ options: [{ id: '', name: '%' }] }} />
                )}
              </OriginalAwareField>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
