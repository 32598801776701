import { useState } from 'react';
import { GhgProtocol } from '../../../components/GhgProtocol.tsx';
import { Overview } from '../Overview';
import { ModalV3 } from '../../../../../../components/ModalV3';
import { TooltipV3 } from '../../../../../../components/TooltipV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingGhgReport, resetBaseline, Lens, ReportType } from '../../../../../../api';
import { simplify, roundToLong } from '../../../../shared';
import { format } from 'date-fns';

interface Props {
  data: ManufacturingGhgReport;
  hasError: boolean;
  reload: () => void;
}

export const GhgOverview = (props: Props) => {
  const [deleting, setDeleting] = useState(false);

  return (
    <Overview data={props.data} hasError={props.hasError} lens={Lens.Ghg}>
      {!props.hasError && (
        <div>
          <div className='flex flex-col gap-y-4 bg-slate-50 p-6 pt-8 border-b border-zinc-200'>
            <div className='font-semibold flex gap-x-4 bg-amber-50 border border-amber-400 rounded-lg p-4'>
              <FontAwesomeIcon className='mt-1 h-5 aspect-square text-amber-400' icon={regular('exclamation-triangle')} />
              Results presented in this section cover emissions from production-related facilities, goods or services only. Everything
              non-production related needs to be added before final reporting. In addition, scope 1 and 2 calculation rely mostly on
              secondary data and as such should be used as reference only.
            </div>
            <div className='flex flex-col gap-y-4 self-start'>
              <div className='flex items-stretch gap-x-4'>
                <div className='flex flex-col justify-between gap-y-4 py-4 px-5 rounded-2xl border bg-white'>
                  <div className='font-semibold'>Total emissions</div>
                  <div className='flex items-center gap-x-2'>
                    <div title={roundToLong(props.data.analysis.totalEmission.value)} className='text-3xl font-semibold'>
                      {simplify(props.data.analysis.totalEmission.value)}
                    </div>
                    <div className='text-zinc-500 uppercase text-sm'>{props.data.analysis.totalEmission.unit}</div>
                  </div>
                  <div className='flex items-center gap-x-2'>
                    <div className='text-[13px] text-zinc-500'>Why the difference with PEF climate change impact?</div>
                    <TooltipV3
                      placement='bottom-start'
                      content={
                        <div className='max-w-[360px] flex flex-col gap-y-4 text-sm bg-[#e8eaf5] p-3 rounded-md shadow-regular'>
                          <div>
                            The GHG Protocol and PEF have slightly different system boundaries and handle certain allocations differently.
                          </div>
                          <div>
                            For example, the Circular Footprint Formula used for packaging end of life impact calculations in PEF includes
                            credits for material that is recycled and credits for energy that is provided e.g. due to waste incineration. In
                            the GHG Protocol, these credits are not to be reported in the overall emissions, but rather, reported separately
                            as ‘avoided emissions’. The same goes with biogenic emissions.
                          </div>
                          <div>
                            Another example is the system boundary used for intermediate products. Packaging end of life is excluded under
                            PEF while it is required under GHG protocol.
                          </div>
                          <div>
                            Furthermore, while both methods use the characterisations factors from the IPCC reports, the update cycle of the
                            respective methods might be different.
                          </div>
                        </div>
                      }
                    >
                      <div>
                        <FontAwesomeIcon className='text-violet-400' icon={solid('info-circle')} />
                      </div>
                    </TooltipV3>
                  </div>
                </div>

                <div className='flex flex-col justify-between gap-y-4 py-4 px-5 rounded-2xl border bg-white'>
                  <div className='flex items-center justify-between gap-x-10'>
                    <div className='flex items-center gap-x-2'>
                      <div className='size-8 flex items-center justify-center bg-indigo-50 rounded-lg'>
                        <FontAwesomeIcon icon={light('flag-checkered')} />
                      </div>
                      <div className='font-semibold text-sm'>{`${format(new Date(props.data.startDate), 'MMM dd, yyyy')}-${format(
                        new Date(props.data.endDate),
                        'yy',
                      )}`}</div>
                    </div>

                    <ModalV3
                      title='Are you sure you want to reset your baseline?'
                      body={
                        <div className='flex justify-items-center flex-col -mt-6'>
                          <div className='flex self-center text-center w-3/4 text-base'>
                            <span>
                              This will reset it for your <span className='text-brand font-semibold'>entire workspace</span> and all
                              progress tracking against this baseline will be lost
                            </span>
                          </div>
                        </div>
                      }
                      confirmLabel='Reset'
                      onConfirm={() => {
                        setDeleting(true);
                        resetBaseline().call({
                          ok: () => {
                            setDeleting(false);
                            props.reload();
                          },
                          fail: () => {
                            setDeleting(false);
                          },
                        });
                      }}
                    >
                      <button disabled={deleting} className='text-brand text-sm hover:underline'>
                        Reset baseline
                      </button>
                    </ModalV3>
                  </div>

                  <div className='flex gap-x-6 items-center'>
                    <div>{props.data.productCount} products</div>
                    <div className='h-full w-[1px] bg-zinc-300' />
                    <div>{props.data.totalUnitCount} units</div>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-y-6 border rounded-2xl py-4 px-5 h-full text-lg bg-white'>
                <div className='font-semibold'>Emissions breakdown</div>
                <div className='grid grid-cols-3 gap-x-4 h-full'>
                  {props.data.analysis.scopeEmissions.map((item, index) => (
                    <div
                      key={index}
                      className='flex flex-col bg-slate-50 justify-between gap-y-4 rounded-2xl border py-6 h-full text-center'
                    >
                      <div>
                        <div className='font-semibold'>{item.name}</div>
                        <div className='uppercase text-zinc-400 font-normal text-xs'>emissions</div>
                      </div>
                      <div className='flex justify-center items-center gap-x-2 px-2'>
                        <div className='flex justify-center items-center gap-1'>
                          <div title={roundToLong(item.totalEmission.value)} className='text-2xl font-normal'>
                            {simplify(item.totalEmission.value)}
                          </div>
                          <div className='text-zinc-500 text-xs uppercase pt-1 font-normal'>{item.totalEmission.unit}</div>
                        </div>
                        <div className='h-full w-[1px] bg-zinc-200' />
                        <div title={item.totalEmission.sharePercent.toString()} className='text-xl'>
                          {item.totalEmission.sharePercent.toFixed(2)}%
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='flex self-end text-sm text-zinc-500'>Methodology Version: V.{props.data.metadata.methodologyVersion}</div>
          </div>
          <div className='mx-6'>
            <GhgProtocol analysis={props.data.analysis} reportType={ReportType.Baseline} id={props.data.id} />
          </div>
        </div>
      )}
    </Overview>
  );
};
