export const ManufacturingSkeletonLoader = () => {
  const skeleton = (height?: string, width?: string, borderRadius?: string) => {
    return (
      <div
        className='loading-skeleton'
        style={{
          height: height,
          width: width,
          borderRadius: borderRadius,
        }}
      />
    );
  };

  return (
    <div className='flex flex-col gap-6 mb-20 leading-3'>
      <div className='flex w-full items-center justify-between h-16 px-6 border-b border-b-zinc-200'>
        <div className='flex w-full items-center gap-6'>
          <div className='flex flex-col gap-2 w-full'>{skeleton('26px', '35%')}</div>
        </div>
        {skeleton('35px', '17%', '18px')}
      </div>
      <div className='px-6'>
        {skeleton('702px')}
        <div className='border-t border-neutral-200'>
          {new Array(4).fill(null).map((_, i) => (
            <div key={i} className='border-neutral-400/50 break-inside-avoid'>
              <div className='w-full border-b border-neutral-400/50 flex justify-between items-center px-6 py-4'>
                <div className='flex items-center w-full gap-8'>
                  {skeleton('40px', '40px')}
                  <div className='flex flex-col gap-3 flex-1'>
                    {skeleton('38px', '200px')}
                    {skeleton('24px', '80%')}
                  </div>
                </div>
                {skeleton('20px', '20px')}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
