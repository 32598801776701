export const getImprovementTipsIcons = (iconId: string) => {
  switch (iconId) {
    case 'tip-transport':
      return (
        <svg viewBox='0 0 176 141' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g>
            <path
              d='M101.2 0C108.488 0 114.4 5.90975 114.4 13.2V26.4H128.342C133.018 26.4 137.253 28.2425 140.8 31.5425L162.057 52.8C165.357 56.1 167.2 60.5825 167.2 65.2575V96.8C172.068 96.8 176 100.732 176 105.6C176 110.468 172.068 114.4 167.2 114.4H158.098C155.953 99.4675 143.11 88 127.6 88C112.09 88 99.2475 99.4675 97.1025 114.4H78.8975C76.7525 99.4675 63.91 88 48.4 88C32.89 88 20.0475 99.4675 17.9107 114.4H13.2C5.90975 114.4 0 108.488 0 101.2V13.2C0 5.90975 5.90975 0 13.2 0H101.2ZM114.4 44V70.4H149.6V65.2575L128.342 44H114.4Z'
              fill='#3D3D3D'
            />
            <path
              opacity='0.4'
              d='M26.4004 118.8C26.4004 106.645 36.2454 96.7998 48.4004 96.7998C60.5554 96.7998 70.4004 106.645 70.4004 118.8C70.4004 130.955 60.5554 140.8 48.4004 140.8C36.2454 140.8 26.4004 130.955 26.4004 118.8ZM105.6 118.8C105.6 106.645 115.445 96.7998 127.6 96.7998C139.755 96.7998 149.6 106.645 149.6 118.8C149.6 130.955 139.755 140.8 127.6 140.8C115.445 140.8 105.6 130.955 105.6 118.8Z'
              fill='#3D3D3D'
            />
          </g>
        </svg>
      );
    case 'tip-packaging-production':
      return (
        <svg viewBox='0 0 194 156' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M122.279 61.26C124.522 65.2916 129.039 67.0497 133.252 65.8675L174.598 54.0456V114.731C174.598 121.4 170.052 127.22 163.565 128.857L101.697 144.316C98.605 145.104 95.3919 145.104 92.3 144.316L30.4322 128.857C23.9423 127.22 19.3984 121.4 19.3984 114.731V54.0456L60.7447 65.8675C64.9581 67.0497 69.4747 65.2916 71.7178 61.26L96.7256 19.3984L122.279 61.26Z'
            fill='#3D3D3D'
          />
          <path
            opacity='0.4'
            d='M5.22348 38.0432L17.8638 12.7686C18.7823 10.9317 20.7617 9.87073 22.8017 10.1254L96.9976 19.1282L71.717 61.2625C69.4739 65.2941 64.9573 67.0522 60.7439 65.87L11.2344 51.7141C5.39626 50.0469 2.50839 43.4691 5.22348 38.0432ZM182.752 51.7141L133.251 65.87C129.038 67.0522 124.521 65.2941 122.278 61.2625L96.9976 19.1282L171.203 10.1254C173.234 9.87073 175.204 10.9317 176.144 12.7686L188.784 38.0432C191.482 43.4691 188.602 50.0469 182.752 51.7141Z'
            fill='#3D3D3D'
          />
        </svg>
      );
    case 'tip-ingredient-production':
      return (
        <svg viewBox='0 0 136 136' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g>
            <path
              d='M135.287 40.1629C124.287 53.4405 106.882 54.035 95.1504 42.2438L93.0366 40.1299C81.252 28.4047 81.8333 11.0316 94.8862 0C105.389 8.68788 108.032 21.4026 102.879 32.1832C113.647 27.0307 126.594 29.673 135.287 40.1629Z'
              fill='#3D3D3D'
            />
            <path
              opacity='0.4'
              d='M78.7942 41.3792C64.8693 34.5885 48.5399 38.6048 38.8691 50.0459L53.4731 64.6605C55.1245 66.3119 55.1245 68.9753 53.4731 70.6268C52.6468 71.4195 51.5532 71.8436 50.4635 71.8436C49.3738 71.8436 48.3317 71.4195 47.5068 70.6268L34.43 57.3387L0.582197 126.778C-0.177464 128.364 -0.210492 130.279 0.582197 131.965C2.03546 134.903 5.60257 136.127 8.50909 134.708L43.8102 117.467L30.8365 104.451C29.1515 102.832 29.1515 100.123 30.8365 98.5057C32.488 96.8542 35.162 96.8542 36.7817 98.5057L51.8375 113.561L78.789 100.379C85.1305 97.3061 90.5472 92.1192 93.8844 85.2836C101.862 68.9912 95.0972 49.3589 78.7942 41.3792Z'
              fill='#3D3D3D'
            />
          </g>
        </svg>
      );
    case 'tip-production-step':
      return (
        <svg viewBox='0 0 119 136' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g>
            <path
              d='M8.45536 59.1871C3.78641 59.1871 0 55.4086 0 50.7317C0 46.0549 3.78641 42.2764 8.45536 42.2764H25.3661V76.0978C25.3661 80.7747 29.1446 84.5532 33.8214 84.5532C38.4983 84.5532 42.2768 80.7747 42.2768 76.0978V42.2764H109.92C114.597 42.2764 118.375 46.0549 118.375 50.7317C118.375 55.4086 114.597 59.1871 109.92 59.1871H109.101C114.015 67.6689 118.375 78.5023 118.375 88.3581C118.375 98.2403 115.231 106.696 109.92 113.592V128.944C109.92 132.458 107.092 135.285 103.578 135.285C100.064 135.285 97.2366 132.458 97.2366 128.944V124.98C86.5617 131.718 72.9803 135.285 59.1875 135.285C45.3947 135.285 31.8133 131.718 21.1384 124.98V128.944C21.1384 132.458 18.2979 135.285 14.7969 135.285C11.2958 135.285 8.45536 132.458 8.45536 128.944V113.592C3.15226 106.696 0 98.2403 0 88.3581C0 78.5023 4.34922 67.6689 9.27711 59.1871H8.45536Z'
              fill='#3D3D3D'
            />
            <path
              opacity='0.4'
              d='M50.7323 8.45536C50.7323 13.1243 46.9538 16.9107 42.2769 16.9107C37.6001 16.9107 33.8216 13.1243 33.8216 8.45536C33.8216 3.78641 37.6001 0 42.2769 0C46.9538 0 50.7323 3.78641 50.7323 8.45536ZM42.2769 42.2768V76.0982C42.2769 80.7751 38.4984 84.5536 33.8216 84.5536C29.1447 84.5536 25.3662 80.7751 25.3662 76.0982V42.2768H42.2769ZM59.1876 21.1384C59.1876 14.1337 64.8686 8.45536 71.8707 8.45536C78.8728 8.45536 84.5537 14.1337 84.5537 21.1384C84.5537 28.1405 78.8728 33.8214 71.8707 33.8214C64.8686 33.8214 59.1876 28.1405 59.1876 21.1384Z'
              fill='#3D3D3D'
            />
          </g>
        </svg>
      );
    case 'tip-packaging-step':
      return (
        <svg viewBox='0 0 172 138' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g>
            <path
              d='M77.4012 0H25.8012C21.0524 0 17.2012 3.85119 17.2012 8.6V60.2C17.2012 64.9488 21.0524 68.8 25.8012 68.8H77.4012C82.15 68.8 86.0012 64.9488 86.0012 60.2V8.6C86.0012 3.85119 82.1581 0 77.4012 0ZM146.201 17.2H111.801C107.052 17.2 103.201 21.0512 103.201 25.8V60.2C103.201 64.9488 107.052 68.8 111.801 68.8H146.201C150.95 68.8 154.801 64.9488 154.801 60.2V25.8C154.801 21.0512 150.958 17.2 146.201 17.2Z'
              fill='#3D3D3D'
            />
            <path
              opacity='0.4'
              d='M146.2 86H25.8C11.18 86 0 97.18 0 111.8C0 126.42 11.18 137.6 25.8 137.6H146.2C160.82 137.6 172 126.42 172 111.8C172 97.18 160.82 86 146.2 86ZM34.4 120.4C29.24 120.4 25.8 116.96 25.8 111.8C25.8 106.64 29.24 103.2 34.4 103.2C39.56 103.2 43 106.64 43 111.8C43 116.96 39.56 120.4 34.4 120.4ZM86 120.4C80.84 120.4 77.4 116.96 77.4 111.8C77.4 106.64 80.84 103.2 86 103.2C91.16 103.2 94.6 106.64 94.6 111.8C94.6 116.96 91.16 120.4 86 120.4ZM137.6 120.4C132.44 120.4 129 116.96 129 111.8C129 106.64 132.44 103.2 137.6 103.2C142.76 103.2 146.2 106.64 146.2 111.8C146.2 116.96 142.76 120.4 137.6 120.4Z'
              fill='#3D3D3D'
            />
          </g>
          <defs>
            <clipPath id='clip0_5094_45646'>
              <rect width='172' height='137.6' fill='white' />
            </clipPath>
          </defs>
        </svg>
      );
    case 'tip-preparation-step':
      return (
        <svg viewBox='0 0 151 134' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <g>
            <path
              d='M150.082 114.756C149.078 110.589 146.393 107.041 142.677 104.937L111.245 83.841C114.939 76.1645 116.82 67.5944 116.82 58.5278C116.82 26.2069 90.6135 0 58.5278 0C26.2069 0 0 26.2069 0 58.5278C0 90.8487 26.2069 117.056 58.2926 117.056C72.4778 117.056 85.4662 112.005 95.6041 103.61L124.215 129.519C127.324 132.262 131.139 133.778 134.849 133.778C135.339 133.778 135.821 133.753 136.311 133.708C140.76 133.284 144.802 130.957 147.4 127.32C149.997 123.683 151.127 119.094 150.082 114.756ZM89.4639 69.8414C83.2532 74.5759 76.755 74.0873 71.0564 79.5089C63.5209 86.6446 56.4767 95.1547 44.327 90.7938C36.2141 87.8701 29.8648 79.6918 29.29 71.0956C28.7183 62.4862 34.3694 54.3028 35.8221 45.8947C37.5451 35.8221 40.8153 27.1997 52.1524 25.3707C61.3497 23.8736 66.3663 28.6368 72.8723 33.9931C78.5788 38.6806 86.5898 39.4148 91.7501 44.7398C98.8179 51.3424 96.806 64.2499 89.4639 69.8414ZM134.431 121.524C132.154 121.904 130 120.367 129.618 118.09C129.237 115.813 130.775 113.659 133.051 113.277C135.329 112.897 137.483 114.434 137.864 116.711C138.246 118.989 136.704 121.132 134.431 121.524Z'
              fill='#3D3D3D'
            />
            <path
              opacity='0.4'
              d='M58.528 45.9863C51.604 45.9863 45.9863 51.604 45.9863 58.528C45.9863 65.452 51.604 71.0697 58.528 71.0697C65.452 71.0697 71.0697 65.452 71.0697 58.528C71.0697 51.604 65.452 45.9863 58.528 45.9863Z'
              fill='#3D3D3D'
            />
          </g>
        </svg>
      );
    case 'tip-packaging-disposal':
      return (
        <svg viewBox='0 0 171 152' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M166.25 66.5H160.312L161.5 57H9.5L10.6875 66.5H4.75C2.15234 66.5 0 68.6375 0 71.25V80.75C0 83.3625 2.15234 85.5 4.75 85.5H13.0625L19 133V137.75C19 140.363 21.1523 142.5 23.75 142.5H33.25C35.8625 142.5 38 140.363 38 137.75V133H133V137.75C133 140.348 135.152 142.5 137.75 142.5H147.25C149.848 142.5 152 140.348 152 137.75V133L157.938 85.5H166.25C168.863 85.5 171 83.3625 171 80.75V71.25C171 68.6375 168.863 66.5 166.25 66.5Z'
            fill='#3D3D3D'
          />
          <path
            opacity='0.4'
            d='M7.27379 13.0962L0.148791 41.329C-0.593693 44.5887 1.67028 47.498 4.75035 47.498H33.6214L41.2304 9.49805H11.8754C9.72301 9.49805 7.79332 10.7152 7.27379 13.0962ZM170.852 41.5902L163.727 13.3574C163.222 10.7152 161.293 9.49805 159.125 9.49805H129.77L137.376 47.498H166.25C169.338 47.498 171.327 44.5887 170.852 41.5902ZM43.3144 47.498H80.7504V9.49805H50.9144L43.3144 47.498ZM90.2504 9.49805V47.498H127.686L120.086 9.49805H90.2504Z'
            fill='#3D3D3D'
          />
        </svg>
      );
  }
};
