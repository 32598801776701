import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { ProductV3, applyDefaults } from '../../../../api';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { canApplyDefaults } from './dataModel';

export enum Layout {
  Graph,
  List,
}

interface Props {
  canUndo: boolean;
  canRedo: boolean;
  onUndo: () => void;
  onRedo: () => void;
  onAppliedDefaults: () => void;
  layout: Layout;
  setLayout: (value: Layout) => void;
}

export const SecondaryBarContent = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const [applyingDefaults, setApplyingDefaults] = useState(false);
  const [applyDefaultsError, setApplyDefaultsError] = useState('');
  const applyDefaultsErrrorClearTimeoutId = useRef<number>();

  return (
    <>
      <div className='flex-1 flex items-center gap-4'>
        <div className='flex gap-2'>
          <button
            type='button'
            disabled={!props.canUndo}
            className='flex justify-center items-center w-8 aspect-square rounded bg-[#E8EAF5] text-[#220066] shadow-[0_0_3px_rgba(0,0,0,0.25)] [&:active:not(:disabled)]:scale-90'
            onClick={props.onUndo}
          >
            <FontAwesomeIcon icon={regular('rotate-left')} />
          </button>
          <button
            type='button'
            disabled={!props.canRedo}
            className='flex justify-center items-center w-8 aspect-square rounded bg-[#E8EAF5] text-[#220066] shadow-[0_0_3px_rgba(0,0,0,0.25)] [&:active:not(:disabled)]:scale-90'
            onClick={props.onRedo}
          >
            <FontAwesomeIcon icon={regular('rotate-right')} />
          </button>
        </div>
      </div>
      <button
        type='button'
        className='w-72 grid grid-cols-2 h-8 rounded-full bg-white shadow-[inset_0_0_4px_rgba(0,0,0,0.25)]'
        onClick={() =>
          props.setLayout(
            {
              [Layout.Graph]: Layout.List,
              [Layout.List]: Layout.Graph,
            }[props.layout],
          )
        }
      >
        {[Layout.Graph, Layout.List].map((layout) => (
          <div
            key={layout}
            className={cn(
              'flex gap-2 justify-center items-center rounded-full h-full transition',
              props.layout === layout ? 'bg-[#330099] text-white font-semibold' : 'text-zinc-500',
            )}
          >
            <FontAwesomeIcon
              icon={
                {
                  [Layout.Graph]: regular('diagram-project'),
                  [Layout.List]: regular('list'),
                }[layout]
              }
            />
            {
              {
                [Layout.Graph]: 'Graph view',
                [Layout.List]: 'List view',
              }[layout]
            }
          </div>
        ))}
      </button>
      <div className='flex-1 flex justify-end self-center'>
        <TooltipV3
          placement='left'
          disabled={canApplyDefaults(formik) && !applyDefaultsError}
          parentControl={
            applyDefaultsError
              ? {
                  open: true,
                  onOpenChange: () => {},
                }
              : undefined
          }
          content={
            <div
              className={cn(
                'rounded-lg text-xs text-zinc-800 px-2 py-1 w-[420px] shadow-[0_0_4px_rgba(0,0,0,0.15)]',
                applyDefaultsError ? 'bg-red-50' : 'bg-[#DACEFD]',
              )}
            >
              {applyDefaultsError ||
                'Defaults can only be applied to products with raw materials and one final production facility. Remove anything else to be able to use this feature.'}
            </div>
          }
        >
          <div>
            <button
              type='button'
              className={cn(
                'flex items-center gap-2 px-4 font-semibold disabled:opacity-25',
                applyDefaultsError ? 'text-red-500' : 'text-[#220066]',
                {
                  'disabled:cursor-wait': applyingDefaults,
                },
              )}
              disabled={!canApplyDefaults(formik) || applyingDefaults}
              onClick={() => {
                clearTimeout(applyDefaultsErrrorClearTimeoutId.current);
                setApplyDefaultsError('');
                setApplyingDefaults(true);
                applyDefaults(formik.values.id).call({
                  ok: ({ product, error }) => {
                    setApplyingDefaults(false);

                    if (error) {
                      setApplyDefaultsError(error.message);
                      applyDefaultsErrrorClearTimeoutId.current = window.setTimeout(() => setApplyDefaultsError(''), 7_000);
                    } else {
                      formik.setValues(product);
                      props.onAppliedDefaults();
                    }
                  },
                  fail: () => {
                    setApplyingDefaults(false);
                  },
                });
              }}
            >
              <FontAwesomeIcon icon={regular('clone')} />
              Apply defaults
            </button>
          </div>
        </TooltipV3>
      </div>
    </>
  );
};
