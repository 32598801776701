import { MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { TargetType, TargetImpact, updateManufacturingTarget } from '../../../../api';
import { Field, FieldProps, useFormikContext } from 'formik';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { YearPicker } from '../../../../components/datepicker/YearPicker';
import { useControlEvents } from '../../../../hooks/useControlEventsV2';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { targetValidationSchema } from './Main';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { addYears, format, startOfYear } from 'date-fns';
import cn from 'classnames';

interface Props {
  submitForm: () => void;
  targets: { targetImpacts: TargetImpact[] };
  targetModel: FieldProps<TargetImpact>;
  setTargets: (targetImpacts: SetStateAction<{ targetImpacts: TargetImpact[] }>) => void;
  previousFormSnapshot: MutableRefObject<{ targetImpacts: TargetImpact[] } | undefined>;
}

export const TargetTile = (props: Props) => {
  const formik = useFormikContext<{ targetImpacts: TargetImpact[] }>();
  const [fetching, setFetching] = useState(false);
  const modalRef = useRef<ModalApi>(null);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const debouncedUpdateTargetRef = useRef<
    | ({
        cancel: () => void;
      } & (() => void))
    | null
  >(null);

  useEffect(() => {
    (async () => {
      setHasValidationErrors(!(await targetValidationSchema.isValid(props.targetModel.field.value)));
    })();
  }, [props.targetModel.field.value]);

  useEffect(() => {
    const payload = {
      ...props.targetModel.field.value,
      reduction: props.targetModel.field.value.reduction === null ? 0 : props.targetModel.field.value.reduction!,
      type: props.targetModel.field.value.internalType,
    };

    if (!isEqual(props.targetModel.meta.initialValue, props.targetModel.field.value)) {
      if (debouncedUpdateTargetRef.current && debouncedUpdateTargetRef.current.cancel) {
        debouncedUpdateTargetRef.current.cancel();
      }

      setFetching(true);

      (async () => {
        await props.targetModel.form.validateForm(props);
      })();

      (async () => {
        debouncedUpdateTargetRef.current = debounce(async () => {
          if (await targetValidationSchema.isValid(payload)) {
            updateManufacturingTarget(payload).call({
              ok: (data) => {
                delete data.method;
                props.setTargets({
                  targetImpacts: formik.values.targetImpacts.map((item) => {
                    if (item.id === data.id) {
                      return {
                        ...data,
                        reduction: data.reduction === null ? 0 : data.reduction!,
                        status: data.type === 'disabled' ? 'disabled' : 'updated',
                        internalType: data.type,
                      };
                    } else {
                      return {
                        ...item,
                        reduction: item.reduction === 0 ? null : item.reduction!,
                      };
                    }
                  }),
                });

                setFetching(false);
              },
            });
          }
        }, 500);

        debouncedUpdateTargetRef.current();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.targetModel.field.value]);

  return (
    <>
      <div
        className={cn(
          'flex items-center self-center truncate',
          props.targetModel.field.value.status === 'idle' ? 'font-semibold pl-3' : 'flex self-start',
        )}
      >
        <TooltipV3
          content={
            <div className='p-3 text-white bg-[#330099] rounded-lg text-xs whitespace-normal'>
              {props.targetModel.field.value.description}
            </div>
          }
        >
          <div
            title={props.targetModel.field.value.name}
            className={cn('truncate', { 'bg-neutral-50 px-4 py-2 rounded-2xl': props.targetModel.field.value.status !== 'idle' })}
          >
            {props.targetModel.field.value.name}
          </div>
        </TooltipV3>
      </div>
      {props.targetModel.field.value.status === 'idle' ? (
        props.targetModel.field.value.type !== TargetType.DISABLED ? (
          <>
            <div className='flex gap-x-2 items-center'>
              <div>
                - <span className='font-semibold'>{props.targetModel.field.value.reduction}</span> %
              </div>
              <div className='text-sm'>
                {
                  {
                    [TargetType.LONG_TERM]: 'by',
                    [TargetType.YEAR_OVER_YEAR]: 'per year',
                    [TargetType.DISABLED]: '',
                  }[props.targetModel.field.value.type]
                }
              </div>
              <div className='font-semibold'>
                {props.targetModel.field.value.target && format(new Date(props.targetModel.field.value.target), 'yyyy')}
              </div>
            </div>
            <div className='col-span-2 text-zinc-500 text-sm ml-6'>
              {props.targetModel.field.value.type === TargetType.LONG_TERM && (
                <>Required annual reduction: {props.targetModel.field.value.estimate}</>
              )}
              {props.targetModel.field.value.type === TargetType.YEAR_OVER_YEAR && (
                <>Estimated long-term reduction: {props.targetModel.field.value.estimate}</>
              )}
            </div>

            <ModalV3
              size='narrow-'
              title='Are you sure?'
              onConfirm={() => {
                props.targetModel.form.setFieldValue(`${props.targetModel.field.name}`, {
                  ...props.targetModel.meta.initialValue,
                  type: TargetType.DISABLED,
                  target: undefined,
                  reduction: 0,
                });

                props.submitForm();
              }}
              body={
                <div className='-mt-6 text-base'>
                  This will reset the target for everyone in the workspace. This category will have no more target unless you set a new one.
                </div>
              }
            >
              <button type='button' className='flex justify-self-center gap-0.5 hover:underline text-brand'>
                Reset
              </button>
            </ModalV3>
          </>
        ) : (
          <div className='col-span-4'></div>
        )
      ) : (
        <>
          <div className='px-2 rounded-full'>
            <select
              className='bg-neutral-50 py-1.5 rounded-full focus-visible:ring-0 text-sm pl-2'
              value={props.targetModel.field.value.internalType}
              onChange={(event) => {
                props.targetModel.form.setFieldValue(`${props.targetModel.field.name}.internalType`, event.target.value);
              }}
            >
              {[
                {
                  label: 'Target year',
                  value: TargetType.LONG_TERM,
                },
                {
                  label: 'Year over year',
                  value: TargetType.YEAR_OVER_YEAR,
                },
              ].map((item, i) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <div className='flex items-center gap-x-3'>
            <Field name={`${props.targetModel.field.name}.reduction`}>
              {(model: FieldProps<number>) => <Input placeholder='…' model={model} />}
            </Field>
            {props.targetModel.field.value.internalType === TargetType.LONG_TERM && <div className='text-sm'>by</div>}
            {props.targetModel.field.value.internalType === TargetType.LONG_TERM && (
              <Field name={`${props.targetModel.field.name}.target`}>
                {(model: FieldProps<string>) => (
                  <YearPicker
                    styles='bg-neutral-50'
                    placeholder='year...'
                    minDate={startOfYear(addYears(new Date(), 1))}
                    inputWidth='w-20'
                    textSize='text-base'
                    model={model}
                  />
                )}
              </Field>
            )}
            <Field name={`${props.targetModel.field.name}`}>
              {(model: FieldProps<TargetImpact>) => {
                return model.field.value.internalType === TargetType.YEAR_OVER_YEAR ? <div className='text-sm'>per year</div> : <></>;
              }}
            </Field>
          </div>
          <div className='text-zinc-500 text-sm'>
            {props.targetModel.field.value.internalType === TargetType.LONG_TERM && (
              <>Required annual reduction: {!fetching && <>{props.targetModel.field.value.estimate ?? ''}</>}</>
            )}
            {props.targetModel.field.value.internalType === TargetType.YEAR_OVER_YEAR && (
              <>Estimated long-term reduction: {!fetching && <>{props.targetModel.field.value.estimate ?? ''}</>}</>
            )}
          </div>
          <div className='flex gap-x-2 items-center justify-self-center'>
            <ModalV3
              ref={modalRef}
              size='narrow-'
              title='Are you sure?'
              onConfirm={async () => {
                await Promise.all(
                  formik.values.targetImpacts.map(async (item, i) => {
                    if (!(await targetValidationSchema.isValid(item))) {
                      formik.setFieldValue(
                        `targetImpacts.${i}`,
                        props.previousFormSnapshot.current?.targetImpacts.find((prevItem) => prevItem.id === item.id),
                      );
                    }
                  }),
                );

                props.targetModel.field.value.status = 'idle';
                props.submitForm();
              }}
              body={
                <div className='-mt-6 text-base'>
                  This target will apply to the entire workspace. Every user within the workspace will share this target you are about to
                  set.
                </div>
              }
            />
            {!hasValidationErrors && (
              <button
                disabled={hasValidationErrors || props.targetModel.field.value.status === 'disabled'}
                onClick={() => modalRef.current?.open()}
                className={cn(
                  'active:scale-95 disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-zinc-300 flex self-end px-4 py-1.5 bg-slate-200 rounded-full whitespace-nowrap text-violet-900 text-base font-semibold',
                )}
              >
                Set target
              </button>
            )}
            {hasValidationErrors && (
              <button
                type='button'
                className='flex justify-self-center gap-0.5 hover:underline text-brand'
                onClick={() => {
                  props.targetModel.form.setFieldValue(
                    `${props.targetModel.field.name}`,
                    props.previousFormSnapshot.current?.targetImpacts.find((prevItem) => prevItem.id === props.targetModel.field.value.id),
                  );
                }}
              >
                Reset
              </button>
            )}
          </div>
        </>
      )}
    </>
  );
};

interface InputProps {
  model: FieldProps;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

export const Input = (props: InputProps) => {
  const controlEvents = useControlEvents();

  return (
    <div
      className={cn(
        'relative flex items-center gap-x-1 bg-neutral-50 rounded-full border',
        props.model.meta.error ? 'border-f' : 'border-transparent',
      )}
    >
      <div className='absolute left-0 translate-x-full'>-</div>
      <input
        disabled={props.disabled}
        type='number'
        min={0.01}
        step={0.01}
        autoComplete='off'
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        className={cn(
          'remove-default-spin flex self-center py-1 text-center w-20 rounded-full bg-neutral-50',
          props.model.meta.error && 'border-f focus:ring-focusRingError',
        )}
        {...props.model.field}
        value={props.model.field.value ?? ''}
        onChange={(event) => {
          if (event.target.value === '') {
            props.model.form.setFieldValue(props.model.field.name, null);
          } else {
            props.model.field.onChange(event);
          }

          if (controlEvents) {
            controlEvents.emitTouchedInput(props.model.field.name);
          }
        }}
      />
      <div className='absolute right-0.5'>%</div>
    </div>
  );
};
