import { SetStateAction, useEffect, useState } from 'react';
import { TimeframeDatePicker } from '../../../../../../components/datepicker/TimeframeDatePicker';
import { MonthPicker } from '../../../../../../components/datepicker/MonthPicker';
import { Field, FieldProps, Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { convertLocalToUTCDate } from '../../../../../../components/datepicker/utils';
import sub from 'date-fns/sub';
import isValid from 'date-fns/isValid';
import isSameDay from 'date-fns/isSameDay';
import add from 'date-fns/add';
import { format } from 'date-fns';
import startOfMonth from 'date-fns/startOfMonth';
import isBefore from 'date-fns/isBefore';
import endOfMonth from 'date-fns/endOfMonth';
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth';
import isLastDayOfMonth from 'date-fns/isLastDayOfMonth';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import cn from 'classnames';

interface Props {
  step: number;
  onNext: () => void;
  dateRange: { start: Date | null; end: Date | null };
  setDateRange: (value: { start: Date | null; end: Date | null }) => void;
  setReportTitle: (value: SetStateAction<string>) => void;
  existedReports: { reportName: string; dateRange: { start: Date; end: Date } }[] | null;
}

export const Step2 = (props: Props) => {
  //const ctx = useContext(ManufacturingContextV3);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  return (
    <div className='flex flex-col gap-y-4 mb-20'>
      <Formik<{
        range: {
          startDate: Date | null;
          endDate: Date | null;
        };
      }>
        validateOnBlur={triedToSubmit}
        validateOnChange={triedToSubmit}
        initialValues={{
          range: {
            startDate: props.dateRange.start,
            endDate: props.dateRange.end,
          },
        }}
        validationSchema={yup.object().shape({
          range: yup
            .object()
            .test('endDateInPast', 'Dates must be in past and minimum date range must be at least 1 month', (value) => {
              return value.startDate <= sub(new Date(), { months: 1, days: -1 });
            })
            .test('isOverlapping', 'Sorry, you’ve already uploaded data for these dates', (value) => {
              return !props.existedReports?.find(({ dateRange }) => {
                return (
                  (isValid(value.startDate) &&
                    isValid(value.endDate) &&
                    areIntervalsOverlapping(
                      { start: convertLocalToUTCDate(value.startDate)!, end: convertLocalToUTCDate(value.endDate)! },
                      { start: new Date(dateRange.start), end: new Date(dateRange.end) },
                    )) ||
                  isSameDay(new Date(dateRange.end), value.startDate) ||
                  isSameDay(new Date(dateRange.start), value.endDate)
                );
              });
            })
            .test('isLessThenMonth', 'Minimum date range is one month', (value) => {
              return value.endDate >= sub(add(value.startDate, { months: 1 }), { days: 1 });
            })
            .shape({
              startDate: yup.date().required(),
              endDate: yup.date(),
            }),
        })}
        onSubmit={(data) => {
          props.setReportTitle((current) => {
            if (!current) {
              return `Historical Volume Report ${format(data.range.startDate!, 'dd/MM/yy')} - ${format(data.range.endDate!, 'dd/MM/yy')}`;
            }
            return current;
          });
          props.setDateRange({ start: data.range.startDate, end: data.range.endDate });
          props.onNext();
        }}
        children={<Content existedReports={props.existedReports} setTriedSubmit={setTriedToSubmit} />}
      />
    </div>
  );
};

interface ContentProps {
  setTriedSubmit: (v: SetStateAction<boolean>) => void;
  existedReports: { reportName: string; dateRange: { start: Date; end: Date } }[] | null;
}

const Content = (props: ContentProps) => {
  const [defaultTimeframe, setDefaultTimeframe] = useState<{ startDate: Date; endDate: Date } | null>(null);
  const [checked, setChecked] = useState(false);
  const [showWarning1, setShowWarning1] = useState(false);
  const [showWarning2, setShowWarning2] = useState(false);
  const formik = useFormikContext<{ range: { startDate: Date | null; endDate: Date | null } }>();

  useEffect(() => {
    formik.setFieldValue('range', {
      startDate: defaultTimeframe?.startDate,
      endDate: defaultTimeframe?.endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, defaultTimeframe]);

  useEffect(() => {
    if (props.existedReports) {
      const latestReport = props.existedReports?.sort(
        (a, b) => new Date(b.dateRange.start).getTime() - new Date(a.dateRange.start).getTime(),
      )[0];

      if (latestReport) {
        const defaultStartDate = convertLocalToUTCDate(startOfMonth(add(new Date(latestReport.dateRange.end), { months: 1 })))!;

        if (isBefore(endOfMonth(defaultStartDate), new Date())) {
          setDefaultTimeframe({
            startDate: defaultStartDate,
            endDate: endOfMonth(defaultStartDate),
          });
        }
      }
    }
  }, [props.existedReports]);

  return (
    <div className='flex flex-col gap-y-4'>
      <div className='text-lg font-semibold'>Select the time period of your historical assessment</div>
      <div>
        Which time period do you want to assess? This will depend on the actual volume data you have available. And keep in mind that you
        need at least one month worth of actual data to run an analysis.
      </div>

      <div className='flex flex-col gap-y-2'>
        <div className='flex flex-col items-start gap-2'>
          <Field name='range'>
            {(model: FieldProps<{ startDate: Date | null; endDate: Date | null }>) => (
              <div className='flex items-center gap-x-2'>
                <div className='text-sm'>From/To</div>
                <div className='flex flex-col'>
                  <MonthPicker
                    disabled={checked}
                    existedReports={props.existedReports}
                    model={!checked ? model : { ...model, field: { ...model.field, value: undefined } }}
                  />
                  {!checked && (
                    <div className='relative'>
                      {model.meta.error && (
                        <div className='absolute top-1.5 left-2 text-f text-xs w-96'>
                          {typeof model.meta.error === 'string' ? model.meta.error : 'Field is required'}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </Field>
        </div>

        <div className='flex gap-x-2 items-center'>
          I want to specify a starting date
          <div className='relative flex items-center gap-4 mt-0.5'>
            <input
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              className='opacity-0 absolute h-4 w-7 z-10 hover:cursor-pointer'
              type='checkbox'
            />
            <div
              className={cn(
                'px-0.5 outline flex items-center rounded-full relative h-4 w-7',
                checked ? 'bg-brand outline-brand' : 'bg-zinc-300 outline-zinc-300',
              )}
            >
              <div
                className={cn('absolute rounded-full size-3 transition-[left] duration-25', {
                  'left-3.5 bg-white': checked,
                  'left-0.5 bg-white': !checked,
                })}
              />
            </div>
          </div>
          <FontAwesomeIcon
            onMouseEnter={() => setShowWarning1(true)}
            onMouseLeave={() => setShowWarning1(false)}
            className={cn('text-xl text-amber-400', {
              hidden:
                formik.values.range.startDate &&
                isFirstDayOfMonth(formik.values.range.startDate) &&
                formik.values.range.endDate &&
                isLastDayOfMonth(formik.values.range.endDate) &&
                checked,
            })}
            icon={regular('exclamation-triangle')}
          />
          <div className={cn('w-72 border border-amber-400 rounded-xl bg-amber-50 py-2 pl-2.5 text-sm', { invisible: !showWarning1 })}>
            By choosing this option, we won't be able to compare your progress year by year against this baseline.
          </div>
        </div>

        {checked && (
          <Field name='range'>
            {(model: FieldProps<{ startDate: Date | null; endDate: Date | null }>) => (
              <div className='flex items-center gap-x-2 mb-6'>
                <div className='text-sm'>From/To</div>
                <div className='flex flex-col'>
                  <TimeframeDatePicker model={model} existedReports={props.existedReports} openToDate={null} />
                  {model.meta.error && (
                    <div className='relative'>
                      <div className='absolute top-1.5 left-2 text-f text-xs w-full'>
                        {typeof model.meta.error === 'string' ? model.meta.error : 'Field is required'}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Field>
        )}
        <div className='h-[1px] bg-slate-200 w-full' />
      </div>

      <div className='flex justify-between'>
        <div className='flex gap-x-3'>
          <div className='flex flex-col gap-y-2'>
            <div className='text-sm'>Selected time period</div>
            {formik.values.range.startDate && formik.values.range.endDate && (
              <div className='text-xl'>
                {format(new Date(formik.values.range.startDate), 'dd MMMM yyyy')} - {format(formik.values.range.endDate, 'dd MMMM yyyy')}
              </div>
            )}
          </div>

          {formik.values.range.startDate && formik.values.range.endDate && checked && (
            <>
              <FontAwesomeIcon
                onMouseEnter={() => setShowWarning2(true)}
                onMouseLeave={() => setShowWarning2(false)}
                className={cn('text-xl text-amber-400 self-center mt-1', {
                  hidden:
                    formik.values.range.startDate &&
                    isFirstDayOfMonth(formik.values.range.startDate) &&
                    formik.values.range.endDate &&
                    isLastDayOfMonth(formik.values.range.endDate),
                })}
                icon={regular('exclamation-triangle')}
              />
              <div
                className={cn('w-72 border border-amber-400 rounded-xl bg-amber-50 py-2 pl-2.5 text-sm', {
                  invisible: !showWarning2,
                })}
              >
                By choosing this option, we won't be able to compare your progress year by year against this baseline.
              </div>
            </>
          )}
        </div>

        <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
          <div className='px-12 py-4 flex justify-end w-full max-w-screen-xl'>
            <button
              type='button'
              className='flex self-start text-lg shadow-md items-center gap-2.5 border-2 border-brand bg-brand rounded-full px-4 py-1 text-white font-semibold active:scale-95'
              onClick={async () => {
                props.setTriedSubmit(true);
                await formik.submitForm();
              }}
            >
              Next
              <FontAwesomeIcon className='text-base' icon={solid('chevron-right')} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
