import { useEffect, useLayoutEffect, useReducer, useState } from 'react';

export const useDynamicHeight = (container?: HTMLElement | null, trigger?: any, bottomOffset?: boolean) => {
  const [height, setHeight] = useState<number>();
  const [render, reRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    window.addEventListener('resize', () => {
      reRender();
    });
    return () => window.removeEventListener('resize', reRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (container) {
      setHeight(window.innerHeight - (container.getBoundingClientRect().top + (bottomOffset ? 73 : 0)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render, trigger, container]);

  return height;
};
