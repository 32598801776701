import cn from 'classnames';
import { FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  ConsumptionNode,
  ConsumptionStepNode,
  Facility,
  FacilityType,
  NodeType,
  ProductState,
  ProductV3,
  facilityOptions,
  getFacilities,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { NewFacilityForm } from '../../Manage/Facilities/NewFacilityForm';
import { DefaultBadge } from './Badges';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { defaultGrids, getConsumptionLocations, newNodeId } from './dataModel';

type Props = PropsWithChildren<{
  data?: ConsumptionNode;
  onSave: ModalFormSaveCallback<ConsumptionNode[]>;
  onOpenChange?: (open: boolean) => void;
}>;

const toNode = (facility: Facility): ConsumptionNode => ({
  id: newNodeId(),
  displayName: '',
  type: NodeType.Consumption,
  flagged: false,
  edges: new Array<string>(),
  steps: new Array<ConsumptionStepNode>(),
  layoutGrid: defaultGrids.consumptionLocation,
  facility,
});

export const ConsumptionLocationDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm<ConsumptionNode | { facility: Facility[] }>
      size='narrow'
      formRef={formRef}
      onOpenChange={props.onOpenChange}
      title={props.data ? `Editing ${props.data.displayName}` : 'Add consumption locations'}
      body={<Body productFormik={formik} formRef={formRef} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>
            Where are the consumers of your product located? This can be a whole country, a city or an even smaller area you want to analyse
            impact for.
          </div>
          <div>
            Consumption location is important for transportation estimates, the impact of any preparation required by the consumer and also
            for the recyclability of packaging which differs between countries and areas.
          </div>
          <div>
            You can add one or many for this single, it all depends on the granularity of your assessment and the insights you want to get
            out of it!
          </div>
        </div>
      }
      emptyData={{
        facility: new Array<Facility>(),
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        facility: props.data ? yup.object().required() : yup.array().min(1),
      })}
      entityName={props.data ? 'location' : undefined}
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        if (props.data) {
          props.onSave({ values: [values as ConsumptionNode], ...rest });
        } else {
          props.onSave({ values: (values.facility as Facility[]).map(toNode), ...rest });
        }
      }}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement>; edit: boolean }) => {
  const formik = useFormikContext<ConsumptionNode>();
  const [newFacilityForm, setNewFacilityForm] = useState(false);
  const [newFacilityName, setNewFacilityName] = useState('');

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='flex flex-col gap-6'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>
            Select {props.edit ? 'a ' : ''}consumption location{props.edit ? '' : 's'}
          </div>
          <div className={cn({ 'w-2/3': props.edit })}>
            <TaggableField name='facility'>
              {(model: FieldProps<Facility>) => (
                <SelectV3<Facility>
                  autoFocus
                  multi={!props.edit}
                  model={model}
                  menuPortalTarget={props.formRef.current}
                  loadOptions={(input, callback) => {
                    setNewFacilityName(input);
                    getFacilities({
                      contains: input,
                      type: FacilityType.Consumption,
                    }).ok(({ facilities }) =>
                      callback(
                        facilities.filter(
                          ({ id }) =>
                            !getConsumptionLocations(props.productFormik)
                              .filter(({ id }) => id !== formik.values.id)
                              .some(({ facility }) => id === facility.id),
                        ),
                      ),
                    );
                  }}
                  renderOptionBadge={(facility) => (facility.default ? <DefaultBadge /> : <></>)}
                  menuFooter={
                    !newFacilityForm && (
                      <SelectFooterAddButton
                        onClick={() => setNewFacilityForm(true)}
                        name={newFacilityName}
                        label={facilityOptions.find(({ id }) => id === FacilityType.Consumption)!.name.toLowerCase()}
                      />
                    )
                  }
                />
              )}
            </TaggableField>
          </div>
        </div>
        {newFacilityForm && (
          <NewFacilityForm
            name={newFacilityName}
            type={FacilityType.Consumption}
            formRef={props.formRef}
            onCancel={() => setNewFacilityForm(false)}
            onCreated={(newFacility) => {
              formik.setFieldValue('facility', props.edit ? newFacility : [...(formik.values.facility as any), newFacility]);
              setNewFacilityForm(false);
            }}
          />
        )}
      </div>
    </TaggableFieldsContainer>
  );
};
