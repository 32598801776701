export const Step2Skeleton = ({ height }: { height?: number }) => {
  const skeleton = (height?: number | string, width?: number | string) => (
    <div
      className='loading-skeleton'
      style={{
        height,
        width,
      }}
    ></div>
  );

  return <div>{skeleton(height, '100%')}</div>;
};
