import { Fragment, ReactNode, useState } from 'react';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ProductV3 } from '../../../../api';

interface Props {
  data: ProductV3;
  fields?: { name: string | ReactNode; value: string | ReactNode }[];
  title?: string;
}

export const BasicDetails = (props: Props) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <div className='grid grid-cols-3 gap-4 w-full border rounded-regular shadow-regular print:shadow-none print:flex print:flex-col'>
      <div className='col-span-2 '>
        <div className='grid grid-cols-2 gap-y-3 p-8'>
          {props.title && <div className='col-span-2 text-2xl font-semibold mb-3'>{props.title}</div>}
          {props.fields?.map((item, i) => (
            <Fragment key={i}>
              <div className='font-semibold'>{item.name}</div>
              <div title={typeof item.value === 'string' ? item.value : undefined} className='truncate'>
                {item.value}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <div className='flex items-center justify-center print:justify-center w-full rounded-r-regular print:rounded-none overflow-hidden p-2'>
        {props.data.imageUrl ? (
          <img
            className={cn('max-h-72 opacity-0 transition-opacity duration-300 print:max-h-40 rounded-2xl', {
              'opacity-100': showImage,
            })}
            src={props.data.imageUrl}
            alt='Product'
            onLoad={() => setShowImage(true)}
          />
        ) : (
          <FontAwesomeIcon size='5x' className='text-neutral-400' icon={duotone('box-circle-check')} />
        )}
      </div>
    </div>
  );
};
