import { FieldProps, FormikContextType, useFormikContext } from 'formik';
import { Fragment, PropsWithChildren, RefObject, useRef, useState } from 'react';
import * as yup from 'yup';
import { DisposalNode, Entity, ImpactDelta, ModellingPayload, ProductModelV3 } from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectV3 } from '../../../../components/SelectV3';
import { useLists } from '../../../../hooks/useLists';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { OriginalAwareField } from './OriginalAwareField';
import { getConsumptionLocations, getPackagings } from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';
import { OriginalAwareProvider } from './useOriginalAware';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data: DisposalNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<DisposalNode>;
}>;

export const DisposalDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm
      size='narrow'
      formRef={formRef}
      title={`${props.data.displayName} disposal (${
        getConsumptionLocations(formik).find(({ edges }) => edges.includes(props.data.id))?.displayName
      })`}
      body={
        <Body
          payload={props.payload}
          productFormik={formik}
          formRef={formRef}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>What happens when the consumer throws away your product packaging?</div>
          <div>
            Can the materials this packaging is made of be recycled? If not,{' '}
            <a className='underline hover:text-brand' href='mailto:impact@sustained.com?subject=Sustained Impact: Packaging recyclability'>
              let us know
            </a>
            , otherwise we’ll assume the country’s default recyclability rate for the given packaging material(s).
          </div>
        </div>
      }
      data={props.data}
      validationSchema={yup.object().shape({
        disposalType: yup.object().required(),
      })}
      onSave={props.onSave}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const lists = useLists();
  const { payload, productFormik } = props;
  const formik = useFormikContext<DisposalNode>();

  useInteractiveImpact<DisposalNode>({
    payload,
    productFormik,
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  return (
    <OriginalAwareProvider nodeId={formik.values.id} payload={payload}>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col p-4 rounded-xl bg-[#F5F7FA]'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Composition</div>
            <div className='self-start grid grid-cols-[max-content_max-content] gap-x-4 gap-y-2 px-3 py-2 bg-white border border-zinc-300 rounded-xl'>
              {getPackagings(props.productFormik)
                .find(({ id }) => id === formik.values.packagingNodeId)!
                .materials.map((material) => (
                  <Fragment key={material.id}>
                    <div>{material.compositionPercent}%</div>
                    <div>
                      {material.name} ({material.subType.name})
                    </div>
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
        <div className='flex flex-col p-4 rounded-xl bg-[#F5F7FA]'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Recyclability</div>
            <div className='w-1/2'>
              <OriginalAwareField name='disposalType'>
                {(model: FieldProps<Entity>) => (
                  <SelectV3 autoFocus model={model} menuPortalTarget={props.formRef.current} options={lists.disposalTypes} />
                )}
              </OriginalAwareField>
            </div>
          </div>
        </div>
      </div>
    </OriginalAwareProvider>
  );
};
