import InfiniteScroll from '../../../../../components/InfiniteScroll';
import { ModalV3 } from '../../../../../components/ModalV3';
import { InputV3 } from '../../../../../components/InputV3';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../../index';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ManufacturingCycle, ProductModel, ProductWithAmount } from '../../../../../api';
import { simplify } from '../../../shared';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { Fragment, useState } from 'react';
import cn from 'classnames';
import { useDynamicHeight } from '../../../../../hooks/useDynamicHeight';

export const SetProductsCount = () => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const formik = useFormikContext<{
    cycle: ManufacturingCycle;
    products: (ProductWithAmount & { models: ProductModel[] })[];
  }>();

  const height = useDynamicHeight(containerRef, formik.values.products, true);

  return (
    <div ref={setContainerRef}>
      <FieldArray
        name='products'
        render={(arrayModel) => {
          return (
            <InfiniteScroll
              height={height}
              next={() => {}}
              hasMore={false}
              loader={<></>}
              dataLength={arrayModel.form.values.products.length}
            >
              <div className='text-sm grid grid-cols-[5fr_2fr_4fr_4fr_4fr_1fr_1fr] gap-x-4'>
                <div className='px-2 sticky top-0 bg-white capitalize font-semibold col-span-7 grid grid-cols-subgrid items-center h-14 border-y'>
                  <div>name</div>
                  <div>net amount</div>
                  <div>id</div>
                  <div>total impact (per unit net amount)</div>
                  <div>number of units manufactured</div>
                  <div className='text-center'>view</div>
                  <div />
                </div>

                {formik.values.products.map((product, i) => (
                  <Fragment key={i}>
                    <div
                      key={i}
                      className='p-2 col-span-7 gap-x-4 grid grid-cols-subgrid items-center border-b border-zinc-200 even:bg-slate-50'
                    >
                      {product.models.length === 0 && (
                        <>
                          <div title={product.name} className='truncate'>
                            {product.name}
                          </div>
                          <div>
                            {product.amount.value}
                            {product.amount.unit.name}
                          </div>
                          <div title={product.sku} className='truncate'>
                            {product.sku}
                          </div>
                          <div>{simplify(product.impactPoints)} impact points</div>
                          <div>
                            <Field name={`products.${i}.count`}>
                              {(model: FieldProps<number>) => (
                                <div className='flex gap-x-4 items-center'>
                                  <InputV3 number integer model={model} />
                                  <ModalV3
                                    size='narrow-'
                                    title='Are you sure you want to proceed?'
                                    body={<div className='-mt-6'>This will overwrite some previously filled cells.</div>}
                                    confirmLabel='Apply to All'
                                    onConfirm={() => {
                                      formik.setValues((oldValues) => {
                                        oldValues.products.forEach((product) => {
                                          product.count = model.field.value;
                                          product.models.map((it) => (it.count = model.field.value));
                                        });
                                        return { ...oldValues };
                                      });
                                    }}
                                  >
                                    <button
                                      type='button'
                                      className={cn('flex-shrink-0 rounded-md', {
                                        hidden: !model.field.value,
                                      })}
                                    >
                                      <FontAwesomeIcon icon={light('clone')} />
                                    </button>
                                  </ModalV3>
                                </div>
                              )}
                            </Field>
                          </div>
                          <div className='px-2 text-center'>
                            <NavLink className='text-brand' to={AppRoutes(product.id).report.productOverview} target='_blank'>
                              View
                            </NavLink>
                          </div>
                          <div className='px-4'>
                            <button type='button' className='' onClick={() => arrayModel.remove(i)}>
                              <FontAwesomeIcon icon={regular('trash-can')} />
                            </button>
                          </div>
                        </>
                      )}

                      {product.models.length > 0 && (
                        <>
                          {product.models.map((model, j) => {
                            return (
                              <div className='col-span-7 gap-x-4 grid grid-cols-subgrid items-center' key={j}>
                                <div title={model.name} className='truncate'>
                                  {model.name}
                                </div>
                                <div></div>
                                <div className='flex'>
                                  <div className='bg-violet-200 px-1 flex self-start rounded-md text-zinc-700 text-[10px] font-semibold uppercase'>
                                    model
                                  </div>
                                </div>
                                <div></div>
                                <div>
                                  <Field name={`products.${i}.models.${j}.count`}>
                                    {(model: FieldProps<number>) => (
                                      <div className='flex gap-x-4 items-center'>
                                        <InputV3 number integer model={model} />
                                        <ModalV3
                                          size='narrow-'
                                          title='Are you sure you want to proceed?'
                                          body={<div className='-mt-6'>This will overwrite some previously filled cells.</div>}
                                          confirmLabel='Apply to All'
                                          onConfirm={() => {
                                            formik.setValues((oldValues) => {
                                              oldValues.products.forEach((product) => {
                                                product.count = model.field.value;
                                                product.models.map((it) => (it.count = model.field.value));
                                              });
                                              return { ...oldValues };
                                            });
                                          }}
                                        >
                                          <button
                                            type='button'
                                            className={cn('flex-shrink-0 rounded-md', {
                                              hidden: !model.field.value,
                                            })}
                                          >
                                            <FontAwesomeIcon icon={light('clone')} />
                                          </button>
                                        </ModalV3>
                                      </div>
                                    )}
                                  </Field>
                                </div>
                                <div className='text-center'>
                                  <NavLink className='text-brand' to={AppRoutes(product.id, model.id).report.modelOverview} target='_blank'>
                                    View
                                  </NavLink>
                                </div>
                                <div className='text-center'>
                                  <button type='button' className='' onClick={() => arrayModel.remove(i)}>
                                    <FontAwesomeIcon icon={regular('trash-can')} />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </Fragment>
                ))}
              </div>
            </InfiniteScroll>
          );
        }}
      />
    </div>
  );
};
