import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefObject, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ProductGeneral, RawMaterialNode, getProductV3, updateProductV3 } from '../../../../api';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { CopyToFromProductsModal } from './CopyToFromProductsModal';
import { copyRawMaterialToProduct } from './dataModel';

interface Props {
  title: string;
  node?: RawMaterialNode;
  exportModal: RefObject<ModalApi>;
}

export const CopyRawMaterials = (props: Props) => {
  const navigate = useNavigate();
  const exportOkModal = useRef<ModalApi>(null);
  const [waiting, setWaiting] = useState(false);
  const [selected, setSelected] = useState(new Array<ProductGeneral>());

  const onExport = async () => {
    setWaiting(true);

    try {
      await Promise.all(
        selected
          .map(({ id }) => id)
          .map(
            (id) =>
              new Promise((resolve, reject) => {
                getProductV3(id).call({
                  ok: (product) => {
                    copyRawMaterialToProduct(props.node!, product);
                    updateProductV3(product).call({
                      ok: () => {
                        setWaiting(false);
                        props.exportModal.current!.close();
                        exportOkModal.current!.open();
                        resolve(null);
                      },
                      fail: reject,
                    });
                  },
                  fail: reject,
                });
              }),
          ),
      );
    } finally {
      setWaiting(false);
    }
  };

  return (
    <>
      <CopyToFromProductsModal
        ref={props.exportModal}
        title={`Export ${props.node?.displayName}`}
        text={`What products you’d like to export ${props.node?.displayName} to?`}
        waiting={waiting}
        selected={selected}
        setSelected={setSelected}
        onNext={onExport}
      />
      <ModalV3
        ref={exportOkModal}
        title={<div className='text-center'>{props.node?.displayName} was exported to the selected products</div>}
        body={
          <div className='flex flex-col items-center gap-12'>
            <div className='w-16 aspect-square flex justify-center items-center rounded-full bg-emerald-50 text-emerald-700'>
              <FontAwesomeIcon icon={regular('check')} size='2x' />
            </div>
            <div className='text-center'>
              {props.node?.displayName} from {props.title} was exported to the products chosen. You can access them through the “Products”
              tab.
            </div>
          </div>
        }
        cancelLabel='Close'
        confirmLabel='Go to product list'
        onConfirm={() => navigate('/products')}
      />
    </>
  );
};
