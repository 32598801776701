import cn from 'classnames';
import { Field, FieldArrayRenderProps, FieldProps, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import objectPath from 'object-path';
import { ReactNode } from 'react';
import { GenericNode } from '../../../../api';
import { OriginalBadge } from './OriginalBadge';
import { flattenAllNodes } from './dataModel';
import { useOriginalAware } from './useOriginalAware';

export interface OriginalAwareItemName {
  arrayModel: FieldArrayRenderProps;
  index: Index;
  field: string;
}

export interface OriginalAwareDiffedItem<T> {
  node: T;
  index: Index;
  added?: boolean;
  deleted?: boolean;
  noOriginal?: boolean;
}

interface Index {
  current: number;
  original?: number;
}

interface Props<T> {
  name?: string;
  itemName?: OriginalAwareItemName;
  badgeMarginStyles?: string;
  children: (props: FieldProps<T>) => ReactNode;
  card?: boolean;
  onBeforeReset?: () => void;
}

interface Id {
  id: string;
}

interface Type {
  type: string;
}

export const joinWithDiff = <T extends Id | Type>(modelNodes: T[], originalNodes?: T[]): OriginalAwareDiffedItem<T>[] => {
  const isSame = (node1: T, node2: T) =>
    ((node1 as Id).id && (node1 as Id).id === (node2 as Id).id) ||
    (!(node1 as Id).id && !(node2 as Id).id && (node1 as Type).type && (node1 as Type).type === (node2 as Type).type) ||
    node1 === node2;
  return [
    ...(originalNodes ?? []).map((node, i) => ({
      node: modelNodes.find((n) => isSame(node, n)) ?? node,
      deleted: !modelNodes.some((n) => isSame(node, n)),
      index: {
        current: modelNodes.findIndex((n) => isSame(node, n)) ?? 0,
        original: i,
      },
    })),
    ...modelNodes
      .filter((node) => !(originalNodes ?? []).some((n) => isSame(node, n)))
      .map((node) => ({
        node,
        added: true,
        noOriginal: !originalNodes,
        index: {
          current: modelNodes.findIndex((n) => isSame(node, n))!,
        },
      })),
  ];
};

const toComparableValue = (value: any) => (value === undefined ? null : value?.id ?? value?.type ?? value);

export const OriginalAwareField = <T extends any = any>(props: Props<T>) => {
  const { nodeId, originalNodes, payload } = useOriginalAware();
  const formik = useFormikContext<GenericNode>();
  const productNode = (originalNodes ?? flattenAllNodes(payload.product.nodes)).find(({ id }) => id === nodeId);
  const originalName =
    props.name ??
    (props.itemName!.index.original === undefined
      ? undefined
      : [props.itemName!.arrayModel.name, props.itemName!.index.original, props.itemName!.field].join('.'));
  const currentName = props.name ?? [props.itemName!.arrayModel.name, props.itemName!.index.current, props.itemName!.field].join('.');
  const hasOriginal = productNode && originalName;
  const originalValue = hasOriginal ? objectPath.get(productNode, originalName) : undefined;
  const unchanged = !hasOriginal || isEqual(toComparableValue(originalValue), toComparableValue(formik.getFieldProps(currentName).value));

  return (
    <div className='flex flex-col gap-2'>
      <Field name={currentName}>{props.children}</Field>
      {productNode && (props.card || !unchanged) && (
        <div
          className={cn('flex', props.badgeMarginStyles ?? 'mx-1.5', {
            invisible: unchanged,
          })}
        >
          <OriginalBadge
            value={originalValue}
            onClick={() => {
              props.onBeforeReset?.();
              formik.setFieldValue(currentName, originalValue);
            }}
          />
        </div>
      )}
    </div>
  );
};
