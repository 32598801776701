import { CSSProperties, forwardRef, SetStateAction, useEffect, useRef } from 'react';
import { GhgProductReport, ProductType } from '../../../../../../api';
import { simplify } from '../../../../shared';
import { format } from 'date-fns';
import cn from 'classnames';
import { executeOnePagerReport } from '../Overview';

interface Props {
  data: GhgProductReport;
  setData: (data: GhgProductReport | undefined) => void;
  setLoading: (loading: SetStateAction<boolean>) => void;
}

export const GhgLensOnePagerReport = forwardRef((props: Props, ref: any) => {
  const canStartConvertIntoPdf = useRef(false);

  const colors = [
    'bg-violet-400',
    'bg-purple-200',
    'bg-amber-400',
    'bg-amber-200',
    'bg-teal-400',
    'bg-teal-200',
    (() => (props.data.product.productType === ProductType.Final ? 'bg-sky-400' : undefined))(),
    'bg-sky-700',
  ].filter(Boolean);

  const contributors = props.data.analysis.categories
    .filter((category) => !category.isOutOfScope)
    .filter((category) => props.data.product.productType === ProductType.Final || category.id !== 'category_11')
    .map((category, i) => ({
      ...category,
      color: colors[i],
    }));

  const totalEmission = contributors.reduce((acc, item) => acc + item.totalEmission.value, 0);

  useEffect(() => {
    if (canStartConvertIntoPdf) {
      executeOnePagerReport(ref, `${props.data.product.name} GHG Emissions Summary Report`, () => {
        canStartConvertIntoPdf.current = false;
        props.setLoading(false);
        props.setData(undefined);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canStartConvertIntoPdf]);

  useEffect(() => {
    if (!props.data.product.imageUrl) {
      canStartConvertIntoPdf.current = true;
    }
  }, [props.data]);

  if (!props.data) {
    return <></>;
  }

  return (
    <div ref={ref} className='relative flex flex-col justify-between h-full gap-y-2 text-[65%] py-3 px-6'>
      <div className='flex justify-between'>
        <div>
          <svg width='128' height='34' viewBox='0 0 128 34'>
            <path
              d='M0 10.7342L1.28496 9.48647C2.27243 10.5979 3.2971 11.0648 4.8093 11.0648C6.3215 11.0648 7.20568 10.4409 7.20568 9.346C7.20568 8.4081 6.66856 7.94122 5.00349 7.52392L3.92924 7.28015C1.47502 6.70997 0.347063 5.68531 0.347063 3.89629C0.347063 1.74367 1.99561 0.425659 4.63577 0.425659C6.38761 0.425659 7.82957 1.01649 8.94099 2.24774L7.65604 3.49551C6.68509 2.42127 5.83396 2.09073 4.47876 2.09073C3.12356 2.09073 2.25591 2.71462 2.25591 3.79299C2.25591 4.6937 2.86327 5.16472 4.40852 5.54483L5.48276 5.7886C8.03202 6.39596 9.12692 7.42062 9.12692 9.25923C9.12692 11.3747 7.42466 12.7299 4.77211 12.7299C2.75997 12.7299 1.05771 12.0357 0 10.7342Z'
              fill='black'
            />
            <path
              d='M15.498 7.6975V0.599243H17.4234V7.50744C17.4234 9.7303 18.4646 10.945 20.4065 10.9615C22.3318 10.945 23.34 9.77988 23.34 7.57768V0.599243H25.2653V7.62726C25.2653 10.8706 23.4928 12.7299 20.3693 12.7464C17.2788 12.7299 15.4939 10.8706 15.4939 7.6975H15.498Z'
              fill='black'
            />
            <path
              d='M31.4629 10.7342L32.7478 9.48647C33.7353 10.5979 34.76 11.0648 36.2722 11.0648C37.7844 11.0648 38.6686 10.4409 38.6686 9.346C38.6686 8.4081 38.1314 7.94122 36.4664 7.52392L35.3921 7.28015C32.9462 6.70584 31.8182 5.68531 31.8182 3.89629C31.8182 1.74367 33.4667 0.425659 36.1069 0.425659C37.8587 0.425659 39.3007 1.01649 40.4121 2.24774L39.1272 3.49551C38.1562 2.42127 37.3051 2.09073 35.9499 2.09073C34.5947 2.09073 33.727 2.71462 33.727 3.79299C33.727 4.6937 34.3344 5.16472 35.8797 5.54483L36.9539 5.7886C39.5032 6.39596 40.5981 7.42062 40.5981 9.25923C40.5981 11.3747 38.8958 12.7299 36.2432 12.7299C34.2311 12.7299 32.5289 12.0357 31.4711 10.7342H31.4629Z'
              fill='black'
            />
            <path d='M49.4936 12.577V2.37174H45.6387V0.599243H55.2532V2.37174H51.3983V12.577H49.4895H49.4936Z' fill='black' />
            <path
              d='M68.2515 12.577L67.1236 9.52371H62.0209L60.8764 12.577H58.8436L63.4959 0.599243H65.6651L70.3008 12.577H68.2515ZM62.6448 7.83798H66.4997L64.8181 3.30964L64.5743 2.59899C64.4875 2.89234 64.4338 3.04934 64.3471 3.29311L62.6448 7.84211V7.83798Z'
              fill='black'
            />
            <path d='M76.2339 12.577V0.599243H78.1593V12.577H76.2339Z' fill='black' />
            <path
              d='M85.2741 12.577V0.599243H87.5135L93.1533 9.29647C93.2937 9.48653 93.397 9.69724 93.5168 9.90383C93.5003 9.69724 93.5003 9.50305 93.5003 9.29647V0.599243H95.3761V12.577H93.1367L87.497 3.90047C87.3565 3.69388 87.2532 3.4997 87.1334 3.29311C87.1499 3.4997 87.1499 3.69388 87.1499 3.90047V12.577H85.2741Z'
              fill='black'
            />
            <path
              d='M102.487 12.577V0.599243H110.713V2.31803H104.412V5.61512H109.792V7.33391H104.412V10.8582H110.767V12.577H102.487Z'
              fill='black'
            />
            <path
              d='M117.22 12.577V0.599243H121.385C125.203 0.599243 127.946 2.85515 127.946 6.55302C127.946 10.2509 125.186 12.577 121.385 12.577H117.22ZM119.146 10.8045H121.369C124.058 10.8045 125.967 9.24276 125.967 6.55302C125.967 3.86328 124.075 2.37174 121.369 2.37174H119.146V10.8045Z'
              fill='black'
            />
            <path d='M0.813934 33.4008V21.423H2.74344V33.4008H0.813934Z' fill='black' />
            <path
              d='M9.85823 33.4008V21.423H12.3249L15.7955 28.3146L19.2496 21.423H21.6955V33.4008H19.8198V24.7242C19.8198 24.5176 19.8197 24.3234 19.8363 24.1334C19.733 24.3234 19.6297 24.5341 19.5223 24.7407L16.659 30.4507H14.9072L12.0274 24.7077L11.7134 24.1334C11.7299 24.3234 11.7299 24.5341 11.7299 24.7407V33.4008H9.8541H9.85823Z'
              fill='black'
            />
            <path
              d='M37.9166 25.3646C37.9166 27.8106 36.0615 29.4261 33.2808 29.4261H30.7316V33.4008H28.8062V21.423H33.4213C36.1813 21.423 37.9166 22.9517 37.9166 25.3646ZM33.2147 27.6908C34.8467 27.6908 35.9375 26.79 35.9375 25.4349C35.9375 24.0135 34.9128 23.1624 33.2478 23.1624H30.7316V27.6908H33.2147Z'
              fill='black'
            />
            <path
              d='M51.609 33.4008L50.4811 30.3474H45.3784L44.234 33.4008H42.2012L46.8535 21.423H49.0226L53.6584 33.4008H51.609ZM46.0023 28.6617H49.8572L48.1715 24.1334L47.9277 23.4227C47.8409 23.7161 47.7872 23.8731 47.7005 24.1168L45.9982 28.6658L46.0023 28.6617Z'
              fill='black'
            />
            <path
              d='M58.323 27.4098C58.323 23.7491 60.9425 21.2494 64.4338 21.2494C66.3096 21.2494 67.9912 22.0138 69.1564 23.3855L67.7144 24.5837C66.9335 23.5962 65.7188 23.0384 64.4338 23.0384C62.0581 23.0384 60.3187 24.7572 60.3187 27.4139C60.3187 30.0706 62.0374 31.7894 64.4338 31.7894C65.8551 31.7894 66.9666 31.2522 67.8342 30.1904L69.2555 31.3886C68.1276 32.7934 66.4253 33.5742 64.4297 33.5742C60.9425 33.5742 58.3189 31.058 58.3189 27.4139L58.323 27.4098Z'
              fill='black'
            />
            <path d='M78.1593 33.4008V23.1955H74.3045V21.423H83.9189V23.1955H80.064V33.4008H78.1552H78.1593Z' fill='black' />
            <path
              d='M121.91 33.2685C125.273 33.2685 128 30.5419 128 27.1784C128 23.8149 125.273 21.0883 121.91 21.0883C118.546 21.0883 115.82 23.8149 115.82 27.1784C115.82 30.5419 118.546 33.2685 121.91 33.2685Z'
              fill='#4f00ff'
            />
            <path
              d='M103.115 31.434C105.465 31.434 107.37 29.5287 107.37 27.1784C107.37 24.828 105.465 22.9227 103.115 22.9227C100.764 22.9227 98.8591 24.828 98.8591 27.1784C98.8591 29.5287 100.764 31.434 103.115 31.434Z'
              fill='#4f00ff'
            />
          </svg>
        </div>
        <div className='flex flex-col text-right'>
          <div>{format(new Date(), 'dd.MM.yyyy')}</div>
          <div className='flex items-center gap-x-1'>
            <div>Methodology: GHG protocol</div>
            <div>|</div>
            <div>V.{props.data.metadata.methodologyVersion}</div>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-y-1 py-3'>
        <div className='font-semibold text-base text-dark'>{props.data.product.name} GHG Emissions Summary Report</div>
        <div>ID: {props.data.product.skuId}</div>
      </div>
      <div className='flex justify-between gap-x-3 text-zinc-500'>
        <div className='flex flex-col'>
          <div>Total Emissions</div>
          <div className='flex gap-x-1 text-dark mt-6'>
            <div className='text-2xl'>{simplify(props.data.analysis.totalEmission.value)}</div>
            <div className='flex self-end mb-1 text-xs'>
              {props.data.analysis.totalEmission.unit}/{props.data.product.amount.unit.id === 'ml' ? 'l' : 'kg'}
            </div>
          </div>
        </div>
        <div />
        <div className='flex justify-center items-center h-[100px]'>
          {props.data.product.imageUrl && <img className='max-h-[90%] max-w-full' src={props.data.product.imageUrl} alt='_' />}
        </div>
      </div>

      <div className='bg-dark w-full h-[1px]' />

      <div className='grid gap-x-2 grid-cols-3'>
        {props.data.analysis.scopeEmissions.map((scope, i) => (
          <div key={i} className='flex flex-col'>
            <div>{scope.name} emissions</div>
            <div>
              <span className='text-lg'>{simplify(scope.totalEmission.value)} </span>
              {scope.totalEmission.unit}/{props.data.product.amount.unit.id === 'ml' ? 'l' : 'kg'}
            </div>
          </div>
        ))}
      </div>

      <div className='bg-dark w-full h-[1px]' />

      <div className='flex flex-col gap-y-3'>
        <div>Scope 3 contribution</div>
        <div className='flex w-full h-8 rounded overflow-hidden'>
          {contributors.map((item) => (
            <div
              key={item.name}
              style={
                {
                  '--share-width': (item.totalEmission.value / totalEmission) * 100 + '%',
                } as CSSProperties
              }
              className={cn(item.color, 'h-full w-[--share-width]')}
            ></div>
          ))}
        </div>
        <div className={cn('grid gap-x-3', props.data.product.productType === ProductType.Final ? 'grid-cols-8' : 'grid-cols-7')}>
          {contributors.map((item) => (
            <div key={item.name} className='flex flex-col gap-y-1.5'>
              <div className={cn('size-5 rounded-md', item.color)} />
              <div className='flex flex-col'>
                <div className='text-base'>
                  {parseFloat(Number(simplify((item.totalEmission.value / totalEmission) * 100)).toFixed(2))}%
                </div>
                <div className='text-[75%]'>{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='bg-dark w-full h-[1px]' />

      <div className='flex flex-col gap-y-3'>
        <div>Data</div>
        {props.data.firstPartyDataPercentage === 100 ? (
          <div>
            A combination of self-reported foreground data provided by the client (not independently verified by a third-party) and
            background data from the following databases was used to estimate the impact of this product:{' '}
            <span className='font-semibold'>Ecoinvent v.3.7.1</span>, <span className='font-semibold'>Agribalyse v.3.1</span> and{' '}
            <span className='font-semibold'>Sustained Database</span>. Enough foreground data was provided by the client to meet LCA
            Screening Level compliance for this product (no generic data/defaults were used).
          </div>
        ) : (
          <div>
            A combination of self-reported foreground data provided by the client (not independently verified by a third-party) and
            background data from the following databases was used to estimate the impact of this product:{' '}
            <span className='font-semibold'>Ecoinvent v.3.7.1</span>, <span className='font-semibold'>Agribalyse v.3.1</span> and{' '}
            <span className='font-semibold'>Sustained Database</span>. Where foreground data wasn’t provided, generic data/defaults have
            been used, resulting in this assessment not meeting LCA Screening Level compliance.
          </div>
        )}
      </div>

      <div className='bg-dark w-full h-[1px]' />

      <div className='flex flex-col gap-y-3'>
        <div>Methodology</div>
        {props.data.product.productType === ProductType.Final ? (
          <div>
            The life cycle assessment (LCA) of this product was performed <span className='font-semibold'>‘cradle-to-grave’</span>, from raw
            materials extraction all the way to the product consumption and packaging end-of-life. Results presented in this report are in
            line with <span className='font-semibold'>ISO 14044</span> and were obtained by largely following the{' '}
            <span className='font-semibold'>Corporate Value Chain (Scope 3) Standard of the Greenhouse Gas Protocol</span> using the OpenLCA
            v.1.11.0 software for impact calculations. Under said protocol, emissions are separated into three categories: Scope 1 (direct
            emissions), Scope 2 (emissions from electricity), and Scope 3 (supply chain emissions). Results presented here cover emissions
            from production-related facilities, goods or services only. In addition, scope 1 and 2 calculation rely mostly on secondary data
            and as such should be used as reference only.
          </div>
        ) : (
          <div>
            This life cycle assessment (LCA) was performed <span className='font-semibold'>‘cradle-to-customer’</span>, from raw materials
            extraction up to final point of sale where it is taken for further processing. Results presented in this report are in line with
            <span className='font-semibold'>ISO 14044</span> and were obtained by largely following the{' '}
            <span className='font-semibold'>Corporate Value Chain (Scope 3) Standard of the Greenhouse Gas Protocol</span> using the OpenLCA
            v.1.11.0 software for impact calculations. Under said protocol, emissions are separated into three categories: Scope 1 (direct
            emissions), Scope 2 (emissions from electricity), and Scope 3 (supply chain emissions). Results presented here cover emissions
            from production-related facilities, goods or services only. In addition, scope 1 and 2 calculation rely mostly on secondary data
            and as such should be used as reference only.
          </div>
        )}
      </div>

      {props.data.firstPartyDataPercentage === 100 ? (
        <div className='rounded-xl border border-dark py-2 px-3 -mx-3 font-semibold'>
          Disclaimer: No verification of the input data was provided by Sustained and as such Sustained cannot be held responsible for the
          representativeness of the results presented in this report.
        </div>
      ) : (
        <div className='bg-orange-100 rounded-xl border border-orange-400 py-2 px-3 -mx-3 font-semibold text-center'>
          <div>Warning: Default data was used to calculate this product’s impact where no foreground data was provided.</div>
          <div>This holds significant uncertainty and as such results should be interpreted with caution.</div>
        </div>
      )}
    </div>
  );
});
