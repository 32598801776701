import { getManufacturingHistoricalReports, ReportType } from '../../../../api';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { Reports } from '../components/Lists/Reports';
import { StickyHeader } from '../../../../components/StickyHeader';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../index';
import cn from 'classnames';

export const Main = () => {
  const reports = useApiQuery(
    getManufacturingHistoricalReports({
      pageSize: 1000,
      pageToken: '',
    }),
  );

  if (!reports.data) return <></>;

  return (
    <div className='flex flex-col text-base'>
      <StickyHeader className='px-6'>
        <div className='flex items-center gap-1'>
          <div className='text-lg font-semibold text-zinc-800'>Historical data reports</div>
          <div className='flex items-center justify-center min-w-5 px-1.5 py-0.5 mt-0.5 bg-slate-100 rounded-full text-xs text-brand'>
            {reports.data.reports.length}
          </div>
        </div>
        <NavLink
          to={AppRoutes().report.newHistorical}
          type='button'
          className={cn(
            { 'pointer-events-none cursor-not-allowed': !reports.data?.hasCompleteProducts },
            'text-base font-semibold [&:not(:disabled)]:shadow-md [&:active:not(:disabled)]:scale-95',
            'border-2 border-brandDark text-brandDark px-3 py-1.5 rounded-full',
            'disabled:text-neutral-500 disabled:border-neutral-400 disabled:cursor-not-allowed',
          )}
        >
          Upload historical data
        </NavLink>
      </StickyHeader>
      <div className='p-6'>
        Find all the historical volume reports ever created in the table below. These use actual data from what came out of our production
        facilities over a given time frame and reflect the true impact your volume activities have had over that same timeframe.
      </div>
      <Reports
        onNext={() => {}}
        reportType={ReportType.Historical}
        data={reports.data}
        reload={reports.reload}
        pathToReport={(id: string) => AppRoutes(id).report.historicalOverview}
      />
    </div>
  );
};
