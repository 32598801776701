import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import * as yup from 'yup';
import { resetPassword } from '../api';
import { Input } from '../components/Input';
import { LoginFrame } from './LoginFrame';

export const ForgotPassword = () => {
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState('');

  return (
    <LoginFrame>
      {submitted ? (
        <div className='flex flex-col gap-12 items-center'>
          <div className='w-32 aspect-square flex justify-center items-center rounded-full bg-brandDarkPurple3 text-brandLime'>
            <FontAwesomeIcon icon={regular('check')} size='3x' />
          </div>
          <div className='flex flex-col gap-4 text-lg text-center text-dark'>
            <div>
              We have sent a password reset link to <span className='font-semibold'>{submitted}</span>.
            </div>
            <div>Please check your inbox (and your spam folder, just in case).</div>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={yup.object().shape({
            email: yup.string().email().required(),
          })}
          validateOnBlur={triedSubmitting}
          validateOnChange={triedSubmitting}
          onSubmit={(values, { setSubmitting }) => {
            resetPassword(values).call({
              ok: () => setSubmitted(values.email),
              fail: () => setSubmitting(false),
            });
          }}
        >
          {({ isSubmitting, isValidating }) => (
            <Form className='flex flex-col items-center gap-4'>
              <Helmet title='Forgot your password?' />
              <div className='text-2xl font-semibold text-black pl-4'>Forgot your password?</div>
              <div className='text-dark'>We’ll send you a link to reset your password.</div>
              <Field name='email'>
                {(model: FieldProps<string>) => (
                  <div className='w-80 flex flex-col gap-1.5'>
                    <div className='text-dark ml-4 text-sm'>Email</div>
                    <div className='flex flex-col flex-1'>
                      <Input autoFocus model={model} placeholder='name@company.com' />
                    </div>
                  </div>
                )}
              </Field>
              <button
                type='submit'
                disabled={isSubmitting || isValidating}
                className={cn(
                  'mt-4 flex justify-center border-2 border-[#050505] bg-[#050505] rounded-full px-8 py-2 text-white font-semibold',
                  '[&:active:not(:disabled)]:scale-95',
                  'disabled:cursor-wait',
                )}
                onClick={() => setTriedSubmitting(true)}
              >
                Send me the reset link
              </button>
            </Form>
          )}
        </Formik>
      )}
    </LoginFrame>
  );
};
