import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { ProductModelV3 } from '../../../../api';

export interface Props {
  waiting: boolean;
}

export const TitleBadges = (props: Props) => {
  const formik = useFormikContext<ProductModelV3>();
  const { waiting } = props;
  const errors = formik.values.validation.errors.length > 0;

  return (
    <div
      className={cn(
        'flex justify-center items-center gap-2 px-3 py-1 rounded-md text-xs transition',
        (() => {
          if (waiting) {
            return 'bg-amber-100 text-amber-800';
          }

          if (errors) {
            return 'bg-red-50 text-red-500';
          }

          return 'bg-green-100 text-green-800';
        })(),
      )}
    >
      {(() => {
        if (waiting) {
          return (
            <>
              <FontAwesomeIcon size='sm' icon={regular('clock')} />
              Saving
            </>
          );
        }

        if (errors) {
          return (
            <>
              <FontAwesomeIcon size='sm' icon={regular('exclamation-triangle')} />
              Unsaved changes
            </>
          );
        }

        return (
          <>
            <FontAwesomeIcon size='sm' icon={regular('check')} />
            Saved
          </>
        );
      })()}
    </div>
  );
};
