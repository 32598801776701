import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export const StatusBadge = ({ state }: { state?: 'unsaved' | 'saving' }) => {
  return (
    <>
      {state && (
        <div className='text-xs whitespace-nowrap'>
          {state === 'saving' && (
            <div className='flex gap-1 items-center px-0.5 bg-amber-100 text-amber-800'>
              <FontAwesomeIcon size='sm' icon={regular('clock')} />
              Saving changes
            </div>
          )}
          {state === 'unsaved' && (
            <div className='flex gap-1 items-center px-0.5 bg-amber-100 text-amber-800'>
              <FontAwesomeIcon size='sm' icon={regular('exclamation-triangle')} />
              Unsaved changes
            </div>
          )}
        </div>
      )}
    </>
  );
};
