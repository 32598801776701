import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { useState } from 'react';
import { Model, ProductV3, ProductionWarehouseNode, Tag } from '../../../../../../../../api';

type FacilitiesPayload = ProductionWarehouseNode;

interface Props {
  product: ProductV3;
  model: Model;
  productProductionWarehouse?: FacilitiesPayload;
  modelProductionWarehouse?: FacilitiesPayload;
  allProductionWarehouses: FacilitiesPayload[];
}

export const ProductionWarehouse = (props: Props) => {
  const [expanded, setExpanded] = useState(true);

  const productWarehouseStoredItems = props.productProductionWarehouse?.items;
  const modelWarehouseStoredItems = props.modelProductionWarehouse?.items;

  const allStoredItems = [
    ...(productWarehouseStoredItems ? productWarehouseStoredItems : []),
    ...(modelWarehouseStoredItems ? modelWarehouseStoredItems : []),
  ];

  const uniqueStoredItems = uniqBy(allStoredItems, (item) => item.id);

  return (
    <div
      className={cn('flex flex-col gap-6 rounded-xl shadow bg-neutral-50 p-3 border', {
        'border-emerald-700': !props.productProductionWarehouse && props.modelProductionWarehouse,
        'border-red-500': props.productProductionWarehouse && !props.modelProductionWarehouse,
      })}
    >
      <button onClick={() => setExpanded(!expanded)} className='flex w-full items-center justify-between gap-2'>
        <div className='flex items-center gap-2 pl-1 font-semibold'>
          <div className='h-7 aspect-square flex items-center justify-center text-fuchsia-900 bg-fuchsia-50 rounded-md border'>
            <FontAwesomeIcon icon={duotone('warehouse-full')} />
          </div>
          <div>{props.productProductionWarehouse?.displayName ?? props.modelProductionWarehouse?.displayName}</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand'
          icon={expanded ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>

      {expanded && (
        <div className='flex flex-col gap-2'>
          {uniqueStoredItems.map((item, i) => {
            const productStoredItem = props.productProductionWarehouse?.items.find(({ id }) => item.id === id);
            const modelStoredItem = props.modelProductionWarehouse?.items.find(({ id }) => item.id === id);

            return (
              <div key={i} className='flex flex-col gap-y-2 p-3 bg-white rounded-xl shadow'>
                <div className='grid grid-cols-3 text-sm gap-y-2'>
                  <div className='col-span-2 grid grid-cols-3'>
                    <div className='font-semibold col-span-2'>{productStoredItem?.name ?? modelStoredItem?.name}</div>
                    {!productStoredItem && modelStoredItem && <div className='text-emerald-700 text-sm'>Added</div>}
                    {productStoredItem && !modelStoredItem && <div className='text-red-500 text-sm'>Removed</div>}
                  </div>

                  <div className='grid grid-cols-3 col-span-2 gap-y-2'>
                    <div>Amount</div>
                    <div>
                      {productStoredItem?.amount.value} {productStoredItem?.amount.unit.name}
                    </div>
                    <div className='flex'>
                      {(() => {
                        if (
                          (modelStoredItem && !productStoredItem) ||
                          (modelStoredItem && productStoredItem && modelStoredItem.amount.value !== productStoredItem.amount.value)
                        ) {
                          return (
                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                              {modelStoredItem.amount.value} {modelStoredItem.amount.unit.name}
                            </div>
                          );
                        }

                        return '';
                      })()}
                    </div>

                    <div>Condition</div>
                    <div>{productStoredItem?.conservation.name}</div>
                    <div className='flex'>
                      {(() => {
                        if (!modelStoredItem && productStoredItem) {
                          return '';
                        } else if (
                          (modelStoredItem && !productStoredItem) ||
                          (modelStoredItem &&
                            productStoredItem &&
                            modelStoredItem.conservation.name !== productStoredItem.conservation.name)
                        ) {
                          return (
                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                              {modelStoredItem.conservation.name}
                            </div>
                          );
                        }

                        return '';
                      })()}
                    </div>

                    <div>Duration</div>
                    <div>
                      {(() => {
                        if (
                          props.product.metadata.system.some(
                            ({ path, tags }) =>
                              path.endsWith(`${props.productProductionWarehouse?.id}/items/${productStoredItem?.id}`) &&
                              tags.includes(Tag.Default),
                          )
                        ) {
                          return 'Default';
                        }
                        return productStoredItem && `${productStoredItem.durationWeeks} weeks`;
                      })()}
                    </div>
                    <div className='flex'>
                      {(() => {
                        if (!modelStoredItem && productStoredItem) {
                          return '';
                        } else if (
                          (modelStoredItem && !productStoredItem) ||
                          (modelStoredItem && productStoredItem && modelStoredItem.durationWeeks !== productStoredItem.durationWeeks)
                        ) {
                          return (
                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                              {`${modelStoredItem?.durationWeeks} weeks`}
                            </div>
                          );
                        }

                        return '';
                      })()}
                    </div>
                  </div>
                  <div />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
