import { FormikContextType } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import range from 'lodash/range';
import sumBy from 'lodash/sumBy';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import {
  ConsumptionNode,
  ConsumptionPreparationNode,
  DisposalNode,
  facilityNodeTypes,
  FinalDestinationNode,
  GenericFacilityNode,
  GenericFacilityWithStepsNode,
  GenericNode,
  GenericNodeWithEdges,
  GenericRawMaterialNode,
  GenericStepNode,
  GenericSupplierNode,
  getNodeTypesForLifecycleStage,
  IngredientNode,
  IngredientType,
  IngredientV3,
  LifeCycleStage,
  Lists,
  ModellingChange,
  ModellingChangeAction,
  ModellingPayload,
  NodeReference,
  NodeType,
  OutputType,
  PackagingNode,
  PackagingNodeMaterial,
  PrimaryNode,
  PrimaryOrMaterialSupplierNode,
  ProcessV3,
  ProductionNode,
  ProductionProcessAuxiliary,
  ProductionProcessElectricityType,
  ProductionWarehouseNode,
  ProductModelV3,
  ProductType,
  RawMaterialSupplierNode,
  Size,
  StaticEntity,
  StepInput,
  StepOutput,
  StorageNode,
  storageNodeTypes,
  StoredItem,
  StoreNode,
  TransportNode,
  WarehouseNode,
  WasteType,
} from '../../../../api';

export interface StepInputAmountSideEffect {
  stepId: string;
  inputId: string;
  value: number;
}

export interface UpdateSideEffects {
  stepInputAmounts: StepInputAmountSideEffect[];
}

const newId = (type: string) => `${type}.${uuid().replaceAll('-', '')}`;

export const newNodeId = () => newId('node');

export const newStepId = () => newId('step');

export const newOutputId = () => newId('output');

const percentPrecision = Math.pow(10, 5);

const roundPercent = (value: number) => Math.round(value * percentPrecision) / percentPrecision;

export const formatPhysicalImpact = (value: number) => {
  const round = (value: number) => Math.round(value * 10) / 10;
  const sign = value < 0 ? '-' : value > 0 ? '+' : '';
  const absValue = Math.abs(value);

  if (absValue === 0) {
    return '0';
  }

  if (absValue > 1_000_000) {
    return `${sign}${round(absValue / 1_000_000)}M`;
  }

  if (absValue > 1_000) {
    return `${sign}${round(absValue / 1_000)}k`;
  }

  if (absValue > 0.1) {
    return `${sign}${round(absValue)}`;
  }

  return `<${sign}0.1`;
};

export const roundAmount = (value: number) => {
  const defaultPrecision = Math.pow(10, 1);
  const belowOnePrecision = Math.pow(10, 5);

  if (value < 1) {
    return Math.round(value * belowOnePrecision) / belowOnePrecision;
  }

  return Math.round(value * defaultPrecision) / defaultPrecision;
};

export const is100Percent = (values: number[]) => {
  const precision = Math.pow(10, 5);
  return sumBy(values.map((value) => Math.round(value * precision))) === 100 * precision;
};

export const shouldAutoAdjustSupplierSplit = (node: RawMaterialSupplierNode, payload: ModellingPayload) =>
  getRawMaterialSuppliersFromAll(payload.product.nodes).some(
    ({ id, splitPercent }) => id === node.id && typeof splitPercent === 'number' && splitPercent === node.splitPercent,
  );

export const shouldAutoAdjustMaterialSplit = (node: PackagingNodeMaterial, payload: ModellingPayload) =>
  getPackagingsFromAll(payload.product.nodes)
    .flatMap(({ materials }) => materials)
    .some(
      ({ id, compositionPercent }) =>
        id === node.id && typeof compositionPercent === 'number' && compositionPercent === node.compositionPercent,
    );

export const adjustSupplierSplits = <T extends GenericRawMaterialNode>(
  formik: FormikContextType<T>,
  editedSupplier?: GenericSupplierNode,
) => {
  formik.setValues((oldValues) => {
    const newValues = cloneDeep(oldValues);
    const nodes = newValues.nodes as (GenericSupplierNode & { autoAdjustSplit: boolean })[];

    if (editedSupplier) {
      nodes.find(({ id }) => id === editedSupplier.id)!.autoAdjustSplit = false;
    }

    const autoAdjustedNodes = nodes.filter(({ autoAdjustSplit }) => autoAdjustSplit);
    const adjustableTotal =
      100 -
      sumBy(
        nodes.filter((node) => !autoAdjustedNodes.some(({ id }) => id === node.id)),
        ({ splitPercent }) => splitPercent ?? 0,
      );
    const adjustedSplitPercent = roundPercent(adjustableTotal / autoAdjustedNodes.length);

    if (adjustedSplitPercent > 0) {
      autoAdjustedNodes.forEach((node) => (node.splitPercent = adjustedSplitPercent));

      if (autoAdjustedNodes.length > 0) {
        last(autoAdjustedNodes)!.splitPercent += roundPercent(adjustableTotal - adjustedSplitPercent * autoAdjustedNodes.length);
      }
    }

    return newValues;
  });
};

export const adjustMaterialSplits = (formik: FormikContextType<PackagingNode>, editedMaterial?: PackagingNodeMaterial) => {
  formik.setValues((oldValues) => {
    const newValues = cloneDeep(oldValues);
    const materials = newValues.materials as (PackagingNodeMaterial & { autoAdjustSplit: boolean })[];

    if (editedMaterial) {
      materials.find(({ id }) => id === editedMaterial.id)!.autoAdjustSplit = false;
    }

    const autoAdjustedMaterials = materials.filter(({ autoAdjustSplit }) => autoAdjustSplit);
    const adjustableTotal =
      100 -
      sumBy(
        materials.filter((material) => !autoAdjustedMaterials.some(({ id }) => id === material.id)),
        ({ compositionPercent }) => compositionPercent ?? 0,
      );
    const adjustedCompositionPercent = roundPercent(adjustableTotal / autoAdjustedMaterials.length);

    if (adjustedCompositionPercent > 0) {
      autoAdjustedMaterials.forEach((material) => (material.compositionPercent = adjustedCompositionPercent));

      if (autoAdjustedMaterials.length > 0) {
        last(autoAdjustedMaterials)!.compositionPercent += roundPercent(
          adjustableTotal - adjustedCompositionPercent * autoAdjustedMaterials.length,
        );
      }
    }

    return newValues;
  });
};

export const getCookedAmount = (
  payload: ModellingPayload,
  consumption: ConsumptionNode,
  editedStep?: FormikContextType<ConsumptionPreparationNode>,
) => ({
  value:
    payload.product.amount.value +
    sumBy(
      [...consumption.steps.filter(({ id }) => id !== editedStep?.values.id), ...(editedStep ? [editedStep.values] : [])]
        .flatMap(({ inputs }) => inputs)
        .filter(({ item }) => (item as IngredientV3)?.type === IngredientType.Consumer),
      ({ amountValue }) => amountValue ?? 0,
    ),
  unit: payload.product.amount.unit,
});

const isDeleted = (node: GenericNode, payload: ModellingPayload) =>
  payload.changes.some(({ id, action }) => id === node.id && action === ModellingChangeAction.Deleted);

const getRemovedPrimaryNodes = (payload: ModellingPayload) => payload.product.nodes.filter((node) => isDeleted(node, payload));

export const getValidationMessages = (formik: FormikContextType<ProductModelV3>, options: { ignoreLessImportant: boolean }) => [
  ...formik.values.validation.warnings.filter(({ showAlways }) => !options.ignoreLessImportant || showAlways),
  ...formik.values.validation.errors,
];

export const hasValidationMessage = (
  node: GenericNode,
  formik: FormikContextType<ProductModelV3>,
  options: { ignoreLessImportant: boolean },
) => getValidationMessages(formik, options).some(({ nodeId }) => nodeId === node.id);

export const sizeToPositions = (size: Size) => range(0, size.height).flatMap((y) => range(0, size.width).map((x) => ({ x, y })));

export const getTransportsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Transport) as TransportNode[];

export const getTransports = (formik: FormikContextType<ProductModelV3>) => getTransportsFromAll(formik.values.nodes);

export const getTransportsWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getTransports(formik),
  ...(noDeleted ? [] : getTransportsFromAll(getRemovedPrimaryNodes(payload))),
];

export const getConnectedTransports = (node: GenericNodeWithEdges, nodes: PrimaryNode[]) => [
  ...getTransportsFromAll(nodes).filter(({ edges }) => edges.includes(node.id)),
  ...getTransportsFromAll(nodes).filter(({ id }) => node.edges.includes(id)),
];

export const getIngredientsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Ingredient) as IngredientNode[];

export const getIngredients = (formik: FormikContextType<ProductModelV3>) => getIngredientsFromAll(formik.values.nodes);

export const getRemovedIngredients = (payload: ModellingPayload) => getIngredientsFromAll(getRemovedPrimaryNodes(payload));

export const getIngredientsWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getIngredients(formik),
  ...(noDeleted ? [] : getRemovedIngredients(payload)),
];

export const getPackagingsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Packaging) as PackagingNode[];

export const getPackagings = (formik: FormikContextType<ProductModelV3>) => getPackagingsFromAll(formik.values.nodes);

export const getRemovedPackagings = (payload: ModellingPayload) => getPackagingsFromAll(getRemovedPrimaryNodes(payload));

export const getPackagingsWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getPackagings(formik),
  ...(noDeleted ? [] : getRemovedPackagings(payload)),
];

export const getRawMaterialsFromAll = (nodes: PrimaryNode[]) => [...getIngredientsFromAll(nodes), ...getPackagingsFromAll(nodes)];

export const getRawMaterials = (formik: FormikContextType<ProductModelV3>) => getRawMaterialsFromAll(formik.values.nodes);

export const getRawMaterialsWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getRawMaterials(formik),
  ...(noDeleted ? [] : getRawMaterialsFromAll(getRemovedPrimaryNodes(payload))),
];

export const getRawMaterialSuppliersFromAll = (nodes: PrimaryNode[]) => [
  ...getIngredientsFromAll(nodes).flatMap(({ nodes }) => nodes),
  ...getPackagingsFromAll(nodes).flatMap(({ nodes }) => nodes),
];

export const getRawMaterialSuppliers = (formik: FormikContextType<ProductModelV3>) => getRawMaterialSuppliersFromAll(formik.values.nodes);

export const getIngredientSuppliersWithDeleted = (node: IngredientNode, payload: ModellingPayload, noDeleted: boolean) => [
  ...node.nodes,
  ...(noDeleted
    ? []
    : getIngredientsFromAll(payload.product.nodes)
        .filter(({ id }) => id === node.id)
        .flatMap(({ nodes }) => nodes)
        .filter((node) => isDeleted(node, payload))),
];

export const getPackagingSuppliersWithDeleted = (node: PackagingNode, payload: ModellingPayload, noDeleted: boolean) => [
  ...node.nodes,
  ...(noDeleted
    ? []
    : getPackagingsFromAll(payload.product.nodes)
        .filter(({ id }) => id === node.id)
        .flatMap(({ nodes }) => nodes)
        .filter((node) => isDeleted(node, payload))),
];

export const getPrimaryAndMaterialSuppliersFromAll = (nodes: PrimaryNode[]) => [...nodes, ...getRawMaterialSuppliersFromAll(nodes)];

export const getPrimaryAndMaterialSuppliers = (formik: FormikContextType<ProductModelV3>): PrimaryOrMaterialSupplierNode[] =>
  getPrimaryAndMaterialSuppliersFromAll(formik.values.nodes);

export const getPrimaryAndMaterialSuppliersWithDeleted = (
  formik: FormikContextType<ProductModelV3>,
  payload: ModellingPayload,
  noDeleted: boolean,
) => [
  ...getPrimaryAndMaterialSuppliers(formik),
  ...(noDeleted
    ? []
    : [
        ...getRemovedPrimaryNodes(payload),
        ...getRemovedIngredients(payload).flatMap(({ nodes }) => nodes),
        ...getRemovedPackagings(payload).flatMap(({ nodes }) => nodes),
        ...getRawMaterialSuppliersFromAll(payload.product.nodes).filter((node) => isDeleted(node, payload)),
      ]),
];

export const getFacilityNodesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => facilityNodeTypes.includes(type)) as GenericFacilityNode[];

export const getFacilityNodes = (formik: FormikContextType<ProductModelV3>) => getFacilityNodesFromAll(formik.values.nodes);

export const getStoredItemChange = (item: StoredItem, node: StorageNode, payload: ModellingPayload) =>
  payload.changes
    .filter(({ id }) => id === node.id)
    .flatMap((change) => (change as ModellingChange<StorageNode>)?.subChanges?.items ?? [])
    .find(({ id }) => id === item.id);

export const getStorageNodesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => storageNodeTypes.includes(type)) as StorageNode[];

export const getStorageItemsWithDeleted = (node: StorageNode, payload: ModellingPayload, noDeleted: boolean) => [
  ...node.items,
  ...(noDeleted
    ? []
    : getStorageNodesFromAll(payload.product.nodes)
        .filter(({ id }) => id === node.id)
        .flatMap(({ items }) => items)
        .filter((item) => getStoredItemChange(item, node, payload)?.action === ModellingChangeAction.Deleted)),
];

export const getProductionFacilitiesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.Production) as ProductionNode[];

export const getProductionFacilities = (formik: FormikContextType<ProductModelV3>) => getProductionFacilitiesFromAll(formik.values.nodes);

export const getProductionFacilitiesWithDeleted = (
  formik: FormikContextType<ProductModelV3>,
  payload: ModellingPayload,
  noDeleted: boolean,
) => [...getProductionFacilities(formik), ...(noDeleted ? [] : getProductionFacilitiesFromAll(getRemovedPrimaryNodes(payload)))];

export const getProductionWarehousesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.ProductionWarehouse) as ProductionWarehouseNode[];

export const getProductionWarehouses = (formik: FormikContextType<ProductModelV3>) => getProductionWarehousesFromAll(formik.values.nodes);

export const getProductionWarehousesWithDeleted = (
  formik: FormikContextType<ProductModelV3>,
  payload: ModellingPayload,
  noDeleted: boolean,
) => [...getProductionWarehouses(formik), ...(noDeleted ? [] : getProductionWarehousesFromAll(getRemovedPrimaryNodes(payload)))];

const getWarehousesFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Warehouse) as WarehouseNode[];

export const getDistributions = (formik: FormikContextType<ProductModelV3>) => [...getWarehouses(formik), ...getStores(formik)];

export const getWarehouses = (formik: FormikContextType<ProductModelV3>) => getWarehousesFromAll(formik.values.nodes);

export const getWarehousesWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getWarehouses(formik),
  ...(noDeleted ? [] : getWarehousesFromAll(getRemovedPrimaryNodes(payload))),
];

const getStoresFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Store) as StoreNode[];

export const getStores = (formik: FormikContextType<ProductModelV3>) => getStoresFromAll(formik.values.nodes);

export const getStoresWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getStores(formik),
  ...(noDeleted ? [] : getStoresFromAll(getRemovedPrimaryNodes(payload))),
];

const getFinalDestinationsFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.FinalDestination) as FinalDestinationNode[];

export const getFinalDestinations = (formik: FormikContextType<ProductModelV3>) => getFinalDestinationsFromAll(formik.values.nodes);

export const getFinalDestinationsWithDeleted = (
  formik: FormikContextType<ProductModelV3>,
  payload: ModellingPayload,
  noDeleted: boolean,
) => [...getFinalDestinations(formik), ...(noDeleted ? [] : getFinalDestinationsFromAll(getRemovedPrimaryNodes(payload)))];

export const getProductionMovableNodesFromAll = (nodes: PrimaryNode[]) => [
  ...getProductionFacilitiesFromAll(nodes),
  ...getProductionWarehousesFromAll(nodes),
];

export const getDistributionMovableNodesFromAll = (nodes: PrimaryNode[]) => [
  ...getWarehousesFromAll(nodes),
  ...getStoresFromAll(nodes),
  ...getFinalDestinationsFromAll(nodes),
];

export const getProductionMovableNodesWithDeleted = (nodes: PrimaryNode[], payload: ModellingPayload, noDeleted: boolean) => [
  ...getProductionMovableNodesFromAll(nodes),
  ...(noDeleted ? [] : getProductionMovableNodesFromAll(getRemovedPrimaryNodes(payload))),
];

export const getDistributionMovableNodesWithDeleted = (nodes: PrimaryNode[], payload: ModellingPayload, noDeleted: boolean) => [
  ...getDistributionMovableNodesFromAll(nodes),
  ...(noDeleted ? [] : getDistributionMovableNodesFromAll(getRemovedPrimaryNodes(payload))),
];

export const getMovableNodesFromAll = (nodes: PrimaryNode[]) => [
  ...getProductionMovableNodesFromAll(nodes),
  ...getDistributionMovableNodesFromAll(nodes),
];

export const getConsumptionLocationsFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.Consumption) as ConsumptionNode[];

export const getConsumptionLocations = (formik: FormikContextType<ProductModelV3>) => getConsumptionLocationsFromAll(formik.values.nodes);

export const getConsumptionLocationsWithDeleted = (
  formik: FormikContextType<ProductModelV3>,
  payload: ModellingPayload,
  noDeleted: boolean,
) => [...getConsumptionLocations(formik), ...(noDeleted ? [] : getConsumptionLocationsFromAll(getRemovedPrimaryNodes(payload)))];

const getDisposalsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Disposal) as DisposalNode[];

export const getDisposals = (formik: FormikContextType<ProductModelV3>) => getDisposalsFromAll(formik.values.nodes);

export const getDisposalsWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getDisposals(formik),
  ...(noDeleted ? [] : getDisposalsFromAll(getRemovedPrimaryNodes(payload))),
];

export const getFacilitiesWithStepsFromAll = (nodes: PrimaryNode[]) =>
  [...getProductionFacilitiesFromAll(nodes), ...getConsumptionLocationsFromAll(nodes)] as GenericFacilityWithStepsNode[];

export const getFacilitiesWithSteps = (formik: FormikContextType<ProductModelV3>) => getFacilitiesWithStepsFromAll(formik.values.nodes);

export const getStepsFromAll = (nodes: PrimaryNode[]) => getFacilitiesWithStepsFromAll(nodes).flatMap(({ steps }) => steps);

export const getSteps = (formik: FormikContextType<ProductModelV3>) => getStepsFromAll(formik.values.nodes);

export const getStepsWithDeleted = (node: GenericFacilityWithStepsNode, payload: ModellingPayload, noDeleted: boolean) => [
  ...node.steps,
  ...(noDeleted
    ? []
    : getFacilitiesWithStepsFromAll(payload.product.nodes)
        .filter(({ id }) => id === node.id)
        .flatMap(({ steps }) => steps)
        .filter((step) => isDeleted(step, payload))),
];

export const getOutputsFromAll = (nodes: PrimaryNode[]) => getStepsFromAll(nodes).flatMap(({ outputs }) => outputs);

export const getOutputs = (formik: FormikContextType<ProductModelV3>) => getOutputsFromAll(formik.values.nodes);

export const getOutputsWithDeleted = (formik: FormikContextType<ProductModelV3>, payload: ModellingPayload, noDeleted: boolean) => [
  ...getOutputs(formik),
  ...(noDeleted
    ? []
    : getFacilitiesWithStepsFromAll(payload.product.nodes)
        .flatMap(({ steps }) => steps)
        .filter((step) => isDeleted(step, payload))
        .flatMap(({ outputs }) => outputs)),
];

export const flattenAllNodes = (nodes: PrimaryNode[]) => [
  ...getStepsFromAll(nodes),
  ...getOutputsFromAll(nodes),
  ...getPrimaryAndMaterialSuppliersFromAll(nodes),
];

export const getInputNode = (
  input: StepInput,
  formik: FormikContextType<ProductModelV3>,
  payload: ModellingPayload,
  noDeleted: boolean,
) => {
  if (input.item) {
    return { ...input.item, amount: { ...input.item, value: input.amountValue } };
  }

  const rawMaterial = getRawMaterialsWithDeleted(formik, payload, noDeleted).find(({ id }) => id === input.id);
  return (
    input.id === payload.product.id
      ? {
          ...payload.product,
          name: 'Final product',
        }
      : rawMaterial
      ? { ...rawMaterial, name: rawMaterial.displayName }
      : getOutputsWithDeleted(formik, payload, noDeleted).find(({ id }) => id === input.id)
  )!;
};

export const stepValidationSchema = (params: {
  productionNode?: ProductionNode;
  packaging: boolean;
  productFormik: FormikContextType<ProductModelV3>;
  lists: Lists;
}) =>
  yup.object().shape({
    process: params.productionNode
      ? yup.object().shape({
          overrides: yup.object().shape({
            electricity: yup
              .object()
              .nullable()
              .default(null)
              .shape({
                value: yup.number().min(0).nullable(),
                types: yup
                  .array()
                  .when({
                    is: (value: ProductionProcessElectricityType[]) => value.length > 1 || value[0]?.type || value[0]?.percent,
                    then: (schema) =>
                      schema.of(
                        yup.object().shape({
                          type: yup.string().required(),
                          percent: yup.number().positive().max(100).required(),
                        }),
                      ),
                  })
                  .test('', 'splitsNot100', function () {
                    const parent = this.parent as { types: { percent: number }[] };
                    return (
                      !parent.types.every(({ percent }) => typeof percent === 'number') ||
                      is100Percent(parent.types.map(({ percent }) => percent))
                    );
                  }),
              })
              .test(
                '',
                'missingValueOrTypes',
                (electricity: { value: any; types?: ProductionProcessElectricityType[] } | null) =>
                  (typeof electricity!.value === 'number' &&
                    (electricity!.types!.length > 1 || !!electricity!.types![0]?.type || !!electricity!.types![0]?.percent)) ||
                  (typeof electricity!.value !== 'number' &&
                    electricity!.types!.length === 1 &&
                    !electricity!.types![0].type &&
                    !electricity!.types![0].percent),
              ),
            gas: yup
              .object()
              .nullable()
              .default(null)
              .shape({
                value: yup.number().min(0).nullable(),
              }),
            water: yup
              .object()
              .nullable()
              .default(null)
              .shape({
                input: yup
                  .object()
                  .nullable()
                  .default(null)
                  .shape({
                    value: yup.number().min(0).nullable(),
                  }),
              }),
            auxiliaries: yup.array().when({
              is: (value: ProductionProcessAuxiliary[]) => value.length > 1 || value[0]?.type || typeof value[0]?.value === 'number',
              then: (schema) =>
                schema.of(
                  yup.object().shape({
                    type: yup.string().required(),
                    value: yup.number().min(0).required(),
                  }),
                ),
            }),
          }),
        })
      : yup.object().required(),
    inputs: yup
      .array()
      .min(1)
      .test(
        '',
        'noPackagingInput',
        (inputs?: NodeReference[]) =>
          !params.packaging || inputs!.some((input) => getPackagings(params.productFormik).some(({ id }) => id === input.id)),
      )
      .test(
        '',
        'noNonPackagingInput',
        (inputs?: NodeReference[]) =>
          !params.packaging || inputs!.some((input) => !getPackagings(params.productFormik).some(({ id }) => id === input.id)),
      )
      .test('', 'needsPackagingUsedInProcess', (inputs?: NodeReference[], context?: yup.TestContext) => {
        const process = params.lists.processes.find(({ id }) => id === (context!.parent as GenericStepNode).process?.id);
        return (
          !params.packaging ||
          !process ||
          inputs!.some((input) => {
            const packaging = getPackagings(params.productFormik).find(({ id }) => id === input.id);
            return packaging && isUsedInProcess(packaging, process, params.lists);
          })
        );
      })
      .of(
        yup.object().shape({
          id: yup.string().required(),
          amountValue: yup.number().positive().required(),
        }),
      ),
    outputs: yup
      .array()
      .when('finalStep', {
        is: false,
        then: (schema) => schema.min(1),
      })
      .test('', 'needsAtLeastOneIntermediate', function () {
        const step = this.parent as GenericStepNode;
        return (
          (step.finalStep && (params.productionNode?.finalFacility ?? true)) ||
          step.outputs.some(({ outputType }) => outputType?.type === OutputType.IntermediateProduct)
        );
      })
      .of(
        yup.object().shape({
          outputType: yup.object().required(),
          amount: yup.object().when('outputType.type', {
            is: (type: OutputType) => [OutputType.Emission, OutputType.Waste].includes(type),
            then: (schema) =>
              schema.shape({
                value: yup
                  .number()
                  .min(0)
                  .nullable()
                  .test('', 'required', (value, context) => {
                    const step = (context as any).from[2].value as GenericStepNode;
                    const output = (context as any).from[1].value as StepOutput;
                    const process = params.lists.processes.find(({ id }) => id === step.process?.id);

                    if (
                      !params.productionNode ||
                      !process?.overrides?.water ||
                      step.outputs.findIndex(({ id }) => id === output.id) !== 0
                    ) {
                      return !!value;
                    }

                    return true;
                  }),
              }),
            otherwise: (schema) =>
              schema.shape({
                value: yup.number().positive().required(),
                unit: yup.object().required(),
              }),
          }),
          economicValue: yup.object().when('outputType.type', {
            is: OutputType.CoProduct,
            then: (schema) =>
              schema.shape({
                price: yup.number().positive().required(),
                currency: yup.object().required(),
              }),
            otherwise: (schema) => schema.nullable(),
          }),
          emission: yup.object().when('outputType.type', {
            is: OutputType.Emission,
            then: (schema) =>
              schema.shape({
                destination: yup.object().required(),
                subType: yup.object().when('destination', {
                  is: (value: StaticEntity) =>
                    params.lists.emissionDestinations.some(({ type, subTypes }) => type === value?.type && subTypes),
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
              }),
            otherwise: (schema) => schema.nullable(),
          }),
          name: yup.string().when(['outputType.type', 'waste.type'], {
            is: (outputType: OutputType, wasteType: WasteType) =>
              outputType !== OutputType.Emission && (outputType !== OutputType.Waste || wasteType === WasteType.Solid),
            then: (schema) => schema.required(),
          }),
          waste: yup.object().when('outputType.type', {
            is: OutputType.Waste,
            then: (schema) =>
              schema.shape({
                type: yup.string().required(),
                subType: yup.object().when('type', {
                  is: WasteType.Liquid,
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
                destination: yup.object().when('type', {
                  is: (type: WasteType) => [WasteType.Solid, WasteType.Packaging].includes(type),
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
                packaging: yup.object().when('type', {
                  is: WasteType.Packaging,
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
              }),
            otherwise: (schema) => schema.nullable(),
          }),
        }),
      ),
  });

export const graphCleanup = (values: ProductModelV3, payload: ModellingPayload, lists: Lists) => {
  // 1. remove primary edges, nodes and transports
  // order matters
  getPrimaryAndMaterialSuppliersFromAll(values.nodes).forEach((node) => {
    node.edges = node.edges.filter((edgeId) => getPrimaryAndMaterialSuppliersFromAll(values.nodes).some(({ id }) => id === edgeId));
  });
  getProductionFacilitiesFromAll(values.nodes).forEach((productionFacility) => {
    productionFacility.edges = productionFacility.edges.filter((productionFacilityEdgeId) =>
      getTransportsFromAll(values.nodes)
        .filter(({ id }) => id === productionFacilityEdgeId)
        .flatMap(({ edges }) => edges)
        .flatMap((id) => values.nodes.filter((node) => node.id === id))
        .some(({ type }) =>
          (productionFacility.finalFacility
            ? [
                ...(payload.product.productType === ProductType.Internal ? [NodeType.ProductionWarehouse] : []),
                ...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution),
                ...getNodeTypesForLifecycleStage(LifeCycleStage.Use),
              ]
            : getNodeTypesForLifecycleStage(LifeCycleStage.Production)
          ).includes(type),
        ),
    );
  });
  values.nodes = values.nodes.filter(
    (node) =>
      node.type !== NodeType.Transport ||
      (node.edges.length > 0 && getPrimaryAndMaterialSuppliersFromAll(values.nodes).some(({ edges }) => edges.includes(node.id))),
  );
  getPrimaryAndMaterialSuppliersFromAll(values.nodes).forEach((node) => {
    node.edges = node.edges.filter((edgeId) => getPrimaryAndMaterialSuppliersFromAll(values.nodes).some(({ id }) => id === edgeId));
  });

  // 2. remove outputs, items and inputs
  // order matters
  getStepsFromAll(values.nodes).forEach((step) => {
    const productionFacility = getProductionFacilitiesFromAll(values.nodes).find(({ steps }) => steps.some(({ id }) => id === step.id));
    step.outputs = step.outputs.filter(({ outputType, waste }) => {
      if (productionFacility) {
        if (productionFacility.finalFacility && step.finalStep) {
          return outputType.type !== OutputType.IntermediateProduct;
        }
      } else {
        if (step.finalStep) {
          return outputType.type !== OutputType.IntermediateProduct;
        }
      }

      if (outputType.type === OutputType.Waste && waste?.type === WasteType.Packaging) {
        return getPackagingsFromAll(values.nodes).some(({ id }) => id === waste.packaging.id);
      }

      return true;
    });
  });
  getTransportsFromAll(values.nodes).forEach((transport) => {
    transport.items = transport.items.filter((item) => {
      const output = getOutputsFromAll(values.nodes).find(({ id }) => id === item.id);

      if (output) {
        return output.outputType.type === OutputType.IntermediateProduct;
      }

      return getRawMaterialsFromAll(values.nodes).some(({ id }) => id === item.id);
    });
  });
  const isTransportedToFacility = (item: { id: string }, facility: GenericFacilityNode) =>
    getTransportsFromAll(values.nodes)
      .filter(({ items }) => items.some(({ id }) => id === item.id))
      .flatMap(({ edges }) => edges)
      .some((id) => id === facility.id);
  getProductionWarehousesFromAll(values.nodes).forEach((warehouse) => {
    warehouse.items = warehouse.items.filter((item) => item.id === payload.product.id || isTransportedToFacility(item, warehouse));
  });
  getTransportsFromAll(values.nodes).forEach((transport) => {
    const fromProductionWarehouse = getProductionWarehousesFromAll(values.nodes).find(({ edges }) => edges.includes(transport.id));

    if (fromProductionWarehouse) {
      transport.items = transport.items.filter((item) => fromProductionWarehouse.items.some(({ id }) => id === item.id));
    }
  });
  const isTransportingFinalProduct = (transport: TransportNode) =>
    transport.edges.every(
      (edgeId) =>
        values.nodes
          .filter(({ type }) =>
            [...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution), ...getNodeTypesForLifecycleStage(LifeCycleStage.Use)].includes(
              type,
            ),
          )
          .some(({ id }) => id === edgeId) ||
        getProductionFacilitiesFromAll(values.nodes)
          .filter(({ finalFacility }) => finalFacility)
          .flatMap(({ edges }) => edges)
          .some((edgeId) => edgeId === transport.id),
    );
  values.nodes = values.nodes.filter(
    (node) => node.type !== NodeType.Transport || isTransportingFinalProduct(node) || node.items.length > 0,
  );
  getStepsFromAll(values.nodes).forEach((step) => {
    const facility = getFacilitiesWithStepsFromAll(values.nodes).find(({ steps }) => steps.some(({ id }) => id === step.id))!;
    const isConsumptionLocation = () =>
      getConsumptionLocationsFromAll(values.nodes).some(({ steps }) => steps.some(({ id }) => id === step.id));

    step.inputs = step.inputs.filter((input) => {
      if (input.item) {
        return true;
      }

      const output = getOutputsFromAll(values.nodes).find(({ id }) => id === input.id);
      const rawMaterial = getRawMaterialsFromAll(values.nodes).find(({ id }) => id === input.id);
      const isSameFacilityOutput = () => facility.steps.flatMap(({ outputs }) => outputs).some(({ id }) => id === output?.id);

      if (output) {
        return (
          output.outputType.type === OutputType.IntermediateProduct &&
          (isConsumptionLocation() || isSameFacilityOutput() || isTransportedToFacility(input, facility))
        );
      }

      if (rawMaterial) {
        return (
          isConsumptionLocation() ||
          (rawMaterial.type === NodeType.Ingredient && rawMaterial.localSupply) ||
          isTransportedToFacility(input, facility)
        );
      }

      return input.id === payload.product.id;
    });
  });
};

export const isUsedInProcess = (node: PackagingNode, process: ProcessV3, lists: Lists) =>
  lists.packagingTypes.find(({ id }) => id === node.packaging.id)!.packagingProcesses.includes(process.id);

export const assignStepLayoutPosition = (node: GenericFacilityWithStepsNode, step: GenericStepNode) => {
  let firstEmptyColumnIndex = range(0, node.layoutGrid.width).find(
    (x) => !node.steps.some(({ layoutPosition }) => layoutPosition?.x === x),
  );

  if (firstEmptyColumnIndex === undefined) {
    firstEmptyColumnIndex = node.layoutGrid.width++;
  }

  step.layoutPosition = { x: firstEmptyColumnIndex, y: 0 };
};
