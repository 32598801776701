import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { simplify } from '../../../../shared';

interface Props {
  title: string;
  products?: {
    highestImpactProduct: {
      id: string;
      name: string;
      impactPoints: number;
    };
    lowestImpactProduct: {
      id: string;
      name: string;
      impactPoints: number;
    };
  };
}

export const WinnersLosersPerUnitOfProduct = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const best = props.products?.lowestImpactProduct;
  const worst = props.products?.highestImpactProduct;

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        <div className='flex items-center gap-2 px-2 py-0.5 -my-0.5 border border-slate-200 bg-slate-100 rounded'>
          <FontAwesomeIcon className='size-5 text-brand' icon={light('cube')} />
          <div className='text-zinc-700'>Values per unit of product</div>
        </div>
      </div>
      <div className='flex flex-col gap-y-6 self-start max-w-[968px] p-6 pb-4 bg-white rounded-2xl border'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center gap-x-1.5 text-base'>
            <div className='size-8 flex items-center justify-center bg-slate-100 rounded-lg '>
              <FontAwesomeIcon icon={light('box-circle-check')} />
            </div>
            <div className='font-semibold text-zinc-800'>Products</div>
          </div>
        </div>
        {best && worst ? (
          <>
            <div className='flex flex-col gap-y-2'>
              <div title={best.name} className='flex gap-x-1 text-zinc-800 text-sm truncate pl-0.5'>
                <div className='truncate'>{best.name}</div>
              </div>
              <div className='flex self-start gap-x-2 pl-2 pr-3 py-1 bg-emerald-50 rounded-2xl text-base text-emerald-700'>
                <div>{simplify(best.impactPoints)}</div>
                <div className='text-[10px] uppercase'>IMPACT P.</div>
              </div>
            </div>
            <div className='flex flex-col gap-y-2'>
              <div title={worst.name} className='flex gap-x-1 text-zinc-800 text-sm truncate pl-0.5'>
                <div className='truncate'>{worst.name}</div>
              </div>
              <div className='flex self-start gap-x-2 pl-2 pr-3 py-1 bg-red-50 rounded-2xl text-base text-red-500'>
                <div>{simplify(worst.impactPoints)}</div>
                <div className='text-[10px] uppercase'>IMPACT P.</div>
              </div>
            </div>
          </>
        ) : (
          <div className='text-zinc-500 rounded-2xl pl-2 pr-3 py-1 bg-gray-100 text-sm'>No products available</div>
        )}
      </div>
    </div>
  );
});
