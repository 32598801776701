import { useState } from 'react';
import { PieChart } from '../../../../../components/charts/PieChart';
import { RadarChart } from '../../../../../components/charts/RadarChart';
import {
  convertFromImpactStagesMatrix,
  convertFromLifecycleStageImpacts,
  SunburstChart,
} from '../../../../../components/charts/SunburstChart/SunburstChart';
import { NavigationButtons } from '../Sku/components/NavigationButtons';
import { CollapsibleSection } from '../CollapsibleSection';
import { ImpactSelect } from '../../../../../components/ImpactSelect';
import {
  ByStageContributionTable,
  fromMatrixComponent,
  fromStageImpactsComponent,
} from '../../../../../components/ByStageContributionTable';
import { ByImpactContributionModellingTable } from './components/ByImpactContributionModellingTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LifeCycleStagesLegend } from '../../../../../components/charts/LifeCycleStagesLegend';
import { NavLink } from 'react-router-dom';
import { simplify, roundToLong, roundToShort } from '../../../shared';
import { ModellingReport } from '../../../../../api';
import { ImpactValueType } from '../Sku/Overview';
import cn from 'classnames';

interface Props {
  data: ModellingReport;
}

export const ImpactChangeResults = (props: Props) => {
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact' };
  const [selectedImpact, setSelectedImpact] = useState<{ id: string; name: string }>(overallImpact);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);
  const [selectedEntity, setSelectedEntity] = useState<'original' | 'modelled'>('original');

  const colors: { [key: string]: string } = {
    pef_climate_change: 'bg-indigo-50',
    pef_acidification: 'bg-indigo-200',
    pef_particulate_matter: 'bg-sky-50',
    pef_eutrophication_terrestrial: 'bg-sky-200',
    pef_resource_use_fossils: 'bg-green-50',
    pef_land_use: 'bg-green-200',
    pef_eutrophication_marine: 'bg-yellow-50',
    pef_ecotoxicity_freshwater: 'bg-amber-200',
    pef_ozone_formation: 'bg-purple-50',
    pef_human_toxicity_non_cancer: 'bg-purple-200',
    pef_resource_use_minerals: 'bg-indigo-50',
    pef_water_scarcity: 'bg-indigo-200',
    pef_eutrophication_freshwater: 'bg-sky-50',
    pef_ionising_radiation: 'bg-sky-200',
    pef_human_toxicity_cancer: 'bg-green-50',
    pef_ozone_depletion: 'bg-green-200',
  };

  const impacts = [
    overallImpact,
    ...props.data.calculations.product.impacts.map((impact) => ({
      id: impact.id,
      name: impact.name,
    })),
  ];

  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm w-full'>
      <div className='flex justify-between gap-8'>
        <div className='text-zinc-500 ml-3 flex flex-col gap-6'>
          <div>
            An overview of the modelling results, showing how the total environmental impact has been affected by the proposed changes, and
            how it is broken down by impact category, by life cycle stage and by underlying process.
          </div>
          <div>
            To better understand the contribution each impact category, life cycle stage or process has on your product’s total
            environmental impact, those contributing at least 80% of the total environmental impact have been flagged as Most Relevant in
            this analysis and are highlighted in orange throughout the section.
          </div>
        </div>
        <NavigationButtons type='icons' back={{ path: '../proposed-changes' }} next={{ path: '../interpretation' }} />
      </div>
      <CollapsibleSection title='Total environmental impact'>
        <div className='flex justify-evenly'>
          {[
            {
              name: 'Original',
              normalisedValue: simplify(props.data.calculations.product.overallImpact),
              impactPoints: simplify(props.data.calculations.product.impactPoints),
            },
            {
              name: 'Modelled',
              normalisedValue: simplify(props.data.calculations.model.overallImpact),
              impactPoints: simplify(props.data.calculations.model.impactPoints),
            },
          ].map((item, i) => (
            <div key={i} className='mt-3'>
              <div className='text-xl font-semibold'>{item.name}</div>
              <div className='flex flex-col items-center gap-3 border rounded-2xl p-6 mt-3'>
                <div className='flex gap-2'>
                  <div className='text-violet-800 text-3xl font-semibold'>{item.normalisedValue}</div>
                  <div className='flex self-end font-semibold text-lg'>weighted person.year</div>
                </div>
                <div className='uppercase'>or</div>
                <div className='flex gap-2'>
                  <div className='text-violet-800 text-3xl font-semibold'>{item.impactPoints}</div>
                  <div className='flex self-end font-semibold text-lg'>Impact points</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Total environmental impact breakdown'>
        <div className='flex flex-col gap-6'>
          <ImpactSelect
            theme='dark'
            impacts={impacts}
            selectedImpact={selectedImpact}
            setSelectedImpact={setSelectedImpact}
            selectedImpactValueType={selectedImpactValueType}
            setSelectedImpactValueType={setSelectedImpactValueType}
          />
          <div className='bg-slate-200 text-violet-800 font-semibold w-full text-center rounded-md p-1'>By impact category</div>
          <div className='flex flex-col gap-6'>
            {[
              {
                name: 'Original',
                impacts: props.data.calculations.product.impacts,
                normalisedValue: props.data.calculations.product.impacts.find(({ id }) => id === selectedImpact.id)?.normalisedValue,
                impactPoints: props.data.calculations.product.impacts.find(({ id }) => id === selectedImpact.id)?.impactPoints,
                physicalValue: props.data.calculations.product.impacts.find(({ id }) => id === selectedImpact.id)?.physicalValue,
                impactUnit: props.data.calculations.product.impacts.find(({ id }) => id === selectedImpact.id)?.unit,
              },
              {
                name: 'Modelled',
                impacts: props.data.calculations.model.impacts,
                normalisedValue: props.data.calculations.model.impacts.find(({ id }) => id === selectedImpact.id)?.normalisedValue,
                impactPoints: props.data.calculations.model.impacts.find(({ id }) => id === selectedImpact.id)?.impactPoints,
                physicalValue: props.data.calculations.model.impacts.find(({ id }) => id === selectedImpact.id)?.physicalValue,
                impactUnit: props.data.calculations.model.impacts.find(({ id }) => id === selectedImpact.id)?.unit,
              },
            ].map((item, i) => (
              <div key={i} className='grid grid-cols-8 gap-6'>
                <div className='flex flex-col gap-1 col-span-5'>
                  <div className='font-semibold text-xl'>{item.name}</div>
                  <div className='flex w-full rounded-md overflow-hidden mt-1.5'>
                    {item.impacts.map((impact, j) => (
                      <div
                        key={j}
                        style={{
                          width: `${impact.contributionPercent}%`,
                        }}
                        className='flex flex-col gap-2'
                      >
                        <div
                          key={j}
                          title={`${impact.name} - ${roundToShort(impact.contributionPercent)}%`}
                          className={cn(
                            'flex items-center justify-center h-14',
                            { 'rounded-l-md': j === 0 },
                            { 'border border-amber-400': impact.isMajorImpact },
                            { 'text-white': impact.id === selectedImpact.id },
                            impact.id === selectedImpact.id ? 'bg-brand' : colors[impact.id],
                          )}
                        >
                          <div className='truncate'>
                            {impact.contributionPercent > 5 ? (
                              <div className='flex gap-0.5 items-center'>
                                <div className='font-semibold'>{roundToShort(impact.contributionPercent)}</div>
                                <div className='text-xs'>%</div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='flex w-full'>
                    {item.impacts.map((impact, j) => (
                      <div
                        key={j}
                        style={{
                          width: `${impact.contributionPercent}%`,
                        }}
                        title={`${impact.name} - ${roundToShort(impact.contributionPercent)}%`}
                        className={cn('truncate', { invisible: impact.contributionPercent < 5 })}
                      >
                        <div className='pr-0.5 truncate text-xs text-zinc-500'>{impact.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
                {selectedImpact.id === overallImpact.id ? (
                  (() => {
                    const entity =
                      item.name === 'Original'
                        ? {
                            normalisedValue: props.data.calculations.product.overallImpact,
                            impactPoints: props.data.calculations.product.impactPoints,
                          }
                        : {
                            normalisedValue: props.data.calculations.model.overallImpact,
                            impactPoints: props.data.calculations.model.impactPoints,
                          };

                    return (
                      <div className='flex justify-self-center gap-1 items-center mt-4 col-span-3'>
                        <div title={roundToLong(entity.normalisedValue)} className='whitespace-nowrap'>
                          {simplify(entity.normalisedValue)}
                        </div>
                        <div className='text-sm text-center'>weighted person.year</div>
                        <div className='uppercase text-sm'>or</div>
                        <div title={roundToLong(entity.impactPoints)} className='font-semibold'>
                          {simplify(entity.impactPoints)}
                        </div>
                        <div className='font-semibold'>Impact points</div>
                      </div>
                    );
                  })()
                ) : selectedImpactValueType === ImpactValueType.Points ? (
                  <div className='flex justify-self-center gap-1 items-center mt-4 col-span-3'>
                    <div title={roundToLong(item.normalisedValue!)} className='whitespace-nowrap'>
                      {simplify(item.normalisedValue)}
                    </div>
                    <div className='text-sm text-center'>weighted person.year</div>
                    <div className='uppercase text-sm'>or</div>
                    <div title={roundToLong(item.impactPoints!)} className='font-semibold'>
                      {simplify(item.impactPoints)}
                    </div>
                    <div className='font-semibold'>Impact points</div>
                  </div>
                ) : (
                  <div className='col-span-3 flex self-center mt-4 font-semibold'>
                    {simplify(item.physicalValue)} {item.impactUnit}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className='border shadow-regular rounded-regular'>
            <div className='text-lg font-semibold border-b border-zinc-200 py-3 text-center'>
              Final environmental impact per impact category
            </div>
            <div className='flex flex-col justify-center items-center w-full p-6'>
              <RadarChart
                height={400}
                width={600}
                meta={[
                  { dataKey: 'original', color: '#000000' },
                  { dataKey: 'modelled', color: '#4f00ff' },
                ]}
                payload={props.data.calculations.product.impacts.map((impact) => ({
                  impactName: impact.name,
                  original: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)!.impactPoints,
                  originalAbsSharePercent: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)!
                    .contributionPercent,
                  isMajorOriginal: props.data.calculations.product.impacts.find((iter) => iter.id === impact.id)?.isMajorImpact,
                  modelled: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)!.impactPoints,
                  modelledAbsSharePercent: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)!.contributionPercent,
                  isMajorModelled: props.data.calculations.model.impacts.find((iter) => iter.id === impact.id)?.isMajorImpact,
                  isMajor: impact.isMajorImpact,
                  keyName: undefined,
                }))}
                tooltipType='multiple'
              />
              <div className='flex gap-6'>
                {[
                  { name: 'Modelled', color: 'bg-brand' },
                  { name: 'Original', color: 'bg-dark' },
                ].map((item, i) => (
                  <div key={i} className='flex items-center gap-1'>
                    <div className={cn('h-2.5 aspect-square full rounded-full shrink-0', item.color)} />
                    <div className='text-sm text-dark'>{item.name}</div>
                  </div>
                ))}
                <div className='flex items-center gap-1'>
                  <div className={cn('h-2.5 aspect-square rounded-full border-2 border-amber-400')} />
                  <div className='text-sm text-dark'>Most relevant</div>
                </div>
              </div>
            </div>
          </div>

          <div className='border shadow-regular rounded-regular'>
            <div className='text-lg font-semibold border-b border-zinc-200 py-3 text-center'>
              Physical impact, final environmental impact and contribution per impact category
            </div>
            <ByImpactContributionModellingTable selectedImpactId={selectedImpact.id} data={props.data} />
          </div>

          <div className='bg-slate-200 text-violet-800 font-semibold w-full text-center rounded-md p-1 mt-6'>By life cycle stage</div>
          <div className='border shadow-regular rounded-regular pb-6'>
            <div className='text-lg font-semibold border-b border-zinc-200 py-3 text-center'>Contribution per life cycle stage</div>
            {selectedImpact.id === overallImpact.id ? (
              <div className='grid grid-cols-2 gap-6'>
                {[
                  { name: 'Original', stages: props.data.calculations.product.analysis.lifecycleStageImpacts },
                  { name: 'Modelled', stages: props.data.calculations.model.analysis.lifecycleStageImpacts },
                ].map(({ name, stages }, i) => (
                  <div key={i} className='flex flex-col gap-6 justify-center items-center'>
                    <PieChart
                      showTicks
                      size={420}
                      outerRadius={150}
                      data={stages.map((stage) => ({
                        name: stage.name,
                        bgColor: stage.bgColor!,
                        isMajor: stage.isMajorImpact,
                        value: stage.absImpactSharePercent,
                      }))}
                    />
                    <div className='-mt-6 font-semibold'>{name}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className='grid grid-cols-2 gap-6'>
                {[
                  {
                    name: 'Original',
                    stages: props.data.calculations.product.analysis.impactStagesMatrix.find(
                      ({ impactId }) => selectedImpact.id === impactId,
                    )!.stages,
                  },
                  {
                    name: 'Modelled',
                    stages: props.data.calculations.model.analysis.impactStagesMatrix.find(
                      ({ impactId }) => selectedImpact.id === impactId,
                    )!.stages,
                  },
                ].map(({ name, stages }, i) => (
                  <div key={i} className='flex flex-col gap-6 justify-center items-center'>
                    <PieChart
                      showTicks
                      size={420}
                      outerRadius={150}
                      data={stages.map((stage) => ({
                        name: stage.name,
                        bgColor: stage.bgColor!,
                        isMajor: stage.isMajorStage,
                        value: stage.absSharePercent,
                      }))}
                    />
                    <div className='font-semibold'>{name}</div>
                  </div>
                ))}
              </div>
            )}
            <div className='flex justify-center mt-8'>
              <LifeCycleStagesLegend renderMajor />
            </div>
          </div>

          <div className='bg-slate-200 text-violet-800 font-semibold w-full text-center rounded-md p-1 mt-6'>By process</div>
          <div className='border shadow-regular rounded-regular pb-6'>
            <div className='text-lg font-semibold border-b border-zinc-200 py-3 text-center'>
              Contribution per life cycle stage and underlying processes
            </div>
            {selectedImpact.id !== overallImpact.id && (
              <div className='grid grid-cols-2 gap-6 mt-12'>
                {[
                  { name: 'Original', impacts: props.data.calculations.product.analysis.impactStagesMatrix },
                  { name: 'Modelled', impacts: props.data.calculations.model.analysis.impactStagesMatrix },
                ].map(({ name, impacts }, i) => (
                  <div key={i} className='flex flex-col gap-6 items-center'>
                    <SunburstChart
                      size={350}
                      selectedCategory={impacts.find(({ impactId }) => selectedImpact.id === impactId)}
                      showPhysicalImpact={selectedImpactValueType === ImpactValueType.Physical}
                      selectedCategoryPhysicalUnit={impacts.find(({ impactId }) => selectedImpact.id === impactId)!.unit}
                      data={convertFromImpactStagesMatrix(impacts.find(({ impactName }) => impactName === selectedImpact.name)!.stages)}
                    />
                    <div className='font-semibold mt-6'>{name}</div>
                  </div>
                ))}
              </div>
            )}
            {selectedImpact.id === overallImpact.id && (
              <div className='grid grid-cols-2 gap-6 mt-12'>
                {[
                  { name: 'Original', impacts: props.data.calculations.product.analysis.lifecycleStageImpacts },
                  { name: 'Modelled', impacts: props.data.calculations.model.analysis.lifecycleStageImpacts },
                ].map(({ name, impacts }, i) => (
                  <div key={i} className='flex flex-col gap-6 items-center'>
                    <SunburstChart size={350} data={convertFromLifecycleStageImpacts(impacts)} />
                    <div className='font-semibold mt-6'>{name}</div>
                  </div>
                ))}
              </div>
            )}
            <div className='flex justify-center mt-8'>
              <LifeCycleStagesLegend renderMajor />
            </div>
          </div>

          <div className='text-zinc-500'>
            In addition to the charts above, a table with the underlying data is presented below. This table shows from highest to lowest
            life cycle stage contribution, the final environmental impacts in weighted person years (after normalisation and weighting), and
            the contribution (%) of each life cycle stage to the total impact. It also presents the underlying Processes within each life
            cycle stage to understand the root cause of the impact in more detail. Above the table, you can toggle between the Original
            product and the Modelled product to compare the results.
          </div>
          <div className='text-zinc-500'>
            Rows highlighted in yellow or displaying the exclamation icon in the table, for both life cycle stages and processes represent
            those contributing the most to the total environmental impact of the product (80% total minimum). These are the most relevant
            processes or the most influential ones. It should be noted that in order to highlight the relevance of any credits (e.g. from
            recycling), absolute values are taken when calculating the contribution. Hence, negative impact processes may show up as ‘Most
            relevant’ processes. When highlighted as most relevant, it shows how much influence these processes have on your overall impact.
          </div>

          <div className='grid grid-cols-2 gap-x-3 bg-slate-100 rounded-xl border p-0.5'>
            {[
              {
                id: 'original',
                label: 'Original',
                isActive: selectedEntity === 'original',
                onClick: () => setSelectedEntity('original'),
              },
              {
                id: 'modelled',
                label: 'Modelled',
                isActive: selectedEntity === 'modelled',
                onClick: () => setSelectedEntity('modelled'),
              },
            ].map(({ id, label, isActive, onClick }) => (
              <button
                key={id}
                onClick={onClick}
                className={cn('flex gap-3 items-center py-2 px-4 rounded-2xl text-xl', {
                  'bg-white border': isActive,
                  'text-zinc-500': !isActive,
                })}
              >
                <FontAwesomeIcon
                  className={cn('w-5 aspect-square p-2 rounded-full', isActive ? 'rotate-90 bg-slate-50 text-brand' : 'bg-white')}
                  icon={light('arrow-right')}
                />
                {label}
              </button>
            ))}
          </div>

          {(() => {
            const data = selectedEntity === 'original' ? props.data.calculations.product : props.data.calculations.model;

            return (
              <div className='border shadow-regular rounded-regular'>
                <div className='text-lg font-semibold border-zinc-200 py-3 text-center'>
                  Final environmental impact and contribution per life cycle stage and underlying process
                </div>
                <div className='p-3'>
                  {selectedImpact.id === overallImpact.id && (
                    <ByStageContributionTable
                      {...(() =>
                        selectedEntity === 'original'
                          ? {
                              productName: props.data.product.name,
                              selectedImpact,
                              selectedImpactValueType,
                            }
                          : {
                              productName: props.data.model.title,
                              selectedImpact,
                              selectedImpactValueType,
                            })()}
                      data={data.analysis.lifecycleStageImpacts.map((impact) => ({
                        ...impact,
                        value: impact.impactValue,
                        absSharePercent: impact.absImpactSharePercent,
                        components: fromStageImpactsComponent(impact.components),
                      }))}
                      totalImpact={data.overallImpact}
                      totalPoints={data.impactPoints}
                    />
                  )}

                  {selectedImpact.id !== overallImpact.id && (
                    <ByStageContributionTable
                      {...(() =>
                        selectedEntity === 'original'
                          ? {
                              productName: props.data.product.name,
                              selectedImpact,
                              selectedImpactValueType,
                              unit: props.data.calculations.product.impacts.find(({ id }) => id === selectedImpact.id)!.unit,
                            }
                          : {
                              productName: props.data.model.title,
                              selectedImpact,
                              selectedImpactValueType,
                              unit: props.data.calculations.model.impacts.find(({ id }) => id === selectedImpact.id)!.unit,
                            })()}
                      showPhysicalValues={selectedImpactValueType === ImpactValueType.Physical}
                      data={data.analysis.impactStagesMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map((stage) => ({
                          ...stage,
                          absSharePercent: stage.absSharePercent,
                          value: selectedImpactValueType === ImpactValueType.Physical ? stage.physicalValue : stage.impactPoints,
                          isMajorImpact: stage.isMajorStage,
                          components: fromMatrixComponent(stage.components, selectedImpactValueType === ImpactValueType.Physical),
                        }))}
                      totalImpact={
                        selectedImpactValueType === ImpactValueType.Physical
                          ? data.analysis.impactStagesMatrix
                              .find(({ impactId }) => impactId === selectedImpact.id)!
                              .stages.map(({ physicalValue }) => physicalValue)
                              .reduce((a, b) => a + b, 0)
                          : data.analysis.impactStagesMatrix
                              .find(({ impactId }) => impactId === selectedImpact.id)!
                              .stages.map(({ weightedNormalisedValue }) => weightedNormalisedValue)
                              .reduce((a, b) => a + b, 0)
                      }
                      totalPoints={data.analysis.impactStagesMatrix
                        .find(({ impactId }) => impactId === selectedImpact.id)!
                        .stages.map(({ impactPoints }) => impactPoints)
                        .reduce((a, b) => a + b, 0)}
                    />
                  )}
                </div>
              </div>
            );
          })()}

          <div className='text-zinc-500'>
            In addition to the information displayed above, a detailed analysis of the impact of the five life cycle stages and processes on
            each of the 16 impact categories has also been carried out. Results are presented in the{' '}
            <NavLink to='../appendix' onClick={() => window.scrollTo(0, 0)} className='hover:text-brand font-semibold underline'>
              Appendix
            </NavLink>
            . This is important if you wish to focus on the improvement of certain impact categories that are relevant to different life
            cycle stages.
          </div>
        </div>
      </CollapsibleSection>

      <NavigationButtons
        type='buttons'
        back={{ path: '../proposed-changes', label: 'Proposed Changes' }}
        next={{ path: '../interpretation', label: 'Interpretation' }}
      />
    </div>
  );
};
