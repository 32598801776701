import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FieldProps } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { KeyboardEvent, RefObject, useState } from 'react';
import { Entity } from '../api';
import { useControlEvents } from '../hooks/useControlEvents';

interface Props {
  inputRef?: RefObject<HTMLInputElement>;
  model?: FieldProps<number>;
  error?: boolean;
  value?: number;
  unit: {
    model?: FieldProps<Entity>;
    options: Entity[];
  };
  integer?: boolean;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
}

export const UnitInputV3 = (props: Props) => {
  const controlEvents = useControlEvents();
  const step = props.integer ? 1 : 0.0000000000001;
  const [focused, setFocused] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const error = props.model?.meta.error || props.error;
  const unitError = props.unit.model?.meta.error || props.error;

  return (
    <div className='flex relative'>
      <input
        ref={props.inputRef}
        type='number'
        min={0}
        step={step}
        autoComplete='off'
        autoFocus={props.autoFocus}
        placeholder={props.placeholder ?? (props.integer ? '0' : '0.0')}
        disabled={props.disabled}
        className={cn(
          'w-full rounded-l-lg border focus:z-10 px-3 py-1.5 placeholder:text-zinc-400',
          {
            'border-r-[#FA4D0A]': unitError,
            'no-arrows': !props.integer,
          },
          (() => {
            if (error) {
              return 'border-[#FA4D0A] focus:ring-focusRingError';
            }

            if (props.disabled) {
              return 'text-neutral-400 border-neutral-400 bg-neutral-100';
            }

            return 'border-zinc-500';
          })(),
        )}
        {...(props.model?.field ?? {})}
        value={props.model?.field.value ?? props.value ?? ''}
        onChange={(event) => {
          if (controlEvents) {
            controlEvents.touched(props.model);
          }

          if (event.target.value === '') {
            props.model?.form.setFieldValue(props.model.field.name, null);
          } else {
            props.model?.field.onChange(event);
          }
        }}
        onMouseDown={() => {
          if (controlEvents) {
            controlEvents.touched(props.model);
          }
        }}
        onKeyDown={props.onKeyDown}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        onWheel={(event) => {
          // prevent number input change on scroll over the input
          (event.target as HTMLElement).blur();
        }}
      />
      <div className='flex relative'>
        <select
          className={cn(
            'peer rounded-r-lg border border-l-0 w-[5.25rem] pl-3 pr-10 appearance-none disabled:w-[4.25rem] disabled:pr-0',
            (() => {
              if (unitError) {
                return 'border-[#FA4D0A] focus:ring-focusRingError';
              }

              if (props.disabled) {
                return 'text-neutral-400 border-neutral-400 bg-neutral-100';
              }

              return 'border-zinc-500';
            })(),
          )}
          disabled={!props.unit.model ?? props.disabled}
          {...(props.unit.model?.field ?? {})}
          value={props.unit.model?.field.value?.id ?? ''}
          onChange={(event) => {
            props.unit.model!.form.setFieldValue(
              props.unit.model!.field.name,
              props.unit.options.find(({ id }) => id === event.target.value),
            );
          }}
          onMouseDown={() => {
            if (controlEvents) {
              controlEvents.touched(props.unit.model);
            }
          }}
        >
          {props.unit.model && <option value='' disabled></option>}
          {props.unit.options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <div
          className={cn(
            'peer-disabled:hidden h-full flex items-center absolute  right-4 pointer-events-none',
            unitError ? 'text-[#FA4D0A]' : 'text-brand',
          )}
        >
          <FontAwesomeIcon icon={regular('chevron-down')} />
        </div>
      </div>
      <AnimatePresence>
        {(focused || mouseOver) && (props.model?.field?.value ?? 0) >= 10_000 && (
          <motion.div
            transition={{ type: 'spring', duration: 0.3 }}
            initial={{ opacity: 0, translateY: 2 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 2 }}
            className={cn(
              'z-10 absolute top-full left-2 pointer-events-none',
              'antialiased leading-none text-[10px] font-semibold rounded-b-[4px] text-zinc-600 bg-[#E8EAF5] shadow-md px-1.5 py-1',
            )}
          >
            {(() => {
              // https://stackoverflow.com/questions/51568821
              const value = props.model!.field.value.toLocaleString();
              let parts = [value];

              try {
                parts = value.split(new RegExp('(?=[,.])|(?<=[,.])', 'g'));
              } catch (e) {}

              return parts.map((part, i) => (
                <span
                  key={i}
                  className={cn({
                    'mx-0.5 leading-[0] text-sm': [',', '.'].includes(part),
                  })}
                >
                  {part}
                </span>
              ));
            })()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
