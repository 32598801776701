export const ModelSkeletonLoader = () => {
  const skeleton = (height?: string, width?: string, radius?: string) => {
    return (
      <div
        className='loading-skeleton'
        style={{
          height: height,
          width: width,
          borderRadius: radius,
        }}
      />
    );
  };

  return (
    <div className='mb-20 -mt-3'>
      <div className='flex justify-between mx-6'>
        <div className='flex w-full items-center gap-6'>
          {skeleton('32px', '32px', '10px')}
          {skeleton('26px', '30%')}
        </div>
        <div className='flex items-center gap-6'>
          {skeleton('32px', '116px', '20px')}
          {skeleton('32px', '146px', '20px')}
        </div>
      </div>

      <div className='flex gap-x-6 w-full my-10 mx-6'>
        {skeleton('112px', '112px', '10px')}
        <div className='flex flex-col justify-between gap-y-1'>
          {skeleton('20px', '200px')}
          <div className='mt-6 flex flex-col gap-y-3'>
            {skeleton('16px', '300px')}
            {skeleton('16px', '300px')}
          </div>
        </div>

        <div className='border-r' />
        <div className='flex flex-col justify-between gap-y-1'>
          {skeleton('16px', '300px')}
          {skeleton('16px', '300px')}
          {skeleton('16px', '300px')}
          {skeleton('16px', '300px')}
        </div>
      </div>

      <div className='flex justify-center bg-slate-50 border-y border-zinc-300 pb-20 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.6))]'>
        <div className='px-12 py-6 w-full items-center justify-center max-w-screen-xl'>
          <div className='flex flex-col gap-y-6'>
            {skeleton('14px', '200px')}
            <div className='flex gap-x-6'>
              <div className='h-[154px] aspect-square'>{skeleton('100%', '100%', '20px')}</div>
              <div className='h-[154px] w-[440px]'>{skeleton('100%', '100%', '20px')}</div>
            </div>

            {skeleton('14px', '200px')}
            <div className='flex gap-x-6'>
              <div className='h-[154px] w-1/2'>{skeleton('100%', '100%', '20px')}</div>
              <div className='h-[154px] w-1/2'>{skeleton('100%', '100%', '20px')}</div>
            </div>
          </div>

          <div className='flex flex-col gap-y-3 mt-6'>
            {new Array(4).fill(null).map((_, i) => (
              <div key={i} className='break-inside-avoid'>
                <div className='h-[56px] border rounded-2xl w-full flex justify-between items-center px-6 py-4'>
                  <div className='flex items-center w-full gap-8'>
                    {skeleton('20px', '20px', '100%')}
                    <div className='flex flex-col gap-3 flex-1'>{skeleton('24px', '30%')}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
