import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FieldProps } from 'formik';
import { FocusEventHandler, ReactNode } from 'react';
import ReactSelect, {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  GroupHeadingProps,
  IndicatorSeparatorProps,
  IndicatorsContainerProps,
  InputProps,
  LoadingIndicatorProps,
  MenuListProps,
  MenuProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  OptionProps,
  PlaceholderProps,
  Props as ReactSelectProps,
  SingleValueProps,
  ValueContainerProps,
  components,
} from 'react-select';
import ReactSelectAsync from 'react-select/async';
import { Entity, StaticEntity } from '../api';
import { useControlEvents } from '../hooks/useControlEvents';

interface Props<T> {
  model?: FieldProps<any>;
  value?: any;
  getOptionValue?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  isOptionShownAsSelected?: (option: T) => boolean;
  convertOptions?: {
    fromModel: (value: any) => any;
    toModel: (value: any) => any;
  };
  onActions?: {
    add: (option: T) => void;
    remove: (option: T, index?: number) => void;
    clear: () => void;
  };
  loadOptions?: (input: string, callback: (options: T[]) => void) => void;
  adjustChange?: (value: any) => any;
  options?: T[];
  multi?: boolean;
  multiRepeated?: boolean;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  menuPortalTarget?: HTMLElement | null;
  renderOptionInMenu?: (option: T, states: { hovered: boolean; selected: boolean }) => ReactNode;
  renderOptionBadge?: (option: T) => ReactNode;
  menuFooter?: ReactNode;
  isOptionDisabled?: (option: T) => boolean;
  hideInputBorder?: boolean;
  noClear?: boolean;
  inputValueButton?: () => void;
  renderOptionIcon?: (option: T) => ReactNode;
}

export const SelectV3 = <T extends any>(props: Props<T>) => {
  const controlEvents = useControlEvents();
  const modelValue = props.model?.field.value ?? props.value;
  const selectValue = props.convertOptions ? props.convertOptions.fromModel(modelValue) : modelValue;
  const indexedSelectValue = props.multiRepeated ? (selectValue as any[]).map((option, _index) => ({ ...option, _index })) : selectValue;
  const value = props.isOptionShownAsSelected ? (indexedSelectValue as any[]).filter(props.isOptionShownAsSelected) : indexedSelectValue;

  const getOptionValue = props.getOptionValue || ((option: T) => (option as Entity).id ?? (option as StaticEntity).type);
  const getOptionLabel = props.getOptionLabel || ((option: T) => (option as Entity).name);

  const reactSelectProps: ReactSelectProps<T> = {
    isClearable: props.noClear ? false : true,
    isMulti: props.multi,
    isDisabled: props.disabled,
    autoFocus: props.autoFocus,
    placeholder: props.placeholder ?? `Choose${props.multi ? ' one or many' : ''}…`,
    loadingMessage: () => 'Searching…',
    noOptionsMessage: () => 'No matches found…',
    value: value ?? '',
    options: props.options,
    getOptionValue: (option) => (option as any)._index ?? getOptionValue(option),
    getOptionLabel: getOptionLabel,
    onChange: (value, meta) => {
      if (controlEvents) {
        controlEvents.touched(props.model);
      }

      if (props.onActions) {
        if (meta.action === 'select-option') {
          props.onActions.add(meta.option ?? (value as any));
        }

        if (meta.action === 'remove-value') {
          const option = meta.removedValue!;
          props.onActions.remove(option, props.multiRepeated ? (option as any)._index : undefined);
        }

        if (meta.action === 'clear') {
          props.onActions.clear();
        }
      } else {
        props.model!.form.setFieldValue(
          props.model!.field.name,
          (() => {
            if (props.convertOptions) {
              return props.convertOptions.toModel(value);
            }

            if (props.adjustChange) {
              return props.adjustChange(value);
            }

            return value;
          })(),
        );
      }
    },
    onMenuOpen: () => {
      if (controlEvents) {
        controlEvents.touched(props.model);
      }
    },
    onFocus: props.onFocus,
    onBlur: props.model?.field.onBlur,
    menuPortalTarget: props.menuPortalTarget,
    components: Components,
    ...{
      model: props.model,
      menuFooter: props.menuFooter,
      renderOptionInMenu: props.renderOptionInMenu,
      renderOptionBadge: props.renderOptionBadge,
      isOptionDisabled: props.isOptionDisabled,
      hideInputBorder: props.hideInputBorder,
      inputValueButton: props.inputValueButton,
      renderOptionIcon: props.renderOptionIcon,
    },
  };

  return props.loadOptions ? (
    <ReactSelectAsync<T, boolean> {...reactSelectProps} loadOptions={props.loadOptions} defaultOptions />
  ) : (
    <ReactSelect<T, boolean> {...reactSelectProps} />
  );
};

const selectProps = (props: { selectProps: ReactSelectProps }) => props.selectProps as any as Props<any>;

const Control = (props: ControlProps<any>) => {
  const model = selectProps(props).model;
  const hideInputBorder = selectProps(props).hideInputBorder;

  return (
    <components.Control
      {...props}
      getStyles={() => ({
        ...props.getStyles('control', props),
        backgroundColor: '',
        borderWidth: '',
        borderColor: '',
        borderRadius: '',
        padding: '',
        minHeight: '',
        boxShadow: '',
        transition: '',
        '&:hover': {},
      })}
      className={
        hideInputBorder
          ? cn(
              'transition rounded-lg px-3 py-1.5 border',
              {
                'hover:bg-gray-100': !props.isFocused && !model?.meta.error,
                'shadow-md border-zinc-200': props.isFocused && !model?.meta.error,
                'ring-4': props.isFocused && model?.meta.error,
              },
              model?.meta.error ? 'border-[#FA4D0A] ring-focusRingError' : 'border-transparent ring-focusRing',
            )
          : cn(
              'transition rounded-lg px-3 py-1.5 border',
              model?.meta.error ? 'border-[#FA4D0A] ring-focusRingError' : 'border-zinc-500 ring-focusRing',
              props.isFocused && model?.meta.error ? 'ring-focusRingError' : 'ring-focusRing',
              props.isDisabled ? 'bg-neutral-100 text-neutral-400' : 'bg-white',
              {
                'border-neutral-400': props.isDisabled && !model?.meta.error,
                'ring-4': props.isFocused,
                'pl-2': props.selectProps.isMulti && props.selectProps.value.length > 0,
              },
            )
      }
    />
  );
};

const ValueContainer = (props: ValueContainerProps<any>) => (
  <components.ValueContainer
    {...props}
    getStyles={() => ({
      ...props.getStyles('valueContainer', props),
      padding: '',
    })}
    className='flex gap-2'
  />
);

const MultiValueContainer = (props: MultiValueGenericProps<any>) => (
  <components.MultiValueContainer
    {...props}
    innerProps={{
      ...props.innerProps,
      className: 'flex items-center bg-[#E8EAF5] rounded-md pl-2',
    }}
  />
);

const MultiValueLabel = (props: MultiValueGenericProps<any>) => (
  <components.MultiValueLabel
    {...props}
    innerProps={{
      ...props.innerProps,
      className: 'text-xs py-0.5',
    }}
  />
);

const MultiValueRemove = (props: MultiValueRemoveProps<any>) =>
  selectProps(props).isOptionDisabled && selectProps(props).isOptionDisabled!(props.data) ? (
    <div className='pr-2.5' />
  ) : (
    <div {...props.innerProps} className='cursor-pointer flex text-[0.75rem] py-1 pr-2 pl-2.5 box-content'>
      <FontAwesomeIcon icon={regular('times')} />
    </div>
  );

const SingleValue = (props: SingleValueProps<any>) => {
  const inputValueButton = selectProps(props).inputValueButton;

  return (
    <components.SingleValue
      {...props}
      getStyles={() => ({
        ...props.getStyles('singleValue', props),
        color: '',
        marginLeft: '',
        marginRight: '',
      })}
    >
      <div className='flex gap-1 items-center'>
        {selectProps(props).renderOptionIcon && selectProps(props).renderOptionIcon!(props.data)}
        {props.children}
        {inputValueButton && inputValueButton()}
      </div>
    </components.SingleValue>
  );
};

const Input = (props: InputProps<any>) => (
  <components.Input
    {...props}
    getStyles={() => ({
      ...props.getStyles('input', props),
      margin: '',
      paddingTop: '',
      paddingBottom: '',
    })}
    inputClassName='focus-visible:ring-transparent'
  />
);

const Placeholder = (props: PlaceholderProps<any>) => (
  <components.Placeholder
    {...props}
    getStyles={() => ({
      ...props.getStyles('placeholder', props),
      color: '',
      marginLeft: '',
      marginRight: '',
    })}
    className='text-gray-400 truncate'
  />
);

const IndicatorsContainer = (props: IndicatorsContainerProps<any>) => (
  <components.IndicatorsContainer
    {...props}
    getStyles={() => ({
      ...props.getStyles('indicatorsContainer', props),
    })}
    className={cn('flex', { 'gap-2': !selectProps(props).hideInputBorder })}
  >
    {!props.isMulti && props.selectProps.value && selectProps(props).renderOptionBadge && (
      <div className='flex ml-1'>{selectProps(props).renderOptionBadge!(props.selectProps.value)}</div>
    )}
    {props.children}
  </components.IndicatorsContainer>
);

const IndicatorSeparator = (props: IndicatorSeparatorProps<any>) => (
  <components.IndicatorSeparator
    {...props}
    getStyles={() => ({
      ...props.getStyles('indicatorSeparator', props),
      backgroundColor: '',
      marginTop: '',
      marginBottom: '',
    })}
    className={cn(selectProps(props).model?.meta.error ? 'bg-f' : 'bg-zinc-300', {
      hidden: selectProps(props).hideInputBorder,
    })}
  />
);

// isLoading=false does not seem to work so have to use this workaround to mask the loading indicator
const LoadingIndicator = (props: LoadingIndicatorProps<any>) => (
  <div
    className={cn('flex justify-center w-4', {
      hidden:
        !props.selectProps.isClearable ||
        !(Array.isArray(props.selectProps.value) ? props.selectProps.value.length > 0 : props.selectProps.value),
    })}
  >
    <FontAwesomeIcon
      icon={regular('times')}
      className={selectProps(props).model?.meta.error ? 'text-f' : props.selectProps.isDisabled ? 'text-neutral-400' : 'text-brand'}
    />
  </div>
);

const ClearIndicator = (props: ClearIndicatorProps<any>) => (
  <div
    className={cn(
      'flex justify-center w-4 cursor-pointer',
      selectProps(props).model?.meta.error ? 'text-f' : props.selectProps.isDisabled ? 'text-neutral-400' : 'text-brand',
      {
        hidden: selectProps(props).hideInputBorder && !props.isFocused,
      },
    )}
    {...props.innerProps}
  >
    <FontAwesomeIcon icon={regular('times')} />
  </div>
);

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => (
  <div
    className={cn(
      { 'px-1': !selectProps(props).hideInputBorder },
      selectProps(props).model?.meta.error ? 'text-f' : props.isDisabled ? 'text-neutral-400' : 'text-brand',
    )}
    {...props.innerProps}
  >
    <FontAwesomeIcon className={cn({ hidden: selectProps(props).hideInputBorder })} icon={regular('chevron-down')} />
  </div>
);

const Menu = (props: MenuProps<any>) => (
  <components.Menu
    {...props}
    getStyles={() => ({
      ...props.getStyles('menu', props),
      borderRadius: '',
      boxShadow: '',
    })}
    className='shadow-xl border border-gray-300 rounded-xl'
  />
);

const MenuList = (props: MenuListProps<any>) => (
  <components.MenuList
    {...props}
    getStyles={() => ({
      ...props.getStyles('menuList', props),
      paddingTop: '',
      paddingBottom: '',
    })}
    className={cn('py-2.5 text-dark rounded-xl', {
      'pb-0': selectProps(props).menuFooter,
    })}
  >
    {props.children}
    <div className='sticky bottom-0 bg-white rounded-b-xl'>{selectProps(props).menuFooter}</div>
  </components.MenuList>
);

const GroupHeading = (props: GroupHeadingProps<any>) => (
  <components.GroupHeading
    {...props}
    getStyles={() => ({
      ...props.getStyles('groupHeading', props),
      paddingLeft: '',
      paddingRight: '',
    })}
    className={cn('px-4')}
  />
);

const Option = (props: OptionProps<any>) => (
  <components.Option
    {...props}
    getStyles={() => ({
      ...props.getStyles('option', props),
      padding: '',
      cursor: 'pointer',
      color: '',
      backgroundColor: '',
      ':active': {},
    })}
    className={
      selectProps(props).renderOptionInMenu
        ? undefined
        : cn(
            'px-4 py-2',
            props.isSelected ? `${props.isFocused ? 'bg-brandDark' : 'bg-brand'} text-white` : props.isFocused ? 'bg-neutral-100' : '',
          )
    }
  >
    {selectProps(props).renderOptionInMenu ? (
      selectProps(props).renderOptionInMenu!(props.data, { hovered: props.isFocused, selected: props.isSelected })
    ) : (
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          {selectProps(props).renderOptionIcon && selectProps(props).renderOptionIcon!(props.data)}
          <div>{props.children}</div>
        </div>
        {selectProps(props).renderOptionBadge && selectProps(props).renderOptionBadge!(props.data)}
      </div>
    )}
  </components.Option>
);

export const Components = {
  Control,
  ValueContainer,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  SingleValue,
  Input,
  Placeholder,
  IndicatorsContainer,
  IndicatorSeparator,
  LoadingIndicator,
  ClearIndicator,
  DropdownIndicator,
  Menu,
  MenuList,
  GroupHeading,
  Option,
};
