import { Fragment, forwardRef, ReactNode } from 'react';
import { Suppliers } from './Suppliers';
import { TimeframeDisplay } from '../../components/TimeframeDisplay';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timeframe } from '../../types';
import cn from 'classnames';

interface Props {
  title: string;
  timeframe: Timeframe;
  physicalImpact: boolean;
  impactCategories: string[];
}

export const WinnersLosers = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <div ref={ref} className='flex flex-col gap-4'>
    <div className='flex justify-between px-2'>
      <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      <TimeframeDisplay value={props.timeframe} />
    </div>
    <div className='p-6 flex gap-4 bg-white border border-zinc-200 rounded-2xl max-w-[962px] overflow-y-scroll'>
      {[
        {
          icon: light('buildings'),
          title: 'Brands',
          good: {
            name: 'Lipton',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '1.53M' : '10.34M',
          },
          bad: {
            name: '7Up',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '17.8M' : '33.54M',
          },
          detailsModal: () => <></>,
        },
        {
          icon: light('box-circle-check'),
          title: 'Products',
          good: {
            name: 'Dairy-free yoghurt',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '2,577' : '72,495',
          },
          bad: {
            name: 'Chocolate bar',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '7.40M' : '16M',
          },
          detailsModal: () => <></>,
        },
        {
          icon: light('user-group'),
          title: 'Suppliers',
          good: {
            name: 'Saltica',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '30.15' : '921',
          },
          bad: {
            name: 'Cacoa Inc.',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '2.46M' : '18M',
          },
          detailsModal: (payload: { title: string; children: ReactNode }) => (
            <Suppliers title={payload.title} children={payload.children} />
          ),
        },
        {
          icon: light('carrot'),
          title: 'Ingredients',
          good: {
            name: 'Salt',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '36.95' : '1,023',
          },
          bad: {
            name: 'Cacao',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '4.17M' : '21M',
          },
          detailsModal: () => <></>,
        },
        {
          icon: light('box-open'),
          title: 'Packaging',
          good: {
            name: 'Jar',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '84.9' : '567',
          },
          bad: {
            name: 'Tetrapack',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '238' : '1,020',
          },
          detailsModal: () => <></>,
        },
        {
          icon: light('industry-windows'),
          title: 'Prod. facility',
          good: {
            name: 'Pepsico Neuwassen',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '17,800' : '500K',
          },
          bad: {
            name: 'Chocolaterie',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '1.53M' : '4M',
          },
          detailsModal: () => <></>,
        },
        {
          icon: light('home-user'),
          title: 'Consumers',
          good: {
            name: 'Germany',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '487' : '800',
          },
          bad: {
            name: 'France',
            value: props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '142K' : '1.8M',
          },
          detailsModal: () => <></>,
        },
      ].map(({ icon, title, good, bad, detailsModal }, i) => (
        <Fragment key={title}>
          {i > 0 && <div className='shrink-0 w-px bg-zinc-200' />}
          <div className='whitespace-nowrap flex flex-col gap-6'>
            <div className='flex justify-between items-center gap-6 text-base'>
              <div className='flex items-center gap-2'>
                <div className='flex justify-center items-center size-8 rounded-lg text-[#9C75FA] bg-slate-100'>
                  <FontAwesomeIcon icon={icon} />
                </div>
                <div className='text-zinc-800 font-semibold'>{title}</div>
              </div>
              {detailsModal({
                title,
                children: (
                  <button type='button' className='text-zinc-400 flex justify-center items-center size-8'>
                    <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
                  </button>
                ),
              })}
            </div>
            {[good, bad].map((item, j) => (
              <div key={j} className='self-start flex flex-col gap-2'>
                <div className='text-zinc-800 px-1'>{item.name}</div>
                <div
                  className={cn(
                    'flex items-center gap-2 p-2 pr-3 rounded-full text-base leading-none',
                    item === bad ? 'text-red-500 bg-red-50' : 'text-emerald-700 bg-emerald-50',
                  )}
                >
                  <FontAwesomeIcon icon={item === bad ? light('shield-exclamation') : light('trophy')} />
                  <div className='flex gap-1 items-center'>
                    <span>{item.value}</span>
                    <span className='uppercase tracking-uppercase text-[10px]'>
                      {props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact
                        ? 'mt co₂ eq'
                        : 'impact p.'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  </div>
));
