import { Fragment, ReactNode } from 'react';
import { NavigationButtons } from '../../Products/Report/Sku/components/NavigationButtons';

interface Props {
  tabDescription: string;
  records: {
    field: string;
    value: string | number | ReactNode;
  }[];
}

export const ReportSummary = (props: Props) => (
  <div className='flex flex-col gap-8'>
    <div className='flex justify-between gap-8'>
      <div className='text-brandGray5'>{props.tabDescription}</div>
      <NavigationButtons type='icons' next={{ path: '../volumes' }} />
    </div>
    <div className='grid grid-cols-3 p-8 gap-4 w-full border rounded-regular shadow-regular print:shadow-none print:flex print:flex-col'>
      {props.records.map((record) => (
        <Fragment key={record.field}>
          <div className='font-semibold'>{record.field}</div>
          <div className='truncate col-span-2' title={typeof record.value === 'string' ? record.value : undefined}>
            {record.value}
          </div>
        </Fragment>
      ))}
    </div>
    <NavigationButtons next={{ path: '../volumes', label: 'Volumes' }} type='buttons' />
  </div>
);
