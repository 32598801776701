import { ForecastReport } from '../../../../../api';
import { ProductionVolumes } from '../../components/ProductionVolumes';
import { NavigationButtons } from '../../../Products/Report/Sku/components/NavigationButtons';

interface Props {
  data: ForecastReport;
}

export const Volumes = (props: Props) => {
  return (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5'>
          A detailed look at the forecasted production volumes provided during report creation for each product, with product name, type, ID
          and volume in hypothetical number of units manufactured. These volumes represent the forecasted number of units manufactured over
          the specified cycle length
        </div>
        <NavigationButtons type='icons' back={{ path: '../summary' }} next={{ path: '../baseline-comparison' }} />
      </div>
      <ProductionVolumes
        data={props.data}
        tableProps={{
          title: `Forecasted production volumes per product over cycle length (1 ${props.data.cycle})`,
          columnName: 'forecasted volumes',
        }}
        description='A detailed view of the input data, including a list of all products or models included in the assessment, their names and IDs, and the number of units forecasted to be manufactured over the selected cycle length. The impact of each unit of product listed below is assessed and aggregated to provide the results of the life cycle assessment. If models were included in the assessment, these are represented in dark purple with the modelling icon below.'
      />
      <NavigationButtons
        back={{ path: '../summary', label: 'Summary' }}
        next={{ path: '../baseline-comparison', label: 'Baseline Comparison' }}
        type='buttons'
      />
    </div>
  );
};
