import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { createHistoricalReport, getBaselinePreview, getManufacturingHistoricalReports, ManufacturingProduct } from '../../../../../api';
import { Input } from '../../../../../components/Input';
import { Modal } from '../../../../../components/Modal';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { validationSchema } from '../../validationSchema';
import { format } from 'date-fns';
import { convertUTCDateToLocal } from '../../../../../components/datepicker/utils';
import { StickyHeader } from '../../../../../components/StickyHeader';

import { Step1 } from './Steps/1_Step';
import { Step2 } from './Steps/2_Step';

import { Step3 as ManualStep3 } from './Steps/Manual/3_Step';
import { Step4 as ManualStep4 } from './Steps/Manual/4_Step';

import { Step3 as ImportStep3 } from './Steps/Import/3_Step';

import { ManufacturingReportCreationMethod } from '../../types';
import { AppRoutes } from '../../../index';

export const NewHistorical = () => {
  const [selectedMethod, setSelectedMethod] = useState<ManufacturingReportCreationMethod>();

  const [existedReports, setExistedReports] = useState<{ reportName: string; dateRange: { start: Date; end: Date } }[] | null>(null);

  const [step, setStep] = useState(1);
  const [dateRange, setDateRange] = useState<{ start: Date | null; end: Date | null }>({ start: null, end: null });
  const [reportTitle, setReportTitle] = useState<string>('');

  const [waiting, setWaiting] = useState<boolean>(false);
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const navigate = useNavigate();
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getManufacturingHistoricalReports({ pageSize: 1000 }).ok((data) => {
      getBaselinePreview().ok((baseline) => {
        const baselineData = !baseline.errorCode
          ? {
              name: `Baseline report ${format(new Date(convertUTCDateToLocal(baseline.startDate)), 'dd/MM/yyyy')} - ${format(
                new Date(convertUTCDateToLocal(baseline.endDate)),
                'dd/MM/yyyy',
              )}`,
              startDate: baseline.startDate,
              endDate: baseline.endDate,
            }
          : undefined;
        setExistedReports(
          [...(baselineData ? [baselineData] : []), ...data.reports].map(({ name, startDate, endDate }) => ({
            reportName: name,
            dateRange: { start: convertUTCDateToLocal(startDate), end: convertUTCDateToLocal(endDate) },
          })),
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={parentRef} className='flex flex-col gap-y-4 -mt-10'>
      <Helmet title='Upload historical data' />
      {!waiting ? (
        <>
          <Formik<{ products: ManufacturingProduct[] }>
            initialValues={{ products: [] }}
            validationSchema={validationSchema}
            validateOnBlur={triedSubmitting}
            validateOnChange={triedSubmitting}
            onSubmit={(values) => {
              setWaiting(true);
              const payload = {
                name: reportTitle,
                products: [...values.products.filter((it) => it.selected && it.id).map(({ id, count }) => ({ id, count: count }))],
                startDate: new Date(dateRange.start!.setMinutes(-dateRange.start!.getTimezoneOffset())),
                endDate: new Date(dateRange.end!.setMinutes(-dateRange.end!.getTimezoneOffset())),
              };

              createHistoricalReport(payload).call({
                ok: (reportResponse) => {
                  setWaiting(false);
                  navigate(AppRoutes(reportResponse.id).report.historicalOverview, { state: reportResponse });
                },
              });
            }}
          >
            {(formik) => (
              <>
                <StickyHeader className='mx-6'>
                  <div className='flex items-center gap-x-4'>
                    <button
                      onClick={() => {
                        if (step === 1) {
                          navigate(-1);
                        } else {
                          setStep((current) => current - 1);
                          formik.setFieldValue('products', []);
                        }
                      }}
                      className='flex items-center justify-center bg-[#E8EAF5] rounded-lg w-8 h-8 hover:bg-white hover:border-2 hover:border-brand'
                    >
                      <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
                    </button>
                    <div className='text-xl font-semibold'>
                      {step <= 2 ? (
                        'New Historical Volume Report'
                      ) : (
                        <div className='text-xl font-semibold'>
                          <ReportTitleForm reportTitle={reportTitle} setReportTitle={setReportTitle} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='border rounded-full px-4 py-2 text-xs uppercase'>
                    step {step}/{selectedMethod === ManufacturingReportCreationMethod.Import ? 3 : 4}
                  </div>
                </StickyHeader>
                <div className='flex flex-col gap-y-4 mx-6'>
                  {(() => {
                    switch (step) {
                      case 1:
                        return <Step1 selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} onNext={() => setStep(2)} />;

                      case 2:
                        return (
                          <Step2
                            step={step}
                            onNext={() => setStep(3)}
                            dateRange={dateRange}
                            setDateRange={setDateRange}
                            setReportTitle={setReportTitle}
                            existedReports={existedReports}
                          />
                        );

                      case 3:
                        return selectedMethod === ManufacturingReportCreationMethod.Manual ? (
                          <ManualStep3 step={step} parentRef={parentRef} dateRange={dateRange} onNext={() => setStep(4)} />
                        ) : (
                          <ImportStep3 dateRange={dateRange} onNext={() => setTriedSubmitting(true)} />
                        );

                      case 4:
                        return <ManualStep4 dateRange={dateRange} onSubmitClick={() => setTriedSubmitting(true)} />;
                    }
                  })()}
                </div>
              </>
            )}
          </Formik>
        </>
      ) : (
        <ManufacturingSkeletonLoader />
      )}
    </div>
  );
};

const ReportTitleForm = ({ reportTitle, setReportTitle }: { reportTitle: string; setReportTitle: (v: string) => void }) => {
  return (
    <Formik<{ name: string }>
      initialValues={{
        name: reportTitle,
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required(),
      })}
      onSubmit={(v) => {
        setReportTitle(v.name);
      }}
    >
      {(formik) => (
        <Form>
          <Modal<{ name: string }>
            icon={regular('edit')}
            title={`Rename "${formik.values.name}"`}
            onConfirm={({ name }, close) => {
              formik.setFieldValue('name', name);
              setReportTitle(name);
              close();
            }}
            formik={{
              initialValues: formik.values,
              validationSchema: yup.object().shape({
                name: yup.string().required(),
              }),
            }}
            bodyContent={<Field name='name'>{(model: FieldProps<string>) => <Input model={model} />}</Field>}
          >
            <div className='flex gap-x-2 items-center ring-0 cursor-pointer hover:text-brandDark'>
              <div>{formik.values.name}</div>
              <FontAwesomeIcon icon={regular('edit')} />
            </div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
