import { createRef, Fragment, MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';
import { TooltipV3 } from '../TooltipV3';
import { ModalV3 } from '../ModalV3';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { simplify } from '../../Root/Main/shared';
import { Activity, tooltip } from '../../Root/Main/Manufacturing/components/GhgProtocol.tsx/dataModel';
import { fakeGhgData } from './fakeGhgData';
import { GhgAnalysis } from '../../api';
import cn from 'classnames';

interface Props {
  analysis?: GhgAnalysis;
}

export const GhgGraph = (props: Props) => {
  const [selectedGas, setSelectedGas] = useState<string>();
  const categories = props.analysis?.categories ?? fakeGhgData.categories;
  const gasEmissions = props.analysis?.gasEmissions ?? fakeGhgData.gasEmissions;
  const scope1 = props.analysis?.scopeEmissions
    ? props.analysis?.scopeEmissions.find((item) => item.id === 'scope_1')!.totalEmission!
    : fakeGhgData.scopeEmissions.find((item) => item.id === 'scope_1')!.totalEmission;
  const scope2 = props.analysis?.scopeEmissions
    ? props.analysis?.scopeEmissions.find((item) => item.id === 'scope_2')!.totalEmission!
    : fakeGhgData.scopeEmissions.find((item) => item.id === 'scope_2')!.totalEmission;

  const downstreamRef = useRef<SVGSVGElement>(null);
  const [downstreamActivities, setDownstreamActivities] = useState<Activity[]>([]);
  const downstreamSvgContainerRef = useRef<RefObject<SVGSVGElement>[]>(categories.slice(0, 8).map(() => createRef<SVGSVGElement>()));

  const upstreamRef = useRef<SVGSVGElement>(null);
  const [upstreamActivities, setUpstreamActivities] = useState<Activity[]>([]);
  const upstreamSvgContainerRef = useRef<RefObject<SVGSVGElement>[]>(
    categories.slice(8, categories.length).map(() => createRef<SVGSVGElement>()),
  );
  const [scope1hovered, setScope1Hovered] = useState(false);

  useEffect(() => {
    if (categories) {
      const getElementPosition = (r: number, multiplier: number, angle: number, ref: RefObject<SVGSVGElement>, isOpposite: boolean) => ({
        y: r * multiplier * Math.sin((angle * Math.PI) / 180) + ref.current!.getBoundingClientRect().height / 2,
        x: r * multiplier * Math.cos((angle * Math.PI) / 180) + (ref.current!.getBoundingClientRect().width - (isOpposite ? -16 : 16)) / 2,
      });

      if (upstreamRef.current) {
        const angles = [210, 185, 155, 125, 95, 68, 39, 10];
        const tooltipOffsets = [70, 50, 60, 50, 50, 50, 50, 50];
        const icons = [
          light('industry-windows'),
          light('wallet'),
          light('gas-pump'),
          light('ship'),
          light('trash'),
          light('plane-departure'),
          light('train-subway'),
          light('city'),
        ];

        setUpstreamActivities(
          categories.slice(0, 8).map((item, index) => ({
            id: item.id,
            isOutOfScope: item.isOutOfScope,
            angle: angles[index],
            tooltip: {
              element: tooltip(
                item.name,
                item.isOutOfScope ? (
                  <div className='mt-1'>Out of scope</div>
                ) : (
                  <div className='flex items-center justify-center gap-1 mt-1'>
                    <div className='text-sm font-semibold'>{simplify(item.totalEmission.value)}</div>
                    <div className='text-xs mt-0.5'>{item.totalEmission.unit}</div>
                  </div>
                ),
                true,
              ),
              coords: getElementPosition(
                upstreamRef.current!.getBoundingClientRect().width / 2 + (tooltipOffsets[index] ?? 50),
                0.95,
                angles[index],
                upstreamRef,
                false,
              ),
            },
            icon: {
              element: <FontAwesomeIcon className='h-7 p-2 aspect-square hover:text-brand' icon={icons[index]} />,
              coords: getElementPosition(upstreamRef.current!.getBoundingClientRect().width / 2, 0.75, angles[index], upstreamRef, false),
            },
          })),
        );
      }

      if (downstreamRef.current) {
        const angles = [-20, 10, 40, 75, 110, 140, 170];
        const tooltipOffsets = [undefined, undefined, undefined, 50, undefined, undefined, -170];
        const icons = [
          light('money-bill'),
          light('store'),
          light('city'),
          light('recycle'),
          light('bowl-food'),
          light('industry-windows'),
          light('ship'),
        ];

        setDownstreamActivities(
          categories
            .slice(8, categories.length)
            .reverse()
            .map((item, index) => ({
              id: item.id,
              isOutOfScope: item.isOutOfScope,
              angle: angles[index],
              tooltip: {
                element: tooltip(
                  item.name,
                  item.isOutOfScope ? (
                    <div className='mt-1'>Out of scope</div>
                  ) : (
                    <div className='flex items-center justify-center gap-1 mt-1'>
                      <div className='text-sm font-semibold'>{simplify(item.totalEmission.value)}</div>
                      <div className='text-xs mt-0.5'>{item.totalEmission.unit}</div>
                    </div>
                  ),
                  true,
                ),
                coords: getElementPosition(
                  downstreamRef.current!.getBoundingClientRect().width / 2 + (tooltipOffsets[index] ?? 50),
                  0.95,
                  angles[index],
                  downstreamRef,
                  true,
                ),
              },
              icon: {
                element: <FontAwesomeIcon className='h-7 p-2 aspect-square hover:text-brand' icon={icons[index]} />,
                coords: getElementPosition(
                  downstreamRef.current!.getBoundingClientRect().width / 2,
                  0.75,
                  angles[index],
                  downstreamRef,
                  true,
                ),
              },
            })),
        );
      }
    }
  }, [downstreamRef, upstreamRef, categories]);

  const getCoords = (x1: number, y1: number, x2: number, y2: number) => {
    const minX = Math.min(x1, x2);
    const maxX = Math.max(x1, x2);

    const minY = Math.min(y1, y2);
    const maxY = Math.max(y1, y2);

    const topLeft = {
      x: minX,
      y: maxY,
    };

    const topRight = {
      x: maxX,
      y: maxY,
    };

    const bottomLeft = {
      x: minX,
      y: minY,
    };

    const bottomRight = {
      x: maxX,
      y: minY,
    };

    return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
    };
  };

  return (
    <div className='print:hidden p-6'>
      <div className='flex flex-col gap-8'>
        <div className='flex self-center w-full'>
          <div className='flex items-center mx-auto'>
            <div className='flex self-center items-center bg-neutral-100 pl-6 p-2 rounded-2xl gap-6'>
              <TooltipV3
                placement='bottom'
                content={
                  <div className='text-sm font-semibold text-white px-2 py-1 bg-violet-950 rounded-lg shadow'>Individual gasses</div>
                }
              >
                <button className='cursor-default'>
                  <FontAwesomeIcon className='flex flex-1 h-8 aspect-square' icon={light('cloud')} />
                </button>
              </TooltipV3>
              <div className='flex gap-4 items-center'>
                {gasEmissions.map(({ id, name, totalEmission }, index) => (
                  <button
                    title={`${name} - ${simplify(totalEmission.value)} ${totalEmission.unit}`}
                    onClick={() => setSelectedGas((current) => (current === id ? undefined : id))}
                    className='hover:text-brand hover:outline outline-brand w-full rounded-full bg-white px-4 text-center'
                    key={index}
                  >
                    <div className={cn('group flex items-center whitespace-nowrap overflow-hidden', selectedGas === id && 'font-semibold')}>
                      <div className='py-2'>
                        {
                          {
                            ghg_gwp_co2: (
                              <div>
                                CO<sub>2</sub>
                              </div>
                            ),
                            ghg_gwp_ch4: (
                              <div>
                                CH<sub>4</sub>
                              </div>
                            ),
                            ghg_gwp_hfc: 'HFCs',
                            ghg_gwp_n2o: (
                              <div>
                                N<sub>2</sub>O
                              </div>
                            ),
                            ghg_gwp_nf3: (
                              <div>
                                NF<sub>3</sub>
                              </div>
                            ),
                            ghg_gwp_pfc: 'PFCs',
                            ghg_gwp_sf6: (
                              <div>
                                SF <sub>6</sub>
                              </div>
                            ),
                          }[id]
                        }
                      </div>
                      {selectedGas === id && (
                        <div className='flex items-center justify-center rounded-r-full whitespace-nowrap group-hover:cursor-pointer'>
                          <div className='lowercase pl-1'>
                            emissions: {simplify(totalEmission.value)} {totalEmission.unit}
                          </div>
                        </div>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>

          <ModalV3
            hideConfirm
            title='Greenhouse Gas Protocol Scope 3 Equivalence'
            body={
              <div className='flex flex-col gap-3 -mt-6'>
                <div>
                  This section is intended to give you an estimate Scope 3 equivalence based on the product-level analysis undertaken within
                  Sustained Impact. Results presented in this section will only consider product-related emissions for the list of products
                  included in the report.
                </div>

                <div>
                  It is the responsibility of the customer to ensure all products produced within the given timeframe are included in this
                  report. Separation by Scope 3 sub-category is based on the limited understanding of the company structure provided in the
                  system.
                </div>

                <div>
                  Where defaults are used, when it comes to suppliers or facilities, it is important to note that we assume the following:
                  <ul className='list-disc ml-3.5'>
                    <li>They are not owned by the Customer</li>
                    <li>They are not rented by the Customer</li>
                    <li>They are paid for by the Customer (Direct contract)</li>
                  </ul>
                </div>

                <div>
                  Raw data export is made available to ensure impacts are correctly mapped, thereby allowing for any changes required before
                  report submission.
                </div>

                <div>
                  Global warming potential (GWP) for every emission considered in the assessment are presented in the Appendix of the
                  report.
                </div>
              </div>
            }
          >
            <button className='flex self-center justify-self-end'>
              <FontAwesomeIcon className='h-8 aspect-square text-xl text-brand/50 active:scale-95' icon={solid('circle-info')} />
            </button>
          </ModalV3>
        </div>

        {(() => {
          const renderActivity = (activities: Activity[], arrowContainerRef: MutableRefObject<RefObject<SVGSVGElement>[]>) =>
            activities.map((item, index) => (
              <div className={cn('group', { 'text-zinc-400': item.isOutOfScope })} key={index}>
                <div
                  style={{ top: `${item.icon.coords.y}px`, left: `${item.icon.coords.x}px` }}
                  className='group-hover:z-20 z-10 absolute -translate-y-1/2 -translate-x-1/2'
                >
                  <button className='relative cursor-default z-10 group-hover:z-20 group-hover:bg-white group-hover:text-brand bg-[#eef2ff] rounded-full flex items-center justify-center'>
                    {item.icon.element}
                  </button>
                </div>
                <div
                  style={{ top: `${item.tooltip.coords.y - 24}px`, left: `${item.tooltip.coords.x}px` }}
                  className='group-hover:z-20 z-10 absolute -translate-x-1/2 text-xs text-center'
                >
                  <div className='absolute bg-white left-[calc(50%_-_15px)] top-[calc(50%_-_5px)] rounded-full h-[10px] w-[30px]'></div>
                  <div className='group-hover:z-20 z-10 relative'>{item.tooltip.element}</div>
                </div>

                {(() => {
                  const coords = getCoords(item.icon.coords.x, item.icon.coords.y, item.tooltip.coords.x, item.tooltip.coords.y);

                  return (
                    <svg
                      ref={arrowContainerRef.current[index]}
                      style={{
                        top: `${coords?.topLeft.y}px`,
                        left: `${coords?.topLeft.x}px`,
                        width: `${coords && coords?.topRight.x - coords?.topLeft.x}px`,
                        height: `${coords && Math.abs(coords?.bottomLeft.y - coords?.topLeft.y)}px`,
                      }}
                      className='absolute -translate-y-full'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <TooltipLink containerRef={arrowContainerRef.current[index]} angle={item.angle} />
                    </svg>
                  );
                })()}
              </div>
            ));

          return (
            <div>
              <div className='flex justify-evenly scale-[85%] print:scale-75'>
                <div className='flex items-center justify-center relative'>
                  <svg ref={upstreamRef} width='376' height='308' viewBox='0 0 376 308' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      className='scale-[85%]'
                      d='M52.6281 0.984375L107.194 55.5242C88.6409 74.0682 77.1714 99.6844 77.1714 127.973C77.1714 184.558 123.065 230.43 179.676 230.43C236.288 230.43 282.182 184.558 282.182 127.973H266.062L320.853 57.9565L375.643 127.973H359.353C359.353 227.161 278.911 307.565 179.676 307.565C80.4417 307.565 0 227.169 0 127.981C0 78.3828 20.1104 33.4867 52.6281 0.984375Z'
                      fill='#EEF2FF'
                    />
                  </svg>
                  <div className='absolute flex flex-col items-center justify-center left-[240px] top-[24px]'>
                    <div className='text-xl text-dark'>Scope 3</div>
                    <div className='text-brand font-semibold uppercase text-xs'>indirect</div>
                  </div>

                  {renderActivity(upstreamActivities, upstreamSvgContainerRef)}

                  <div className='absolute left-[67px] bottom-[110px]'>
                    <svg width='204' height='244' viewBox='0 0 204 244' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        className='scale-[85%]'
                        d='M7.50974 172.7C5.05309 172.7 2.62744 172.607 0.217285 172.436C6.54103 203.622 27.0312 229.664 54.6821 243.561C131.032 222.848 187.186 153.102 187.186 70.2352H203.476L148.694 0.21875L93.9032 70.2352H110.023C110.023 126.82 64.1289 172.692 7.51749 172.692L7.50974 172.7Z'
                        fill='#E2E8F0'
                      />
                    </svg>
                    <div className='group absolute left-[94px] bottom-[114px] text-zinc-400'>
                      <button className='cursor-default group-hover:z-20 relative group-hover:bg-white rounded-full flex items-center justify-center'>
                        <FontAwesomeIcon className='h-7 aspect-square p-2 group-hover:text-brand' icon={light('plug-circle-bolt')} />
                      </button>
                      <svg width={18} height={10} className='absolute -left-1'>
                        <line x1={10} y1={0} x2={0} y2={18} stroke='#b5b5b5' />
                      </svg>
                      <div className='group flex flex-col items-center text-center absolute z-20 w-[140px] -left-[86px] top-[40px] text-xs text-zinc-500'>
                        {(() => {
                          return tooltip(
                            'Purchased electricity, steam, heating and cooling for own use',
                            <div className='flex items-center gap-x-1 mt-1'>
                              <div className='text-sm font-semibold'>{simplify(scope2.value)}</div>
                              <div className='text-xs mt-0.5'>{scope2.unit}</div>
                            </div>,
                            true,
                          );
                        })()}
                      </div>
                    </div>

                    <div className='absolute -top-[50px] left-[90px] flex flex-col items-center justify-center'>
                      <div className='text-xl text-zinc-500'>Scope 2</div>
                      <div className='text-zinc-400 uppercase text-xs  font-semibold'>indirect</div>
                    </div>
                  </div>
                </div>

                <div
                  onMouseEnter={() => setScope1Hovered(true)}
                  onMouseLeave={() => setScope1Hovered(false)}
                  className='group flex justify-center self-start relative -left-3'
                >
                  <svg width={110 * 0.85} height={337 * 0.85} fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      className='scale-[85%]'
                      d='M109.829 70.1883L55.0466 0.171875L0.256348 70.1883H14.8878V336.172H93.2061V70.1883H109.829Z'
                      fill={scope1hovered ? '#EEF2FF' : '#F5F5F5'}
                    />
                  </svg>
                  <div className='absolute -top-[50px] left-[14px] flex flex-col items-center justify-center'>
                    <div className='text-xl text-zinc-500'>Scope 1</div>
                    <div className='text-zinc-400 uppercase text-xs  font-semibold'>direct</div>
                  </div>

                  <div className='hidden group-hover:flex items-center flex-col gap-y-1 rounded-md group-hover:border-zinc-300 group-hover:shadow-regular group-hover:z-30 absolute top-1/2 -translate-y-1/2 p-3 border bg-white'>
                    <div>Scope 1</div>
                    <div className=''>
                      {(() => {
                        return (
                          <div className='flex items-center gap-x-1 whitespace-nowrap'>
                            <div className='text-sm font-semibold'>{simplify(scope1.value)}</div>
                            <div className='text-xs mt-0.5'>{scope1.unit}</div>
                          </div>
                        );
                      })()}
                    </div>
                  </div>

                  <div className='absolute flex flex-col justify-center gap-4 bottom-3 w-20'>
                    {[
                      {
                        icon: <FontAwesomeIcon className='h-7 p-2 aspect-square group-hover:text-brand' icon={light('city')} />,
                        label: 'Company Facilities',
                        value: 'Out of scope',
                      },
                      {
                        icon: <FontAwesomeIcon className='h-7 p-2 aspect-square group-hover:text-brand' icon={light('truck')} />,
                        label: 'Company Vehicles',
                        value: 'Out of scope',
                      },
                    ].map((item, index) => (
                      <div className='relative flex flex-col items-center text-zinc-400 h-[110px]' key={index}>
                        <button className='cursor-default group-hover:z-20 relative rounded-full flex items-center justify-center'>
                          {item.icon}
                        </button>
                        <div className='h-3.5 bg-zinc-400 w-px' />
                        <div className='absolute top-[54px] group-hover:z-20 text-center text-xs mt-2 group-hover:text-dark'>
                          {item.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className='flex items-center relative h-[360px]'>
                  <svg ref={downstreamRef} width='376' height='308' viewBox='0 0 376 308' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      className='scale-[85%]'
                      d='M323.38 0.984375L268.814 55.5242C287.367 74.0682 298.837 99.6844 298.837 127.973C298.837 184.558 252.943 230.43 196.332 230.43C139.72 230.43 93.8265 184.558 93.8265 127.973H109.946L55.1555 57.9565L0.365234 127.973H16.6551C16.6551 227.161 97.0969 307.565 196.332 307.565C295.566 307.565 376.008 227.161 376.008 127.973C376.008 78.3828 355.898 33.479 323.38 0.984375Z'
                      fill='#EEF2FF'
                    />
                  </svg>

                  <div className='absolute flex flex-col items-center justify-center left-[16px] top-[26px]'>
                    <div className='text-xl text-dark'>Scope 3</div>
                    <div className='text-brand font-semibold uppercase text-xs'>indirect</div>
                  </div>

                  {renderActivity(downstreamActivities, downstreamSvgContainerRef)}
                </div>
              </div>

              <div className='flex justify-center items-center gap-6 text-base font-semibold bg-neutral-100 p-2 rounded-full'>
                {[{ label: 'Upstream Activities' }, { label: 'Reporting Company' }, { label: 'Downstream Activities' }].map(
                  ({ label }, index, arr) => (
                    <Fragment key={index}>
                      <div className='px-4 py-2.5 bg-white rounded-full'>{label}</div>
                      {index < arr.length - 1 && <FontAwesomeIcon className='h-8 aspect-square text-brand' icon={light('right')} />}
                    </Fragment>
                  ),
                )}
              </div>
            </div>
          );
        })()}

        <div className='text-center pb-6 text-sm'>All results in this graph view are exclusive of biogenic CO2 emissions</div>
      </div>
    </div>
  );
};

interface ArrowProps {
  containerRef: RefObject<SVGSVGElement>;
  angle: number;
}

const TooltipLink = (props: ArrowProps) => {
  const [{ x1, y1, x2, y2 }, setCoords] = useState<{ x1: number; y1: number; x2: number; y2: number }>({ x1: 0, y1: 0, x2: 0, y2: 0 });

  useEffect(() => {
    if (props.containerRef?.current) {
      if ((props.angle >= 0 && props.angle < 90) || (props.angle >= 180 && props.angle < 270)) {
        setCoords({
          x1: 0,
          y1: 0,
          x2: props.containerRef.current.getBoundingClientRect().width,
          y2: props.containerRef.current.getBoundingClientRect().height,
        });
      } else {
        setCoords({
          x1: props.containerRef.current.getBoundingClientRect().width,
          y1: 0,
          x2: 0,
          y2: props.containerRef.current.getBoundingClientRect().height,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.containerRef?.current]);

  return <line x1={x1} y1={y1} x2={x2} y2={y2} stroke='#b5b5b5' />;
};
