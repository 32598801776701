import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { ProductState, ProductV3 } from '../../../../api';

export interface Props {
  waiting: boolean;
  unsavedChanges: boolean;
}

export const TitleBadges = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const { waiting, unsavedChanges } = props;
  const draft = formik.values.state === ProductState.Draft;
  const errors = formik.values.validation.errors.length > 0;

  return (
    <div className='ml-2 flex gap-3 items-center text-xs'>
      {draft && <div className='px-3 py-1 bg-neutral-200 text-neutral-600 rounded-md'>Draft</div>}
      <div
        className={cn(
          'flex justify-center items-center gap-2 px-3 py-1 rounded-md transition',
          (() => {
            if (waiting) {
              return 'bg-amber-100 text-amber-800';
            }

            if (errors) {
              return 'bg-red-50 text-red-500';
            }

            if (unsavedChanges) {
              return 'bg-amber-100 text-amber-800';
            }

            if (draft) {
              return 'bg-green-100 text-green-800';
            }

            return 'hidden';
          })(),
        )}
      >
        {(() => {
          if (waiting) {
            return (
              <>
                <FontAwesomeIcon size='sm' icon={regular('clock')} />
                {draft ? 'Saving' : 'Validating changes'}
              </>
            );
          }

          if (errors || unsavedChanges) {
            return (
              <>
                <FontAwesomeIcon size='sm' icon={unsavedChanges ? regular('exclamation-circle') : regular('exclamation-triangle')} />
                Unsaved changes
              </>
            );
          }

          return (
            <>
              <FontAwesomeIcon size='sm' icon={regular('check')} />
              Saved
            </>
          );
        })()}
      </div>
    </div>
  );
};
