import { forwardRef, PropsWithChildren } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}

export const StickyHeader = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  return (
    <div className='z-30 bg-white sticky top-0' ref={ref}>
      <div className={cn('flex items-center justify-between border-b border-zinc-200 h-16', props.className)}>{props.children}</div>
    </div>
  );
});
