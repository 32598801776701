import { ReactNode } from 'react';

interface Props {
  description: ReactNode;
  label: ReactNode;
  communicationExamples?: ReactNode;
  cta?: ReactNode;
  bottomNavigationButtons: ReactNode;
}

export const EnvironmentalLabel = (props: Props) => {
  return (
    <div className='flex flex-col gap-6 mx-6'>
      {props.description}
      {props.label}
      {props.communicationExamples}
      {props.cta}
      {props.bottomNavigationButtons}
    </div>
  );
};
