import { FieldProps, FormikContextType, useFormikContext } from 'formik';
import { Fragment, PropsWithChildren, RefObject, useRef } from 'react';
import * as yup from 'yup';
import { DisposalNode, Entity, ProductState, ProductV3 } from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectV3 } from '../../../../components/SelectV3';
import { useLists } from '../../../../hooks/useLists';
import { ExtractedData } from './ExtractedData';
import { ModalHeaderRightBar } from './ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from './TaggableFields';
import { getConsumptionLocations, getPackagings } from './dataModel';

type Props = PropsWithChildren<{
  data: DisposalNode;
  onSave: ModalFormSaveCallback<DisposalNode>;
}>;

export const DisposalDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm
      size='narrow'
      formRef={formRef}
      title={`${props.data.displayName} disposal (${
        getConsumptionLocations(formik).find(({ edges }) => edges.includes(props.data.id))?.displayName
      })`}
      body={<Body productFormik={formik} formRef={formRef} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>What happens when the consumer throws away your product packaging?</div>
          <div>
            Can the materials this packaging is made of be recycled? If not,{' '}
            <a className='underline hover:text-brand' href='mailto:impact@sustained.com?subject=Sustained Impact: Packaging recyclability'>
              let us know
            </a>
            , otherwise we’ll assume the country’s default recyclability rate for the given packaging material(s).
          </div>
        </div>
      }
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        disposalType: yup.object().required(),
      })}
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={props.onSave}
    >
      {props.children}
    </ModalForm>
  );
};

const Body = (props: { productFormik: FormikContextType<ProductV3>; formRef: RefObject<HTMLDivElement> }) => {
  const lists = useLists();
  const formik = useFormikContext<DisposalNode>();

  return (
    <TaggableFieldsContainer pathPrefix='nodes'>
      <div className='flex flex-col gap-4'>
        <ExtractedData {...props} />
        <div className='flex flex-col p-4 rounded-xl bg-[#F5F7FA]'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Composition</div>
            <div className='self-start grid grid-cols-[max-content_max-content] gap-x-4 gap-y-2 px-3 py-2 bg-white border border-zinc-300 rounded-xl'>
              {getPackagings(props.productFormik)
                .find(({ id }) => id === formik.values.packagingNodeId)!
                .materials.map((material) => (
                  <Fragment key={material.id}>
                    <div>{material.compositionPercent}%</div>
                    <div>
                      {material.name} ({material.subType.name})
                    </div>
                  </Fragment>
                ))}
            </div>
          </div>
        </div>
        <div className='flex flex-col p-4 rounded-xl bg-[#F5F7FA]'>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>Recyclability</div>
            <div className='w-1/2'>
              <TaggableField name='disposalType'>
                {(model: FieldProps<Entity>) => (
                  <SelectV3 autoFocus model={model} menuPortalTarget={props.formRef.current} options={lists.disposalTypes} />
                )}
              </TaggableField>
            </div>
          </div>
        </div>
      </div>
    </TaggableFieldsContainer>
  );
};
