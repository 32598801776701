export enum SectionId {
  Overview,
  ProgressTracking,
  ImpactDetails,
  WinnersLosers,
  DataCompletion,
  SupplyChain,
  EcoLabellingDetails,
  Ghg,
}

export enum Methodology {
  Production,
  Consumer,
  Ghg,
}

export enum Timeframe {
  Month,
  Quarter,
  HalfYear,
  Year,
}
