import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { forwardRef } from 'react';
import { DefaultBadge } from './Badges';
import { Layout } from './SecondaryBarContent';

export type MessageId = 'getStarted' | 'appliedDefaults' | 'restoreRevision';

interface Props {
  id: MessageId;
  layout: Layout;
  onClose: () => void;
}

export const Message = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex gap-4 p-2 pr-3.5 shadow-[0_0_3px_rgba(0,0,0,0.25)] rounded-lg',
      props.id !== 'restoreRevision' ? 'bg-white' : 'bg-[#FFFBEB]',
    )}
  >
    <div
      className={cn('self-center flex justify-center items-center rounded-full text-zinc-700 h-10 aspect-square', {
        'bg-[#D6FF00]': props.id !== 'restoreRevision',
      })}
    >
      {props.id !== 'restoreRevision' ? (
        <FontAwesomeIcon size='lg' icon={light('lightbulb')} />
      ) : (
        <FontAwesomeIcon size='2x' className='text-amber-400' icon={light('exclamation-triangle')} />
      )}
    </div>
    <div className='flex flex-col text-zinc-900 flex-1'>
      {(props.id === 'appliedDefaults' || props.id === 'restoreRevision') && (
        <div className='text-base font-semibold'>{props.id === 'appliedDefaults' ? 'Defaults applied' : 'Restore incomplete'}</div>
      )}
      <div className='text-sm'>
        {props.id === 'getStarted' && (
          <>
            <span className='font-semibold'>Get started!</span> You can start by adding ingredients and packaging used to make your product
            and apply our defaults. You can also add your production facility before using defaults, or you can create it all yourself
            starting wherever you like.{' '}
            {props.layout === Layout.Graph && (
              <>
                You can toggle between this view and a list view – that covers each phase separately – using the above buttons. Don’t forget
                to add transport links clicking on the van icon in the bottom left corner.
              </>
            )}
            {props.layout === Layout.List && (
              <>
                You can toggle between this view and a board view – that will give you an overview of all sections. Don’t forget to add
                transport links by clicking on the van icon between each step.
              </>
            )}
          </>
        )}
        {props.id === 'appliedDefaults' && (
          <>
            All the nodes with the tag{' '}
            <div className='inline-flex -mb-0.5'>
              <DefaultBadge />
            </div>{' '}
            were automatically created using our default logic. Click on any of them to view the defaults assumptions made and replace it
            with your own first-party data instead for a more realistic picture of your product life cycle.
          </>
        )}
        {props.id === 'restoreRevision' && (
          <>To finalise restoring this version, you need to fix the errors in the graph and press the Restore button.</>
        )}
      </div>
    </div>
    <button type='button' className='flex self-start text-lg' onClick={props.onClose}>
      <FontAwesomeIcon size='lg' icon={light('times')} />
    </button>
  </div>
));
