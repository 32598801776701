import { NavLink } from 'react-router-dom';
import { Lcia } from '../../../components/Lcia';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { BaselinePefReport, ReportType } from '../../../../../../api';

interface Props {
  data: BaselinePefReport;
}

export const BaselineLcia = (props: Props) => {
  const description = (
    <div className='flex justify-between gap-8'>
      <div className='flex flex-col gap-6'>
        <div>
          A comprehensive look at the environmental impact of the specified production volumes over the 1-year range selected. A product’s
          total environmental impact is calculated across a number of impact categories, 16 in total, each with their own unit of
          measurement. The absolute impact of each category is referred to as the ‘physical output’ in this section and is shown in
          scientific units (eg. kg CO2e for climate change).
        </div>
        <div>
          In order to make these physical outputs comparable and obtain the final environmental impacts, normalisation and weightings are
          then applied (using the factors presented in the{' '}
          <NavLink to='../appendix' onClick={() => window.scrollTo({ top: 0 })} className='hover:text-brand font-semibold underline'>
            Appendix
          </NavLink>
          ), as per the PEF framework. These final environmental impacts are calculated in weighted person year, which compares the impact
          of your product to the impact an average person has in one year (similarly weighted). For more details, you can read our{' '}
          <NavLink
            to='/methodology'
            target='_blank'
            className='hover:text-brand font-semibold underline'
            onClick={() => window.scrollTo({ top: 0 })}
          >
            methodology
          </NavLink>
          .
        </div>
      </div>
      <NavigationButtons type='icons' back={{ path: '../volumes' }} next={{ path: '../interpretation' }} />
    </div>
  );
  return (
    <div className='flex flex-col gap-y-6'>
      <Lcia description={description} data={props.data} reportType={ReportType.Baseline} />
      <NavigationButtons
        back={{ path: '../volumes', label: 'Volumes' }}
        next={{ path: '../interpretation', label: 'Interpretation' }}
        type='buttons'
      />
    </div>
  );
};
