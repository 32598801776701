import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

interface Props {
  value: any;
  onClick: () => void;
}

export const OriginalBadge = (props: Props) => (
  <div
    className={cn(
      'cursor-pointer flex gap-1.5 items-center',
      'leading-none text-[9px] font-semibold text-zinc-600 bg-[#DACEFD] px-1.5 py-1 rounded',
    )}
    onClick={props.onClick}
  >
    <div className='tracking-wider uppercase'>was:</div>
    <div>
      {(() => {
        if (props.value === undefined || props.value === null) {
          return <span className='opacity-50'>empty</span>;
        }

        if (props.value.name) {
          return props.value.name;
        }

        if (typeof props.value === 'boolean') {
          return props.value ? 'Yes' : 'No';
        }

        return props.value;
      })()}
    </div>
    <FontAwesomeIcon size='xs' icon={solid('undo')} />
  </div>
);
