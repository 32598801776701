import {
  autoUpdate,
  flip,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { cloneElement, isValidElement, ReactNode, useEffect, useState } from 'react';

interface Props {
  content: (props: { open: boolean; close: () => void }) => ReactNode;
  placement?: Placement;
  offset?: number;
  offsetCross?: number;
  children: (props: { open: boolean }) => JSX.Element;
}

export const Popover = (props: Props) => {
  const [open, setOpen] = useState(false);
  const placement = props.placement ?? 'bottom';

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: props.offset ?? 50, ...(props.offsetCross ? { crossAxis: props.offsetCross } : {}) }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  const getAnimationProps = () => {
    switch (placement) {
      case 'left':
      case 'left-start':
      case 'left-end':
        return {
          initial: { opacity: 0, translateX: 4 },
          animate: { opacity: 1, translateX: 0 },
          exit: { opacity: 0, translateX: -4 },
        };
      case 'right':
      case 'right-end':
      case 'right-start':
        return {
          initial: { opacity: 0, translateX: -4 },
          animate: { opacity: 1, translateX: 0 },
          exit: { opacity: 0, translateX: 4 },
        };
      case 'bottom-start':
      case 'bottom-end':
        return {
          initial: { opacity: 0, translateY: -4 },
          animate: { opacity: 1, translateY: 0 },
          exit: { opacity: 0, translateY: 4 },
        };
      default:
        return {
          initial: { opacity: 0, translateY: 4 },
          animate: { opacity: 1, translateY: 0 },
          exit: { opacity: 0, translateY: -4 },
        };
    }
  };

  return (
    <>
      {isValidElement(props.children({ open })) && cloneElement(props.children({ open }), getReferenceProps({ ref: reference }))}
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            {...getAnimationProps()}
            {...getFloatingProps({
              ref: floating,
              className: 'antialiased z-30 max-w-xl',
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            {props.content({ open, close: () => setOpen(false) })}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
