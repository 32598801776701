import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FieldProps } from 'formik';
import { StaticEntity } from '../api';
import cn from 'classnames';

interface Props {
  model: FieldProps<StaticEntity<string> | undefined>;
  options: StaticEntity<string>[];
  onChange?: (value: StaticEntity<string>) => void;
}

export const RadioInputs = (props: Props) => {
  return (
    <>
      {props.options.map((option) => {
        const checked = props.model.field.value?.type === option.type;

        return (
          <label key={option.type} className='flex items-center gap-2'>
            <div
              className={cn(
                'flex items-center justify-center h-4 aspect-square border rounded-sm',
                props.model.meta.error ? 'border-f' : checked ? 'border-brand' : 'border-zinc-500',
                checked ? 'bg-brand' : 'bg-white',
              )}
            >
              {checked && <FontAwesomeIcon className='h-3 aspect-square text-white' icon={solid('check')} />}
              <input
                type='radio'
                id={option.type}
                className={cn('absolute h-4 aspect-square appearance-none')}
                checked={checked}
                value={option.type}
                onChange={(v) => {
                  if (props.onChange) {
                    props.onChange(props.options.find(({ type }) => type === v.target.value)!);
                  } else {
                    props.model.form.setFieldValue(props.model.field.name, option);
                  }
                }}
              />
            </div>
            <div>{option.name}</div>
          </label>
        );
      })}
    </>
  );
};
