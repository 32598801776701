import { NavigationButtons } from '../Sku/components/NavigationButtons';
import { ContextualExampleTile } from '../../../../../components/ContextualExampleTile';
import { ModellingReport } from '../../../../../api';

interface Props {
  data: ModellingReport;
}

export const Interpretation = (props: Props) => {
  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm w-full'>
      <div className='flex justify-between gap-8'>
        <div className='text-zinc-500 ml-3 flex flex-col gap-6'>
          <div>
            Not everyone has a PhD to understand what exactly those impact changes truly represent. To give you some context and help you
            understand, here are some contextual examples. Unfortunately, not every impact category can be easily compared to something out
            of our everyday lives (or we actually could, but it might unrightfully scare anyone off and we don’t want that!), but where a
            comparison to something we all can relate to is possible, we’ve tried to include it here. We’ve also considered the PEF
            suggested weightings to determine which impact categories to focus on.
          </div>

          <div>
            As you consider whether your proposed changes would truly make a difference for your company, the consequences of the changes
            should be considered on more than a single product. As such, the results below show the difference if those changes were applied
            to producing <span className='text-violet-800 font-semibold'>100 units of this product</span>.
          </div>

          <div className='text-violet-800 font-semibold'>
            It is important to note that the examples you see use ‘simplified’ language which is not truly reflective of the complexity of
            the science behind those impact calculations. They are intended as hints to help you contextualise the size/magnitude of an
            impact rather than understand its full meaning. You can find the pure scientific equivalences by hovering over the cards or by
            visiting our Appendix where a detailed account of how these were calculated and what they truly mean from a scientific point of
            view is presented.
          </div>

          <div>
            Your proposed changes, <span className='text-violet-800 font-semibold'>if applied to 100 units of this product</span>, and
            compared to the impact of the base product would be equivalent to the following:
          </div>
        </div>
        <NavigationButtons type='icons' back={{ path: '../impact-change-results' }} next={{ path: '../consumer-view' }} />
      </div>

      <div className='grid grid-cols-3 gap-6 print:block'>
        {props.data.interpretation.contextualExamples.map((contextualExample, i) => (
          <ContextualExampleTile key={i} contextualExample={contextualExample} />
        ))}
      </div>

      <NavigationButtons
        type='buttons'
        back={{ path: '../impact-change-results', label: 'Impact Change Results' }}
        next={{ path: '../consumer-view', label: 'Consumer View' }}
      />
    </div>
  );
};
