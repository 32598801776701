import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { Dispatch, ForwardedRef, SetStateAction, forwardRef, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ProductGeneral, ProductV3, searchProducts } from '../../../../api';
import InfiniteScroll from '../../../../components/InfiniteScroll';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { useDebounce } from '../../../../hooks/useDebounce';

interface Props {
  title: string;
  text: string;
  from?: boolean;
  waiting: boolean;
  selected: ProductGeneral[];
  setSelected: Dispatch<SetStateAction<ProductGeneral[]>>;
  onNext: () => void;
}

export const CopyToFromProductsModal = forwardRef((props: Props, ref: ForwardedRef<ModalApi>) => (
  <ModalV3
    ref={ref}
    title={props.title}
    cancelLabel='Close'
    confirmLabel='Next'
    onConfirm={props.onNext}
    noCloseOnConfirm
    waiting={props.waiting}
    disableConfirm={props.selected.length === 0}
    body={<Content {...props} />}
    onOpenChange={(open) => {
      if (open) {
        props.setSelected([]);
      }
    }}
  />
));

const Content = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const [searchInputValue, setSearchInputValue] = useState('');
  const debouncedSearchInputValue = useDebounce(searchInputValue, 300);
  const nextPageTokenRef = useRef('');
  const [list, setList] = useState(new Array<ProductGeneral>());

  const fetchNextPage = (options?: { reset?: boolean }) => {
    if (options?.reset) {
      nextPageTokenRef.current = '';
    }

    searchProducts({
      pageSize: 50,
      sortBy: 'name',
      sortAscending: true,
      type: formik.values.productType,
      contains: debouncedSearchInputValue,
      pageToken: nextPageTokenRef.current,
    }).ok(({ products, nextPageToken }) => {
      nextPageTokenRef.current = nextPageToken;
      setList((current) => [...(options?.reset ? [] : current), ...products.filter(({ id }) => id !== formik.values.id)]);
    });
  };

  useEffect(() => {
    fetchNextPage({ reset: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInputValue]);

  return (
    <div className='flex flex-col gap-6'>
      <div className='flex justify-between gap-6'>
        <div>{props.text}</div>
        <div className='flex items-center relative h-full text-gray-500'>
          <input
            autoFocus
            type='search'
            className='rounded-full border border-gray-500 py-1.5 pl-4 pr-9 shadow-[0_2px_6px_rgba(0,0,0,0.05)_inset]'
            placeholder='Find Product…'
            value={searchInputValue}
            onChange={(event) => setSearchInputValue(event.target.value)}
          />
          <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-4' />
        </div>
      </div>
      <InfiniteScroll
        className='rounded-lg shadow-[0_0_3px_rgba(0,0,0,0.25)]'
        height={300}
        dataLength={list.length}
        next={fetchNextPage}
        hasMore={nextPageTokenRef.current !== ''}
        loader={<></>}
      >
        <table className='border-collapse w-full'>
          <thead>
            <tr className='border-b border-zinc-300 whitespace-nowrap h-12'>
              <th className='pl-5 pr-2' />
              <th className='px-2'>Name</th>
              <th className='px-2'>Net amount</th>
              <th className='px-2'>ID</th>
              {props.from && <th className='pl-2 pr-5'>Graph</th>}
            </tr>
          </thead>
          <tbody>
            {list.map((product, i) => (
              <tr
                key={product.id}
                className={cn('h-12', {
                  'bg-slate-50': i % 2 === 1,
                  'rounded-t-lg': i === 0,
                  'rounded-b-lg': i === list.length - 1,
                })}
              >
                <td className='pl-5 pr-2'>
                  <input
                    className='relative top-0.5'
                    type='checkbox'
                    checked={props.selected.some(({ id }) => id === product.id)}
                    onChange={() =>
                      props.setSelected((current) =>
                        current.some(({ id }) => id === product.id)
                          ? current.filter(({ id }) => id !== product.id)
                          : [...(props.from ? [] : current), product],
                      )
                    }
                  />
                </td>
                <td className='px-2' title={product.name}>
                  <div className={cn('truncate', props.from ? 'w-44' : 'w-56')}>{product.name}</div>
                </td>
                <td className='px-2'>
                  {product.amount?.value}
                  {product.amount?.unit.name}
                </td>
                <td className='px-2' title={product.skuId}>
                  <div className='w-20 truncate'>{product.skuId}</div>
                </td>
                {props.from && (
                  <td className='pl-2 pr-5'>
                    <NavLink className='text-brand flex justify-center' to={`/products/${product.id}`} target='_blank'>
                      <FontAwesomeIcon icon={light('arrow-up-right-from-square')} size='lg' />
                    </NavLink>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};
