import { NavLink } from 'react-router-dom';
import { Lens, Methodology, ProductReport, ProductType } from '../../../../../../api';
import { useProfile } from '../../../../../../hooks/useProfile';
import { BasicDetails } from '../../BasicDetails';
import { ExtendedDetails } from '../../ExtendedDetails';
import { NavigationButtons } from './NavigationButtons';

interface Props {
  data: ProductReport;
  lens: Lens;
}

export const Details = (props: Props) => {
  const foundationEarth = useProfile().selectedWorkspace.methodology.type === Methodology.FoundationEarth;

  return (
    <div className='flex flex-col gap-8 px-6'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>
          {props.lens === Lens.Production
            ? 'A detailed view of your product, displaying a summary of the primary data provided during product creation, presenting all overall product level data first, followed by data for each life cycle stage.'
            : 'A detailed view of your product with access to the detailed product life cycle view'}
        </div>
        <NavigationButtons type='icons' back={{ path: '../summary' }} next={{ path: '../goal-and-scope' }} />
      </div>

      <BasicDetails
        data={props.data.product}
        fields={[
          { name: 'Product name', value: props.data.product.name },
          {
            name: 'Product type',
            value: {
              [ProductType.Final]: 'Final (Sold to consumers)',
              [ProductType.Intermediate]: 'Intermediate (B2B)',
              [ProductType.Internal]: 'Internal (not for sale)',
            }[props.data.product.productType],
          },
          { name: 'ID', value: props.data.product.skuId },
          { name: 'GTIN', value: props.data.product.gtin ?? '—' },
          { name: 'Category', value: props.data.product.category?.name ?? '—' },
          { name: 'Net amount', value: props.data.product.amount.value + props.data.product.amount.unit.name },
          ...(() => {
            return props.lens === Lens.Consumer && foundationEarth
              ? [{ name: 'Raw to cooked ratio (R2C)', value: props.data.product.rawToCookedRatio }]
              : [];
          })(),
          ...(() => {
            return props.lens === Lens.Production ? [{ name: 'Number of servings', value: props.data.product.servings ?? '—' }] : [];
          })(),
          { name: 'Food type', value: props.data.product.foodType.name },
          ...(() => {
            return props.data.product.economicValue
              ? [
                  {
                    name: 'Economic value',
                    value: `${props.data.product.economicValue.price} ${props.data.product.economicValue.currency.name}/kg`,
                  },
                ]
              : [];
          })(),
          { name: 'Conservation requirements', value: props.data.product.conservation.requirement.name },
        ]}
      />

      <div className='flex flex-col gap-8 border rounded-regular shadow-regular p-8 print:hidden'>
        <div className='text-2xl font-semibold'>Life cycle visualisation</div>
        {props.data.product.productType === ProductType.Final && (
          <div>
            You can view or edit your product’s detailed life cycle, from its raw materials to production, to distribution, use and end of
            life, by clicking on the button below. You will be taken into our graph editing interface where you’ll be able to understand the
            properties of the product, its suppliers, any primary data added into the system and more.
          </div>
        )}

        {props.data.product.productType === ProductType.Intermediate && (
          <div>
            You can view or edit your product’s detailed life cycle, from its raw materials to production and distribution up to the sale
            point, by clicking on the button below. You will be taken into our graph editing interface where you’ll be able to understand
            the properties of the product, its suppliers, any primary data added into the system and more.
          </div>
        )}

        {props.data.product.productType === ProductType.Internal && (
          <div>
            You can view or edit your product’s detailed life cycle, from its raw materials to its production, by clicking on the button
            below. You will be taken into our graph editing interface where you’ll be able to understand the properties of the product, its
            suppliers, any primary data added into the system and more.
          </div>
        )}

        <NavLink
          type='button'
          className='px-4 py-1 flex self-center rounded-full bg-brand font-semibold text-white border-brand border-2 active:scale-95'
          to={`/products/${props.data.product.id}`}
          target='_blank'
        >
          View product
        </NavLink>
      </div>

      <ExtendedDetails data={props.data} />

      <NavigationButtons
        type='buttons'
        back={{
          path: '../summary',
          label: 'Summary',
        }}
        next={{
          path: '../goal-and-scope',
          label: 'Goal & Scope',
        }}
      />
    </div>
  );
};
