import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

export const Faq = () => {
  const [expanded, setExpanded] = useState(new Array<number>());

  return (
    <div className='flex flex-col'>
      <Helmet title='FAQ' />
      <div className='text-2xl font-semibold text-dark mb-10 px-6'>
        Frequently Asked <span className='text-brand'>Questions</span>
      </div>
      <div className='flex flex-col gap-6'>
        {[
          [
            'Is Sustained Impact aligned to approved standards?',
            'Yes, Sustained Impact follows the main aspects of the international Life Cycle Assessment (LCA) guidelines, such as the ISO Standards (14040 and 14044), the Product Environmental Impact (PEF) and the GHG protocol.',
          ],
          [
            'What are the current system boundaries of the PEF analysis?',
            <div className='flex flex-col gap-4'>
              <div>Currently the system boundary for the impact assessment is:</div>

              <ul className='list-disc list-outside ml-8'>
                <li>Ingredients production and distribution</li>
                <li>Packaging production and distribution</li>
                <li>Production steps (processing)</li>
                <li>Distribution</li>
                <li>Preparation such as cooking (optional)</li>
                <li>Packaging disposal</li>
              </ul>

              <div>
                This will be extended to include additional end of life impacts and other elements of the supply chain as we develop the
                system further - with your help we hope!
              </div>
            </div>,
          ],
          [
            'Can I add primary data about a specific process used in my production cycle?',
            <div className='flex flex-col gap-4'>
              <div>
                Currently, you can add primary information on the type of ingredients/packaging and respective quantities, the detailed
                composition of your packaging, country of origin of materials, and others.
              </div>
              <div>
                After selecting one of the many pre-defined options for your product processing steps (grinding, cooking, frying, can
                filling, etc), you can override some of the secondary data assumptions with your own primary data. This includes energy,
                water and auxiliary inputs.
              </div>
              <div>
                You can also specify the outputs of any given process including emissions, waste, co-products etc. At this stage however,
                these outputs can only be captured, and we will process their impacts in future iterations.
              </div>
            </div>,
          ],
          [
            'How accurate and trustworthy is the data used by Sustained Impact?',
            <div className='flex flex-col gap-4'>
              <div>
                Sustained Impact uses primary data provided by the brand owner of the product - where it is produced, sold, raw materials,
                production steps, the final product packaging and more. In addition to this, background data obtained from internationally
                recognised databases for LCA is used to assess the estimated environmental impact of your product across the 16 impact
                categories specified by PEF. This data is used within LCA models created by the LCA scientists at Sustained.
              </div>

              <div>
                Sustained Impact assessments contain complex modelling with a non-trivial amount of estimation, due to the use of background
                data. Future versions will enable more primary data to be used, replacing this secondary data incrementally. This will
                enable more accurate assessments to be done. All data information of data sources, estimations, and limitations are
                available in our report, ensuring the transparency of the calculations.
              </div>
            </div>,
          ],
          [
            'Are there any APIs that I can use to integrate this in my existing process?',
            <div>
              Yes, we have two APIs: the Sustained Integrated API, for your consumer-facing applications and the Sustained Management API,
              for internal product management. Contact us at{' '}
              <a href='mailto:impact@sustained.com' className='underline' onClick={(e) => e.stopPropagation()}>
                impact@sustained.com
              </a>{' '}
              to learn more.
            </div>,
          ],
          [
            'Can I share the results with consumers?',
            <div className='flex flex-col gap-4'>
              <div>
                You can, as long as you credit us for the analysis and make sure that the estimated nature of the results is highlighted.
              </div>

              <div>
                For more automation, we also have our Sustained Integrated API which you can use to integrate directly into your consumer
                products. Contact us at{' '}
                <a href='mailto:impact@sustained.com' className='underline' onClick={(e) => e.stopPropagation()}>
                  impact@sustained.com
                </a>{' '}
                to learn more.
              </div>
            </div>,
          ],
          [
            "What are some of the features you're planning to add in future iterations?",
            <div className='flex flex-col gap-4'>
              <div>
                This is only step one of our journey to become the leading self-service product assessment tool for businesses. We have a
                number of features in the pipeline including:
              </div>

              <ul className='list-disc list-outside ml-8'>
                <li>Food waste impact</li>
                <li>Primary data for the use, distribution and raw materials stages</li>
                <li>Benchmarking</li>
                <li>Primary vs. secondary data indicator</li>
                <li>Progress tracking against set targets</li>
                <li>Suppliers login for primary data upload</li>
                <li>Advanced management API for automated impact calculation</li>
                <li>ERP integration</li>
              </ul>

              <div>And much more…</div>

              <div>
                However, like with most early-stage products, we are really looking to you to help us choose what to focus on next, so
                please don't shy away!
              </div>
            </div>,
          ],
        ].map(([title, text], i) => (
          <button
            key={i}
            type='button'
            className='flex flex-col gap-4 text-gray-600 bg-lightBg rounded-3xl p-6'
            onClick={() => setExpanded((current) => (current.includes(i) ? current.filter((index) => index !== i) : [...current, i]))}
          >
            <div className='w-full flex items-center justify-between text-xl'>
              <div className='font-semibold'>{title}</div>
              <FontAwesomeIcon icon={expanded.includes(i) ? solid('chevron-up') : solid('chevron-down')} className='pr-2' />
            </div>
            <div className={`${expanded.includes(i) ? '' : 'hidden'} text-lg`}>{text}</div>
          </button>
        ))}
      </div>
    </div>
  );
};
