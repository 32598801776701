import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { RefObject, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { LifeCycleStage, ProductGeneral, ProductV3, exportProductDistribution, importProductDistribution } from '../../../../api';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { CopyToFromProductsModal } from './CopyToFromProductsModal';

interface Props {
  title: string;
  importModal: RefObject<ModalApi>;
  exportModal: RefObject<ModalApi>;
}

export const CopyDistribution = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const navigate = useNavigate();
  const importFromModal = useRef<ModalApi>(null);
  const importOkModal = useRef<ModalApi>(null);
  const exportOverrideModal = useRef<ModalApi>(null);
  const exportOkModal = useRef<ModalApi>(null);
  const [waiting, setWaiting] = useState(false);
  const [selected, setSelected] = useState(new Array<ProductGeneral>());

  const hasDistribution = (product: ProductGeneral) =>
    product.stats.countsByLifeCycleStage.some(({ stage, count }) => stage === LifeCycleStage.Distribution && count > 0);

  const onImport = () => {
    setWaiting(true);
    importProductDistribution(formik.values.id, { product: formik.values, fromProductId: selected[0].id }).call({
      ok: (values) => {
        formik.setValues(values);
        setWaiting(false);
        [importFromModal, props.importModal].forEach(({ current }) => current?.close());
        importOkModal.current!.open();
      },
      fail: () => {
        setWaiting(false);
      },
    });
  };

  const onExport = () => {
    setWaiting(true);
    exportProductDistribution(formik.values.id, { toProductIds: selected.map(({ id }) => id) }).call({
      ok: () => {
        setWaiting(false);
        [props.exportModal, exportOverrideModal].forEach(({ current }) => current?.close());
        exportOkModal.current!.open();
      },
      fail: () => {
        setWaiting(false);
      },
    });
  };

  return (
    <>
      <ModalV3
        ref={hasDistribution(formik.values) ? props.importModal : undefined}
        size='narrow'
        title='Import distribution section'
        cancelLabel='Go back'
        confirmLabel='Continue'
        onConfirm={() => importFromModal.current!.open()}
        body={
          <div className='flex flex-col gap-6'>
            <div>Read the message carefully before proceeding</div>
            <div className='flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
              <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
              <div>
                If you choose to import the “Distribution” life cycle stage from another product, it will override all data that you
                currently have for that stage in this product.
              </div>
            </div>
            <div className='text-base font-semibold text-neutral-900'>Do you want to proceed?</div>
          </div>
        }
      />
      <CopyToFromProductsModal
        ref={hasDistribution(formik.values) ? importFromModal : props.importModal}
        title='Import distribution section'
        text='What product would you like to import the distribution life cycle stage from?'
        waiting={waiting}
        selected={selected}
        setSelected={setSelected}
        onNext={onImport}
        from
      />
      <CopyToFromProductsModal
        ref={props.exportModal}
        title='Export distribution section'
        text='What products you’d like to apply this distribution life cycle stage to?'
        waiting={waiting}
        selected={selected}
        setSelected={setSelected}
        onNext={() => {
          if (selected.some(hasDistribution)) {
            props.exportModal.current!.close();
            exportOverrideModal.current!.open();
          } else {
            onExport();
          }
        }}
      />
      {selected.length > 0 && (
        <ModalV3
          ref={exportOverrideModal}
          title='Export distribution section'
          cancelLabel='Go back'
          confirmLabel='Continue'
          waiting={waiting}
          noCloseOnConfirm
          onCancel={() => props.exportModal.current!.open()}
          onConfirm={onExport}
          body={
            <div className='flex flex-col gap-6'>
              <div>Read the message carefully before proceeding</div>
              <div className='flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
                <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
                <div>
                  The products below already contain data in the “Distribution” life cycle stage. By applying the new Distribution model to
                  those products you will override the current data.
                </div>
              </div>
              <table className='border-collapse rounded-lg shadow-[0_0_3px_rgba(0,0,0,0.25)]'>
                <thead>
                  <tr className='border-b border-zinc-300 whitespace-nowrap h-12'>
                    <th className='pl-5 pr-2'>Name</th>
                    <th className='px-2'>Net amount</th>
                    <th className='pl-2 pr-5'>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {selected.filter(hasDistribution).map((product, i) => (
                    <tr
                      key={product.id}
                      className={cn('h-12', {
                        'bg-slate-50': i % 2 === 1,
                        'rounded-t-lg': i === 0,
                        'rounded-b-lg': i === selected.length - 1,
                      })}
                    >
                      <td className='pl-5 pr-2' title={product.name}>
                        <div className='truncate w-56'>{product.name}</div>
                      </td>
                      <td className='px-2'>
                        {product.amount?.value}
                        {product.amount?.unit.name}
                      </td>
                      <td className='pl-2 pr-5' title={product.skuId}>
                        <div className='w-20 truncate'>{product.skuId}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='text-base font-semibold text-neutral-900'>Do you want to proceed?</div>
            </div>
          }
        />
      )}
      {selected.length > 0 && (
        <ModalV3
          ref={importOkModal}
          size='narrow'
          title={<div className='text-center'>All done!</div>}
          body={
            <div className='flex flex-col items-center gap-12'>
              <div className='w-16 aspect-square flex justify-center items-center rounded-full bg-emerald-50 text-emerald-700'>
                <FontAwesomeIcon icon={regular('check')} size='2x' />
              </div>
              <div className='text-center'>
                {selected[0].amount.value}
                {selected[0].amount.unit.name} {selected[0].name} “Distribution” life cycle stage was applied to your product.
              </div>
            </div>
          }
          hideCancel
          confirmLabel='Close'
        />
      )}
      <ModalV3
        ref={exportOkModal}
        title={<div className='text-center'>Distribution life cycle stage applied to the selected products</div>}
        body={
          <div className='flex flex-col items-center gap-12'>
            <div className='w-16 aspect-square flex justify-center items-center rounded-full bg-emerald-50 text-emerald-700'>
              <FontAwesomeIcon icon={regular('check')} size='2x' />
            </div>
            <div className='text-center'>
              {props.title} “Distribution” life cycle stage was applied to the products chosen. You can access them through the “Products”
              tab.
            </div>
          </div>
        }
        cancelLabel='Close'
        confirmLabel='Go to product list'
        onConfirm={() => navigate('/products')}
      />
    </>
  );
};
