import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { RefObject, useRef, useState } from 'react';
import { getProductsByCsvData, ManufacturingCycle, ManufacturingProduct } from '../../../../api';
import { useFormikContext } from 'formik';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import cn from 'classnames';

interface Props {
  modalRef: RefObject<ModalApi>;
  preventDismiss: boolean;
}

export interface ReportCreationFormContext {
  products: ManufacturingProduct[];
  unresolved: number;
  omitted: number;
}

export const Import = (props: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>();
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState<string>();

  const formik = useFormikContext<
    ReportCreationFormContext & {
      cycle: ManufacturingCycle;
      name: string;
    }
  >();

  const convertBytes = (bytes: number) => {
    const sizes = ['Bytes', 'KiB', 'MiB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <ModalV3
      open
      size='narrow'
      ref={props.modalRef}
      preventDismiss={props.preventDismiss}
      title={<div className='text-lg'>CSV file upload</div>}
      body={
        <div className='flex flex-col -mt-6 text-zinc-900'>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-y-3'>
              <div>You can upload a file in CSV format containing your production volumes below.</div>
              <div className=''>
                Please make sure:
                <ul className='list-disc ml-6'>
                  <li>the file is structured with three columns (product name, product ID and number of units produced)</li>
                  <li>that column headers are called as the example below</li>
                  <li>that product IDs match the records in Sustained Impact</li>
                </ul>
              </div>
              <div className='grid grid-cols-[200px_200px_200px]'>
                <div className='col-span-3 grid grid-cols-subgrid py-3 font-semibold *:px-4'>
                  <div>product_name</div>
                  <div>product_id</div>
                  <div>produced_units</div>
                </div>

                <div className='col-span-3 grid grid-cols-subgrid py-3 border-t border-zinc-300 *:px-4'>
                  <div>Product Name 1</div>
                  <div>xyz-123</div>
                  <div>10</div>
                </div>
              </div>
            </div>
          </div>

          {file && (
            <div className='flex gap-x-4 *:py-2 *:px-4 w-full'>
              <div className='flex items-center gap-x-2 text-emerald-700 bg-emerald-50 rounded-full whitespace-nowrap'>
                <FontAwesomeIcon className='size-5' icon={regular('check')} />
                <div className='pr-2'>File uploaded</div>
              </div>
              <div className='self-start items-center bg-indigo-50 rounded-lg truncate'>
                <div className='flex gap-x-2 whitespace-nowrap truncate'>
                  <div title={file.name} className='truncate font-semibold'>
                    {file.name}
                  </div>
                  <div className='text-zinc-500'>{convertBytes(file.size)}</div>
                  <button
                    onClick={() => {
                      setFile(undefined);
                      (ref as any).current.value = null;
                    }}
                  >
                    <FontAwesomeIcon className='text-lg' icon={regular('xmark')} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {error && (
            <div className='flex self-center text-center bg-red-50 rounded-lg border border-red-500 px-6 py-1.5'>
              <div className='w-full text-dark'>{error}</div>
            </div>
          )}
        </div>
      }
      footer={() => (
        <div className='w-full flex items-center justify-between'>
          <div className='flex relative'>
            <button
              type='button'
              className={cn(
                'flex items-center justify-center gap-2 bg-brand rounded-full pl-6 pr-8 py-2.5 text-white font-semibold shadow-md',
                { hidden: file },
              )}
            >
              {waiting ? (
                <>
                  <FontAwesomeIcon icon={duotone('spinner-third')} className='animate-spin' />
                  <div>Uploading…</div>
                </>
              ) : file ? (
                <>
                  <FontAwesomeIcon icon={regular('circle-check')} />
                  <div>File uploaded</div>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={regular('upload')} />
                  <div>Upload a file</div>
                </>
              )}
            </button>
            <input
              type='file'
              accept='.csv'
              disabled={waiting || file !== undefined}
              ref={ref}
              onClick={() => {
                if (error) {
                  setFile(undefined);
                  (ref as any).current.value = null;
                }
              }}
              className='absolute inset-0 text-transparent file:w-full file:h-full file:opacity-0 file:cursor-pointer file:disabled:cursor-default'
              onChange={(event) => {
                const file = event.target.files && event.target.files[0];

                if (!file) {
                  return;
                }

                if (file.size > 2097152) {
                  setError(`Uploaded file is ${convertBytes(file.size)}. File size should not exceed 2 MB`);
                  return;
                }

                setError(undefined);
                setWaiting(true);
                getProductsByCsvData({ file: file, reportType: 'forecast' }).call({
                  ok: (data) => {
                    if (data.errorCode) {
                      setError(data.message);
                      setWaiting(false);
                      return;
                    }

                    const products = [
                      ...data.resolvedProducts.map((item) => ({
                        ...item,
                        selected: true,
                        resolved: true,
                        models: [],
                      })),
                    ].sort((a, b) => {
                      const aValue = [undefined, null, 0].includes(a.count) ? -1 : a.count;
                      const bValue = [undefined, null, 0].includes(b.count) ? -1 : b.count;

                      if (aValue === -1 && bValue !== -1) {
                        return -1;
                      } else if (aValue !== -1 && bValue === -1) {
                        return 1;
                      }

                      return 0;
                    });

                    formik.setFieldValue('products', products);
                    formik.setFieldValue('unresolved', data.unresolvedProducts.length);
                    formik.setFieldValue('omitted', data.omittedProductCount);

                    setWaiting(false);
                    setFile(file);
                  },
                  fail: () => setWaiting(false),
                });
              }}
            />
          </div>

          {file && (
            <button
              type='button'
              className={cn(
                'flex self-end text-sm items-center gap-2.5 border-2 rounded-full px-4 py-2 font-semibold',
                '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
                'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
              )}
              onClick={props.modalRef.current?.close}
            >
              Next
              <FontAwesomeIcon className='text-base' icon={solid('chevron-right')} />
            </button>
          )}
        </div>
      )}
    ></ModalV3>
  );
};
