import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useState } from 'react';

interface Props {
  title: string;
  open?: boolean;
}

export const CollapsibleSection = (props: PropsWithChildren<Props>) => {
  const [expanded, setExpanded] = useState(props.open ?? false);
  return (
    <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
      <div className='flex items-center justify-between px-8 print:px-0 text-dark'>
        <button onClick={() => setExpanded(!expanded)} className='flex items-center justify-between hover:text-brandDark'>
          <div className='text-xl font-semibold py-6'>{props.title}</div>
        </button>
        <button onClick={() => setExpanded(!expanded)} className='flex items-center justify-between hover:text-brandDark'>
          <FontAwesomeIcon className='text-xl print:hidden' icon={expanded ? regular('circle-minus') : regular('circle-plus')} />
        </button>
      </div>
      {expanded && <div className='px-8 pb-8 print:hidden'>{props.children}</div>}
      <div className='hidden print:block pb-8'>{props.children}</div>
    </div>
  );
};
