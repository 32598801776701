import { addMonths, subMonths } from 'date-fns';
import { ImpactTracking } from '../../../../api';

export const convert = (impact: ImpactTracking) => ({
  getReportsRanges: () => {
    if (impact.baseline) {
      const baselineImpactData = {
        name: impact.baseline.name,
        points: impact.baseline.totalImpact.points,
      };

      const baselineReport = [
        {
          ...baselineImpactData,
          date: new Date(impact.baseline.start.date),
        },
        {
          ...baselineImpactData,
          date: new Date(impact.baseline.end.date),
        },
      ];

      const historicalReports = impact.reports.map((report) => {
        const impactData = {
          name: report.name,
          points: report.totalImpact.points,
        };

        return [
          {
            ...impactData,
            date: new Date(report.start.date),
          },
          {
            ...impactData,
            date: new Date(report.end.date),
          },
        ];
      });

      return [baselineReport, ...historicalReports];
    }
  },

  getReportsLinks: () => {
    if (impact.baseline && impact.reports.length > 0) {
      const betweenBaselineAndFirstReportLink = [
        {
          name: impact.baseline.name,
          points: impact.baseline.totalImpact.points,
          date: addMonths(new Date(impact.baseline.end.date), 1),
        },
        {
          name: impact.reports[0].name,
          points: impact.reports[0].totalImpact.points,
          date: subMonths(new Date(impact.reports[0].start.date), 1),
        },
      ];

      const betweenReportsLinks = impact.reports
        .map((report, i, arr) => {
          const nextReport = arr[i + 1];
          if (nextReport) {
            return [
              {
                name: report.name,
                points: report.totalImpact.points,
                date: addMonths(new Date(report.end.date), 1),
              },
              {
                name: nextReport.name,
                points: nextReport.totalImpact.points,
                date: subMonths(new Date(nextReport.start.date), 1),
              },
            ];
          } else {
            return undefined;
          }
        })
        .filter((report) => report);

      return [betweenBaselineAndFirstReportLink, ...betweenReportsLinks];
    }
  },

  getBaselineData: () => {
    if (impact.baseline) {
      return {
        name: impact.baseline.name,
        points: impact.baseline.totalImpact.points,
      };
    }
  },

  getForecastData: () => {
    if (impact.forecast) {
      return {
        name: impact.forecast.name,
        points: impact.forecast.totalImpact.points,
      };
    }
  },

  getTargetData: () => {
    if (impact.target) {
      return {
        estimate: impact.target.estimate,
        type: impact.target.type,
        start: {
          ...impact.target.start,
          date: new Date(impact.target.start.date),
          totalPoints: 0, // TODO: not in api. have to be added
        },
        end: {
          ...impact.target.end,
          date: new Date(impact.target.end.date),
          totalPoints: 0, // TODO: not in api. have to be added
        },
        targetBadge: impact.targetBadge,
      };
    }
  },

  getTargetHint: () => impact.targetHint,

  getPredictionData: () => {
    if (impact.estimate) {
      return impact.estimate;
    }
  },

  getAxisValues: () => {
    return {
      xAxis: {
        startDate: new Date(impact.xAxis.startDate),
        endDate: new Date(impact.xAxis.endDate),
      },
      yAxis: {
        points: impact.yAxisMaxValue.points,
        physical: impact.yAxisMaxValue.physical,
      },
    };
  },

  getTargetDateBadge: () => impact.targetDateBadge,
});
