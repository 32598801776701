import { FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import { useEffect } from 'react';
import {
  calculateProductModelV3,
  GenericFacilityWithStepsNode,
  GenericNode,
  GenericStepNode,
  ImpactDelta,
  ModellingPayload,
  PrimaryNode,
  ProductModelV3,
} from '../../../../api';
import { useDebounce } from '../../../../hooks/useDebounce';
import { getFacilitiesWithStepsFromAll, getPrimaryAndMaterialSuppliersFromAll } from './dataModel';

interface Props<T extends GenericNode> {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  parentNode?: GenericFacilityWithStepsNode;
  toNode?: (form: T) => T;
  toFromId?: (form: T) => string;
  onChange: (impactDelta?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

export const useInteractiveImpact = <T extends GenericNode>(props: Props<T>) => {
  const formik = useFormikContext<T>();
  const deboucedValues = useDebounce(formik.values, 300);

  useEffect(() => {
    props.onChange(props.payload.changes.find(({ id }) => id === formik.values.id)?.impactDelta);
    return () => props.onChange(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const clonedProduct = cloneDeep(props.productFormik.values);
    const updateClonedProduct = (values: T) => {
      let node: T;
      let fromId = '';

      try {
        node = props.toNode ? props.toNode(values) : values;

        if (props.toFromId) {
          fromId = props.toFromId(values);
        }
      } catch (e) {
        return;
      }

      if (props.parentNode) {
        const parentNode = getFacilitiesWithStepsFromAll(clonedProduct.nodes).find(({ id }) => id === props.parentNode!.id)!;
        const index = parentNode.steps.findIndex(({ id }) => id === node.id);

        if (index < 0) {
          parentNode.steps.push(node as any as GenericStepNode);
        } else {
          parentNode.steps[index] = node as any as GenericStepNode;
        }
      } else {
        const index = clonedProduct.nodes.findIndex(({ id }) => id === node.id);

        if (index < 0) {
          clonedProduct.nodes.push(node as any as PrimaryNode);
        } else {
          clonedProduct.nodes[index] = node as any as PrimaryNode;
        }

        if (fromId) {
          getPrimaryAndMaterialSuppliersFromAll(clonedProduct.nodes).forEach((n) => {
            n.edges = n.edges.filter((edgeId) => edgeId !== node.id);
          });
          getPrimaryAndMaterialSuppliersFromAll(clonedProduct.nodes)
            .find(({ id }) => id === fromId)!
            .edges.push(node.id);
        }
      }
    };

    if (Array.isArray((deboucedValues as any).facility)) {
      (deboucedValues as any).facility.forEach(updateClonedProduct);
    } else {
      updateClonedProduct(deboucedValues);
    }

    props.onCalculating(true);
    calculateProductModelV3({
      ...props.payload,
      model: clonedProduct,
    }).ok(({ changes }) => {
      if (Array.isArray((deboucedValues as any).facility) && (deboucedValues as any).facility.length > 0) {
        props.onChange(changes.find(({ id }) => id === last(clonedProduct.nodes)!.id)?.impactDelta);
      } else {
        props.onChange(changes.find(({ id }) => id === deboucedValues.id)?.impactDelta);
      }

      props.onCalculating(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deboucedValues]);
};
