import { forwardRef } from 'react';
import { ProgressChartPlaceholder } from './ProgressChartPlaceholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  title: string;
  attention: string;
}

export const ProgressTrackingPlaceholder = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref} className='flex flex-col px-2 gap-4'>
      <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      <div className='text-base flex flex-1 items-center gap-x-4 bg-white p-4 rounded-lg border'>
        <div className='size-10 bg-brandLime flex shrink-0 items-center justify-center rounded-full'>
          <FontAwesomeIcon className='text-[18px] text-zinc-700' icon={light('lightbulb')} />
        </div>
        <div>{props.attention}</div>
      </div>
      <div className='bg-white pt-4 rounded-lg border'>
        <div className='h-64 ml-20 p-6 opacity-50'>
          <ProgressChartPlaceholder />
        </div>
      </div>
    </div>
  );
});
