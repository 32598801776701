import { CreateReport } from '../../../../components/Lists/CreateReport';
import { ManufacturingCycle, ReportType } from '../../../../../../../api';
import { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Import } from '../../../../components/Import';
import { ModalApi } from '../../../../../../../components/ModalV3';
import cn from 'classnames';

interface Props {
  reportTitle: string;
  onNext: () => void;
}

export const Step2 = (props: Props) => {
  const formik = useFormikContext<{ cycle: ManufacturingCycle }>();
  const modalRef = useRef<ModalApi>(null);

  return (
    <div>
      <Import preventDismiss={false} modalRef={modalRef} />
      <div className='flex flex-col gap-y-2'>
        <div className='font-semibold text-lg text-dark'>Confirm or edit mapped products and production timeframe</div>
        <div>Choose a timeframe over which you want to forecast your impact, and adjust products and volumes mapped if needed.</div>
      </div>
      <div className='grid grid-cols-[1fr_1px_8fr] gap-x-6 h-full pt-6'>
        <div className='flex flex-col gap-y-4'>
          <div className='font-semibold'>Timeframe</div>
          <div className='flex flex-col gap-y-4'>
            {[
              {
                cycle: ManufacturingCycle.Year,
              },
              {
                cycle: ManufacturingCycle.Quarter,
              },
              {
                cycle: ManufacturingCycle.Month,
              },
            ].map((item) => (
              <button
                key={item.cycle}
                onClick={() => {
                  formik.setFieldValue('cycle', item.cycle);
                }}
                className={cn(
                  'p-4 border rounded-[8px] capitalize',
                  {
                    'bg-slate-100 border-brand font-semibold': formik.values.cycle === item.cycle,
                  },
                  {
                    'border-red-500': formik.errors.cycle,
                  },
                )}
              >
                {item.cycle}
              </button>
            ))}
          </div>
        </div>
        <div className='bg-zinc-200 h-full w-px'></div>
        <div className='flex flex-col gap-y-4'>
          <div className='font-semibold'>Products and volumes</div>
          <CreateReport dataFromCsv reportType={ReportType.Forecast} reportTitle={props.reportTitle} onNext={props.onNext} />
        </div>
      </div>
    </div>
  );
};
