import { Lens } from '../../../../../../api';
import cn from 'classnames';

interface Props {
  lens: Lens;
}

export const ProductSkeletonLoader = (props: Props) => {
  const skeleton = (height?: string, width?: string, borderRadius?: string) => (
    <div
      className={cn('loading-skeleton', borderRadius ?? '')}
      style={{
        height: height,
        width: width,
      }}
    />
  );

  return (
    <div>
      <div className='mx-6 -mt-10'>
        <div className='flex justify-between items-center h-20'>
          <div className='flex w-full'>{skeleton('26px', '65%')}</div>
          <div className='flex gap-3'>{skeleton('36px', '114px', 'rounded-full')}</div>
        </div>
        <div className='border-t -mx-3' />
        <div className='flex items-center gap-x-3'>
          <div className='grid grid-cols-3 w-full my-3 divide-x'>
            {new Array(3).fill(null).map((_, i, arr) => (
              <div
                key={i}
                className={cn('flex flex-col h-[66px] justify-evenly', i === 0 ? 'pr-3' : i === arr.length - 1 ? 'pl-3' : 'px-3')}
              >
                <div className='mt-1'>
                  {skeleton('16px', '50%')}
                  {skeleton('12px', '95%')}
                </div>
              </div>
            ))}
          </div>
          <div className='flex gap-x-3'>
            {skeleton('32px', '170px', 'rounded-lg')}
            {skeleton('32px', '32px', 'rounded-lg')}
          </div>
        </div>
        <div className='border-t -mx-3' />
        <div className='mt-6'>
          <div className='flex flex-col gap-6'>
            <div className='grid grid-cols-3 gap-x-4'>
              <div className='flex flex-col self-start h-[498px] rounded-xl border'>
                <div className='flex h-[200px] justify-center items-center p-2'>{skeleton('90%', '200px')}</div>
                <div className='flex flex-col justify-between gap-6 p-6'>
                  <div className='flex flex-col gap-2'>
                    {skeleton('20px', '80%')}
                    {skeleton('20px', '30%')}
                  </div>
                  {skeleton('20px', '60%')}
                  <div className='flex flex-col gap-2'>
                    {skeleton('20px', '30%')}
                    {skeleton('20px', '70%')}
                  </div>
                  <div className='flex flex-col gap-2'>
                    {skeleton('20px', '40%')}
                    {skeleton('20px', '60%')}
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-4 col-span-2'>
                <div className='grid grid-cols-2 gap-x-4'>
                  <div className='h-[134px] border rounded-2xl overflow-hidden'>{skeleton('100%', '100%')}</div>
                  <div className='h-[134px] border rounded-2xl overflow-hidden'>{skeleton('100%', '100%')}</div>
                </div>
                <div className='flex flex-col h-full rounded-2xl border overflow-hidden'>{skeleton('100%', '100%')}</div>
              </div>
            </div>
            {props.lens === Lens.Production && (
              <div className='border-t border-zinc-100'>
                {new Array(3).fill(null).map((_, i) => (
                  <div key={i}>
                    <div className='w-full h-[97px] border-b border-zinc-100 flex justify-between items-center px-6 py-4'>
                      <div className='flex items-center w-full gap-8'>
                        {skeleton('40px', '40px', 'rounded-full')}
                        <div className='flex flex-col gap-3 flex-1'>
                          {skeleton('24px', '200px')}
                          {skeleton('24px', '80%')}
                        </div>
                      </div>
                      {skeleton('24px', '24px', 'rounded-full')}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
