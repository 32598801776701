import { Fragment, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import MultiRef from 'react-multi-ref';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { DataCompletion } from './sections/DataCompletion';
import { EcoLabellingDetails } from './sections/EcoLabellingDetails';
import { ImpactDetails } from './sections/ImpactDetails';
import { Overview } from './sections/Overview';
import { ProgressTracking } from './sections/ProgressTracking';
import { SupplyChain } from './sections/SupplyChain';
import { WinnersLosers } from './sections/WinnersLosers';
import { Methodology, SectionId, Timeframe } from './types';

export const Prototype = () => {
  const sectionRefs = useRef(new MultiRef<SectionId, HTMLDivElement>());

  const [methodology, setMethodology] = useState(Methodology.Production);
  const [baseline, setBaseline] = useState({
    from: new Date('2020-01-28'),
    to: new Date('2021-01-28'),
  });
  const [timeframe, setTimeframe] = useState(Timeframe.Month);

  const [impactCategories, setImpactCategories] = useState(new Array<string>());
  const [workspaceIds, setWorkspaceIds] = useState(new Array<string>());
  const [brandTypes, setBrandTypes] = useState(new Array<string>());
  const [categoryIds, setCategoryIds] = useState(new Array<string>());
  const [productTypes, setProductTypes] = useState(new Array<string>());
  const [countryIds, setCountryIds] = useState(new Array<string>());
  const [physicalImpact, setPhysicalImpact] = useState(false);

  const headerProps = {
    impactCategories,
    setImpactCategories,
    workspaceIds,
    setWorkspaceIds,
    brandTypes,
    setBrandTypes,
    categoryIds,
    setCategoryIds,
    productTypes,
    setProductTypes,
    countryIds,
    setCountryIds,
    physicalImpact,
    setPhysicalImpact,
  };
  const sectionProps = {
    methodology,
    baseline,
    timeframe,
    physicalImpact,
    impactCategories,
    workspaceIds,
  };
  const sections = [
    {
      id: SectionId.Overview,
      label: 'Overview',
      component: <Overview ref={sectionRefs.current.ref(SectionId.Overview)} title='Overview' {...sectionProps} />,
    },
    {
      id: SectionId.ProgressTracking,
      label: 'Progress tracking',
      component: <ProgressTracking ref={sectionRefs.current.ref(SectionId.ProgressTracking)} title='Progress tracking' {...sectionProps} />,
    },
    {
      id: SectionId.ImpactDetails,
      label: 'Impact details',
      component: <ImpactDetails ref={sectionRefs.current.ref(SectionId.ImpactDetails)} title='Impact details' {...sectionProps} />,
    },
    {
      id: SectionId.WinnersLosers,
      label: 'Winners & losers',
      component: (
        <WinnersLosers ref={sectionRefs.current.ref(SectionId.WinnersLosers)} title='Winners & losers at volumes' {...sectionProps} />
      ),
    },
    {
      id: SectionId.DataCompletion,
      label: 'Data completion',
      component: (
        <DataCompletion ref={sectionRefs.current.ref(SectionId.DataCompletion)} title='Data completion on average' {...sectionProps} />
      ),
    },
    {
      id: SectionId.SupplyChain,
      label: 'Supply chain',
      component: <SupplyChain ref={sectionRefs.current.ref(SectionId.SupplyChain)} title='Supply chain' {...sectionProps} />,
    },
    {
      id: SectionId.EcoLabellingDetails,
      label: 'Eco-labelling details',
      component: (
        <EcoLabellingDetails
          ref={sectionRefs.current.ref(SectionId.EcoLabellingDetails)}
          title='Eco-labelling details (For 1 kg of product)'
          {...sectionProps}
        />
      ),
    },
  ].filter(({ id }) =>
    ({
      [Methodology.Production]: [
        SectionId.Overview,
        SectionId.ProgressTracking,
        SectionId.ImpactDetails,
        SectionId.WinnersLosers,
        SectionId.DataCompletion,
        SectionId.SupplyChain,
      ],
      [Methodology.Consumer]: [SectionId.Overview, SectionId.EcoLabellingDetails],
      [Methodology.Ghg]: [SectionId.Overview, SectionId.ProgressTracking, SectionId.ImpactDetails],
    }[methodology].includes(id)),
  );

  return (
    <div className='min-h-full flex leading-none text-sm'>
      <Helmet title='Dashboard' />
      <Sidebar
        methodology={methodology}
        setMethodology={setMethodology}
        baseline={baseline}
        setBaseline={setBaseline}
        timeframe={timeframe}
        setTimeframe={setTimeframe}
        sections={sections}
        sectionRefs={sectionRefs.current.map}
      />
      <div className='flex-1 flex flex-col'>
        <Header {...headerProps} />
        <div className='flex flex-col gap-12 px-5 py-6'>
          {sections.map(({ id, component }) => (
            <Fragment key={id}>{component}</Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
