import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProfile } from '../hooks/useProfile';
import { Grade, Methodology, grades } from '../api';
import cn from 'classnames';

interface Props {
  disabled?: boolean;
  grade: Grade | '';
  shift?: boolean;
}

export const GradeBadge = (props: Props) => {
  const profile = useProfile();

  if (!props.grade) {
    return (
      <div className='flex self-start gap-1 items-center rounded-full bg-neutral-100 h-[32px] mt-[11px] pl-1 pr-3 print:shadow-none shadow-[0px_2px_4px_rgba(0,_0,_0,_0.25)] text-neutral-400 text-xs'>
        <div className='flex items-center justify-center rounded-full p-1 bg-brandLime'>
          <FontAwesomeIcon className='h-4 aspect-square' icon={light('arrows-rotate')} />
        </div>
        <div>Loading value…</div>
      </div>
    );
  }

  if (profile.selectedWorkspace.methodology.type === Methodology.FoundationEarth) {
    return <img src={`https://cdn.foundation-earth.org/v1/certificate/grade/long/${props.grade}.svg`} width='100' alt={props.grade} />;
  }

  return (
    <div className='flex flex-col w-[116px]'>
      <div className='flex self-start rounded-full h-[16px] pt-[2px] ml-[13px] -mb-[5px] bg-[#f4f4f4] z-[0]'>
        <span className='opacity-50 font-semibold text-neutral-700 px-[10px] text-[7px] tracking-[0.5px]'>Estimated</span>
      </div>
      <div className='flex items-center rounded-full bg-neutral-100 h-[32px] px-[5px] print:shadow-none shadow-[0px_2px_4px_rgba(0,_0,_0,_0.25)]'>
        {grades.map((grade) => (
          <div
            key={grade}
            className={cn(
              'flex justify-center items-center',
              grade === props.grade || (grade === Grade.A && props.grade === Grade.AA)
                ? 'rounded-full text-white size-[22px] text-[14px]'
                : 'opacity-50 text-neutral-700 w-[14px] text-[10px]',
              {
                'bg-gray-500': grade === props.grade && props.disabled,
                'bg-aa': grade === Grade.A && props.grade === Grade.AA,
                'bg-a': grade === Grade.A && grade === props.grade,
                'bg-b': grade === Grade.B && grade === props.grade,
                'bg-c': grade === Grade.C && grade === props.grade,
                'bg-d': grade === Grade.D && grade === props.grade,
                'bg-e': grade === Grade.E && grade === props.grade,
                'bg-f': grade === Grade.F && grade === props.grade,
                'bg-g': grade === Grade.G && grade === props.grade,
              },
            )}
          >
            <span className={cn({ 'pb-0.5': grade === props.grade || (grade === Grade.A && props.grade === Grade.AA && props.shift) })}>
              {grade === Grade.A && props.grade === Grade.AA ? props.grade : grade}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
