import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { ReactNode, RefObject, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import {
  ImpactDeltaType,
  LifeCycleStage,
  ModellingBadgeType,
  ModellingChange,
  ModellingChangeAction,
  ModellingPayload,
  ProductModelV3,
  TransportNode,
  getLifeCycleForNodeType,
} from '../../../../api';
import { Menu } from '../../../../components/Menu';
import { ModalFormSaveCallback } from '../../../../components/ModalForm';
import { TransportDetails } from './TransportDetails';
import { getPrimaryAndMaterialSuppliersWithDeleted, hasValidationMessage } from './dataModel';

interface Props {
  payload: ModellingPayload;
  disabled: boolean;
  nodeRefs: RefObject<Record<string, RefObject<HTMLDivElement>>>;
  lifeCycleBoxRefs: RefObject<Record<LifeCycleStage, RefObject<HTMLDivElement>>>;
  scrollableRef: RefObject<HTMLDivElement>;
  fromId: string;
  toId: string;
  transport?: TransportNode;
  ignoreLessImportantValidation: boolean;
  readOnlyMode: boolean;
  onSave?: ModalFormSaveCallback<TransportNode, { fromId: string }>;
  onDelete?: () => void;
}

export const TransportLink = (props: Props) => {
  const formik = useFormikContext<ProductModelV3>();
  const [, render] = useReducer((x) => x + 1, 0);
  const fromRef = props.nodeRefs.current![props.fromId]?.current;
  const toRef = props.nodeRefs.current![props.toId]?.current;
  const scrollableRef = props.scrollableRef.current!;
  const [hovering, setHovering] = useState(false);

  useLayoutEffect(render, [formik.values, render]);

  useEffect(() => {
    window.addEventListener('resize', render);
    return () => window.removeEventListener('resize', render);
  }, []);

  useEffect(() => {
    const refs = Object.values(props.lifeCycleBoxRefs.current!);
    const observer = new ResizeObserver(render);

    refs.forEach((ref) => observer.observe(ref.current!));

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!fromRef || !toRef) {
    return <></>;
  }

  const fromNode = getPrimaryAndMaterialSuppliersWithDeleted(formik, props.payload, props.readOnlyMode).find(
    ({ id }) => id === props.fromId,
  );
  const toNode = getPrimaryAndMaterialSuppliersWithDeleted(formik, props.payload, props.readOnlyMode).find(({ id }) => id === props.toId);

  if (!fromNode || !toNode) {
    return <></>;
  }

  const fromLifeCycle = getLifeCycleForNodeType(fromNode.type);
  const toLifeCycle = getLifeCycleForNodeType(toNode.type);
  const fromBoxRef = props.lifeCycleBoxRefs.current![fromLifeCycle].current;
  const toBoxRef = props.lifeCycleBoxRefs.current![toLifeCycle].current;

  if (!fromBoxRef || !toBoxRef) {
    return <></>;
  }

  const from = fromRef.getBoundingClientRect();
  const to = toRef.getBoundingClientRect();
  const fromBox = fromBoxRef.getBoundingClientRect();
  const toBox = toBoxRef.getBoundingClientRect();
  const sameBox = fromBoxRef === toBoxRef;
  const scrollable = scrollableRef.getBoundingClientRect();
  const change = props.payload.changes.find(({ id }) => id === props.transport?.id && !props.readOnlyMode);
  const highImpact =
    props.payload.badges.find(({ id }) => id === props.transport?.id && !props.readOnlyMode)?.type === ModellingBadgeType.HighImpact;
  const editable = !!props.transport;
  const enabled = editable && !props.disabled && (!change || change.action !== ModellingChangeAction.Deleted);
  const deletable = !props.readOnlyMode;
  const error = editable && hasValidationMessage(props.transport!, formik, { ignoreLessImportant: props.ignoreLessImportantValidation });

  const onMouseEnter = () => {
    if (enabled) {
      setHovering(true);
    }
  };

  const onMouseLeave = () => {
    setHovering(false);
  };

  const openMenu = (children: ReactNode, onDelete?: () => void) => {
    return enabled && props.onSave ? (
      <Menu
        zIndex={4}
        placement='bottom-end'
        scrollableParent={scrollableRef}
        items={[
          {
            label: 'Edit',
            icon: regular('pen'),
            modal: (button, onOpenChange) => (
              <TransportDetails
                onOpenChange={onOpenChange}
                payload={props.payload}
                readOnlyMode={props.readOnlyMode}
                data={props.transport}
                onSave={props.onSave!}
              >
                {button}
              </TransportDetails>
            ),
          },
          {
            label: 'Delete',
            icon: regular('trash-alt'),
            onClick: onDelete,
          },
        ]}
      >
        {() => children}
      </Menu>
    ) : (
      children
    );
  };

  if (from.left === to.left) {
    return (
      <Vertical
        change={change}
        from={from}
        to={to}
        scrollableRef={scrollableRef}
        scrollable={scrollable}
        hovering={hovering}
        enabled={enabled}
        editable={editable}
        deletable={deletable}
        error={error}
        highImpact={highImpact}
        onDelete={props.onDelete}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        openMenu={openMenu}
      />
    );
  }

  if (from.left > to.left) {
    return (
      <Backwards
        change={change}
        from={from}
        to={to}
        fromBox={fromBox}
        toBox={toBox}
        sameBox={sameBox}
        scrollableRef={scrollableRef}
        scrollable={scrollable}
        hovering={hovering}
        enabled={enabled}
        editable={editable}
        deletable={deletable}
        error={error}
        highImpact={highImpact}
        onDelete={props.onDelete}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        openMenu={openMenu}
      />
    );
  }

  return (
    <Forwards
      change={change}
      lifeCycleBoxRefs={props.lifeCycleBoxRefs}
      from={from}
      to={to}
      fromLifeCycle={fromLifeCycle}
      toLifeCycle={toLifeCycle}
      fromBox={fromBox}
      toBox={toBox}
      sameBox={sameBox}
      scrollableRef={scrollableRef}
      scrollable={scrollable}
      hovering={hovering}
      enabled={enabled}
      editable={editable}
      deletable={deletable}
      error={error}
      highImpact={highImpact}
      onDelete={props.onDelete}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      openMenu={openMenu}
    />
  );
};

const Forwards = (props: {
  change?: ModellingChange;
  lifeCycleBoxRefs: RefObject<Record<LifeCycleStage, RefObject<HTMLDivElement>>>;
  from: DOMRect;
  to: DOMRect;
  fromLifeCycle: LifeCycleStage;
  toLifeCycle: LifeCycleStage;
  fromBox: DOMRect;
  toBox: DOMRect;
  sameBox: boolean;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  hovering: boolean;
  enabled: boolean;
  editable: boolean;
  deletable: boolean;
  error: boolean;
  highImpact: boolean;
  onDelete?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openMenu: (children: ReactNode, onDelete?: () => void) => ReactNode;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromRight = props.from.right + leftOffset;
  const toTop = props.to.top + topOffset;
  const toLeft = props.to.left + leftOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const fullWidth = props.to.left - props.from.right;
  const fromWidthExtraBoxSpace = (() => {
    if (props.fromLifeCycle === LifeCycleStage.Production && props.toLifeCycle === LifeCycleStage.Use) {
      const middleBox = props.lifeCycleBoxRefs.current![LifeCycleStage.Distribution].current!.getBoundingClientRect();
      return middleBox.right - props.fromBox.right;
    }

    return 0;
  })();
  const fromWidth = props.sameBox
    ? fullWidth / 2
    : props.fromBox.right + (props.toBox.left - props.fromBox.right + fromWidthExtraBoxSpace) / 2 - props.from.right;
  const toWidth = fullWidth - fromWidth;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const fromStraightWidth = fromWidth - cornerSize;
  const toStraightWidth = toWidth - cornerSize;

  const getColor = () => {
    if (!props.editable) {
      return 'border-zinc-400 text-zinc-400';
    }

    if (props.error) {
      return 'border-red-500 text-red-500';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'border-blue-500 text-blue-500';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'border-[#753BFD] text-[#753BFD]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'border-zinc-300 text-zinc-300';
    }

    if (props.highImpact) {
      return 'border-amber-400 text-amber-400';
    }

    return 'border-[#330099] text-[#330099]';
  };

  const getColorAsBg = () => {
    if (!props.editable) {
      return 'bg-zinc-400';
    }

    if (props.error) {
      return 'bg-red-500';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'bg-blue-500';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'bg-[#753BFD]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'bg-zinc-300';
    }

    if (props.highImpact) {
      return 'bg-amber-400';
    }

    return 'bg-[#330099]';
  };

  const getHoverColor = () => {
    if (props.error) {
      return 'border-red-50 text-red-50';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'border-slate-100 text-slate-100';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'border-[#E8EAF5] text-[#E8EAF5]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'border-zinc-100 text-zinc-100';
    }

    if (props.highImpact) {
      return 'border-amber-100 text-amber-100';
    }

    return 'border-[#E8EAF5] text-[#E8EAF5]';
  };

  const getHoverColorAsBg = () => {
    if (props.error) {
      return 'bg-red-50';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'bg-slate-100';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'bg-[#E8EAF5]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'bg-zinc-100';
    }

    if (props.highImpact) {
      return 'bg-amber-100';
    }

    return 'bg-[#E8EAF5]';
  };

  return (
    <>
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          icon={solid('circle')}
          className={cn('absolute h-[9px] aspect-square', getHoverColor())}
          style={{
            left: `${fromRight - 4}px`,
            top: `${fromCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        icon={solid('circle')}
        className={cn('absolute h-[5px] aspect-square z-[1]', getColor())}
        style={{
          left: `${fromRight - 2}px`,
          top: `${fromCenter - 2}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn('h-[9px] aspect-square absolute rotate-90', getHoverColor())}
          icon={solid('triangle')}
          style={{
            left: `${toLeft - 6}px`,
            top: `${toCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn('h-[5px] aspect-square absolute z-[1] rotate-90', getColor())}
        icon={solid('triangle')}
        style={{
          left: `${toLeft - 4}px`,
          top: `${toCenter - 2}px`,
        }}
      />
      {props.hovering && props.enabled && (
        <div
          className={cn('corner-from absolute rounded-full border-[5px] rotate-45 aspect-square', getHoverColor(), {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          })}
          style={{
            width: `${cornerSize * 2 + 4}px`,
            left: `${fromRight + fromStraightWidth - cornerSize - 1}px`,
            top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1) - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('corner-from absolute rounded-full rotate-45 border aspect-square', getColor(), {
          'border-l-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
          'border-l-transparent border-r-transparent border-b-transparent': fromCenter < toCenter,
          invisible: cornerSize === 0,
        })}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromRight + fromStraightWidth - cornerSize + 1}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('corner-to absolute rounded-full border-[5px] rotate-45 aspect-square', getHoverColor(), {
            'border-r-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-t-transparent border-r-transparent border-l-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          })}
          style={{
            width: `${cornerSize * 2 + 4}px`,
            left: `${toLeft - toStraightWidth - cornerSize - 2}px`,
            top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1) - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('corner-to absolute rounded-full rotate-45 border aspect-square', getColor(), {
          'border-r-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
          'border-t-transparent border-r-transparent border-l-transparent': fromCenter < toCenter,
          invisible: cornerSize === 0,
        })}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${toLeft - toStraightWidth - cornerSize}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('h-line-from absolute h-[5px]', getHoverColorAsBg())}
          style={{
            width: `${fromStraightWidth + 1}px`,
            left: `${fromRight}px`,
            top: `${fromCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('h-line-from absolute h-px', getColorAsBg())}
        style={{
          width: `${fromStraightWidth + 1}px`,
          left: `${fromRight}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('v-line absolute w-[5px]', getHoverColorAsBg(), {
            invisible: cornerSize === 0,
          })}
          style={{
            height: `${straightHeight + 1}px`,
            left: `${fromRight + fromWidth - 2}px`,
            top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
          }}
        ></div>
      )}
      <div
        className={cn('v-line absolute w-px', getColorAsBg())}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${fromRight + fromWidth}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('h-line-to absolute h-[5px]', getHoverColorAsBg())}
          style={{
            width: `${toStraightWidth - 2}px`,
            left: `${toLeft - toStraightWidth}px`,
            top: `${toCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('h-line-to absolute h-px', getColorAsBg())}
        style={{
          width: `${toStraightWidth - 1}px`,
          left: `${toLeft - toStraightWidth}px`,
          top: `${toCenter}px`,
        }}
      ></div>
      {!props.hovering && props.change && !props.highImpact && (
        <div
          className={cn(
            'delta pointer-events-none absolute text-[8px] p-px font-semibold -translate-y-1/2 leading-none rounded-md z-[1]',
            'bg-white',
            {
              [ImpactDeltaType.Higher]: 'text-red-500',
              [ImpactDeltaType.Lower]: 'text-emerald-700',
              [ImpactDeltaType.Zero]: 'text-zinc-500',
            }[props.change.impactDelta.type],
          )}
          style={{
            left: `${fromRight + fromStraightWidth / 2}px`,
            top: `${fromCenter}px`,
          }}
        >
          {props.change.impactDelta.display}
        </div>
      )}
      {!props.hovering && !props.change && props.highImpact && (
        <FontAwesomeIcon
          icon={solid('seal-exclamation')}
          className='pointer-events-none absolute h-3 aspect-square rounded-full bg-white text-amber-400 -translate-y-1/2 z-[1]'
          style={{
            left: `${fromRight + fromStraightWidth / 2}px`,
            top: `${fromCenter}px`,
          }}
        />
      )}
      {props.openMenu(
        <div
          className={cn('h-line-from absolute h-4 -translate-y-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${fromWidth}px`,
            left: `${fromRight}px`,
            top: `${fromCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('v-line absolute w-4 -translate-x-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            height: `${height}px`,
            left: `${fromRight + fromWidth}px`,
            top: `${Math.min(fromCenter, toCenter)}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('h-line-to absolute h-4 -translate-y-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${toWidth}px`,
            left: `${toLeft - toWidth}px`,
            top: `${toCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
    </>
  );
};

const Backwards = (props: {
  change?: ModellingChange;
  from: DOMRect;
  to: DOMRect;
  fromBox: DOMRect;
  toBox: DOMRect;
  sameBox: boolean;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  hovering: boolean;
  enabled: boolean;
  editable: boolean;
  deletable: boolean;
  error: boolean;
  highImpact: boolean;
  onDelete?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openMenu: (children: ReactNode, onDelete?: () => void) => ReactNode;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop + 10;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromLeft = props.from.left + leftOffset;
  const toTop = props.to.top + topOffset;
  const toRight = props.to.right + leftOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const fullWidth = props.from.left - props.to.right;
  const toWidth = props.sameBox ? fullWidth / 2 : props.toBox.right + (props.fromBox.left - props.toBox.right) / 2 - props.to.right;
  const fromWidth = fullWidth - toWidth;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const fromStraightWidth = fromWidth - cornerSize;
  const toStraightWidth = toWidth - cornerSize;

  const getColor = () => {
    if (!props.editable) {
      return 'border-zinc-400 text-zinc-400';
    }

    if (props.error) {
      return 'border-red-500 text-red-500';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'border-blue-500 text-blue-500';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'border-[#753BFD] text-[#753BFD]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'border-zinc-300 text-zinc-300';
    }

    if (props.highImpact) {
      return 'border-amber-400 text-amber-400';
    }

    return 'border-[#330099] text-[#330099]';
  };

  const getColorAsBg = () => {
    if (!props.editable) {
      return 'bg-zinc-400';
    }

    if (props.error) {
      return 'bg-red-500';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'bg-blue-500';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'bg-[#753BFD]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'bg-zinc-300';
    }

    if (props.highImpact) {
      return 'bg-amber-400';
    }

    return 'bg-[#330099]';
  };

  const getHoverColor = () => {
    if (props.error) {
      return 'border-red-50 text-red-50';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'border-slate-100 text-slate-100';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'border-[#E8EAF5] text-[#E8EAF5]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'border-zinc-100 text-zinc-100';
    }

    if (props.highImpact) {
      return 'border-amber-100 text-amber-100';
    }

    return 'border-[#E8EAF5] text-[#E8EAF5]';
  };

  const getHoverColorAsBg = () => {
    if (props.error) {
      return 'bg-red-50';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'bg-slate-100';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'bg-[#E8EAF5]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'bg-zinc-100';
    }

    if (props.highImpact) {
      return 'bg-amber-100';
    }

    return 'bg-[#E8EAF5]';
  };

  return (
    <>
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          icon={solid('circle')}
          className={cn('absolute h-[9px] aspect-square z-[1]', getHoverColor())}
          style={{
            left: `${fromLeft - 4}px`,
            top: `${fromCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        icon={solid('circle')}
        className={cn('absolute h-[5px] aspect-square z-[1]', getColor())}
        style={{
          left: `${fromLeft - 2}px`,
          top: `${fromCenter - 2}px`,
        }}
      />
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn('h-[9px] aspect-square absolute z-[1] -rotate-90', getHoverColor())}
          icon={solid('triangle')}
          style={{
            left: `${toRight - 3}px`,
            top: `${toCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn('h-[5px] aspect-square absolute z-[1] -rotate-90', getColor())}
        icon={solid('triangle')}
        style={{
          left: `${toRight - 1}px`,
          top: `${toCenter - 2}px`,
        }}
      />
      {props.hovering && props.enabled && (
        <div
          className={cn('corner-from absolute rounded-full border-[5px] rotate-45 aspect-square', getHoverColor(), {
            'border-r-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-t-transparent border-r-transparent border-l-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          })}
          style={{
            width: `${cornerSize * 2 + 4}px`,
            left: `${fromLeft - fromStraightWidth - cornerSize - 2}px`,
            top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1) - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('corner-from absolute rounded-full rotate-45 border aspect-square', getColor(), {
          'border-r-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
          'border-t-transparent border-r-transparent border-l-transparent': fromCenter > toCenter,
          invisible: cornerSize === 0,
        })}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromLeft - fromStraightWidth - cornerSize}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('corner-to absolute rounded-full border-[5px] rotate-45 aspect-square', getHoverColor(), {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          })}
          style={
            fromCenter > toCenter
              ? {
                  width: `${cornerSize * 2 + 6}px`,
                  left: `${toRight + toStraightWidth - cornerSize - 3}px`,
                  top: `${toCenter - 2}px`,
                }
              : {
                  width: `${cornerSize * 2 + 6}px`,
                  left: `${toRight + toStraightWidth - cornerSize - 3}px`,
                  top: `${toCenter - cornerSize * 2 - 3}px`,
                }
          }
        ></div>
      )}
      <div
        className={cn('corner-to absolute rounded-full rotate-45 border aspect-square', getColor(), {
          'border-l-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
          'border-l-transparent border-r-transparent border-b-transparent': fromCenter > toCenter,
          invisible: cornerSize === 0,
        })}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${toRight + toStraightWidth - cornerSize + 1}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('h-line-from absolute h-[5px]', getHoverColorAsBg())}
          style={{
            width: `${fromStraightWidth + 1}px`,
            left: `${fromLeft - fromStraightWidth}px`,
            top: `${fromCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('h-line-from absolute h-px z-[1]', getColorAsBg())}
        style={{
          width: `${fromStraightWidth}px`,
          left: `${fromLeft - fromStraightWidth}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('v-line absolute w-[5px]', getHoverColorAsBg(), { invisible: cornerSize === 0 })}
          style={{
            height: `${straightHeight + 1}px`,
            left: `${toRight + toWidth - 2}px`,
            top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
          }}
        ></div>
      )}
      <div
        className={cn('v-line absolute w-px', getColorAsBg(), { invisible: cornerSize === 0 })}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${toRight + toWidth}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>
      {props.hovering && props.enabled && (
        <div
          className={cn('h-line-to absolute h-[5px]', getHoverColorAsBg())}
          style={{
            width: `${toStraightWidth - 1}px`,
            left: `${toRight + 1}px`,
            top: `${toCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('h-line-to absolute h-px', getColorAsBg())}
        style={{
          width: `${toStraightWidth + 1}px`,
          left: `${toRight}px`,
          top: `${toCenter}px`,
        }}
      ></div>
      {!props.hovering && props.change && !props.highImpact && (
        <div
          className={cn(
            'delta pointer-events-none absolute text-[8px] p-px font-semibold -translate-y-1/2 -translate-x-full leading-none rounded-md z-[1]',
            'bg-white',
            {
              [ImpactDeltaType.Higher]: 'text-red-500',
              [ImpactDeltaType.Lower]: 'text-emerald-700',
              [ImpactDeltaType.Zero]: 'text-zinc-500',
            }[props.change.impactDelta.type],
          )}
          style={{
            left: `${fromLeft - fromStraightWidth / 2}px`,
            top: `${fromCenter}px`,
          }}
        >
          {props.change.impactDelta.display}
        </div>
      )}
      {!props.hovering && !props.change && props.highImpact && (
        <FontAwesomeIcon
          icon={solid('seal-exclamation')}
          className='pointer-events-none absolute h-3 aspect-square rounded-full bg-white text-amber-400 -translate-y-1/2 z-[1]'
          style={{
            left: `${fromLeft - fromStraightWidth / 2}px`,
            top: `${fromCenter}px`,
          }}
        />
      )}
      {props.openMenu(
        <div
          className={cn('h-line-from absolute h-4 -translate-y-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${fromWidth}px`,
            left: `${fromLeft - fromWidth}px`,
            top: `${fromCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('v-line absolute w-4 -translate-x-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            height: `${height}px`,
            left: `${toRight + toWidth}px`,
            top: `${Math.min(fromCenter, toCenter)}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('h-line-to absolute h-4 -translate-y-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${toWidth}px`,
            left: `${toRight}px`,
            top: `${toCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
    </>
  );
};

const Vertical = (props: {
  change?: ModellingChange;
  from: DOMRect;
  to: DOMRect;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  hovering: boolean;
  enabled: boolean;
  editable: boolean;
  deletable: boolean;
  error: boolean;
  highImpact: boolean;
  onDelete?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openMenu: (children: ReactNode, onDelete?: () => void) => ReactNode;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromBottom = props.from.bottom + topOffset;
  const toBottom = props.to.bottom + topOffset;
  const fromTop = props.from.top + topOffset;
  const toTop = props.to.top + topOffset;

  const topToBottom = toTop > fromTop;
  const center = props.from.left + props.from.width / 2 + leftOffset;
  const height = (topToBottom ? toTop - fromBottom : fromTop - toBottom) - 1;
  const top = topToBottom ? fromBottom : toBottom;

  const getColor = () => {
    if (!props.editable) {
      return 'border-zinc-400 text-zinc-400';
    }

    if (props.error) {
      return 'border-red-500 text-red-500';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'border-blue-500 text-blue-500';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'border-[#753BFD] text-[#753BFD]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'border-zinc-300 text-zinc-300';
    }

    if (props.highImpact) {
      return 'border-amber-400 text-amber-400';
    }

    return 'border-[#330099] text-[#330099]';
  };

  const getColorAsBg = () => {
    if (!props.editable) {
      return 'bg-zinc-400';
    }

    if (props.error) {
      return 'bg-red-500';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'bg-blue-500';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'bg-[#753BFD]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'bg-zinc-300';
    }

    if (props.highImpact) {
      return 'bg-amber-400';
    }

    return 'bg-[#330099]';
  };

  const getHoverColor = () => {
    if (props.error) {
      return 'border-red-50 text-red-50';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'border-slate-100 text-slate-100';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'border-[#E8EAF5] text-[#E8EAF5]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'border-zinc-100 text-zinc-100';
    }

    if (props.highImpact) {
      return 'border-amber-100 text-amber-100';
    }

    return 'border-[#E8EAF5] text-[#E8EAF5]';
  };

  const getHoverColorAsBg = () => {
    if (props.error) {
      return 'bg-red-50';
    }

    if (props.change?.action === ModellingChangeAction.Added) {
      return 'bg-slate-100';
    }

    if (props.change?.action === ModellingChangeAction.Updated) {
      return 'bg-[#E8EAF5]';
    }

    if (props.change?.action === ModellingChangeAction.Deleted) {
      return 'bg-zinc-100';
    }

    if (props.highImpact) {
      return 'bg-amber-100';
    }

    return 'bg-[#E8EAF5]';
  };

  return (
    <>
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          icon={solid('circle')}
          className={cn('absolute h-[9px] aspect-square', getHoverColor())}
          style={{
            left: `${center - 3}px`,
            top: `${(topToBottom ? fromBottom : fromTop) - 5}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        icon={solid('circle')}
        className={cn('absolute h-[5px] aspect-square', getColor())}
        style={{
          left: `${center - 1}px`,
          top: `${(topToBottom ? fromBottom : fromTop) - 3}px`,
        }}
      />
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn('h-[9px] aspect-square absolute rotate-180', getHoverColor(), {
            invisible: !topToBottom,
          })}
          icon={solid('triangle')}
          style={{
            left: `${center - 3}px`,
            top: `${toTop - 6}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn('h-[5px] aspect-square absolute z-[1] rotate-180', getColor(), {
          invisible: !topToBottom,
        })}
        icon={solid('triangle')}
        style={{
          left: `${center - 1}px`,
          top: `${toTop - 5}px`,
        }}
      />
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn('h-[9px] aspect-square absolute', getHoverColor(), {
            invisible: topToBottom,
          })}
          icon={solid('triangle')}
          style={{
            left: `${center - 3}px`,
            top: `${toBottom - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn('h-[5px] aspect-square absolute z-[1]', getColor(), {
          invisible: topToBottom,
        })}
        icon={solid('triangle')}
        style={{
          left: `${center - 1}px`,
          top: `${toBottom - 2}px`,
        }}
      />
      {props.hovering && props.enabled && (
        <div
          className={cn('v-line absolute w-[5px]', getHoverColorAsBg())}
          style={{
            height: `${height - 6}px`,
            left: `${center - 1}px`,
            top: `${top + 3}px`,
          }}
        ></div>
      )}
      <div
        className={cn('v-line absolute w-px', getColorAsBg())}
        style={{
          height: `${height - 2}px`,
          left: `${center + 1}px`,
          top: `${top}px`,
        }}
      ></div>
      {!props.hovering && props.change && !props.highImpact && (
        <div
          className={cn(
            'delta pointer-events-none absolute text-[8px] p-px font-semibold -translate-y-1/2 -translate-x-1/2 leading-none rounded-md z-[1]',
            'bg-white',
            {
              [ImpactDeltaType.Higher]: 'text-red-500',
              [ImpactDeltaType.Lower]: 'text-emerald-700',
              [ImpactDeltaType.Zero]: 'text-zinc-500',
            }[props.change.impactDelta.type],
          )}
          style={{
            left: `${center + 1}px`,
            top: `${top + height / 2}px`,
          }}
        >
          {props.change.impactDelta.display}
        </div>
      )}
      {!props.hovering && !props.change && props.highImpact && (
        <FontAwesomeIcon
          icon={solid('seal-exclamation')}
          className='pointer-events-none absolute h-3 aspect-square rounded-full bg-white text-amber-400 -translate-y-1/2 -translate-x-1/2 z-[1]'
          style={{
            left: `${center + 1}px`,
            top: `${top + height / 2}px`,
          }}
        />
      )}
      {props.openMenu(
        <div
          className={cn('v-line absolute w-4 -translate-x-1/2', { 'z-[2]': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            height: `${height - 2}px`,
            left: `${center + 1}px`,
            top: `${top + 1}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
    </>
  );
};
