import { Summary as ProductionSummary } from '../components/Summary';
import { Lens, ProductReport } from '../../../../../../api';

interface Props {
  data: ProductReport;
}

export const Summary = (props: Props) => {
  return <ProductionSummary type={Lens.Production} data={props.data} />;
};
