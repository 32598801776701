import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import ReactSelect, { components, DropdownIndicatorProps, OptionProps, StylesConfig } from 'react-select';

interface Props {
  options: {
    id: string;
    name: string;
    disabled?: boolean;
    renderIcon?: boolean;
  }[];
  selectedId?: string;
  setSelectedId: (v: string | null) => void;
  width?: number;
  value?: {
    value: string;
    label: string;
  };
  disabled?: boolean;
}

const convertToSelectOptions = (options: { id: string; name: string; disabled?: boolean; renderIcon?: boolean }[]) => {
  return options.map((option) => ({
    ...option,
    value: option.id,
    label: option.name,
  }));
};

const styles: StylesConfig<{ value: string; label: string }> = {
  control: (provided, state) => ({
    ...provided,
    paddingLeft: '12px',
    borderRadius: '0.5rem',
    borderWidth: '1px',
    borderColor: '#71717a',
    fontWeight: '600',
    fontSize: '14px',
    ...(state.isDisabled
      ? {
          backgroundColor: '#fff',
          borderColor: '#a7a7b0',
        }
      : {}),
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? '#ffffff' : '#313030',
      backgroundColor: state.isSelected ? '#4f00ff' : '#fff',
      '&:hover': {
        backgroundColor: state.isSelected && state.isFocused ? '#4f00ff' : '#efefef80',
        cursor: 'pointer',
      },
    };
  },
  container: (provided, state) => ({
    ...provided,
    ...(state.isFocused
      ? {
          boxShadow: state.isFocused && '0px 0px 2px 5px #4F00FF33',
          borderRadius: '0.5rem',
        }
      : {}),
  }),
};

export const SingleSelect = (props: Props) => (
  <ReactSelect<{ value: string; label: string }>
    className='z-[10]'
    {...props}
    isDisabled={props.disabled}
    value={props.value ?? convertToSelectOptions(props.options).find(({ value }) => value === props.selectedId)}
    styles={styles}
    isMulti={false}
    options={convertToSelectOptions(props.options)}
    isSearchable={false}
    onChange={(payload) => {
      props.setSelectedId(payload ? payload.value : null);
    }}
    components={{
      DropdownIndicator: (props: DropdownIndicatorProps<any>) => CustomDropdownIndicator(props),
      Option: (props: OptionProps<any>) => CustomOption(props),
    }}
  />
);
const { Option } = components;

const CustomDropdownIndicator = (props: DropdownIndicatorProps<any>) => (
  <div className={cn('px-3')} {...props.innerProps}>
    <FontAwesomeIcon className='text-dark' icon={regular('chevron-down')} />
  </div>
);

const CustomOption = (props: OptionProps<any>) => (
  <Option {...props}>
    <div className='flex gap-2 items-center'>
      <FontAwesomeIcon
        className={cn('', {
          invisible: !props.data.renderIcon,
        })}
        icon={duotone('bullseye-arrow')}
      />
      {props.data.label}
    </div>
  </Option>
);
