import { ImpactTracking, ReportType, TargetHintType, Targets } from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { simplify } from '../Root/Main/shared';
import { ImpactValueType } from '../Root/Main/Products/Report/Sku/Overview';
import { getTrackingData } from './charts/ProgressTracking/converter';
import { format, isSameYear } from 'date-fns';
import cn from 'classnames';
import { AppRoutes } from '../Root/Main';
import { NavLink } from 'react-router-dom';

interface Props {
  reportType: ReportType.Forecast | ReportType.Historical;
  targetData?: {
    estimate: string;
    targetBadge?: { name: string };
  };
  targetHint?: {
    type: TargetHintType;
    impactValueDiff?: {
      points: number;
      physical?: number;
    };
  };
  selectedUnit: string;
  selectedImpact: ImpactTracking;
  targets: Targets;
}

export const ImpactTargetHints = (props: Props) => {
  const selectedImpact = props.selectedImpact;
  const trackingData = getTrackingData(selectedImpact, props.targets);

  const isAnyActualHistoricalData = trackingData.data.some(({ dataset }) => dataset.some(({ type }) => type === 'report'));

  const latestFullYearWithActualData = trackingData.data
    .filter(({ year }) => !isSameYear(year, new Date()))
    .reverse()
    .find((year) => year.dataset.some(({ type }) => type === 'report'));

  const historicalData = latestFullYearWithActualData?.dataset.reduce(
    (acc, cur) => ({
      date: latestFullYearWithActualData?.year,
      points: {
        value: acc.points.value + (cur.points ?? 0),
        unit: 'impact points',
      },
      physical: {
        value: acc.physical.value + (cur.physical ?? 0),
        unit: selectedImpact.unit,
      },
    }),
    {
      date: new Date(),
      points: {
        value: 0,
        unit: 'impact points',
      },
      physical: {
        value: 0,
        unit: selectedImpact.unit,
      },
    },
  );

  const data = {
    baseline: selectedImpact.baseline && {
      points: {
        value: selectedImpact.baseline.totalImpact.points,
        unit: 'impact points',
      },
      physical: {
        value: selectedImpact.baseline.totalImpact.physical,
        unit: selectedImpact.unit,
      },
    },
    historical:
      props.targetHint?.type !== TargetHintType.UnknownNoHistorical && historicalData
        ? {
            date: historicalData?.date,
            points: {
              value: historicalData?.points.value,
              unit: 'impact points',
            },
            physical: {
              value: historicalData?.physical.value,
              unit: selectedImpact.unit,
            },
          }
        : undefined,
    forecast: selectedImpact.forecast && {
      points: {
        value: selectedImpact.forecast.totalImpact.points,
        unit: 'impact points',
      },
      physical: {
        value: selectedImpact.forecast.totalImpact.physical,
        unit: selectedImpact.unit,
      },
    },
  };

  const estimatedTrend = data.baseline
    ? props.reportType === ReportType.Forecast
      ? {
          points: ((data.forecast.points.value - data.baseline.points.value) / data.baseline.points.value) * 100,
          physical:
            data.baseline.physical.value &&
            data.forecast.physical.value &&
            ((data.forecast.physical.value - data.baseline.physical.value) / data.baseline.physical.value) * 100,
        }
      : data.historical
      ? {
          points: ((data.historical.points.value - data.baseline.points.value) / data.baseline.points.value) * 100,
          physical:
            data.baseline.physical.value &&
            ((data.historical.physical.value - data.baseline.physical.value) / data.baseline.physical.value) * 100,
        }
      : undefined
    : undefined;

  const prettifyPercentageValue = (value?: number) => {
    if (!value) return '';

    if (parseFloat(value.toFixed(1)) === 0) return '0%';

    return `${parseFloat(value.toFixed(1)) > 0 ? '+' : '-'}${Math.abs(parseFloat(value.toFixed(1)))}%`;
  };

  return (
    <div className='grid grid-cols-2 gap-x-4'>
      {!isAnyActualHistoricalData && props.reportType === ReportType.Historical && (
        <div className='flex gap-x-4 py-4 px-6 bg-amber-50 border border-amber-400 rounded-lg'>
          <div className='flex flex-none items-center justify-center size-10 bg-brandLime rounded-full'>
            <FontAwesomeIcon className='text-xl text-zinc-700' icon={light('lightbulb')} />
          </div>
          <div className='flex flex-col gap-y-4 text-zinc-900'>
            <div>
              <span className='font-semibold'>More data needed.</span> Progress can only be assessed with at least two data points. Upload
              your first set of historical data!
            </div>
            <div>
              <NavLink
                type='button'
                to={AppRoutes().report.newHistorical}
                className='font-semibold bg-brand text-slate-50 px-4 py-1.5 rounded-full'
              >
                Upload historical data
              </NavLink>
            </div>
          </div>
        </div>
      )}
      {(() => {
        const renderValue = ({ value, unit, message }: { value?: number; unit?: string; message?: string }) =>
          value ? (
            <>
              <div className='font-semibold text-zinc-900'>{simplify(value)}</div>
              <div className='tracking-wide uppercase text-xs text-zinc-800'>{unit}</div>
            </>
          ) : (
            <div className='uppercase tracking-wide text-xs text-zinc-800'>{message}</div>
          );

        return (
          <div className='grid grid-cols-[1fr_auto_1fr] gap-x-2'>
            <div className='flex flex-col gap-y-2 p-3 justify-between bg-neutral-50 rounded-lg border'>
              <div className='font-semibold line-clamp-2 text-base'>
                {format(new Date(selectedImpact.baseline!.start.date), 'yyyy')} impact (Baseline)
              </div>
              <div className='text-brand text-sm'>{selectedImpact.name}</div>
              <div className='flex items-center gap-x-1'>
                {renderValue({
                  value: props.selectedUnit === ImpactValueType.Points ? data.baseline?.points.value : data.baseline?.physical.value,
                  unit: props.selectedUnit === ImpactValueType.Physical ? selectedImpact.unit : 'impact points',
                  message: 'no baseline recorded',
                })}
              </div>
            </div>

            <FontAwesomeIcon className='flex self-center h-8 aspect-square text-brand' icon={light('right')} />

            <div className='flex flex-col gap-y-2 p-3 justify-between bg-indigo-50/20 rounded-lg border border-indigo-200'>
              <div className='font-semibold line-clamp-2 text-base'>
                {props.reportType === ReportType.Forecast
                  ? `Forecast est. impact`
                  : data.historical
                  ? `${format(data.historical.date, 'yyyy')} Impact`
                  : 'Latest impact'}
              </div>
              <div className='text-brand text-sm'>{selectedImpact.name}</div>
              <div className='flex items-center gap-x-1'>
                {props.reportType === ReportType.Forecast
                  ? renderValue({
                      value: props.selectedUnit === ImpactValueType.Points ? data.forecast?.points.value : data.forecast?.physical.value,
                      unit: props.selectedUnit === ImpactValueType.Physical ? selectedImpact.unit : 'impact points',
                    })
                  : renderValue({
                      value:
                        props.selectedUnit === ImpactValueType.Points ? data.historical?.points.value : data.historical?.physical.value,
                      unit: props.selectedUnit === ImpactValueType.Physical ? selectedImpact.unit : 'impact points',
                      message: 'no historical data',
                    })}
              </div>
            </div>
          </div>
        );
      })()}

      {(() => {
        const renderTile = (targetHintType: TargetHintType) => {
          return (
            (props.reportType === ReportType.Forecast || (props.reportType === ReportType.Historical && latestFullYearWithActualData)) && (
              <div
                className={cn('flex w-full gap-x-4 p-3 rounded-lg border', {
                  'border-green-500 bg-green-50': [
                    TargetHintType.LongTermOnTrack,
                    TargetHintType.AnnualSufficient,
                    TargetHintType.LongTermSufficient,
                    TargetHintType.LongTermMeet,
                    TargetHintType.ForecastAnnualReachable,
                    TargetHintType.ForecastLongTermReachable,
                  ].includes(targetHintType),
                  'border-amber-400 bg-amber-50': [
                    TargetHintType.AnnualInsufficient,
                    TargetHintType.LongTermInsufficient,
                    TargetHintType.ForecastAnnualReachedInHistory,
                  ].includes(targetHintType),
                  'bg-neutral-100 border-zinc-500': [TargetHintType.UnknownNoBaseline, TargetHintType.UnknownNoTarget].includes(
                    targetHintType,
                  ),
                })}
              >
                <div className='flex flex-col justify-between items-start'>
                  <div className='text-base text-zinc-700'>Result</div>
                  {estimatedTrend && (
                    <div className='whitespace-nowrap font-semibold text-zinc-900'>
                      {props.selectedUnit === ImpactValueType.Points
                        ? prettifyPercentageValue(estimatedTrend.points)
                        : prettifyPercentageValue(estimatedTrend.physical)}
                    </div>
                  )}
                  <div
                    className={cn(
                      'px-1.5 py-0.5 rounded-lg text-xs font-semibold text-white whitespace-nowrap',
                      {
                        'bg-[#FBBF24]': [
                          TargetHintType.AnnualInsufficient,
                          TargetHintType.LongTermInsufficient,
                          TargetHintType.ForecastAnnualReachedInHistory,
                        ].includes(targetHintType),
                      },
                      {
                        'bg-[#047857]': [
                          TargetHintType.AnnualSufficient,
                          TargetHintType.LongTermSufficient,
                          TargetHintType.LongTermMeet,
                          TargetHintType.ForecastAnnualReachable,
                          TargetHintType.ForecastLongTermReachable,
                        ].includes(targetHintType),
                      },
                      { 'bg-zinc-500': [TargetHintType.UnknownNoBaseline, TargetHintType.UnknownNoTarget].includes(targetHintType) },
                    )}
                  >
                    {(() => {
                      if (
                        [
                          TargetHintType.LongTermOnTrack,
                          TargetHintType.AnnualSufficient,
                          TargetHintType.LongTermSufficient,
                          TargetHintType.ForecastAnnualReachable,
                          TargetHintType.ForecastLongTermReachable,
                        ].includes(targetHintType)
                      ) {
                        return 'Sufficient';
                      } else if (
                        [
                          TargetHintType.AnnualInsufficient,
                          TargetHintType.LongTermInsufficient,
                          TargetHintType.ForecastAnnualReachedInHistory,
                        ].includes(targetHintType)
                      ) {
                        return 'Insufficient';
                      } else if (TargetHintType.LongTermMeet === targetHintType) {
                        return 'Target met';
                      } else if (
                        [TargetHintType.UnknownNoBaseline, TargetHintType.UnknownNoTarget, TargetHintType.UnknownNoHistorical].includes(
                          targetHintType,
                        )
                      ) {
                        return 'Unknown';
                      }
                    })()}
                  </div>
                </div>
                <div className='text-sm text-neutral-700 flex items-center'>
                  <div>
                    {(() => {
                      switch (targetHintType) {
                        case TargetHintType.LongTermOnTrack:
                          return 'Based on current volumes and trajectory, you are on track to meet this impact reduction target!';

                        //done
                        case TargetHintType.AnnualInsufficient:
                          return (
                            <>
                              If you continue at the current pace, you likely will not meet your annual reduction target (
                              <span className='font-semibold'>{props.targetData?.estimate}</span>) every single year. Time to be more
                              bullish!
                            </>
                          );

                        //done
                        case TargetHintType.AnnualSufficient:
                          return (
                            <>
                              If you continue at the current pace, you are on track to continuously meet your annual reduction target (
                              <span className='font-semibold'>{props.targetData?.estimate}</span>)!
                            </>
                          );

                        // done
                        case TargetHintType.LongTermSufficient:
                          return (
                            <>
                              If you continue at the current pace, you are on track to meet your target (
                              <span className='font-semibold'>{props.targetData!.estimate}</span>)!
                            </>
                          );

                        // done
                        case TargetHintType.LongTermInsufficient:
                          return (
                            <>
                              If you continue at the current pace, you likely will not meet your target (
                              <span className='font-semibold'>{props.targetData!.estimate}</span>). Time to be more bullish!
                            </>
                          );

                        // done
                        case TargetHintType.LongTermMeet:
                          return (
                            <>
                              Congrats, you have officially reached your target (
                              <span className='font-semibold'>{props.targetData!.estimate}</span>)!
                            </>
                          );

                        // done
                        case TargetHintType.UnknownNoTarget:
                          return 'Progress towards targets can only be assessed where a target reduction was set';

                        //done
                        case TargetHintType.UnknownNoBaseline:
                          return 'Progress can only be assessed with baseline and target set';

                        // ???
                        case TargetHintType.UnknownNoHistorical:
                          return 'Progress towards targets can only be assessed with at least two data points. Upload your first set of historical data to see how you’re doing against your target!';

                        // ??? deal with month may be
                        case TargetHintType.ForecastAnnualReachable:
                          return (
                            <>
                              You would need to make this forecast a reality by{' '}
                              <span className='font-semibold'>{props.targetData!.targetBadge?.name}</span> to meet your annual target (
                              <span className='font-semibold'>{props.targetData!.estimate}</span>)
                            </>
                          );

                        // done
                        case TargetHintType.ForecastAnnualReachedInHistory:
                          return (
                            <>
                              You would have needed to make this forecast a reality by{' '}
                              <span className='font-semibold'>{props.targetData!.targetBadge?.name}</span> to meet your annual target (
                              <span className='font-semibold'>{props.targetData!.estimate}</span>). You need to do more to reach your target
                              in the future!
                            </>
                          );

                        // ??? deal with month may be
                        case TargetHintType.ForecastLongTermReachable:
                          return (
                            <>
                              The manufacturing output modelled in this forecast would allow you to reach your target (
                              <span className='font-semibold'>{props.targetData!.estimate}</span>)
                            </>
                          );
                      }
                    })()}
                  </div>
                </div>
              </div>
            )
          );
        };

        return props.targetHint && renderTile(props.targetHint!.type);
      })()}
    </div>
  );
};
