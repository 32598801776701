import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { ReactNode, useEffect, useState } from 'react';
import { BaselinePefReport, Lens, ReportType, resetBaseline } from '../../../../../../api';
import { ContextualExampleTile } from '../../../../../../components/ContextualExampleTile';
import { ModalV3 } from '../../../../../../components/ModalV3';
import { ImpactAnalysisResultSummary } from '../../../components/ImpactAnalysisResultSummary';
import { PefDashboardSummary } from '../../../components/PefDashboardSummary';
import { WorstOffenders } from '../../../components/WorstOffenders';
import { Overview } from '../Overview';
import { convertUTCDateToLocal } from '../../../../../../components/datepicker/utils';

interface Props {
  data: BaselinePefReport & { errorCode?: string; message?: string };
  hasError: boolean;
  reload: () => void;
  warning?: ReactNode;
}

export const ProductionOverview = (props: Props) => {
  const [expanded, setExpanded] = useState(new Array<string>());
  const [focused, setFocused] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (!expanded.includes('Impact Analysis Results')) {
      setFocused(false);
    }

    return () => setFocused(false);
  }, [expanded]);

  const handleExpandedState = (item1: string) => {
    setExpanded((current) => (current.includes(item1) ? current.filter((item2) => item2 !== item1) : [...current, item1]));
  };

  const collapsible = (title: string, description: string, icon: ReactNode, children: ReactNode) => (
    <div className='print:mt-6'>
      <button
        onClick={() => handleExpandedState(title)}
        className='py-6 w-full flex justify-between items-center px-10 hover:cursor-pointer hover:bg-lightBg/75'
      >
        <div className='flex gap-4 items-center'>
          <div>{icon}</div>
          <div className='flex flex-col gap-2'>
            <div className='text-lg font-semibold'>{title}</div>
            <div>{description}</div>
          </div>
        </div>
        <FontAwesomeIcon
          className='text-xl print:hidden'
          icon={expanded.includes(title) ? solid('chevron-down') : solid('chevron-right')}
        />
      </button>
      <div className='print:hidden'>{expanded.includes(title) && children}</div>
      <div className='hidden print:block'>{children}</div>
    </div>
  );

  return (
    <Overview data={props.data} hasError={props.hasError} lens={Lens.Production}>
      {!props.hasError && (
        <>
          <PefDashboardSummary
            data={props.data}
            reportTimeframe={`${format(convertUTCDateToLocal(props.data.startDate), 'MMM dd, yyyy')} - ${format(
              convertUTCDateToLocal(props.data.endDate),
              'MMM dd, yyyy',
            )}`}
            expanded={expanded}
            handleExpandedState={handleExpandedState}
            setFocused={setFocused}
            warning={props.warning}
          >
            <ModalV3
              title='Are you sure you want to reset your baseline?'
              body={
                <div className='flex justify-items-center flex-col -mt-6'>
                  <div className='flex self-center text-center w-3/4 text-base'>
                    <span>
                      This will reset it for your <span className='text-brand font-semibold'>entire workspace</span> and all progress
                      tracking against this baseline will be lost
                    </span>
                  </div>
                </div>
              }
              confirmLabel='Reset'
              onConfirm={() => {
                setDeleting(true);
                resetBaseline().call({
                  ok: () => {
                    setDeleting(false);
                    props.reload();
                  },
                  fail: () => {
                    setDeleting(false);
                  },
                });
              }}
            >
              <button disabled={deleting} className='text-brand text-sm hover:underline'>
                Reset baseline
              </button>
            </ModalV3>
          </PefDashboardSummary>

          {collapsible(
            'Worst offenders',
            'Which products have the highest overall environmental impact across your entire volume output',
            <FontAwesomeIcon className='h-8 aspect-square' icon={duotone('light-emergency-on')} />,
            <WorstOffenders data={props.data} />,
          )}

          {collapsible(
            'Impact Analysis Results',
            'Your product’s impact by category, life cycle stage and process as well as the major contributors to each',
            <FontAwesomeIcon className='h-8 aspect-square' icon={duotone('spinner-third')} />,
            <ImpactAnalysisResultSummary data={props.data} type={ReportType.Baseline} focused={focused} setFocused={setFocused} />,
          )}
          {collapsible(
            'Physical Impact - Equivalence in real life',
            'What’s your impact, translated into relatable measures',
            <FontAwesomeIcon className='size-8' icon={duotone('leaf')} />,
            <div className='bg-lightBg print:bg-transparent flex flex-col items-center gap-6 p-6'>
              <div className='font-semibold text-xl'>Physical Impacts Equivalent to:</div>
              <div className='grid grid-cols-3 gap-6 print:block'>
                {props.data?.interpretation.contextualExamples.map((contextualExample, i) => (
                  <ContextualExampleTile key={i} contextualExample={contextualExample} />
                ))}
              </div>
            </div>,
          )}
        </>
      )}
    </Overview>
  );
};
