import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Fragment } from 'react';
import { ConsumerViewImpact, Grade } from '../../../../../../api';
import { GradeBadge } from '../../../../../../components/GradeBadge';
import { getImpactIcon } from '../../../../../../icons/Impact';
import bottle from '../../bottle.png';

interface Props {
  overallGrade: Grade;
  name: string;
  amountValue: number;
  amountUnit: string;
  impacts: ConsumerViewImpact[];
  image?: string;
  scale: string;
}

export const ProductDetailsConsumerView = (props: Props) => (
  <div className={cn('flex flex-col gap-4 p-6 border border-gray-300 rounded-xl w-[285px] bg-white m-auto shadow-regular', props.scale)}>
    <div className='flex justify-between p-4 rounded-xl border h-36'>
      <div className='flex items-center'>
        <img src={props.image && props.image !== '' ? props.image : bottle} alt={props.name} className='h-[75px] w-[auto]' />
      </div>
      <div className='flex flex-col items-end gap-4'>
        <div className='left-2 relative'>
          <GradeBadge grade={props.overallGrade} />
        </div>
        <div className='flex flex-col items-end gap-2 text-right'>
          <div className='text-sm font-semibold overflow-hidden overflow-ellipsis max-w-[150px]'>{props.name}</div>
          <div className='text-xs text-neutral-400'>
            {props.amountValue} {props.amountUnit}
          </div>
        </div>
      </div>
    </div>
    <div className='font-semibold text-lg'>Here's the breakdown</div>
    <div className='flex flex-col text-[12px]'>
      <div className='flex justify-between'>
        <div className='text-neutral-400 font-semibold'>Best</div>
        <div className='text-brand'>Show less</div>
      </div>
      <div className='flex flex-col my-2'>
        {props.impacts.map((impact, i) => (
          <Fragment key={impact.name}>
            {i === 0 && <div className='-mx-6 bg-gray-200 h-px' />}
            <div className='flex items-center justify-between h-12'>
              <div className='flex items-center gap-4'>
                <div className='flex justify-center items-center h-5 w-5'>{getImpactIcon(impact.iconId)}</div>
                <div className='font-semibold text-neutral-700'>{impact.name}</div>
              </div>
              <div className='flex items-center gap-6'>
                <div
                  className={cn('rounded-full w-4 h-4', {
                    'bg-aa': impact.grade === Grade.AA,
                    'bg-a': impact.grade === Grade.A,
                    'bg-b': impact.grade === Grade.B,
                    'bg-c': impact.grade === Grade.C,
                    'bg-d': impact.grade === Grade.D,
                    'bg-e': impact.grade === Grade.E,
                    'bg-f': impact.grade === Grade.F,
                    'bg-g': impact.grade === Grade.G,
                  })}
                />
                <FontAwesomeIcon icon={regular('chevron-down')} className='text-neutral-500' />
              </div>
            </div>
            <div className='-mx-6 bg-gray-200 h-px' />
          </Fragment>
        ))}
      </div>
      <div className='flex'>
        <div className='text-neutral-400 font-semibold'>Worst</div>
      </div>
    </div>
  </div>
);
