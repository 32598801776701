import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ManufacturingImprovementTips } from '../api';
import { NavLink } from 'react-router-dom';

interface Props {
  data: ManufacturingImprovementTips[];
  setFocused: (value: boolean) => void;
  expanded: string[];
  handleExpandedState: (value: string) => void;
}

export const ManufacturingImprovementTipsV3 = (props: Props) => (
  <div className='border rounded-2xl p-6 bg-amber-50'>
    <div className='font-semibold mb-6'>Top Tips to improve your performance</div>
    <div>
      <div className='flex flex-col gap-y-3'>
        {props.data.map((tip, i1) => (
          <div key={i1} className='grid grid-cols-[30px_auto] gap-2 items-center'>
            <FontAwesomeIcon
              className='text-xl'
              icon={
                {
                  'tip-balancing': duotone('scale-unbalanced-flip'),
                  'tip-products': duotone('box-circle-check'),
                  'tip-lifecycles': duotone('arrows-spin'),
                  'tip-processes': duotone('gears'),
                }[tip.iconId]!
              }
            />
            <div className='flex items-center'>
              <span>
                <span>{tip.text} </span>
                {tip.id === 'tip-processes' && (
                  <span>
                    (see&nbsp;
                    <button
                      className='underline font-semibold hover:text-brand'
                      onClick={() => {
                        !props.expanded.includes('Impact Analysis Results') && props.handleExpandedState('Impact Analysis Results');
                        props.setFocused(true);
                      }}
                    >
                      By Life Cycle Stage & Process
                    </button>{' '}
                    graph/table below)
                  </span>
                )}
                {tip.products.map((product, i2) => (
                  <NavLink
                    to={
                      product.parentId
                        ? `/products/${product.parentId}/models/${product.id}/report/production/overview`
                        : `/products/${product.id}/report/production/overview`
                    }
                    key={i2}
                    className='font-semibold hover:text-brandDark'
                    target='_blank'
                  >
                    <span className='underline'>
                      {product.name} ({product.amount.value}
                    </span>
                    {product.amount.unit.name}){i2 < tip.products.length - 1 ? ' / ' : '.'}
                  </NavLink>
                ))}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
