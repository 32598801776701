import { ProgressChart } from './ProgressChart';
import { TargetType } from '../../../api';
import { ImpactValueType } from '../../../Root/Main/Products/Report/Sku/Overview';

interface Props {
  small?: boolean;
}

export const ProgressChartPlaceholder = (props: Props) => {
  const selectedImpactUnit = ImpactValueType.Points;

  const targets = {
    targetImpacts: [
      {
        id: 'overall',
        name: 'Total Environmental Impact',
        type: TargetType.YEAR_OVER_YEAR,
        method: '',
        reduction: 5,
        estimate: '-100% by 2044',
        description:
          'Overall impact represents the sum of all 16 normalised and weighted impact categories, as defined by the PEF framework.',
        isMajorCategory: true,
      },
    ],
  };

  const impact = {
    id: 'overall',
    name: 'Total environmental impact',
    unit: 'Impact points',
    isToxicityRelated: false,
    latestDailyImpact: {
      name: 'Latest historical daily impact',
      dailyImpact: {
        points: 313530.04530223773,
      },
    },
    target: {
      estimate: '-5% per year',
      type: 'year_over_year',
      start: {
        date: '2018-12-31',
        dailyImpact: {
          points: 571412.7091273658,
        },
      },
      end: {
        date: '2030-01-07',
        dailyImpact: {
          points: 256352.96197152336,
        },
      },
    },
    delta: {
      raw: -45.13071895424836,
      formatted: '45',
      display: '-45%',
      type: 'lower',
    },
    targetHint: {
      type: 'annual_sufficient',
    },
    baseline: {
      name: '2018/2018 Baseline',
      start: {
        date: '2018-01-01',
        dailyImpact: {
          points: 571412.7091273658,
        },
      },
      end: {
        date: '2018-12-31',
        dailyImpact: {
          points: 571412.7091273658,
        },
      },
      totalImpact: {
        points: 208565638.83148852,
      },
      days: 365,
    },
    reports: [
      {
        name: 'Historical: 2020-01-01/2020-12-31',
        start: {
          date: '2020-01-01',
          dailyImpact: {
            points: 512866.3249954636,
          },
        },
        end: {
          date: '2020-12-31',
          dailyImpact: {
            points: 512866.3249954636,
          },
        },
        totalImpact: {
          points: 187709074.94833967,
        },
        days: 366,
      },
      {
        name: 'Historical: 2021-06-01/2022-05-31',
        start: {
          date: '2021-06-01',
          dailyImpact: {
            points: 399988.8963891562,
          },
        },
        end: {
          date: '2022-05-31',
          dailyImpact: {
            points: 399988.8963891562,
          },
        },
        totalImpact: {
          points: 145995947.182042,
        },
        days: 365,
      },
      {
        name: 'Historical: 2023-01-01/2023-06-30',
        start: {
          date: '2023-01-01',
          dailyImpact: {
            points: 345688.9041405887,
          },
        },
        end: {
          date: '2023-06-30',
          dailyImpact: {
            points: 345688.9041405887,
          },
        },
        totalImpact: {
          points: 62569691.649446554,
        },
        days: 181,
      },
      {
        name: 'Historical: 2023-07-01/2023-11-30',
        start: {
          date: '2023-07-01',
          dailyImpact: {
            points: 313530.04530223773,
          },
        },
        end: {
          date: '2023-11-30',
          dailyImpact: {
            points: 313530.04530223773,
          },
        },
        totalImpact: {
          points: 47970096.93124238,
        },
        days: 153,
      },
    ],
    xAxis: {
      startDate: '2018-01-01',
      endDate: '2030-01-07',
    },
    yAxisMaxValue: {
      points: 571412.7091273658,
    },
    estimate: {
      type: 'sufficient',
      start: {
        date: '2023-11-30',
        dailyImpact: {
          points: 313530.04530223773,
        },
      },
      end: {
        date: '2030-01-07',
        dailyImpact: {
          points: 137740.99193793064,
        },
      },
    },
  };

  return (
    <ProgressChart
      disabled
      small={props.small}
      targets={targets as any}
      selectedImpact={impact}
      selectedType={selectedImpactUnit}
      impacts={[impact] as any}
    />
  );
};
