import {
  ForwardedRef,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useEffect,
} from 'react';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps, Formik, useFormikContext } from 'formik';
import {
  AgreementType,
  Facility,
  FacilityType,
  OwnershipStatusType,
  StaticEntity,
  Supplier,
  createFacility,
  getSuppliers,
  facilityOptions,
} from '../../../../api';
import { AddressResolver } from '../../../../components/AddressResolver';
import { InputV3 } from '../../../../components/InputV3';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { RadioInputs } from '../../../../components/RadioInputs';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { useLists } from '../../../../hooks/useLists';
import { NewSupplierForm } from '../Suppliers/NewSupplierForm';
import { facilityValidationSchema } from './index';

interface Props {
  name: string;
  type?: FacilityType;
  formRef?: RefObject<HTMLDivElement>;
  modalRef?: RefObject<ModalApi>;
  onCancel: () => void;
  onCreated: (newFacility: Facility) => void;
  formWide?: boolean;
  waiting?: boolean;
  setWaiting?: (v: SetStateAction<boolean>) => void;
}

export const NewFacilityForm = forwardRef((props: PropsWithChildren<Props>, ref: ForwardedRef<{ submit: () => void }> | undefined) => {
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const [waiting, setWaiting] = useState(props.waiting ?? false);

  return (
    <Formik<Facility>
      initialValues={
        {
          name: props.name,
          type: props.type,
        } as unknown as Facility
      }
      validationSchema={facilityValidationSchema}
      validateOnBlur={triedSubmitting}
      validateOnChange={triedSubmitting}
      onSubmit={(values) => {
        setWaiting(true);
        props.setWaiting?.(true);
        createFacility(values).ok((data) => {
          props.onCreated(data);
          setWaiting(false);
          props.setWaiting?.(false);
        });
      }}
    >
      <Content ref={ref} {...props} setTriedSubmitting={setTriedSubmitting} waiting={waiting} />
    </Formik>
  );
});

const Content = forwardRef(
  (
    props: Props & { setTriedSubmitting: (v: SetStateAction<boolean>) => void; waiting: boolean },
    ref: ForwardedRef<{ submit: () => void }> | undefined,
  ) => {
    const [newSupplierName, setNewSupplierName] = useState('');
    const lists = useLists();
    const supplierSubmitButtonRef = useRef<{ submit: () => void }>(null);
    const formik = useFormikContext<Facility>();

    useImperativeHandle(ref, () => ({
      submit: () => {
        props.setTriedSubmitting(true);
        formik.handleSubmit();
      },
    }));

    useEffect(() => {
      if (!formik.values.ownershipStatus) {
        if (formik.values.owner?.controlledByCustomer) {
          formik.setFieldValue('ownershipStatus', lists.facilityOwnerStatuses.find(({ type }) => type === OwnershipStatusType.Owned)!);
        } else {
          formik.setFieldValue('ownershipStatus', undefined);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.owner]);

    return (
      <div className={cn('flex flex-col gap-y-4', { 'p-3 bg-[#F5F7FA] rounded-xl shadow-regular': !ref })}>
        <div className={cn('grid gap-4', props.formWide ? 'grid-cols-4' : 'grid-cols-2')}>
          <div className='flex flex-col gap-2'>
            <div className='pl-1.5 font-semibold'>Location name&#42;</div>
            <Field name='name'>{(model: FieldProps<string>) => <InputV3 autoFocus model={model} placeholder='Name the location…' />}</Field>
          </div>
          <div className='flex flex-col gap-2 w-full'>
            <div className='pl-1.5 font-semibold'>Location ID</div>
            <Field name='alias'>{(model: FieldProps<string>) => <InputV3 model={model} placeholder='Your internal ID…' />}</Field>
          </div>
          <Field name='type'>
            {(model: FieldProps<FacilityType>) => (
              <div className='flex flex-col gap-2'>
                <div className='pl-1.5 font-semibold'>Type of location&#42;</div>
                <SelectV3<{ id: FacilityType; name: string }>
                  model={model}
                  disabled={!!props.type}
                  menuPortalTarget={props.formRef?.current}
                  options={facilityOptions}
                  onActions={{
                    add: (value) => {
                      model.form.setFieldValue('type', value.id);
                      if (value.id === FacilityType.Consumption) {
                        formik.setFieldValue('owner', undefined);
                        formik.setFieldValue('ownershipStatus', undefined);
                      }
                    },
                    remove: () => {
                      model.form.setFieldValue('type', undefined);
                      formik.setFieldValue('owner', undefined);
                      formik.setFieldValue('ownershipStatus', undefined);
                    },
                    clear: () => {
                      model.form.setFieldValue('type', undefined);
                      formik.setFieldValue('owner', undefined);
                      formik.setFieldValue('ownershipStatus', undefined);
                    },
                  }}
                  getOptionValue={({ id }) => id}
                  getOptionLabel={({ id }) => facilityOptions.find((option) => option.id === id)!.name}
                  convertOptions={{
                    fromModel: () => facilityOptions.find((option) => option.id === model.field.value),
                    toModel: (option) => option?.id,
                  }}
                />
              </div>
            )}
          </Field>
          {formik.values.type && formik.values.type !== FacilityType.Consumption ? (
            <Field name='owner'>
              {(model: FieldProps<Supplier>) => {
                return (
                  <div className='flex flex-col gap-2'>
                    <div className='pl-1.5 font-semibold'>Owner&#42;</div>
                    <SelectV3<Supplier>
                      model={model}
                      loadOptions={(input, callback) => {
                        setNewSupplierName(input);
                        getSuppliers({
                          contains: input,
                        }).ok(({ suppliers }) => callback(suppliers));
                      }}
                      onActions={{
                        add: (value) => {
                          if (value.controlledByCustomer) {
                            formik.setFieldValue(
                              'ownershipStatus',
                              lists.facilityOwnerStatuses.find(({ type }) => type === OwnershipStatusType.Owned)!,
                            );
                          } else {
                            formik.setFieldValue('ownershipStatus', undefined);
                          }
                          model.form.setFieldValue('owner', value);
                        },
                        remove: () => {
                          formik.setFieldValue('ownershipStatus', undefined);
                          model.form.setFieldValue('owner', undefined);
                        },
                        clear: () => {
                          formik.setFieldValue('ownershipStatus', undefined);
                          model.form.setFieldValue('owner', undefined);
                        },
                      }}
                      menuPortalTarget={props.formRef?.current}
                      menuFooter={
                        props.modalRef ? (
                          <SelectFooterAddButton
                            onClick={() => props.modalRef?.current!.open()}
                            name={newSupplierName}
                            label='new provider'
                          />
                        ) : undefined
                      }
                    />
                  </div>
                );
              }}
            </Field>
          ) : (
            <div />
          )}
          <Field name='location'>
            {(model: FieldProps<StaticEntity<string>>) => (
              <div className='flex flex-col gap-2 w-full'>
                <div className='pl-1.5 font-semibold'>Country&#42;</div>
                <SelectV3
                  model={model}
                  options={lists.countries}
                  menuPortalTarget={props.formRef?.current}
                  onActions={{
                    add: (value) => {
                      model.form.setFieldValue('address', undefined);
                      model.form.setFieldValue('location', value);
                    },
                    remove: () => {
                      model.form.setFieldValue('address', undefined);
                      model.form.setFieldValue('location', undefined);
                    },
                    clear: () => {
                      model.form.setFieldValue('address', undefined);
                      model.form.setFieldValue('location', undefined);
                    },
                  }}
                />
              </div>
            )}
          </Field>
          {formik.values.location && (
            <Field name='address'>
              {(model: FieldProps<{ text: string | undefined }>) => (
                <div className='flex flex-col gap-2 w-full'>
                  <div className='pl-1.5 font-semibold'>Address&#42;</div>
                  <div title={model.field.value?.text}>
                    <AddressResolver model={model} countryId={formik.values.location?.id ?? ''} />
                  </div>
                </div>
              )}
            </Field>
          )}
          {formik.values.type && formik.values.type !== FacilityType.Consumption && (
            <Field name='ownershipStatus'>
              {(model: FieldProps<string>) => (
                <div className='flex flex-col gap-2 w-full'>
                  <div className='pl-1.5 font-semibold'>Facility Status&#42;</div>
                  <SelectV3
                    model={model}
                    /*onActions={{
                      add: (value) => {
                        if (value.type === OwnershipStatusType.ThirdParty) {
                          model.form.setFieldValue(model.field.name, {
                            ...value,
                            agreement: lists.agreements.find(({ type }) => type === AgreementType.Direct),
                          });
                        } else {
                          model.form.setFieldValue(model.field.name, value);
                        }
                      },
                      remove: () => {},
                      clear: () => {},
                    }}*/
                    options={
                      formik.values.owner?.controlledByCustomer
                        ? lists.facilityOwnerStatuses
                        : lists.facilityOwnerStatuses.filter(({ type }) => type !== OwnershipStatusType.Owned)
                    }
                    disabled={formik.values.owner?.controlledByCustomer}
                    menuPortalTarget={props.formRef?.current}
                  />
                </div>
              )}
            </Field>
          )}

          {formik.values.ownershipStatus?.type === OwnershipStatusType.ThirdParty && formik.values.type !== FacilityType.Production && (
            <Field name='ownershipStatus.agreement'>
              {(model: FieldProps<StaticEntity<AgreementType>>) => (
                <div className='flex flex-col gap-2 pl-1.5'>
                  <div className='flex items-center gap-x-1 font-semibold'>
                    Agreement&#42;{' '}
                    <TooltipV3
                      content={
                        <div className='bg-[#330099] rounded-lg shadow px-2 py-1 text-white text-xs'>
                          Specify if you have a formal contractual agreement directly with the facility for products transitioning through
                          it (‘Direct contract’ option) or if you’re not explicitly paying for the services in question (‘Indirect
                          agreement’). This will affect which scope 3 sub-category to attribute those emissions to.
                        </div>
                      }
                    >
                      <div className='flex'>
                        <FontAwesomeIcon icon={light('info-circle')} />
                      </div>
                    </TooltipV3>
                  </div>
                  <RadioInputs model={model} options={lists.agreements} />
                </div>
              )}
            </Field>
          )}
        </div>

        {!ref && (
          <>
            <div className='border-t border-zinc-300' />
            <div className='flex justify-between font-semibold'>
              <button
                type='button'
                onClick={props.onCancel}
                className='flex border bg-white border-brandDarkPurple2 text-brandDarkPurple2 rounded-md px-3 py-1.5'
              >
                Cancel
              </button>
              <button
                type='button'
                disabled={props.waiting}
                onClick={() => {
                  props.setTriedSubmitting(true);
                  formik.handleSubmit();
                }}
                className={cn('flex bg-lightBg2 text-brandDarkPurple2 rounded-md px-3 py-1.5', { 'cursor-wait': props.waiting })}
              >
                Create
                {(() => {
                  const typeName = facilityOptions.find(({ id }) => id === formik.values.type)?.name;
                  return typeName ? ` ${typeName.toLowerCase()}` : '';
                })()}
              </button>
            </div>
          </>
        )}

        <ModalV3
          noCloseOnConfirm
          size='wide'
          title='New Provider'
          ref={props.modalRef}
          onConfirm={() => supplierSubmitButtonRef.current!.submit()}
          onCancel={() => {}}
          body={
            <div ref={props.formRef} className='flex flex-col gap-4 col-span-2 bg-[#F5F7FA] p-3 rounded-xl shadow-regular -mt-4'>
              <NewSupplierForm
                ref={supplierSubmitButtonRef}
                formRef={props.formRef}
                name={newSupplierName}
                requiredServices={[]}
                onCancel={() => {}}
                onCreated={(newSupplier) => {
                  formik.setFieldValue('owner', newSupplier);
                  props.modalRef?.current!.close();
                }}
              />
            </div>
          }
        />
      </div>
    );
  },
);
