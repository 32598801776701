import { forwardRef } from 'react';
import { GhgGraph } from '../../../../../components/charts/GhgGraph';

interface Props {
  title: string;
}

export const GhgPlaceholder = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex items-center justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      </div>
      <div className='p-6 bg-white rounded-2xl border pointer-events-none opacity-50'>
        <GhgGraph />
      </div>
    </div>
  );
});
