import * as yup from 'yup';
import { ManufacturingProduct } from '../../../api';

export const validationSchema = yup.object().shape({
  products: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        count: yup
          .number()
          .when('models', {
            is: (models: ManufacturingProduct[]) => models.length > 0 && !models[0].count,
            then: yup.number().integer().positive().required(),
            otherwise: yup.number(),
          })
          .when('selected', {
            is: (selected: boolean) => selected,
            then: yup.number().integer().positive().required(),
            otherwise: yup.number(),
          }),
        models: yup.array().of(
          yup.object().shape({
            count: yup.number().integer().positive().required(),
          }),
        ),
      }),
    ),
});
