import cn from 'classnames';
import { OriginalAwareDiffedItem } from './OriginalAwareField';

export interface Props {
  added?: boolean;
  updated?: boolean;
  deleted?: boolean;
  highImpact?: boolean;
}

export const Badge = (props: Props) => (
  <div
    className={cn('uppercase leading-none text-[8px] font-semibold tracking-wider text-zinc-600 px-1.5 py-1 rounded', {
      'bg-blue-200': props.added,
      'bg-[#DACEFD]': props.updated,
      'bg-zinc-200': props.deleted,
      'bg-[#FEF3C7]': props.highImpact,
    })}
  >
    {(() => {
      if (props.added) {
        return 'new';
      }

      if (props.updated) {
        return 'edited';
      }

      if (props.deleted) {
        return 'removed';
      }

      return 'high impact';
    })()}
  </div>
);

export const CardBadge = (props: { item: OriginalAwareDiffedItem<any> }) =>
  props.item.added || props.item.deleted ? (
    <div className='flex'>
      <Badge added={props.item.added} deleted={props.item.deleted} />
    </div>
  ) : (
    <></>
  );
