export enum SectionId {
  Overview,
  ProgressTracking,
  ImpactDetails,
  WinnersLosers,
  DataCompletion,
  SupplyChain,
  EcoLabellingDetails,
  Ghg,
}

export enum Methodology {
  Production,
  Consumer,
  Ghg,
}
