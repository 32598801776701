import { Summary as FESummary } from '../../components/Summary';
import { Lens, ProductReport } from '../../../../../../../api';

interface Props {
  data: ProductReport;
}

export const Summary = (props: Props) => {
  return <FESummary type={Lens.Consumer} data={props.data} />;
};
