import { Appendix } from '../../../components/Appendix';
import { HistoricalPefReport, ReportType } from '../../../../../../api';

interface Props {
  data: HistoricalPefReport;
}

export const HistoricalAppendix = (props: Props) => {
  return <Appendix data={props.data} reportType={ReportType.Historical} />;
};
