import {
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { ForwardedRef, PropsWithChildren, forwardRef, useState } from 'react';
import { ProductV3, visibleTags } from '../../../../api';
import { ModalFormApi } from '../../../../components/ModalForm';
import { ModalV3 } from '../../../../components/ModalV3';
import { BestMatchBadge, DefaultBadge, ExactMatchBadge, PlaceholderBadge, UnmatchedBadge } from './Badges';

export const BadgesHelpPopover = () => {
  const formik = useFormikContext<ProductV3>();
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-end',
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 8, crossAxis: 42 }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  if (!formik.values.metadata.system.flatMap(({ tags }) => tags).some((tag) => visibleTags.includes(tag))) {
    return <></>;
  }

  return (
    <>
      <div
        className={cn('cursor-pointer select-none flex items-center gap-2 p-2 pr-3 rounded-lg', {
          'bg-[#E8EAF5]': open,
        })}
        {...getReferenceProps({
          ref: reference,
        })}
      >
        <div className='w-5 aspect-square bg-brandLime flex justify-center items-center rounded-full'>
          <FontAwesomeIcon className='text-zinc-700' icon={light('lightbulb')} size='sm' />
        </div>
        <div className='font-semibold text-brand'>Badges</div>
      </div>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
              },
            })}
            className='antialiased w-[400px] z-10 bg-white rounded-lg p-4 shadow-[0_1px_10px_0_rgba(0,0,0,0.15)]'
          >
            <div className='flex flex-col gap-4'>
              <div className='flex flex-col gap-1'>
                <div className='font-semibold text-zinc-800'>Helping you understand the badges</div>
                <div className='text-xs text-zinc-900'>
                  There may be five types of badges in this modal, describing the nature of each input. Here is what they mean:
                </div>
              </div>
              <HelpContent small />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export const skipBadgesHelpKey = 'skipBadgesHelp';

export const BadgesHelpModal = forwardRef(
  (
    props: PropsWithChildren<{
      onOpenChange?: (open: boolean) => void;
      showSkip?: boolean;
    }>,
    ref: ForwardedRef<ModalFormApi>,
  ) => {
    const skipCheckboxId = useId();
    const [skip, setSkip] = useState(false);
    return (
      <ModalV3
        ref={ref}
        title='Helping you understand the badges'
        body={
          <div className='flex flex-col gap-8'>
            <div className='text-sm text-zinc-900'>
              Your product graph may include five types of badges, describing the nature of each input in your graph. Here is what they
              mean:
            </div>
            <HelpContent />
            {props.showSkip && (
              <div className='flex gap-4'>
                <input
                  id={skipCheckboxId}
                  type='checkbox'
                  checked={skip}
                  onChange={() => {
                    const newSkip = !skip;
                    setSkip(newSkip);
                    localStorage.setItem(skipBadgesHelpKey, newSkip.toString());
                  }}
                />
                <label htmlFor={skipCheckboxId} className='select-none'>
                  Don’t show this message again (you can always get back to it by clicking on “instructions &gt; badges”)
                </label>
              </div>
            )}
          </div>
        }
        confirmLabel='Close'
        hideCancel
        {...props}
      />
    );
  },
);

const HelpContent = (props: { small?: boolean }) => (
  <div className='flex flex-col gap-2'>
    {[
      {
        component: <UnmatchedBadge />,
        text: 'Requires user intervention. Unmatched from customer documents. Will be ignored until resolved when calculating the product impact.',
      },
      {
        component: <ExactMatchBadge />,
        text: 'A perfect match in theory, AI-matched from customer documents.',
      },
      {
        component: <BestMatchBadge />,
        text: 'Requires user verification. AI-matched from customer documents.',
      },
      {
        component: <DefaultBadge />,
        text: 'System-generated default to fill in the gaps where data is missing. To be replaced with first-party data.',
      },
      {
        component: <PlaceholderBadge />,
        text: 'Missing process in secondary dataset requiring a new process creation by our LCA expert. Will be ignored when initially calculating the impact.',
      },
    ].map(({ component, text }) => (
      <div key={text} className='flex gap-5 rounded-lg p-2 pl-3 border border-zinc-300'>
        <div className='flex shrink-0 justify-center items-center w-20'>{component}</div>
        <div
          className={cn('text-zinc-700', {
            'text-xs': props.small,
          })}
        >
          {text}
        </div>
      </div>
    ))}
  </div>
);
