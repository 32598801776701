import { ProductReport } from '../../../../../../../api';
import { CollapsibleSection } from '../../../CollapsibleSection';
import { GoalAndScope as GoalAndScopeConsumer } from '../../components/GoalAndScope';
import { NavigationButtons } from '../../components/NavigationButtons';

interface Props {
  data: ProductReport;
}

export const GoalAndScope = (props: Props) => {
  return (
    <GoalAndScopeConsumer
      data={props.data}
      description={
        <div className='flex justify-between gap-8'>
          <div className='text-brandGray5 ml-3'>
            An overview of the goal and scope of the LCA assessment carried out including the functional unit, system boundaries, data
            quality, and the methodology used.
          </div>
          <NavigationButtons type='icons' back={{ path: '../details' }} next={{ path: '../impact-analysis' }} />
        </div>
      }
      goal={
        <CollapsibleSection title='Goal'>
          <div>
            The goal of this analysis is to provide an overview of the environmental impact of a given product as consumed by a person at
            home.
          </div>
        </CollapsibleSection>
      }
      functionalUnit={
        <CollapsibleSection title='Functional Unit'>
          <div className='flex flex-col gap-6'>
            <div>The functional unit is:</div>
            <div className='text-brandDark'>1kg of consumed product ({props.data.product.name})</div>
            <div>
              This functional unit is catered to consumer’s understanding and is based on the amount of product after preparation by the
              consumer (as defined by the Raw to Cooked ratio of {props.data.product.rawToCookedRatio} provided during product creation).
              The results displayed in this report will be valid for the specified functional unit only,
            </div>
          </div>
        </CollapsibleSection>
      }
      methodology={
        <CollapsibleSection title='Methodology'>
          <div className='flex flex-col gap-6'>
            <div>
              The results of the life cycle impact assessment presented in this report were obtained by largely following the Product
              Environmental Footprint (PEF) framework v.3.0 and more specifically the{' '}
              <button
                onClick={() =>
                  window.open(
                    'https://www.foundation-earth.org/wp-content/uploads/2023/03/Foundation-Earth-LCA-Methodology-Beta-Version-1.0.pdf',
                    '_blank',
                  )
                }
                className='underline font-semibold hover:text-brand'
              >
                Foundation Earth methodology
              </button>{' '}
              created based on PEF, using the OpenLCA v.1.11.0 software to compute the environmental impact calculations.
            </div>
            <div>
              This framework was selected because it is comprehensive and aligns with the aim to harmonise European environmental footprint
              studies. PEF recommends the calculation of 16 environmental impact categories (details of which can be found on our
              Methodology page). After calculation of each impact category’s physical value (in their specific units), these were divided by
              average annual EU27 per capita burdens to generate normalised scores as per the PEF framework. Normalised scores (expressed as
              person.year) for all impact categories were then weighted using the PEF recommended weightings. Finally, as per the Foundation
              Earth methodology, a Raw to Cooked ratio is applied to take into account any mass change resulting from consumer preparation.
              Final results are displayed from highest to lowest impact (See Impact Analysis section).
            </div>
            <div>
              The final analysis was made with the following Sustained Impact web application and methodology versions:
              <ul className='list-disc ml-6 my-2'>
                <li>Sustained Impact web application v.{props.data.metadata.webAppVersion}</li>
                <li>Sustained Impact methodology v.{props.data.metadata.methodologyVersion} (Foundation Earth aligned)</li>
              </ul>
            </div>
          </div>
        </CollapsibleSection>
      }
      systemBoundaries={
        <>
          <div>The analysis is performed from ‘cradle-to-grave’ with the following life cycle stages considered:</div>
          <ul className='list-disc ml-6 my-2'>
            <li>Extraction and production of raw materials with associated transportation to production facility</li>
            <li>Production of the product by the manufacturer including packaging</li>
            <li>Distribution from production facility to the end consumer</li>
            <li>Use phase of the product ready for consumption</li>
            <li>End-of-life through packaging waste processing</li>
          </ul>
        </>
      }
      bottomNavigationButtons={
        <NavigationButtons
          type='buttons'
          back={{ path: '../details', label: 'Product Details' }}
          next={{ path: '../impact-analysis', label: 'Impact analysis' }}
        />
      }
    />
  );
};
