import { FieldProps } from 'formik';
import { createContext, PropsWithChildren, useContext } from 'react';

interface Api {
  touched: (model?: FieldProps) => void;
  touchedPath: (path: string) => void;
}

const Context = createContext<Api | null>(null);

export const useControlEvents = () => useContext(Context);

type Props = PropsWithChildren<{
  onTouched: (path: string) => void;
}>;

export const ControlEventsProvider = (props: Props) => (
  <Context.Provider
    value={{
      touched: (model) => {
        if (model) {
          props.onTouched(model.field.name);
        }
      },
      touchedPath: props.onTouched,
    }}
  >
    {props.children}
  </Context.Provider>
);
