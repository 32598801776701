import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import radarPlaceholder from '../../../assets/radar_placeholder.png';
import piePlaceholder from '../../../assets/pie_placeholder.png';
import rankingPlaceholder from '../../../assets/ranking_placeholder.png';

interface Props {
  title: string;
}

export const ImpactDetailsPlaceholder = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <div ref={ref} className='flex flex-col px-2 gap-4 pb-10'>
    <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
    <div className='text-base flex items-center gap-x-4 bg-white p-4 rounded-lg border'>
      <div className='size-10 bg-brandLime flex items-center justify-center rounded-full'>
        <FontAwesomeIcon className='text-[18px] text-zinc-700' icon={light('lightbulb')} />
      </div>
      <div>We couldn’t find any data for this section. Set a baseline to learn more about where your impact comes from.</div>
    </div>
    <div className='grid grid-cols-[4fr_3fr_2fr] items-center justify-between gap-x-4'>
      {[
        {
          title: 'By impact category',
          img: <img src={radarPlaceholder} alt='_' className='size-auto' />,
        },
        {
          title: 'By life cycle stage',
          img: <img src={piePlaceholder} alt='_' className='size-auto -my-8' />,
        },
        {
          title: 'Product ranking',
          img: <img src={rankingPlaceholder} alt='_' className='h-64' />,
        },
      ].map((placeholder, index) => (
        <div key={index} className='flex flex-col justify-between p-4 bg-white border rounded-lg h-full'>
          <div className='text-zinc-300'>{placeholder.title}</div>
          {placeholder.img}
        </div>
      ))}
    </div>
  </div>
));
