import { NavLink } from 'react-router-dom';
import { ModellingReport, NodeType, ProductType } from '../../../../../api';
import { NavigationButtons } from '../Sku/components/NavigationButtons';
import { Distribution } from './components/ProposedChanges/Distribution';
import { EndOfLife } from './components/ProposedChanges/EndOfLife';
import { Main as Production } from './components/ProposedChanges/Production';
import { RawMaterials } from './components/ProposedChanges/RawMaterials';
import { Use } from './components/ProposedChanges/Use';
import { getConsumptionLocationsFromAll } from '../../Details/dataModel';

interface Props {
  data: ModellingReport;
}

export const ProposedChanges = (props: Props) => {
  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm w-full'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>
          A detailed view of your original product, and the changes modelled for each life cycle stage. All details of your original product
          are included for reference and changes are displayed in blue in the second column. Where the change column is empty, this means
          the same variable is considered for the original product and the modelled one.
        </div>
        <NavigationButtons type='icons' back={{ path: '../summary' }} next={{ path: '../impact-change-results' }} />
      </div>
      <div className='border print:border-none rounded-regular shadow-regular px-8 py-6'>
        <div className='text-xl font-semibold group-hover:text-brand'>Life cycle visualisation</div>
        <div className='w-full flex flex-col gap-6 mt-6'>
          <div>
            You can view or edit your product’s detailed life cycle, from its raw materials to production, to distribution, use and end of
            life, by clicking on the button below. You will be taken into our graph editing interface where you’ll be able to understand the
            properties of the product, its suppliers, any primary data added into the system and more.
          </div>
          <NavLink
            to={`/products/${props.data.product.id}/models/${props.data.model.id}`}
            className='flex self-center border-2 whitespace-nowrap border-brand bg-brand rounded-full px-4 py-1.5 text-white font-semibold active:scale-95 print:hidden'
          >
            View Modelled product
          </NavLink>
        </div>
      </div>
      <RawMaterials product={props.data.product} model={props.data.model} />
      <Production product={props.data.product} model={props.data.model} />
      {(props.data.product.productType === ProductType.Final || props.data.product.productType === ProductType.Intermediate) &&
        [...props.data.product.nodes, ...props.data.model.nodes].find(({ type }) =>
          [NodeType.Store, NodeType.Warehouse, NodeType.FinalDestination].includes(type),
        ) && <Distribution product={props.data.product} model={props.data.model} />}
      {props.data.product.productType === ProductType.Final &&
        getConsumptionLocationsFromAll([...props.data.product.nodes, ...props.data.model.nodes]).length > 0 && (
          <Use product={props.data.product} model={props.data.model} />
        )}
      {props.data.product.productType === ProductType.Final && <EndOfLife product={props.data.product} model={props.data.model} />}
      <NavigationButtons
        type='buttons'
        back={{ path: '../summary', label: 'Summary' }}
        next={{ path: '../impact-change-results', label: 'Impact Change Results' }}
      />
    </div>
  );
};
