import { format } from 'date-fns';
import { CreateReport } from '../../../../components/Lists/CreateReport';
import { ReportType } from '../../../../../../../api';
import { useRef, useState } from 'react';
import { Import } from '../../../../components/Import';
import { ModalApi } from '../../../../../../../components/ModalV3';

interface Props {
  dateRange: { start: Date | null; end: Date | null };
  onNext: () => void;
}

export const Step3 = (props: Props) => {
  const dateRange =
    props.dateRange.start && props.dateRange.end ? { startDate: props.dateRange.start, endDate: props.dateRange.end } : undefined;
  const [topContentRef, setTopContentRef] = useState<HTMLDivElement | null>(null);
  const modalRef = useRef<ModalApi>(null);

  return (
    <>
      <Import preventDismiss modalRef={modalRef} />
      <div ref={setTopContentRef} className='flex flex-col gap-y-4'>
        <div className='font-semibold text-xl text-dark'>Add actual manufactured quantities</div>
        <div>
          How many of each product did you manufacture over the given time period (
          {`${format(dateRange?.startDate!, 'dd/MM/yy')} - ${format(dateRange?.endDate!, 'dd/MM/yy')}`})? Add those in the table below.
        </div>
      </div>
      {topContentRef && (
        <CreateReport
          dataFromCsv
          reportType={ReportType.Historical}
          dateRange={dateRange}
          reportTitle={`Historical Volume Report ${format(props.dateRange.start!, 'dd/MM/yy')} - ${format(
            props.dateRange.end!,
            'dd/MM/yy',
          )}`}
          onNext={props.onNext}
        />
      )}
    </>
  );
};
