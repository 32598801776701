import { Field, FieldProps, FormikContextType, useFormikContext } from 'formik';
import { PropsWithChildren, RefObject, useRef } from 'react';
import * as yup from 'yup';
import { Entity, ProductV3, StorageNode, StoredItem } from '../../../../../api';
import { InputV3 } from '../../../../../components/InputV3';
import { ModalForm, ModalFormSaveCallback } from '../../../../../components/ModalForm';
import { SelectV3 } from '../../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../../components/UnitInputV3';
import { useLists } from '../../../../../hooks/useLists';
import { ModalHeaderRightBar } from '../ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer } from '../TaggableFields';

type Props = PropsWithChildren<{
  parentNode: StorageNode;
  data: StoredItem;
  onSave: ModalFormSaveCallback<StoredItem>;
}>;

export const ItemDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();

  return (
    <ModalForm
      formRef={formRef}
      title={`Editing ${props.data.name}`}
      body={<Body productFormik={formik} parentNode={props.parentNode} formRef={formRef} />}
      headerRight={<ModalHeaderRightBar noFlag />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>How is this item being stored?</div>
          <div>
            The condition and duration are both very important parameters to accurately measure the impact of storing goods in storage
            facilities.
          </div>
          <div>
            Overwrite the default PEF assumptions with your own primary data if you have this information for a more accurate representation
            of your actual storage impact.
          </div>
        </div>
      }
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={yup.object().shape({
        conservation: yup.object().required(),
        durationWeeks: yup.number().required(),
      })}
      saveLabel='Confirm changes'
      onSave={props.onSave}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  parentNode: StorageNode;
  productFormik: FormikContextType<ProductV3>;
  formRef: RefObject<HTMLDivElement>;
}

const Body = (props: BodyProps) => {
  const formik = useFormikContext<StoredItem>();
  const lists = useLists();

  return (
    <TaggableFieldsContainer pathPrefix={`nodes/${props.parentNode.id}/items`}>
      <div className='grid grid-cols-3 gap-4'>
        <Field name='name'>
          {(model: FieldProps<string>) => (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Name</div>
              <div className='flex flex-col'>
                <InputV3 model={model} disabled />
              </div>
            </div>
          )}
        </Field>
        <Field name='amount.value'>
          {(model: FieldProps<number>) => (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Amount</div>
              <div className='flex flex-col'>
                <UnitInputV3 model={model} unit={{ options: [formik.values.amount.unit] }} disabled />
              </div>
            </div>
          )}
        </Field>
        <Field name='type'>
          {(model: FieldProps<string>) => (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Type</div>
              <div className='flex flex-col'>
                <InputV3 model={model} disabled />
              </div>
            </div>
          )}
        </Field>
        <TaggableField name='conservation'>
          {(model: FieldProps<Entity>) => (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Storage condition</div>
              <div className='flex flex-col'>
                <SelectV3<Entity>
                  model={model}
                  options={lists.conservationRequirements}
                  menuPortalTarget={props.formRef.current}
                  disabled={formik.values.id === props.productFormik.values.id}
                />
              </div>
            </div>
          )}
        </TaggableField>
        <TaggableField name='durationWeeks'>
          {(model: FieldProps<number>) => (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Storage duration</div>
              <div className='flex flex-col'>
                <UnitInputV3 model={model} unit={{ options: [{ id: 'weeks', name: 'weeks' }] }} />
              </div>
            </div>
          )}
        </TaggableField>
      </div>
    </TaggableFieldsContainer>
  );
};
