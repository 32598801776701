import { ImpactAnalysis as ImpactAnalysisFE } from '../../components/ImpactAnalysis';
import { ProductReport } from '../../../../../../../api';
import { NavigationButtons } from '../../components/NavigationButtons';
import { NavLink } from 'react-router-dom';

interface Props {
  data: ProductReport;
}

export const ImpactAnalysis = (props: Props) => {
  return (
    <ImpactAnalysisFE
      data={props.data}
      description={
        <div className='flex justify-between gap-8'>
          <div className='text-brandGray5 ml-3 flex flex-col gap-6'>
            <div>
              A comprehensive look at your product's environmental impact. This product’s total environmental impact is calculated across a
              number of impact categories, 16 in total, each with their own unit of measurement. The absolute impact of each category is
              referred to as the ‘physical output’ in this section and is shown in scientific units (eg. kg CO2e for climate change).
            </div>
            <div>
              In order to make these physical outputs comparable and obtain the final environmental impacts, normalisation and weightings
              are then applied (using the factors presented in the{' '}
              <NavLink to='../appendix' onClick={() => window.scrollTo({ top: 0 })} className='hover:text-brand font-semibold underline'>
                Appendix
              </NavLink>
              ), as per the PEF framework. These final environmental impacts are calculated in weighted person year, which compares the
              impact of your product to the impact an average person has in one year (similarly weighted).
            </div>
          </div>
          <NavigationButtons type='icons' back={{ path: '../goal-and-scope' }} next={{ path: '../environmental-label' }} />
        </div>
      }
    />
  );
};
