import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Form, Formik } from 'formik';
import { cloneElement, isValidElement, PropsWithChildren, ReactNode, useState } from 'react';

type Props<FV extends object> = PropsWithChildren<{
  icon?: IconDefinition;
  title?: string;
  titleContent?: ReactNode;
  body?: string;
  bodyContent?: ReactNode;
  contentBelowButtons?: (values: FV, close: () => void) => ReactNode;
  waiting?: boolean;
  widerButtons?: boolean;
  open?: boolean;
  formik?: {
    initialValues: FV;
    validationSchema?: any;
  };
  scrollable?: boolean;
  noCancel?: boolean;
  noSubmit?: boolean;
  cancelLabel?: string;
  onConfirm: (values: FV, close: () => void) => void;
  onCancel?: () => void;
  confirmLabel?: string;
  onOpenChange?: (open: boolean) => void;
  wrapperStyle?: string;
}>;

export const Modal = <FV extends object>(props: Props<FV>) => {
  const [open, setOpen] = useState(props.open ?? false);
  const [triedSubmitting, setTriedSubmitting] = useState(false);

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: (newOpen) => {
      setOpen(newOpen);

      if (props.onOpenChange) {
        props.onOpenChange(newOpen);
      }
    },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context),
  ]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && (
          <FloatingOverlay lockScroll className='flex justify-center items-center bg-black/50 z-50'>
            <Formik
              initialValues={props.formik?.initialValues || ({} as FV)}
              validationSchema={props.formik?.validationSchema}
              validateOnBlur={triedSubmitting}
              validateOnChange={triedSubmitting}
              onSubmit={(values) => props.onConfirm(values, () => context.onOpenChange(false))}
            >
              {(formik) => (
                <Form
                  {...getFloatingProps({
                    ref: floating,
                  })}
                  className={cn('antialiased flex flex-col gap-8 bg-white shadow-2xl border rounded-3xl', props.wrapperStyle ?? '', {
                    'overflow-y-scroll max-h-[calc(100vh-10%)]': props.scrollable,
                    'p-16 mx-16': !props.wrapperStyle,
                  })}
                >
                  {props.icon && <FontAwesomeIcon size='2x' icon={props.icon} className='text-dark' />}
                  <div className={cn('flex flex-col h-full', props.body ? 'gap-4' : 'gap-8')}>
                    {props.title ? (
                      <div className='flex justify-center text-center font-semibold text-lg text-dark'>{props.title}</div>
                    ) : (
                      props.titleContent
                    )}
                    {props.body ? <div className='flex justify-center text-center text-light'>{props.body}</div> : props.bodyContent}
                  </div>
                  <div className='flex justify-center gap-8'>
                    {!props.noCancel && (
                      <button
                        type='button'
                        className={cn(
                          'flex justify-center gap-2 border-2 border-brand bg-brand rounded-full px-6 py-2 text-white font-semibold',
                          '[&:active:not(:disabled)]:scale-95',
                          'disabled:cursor-wait',
                          props.widerButtons ? 'w-60' : 'w-40',
                        )}
                        onClick={() => {
                          props.onCancel && props.onCancel();
                          context.onOpenChange(false);
                        }}
                      >
                        {props.cancelLabel ?? 'Cancel'}
                      </button>
                    )}
                    {!props.noSubmit && (
                      <button
                        type='submit'
                        disabled={props.waiting}
                        className={cn(
                          'flex justify-center gap-2 border-2 border-brand bg-brand rounded-full px-6 py-2 text-white font-semibold',
                          '[&:active:not(:disabled)]:scale-95',
                          'disabled:cursor-wait',
                          props.widerButtons ? 'w-60' : 'w-40',
                        )}
                        onClick={() => setTriedSubmitting(true)}
                      >
                        {props.confirmLabel ?? 'Confirm'}
                      </button>
                    )}
                  </div>
                  {props.contentBelowButtons && props.contentBelowButtons(formik.values, () => context.onOpenChange(false))}
                </Form>
              )}
            </Formik>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </>
  );
};
