import { Report } from './Report';
import { Main } from './Main';
import { Route, Routes } from 'react-router';

export const Forecasts = () => {
  return (
    <Routes>
      <Route path=':id/report/*' element={<Report />} />
      <Route path='*' element={<Main />} />
    </Routes>
  );
};
