import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import range from 'lodash/range';
import { ReactNode, cloneElement, isValidElement, useEffect, useState } from 'react';
import { Size } from '../../../../api';

interface Props {
  modelName: string;
  children: (props: { open: boolean }) => ReactNode;
}

export const GridSize = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState<Size | null>(null);

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    placement: 'right-start',
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 10, crossAxis: -1 }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  const onMouseEnter = (size: Size) => {
    setHovered(size);
  };

  const onMouseLeave = () => {
    setHovered(null);
  };

  const children = props.children({ open });

  return (
    <>
      {isValidElement(children) && cloneElement(children, getReferenceProps({ ref: reference }))}
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, translateX: -2, translateY: -2 }}
            animate={{ opacity: 1, translateX: 0, translateY: 0 }}
            exit={{ opacity: 0, translateX: -2, translateY: -2 }}
            {...getFloatingProps({
              ref: floating,
              className: 'antialiased z-50 flex flex-col gap-2 p-2 pt-2.5 bg-white border rounded-lg shadow-xl',
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            <Field name={props.modelName}>
              {(model: FieldProps<Size>) => {
                const size = hovered ?? model.field.value;
                const edge = 3;
                const maxWidth = model.field.value.width + edge;
                const maxHeight = model.field.value.height + edge;
                return (
                  <>
                    <div className='leading-none p-1'>Resize layout grid</div>
                    <div className='h-px bg-gray-200'></div>
                    <div
                      className='grid'
                      style={{
                        gridTemplateColumns: `repeat(${maxWidth}, minmax(0, 1fr))`,
                      }}
                    >
                      {range(1, maxHeight + 1)
                        .flatMap((height) => range(1, maxWidth + 1).map((width) => ({ width, height })))
                        .map((cellSize) => (
                          <button
                            key={`${cellSize.width}.${cellSize.height}`}
                            type='button'
                            className='w-7 h-4 p-1 box-content'
                            onMouseEnter={() => onMouseEnter(cellSize)}
                            onMouseLeave={onMouseLeave}
                            onClick={() => {
                              model.form.setFieldValue(props.modelName, cellSize);
                            }}
                          >
                            <div
                              className={cn(
                                'rounded-sm w-full h-full',
                                cellSize.width <= size.width && cellSize.height <= size.height ? 'bg-gray-300' : 'bg-gray-100',
                              )}
                            ></div>
                          </button>
                        ))}
                    </div>
                    <div className='flex justify-center items-center gap-0.5'>
                      {size.width}
                      <FontAwesomeIcon size='xs' icon={regular('times')} />
                      {size.height}
                    </div>
                  </>
                );
              }}
            </Field>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
