import { ConsumptionNode, DisposalNode, Model, PackagingNodeMaterial, ProductV3 } from '../../../../../../../api';
import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getConsumptionLocationsFromAll, getDisposalsFromAll, getPackagingsFromAll } from '../../../../Details/dataModel';
import uniqBy from 'lodash/uniqBy';

interface Props {
  product: ProductV3;
  model: Model;
}

export const EndOfLife = (props: Props) => {
  const [expandedWrapper, setExpandedWrapper] = useState(true);

  const content = (() => {
    const productDisposals = getDisposalsFromAll(props.product.nodes).map((disposal) => ({
      ...disposal,
      consumptionLocations: getConsumptionLocationsFromAll(props.product.nodes).filter((consumption) =>
        consumption.edges.includes(disposal.id),
      ),
      packagingMaterials: getPackagingsFromAll(props.product.nodes).find(({ id }) => id === disposal.packagingNodeId)!.materials,
    }));

    const modelDisposals = getDisposalsFromAll(props.model.nodes).map((disposal) => ({
      ...disposal,
      consumptionLocations: getConsumptionLocationsFromAll(props.model.nodes).filter((consumption) =>
        consumption.edges.includes(disposal.id),
      ),
      packagingMaterials: getPackagingsFromAll(props.model.nodes).find(({ id }) => id === disposal.packagingNodeId)!.materials,
    }));

    const allUniqDisposals = uniqBy([...productDisposals, ...modelDisposals], 'id');

    return (
      <div className='flex flex-col gap-6'>
        {allUniqDisposals.map((disposal, i) => {
          const productDisposal = productDisposals.find(({ id }) => id === disposal.id);
          const modelDisposal = modelDisposals.find(({ id }) => id === disposal.id);

          return (
            <Fragment key={i}>
              <Disposal product={props.product} model={props.model} productDisposal={productDisposal} modelDisposal={modelDisposal} />
            </Fragment>
          );
        })}
      </div>
    );
  })();

  return (
    <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
      <button
        disabled
        onClick={() => {
          setExpandedWrapper(!expandedWrapper);
          if (expandedWrapper) {
          }
        }}
        className='grid grid-cols-3 items-center w-full px-8 print:px-0'
      >
        <div className='grid grid-cols-3 col-span-2 items-center'>
          <div className='text-xl font-semibold py-6 group-hover:text-brand'>End of life</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand invisible'
          icon={expandedWrapper ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {expandedWrapper && <div className='px-8 pb-8 text-dark print:hidden'>{content}</div>}
      <div className='px-8 print:px-0 pb-8 hidden print:block'>{content}</div>
    </div>
  );
};

type DisposalPayload = DisposalNode & { consumptionLocations: ConsumptionNode[]; packagingMaterials: PackagingNodeMaterial[] };

interface DisposalProps {
  product: ProductV3;
  model: Model;
  productDisposal?: DisposalPayload;
  modelDisposal?: DisposalPayload;
}
const Disposal = (props: DisposalProps) => {
  const [expandedDisposal, setExpandedDisposal] = useState(true);

  const productConsumptionLocations = props.productDisposal?.consumptionLocations ?? [];
  const modelConsumptionLocations = props.modelDisposal?.consumptionLocations ?? [];

  const uniqConsumptionLocations = uniqBy([...productConsumptionLocations, ...modelConsumptionLocations], 'id');

  return (
    <div className='flex flex-col gap-3'>
      <button onClick={() => setExpandedDisposal(!expandedDisposal)} className='flex items-center gap-2'>
        <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedDisposal })} icon={regular('chevron-right')} />
        <div className='h-7 aspect-square flex items-center justify-center bg-slate-50 text-slate-900 rounded-md border'>
          <FontAwesomeIcon icon={duotone('dumpster')} />
        </div>
        <div>{props.productDisposal?.displayName ?? props.modelDisposal?.displayName}</div>
      </button>
      {expandedDisposal &&
        uniqConsumptionLocations.map((consumptionLocation, i) => {
          const productConsumptionLocation = productConsumptionLocations.find(({ id }) => id === consumptionLocation.id);
          const modelConsumptionLocation = modelConsumptionLocations.find(({ id }) => id === consumptionLocation.id);

          return (
            <div
              key={i}
              className={cn('flex flex-col gap-6 rounded-xl shadow bg-neutral-50 p-6 border', {
                'border-emerald-700': !productConsumptionLocation && modelConsumptionLocation,
                'border-red-500': productConsumptionLocation && !modelConsumptionLocation,
              })}
            >
              <div className='grid grid-cols-3'>
                <div className='flex flex-col text-sm col-span-2'>
                  <div className='col-span-2 grid grid-cols-3'>
                    <div className='col-span-2 flex items-center gap-x-2 self-center text-base'>
                      <div className='h-7 aspect-square flex items-center justify-center text-cyan-900 bg-cyan-50 rounded-md border'>
                        <FontAwesomeIcon icon={duotone('house-user')} />
                      </div>
                      {productConsumptionLocation?.displayName ?? modelConsumptionLocation?.displayName}
                    </div>
                    <div>
                      {!productConsumptionLocation && modelConsumptionLocation && <div className='text-emerald-600'>Added</div>}
                      {productConsumptionLocation && !modelConsumptionLocation && <div className='text-red-600'>Removed</div>}
                    </div>
                  </div>

                  <div className='grid grid-cols-3 items-center gap-y-3'>
                    <div></div>
                    <div className='text-violet-900 text-sm font-semibold'>Original</div>
                    <div className='text-violet-900 text-sm font-semibold'>Modelled</div>

                    <div>Recyclability</div>
                    <div>{props.productDisposal?.disposalType.name}</div>
                    <div className='flex'>
                      {(() => {
                        if (
                          (!props.productDisposal && props.modelDisposal) ||
                          (props.productDisposal &&
                            props.modelDisposal &&
                            props.productDisposal.disposalType.type !== props.modelDisposal.disposalType.type)
                        ) {
                          return (
                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                              {props.modelDisposal?.disposalType.name}
                            </div>
                          );
                        }

                        return '';
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
