import { Interpretation } from '../../../components/Interpretation';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { HistoricalPefReport, ReportType } from '../../../../../../api';

interface Props {
  data: HistoricalPefReport;
}

export const HistoricalInterpretation = (props: Props) => {
  return (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-6'>
          An additional interpretation of the Life Cycle Impact Assessment results presented in the previous section, providing some further
          contextualisation and looking at some potential next steps for you on your road to improvement!
        </div>
        <NavigationButtons type='icons' back={{ path: '../lcia' }} next={{ path: '../appendix' }} />
      </div>
      <Interpretation data={props.data} reportType={ReportType.Historical} />
      <NavigationButtons back={{ path: '../lcia', label: 'LCIA' }} next={{ path: '../appendix', label: 'Appendix' }} type='buttons' />
    </div>
  );
};
