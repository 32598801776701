import { SetStateAction, useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DetailsView } from './DetailsView';
import { ListView } from './ListView';
import { ManageEntity, resolvedMappings, unresolvedMappings } from '../Overview';
import { getMapping, getMappingProducts, Mapping, MappingProduct } from '../../../../api';
import { useParams } from 'react-router';
import { useDebounce } from '../../../../hooks/useDebounce';
import { skipManageBadgesOnboarding, TagsOnboarding } from './components/TagsOnboarding';
import { ModalFormApi } from '../../../../components/ModalForm';

interface Props {
  mappingsData: ManageEntity<Mapping[]>;
  setMappingsData: (data: SetStateAction<ManageEntity<Mapping[]>>) => void;
  fetchNextMappingsPage: () => void;
  selectedCategory: string;
  reload: () => void;
  selectedTab: 'resolved' | 'unresolved';
  setSelectedTab: (value: 'resolved' | 'unresolved') => void;
}

export const Mappings = (props: Props) => {
  const [expanded, setExpanded] = useState<string[]>([
    'To be reviewed mappings',
    'Resolved mappings',
    'Item mapping',
    'Ingredient could not be automatically mapped',
    'Packaging could not be automatically mapped',
  ]);

  const tagsOnboardingRef = useRef<ModalFormApi>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const mainListHeaderRef = useRef<HTMLDivElement>(null);
  const secondaryListHeaderRef = useRef<HTMLDivElement>(null);
  const detailsHeaderRef = useRef<HTMLDivElement>(null);

  const [mainListHeight, setMainListHeight] = useState<number>();
  const [secondaryListHeight, setSecondaryListHeight] = useState<number>();
  const [detailsBodyHeight, setDetailsBodyHeight] = useState<number>();

  const [mappingProducts, setMappingProducts] = useState<{ list: MappingProduct[]; mappingId: string; searchString: string }>({
    list: [],
    mappingId: '',
    searchString: '',
  });

  const debouncedSearchString = useDebounce(mappingProducts.searchString, 300);

  const [selectedMapping, setSelectedMapping] = useState<Mapping | null>(null);
  const { id } = useParams();

  const [render, setRender] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (localStorage.getItem(skipManageBadgesOnboarding) !== 'true') {
      tagsOnboardingRef.current?.open();
    }
  }, []);

  useEffect(() => {
    if (mappingProducts.mappingId) {
      getMappingProducts(mappingProducts.mappingId, { contains: debouncedSearchString }).call({
        ok: (data) => {
          setMappingProducts((current) => ({ ...current, list: data.products }));
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  useEffect(() => {
    window.addEventListener('resize', setRender);
    return () => window.removeEventListener('resize', setRender);
  }, []);

  useLayoutEffect(() => {
    if (containerRef.current) {
      if (detailsHeaderRef.current) {
        setDetailsBodyHeight(containerRef.current.getBoundingClientRect().height - detailsHeaderRef.current.getBoundingClientRect().height);
      }

      if (mainListHeaderRef.current) {
        setMainListHeight(containerRef.current.getBoundingClientRect().height - mainListHeaderRef.current.getBoundingClientRect().height);
      }

      if (secondaryListHeaderRef.current) {
        setSecondaryListHeight(
          containerRef.current.getBoundingClientRect().height - secondaryListHeaderRef.current.getBoundingClientRect().height,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, selectedMapping, render, expanded, props.selectedTab]);

  useEffect(() => {
    if (id) {
      getMapping(id).call({
        ok: (data) => {
          setSelectedMapping(data);
        },
      });
    } else {
      setSelectedMapping(null);
    }
  }, [id, props.mappingsData.list]);

  const handleExpandedState = (item1: string) => {
    setExpanded((current) => (current.includes(item1) ? current.filter((item2) => item2 !== item1) : [...current, item1]));
  };

  const onTabSelect = (resolved: boolean) => {
    props.setSelectedTab(resolved ? 'resolved' : 'unresolved');
    props.setMappingsData((current) => {
      return {
        ...current,
        list: [],
        searchParams: {
          ...current.searchParams,
          state: resolved ? resolvedMappings : unresolvedMappings,
        },
      };
    });
  };

  return (
    <div ref={containerRef} className='w-full h-[calc(100vh-theme(spacing.20))] bg-zinc-50'>
      <Helmet title='Mappings' />
      <TagsOnboarding ref={tagsOnboardingRef} showSkip />
      {!selectedMapping ? (
        <ListView
          expanded={expanded}
          handleExpandedState={handleExpandedState}
          mainListHeaderRef={mainListHeaderRef}
          mainListHeight={mainListHeight}
          selectedTab={props.selectedTab}
          onTabSelect={onTabSelect}
          mappingProducts={mappingProducts}
          setMappingProducts={setMappingProducts}
          selectedCategory={props.selectedCategory}
          mappingsData={props.mappingsData}
          setMappingsData={props.setMappingsData}
          fetchNextMappingsPage={props.fetchNextMappingsPage}
        />
      ) : (
        <DetailsView
          expanded={expanded}
          handleExpandedState={handleExpandedState}
          detailsHeaderRef={detailsHeaderRef}
          mappingBodyHeight={detailsBodyHeight}
          secondaryListHeaderRef={secondaryListHeaderRef}
          secondaryListHeight={secondaryListHeight}
          mappingsData={props.mappingsData}
          setMappingsData={props.setMappingsData}
          fetchNextMappingsPage={props.fetchNextMappingsPage}
          selectedCategory={props.selectedCategory}
          selectedMapping={selectedMapping}
          setSelectedMapping={setSelectedMapping}
          mappingProducts={mappingProducts}
          setMappingProducts={setMappingProducts}
          reload={onTabSelect}
          tagsOnboardingRef={tagsOnboardingRef}
        />
      )}
    </div>
  );
};
