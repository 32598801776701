import { ManufacturingCycle, ProductModel, ProductWithAmount } from '../../../../../../../api';
import { useFormikContext } from 'formik';
import { SetProductsCount } from '../../../../components/Lists/SetProductsCount';
import { simplify } from '../../../../../shared';
import cn from 'classnames';

interface Props {
  reportTitle: string;
  onNext: () => void;
}

export const Step3 = (props: Props) => {
  const formik = useFormikContext<{
    cycle: ManufacturingCycle;
    products: (ProductWithAmount & { models: ProductModel[] })[];
  }>();

  return (
    <>
      <div className='flex flex-col gap-y-6 min-h-[calc(100vh_-_theme(spacing.20)_-_80px)]'>
        <>
          <div className='flex flex-col gap-y-2'>
            <div className='text-lg font-semibold'>Confirm or edit product quantities and production timeframe</div>
            <div>Choose a timeframe over which you want to forecast your impact, and specify production volumes.</div>
          </div>
          <div className='grid grid-cols-[1fr_1px_8fr] gap-x-6 h-full'>
            <div className='flex flex-col gap-y-6'>
              <div className='font-semibold'>Timeframe</div>
              <div className='flex flex-col gap-y-4'>
                {[
                  {
                    cycle: ManufacturingCycle.Year,
                  },
                  {
                    cycle: ManufacturingCycle.Quarter,
                  },
                  {
                    cycle: ManufacturingCycle.Month,
                  },
                ].map((item) => (
                  <button
                    key={item.cycle}
                    onClick={() => {
                      formik.setFieldValue('cycle', item.cycle);
                    }}
                    className={cn(
                      'p-4 border rounded-[8px] capitalize',
                      {
                        'bg-slate-100 border-brand font-semibold': formik.values.cycle === item.cycle,
                      },
                      {
                        'border-red-500': formik.errors.cycle,
                      },
                    )}
                  >
                    {item.cycle}
                  </button>
                ))}
              </div>
            </div>
            <div className='bg-zinc-200 h-full w-px'></div>
            <div className='flex flex-col gap-y-6'>
              <div className='font-semibold'>Products and volumes</div>
              <SetProductsCount />
            </div>
          </div>
          <div className='bg-white border-t fixed bottom-0 inset-x-0 flex justify-center'>
            <div className='px-12 py-4 flex justify-between w-full max-w-screen-xl'>
              <div className='flex items-center gap-x-4'>
                <div>Total volumes added:</div>
                {[
                  {
                    label: 'Products',
                    count: formik.values.products.length + formik.values.products.flatMap(({ models }) => models).length,
                  },
                  {
                    label: 'Units',
                    count:
                      formik.values.products.reduce((acc, { count }) => acc + count, 0) +
                      formik.values.products.flatMap(({ models }) => models).reduce((acc, { count }) => acc + count, 0),
                  },
                ].map((item, i) => (
                  <div key={i} className='flex items-center gap-x-1 p-2 pr-4 border rounded-full'>
                    <div className='text-[12px] text-brand px-[6px] py-[2px] bg-slate-100 rounded-full'>{simplify(item.count)}</div>
                    <div className='text-sm text-zinc-500'>{item.label}</div>
                  </div>
                ))}
              </div>

              <button
                className={cn(
                  'flex text-sm items-center gap-2.5 border-2 rounded-full px-4 py-1 font-semibold',
                  '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
                  'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
                )}
                onClick={async () => {
                  props.onNext();
                  await formik.submitForm();
                }}
              >
                Create report
              </button>
            </div>
          </div>
        </>
      </div>

      {/*<CreateReport dataFromCsv={false} reportType={ReportType.Forecast} reportTitle={props.reportTitle} onNext={props.onNext} />*/}
    </>
  );
};
