import cn from 'classnames';
import { useFormikContext } from 'formik';
import { RefObject, useEffect, useLayoutEffect, useReducer } from 'react';
import { GenericStepNode, ModellingChangeAction, ModellingPayload, ProductModelV3 } from '../../../../api';

interface Props {
  payload: ModellingPayload;
  scrollableRef: RefObject<HTMLDivElement>;
  fromStepRef: RefObject<HTMLDivElement>;
  toStepRef: RefObject<HTMLDivElement>;
  fromRef: RefObject<HTMLDivElement>;
  toRef: RefObject<HTMLDivElement>;
  toStep: GenericStepNode;
  production?: boolean;
  readOnlyMode: boolean;
  amount: string;
}

export const StepLink = (props: Props) => {
  const formik = useFormikContext<ProductModelV3>();
  const [, render] = useReducer((x) => x + 1, 0);
  const scrollableRef = props.scrollableRef.current!;

  useLayoutEffect(render, [formik.values, render]);

  useEffect(() => {
    window.addEventListener('resize', render);
    return () => window.removeEventListener('resize', render);
  }, []);

  if (!props.fromRef.current || !props.toRef.current || !props.fromStepRef.current || !props.toStepRef.current) {
    return <></>;
  }

  const from = props.fromRef.current.getBoundingClientRect();
  const to = props.toRef.current.getBoundingClientRect();
  const fromStep = props.fromStepRef.current.getBoundingClientRect();
  const toStep = props.toStepRef.current.getBoundingClientRect();
  const scrollable = scrollableRef.getBoundingClientRect();
  const deleted =
    props.payload.changes.find(({ id }) => id === props.toStep.id && !props.readOnlyMode)?.action === ModellingChangeAction.Deleted;

  if (fromStep.left === toStep.left) {
    return (
      <Vertical
        from={from}
        to={to}
        scrollableRef={scrollableRef}
        scrollable={scrollable}
        deleted={deleted}
        production={props.production}
        amount={props.amount}
      />
    );
  }

  if (fromStep.left > toStep.left) {
    return (
      <Backwards
        from={from}
        to={to}
        scrollableRef={scrollableRef}
        scrollable={scrollable}
        deleted={deleted}
        production={props.production}
        amount={props.amount}
      />
    );
  }

  return (
    <Forwards
      from={from}
      to={to}
      scrollableRef={scrollableRef}
      scrollable={scrollable}
      deleted={deleted}
      production={props.production}
      amount={props.amount}
    />
  );
};

const Forwards = (props: {
  from: DOMRect;
  to: DOMRect;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  deleted: boolean;
  production?: boolean;
  amount: string;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromRight = props.from.right + leftOffset;
  const toTop = props.to.top + topOffset;
  const toLeft = props.to.left + leftOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const width = props.to.left - props.from.right;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const straightWidth = width / 2 - cornerSize;

  return (
    <>
      <div
        className={cn(
          'dot absolute w-[5px] aspect-square rounded-full -translate-y-[2px]',
          props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900',
        )}
        style={{
          left: `${fromRight}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      <div
        className={cn(
          'arrow absolute w-0 h-0 border-t-[3px] border-b-[3px] border-l-[5px] border-transparent -translate-x-full -translate-y-[2.5px]',
          props.deleted ? 'border-l-zinc-300' : props.production ? 'border-l-rose-900' : 'border-l-cyan-900',
        )}
        style={{
          left: `${toLeft + 1}px`,
          top: `${toCenter}px`,
        }}
      ></div>
      <div
        className={cn(
          'corner-from absolute rounded-full rotate-45 border aspect-square',
          props.deleted ? 'border-zinc-300' : props.production ? 'border-rose-900' : 'border-cyan-900',
          {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromRight + straightWidth - cornerSize + 1}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      <div
        className={cn(
          'corner-to absolute rounded-full rotate-45 border aspect-square',
          props.deleted ? 'border-zinc-300' : props.production ? 'border-rose-900' : 'border-cyan-900',
          {
            'border-r-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-t-transparent border-r-transparent border-l-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${toLeft - straightWidth - cornerSize}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      <div
        className={cn('h-line-from absolute h-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          width: `${straightWidth + 1}px`,
          left: `${fromRight}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      <div
        className={cn('v-line absolute w-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${fromRight + width / 2}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>
      <div
        className={cn(
          'absolute z-10 bg-white rounded-md p-0.5 -translate-x-1/2 cursor-default',
          props.deleted ? 'text-zinc-300' : props.production ? 'text-rose-900' : 'text-cyan-900',
        )}
        style={{
          left: `${fromRight + width / 2}px`,
          top: `${Math.min(fromCenter, toCenter) + straightHeight / 2}px`,
        }}
      >
        {props.amount}
      </div>
      <div
        className={cn('h-line-to absolute h-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          width: `${straightWidth}px`,
          left: `${toLeft - straightWidth}px`,
          top: `${toCenter}px`,
        }}
      ></div>
    </>
  );
};

const Backwards = (props: {
  from: DOMRect;
  to: DOMRect;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  deleted: boolean;
  production?: boolean;
  amount: string;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromLeft = props.from.left + leftOffset;
  const toTop = props.to.top + topOffset;
  const toRight = props.to.right + leftOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const width = props.from.left - props.to.right;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const straightWidth = width / 2 - cornerSize;

  return (
    <>
      <div
        className={cn(
          'dot absolute w-[5px] aspect-square rounded-full -translate-y-[2px]',
          props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900',
        )}
        style={{
          left: `${fromLeft}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      <div
        className={cn(
          'arrow absolute w-0 h-0 border-t-[3px] border-b-[3px] border-r-[5px] border-transparent -translate-y-[2.5px]',
          props.deleted ? 'border-r-zinc-300' : props.production ? 'border-r-rose-900' : 'border-r-cyan-900',
        )}
        style={{
          left: `${toRight - 1}px`,
          top: `${toCenter}px`,
        }}
      ></div>
      <div
        className={cn(
          'corner-from absolute rounded-full rotate-45 border aspect-square',
          props.deleted ? 'border-zinc-300' : props.production ? 'border-rose-900' : 'border-cyan-900',
          {
            'border-r-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-t-transparent border-r-transparent border-l-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromLeft - straightWidth - cornerSize}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      <div
        className={cn(
          'absolute z-10 bg-white rounded-md p-0.5 -translate-x-1/2 cursor-default',
          props.deleted ? 'text-zinc-300' : props.production ? 'text-rose-900' : 'text-cyan-900',
        )}
        style={{
          left: `${fromLeft - straightWidth - cornerSize}px`,
          top: `${Math.min(fromCenter, toCenter) + straightHeight / 2}px`,
        }}
      >
        {props.amount}
      </div>
      <div
        className={cn(
          'corner-to absolute rounded-full rotate-45 border aspect-square',
          props.deleted ? 'border-zinc-300' : props.production ? 'border-rose-900' : 'border-cyan-900',
          {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${toRight + straightWidth - cornerSize + 1}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      <div
        className={cn('h-line-from absolute h-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          width: `${straightWidth}px`,
          left: `${fromLeft - straightWidth}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      <div
        className={cn('v-line absolute w-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${toRight + width / 2}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>
      <div
        className={cn('h-line-to absolute h-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          width: `${straightWidth + 1}px`,
          left: `${toRight}px`,
          top: `${toCenter}px`,
        }}
      ></div>
    </>
  );
};

const Vertical = (props: {
  from: DOMRect;
  to: DOMRect;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  deleted: boolean;
  production?: boolean;
  amount: string;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromRight = props.from.right + leftOffset;
  const toTop = props.to.top + topOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const width = 40;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const straightWidth = width / 2 - cornerSize;

  return (
    <>
      <div
        className={cn(
          'dot absolute w-[5px] aspect-square rounded-full -translate-y-[2px]',
          props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900',
        )}
        style={{
          left: `${fromRight}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      <div
        className={cn(
          'arrow absolute w-0 h-0 border-t-[3px] border-b-[3px] border-r-[5px] border-transparent translate-x-[4px] -translate-y-[2.5px]',
          props.deleted ? 'border-r-zinc-300' : props.production ? 'border-r-rose-900' : 'border-r-cyan-900',
        )}
        style={{
          left: `${fromRight - 1}px`,
          top: `${toCenter}px`,
        }}
      ></div>
      <div
        className={cn(
          'corner-from absolute rounded-full rotate-45 border aspect-square',
          props.deleted ? 'border-zinc-300' : props.production ? 'border-rose-900' : 'border-cyan-900',
          {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromRight + straightWidth - cornerSize + 1}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      <div
        className={cn(
          'absolute z-10 bg-white rounded-md p-0.5 -translate-x-1/2 cursor-default',
          props.deleted ? 'text-zinc-300' : props.production ? 'text-rose-900' : 'text-cyan-900',
        )}
        style={{
          left: `${fromRight + straightWidth + cornerSize}px`,
          top: `${Math.min(fromCenter, toCenter) + straightHeight / 2}px`,
        }}
      >
        {props.amount}
      </div>
      <div
        className={cn(
          'corner-to absolute rounded-full rotate-45 border aspect-square',
          props.deleted ? 'border-zinc-300' : props.production ? 'border-rose-900' : 'border-cyan-900',
          {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromRight + straightWidth - cornerSize + 1}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>
      <div
        className={cn('h-line-from absolute h-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          width: `${straightWidth + 1}px`,
          left: `${fromRight}px`,
          top: `${fromCenter}px`,
        }}
      ></div>
      <div
        className={cn('v-line absolute w-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${fromRight + width / 2}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>
      <div
        className={cn('h-line-to absolute h-px', props.deleted ? 'bg-zinc-300' : props.production ? 'bg-rose-900' : 'bg-cyan-900')}
        style={{
          width: `${straightWidth + 1 - 4}px`,
          left: `${fromRight + 4}px`,
          top: `${toCenter}px`,
        }}
      ></div>
    </>
  );
};
