import { addMonths, addYears, format } from 'date-fns';
import { Timeframe } from './types';

export const getDatesForTimeframe = (value: Timeframe) => {
  switch (value) {
    case Timeframe.Month:
      return { from: addMonths(new Date(), -1), to: new Date() };
    case Timeframe.Quarter:
      return { from: addMonths(new Date(), -3), to: new Date() };
    case Timeframe.HalfYear:
      return { from: addMonths(new Date(), -6), to: new Date() };
    case Timeframe.Year:
      return { from: addYears(new Date(), -1), to: new Date() };
  }
};

export const formatTimeframe = (value: Timeframe, options?: { compact?: boolean }) =>
  formatPeriod(getDatesForTimeframe(value).from, getDatesForTimeframe(value).to, options);

export const formatPeriod = (from: Date, to: Date, options?: { compact?: boolean }) =>
  `${format(from, `MMM dd${from.getFullYear() === to.getFullYear() ? '' : options?.compact ? ', yy' : ', yyyy'}`)}${
    options?.compact ? '-' : ' — '
  }${format(to, `MMM dd, ${from.getFullYear() === to.getFullYear() ? 'yyyy' : options?.compact ? 'yy' : 'yyyy'}`)}`;
