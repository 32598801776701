import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { convertLocalToUTCDate, months, years } from './utils';
import { FieldProps } from 'formik';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import sub from 'date-fns/sub';
import cn from 'classnames';
import './styles.css';

interface Props {
  model: FieldProps<string | undefined>;
}

export const ExactDatePicker = (props: Props) => {
  return (
    <div className='flex gap-8 items-center'>
      <div className='flex gap-2 items-center'>
        <div className='text-sm'>From</div>
        <div
          className={cn(
            'double-datepicker flex relative border rounded-full',
            props.model.meta.error ? 'border-f focus:ring-focusRingError' : '',
          )}
        >
          <DatePicker
            selected={props.model.field.value ? new Date(props.model.field.value) : null}
            calendarStartDay={1}
            dateFormat='dd/MM/yyyy'
            maxDate={sub(new Date(), { years: 1, days: 1 })}
            placeholderText=''
            className={cn('py-1 rounded-full w-32 pl-4 text-sm text-dark', props.model.meta.error && 'border-f focus:ring-focusRingError')}
            onChange={(date) => props.model.form.setFieldValue(props.model.field.name, convertLocalToUTCDate(date))}
            selectsStart
            showYearDropdown
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className='flex w-full justify-between items-center px-3 py-2'>
                <FontAwesomeIcon
                  className='hover:cursor-pointer hover:text-brand text-base'
                  icon={regular('chevron-left')}
                  onClick={() => !prevMonthButtonDisabled && decreaseMonth()}
                />
                <select
                  className='focus-visible:ring-0 font-semibold text-base rounded-md hover:cursor-pointer text-center'
                  value={getYear(date)}
                  onChange={({ target: { value } }) => {
                    return changeYear(getYear(new Date(value)));
                  }}
                >
                  {years(sub(new Date(), { years: 10 }).getFullYear(), new Date().getFullYear() + 1).map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <select
                  className='focus-visible:ring-0 font-semibold text-base rounded-md hover:cursor-pointer text-center'
                  value={months[getMonth(date)]}
                  onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon
                  className='hover:cursor-pointer hover:text-brand text-base'
                  icon={regular('chevron-right')}
                  onClick={() => !nextMonthButtonDisabled && increaseMonth()}
                />
              </div>
            )}
          />
          {props.model.field.value && (
            <div className='absolute right-3 hover:cursor-pointer translate-y-1/2 flex items-center justify-center'>
              <FontAwesomeIcon
                onClick={() => props.model.form.setFieldValue('startDate', null)}
                className='pl-[18%] text-brandDark text-sm'
                icon={regular('xmark')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
