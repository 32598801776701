import { Details as FEDetails } from '../../components/Details';
import { Lens, ProductReport } from '../../../../../../../api';

interface Props {
  data: ProductReport;
}

export const Details = (props: Props) => {
  return <FEDetails lens={Lens.Consumer} data={props.data}></FEDetails>;
};
