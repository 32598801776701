import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import first from 'lodash/first';
import last from 'lodash/last';
import { useEffect, useState } from 'react';
import { Methodology, SectionId } from '../Prototype/types';
import { PortfolioDashboard } from '../../../api';
import { Popover } from '../../../components/Popover';
import { PopoverFrame } from '../Prototype/components/PopoverFrame';

interface Props {
  methodology: Methodology;
  setMethodology: (methodology: Methodology) => void;
  sections: { id: SectionId; label: string; disabled: boolean }[];
  sectionRefs: Map<SectionId, HTMLDivElement>;
  portfolio: PortfolioDashboard;
}

export const Sidebar = (props: Props) => {
  const [sectionsExpanded, setSectionsExpanded] = useState(true);

  return (
    <div className='z-20 sticky top-20 w h-[calc(100vh-80px)] flex flex-col gap-4 px-5 py-6 bg-white border-r border-zinc-200 min-w-56'>
      <div className='flex flex-col gap-2'>
        <button
          type='button'
          className='flex justify-between items-center p-1.5 pr-3 rounded-lg bg-slate-100'
          onClick={() => setSectionsExpanded((current) => !current)}
        >
          <div className='flex items-center gap-2'>
            <div className='flex justify-center items-center rounded-sm size-7 bg-white'>
              <FontAwesomeIcon icon={light('chart-line')} className='text-brand' />
            </div>
            <div className='text-zinc-800 font-semibold'>Dashboard</div>
          </div>
          <FontAwesomeIcon icon={sectionsExpanded ? regular('chevron-down') : regular('chevron-right')} className='text-zinc-500' />
        </button>
        {sectionsExpanded && <SectionButtons {...props} />}
      </div>
      <div className='h-px bg-zinc-200 shrink-0' />
      <div className='flex flex-col gap-4'>
        <div className='text-zinc-800 font-semibold'>Settings</div>
        <div className='flex flex-col gap-3 text-zinc-700'>
          <Popover
            placement='right-start'
            offset={2}
            offsetCross={-46}
            content={({ close }) => (
              <PopoverFrame className='whitespace-nowrap gap-2 p-4'>
                <div className='font-semibold text-zinc-800'>Methodology</div>
                <div className='h-px shrink-0 bg-zinc-200' />
                {[Methodology.Production, Methodology.Consumer, Methodology.Ghg].map((methodology) => (
                  <button
                    key={methodology}
                    type='button'
                    className={cn('flex items-center gap-2 rounded-lg px-2 py-1', {
                      'text-zinc-700 bg-indigo-50': methodology === props.methodology,
                    })}
                    onClick={() => {
                      props.setMethodology(methodology);
                      close();
                    }}
                  >
                    {
                      {
                        [Methodology.Production]: 'PEF — Production impact',
                        [Methodology.Consumer]: 'PEF — Consumer impact',
                        [Methodology.Ghg]: 'GHG Protocol',
                      }[methodology]
                    }
                  </button>
                ))}
              </PopoverFrame>
            )}
          >
            {({ open }) => (
              <button
                type='button'
                className={cn('flex items-center gap-2 pr-2 rounded-lg', {
                  'bg-indigo-50 text-zinc-700': open,
                })}
              >
                <div className='size-8 shrink-0 flex justify-center items-center bg-indigo-50 rounded-lg'>
                  <FontAwesomeIcon icon={light('book-open-cover')} className='text-brandDarkPurple2' />
                </div>
                {
                  {
                    [Methodology.Production]: 'PEF — Production',
                    [Methodology.Consumer]: 'PEF — Consumer',
                    [Methodology.Ghg]: 'GHG Protocol',
                  }[props.methodology]
                }
              </button>
            )}
          </Popover>
        </div>
      </div>
    </div>
  );
};

const SectionButtons = (props: Props) => {
  const [scrollTop, setScrollTop] = useState(document.documentElement.scrollTop);

  useEffect(() => {
    const onScroll = () => setScrollTop(document.documentElement.scrollTop);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className='flex flex-col'>
      {props.sections.map((section) => {
        return (
          <button
            key={section.id}
            type='button'
            className={cn(
              'p-2 rounded',
              (() => {
                if (scrollTop === 0) {
                  return first(props.sections)!.id;
                }

                if (scrollTop + window.innerHeight === document.documentElement.scrollHeight) {
                  return last(props.sections)!.id;
                }

                let current = { distance: Infinity, id: null as SectionId | null };

                if (props.sectionRefs.size > 0) {
                  props.sections.forEach(({ id }) => {
                    const ref = props.sectionRefs.get(id);
                    let distance = Infinity;

                    if (ref) {
                      distance = Math.min(
                        Math.abs(ref.getBoundingClientRect().top - window.innerHeight / 2.0),
                        Math.abs(ref.getBoundingClientRect().bottom - window.innerHeight / 2.0),
                      );
                    }

                    if (distance < current.distance) {
                      current = { distance, id };
                    }
                  });
                }

                return current.id;
              })() === section.id
                ? 'text-zinc-700 bg-slate-100'
                : section.disabled
                ? 'text-zinc-400'
                : 'text-zinc-500',
            )}
            onClick={() => {
              props.sectionRefs.get(section.id)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
          >
            {section.label}
          </button>
        );
      })}
    </div>
  );
};
