import { simplify, roundToLong, roundToShort } from '../../shared';
import { ImpactStageMatrix, LifecycleStageImpact } from '../../../../api';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getImpactIcon } from '../../../../icons/Impact';

interface Props {
  totalImpactPoints: number;
  impactStageMatrix: ImpactStageMatrix[];
  lifecycleStageImpacts: LifecycleStageImpact[];
}

export const Top3ImpactsAndContributors = (props: Props) => {
  return (
    <div className='grid grid-cols-[1fr_1px_1fr] gap-x-3 border rounded-2xl bg-white'>
      <div className='p-6'>
        <div className='flex items-center gap-x-2 font-semibold pb-3'>
          <div className='size-8 flex items-center justify-center text-brand bg-slate-50 rounded-md border'>
            {getImpactIcon('impact-climate-change')}
          </div>
          Top 3 Impact Categories
        </div>
        <table className='w-full table-fixed'>
          <thead className='uppercase text-xs text-zinc-500'>
            <tr>
              <th className='p-2 pl-0'>category</th>
              <th className='p-2 pl-0'>physical impact</th>
              <th className='p-2 pl-0'>contribution</th>
            </tr>
          </thead>
          <tbody>
            {props.impactStageMatrix.slice(0, 3).map((impact, i) => (
              <tr key={i}>
                <td title={impact.impactName} className='p-2 pl-0 truncate'>
                  {impact.impactName}
                </td>
                <td className='p-2 pl-0' title={roundToLong(impact.physicalValue)}>
                  {simplify(impact.physicalValue)} <span className='uppercase text-xs text-zinc-500'>{impact.unit}</span>
                </td>
                <td className='p-2 pl-0 font-semibold' title={roundToLong(impact.absSharePercent)}>
                  {roundToShort(impact.absSharePercent)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='border-l my-6' />
      <div className='p-6'>
        <div>
          <div className='flex items-center gap-x-2 font-semibold pb-3'>
            <div className='size-8 flex items-center justify-center bg-[#FFF7ED] text-[#7C2D12] rounded-md border'>
              <FontAwesomeIcon icon={duotone('cauldron')} />
            </div>
            Top 3 Process Contributors
          </div>
          <table className='w-full table-fixed'>
            <thead className='uppercase text-xs text-zinc-500'>
              <tr>
                <th className='p-2 pl-0'>Process</th>
                <th className='p-2 pl-0'>type</th>
                <th className='p-2 pl-0'>contribution</th>
              </tr>
            </thead>
            <tbody>
              {props.lifecycleStageImpacts
                .flatMap((stage) =>
                  stage.components.flatMap((component) =>
                    component.components.flatMap((contributor) => ({
                      name: contributor.name,
                      type: component.name,
                      share: contributor.impactSharePercent,
                    })),
                  ),
                )
                .sort((a, b) => b.share - a.share)
                .slice(0, 3)
                .map((contributor, i) => (
                  <tr key={i}>
                    <td className='p-2 pl-0 truncate' title={contributor.name}>
                      {contributor.name}
                    </td>
                    <td className='p-2 pl-0 truncate' title={contributor.type}>
                      {contributor.type}
                    </td>
                    <td className='p-2 pl-0 truncate font-semibold' title={roundToShort(contributor.share).toString()}>
                      {roundToShort(contributor.share)}%
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
