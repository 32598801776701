import { useEffect, useRef } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import { ProductionOverview } from './Pef/Overview';
import { GhgOverview } from './Ghg/Overview';
import { ReportWarning } from '../../../../../components/ReportWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Report as GhgReport } from './Ghg';
import { Report as PefReport } from './Pef';
import { ManufacturingSkeletonLoader } from '../../components/ManufacturingSkeletonLoader';
import { getGhgHistoricalReport, getManufacturingTargets, getPefHistoricalReport, WarningType } from '../../../../../api';
import { useApiQuery } from '../../../../../hooks/useApiQuery';

export const Report = () => {
  const params = useParams();
  const lens = params['*']?.split('/')[0] === 'production' ? 'pef' : 'ghg';

  const pefLoaded = useRef(false);
  const ghgLoaded = useRef(false);
  const targetsLoaded = useRef(false);

  const pefReport = useApiQuery(getPefHistoricalReport(params.id!), { cancel: lens !== 'pef' || pefLoaded.current });
  const ghgReport = useApiQuery(getGhgHistoricalReport(params.id!), { cancel: lens !== 'ghg' || ghgLoaded.current });

  const targetsResponse = useApiQuery(getManufacturingTargets());

  useEffect(() => {
    if (targetsResponse.data && !targetsLoaded.current) {
      targetsLoaded.current = true;
    }
    if (pefReport.data && !pefLoaded.current) {
      pefLoaded.current = true;
    }
    if (ghgReport.data && !ghgLoaded.current) {
      ghgLoaded.current = true;
    }
  }, [pefReport.data, ghgReport.data, targetsResponse.data]);

  if ((lens === 'pef' && !pefReport.data) || (lens === 'ghg' && !ghgReport.data) || !targetsResponse.data) {
    return <ManufacturingSkeletonLoader />;
  }

  return (
    <Routes>
      <Route
        path='production/*'
        element={
          <Routes>
            <Route
              path='overview'
              element={
                <ProductionOverview
                  warning={
                    pefReport.data?.warnings &&
                    pefReport.data.warnings.length > 0 &&
                    pefReport.data.warnings.find(({ id }) => id === WarningType.ScreeningLcaNotMeet) && (
                      <ReportWarning
                        theme='red'
                        icon={
                          <div className='size-9 bg-rose-500 rounded-full flex items-center justify-center p-2'>
                            <FontAwesomeIcon className='size-5 text-white' icon={solid('percent')} />
                          </div>
                        }
                        title='Preliminary Historical Report - Screening LCA threshold not met'
                        text='Not enough first-party data was provided for your products to meet the screening LCA threshold level (first-party data < 100%). A lot of defaults were automatically made by the system and as such, all results presented on this page hold a lot of uncertainty and should be interpreted with caution.'
                      />
                    )
                  }
                  data={pefReport.data!}
                  targets={targetsResponse.data!}
                />
              }
            />
            <Route path='*' element={<PefReport data={pefReport.data!} targets={targetsResponse.data!} />} />
          </Routes>
        }
      />
      <Route
        path='ghg/*'
        element={
          <Routes>
            <Route path='overview' element={<GhgOverview data={ghgReport.data!} />} />
            <Route path='*' element={<GhgReport data={ghgReport.data!} />} />
          </Routes>
        }
      />
      <Route path='*' element={<Navigate to='production/overview' replace />} />
    </Routes>
  );
};
