import { useId } from '@floating-ui/react-dom-interactions';
import { duotone, light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import { PropsWithChildren, RefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import MultiRef from 'react-multi-ref';
import { OptionProps, components } from 'react-select';
import ReactSelectAsync from 'react-select/async';
import {
  ConsumptionNode,
  ConsumptionPreparationNode,
  Entity,
  ImpactDelta,
  Methodology,
  ModellingPayload,
  NodeType,
  OutputType,
  ProcessType,
  ProductModelV3,
  ProductionProcess,
  StaticEntity,
  StepInput,
  StepOutput,
  searchIngredientsV3,
} from '../../../../../api';
import { InputV3 } from '../../../../../components/InputV3';
import { ModalForm, ModalFormSaveCallback } from '../../../../../components/ModalForm';
import { Components, SelectV3 } from '../../../../../components/SelectV3';
import { Toggle } from '../../../../../components/Toggle';
import { UnitInputV3 } from '../../../../../components/UnitInputV3';
import { useEffectOnNextRenders } from '../../../../../hooks/useEffectOnNextRenders';
import { useLists } from '../../../../../hooks/useLists';
import { useProfile } from '../../../../../hooks/useProfile';
import { CardBadge } from '../Badge';
import { InteractiveImpactBadge } from '../InteractiveImpactBadge';
import { OriginalAwareDiffedItem, OriginalAwareField, joinWithDiff } from '../OriginalAwareField';
import {
  StepInputAmountSideEffect,
  UpdateSideEffects,
  getCookedAmount,
  getIngredients,
  getInputNode,
  getStepsFromAll,
  newOutputId,
  newStepId,
  stepValidationSchema,
} from '../dataModel';
import { useInteractiveImpact } from '../useInteractiveImpact';
import { OriginalAwareProvider } from '../useOriginalAware';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  parentNode: ConsumptionNode;
  data?: ConsumptionPreparationNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<ConsumptionPreparationNode, { sideEffects: UpdateSideEffects }>;
}>;

export const StepDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const lists = useLists();
  const [outputRefs] = useState(() => new MultiRef<number, OutputApi>());
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm
      size='wide'
      formRef={formRef}
      title={props.data ? `Editing ${props.data.displayName}` : 'New preparation step'}
      body={
        <Body
          payload={props.payload}
          parentNode={props.parentNode}
          productFormik={formik}
          formRef={formRef}
          outputRefs={outputRefs}
          edit={!!props.data}
          readOnlyMode={props.readOnlyMode}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>What is the consumer required to do before he can eat/drink your product?</div>
          <div>
            Select a process, and specify whether it’s the last step involved. You’ll then need to specify what the inputs to this process
            are (ie. what he’s supposed to be boiling, frying or microwaving…), and if this isn’t the last step of the preparation for the
            consumer, you’ll also need an output!
          </div>
        </div>
      }
      emptyData={{
        id: newStepId(),
        displayName: '',
        type: NodeType.ConsumptionPreparation,
        flagged: false,
        process: undefined as any as ProductionProcess,
        inputs: [{} as any as StepInput],
        outputs: new Array<StepOutput>(),
        finalStep: false,
      }}
      data={props.data}
      validationSchema={stepValidationSchema({ packaging: false, productFormik: formik, lists })}
      getCustomErrors={(errors) => [
        { message: 'Add at least one intermediate product.', expected: 'needsAtLeastOneIntermediate', actual: errors.outputs },
      ]}
      entityName='step'
      onSave={(params) => {
        props.onSave({
          ...params,
          sideEffects: { stepInputAmounts: Array.from(outputRefs.map.values()).flatMap((ref) => ref.getSideEffects()) },
        });
      }}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  parentNode: ConsumptionNode;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  outputRefs: MultiRef<number, OutputApi>;
  edit: boolean;
  readOnlyMode: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const formik = useFormikContext<ConsumptionPreparationNode>();
  const lists = useLists();

  const { payload, parentNode, productFormik } = props;
  const allowExternalIngredients =
    useProfile().selectedWorkspace.methodology.type === Methodology.FoundationEarth && payload.product.rawToCookedRatio === 1;
  const outputsFinalProduct = formik.values.finalStep;
  const defaultUnit = lists.foodTypes.find(({ type }) => type === payload.product.foodType.type)!.unit;
  const inputOptions = [
    { ...payload.product, amountValue: payload.product.amount.value },
    ...parentNode.steps
      .filter(({ id }) => id !== formik.values.id)
      .flatMap(({ outputs }) => outputs)
      .filter(({ outputType: { type } }) => type === OutputType.IntermediateProduct),
    ...getIngredients(props.productFormik),
  ].map((node) => ({ ...node, amountValue: node.amount!.value }));

  useInteractiveImpact<ConsumptionPreparationNode>({
    payload,
    productFormik,
    parentNode,
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  useEffectOnNextRenders(() => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);
      newValues.displayName = '';
      delete newValues.index;
      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.process?.id]);

  useEffectOnNextRenders(() => {
    formik.setValues((oldValues) => {
      const newValues = cloneDeep(oldValues);

      if (outputsFinalProduct) {
        newValues.outputs = newValues.outputs.filter(({ outputType }) => outputType?.type !== OutputType.IntermediateProduct);
      }

      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputsFinalProduct]);

  return (
    <OriginalAwareProvider nodeId={formik.values.id} payload={payload}>
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-4'>
          <Field name='process'>
            {(model: FieldProps<Entity>) => (
              <div className='flex flex-col gap-1'>
                <div className='pl-1.5'>Process</div>
                <div className='w-80'>
                  <SelectV3<Entity>
                    autoFocus
                    model={model}
                    disabled={props.edit}
                    menuPortalTarget={props.formRef.current}
                    options={lists.processes
                      .filter(({ type }) => type === ProcessType.Household)
                      .filter(({ id }) => !lists.packagingTypes.flatMap(({ packagingProcesses }) => packagingProcesses).includes(id))
                      .map((item) => ({
                        ...item,
                        duration: undefined,
                      }))}
                  />
                </div>
              </div>
            )}
          </Field>
          <OriginalAwareField name='finalStep'>
            {(model: FieldProps) => (
              <div className='flex flex-col gap-4 text-neutral-900'>
                <div className='flex flex-col gap-1'>
                  <div className='font-semibold'>Final preparation step</div>
                  <div>Please let us know if this is the last preparation step before consumers can eat the product.</div>
                </div>
                <Toggle model={model} />
              </div>
            )}
          </OriginalAwareField>
        </div>
        {(() => {
          const defaultDuration = lists.processes
            .filter(({ type }) => type === ProcessType.Household)
            .filter(({ id }) => !lists.packagingTypes.flatMap(({ packagingProcesses }) => packagingProcesses).includes(id))
            .find(({ id }) => id === formik.values.process?.id)?.duration;

          return (
            defaultDuration &&
            formik.values.process && (
              <div className='flex flex-col gap-2 rounded-2xl bg-[#F5F7FA] px-3 py-4 text-dark'>
                <div className='text-lg font-semibold'>Optional fields</div>
                <div className='bg-[#DACEFD50] rounded-lg flex items-center gap-3 p-3'>
                  <FontAwesomeIcon className='text-lg' icon={regular('info-circle')} />
                  <div>
                    Optional fields make your impact assessment more accurate using the primary data entered. Leaving these empty means
                    secondary data assumptions is used instead.
                  </div>
                </div>
                <div className='flex flex-col items-start gap-1 mt-2'>
                  Recommended duration
                  <OriginalAwareField name='process.duration'>
                    {(model: FieldProps) => (
                      <UnitInputV3 placeholder={defaultDuration.toString()} unit={{ options: [{ id: '', name: 'min' }] }} model={model} />
                    )}
                  </OriginalAwareField>
                </div>
              </div>
            )
          );
        })()}
        <div className='border-b border-neutral-300' />
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-2 text-lg text-neutral-900'>
            <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
            <div className='font-semibold'>Inputs</div>
          </div>
          <FieldArray
            name='inputs'
            render={(arrayModel) => (
              <>
                {joinWithDiff(
                  formik.values.inputs,
                  getStepsFromAll(payload.product.nodes).find(({ id }) => id === formik.values.id)?.inputs,
                )
                  .filter(({ deleted }) => !deleted)
                  .map((item, i) => (
                    <div key={item.node.id ?? i} className='flex gap-2'>
                      <Field name={`${arrayModel.name}.${item.index.current}.id`}>
                        {(model: FieldProps<string>) => (
                          <div className='flex flex-col gap-1'>
                            {i === 0 && <div className='pl-1.5'>Preparation step input</div>}
                            <div className='w-80'>
                              <InputSelect
                                allowExternal={allowExternalIngredients}
                                disabled={props.edit && !item.added && !item.deleted}
                                index={item.index.current}
                                model={model}
                                arrayModel={arrayModel}
                                value={item.node}
                                options={inputOptions}
                                {...props}
                              />
                            </div>
                          </div>
                        )}
                      </Field>
                      <OriginalAwareField
                        itemName={{
                          arrayModel,
                          field: 'amountValue',
                          ...item,
                        }}
                      >
                        {(model: FieldProps<number>) => (
                          <div className='flex flex-col gap-1'>
                            {i === 0 && <div className='pl-1.5'>Amount</div>}
                            <div className='w-44'>
                              <UnitInputV3
                                model={model}
                                unit={{
                                  options: (
                                    (() => {
                                      const unit = getInputNode(item.node, props.productFormik, payload, props.readOnlyMode)?.amount?.unit;
                                      return unit ? [unit] : [];
                                    }) as () => Entity[]
                                  )(),
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </OriginalAwareField>
                      <div className='flex flex-col gap-1'>
                        {i === 0 && <div>&nbsp;</div>}
                        <button
                          type='button'
                          className='flex bg-[#F5F7FA] text-zinc-400 hover:text-red-500 rounded-lg justify-center items-center aspect-square h-8 p-0.5'
                          onClick={arrayModel.handleRemove(item.index.current)}
                        >
                          <FontAwesomeIcon size='lg' icon={regular('trash-can')} />
                        </button>
                      </div>
                      <div className='flex flex-col gap-1'>
                        {i === 0 && <div>&nbsp;</div>}
                        <div className='mt-2'>
                          <CardBadge item={item} />
                        </div>
                      </div>
                    </div>
                  ))}
                {(formik.values.inputs.length < inputOptions.length || allowExternalIngredients) && (
                  <Field name={arrayModel.name}>
                    {(model: FieldProps) => (
                      <button
                        type='button'
                        className={cn(
                          'pl-1.5 self-start flex items-center gap-2 hover:text-brand',
                          model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                        )}
                        onClick={arrayModel.handlePush({})}
                      >
                        <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                        <div className='font-semibold'>Add input</div>
                      </button>
                    )}
                  </Field>
                )}
              </>
            )}
          />
        </div>
        <div className='flex flex-col gap-4'>
          <div className='border-t pt-6 flex items-center gap-2 text-lg text-neutral-900'>
            <FontAwesomeIcon icon={regular('arrow-down-to-bracket')} />
            <div className='font-semibold'>Outputs</div>
          </div>
          <FieldArray
            name='outputs'
            render={(arrayModel) => (
              <>
                {outputsFinalProduct && (
                  <div className='flex gap-2'>
                    <div className='flex flex-col gap-1'>
                      <div className='pl-1.5'>Output type</div>
                      <div className='w-44'>
                        <SelectV3
                          value={{ id: 'final_product', name: 'Final product' }}
                          options={[]}
                          menuPortalTarget={props.formRef.current}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                      <div className='pl-1.5'>Name</div>
                      <div className='w-36 flex flex-col'>
                        <InputV3 value='Ready to eat' disabled />
                      </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                      <div className='pl-1.5'>Quantity</div>
                      <div className='w-40'>
                        <UnitInputV3
                          value={getCookedAmount(payload, parentNode, formik).value}
                          unit={{ options: [getCookedAmount(payload, parentNode, formik).unit] }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
                {joinWithDiff(
                  formik.values.outputs,
                  getStepsFromAll(payload.product.nodes).find(({ id }) => id === formik.values.id)?.outputs,
                )
                  .filter(({ deleted }) => !deleted)
                  .map((item, index) => (
                    <Output
                      key={item.node.id}
                      ref={props.outputRefs.ref(index)}
                      item={item}
                      arrayModel={arrayModel}
                      outputsFinalProduct={outputsFinalProduct}
                      {...props}
                    />
                  ))}
                <div className='pl-1.5 flex flex-col gap-2'>
                  {!outputsFinalProduct && (
                    <Field name={arrayModel.name}>
                      {(model: FieldProps) => (
                        <button
                          type='button'
                          className={cn(
                            'self-start flex items-center gap-2 hover:text-brand',
                            model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                          )}
                          onClick={arrayModel.handlePush({
                            id: newOutputId(),
                            outputType: lists.outputTypes.find(({ type }) => type === OutputType.IntermediateProduct),
                            amount: {
                              unit: defaultUnit,
                            },
                          })}
                        >
                          <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                          <div className='font-semibold'>Add output</div>
                        </button>
                      )}
                    </Field>
                  )}
                </div>
              </>
            )}
          />
        </div>
      </div>
    </OriginalAwareProvider>
  );
};

const InputSelect = (
  props: BodyProps & {
    allowExternal: boolean;
    disabled: boolean;
    index: number;
    model: FieldProps<string>;
    arrayModel: FieldArrayRenderProps;
    value: StepInput;
    options: StepInput[];
  },
) => {
  const formik = useFormikContext<ConsumptionPreparationNode>();

  const { payload, productFormik, allowExternal, index, model, arrayModel } = props;
  const productId = payload.product.id;
  const value = props.value.id ? props.value : null;
  const asyncPlaceholderOption = useRef({ id: 'asyncPlaceholder', amountValue: 0 }).current;
  const asyncNoResultsOption = useRef({ id: 'asyncNoResults', amountValue: 0 }).current;
  const externalIngredientsLabel = 'Ingredients > External';
  const syncOptions = [
    {
      label: 'Final Product',
      options: props.options.filter(({ id }) => id === productId),
    },
    {
      label: `Ingredients${allowExternal ? ' > In-Pack' : ''}`,
      options: props.options.filter(({ id }) => id !== productId),
    },
  ];

  return (
    <ReactSelectAsync
      isDisabled={props.disabled}
      isClearable
      placeholder='Choose…'
      loadingMessage={() => null}
      noOptionsMessage={() => 'No matches found…'}
      getOptionValue={({ id }) => id}
      getOptionLabel={(option) => {
        if (option === asyncPlaceholderOption) {
          return 'Type for suggestions…';
        }

        if (option === asyncNoResultsOption) {
          return 'No matches found…';
        }

        return getInputNode(option, productFormik, payload, props.readOnlyMode)!.name;
      }}
      isOptionDisabled={(option) => [asyncPlaceholderOption, asyncNoResultsOption].includes(option)}
      filterOption={({ value, label }, input) =>
        [asyncPlaceholderOption, asyncNoResultsOption].some(({ id }) => id === value) ||
        (!formik.values.inputs.some((input) => input.id === value) && label.toLowerCase().includes(input.toLowerCase()))
      }
      value={value}
      onChange={(value, meta) => {
        if (meta.action === 'select-option') {
          arrayModel.replace(index, value);
        }

        if (meta.action === 'clear') {
          arrayModel.replace(index, {});
        }
      }}
      defaultOptions
      loadOptions={(input, callback) => {
        if (allowExternal && input.length >= 1) {
          searchIngredientsV3(input, { consumption: true }).ok(({ sustained }) => {
            const results = sustained.filter(({ id }) => !getIngredients(productFormik).some(({ ingredient }) => ingredient.id === id));
            callback([
              ...syncOptions,
              {
                label: externalIngredientsLabel,
                options:
                  results.length > 0
                    ? results.map((item) => ({ ...item, amountValue: null as any as number, item }))
                    : [asyncNoResultsOption],
              },
            ]);
          });
        } else {
          callback([
            ...syncOptions,
            ...(allowExternal
              ? [
                  {
                    label: externalIngredientsLabel,
                    options: [asyncPlaceholderOption],
                  },
                ]
              : []),
          ]);
        }
      }}
      menuPortalTarget={props.formRef.current}
      formatGroupLabel={(data) => (
        <div className='flex items-center gap-1.5'>
          <div>{data.label}</div>
        </div>
      )}
      components={{
        ...Components,
        Option: InputSelectOption,
      }}
      {...{ model, productId }}
    />
  );
};

export const InputSelectOption = (props: OptionProps<StepInput>) => {
  const productId: string = (props.selectProps as any).productId;
  return (
    <components.Option
      {...props}
      getStyles={() => ({
        ...props.getStyles('option', props),
        padding: '',
        color: '',
        backgroundColor: '',
        ':active': {},
        ...(props.isDisabled ? {} : { cursor: 'pointer' }),
      })}
      className={cn('px-2 py-1', {
        'text-neutral-400': props.isDisabled,
        'bg-neutral-100': props.isFocused,
      })}
    >
      <div className='flex items-center gap-4 px-2 py-1 rounded-xl'>
        {!props.isDisabled && (
          <div
            className={cn('flex justify-center items-center rounded-md h-7 aspect-square', {
              'bg-green-50 text-green-900': productId !== props.data.id,
              'bg-purple-50 text-purple-900': productId === props.data.id,
            })}
          >
            <FontAwesomeIcon icon={productId === props.data.id ? duotone('box-circle-check') : duotone('carrot')} />
          </div>
        )}
        <div>{props.children}</div>
      </div>
    </components.Option>
  );
};

interface OutputApi {
  getSideEffects: () => StepInputAmountSideEffect[];
}

const Output = forwardRef<
  OutputApi,
  {
    item: OriginalAwareDiffedItem<StepOutput>;
    outputsFinalProduct: boolean;
    arrayModel: FieldArrayRenderProps;
  } & BodyProps
>((props, ref) => {
  const formik = useFormikContext<ConsumptionPreparationNode>();
  const lists = useLists();

  const { item, arrayModel } = props;
  const output = item.node;
  const index = item.index.current;
  const originalAmountValue = useRef(output.amount?.value);
  const updateNextStepInputCheckboxId = useId();
  const [updateNextStepInput, setUpdateNextStepInput] = useState(true);

  const getSingleNextStepUsingOutput = () => {
    const inputs = props.parentNode.steps
      .flatMap((step) => step.inputs.map((input) => ({ step, input })))
      .filter(({ input: { id } }) => id === output.id);
    return inputs.length === 1 ? inputs[0].step : undefined;
  };

  const canUpdateNextStepInput =
    props.edit &&
    typeof originalAmountValue.current === 'number' &&
    typeof output.amount?.value === 'number' &&
    originalAmountValue.current !== output.amount?.value &&
    getSingleNextStepUsingOutput();

  useImperativeHandle(ref, () => ({
    getSideEffects: () => {
      if (canUpdateNextStepInput && updateNextStepInput) {
        const step = getSingleNextStepUsingOutput()!;
        return [{ stepId: step!.id, inputId: output.id, value: output.amount!.value }];
      }

      return [];
    },
  }));

  return (
    <div key={output.id} className='flex flex-col gap-2'>
      <div key={item.node.id}>
        <div className='flex gap-2'>
          <Field name={`${arrayModel.name}.${index}.outputType`}>
            {(model: FieldProps<StaticEntity>) => (
              <div className='flex flex-col gap-1'>
                <div className='pl-1.5'>Output type</div>
                <div className='w-56'>
                  <SelectV3 model={model} disabled menuPortalTarget={props.formRef.current} options={lists.outputTypes} />
                </div>
              </div>
            )}
          </Field>
          <OriginalAwareField
            itemName={{
              arrayModel,
              field: 'name',
              ...item,
            }}
          >
            {(model: FieldProps<string>) => (
              <div className='flex flex-col gap-1'>
                <div className='pl-1.5'>Name</div>
                <div className='w-56 flex flex-col'>
                  <InputV3 model={model} placeholder='Name the output…' />
                </div>
              </div>
            )}
          </OriginalAwareField>
          <OriginalAwareField
            itemName={{
              arrayModel,
              field: 'amount.value',
              ...item,
            }}
          >
            {(model: FieldProps<number>) => (
              <Field name={`${arrayModel.name}.${index}.amount.unit`}>
                {(unitModel: FieldProps<Entity>) => (
                  <div className='flex flex-col gap-1'>
                    <div className='pl-1.5'>Quantity</div>
                    <div className='w-44'>
                      <UnitInputV3
                        model={model}
                        unit={{ model: unitModel, options: lists.units }}
                        placeholder={(() => {
                          if (
                            item.node.outputType?.type === OutputType.IntermediateProduct &&
                            formik.values.inputs.some(({ amountValue }) => typeof amountValue === 'number')
                          ) {
                            return sumBy(formik.values.inputs, ({ amountValue }) => amountValue).toString();
                          }

                          return undefined;
                        })()}
                      />
                    </div>
                  </div>
                )}
              </Field>
            )}
          </OriginalAwareField>
          <div className='flex flex-col gap-1'>
            <div>&nbsp;</div>
            <button
              type='button'
              className='flex bg-[#F5F7FA] text-zinc-400 hover:text-red-500 rounded-lg justify-center items-center aspect-square h-8 p-0.5'
              onClick={arrayModel.handleRemove(index)}
            >
              <FontAwesomeIcon size='lg' icon={regular('trash-can')} />
            </button>
          </div>
          <div className='flex flex-col gap-1'>
            <div>&nbsp;</div>
            <div className='mt-2'>
              <CardBadge item={item} />
            </div>
          </div>
        </div>
      </div>
      {canUpdateNextStepInput && (
        <div className='flex gap-2 ml-2'>
          <input
            id={updateNextStepInputCheckboxId}
            type='checkbox'
            checked={updateNextStepInput}
            onChange={() => setUpdateNextStepInput((value) => !value)}
          />
          <label htmlFor={updateNextStepInputCheckboxId} className='select-none'>
            Automatically change input amount of the next step ({getSingleNextStepUsingOutput()!.displayName}) to {output.amount!.value}
            {output.amount!.unit.name}
          </label>
        </div>
      )}
    </div>
  );
});
