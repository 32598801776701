import { offset, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, Fragment, PropsWithChildren, SetStateAction, useState } from 'react';

interface FilterField<T> {
  label: string;
  items: { label: string; value: string }[];
  field: string;
  value: T;
  disabled: boolean;
}

export const Filters = <T extends string | undefined, SP>(
  props: PropsWithChildren<{
    count: number;
    filterFields: FilterField<T>[];
    searchParams: SP;
    setSearchParams: Dispatch<SetStateAction<SP>>;
  }>,
) => {
  const [open, setOpen] = useState(false);
  const count = props.count;

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-end',
    open,
    onOpenChange: setOpen,
    middleware: [offset(8)],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  return (
    <>
      <button
        type='button'
        className={cn(
          'relative border-2 box-border bg-[#E8EAF5] text-[#330099] text-sm rounded-full flex items-center gap-2 px-4 h-full',
          count > 0 ? 'border-[#330099]' : 'border-transparent',
        )}
        {...getReferenceProps({ ref: reference })}
      >
        Filters
        <FontAwesomeIcon icon={light('sliders-v')} />
        {count > 0 && (
          <div className='absolute -top-1.5 right-0.5 flex justify-center items-center h-3.5 aspect-square rounded-full bg-[#330099] text-white text-[10px]'>
            {count}
          </div>
        )}
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
              },
            })}
            className='z-10 antialiased shadow-[0_1px_10px_rgba(0,0,0,0.15)] text-neutral-900 bg-[#E8EAF5] rounded-lg p-5 flex flex-col gap-4'
          >
            <div className='font-semibold'>Filters</div>
            <div className='-mx-1 h-px bg-zinc-300' />
            <div className='grid grid-cols-[max-content_1fr] items-center gap-x-12 gap-y-2.5 text-sm'>
              {props.filterFields.map(({ label, items, field, value, disabled }) => (
                <Fragment key={label}>
                  <div>{label}</div>
                  <div className='flex items-center relative'>
                    <select
                      disabled={disabled}
                      className={cn('w-full appearance-none border border-zinc-600 rounded-lg pl-4 pr-8 py-2', {
                        'cursor-not-allowed': disabled,
                        'bg-gray-100 text-zinc-400': disabled,
                      })}
                      value={value}
                      onChange={(event) => {
                        props.setSearchParams((oldParams) => {
                          const newParams = { ...oldParams };

                          if (event.target.value) {
                            (newParams as any)[field] = event.target.value;
                          } else {
                            delete (newParams as any)[field];
                          }

                          return newParams;
                        });
                      }}
                    >
                      {items.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                    <FontAwesomeIcon icon={regular('chevron-down')} className='absolute right-4 text-zinc-600' />
                  </div>
                </Fragment>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
