import { MutableRefObject, SetStateAction } from 'react';
import { TargetTile } from './TargetTile';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { TargetImpact } from '../../../../api';
import cn from 'classnames';

interface Props {
  targets: { targetImpacts: TargetImpact[] };
  setTargets: (targets: SetStateAction<{ targetImpacts: TargetImpact[] }>) => void;
  submitForm: () => void;
  disabled: boolean;
  previousFormSnapshot: MutableRefObject<{ targetImpacts: TargetImpact[] } | undefined>;
}

export const TargetTiles = (props: Props) => {
  const formik = useFormikContext<{ targetImpacts: TargetImpact[] }>();

  return (
    <div className='flex flex-col gap-y-6'>
      <FieldArray
        name='targetImpacts'
        render={(arrayModel) => (
          <div className='flex flex-col gap-y-2'>
            {formik.values.targetImpacts.map((actualTargetData, i, arr) => {
              const totalIdleItems = arr.filter((it) => it.status === 'idle').length;
              return (
                <Field name={`${arrayModel.name}.${i}`} key={actualTargetData.id}>
                  {(targetModel: FieldProps<TargetImpact>) => {
                    return (
                      <div
                        className={cn(
                          { 'pointer-events-none text-zinc-400': props.disabled },
                          'col-span-5 grid items-center min-h-14 pl-2 rounded-2xl border',
                          {
                            'mb-4': totalIdleItems === i + 1,
                            'bg-slate-50': targetModel.field.value.status === 'idle',
                          },
                          targetModel.field.value.status === 'idle'
                            ? 'grid-cols-[2fr_1fr_2fr_1fr_130px]'
                            : 'grid-cols-[2fr_1fr_2fr_3fr_130px]',
                        )}
                      >
                        <TargetTile
                          targets={props.targets}
                          setTargets={props.setTargets}
                          targetModel={targetModel}
                          submitForm={props.submitForm}
                          previousFormSnapshot={props.previousFormSnapshot}
                        />
                      </div>
                    );
                  }}
                </Field>
              );
            })}
          </div>
        )}
      />
    </div>
  );
};
