import { Helmet } from 'react-helmet-async';

export const Help = () => (
  <div className='flex flex-col px-6 gap-20'>
    <Helmet title='Help' />
    <div className='flex flex-col gap-6'>
      <div className='text-2xl font-semibold text-dark'>Help</div>
      <div className='flex flex-col gap-6 text-lg'>
        <div>We are sorry to hear that you are in need of help. We are of course more than happy to be of assistance!</div>
        <div>
          Please email us at:{' '}
          <a href='mailto:impact-help@sustained.com' className='whitespace-nowrap underline rounded-sm text-brand'>
            impact-help@sustained.com
          </a>{' '}
          and make sure to include as much detail as possible in your help request.
        </div>
      </div>
    </div>
    <div className='flex flex-col gap-6'>
      <div className='text-2xl font-semibold text-dark'>User Guide</div>
      <div className='text-lg'>
        A detailed step by step user guide can be found{' '}
        <a rel='noreferrer' href='https://sustained-impact.document360.io/' target='_blank' className='underline text-brand'>
          here
        </a>
        . You can search for the help you need in there or browse the list of available guides.
      </div>
    </div>
  </div>
);
