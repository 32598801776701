import { Placement } from '@floating-ui/core';
import {
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import {
  ForwardedRef,
  Fragment,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

export interface MenuItemConfig {
  label: string;
  icon?: IconDefinition;
  onClick?: () => void;
  disabled?: boolean;
  waiting?: boolean;
  modal?: (button: ReactNode, onOpenChange: (open: boolean) => void) => ReactNode;
  isHidden?: boolean;
}

interface Props {
  items?: MenuItemConfig[];
  content?: ReactNode;
  placement: Placement;
  noOffset?: boolean;
  className?: string;
  children: (props: { open: boolean }) => ReactNode;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  minWidth?: string;
  zIndex?: number;
  scrollableParent?: HTMLDivElement;
}

export interface MenuApi {
  open: () => void;
  close: () => void;
}

export const Menu = forwardRef((props: Props, ref: ForwardedRef<MenuApi>) => {
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(props.open ?? false);
  const [clickPosition, setClickPosition] = useState<{ x: number; y: number } | null>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  useEffect(() => {
    if (props.scrollableParent) {
      const onClick = (event: MouseEvent) => {
        const targetRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
        setClickPosition({
          x: event.clientX - targetRect.left + props.scrollableParent!.scrollLeft,
          y: event.clientY - targetRect.top + props.scrollableParent!.scrollTop,
        });
      };

      props.scrollableParent.addEventListener('click', onClick);

      return () => props.scrollableParent!.removeEventListener('click', onClick);
    }
  }, [props.scrollableParent]);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: props.placement,
    open,
    onOpenChange: (newOpen) => {
      setOpen(newOpen);

      if (newOpen && props.onOpen) {
        props.onOpen();
      }
    },
    middleware: [
      offset({
        mainAxis: props.noOffset ? 0 : 8,
        crossAxis: 12 * (props.placement.endsWith('start') ? -1 : props.placement.endsWith('end') ? 1 : 0),
      }),
      flip(),
      shift(),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context, {
      modal: false,
      order: ['reference', 'content'],
      enabled: !props.items?.some(({ modal }) => modal),
    }),
    useRole(context, { role: 'menu' }),
    useDismiss(context, {
      outsidePointerDown: !modal,
    }),
  ]);

  const children = props.children({ open });

  return (
    <>
      {isValidElement(children) && cloneElement(children, getReferenceProps({ ref: reference }))}
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                zIndex: props.zIndex,
                position: strategy,
                left: clickPosition?.x ?? x ?? '',
                top: clickPosition?.y ?? y ?? '',
              },
            })}
            className={cn('antialiased shadow-xl bg-white border rounded-xl py-2', props.className)}
          >
            {props.items &&
              props.items.map((item) => {
                const content = (
                  <button
                    type='button'
                    disabled={item.disabled}
                    className={cn('flex px-2 w-full group', {
                      hidden: item.isHidden,
                    })}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                        setOpen(false);
                      }
                    }}
                  >
                    <div
                      className={cn(
                        'whitespace-nowrap w-full flex gap-3 items-center px-3 py-2 rounded-lg group-hover:bg-brand/10 transition-colors',
                        item.disabled ? 'text-zinc-400 cursor-not-allowed' : 'text-zinc-700',
                        { 'cursor-wait': item.waiting },
                      )}
                    >
                      {item.icon && (
                        <FontAwesomeIcon
                          icon={item.waiting ? regular('spinner') : item.icon}
                          spin={item.waiting}
                          pulse={item.waiting}
                          className='w-5'
                        />
                      )}
                      {item.label}
                    </div>
                  </button>
                );

                return (
                  <Fragment key={item.label}>
                    {item.modal
                      ? item.modal(content, (modalOpen) => {
                          setModal(modalOpen);

                          if (!modalOpen) {
                            setOpen(false);
                          }
                        })
                      : content}
                  </Fragment>
                );
              })}

            {props.content && props.content}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
});
