import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  theme: 'amber' | 'red';
  icon: ReactNode;
  title: string;
  text: string;
}

export const ReportWarning = (props: Props) => {
  return (
    <div
      className={cn(
        'flex items-center gap-x-4 rounded-2xl border px-6 py-4 shadow',
        { 'border-amber-400 bg-amber-50': props.theme === 'amber' },
        { 'bg-rose-50': props.theme === 'red' },
      )}
    >
      {props.icon}
      <div className='flex flex-col gap-y-1 text-dark'>
        <div className='font-semibold text-xl'>{props.title}</div>
        <div>{props.text}</div>
      </div>
    </div>
  );
};
