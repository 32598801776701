import { ReactNode } from 'react';
import { ProductReport, ProductType } from '../../../../../../api';
import { CollapsibleSection } from '../../CollapsibleSection';
import { SystemBoundariesGraph } from './SystemBoundariesGraph';

interface Props {
  data: ProductReport;
  description: ReactNode;
  goal: ReactNode;
  functionalUnit: ReactNode;
  methodology: ReactNode;
  systemBoundaries: ReactNode;
  bottomNavigationButtons: ReactNode;
}

export const GoalAndScope = (props: Props) => (
  <div className='flex flex-col gap-6 px-6'>
    {props.description}
    {props.goal}
    {props.functionalUnit}
    {props.methodology}
    <CollapsibleSection title='System Boundaries'>
      <div className='flex flex-col gap-6'>
        {props.systemBoundaries}
        <SystemBoundariesGraph data={props.data} />
      </div>
    </CollapsibleSection>

    <CollapsibleSection title='Data requirements and quality'>
      <div className='flex flex-col gap-6'>
        <div>
          A combination of the self-reported foreground data provided by the client via the Sustained Impact system interface (as presented
          in the Product Details section), and background data from a number of databases was used to estimate the environmental impact to
          fulfil the functional unit. The following databases were used in the analysis
        </div>
        <ul>
          <li className='underline hover:text-brand'>
            <a href='https://ecoinvent.org/the-ecoinvent-database/data-releases/ecoinvent-3-7-1/' rel='noreferrer' target='_blank'>
              Ecoinvent v.3.7.1
            </a>
          </li>
          <li className='underline hover:text-brand'>
            <a href='https://agribalyse.ademe.fr/' rel='noreferrer' target='_blank'>
              Agribalyse v.3.1
            </a>
          </li>
          <li className='underline hover:text-brand'>
            <a href='https://www.sustained.com' rel='noreferrer' target='_blank'>
              Sustained Database v.1.0
            </a>
          </li>
        </ul>
        {[ProductType.Final, ProductType.Intermediate] ? (
          <div>
            Additionally, Sustained is continuously working on database improvements by creating new datasets for specific processes. These
            new datasets are created based on the databases aforementioned, and follow PEF guidelines. Electricity consumption in
            production, distribution, and use phase is modelled using the country's electricity mixes from Ecoinvent, or the respective
            regionalised mix of renewable electricity of the indicated country.
          </div>
        ) : (
          <div>
            Additionally, Sustained is continuously working on database improvements by creating new datasets for specific processes. These
            new datasets are created based on the databases aforementioned, and follow PEF guidelines.
          </div>
        )}
        <div>
          It should be noted that the self-reported foreground data has not been verified by Sustained and as such, all results presented in
          this report should be interpreted accordingly.
        </div>
        <div>
          With the current Sustained Impact software version, the customer is given the option to provide through the online interface only
          limited foreground or primary data. This includes:
        </div>
        <ul className='list-disc ml-4'>
          <li>Raw materials (ingredients and packaging), their amounts, composition (for packaging) and country of origins per supplier</li>
          <li>Production processes with primary data inputs in the form of energy, water and auxiliary inputs</li>
          <li>Distribution path</li>
          <li>Transportation links with primary data inputs in the form of distance and mode of transport</li>
          <li>Cooking instructions</li>
          <li>Packaging recyclability properties</li>
        </ul>
        <div>Future versions of the Sustained Impact product will allow for additional foreground data input.</div>
        <div>
          Generic data have been used where foreground data couldn’t be provided: for transportation assumptions throughout each life cycle
          stage, industrial production processes, household preparation processes and packaging recyclability by material type etc. These
          data were collected from available background processes in our databases or from custom processes created by our team of LCA
          experts. Bear in mind that both our own and the datasets from the LCI databases come with an intrinsic ‘quality’ and uncertainty.
          The data quality and uncertainty for both background and foreground data will be quantified and displayed in future report
          versions.
        </div>
        <div>
          Finally, where there is uncertainty from the part of the client on any of the foreground data assumptions, such as the specific
          type of plastic wrap, the Sustained defaults are used to approximate the environmental impact of the product.
        </div>
      </div>
    </CollapsibleSection>
    {props.bottomNavigationButtons}
  </div>
);
