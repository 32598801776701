import { MappedItem, Mapping } from '../../../../api';
import * as yup from 'yup';
import { is100Percent } from '../../Products/Details/dataModel';

export const getInitialFormData = (mapping: Mapping) => {
  switch (mapping.type) {
    case 'ingredient':
      return {
        ...mapping,
        mappedData:
          mapping.mappedData.items.length > 0
            ? mapping.mappedData
            : {
                items: [
                  {
                    ingredient: null,
                    displayName: null,
                  },
                  {
                    conservation: null,
                  },
                ],
              },
      };

    case 'packaging':
      return {
        ...mapping,
        mappedData:
          mapping.mappedData.items.length > 0
            ? mapping.mappedData
            : {
                items: [
                  {
                    packaging: null,
                    displayName: null,
                  },
                  {
                    materials: [],
                  },
                ],
              },
      };

    default:
      console.warn('Invalid mapping type');
      return {} as any;
  }
};

export const mappingValidationSchema = yup
  .object()
  .shape({
    type: yup.string().required(),
  })
  .when({
    is: (item: any) => item.type === 'ingredient',
    then: yup.object().shape({
      type: yup.string().required(),
      mappedData: yup.object().shape({
        items: yup.array().of(
          yup
            .object()
            .nullable()
            .shape({
              ingredient: yup.object().when({
                is: (value: MappedItem | null | undefined) => value !== undefined,
                then: yup
                  .object()
                  .required()
                  .shape({
                    comment: yup.string().when('placeholder', {
                      is: true,
                      then: (schema) => schema.required(),
                    }),
                  }),
                otherwise: yup.object().nullable().notRequired(),
              }),
              conservation: yup.object().when({
                is: (item: MappedItem) => item?.conservation !== null,
                then: yup.object(),
                otherwise: yup.object().nullable().notRequired(),
              }),
            }),
        ),
      }),
    }),
    otherwise: yup.object().when({
      is: (item: any) => item.type === 'packaging',
      then: yup.object().shape({
        type: yup.string().required(),
        mappedData: yup.object().shape({
          items: yup.array().of(
            yup
              .object()
              .nullable()
              .shape({
                packaging: yup.object().when({
                  is: (value: MappedItem) => value !== undefined,
                  then: yup
                    .object()
                    .required()
                    .shape({
                      comment: yup.string().when('placeholder', {
                        is: true,
                        then: (schema) => schema.required(),
                      }),
                    }),
                  otherwise: yup.object().nullable().notRequired(),
                }),
                materials: yup
                  .array()
                  .nullable()
                  .min(1)
                  .of(
                    yup.object().shape({
                      comment: yup.string().when(['placeholder', 'subType.placeholder'], {
                        is: (placeholder: boolean, subTypePlaceholder: boolean) => placeholder || subTypePlaceholder,
                        then: (schema) => schema.required(),
                      }),
                      subType: yup.object().required(),
                      compositionPercent: yup.number().positive().max(100).required(),
                    }),
                  )
                  .test('', 'splitsNot100', function () {
                    const parent = this.parent as MappedItem;
                    if (parent.materials) {
                      return is100Percent(parent.materials.map(({ compositionPercent }) => compositionPercent));
                    } else {
                      return true;
                    }
                  }),
              }),
          ),
        }),
      }),
    }),
  });
