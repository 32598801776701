import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useEffectOnNextRenders = (effect: EffectCallback, deps?: DependencyList) => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
