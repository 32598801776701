import cn from 'classnames';
import { PropsWithChildren } from 'react';

export const PopoverFrame = (
  props: PropsWithChildren<{
    className?: string;
  }>,
) => (
  <div
    className={cn('leading-normal flex flex-col rounded-lg text-zinc-500 bg-white shadow-[0_1px_10px_rgba(0,0,0,0.15)]', props.className)}
  >
    {props.children}
  </div>
);
