export const SkeletonLoader = () => (
  <>
    {new Array(10).fill(null).map((_, i) => (
      <div
        key={i}
        className='loading-skeleton'
        style={{
          height: '40px',
          width: '100%',
        }}
      />
    ))}
  </>
);
