import { ModalApi, ModalV3 } from '../../../../../components/ModalV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Mapping, MappingProduct, ProductType } from '../../../../../api';
import cn from 'classnames';
import { simplify, roundToLong } from '../../../shared';
import { PropsWithChildren, RefObject, SetStateAction } from 'react';

interface Props {
  modalRef: RefObject<ModalApi>;
  mapping: Mapping;
  mappingProducts: { list: MappingProduct[]; mappingId: string; searchString: string };
  setMappingProducts: (value: SetStateAction<{ list: MappingProduct[]; mappingId: string; searchString: string }>) => void;
}

export const ProductsListModal = (props: PropsWithChildren<Props>) => (
  <>
    <ModalV3
      size='wide+'
      hideConfirm
      ref={props.modalRef}
      cancelLabel='Close'
      onClose={() => props.setMappingProducts({ list: [], mappingId: '', searchString: '' })}
      title={
        <div>
          <div className='flex flex-col gap-3'>
            <div className='flex flex-col font-semibold text-xl text-neutral-900'>Products using {props.mapping.extractedData}</div>
            <div className='flex justify-between items-center text-base font-normal'>
              <div>Internal ID: {props.mapping.internalId}</div>
              <div className='flex gap-x-6'>
                <div className='px-4 py-2 bg-slate-100 rounded-lg'>
                  {props.mappingProducts.list.length} Product{props.mappingProducts.list.length === 1 ? '' : 's'}
                </div>
                <div className='flex items-center relative'>
                  <input
                    type='search'
                    className='rounded-lg border pl-3 pr-10 py-1.5 placeholder:text-gray-400 border-zinc-500'
                    autoFocus
                    placeholder='Find…'
                    value={props.mappingProducts.searchString}
                    onChange={(event) => props.setMappingProducts((current) => ({ ...current, searchString: event.target.value }))}
                  />
                  <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-6 text-light' />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      body={<ModalContent productList={props.mappingProducts.list} />}
    />
    {props.children}
  </>
);

const ModalContent = (props: { productList: MappingProduct[] }) => (
  <div className='-mt-12'>
    <div className='border rounded-lg overflow-hidden mt-6'>
      <table className='w-full'>
        <thead className='font-semibold'>
          <tr className='h-12'>
            <td className='pl-3'>Name</td>
            <td className='px-2'>Product type</td>
            <td>Net amount</td>
            <td className='px-2'>SKU ID</td>
            <td>Impact Points</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr className='border-b border-zinc-200'></tr>
          {props.productList.map((product, i) => (
            <tr className={cn('h-12', i % 2 ? '' : 'bg-slate-50')} key={product.id}>
              <td className='max-w-[180px] truncate whitespace-nowrap pl-3'>{product.name}</td>
              <td className='px-2'>
                {
                  {
                    [ProductType.Final]: 'Final',
                    [ProductType.Intermediate]: 'Intermediate',
                    [ProductType.Internal]: 'Internal',
                  }[product.type]
                }
              </td>
              <td>{product.netAmount}</td>
              <td className='px-2 truncate max-w-[180px]'>{product.skuId}</td>
              <td title={product.impactPoints ? roundToLong(product.impactPoints) : ''} className='font-semibold'>
                {product.impactPoints ? simplify(product.impactPoints) : ''}
              </td>
              <td className='font-semibold px-3'>
                <button
                  onClick={() => window.open(`/products/${product.id}/graph`, '_blank')}
                  className='flex self-center mx-auto bg-slate-200 p-2 rounded-lg hover:text-brand active:scale-95'
                >
                  <FontAwesomeIcon className='text-lg' icon={regular('file-chart-column')} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
