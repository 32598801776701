import cn from 'classnames';
import { forwardRef } from 'react';
import { TooltipV3 } from '../../../../components/TooltipV3';

interface Props {
  title: string;
}

export const DataCompletion = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex items-center justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      </div>
      <div className='grid grid-cols-2 gap-3'>
        {[
          {
            title: 'Product entries',
            value: '12,2/15k',
            items: [
              {
                name: 'Complete',
                percent: 0.81,
                percentOnBar: true,
                color: 'bg-indigo-200',
              },
              {
                name: 'Drafts',
                percent: 0.1,
                color: 'bg-blue-400',
              },
            ],
          },
          {
            title: 'First party data',
            value: '82%',
            items: [
              {
                name: 'Complete',
                percent: 0.82,
                color: 'bg-purple-200',
              },
            ],
          },
        ].map(({ title, value, items }) => (
          <div key={title} className='flex flex-col gap-4 p-4 border border-zinc-200 bg-white rounded-2xl'>
            <div className='px-1 text-zinc-800 font-semibold text-base'>{title}</div>
            <div className='flex flex-col gap-2'>
              <div className='px-1 text-2xl text-zinc-700'>{value}</div>
              <div className='flex rounded-full overflow-clip text-base text-zinc-500 bg-slate-100'>
                {items.map(({ name, percent, percentOnBar, color }, i) => (
                  <TooltipV3
                    key={i}
                    disabled={i > 0 || percentOnBar}
                    content={
                      <div className='leading-normal whitespace-normal px-2 py-1 rounded-lg text-white bg-brandDarkPurple2 shadow-[0_0_4px_2px_rgba(0,0,0,0.15)]'>
                        {Math.round(percent * 100)}%
                      </div>
                    }
                  >
                    <div key={name} className={cn('h-8 flex justify-center items-center', color)} style={{ width: `${percent * 100}%` }}>
                      {percentOnBar && <>{Math.round(percent * 100)}%</>}
                    </div>
                  </TooltipV3>
                ))}
              </div>
              <div className='px-1 flex justify-between'>
                {[...items, { name: 'Missing', color: 'bg-slate-100' }].map(({ name, color }) => (
                  <div key={name} className='flex items-center gap-1'>
                    <div
                      className={cn('size-2.5 border rounded-full', color, name === 'Missing' ? 'border-zinc-300' : 'border-transparent')}
                    />
                    <div className='text-zinc-500 text-xs'>{name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
