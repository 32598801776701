import { duotone, light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { Fragment, RefObject, useEffect, useState } from 'react';
import {
  DisposalNode,
  GenericNode,
  GenericNodeWithEdges,
  GenericStepNode,
  IngredientType,
  LifeCycleStage,
  NodeType,
  OutputType,
  PackagingNode,
  ProductType,
  ProductV3,
  RawMaterialNode,
  RawMaterialSupplierNode,
  StepNode,
  StorageNode,
  TransportNode,
  getNodeTypesForLifecycleStage,
} from '../../../../api';
import { Menu } from '../../../../components/Menu';
import { ModalFormApi, ModalFormSaveParams } from '../../../../components/ModalForm';
import { useLists } from '../../../../hooks/useLists';
import { renderBadges } from './Badges';
import { ConsumptionLocationAddStepButtons } from './ConsumptionLocationGraph';
import { StepDetails as PreparationStepDetails } from './ConsumptionLocationGraph/StepDetails';
import { DisposalDetails } from './DisposalDetails';
import { LifecycleStagePlaceholder } from './LifecycleStagePlaceholder';
import { Message, MessageId } from './Message';
import { ProductionFacilityAddStepButtons } from './ProductionFacilityGraph';
import { IngredientStepDetails } from './ProductionFacilityGraph/IngredientStepDetails';
import { PackagingStepDetails } from './ProductionFacilityGraph/PackagingStepDetails';
import { Layout } from './SecondaryBarContent';
import { StorageItemsTable } from './StorageItems';
import { TransportDetails } from './TransportDetails';
import {
  UpdateSideEffects,
  getConsumptionLocations,
  getInputNode,
  getOutputs,
  getPackagings,
  getPrimaryAndMaterialSuppliers,
  getPrimaryTag,
  getStorageNodes,
  getTransports,
  isPlaceholder,
  onEdit,
  onSaveTransport,
  remove,
  removeStep,
  replaceStep,
  sortAndFilterNodes,
} from './dataModel';
import {
  getConsumptionMenuItems,
  getDistributionMenuItems,
  getIngredientMenuItems,
  getPackagingMenuItems,
  getProductionMenuItems,
  renderDistributionAddButtons,
  renderProductionAddButtons,
  renderRawMaterialsAddButtons,
  renderUseAddButtons,
} from './shared';

interface Props {
  stage: LifeCycleStage;
  filter: NodeType[];
  search: string;
  ignoreLessImportantValidation: boolean;
  openExportRawMaterialModal: (node: RawMaterialNode) => void;
}

export const ListLayoutView = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const lists = useLists();
  const [expandedIds, setExpandedIds] = useState(new Array<string>());

  const getDisposalPackaging = (node: DisposalNode) => getPackagings(formik).find(({ id }) => id === node.packagingNodeId);

  const renderMaterials = (node: PackagingNode) =>
    node.materials.map((material) => (
      <Fragment key={material.id}>
        <span>{material.compositionPercent}% </span>
        <span>
          {material.name} ({material.subType.name})
        </span>
      </Fragment>
    ));

  const renderDisclosureButton = (node: GenericNode, className: string) => (
    <button
      type='button'
      className={cn('w-5 aspect-square rounded-full flex justify-center items-center border', className, {
        invisible: node.type === NodeType.FinalDestination,
      })}
      onClick={() => onDisclosureClick(node.id)}
    >
      <FontAwesomeIcon icon={expandedIds.includes(node.id) ? regular('chevron-down') : regular('chevron-right')} size='xs' />
    </button>
  );

  const onDisclosureClick = (id: string) =>
    setExpandedIds((oldExpandedIds) =>
      oldExpandedIds.includes(id) ? oldExpandedIds.filter((expandedId) => expandedId !== id) : [...oldExpandedIds, id],
    );

  const nodes = sortAndFilterNodes(formik.values.nodes, formik, props).filter((node) => props.filter.includes(node.type));

  return nodes.length === 0 && !props.search ? (
    <div className='flex-1 flex justify-center items-center text-zinc-400'>
      <LifecycleStagePlaceholder stage={props.stage} />
    </div>
  ) : (
    <div className='flex flex-col gap-3 py-4'>
      {nodes
        .filter((node) => node.displayName.toLowerCase().includes(props.search.toLowerCase()))
        .map((node) => {
          const { type } = node;
          const expanded = expandedIds.includes(node.id);

          const getTransportConnections = (fromNodes: GenericNodeWithEdges[]) => {
            const transports = fromNodes
              .flatMap(({ edges }) => edges)
              .map((edgeId) => getTransports(formik).find(({ id }) => id === edgeId)!);
            const nodes = transports.flatMap(({ edges }) => edges).map((edgeId) => formik.values.nodes.find(({ id }) => id === edgeId)!);

            return {
              transports,
              nodes,
              zero: transports.length === 0,
              one: transports.length === 1,
              many: transports.length > 1,
            };
          };

          const transportsWidth = 'w-72';
          const transportItemHeight = 'h-6';

          const renderTransportArrow = () => (
            <div className='flex items-center mr-0.5'>
              <FontAwesomeIcon icon={solid('circle')} className='-mr-px text-[7.5px]' />
              <FontAwesomeIcon icon={regular('arrow-right')} size='lg' />
            </div>
          );

          const renderNoTransportsItem = (fromNode: GenericNode) => (
            <div className={cn('flex justify-between gap-4', transportItemHeight)}>
              <div className='flex items-center gap-1 text-zinc-500'>
                {renderTransportArrow()}
                <div className='rounded-2xl px-1.5 py-0.5 text-xs bg-zinc-100'>0</div>
                <div className='text-zinc-600'>Transport links</div>
              </div>
              <div className='flex items-center'>
                <TransportDetails fromId={fromNode.id} onSave={(params) => onSaveTransport(params, formik)}>
                  <button type='button' className='flex items-center gap-2 px-2 py-1 -mr-2 rounded-lg text-xs text-[#220066] bg-[#E8EAF5]'>
                    <FontAwesomeIcon icon={regular('truck')} />
                    <div>Add link</div>
                  </button>
                </TransportDetails>
              </div>
            </div>
          );

          const renderTransportItem = (transport: TransportNode, toNode: GenericNode) => (
            <div className={cn('truncate flex justify-between gap-6', transportItemHeight)}>
              <div className='truncate flex items-center gap-1 text-brand'>
                {renderTransportArrow()}
                <div className='truncate'>To {toNode.displayName}</div>
                {renderBadges(transport, formik, { className: 'ml-2' })}
              </div>
              <div className='flex items-center'>
                <div className='flex gap-3 text-zinc-600'>
                  <TransportDetails data={transport} onSave={(params) => onSaveTransport(params, formik)}>
                    <button type='button'>
                      <FontAwesomeIcon icon={light('edit')} />
                    </button>
                  </TransportDetails>
                  <button type='button' onClick={() => remove(transport, formik)}>
                    <FontAwesomeIcon icon={light('trash-alt')} />
                  </button>
                </div>
              </div>
            </div>
          );

          const renderTransports = () => {
            const fromNodes =
              type === NodeType.Ingredient || type === NodeType.Packaging
                ? sortAndFilterNodes<RawMaterialSupplierNode>(node.nodes, formik, props)
                : [node];
            const connections = getTransportConnections(fromNodes);
            const expandable = !expanded && connections.many;

            const renderArrow = () => (
              <div className='flex items-center mr-0.5'>
                <FontAwesomeIcon icon={solid('circle')} className='-mr-px text-[7.5px]' />
                <FontAwesomeIcon icon={regular('arrow-right')} size='lg' />
              </div>
            );

            return (
              <div
                className={cn('truncate flex flex-col gap-3 rounded-lg border border-zinc-300 px-3 py-1 -mr-1', transportsWidth, {
                  'cursor-pointer select-none': expandable,
                })}
                onClick={expandable ? () => onDisclosureClick(node.id) : undefined}
              >
                {expanded && <div className='uppercase text-xs font-semibold text-zinc-600 mt-1.5'>Transport Links</div>}
                {connections.zero ? (
                  renderNoTransportsItem(fromNodes[0])
                ) : (!expanded && connections.one) || (expanded && (connections.one || connections.many)) ? (
                  <div className='w-full truncate flex flex-col gap-2'>
                    {connections.transports.map((transport, i) => (
                      <Fragment key={transport.id}>{renderTransportItem(transport, connections.nodes[i])}</Fragment>
                    ))}
                  </div>
                ) : (
                  <div className={cn('flex justify-between gap-4', transportItemHeight)}>
                    <div className='flex items-center gap-1 text-brand'>
                      {renderArrow()}
                      <div className='rounded-2xl px-1.5 py-0.5 text-xs bg-[rgba(218,206,253,0.5)]'>{connections.transports.length}</div>
                      <div>Transport links</div>
                    </div>
                    {expandable && (
                      <div className='flex items-center'>
                        <FontAwesomeIcon icon={regular('chevron-down')} className='text-zinc-600' />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          };

          return (
            <div
              key={node.id}
              className={cn(
                'px-4 py-2 border-l-[3px] flex flex-col gap-2 bg-white rounded-xl shadow-[0_1px_10px_rgba(0,0,0,0.15)]',
                isPlaceholder(node) ? 'border border-dashed border-zinc-400' : '',
                {
                  'border-l-green-900': type === NodeType.Ingredient,
                  'border-l-lime-500': type === NodeType.Packaging,
                  'border-l-rose-900': type === NodeType.Production,
                  'border-l-fuchsia-400': [NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type),
                  'border-l-blue-900': [NodeType.Store, NodeType.FinalDestination].includes(type),
                  'border-l-cyan-500': type === NodeType.Consumption,
                  'border-l-slate-400': type === NodeType.Disposal,
                },
              )}
            >
              <div className='h-8 flex justify-between'>
                <div className='flex items-center gap-3'>
                  {renderDisclosureButton(node, 'border-zinc-900 text-zinc-900')}
                  <div
                    className={cn('relative flex justify-center items-center rounded-md h-7 aspect-square', {
                      'bg-green-50 text-green-900': type === NodeType.Ingredient,
                      'bg-lime-50 text-lime-900': type === NodeType.Packaging,
                      'bg-rose-50 text-rose-900': type === NodeType.Production,
                      'bg-fuchsia-50 text-fuchsia-900': [NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type),
                      'bg-blue-50 text-blue-900': [NodeType.Store, NodeType.FinalDestination].includes(type),
                      'bg-cyan-50 text-cyan-900': type === NodeType.Consumption,
                      'bg-slate-50 text-slate-900': type === NodeType.Disposal,
                    })}
                  >
                    {type === NodeType.Ingredient && (
                      <>
                        <FontAwesomeIcon icon={duotone('carrot')} />
                        {node.ingredient.type === IngredientType.IntermediateProduct && (
                          <FontAwesomeIcon icon={solid('circle-arrow-down')} className='text-[7px] absolute right-[7px] bottom-[7px]' />
                        )}
                      </>
                    )}
                    {type === NodeType.Packaging && <FontAwesomeIcon icon={duotone('box-open')} />}
                    {type === NodeType.Production && <FontAwesomeIcon icon={duotone('industry-windows')} />}
                    {[NodeType.ProductionWarehouse, NodeType.Warehouse].includes(type) && (
                      <FontAwesomeIcon icon={duotone('warehouse-full')} />
                    )}
                    {type === NodeType.Store && <FontAwesomeIcon icon={duotone('store')} />}
                    {type === NodeType.FinalDestination && <FontAwesomeIcon icon={duotone('flag-checkered')} />}
                    {type === NodeType.Consumption && <FontAwesomeIcon icon={duotone('house-user')} />}
                    {type === NodeType.Disposal && <FontAwesomeIcon icon={duotone('dumpster')} />}
                  </div>
                  <div className='inline-flex gap-2 w-40 font-semibold text-zinc-900 truncate'>
                    <span className='truncate'>
                      {node.displayName}
                      {node.type === NodeType.Disposal && (
                        <> ({getConsumptionLocations(formik).find(({ edges }) => edges.includes(node.id))!.displayName})</>
                      )}
                    </span>
                    {(node.type === NodeType.Ingredient || node.type === NodeType.Packaging) && (
                      <span className='font-normal text-zinc-800'>
                        {node.amount.value}
                        {node.amount.unit.name}
                      </span>
                    )}
                  </div>
                  {type !== NodeType.Disposal && (
                    <Menu
                      zIndex={20}
                      placement='bottom-start'
                      items={(() => {
                        switch (type) {
                          case NodeType.Ingredient:
                            return getIngredientMenuItems(node, formik, props.openExportRawMaterialModal);
                          case NodeType.Packaging:
                            return getPackagingMenuItems(node, formik, props.openExportRawMaterialModal);
                          case NodeType.Production:
                          case NodeType.ProductionWarehouse:
                            return getProductionMenuItems(node, formik);
                          case NodeType.Warehouse:
                          case NodeType.Store:
                          case NodeType.FinalDestination:
                            return getDistributionMenuItems(node, formik);
                          case NodeType.Consumption:
                            return getConsumptionMenuItems(node, formik);
                        }
                      })()}
                    >
                      {({ open }) => (
                        <button type='button' className={cn('flex hover:text-inherit', { 'text-zinc-400': !open })}>
                          <FontAwesomeIcon className='h-4 aspect-square' icon={solid('ellipsis-vertical')} />
                        </button>
                      )}
                    </Menu>
                  )}
                  {!expanded && ![NodeType.FinalDestination, NodeType.Disposal].includes(type) && (
                    <div
                      className={cn('ml-3 text-xs flex gap-2 items-center rounded p-1 pr-2', {
                        'bg-rose-50': node.type === NodeType.Production,
                        'bg-fuchsia-50': [NodeType.ProductionWarehouse, NodeType.Warehouse].includes(node.type),
                        'bg-blue-50': [NodeType.Store, NodeType.FinalDestination].includes(node.type),
                        'bg-cyan-50': node.type === NodeType.Consumption,
                      })}
                    >
                      <div
                        className={cn(
                          'rounded-2xl',
                          node.type === NodeType.Ingredient || node.type === NodeType.Packaging ? 'px-2 py-1' : 'px-1.5 py-0.5',
                          {
                            'text-green-900 bg-green-50': node.type === NodeType.Ingredient,
                            'text-lime-800 bg-lime-50': node.type === NodeType.Packaging,
                            'text-rose-900 bg-white': node.type === NodeType.Production,
                            'text-fuchsia-900 bg-white': [NodeType.ProductionWarehouse, NodeType.Warehouse].includes(node.type),
                            'text-blue-900 bg-white': node.type === NodeType.Store,
                            'text-cyan-900 bg-white': node.type === NodeType.Consumption,
                          },
                        )}
                      >
                        {
                          (node.type === NodeType.Ingredient || node.type === NodeType.Packaging
                            ? node.nodes
                            : node.type === NodeType.Production || node.type === NodeType.Consumption
                            ? node.steps
                            : (node as StorageNode).items
                          ).length
                        }
                      </div>
                      <div className='text-zinc-600'>
                        {node.type === NodeType.Ingredient || node.type === NodeType.Packaging ? (
                          <div className='inline-flex gap-8 text-sm'>
                            <div className='w-48 truncate'>
                              {node.nodes.length === 1 ? `Supplier: ${node.nodes[0].displayName}` : 'Suppliers'}
                            </div>
                            {node.type === NodeType.Packaging && !getPrimaryTag(node, formik) && (
                              <div className='w-72 truncate'>Materials: {renderMaterials(node)}</div>
                            )}
                          </div>
                        ) : node.type === NodeType.Production ? (
                          <>Production step{node.steps.length === 1 ? '' : 's'}</>
                        ) : node.type === NodeType.Consumption ? (
                          <>Preparation step{node.steps.length === 1 ? '' : 's'}</>
                        ) : (
                          <>Stored item{(node as StorageNode).items.length === 1 ? '' : 's'}</>
                        )}
                      </div>
                      {![NodeType.Ingredient, NodeType.Packaging].includes(node.type) && renderBadges(node, formik, { subNodes: true })}
                    </div>
                  )}
                  {expanded && (type === NodeType.Production || type === NodeType.Consumption) && (
                    <div className='ml-3 flex items-center gap-2.5 text-xs'>
                      {type === NodeType.Production && <ProductionFacilityAddStepButtons facility={node} productFormik={formik} />}
                      {type === NodeType.Consumption && <ConsumptionLocationAddStepButtons facility={node} productFormik={formik} />}
                    </div>
                  )}
                  {type === NodeType.Disposal
                    ? getDisposalPackaging(node) && (
                        <div className='flex items-center gap-3 text-zinc-600'>
                          <div className='flex items-center gap-5 w-32'>
                            <div className='truncate'>
                              {getDisposalPackaging(node)!.amount.value}
                              {getDisposalPackaging(node)!.amount.unit.name}
                            </div>
                            {renderBadges(node, formik)}
                          </div>
                          {!expanded && (
                            <>
                              <div className='truncate w-96'>{renderMaterials(getDisposalPackaging(node)!)}</div>
                              <div>{node.disposalType.name} recyclability</div>
                            </>
                          )}
                        </div>
                      )
                    : renderBadges(node, formik, { className: 'ml-3' })}
                </div>
                <div className='flex items-center'>
                  {type === NodeType.Disposal && getDisposalPackaging(node) && (
                    <DisposalDetails data={node} onSave={(params) => onEdit(params, formik)}>
                      <button type='button' className='flex text-zinc-600'>
                        <FontAwesomeIcon icon={light('edit')} />
                      </button>
                    </DisposalDetails>
                  )}
                  {!expanded && ![NodeType.FinalDestination, NodeType.Consumption, NodeType.Disposal].includes(type) && renderTransports()}
                </div>
              </div>
              {expanded && (type === NodeType.Ingredient || type === NodeType.Packaging) && (
                <div className='flex gap-4 -ml-1 mb-1'>
                  <div className='flex-1 flex flex-col gap-3.5 px-4 pt-2.5 pb-4 border border-zinc-300 rounded-lg text-zinc-600'>
                    <div className='uppercase text-xs font-semibold'>Suppliers</div>
                    <div className='grid grid-cols-[repeat(3,max-content)_1fr] gap-x-12 gap-y-3'>
                      {sortAndFilterNodes<RawMaterialSupplierNode>(node.nodes, formik, props).map((supplier, i) => (
                        <Fragment key={supplier.id}>
                          {i > 0 && <div className='col-span-4 h-px bg-zinc-300'></div>}
                          <div className={transportItemHeight}>{supplier.displayName}</div>
                          <div>{supplier.splitPercent}%</div>
                          <div>{supplier.location.name}</div>
                          <div className='flex items-center'>{renderBadges(supplier, formik)}</div>
                          {range(0, Math.max(0, supplier.edges.length - 1)).map((i) => (
                            <div key={i} className={cn('col-span-4', transportItemHeight)} />
                          ))}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                  <div
                    className={cn(
                      '-mr-1 flex flex-col gap-3.5 px-4 pt-2.5 pb-4 border border-zinc-300 rounded-lg text-zinc-600',
                      transportsWidth,
                    )}
                  >
                    <div className='uppercase text-xs font-semibold'>Transport links</div>
                    <div className='flex flex-col gap-3'>
                      {sortAndFilterNodes<RawMaterialSupplierNode>(node.nodes, formik, props).map((supplier, i) => {
                        const connections = getTransportConnections([supplier]);
                        return (
                          <Fragment key={supplier.id}>
                            {i > 0 && <div className='col-span-3 h-px bg-zinc-300'></div>}
                            <div
                              className={cn('w-full flex flex-col gap-3', {
                                truncate: !connections.zero,
                              })}
                            >
                              {connections.zero
                                ? renderNoTransportsItem(supplier)
                                : connections.transports.map((transport, i) => (
                                    <Fragment key={transport.id}>{renderTransportItem(transport, connections.nodes[i])}</Fragment>
                                  ))}
                            </div>
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
              {expanded && (type === NodeType.Production || type === NodeType.Consumption) && node.steps.length > 0 && (
                <div className='flex gap-4 mb-1'>
                  <div className='flex-1 flex flex-col gap-2'>
                    {sortAndFilterNodes<StepNode>(node.steps, formik, props).map((step) => {
                      const process = lists.processes.find((process) => process.id === step.process.id)!;
                      return (
                        <div
                          key={step.id}
                          className='px-4 py-1.5 -ml-1 flex flex-col gap-2 bg-zinc-50 rounded-lg shadow-[0_0_3px_rgba(0,0,0,0.25)]'
                        >
                          <div className='flex justify-between items-center'>
                            <div className='flex items-center gap-3'>
                              {renderDisclosureButton(
                                step,
                                cn({
                                  'border-[#C2410C] text-[#C2410C]': step.type === NodeType.ProductionIngredient,
                                  'border-[#A16207] text-[#A16207]': step.type === NodeType.ProductionPackaging,
                                  'border-cyan-700 text-cyan-700': step.type === NodeType.ConsumptionPreparation,
                                }),
                              )}
                              <div
                                className={cn('flex justify-center items-center rounded-md h-7 aspect-square', {
                                  'bg-[#FFF7ED] text-[#7C2D12]': step.type === NodeType.ProductionIngredient,
                                  'bg-[#FEFCE8] text-[#713F12]': step.type === NodeType.ProductionPackaging,
                                  'bg-cyan-50 text-cyan-900': step.type === NodeType.ConsumptionPreparation,
                                })}
                              >
                                {step.type === NodeType.ProductionIngredient && <FontAwesomeIcon icon={duotone('cauldron')} size='lg' />}
                                {step.type === NodeType.ProductionPackaging && <FontAwesomeIcon icon={duotone('conveyor-belt-boxes')} />}
                                {step.type === NodeType.ConsumptionPreparation && <FontAwesomeIcon icon={duotone('pan-frying')} />}
                              </div>
                              <div className='font-semibold text-zinc-700 w-44 truncate'>{step.displayName}</div>
                              {renderBadges(step, formik)}
                            </div>
                            <div className='flex items-center gap-5 text-zinc-600'>
                              {(() => {
                                const button = (
                                  <button type='button'>
                                    <FontAwesomeIcon icon={light('edit')} />
                                  </button>
                                );

                                const onSave = (params: ModalFormSaveParams<GenericStepNode, { sideEffects: UpdateSideEffects }>) => {
                                  replaceStep(params.values, params.metadata!, params.sideEffects, node, formik);
                                  params.closeModal();
                                };

                                return (
                                  <>
                                    {node.type === NodeType.Production && step.type === NodeType.ProductionIngredient && (
                                      <IngredientStepDetails key={step.id} parentNode={node} data={step} onSave={onSave}>
                                        {button}
                                      </IngredientStepDetails>
                                    )}
                                    {node.type === NodeType.Production && step.type === NodeType.ProductionPackaging && (
                                      <PackagingStepDetails key={step.id} parentNode={node} data={step} onSave={onSave}>
                                        {button}
                                      </PackagingStepDetails>
                                    )}
                                    {node.type === NodeType.Consumption && step.type === NodeType.ConsumptionPreparation && (
                                      <PreparationStepDetails key={step.id} parentNode={node} data={step} onSave={onSave}>
                                        {button}
                                      </PreparationStepDetails>
                                    )}
                                  </>
                                );
                              })()}
                              <button type='button' onClick={() => removeStep(step, node, formik)}>
                                <FontAwesomeIcon icon={light('trash-alt')} />
                              </button>
                            </div>
                          </div>
                          {expandedIds.includes(step.id) && (
                            <div className='flex gap-6 p-2 mb-1 -mx-1 bg-white border border-zinc-300 text-zinc-500 rounded-lg'>
                              <div className='flex-1 flex flex-col gap-2'>
                                <div className='uppercase text-xs font-semibold text-zinc-600 pl-2'>Inputs</div>
                                <div className='flex flex-col gap-1'>
                                  {step.inputs
                                    .map((input) => getInputNode(input, formik))
                                    .map((input) => (
                                      <div key={input.id} className='flex px-2 py-1 gap-3 rounded-lg bg-zinc-50'>
                                        <div
                                          className={cn('flex-1 py-0.5', {
                                            [cn('font-semibold', {
                                              'text-[#7C2D12]': type === NodeType.Production,
                                              'cyan-900': type === NodeType.Consumption,
                                            })]:
                                              getOutputs(formik).find(({ id }) => id === input.id)?.outputType.type ===
                                              OutputType.IntermediateProduct,
                                          })}
                                        >
                                          {input.name}
                                        </div>
                                        <div className='w-px bg-neutral-300' />
                                        <div className='self-center w-20 font-semibold text-xs'>
                                          {input.amount!.value}
                                          {input.amount!.unit.name}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              {(() => {
                                const electricity = step.process.overrides?.electricity;
                                const gas = step.process.overrides?.gas;
                                const water = step.process.overrides?.water?.input;
                                const auxiliaries = step.process.overrides?.auxiliaries ?? [];

                                const renderItem = (params: {
                                  label: string;
                                  count?: number;
                                  value?: number;
                                  unit?: string;
                                  expandSuffix?: string;
                                  expandIconPadding?: boolean;
                                }) => (
                                  <button
                                    className='flex justify-between items-center px-3.5 py-1.5 gap-3 rounded-lg bg-zinc-50'
                                    disabled={!params.expandSuffix}
                                    onClick={params.expandSuffix ? () => onDisclosureClick(`${step.id}.${params.expandSuffix}`) : undefined}
                                  >
                                    <div className='flex items-center gap-1'>
                                      {(params.expandSuffix || params.expandIconPadding) && (
                                        <div className='flex w-2.5'>
                                          <FontAwesomeIcon
                                            icon={
                                              expandedIds.includes(`${step.id}.${params.expandSuffix}`)
                                                ? regular('chevron-down')
                                                : regular('chevron-right')
                                            }
                                            size='xs'
                                            className={cn({
                                              invisible: params.expandIconPadding,
                                            })}
                                          />
                                        </div>
                                      )}
                                      <div>{params.label}</div>
                                      {params.count && (
                                        <div className='text-zinc-800 bg-white text-xs rounded-2xl px-2 py-1'>{params.count}</div>
                                      )}
                                    </div>
                                    <div>
                                      {params.value ?? '-'}
                                      {params.unit}
                                    </div>
                                  </button>
                                );

                                return (
                                  type === NodeType.Production &&
                                  (electricity?.value || gas?.value || water?.value || auxiliaries.length > 0) && (
                                    <>
                                      <div className='w-px bg-zinc-300' />
                                      <div className='flex-1 flex flex-col gap-2'>
                                        <div className='uppercase text-xs font-semibold text-zinc-600 pl-2'>Optional Inputs</div>
                                        <div className='flex flex-col gap-1'>
                                          {electricity?.value && (
                                            <>
                                              {renderItem({
                                                label: 'Electricity',
                                                count: electricity.types.length,
                                                value: electricity.value,
                                                unit: process.overrides!.electricity!.unit.name,
                                                expandSuffix: 'el',
                                              })}
                                              {expandedIds.includes(`${step.id}.el`) && (
                                                <>
                                                  {electricity.types.map((type) => (
                                                    <Fragment key={type.type}>
                                                      {renderItem({
                                                        label: type.name,
                                                        value: type.percent,
                                                        unit: '%',
                                                        expandIconPadding: true,
                                                      })}
                                                    </Fragment>
                                                  ))}
                                                </>
                                              )}
                                            </>
                                          )}
                                          {gas?.value &&
                                            renderItem({ label: 'Gas', value: gas.value, unit: process.overrides!.gas!.unit.name })}
                                          {water?.value &&
                                            renderItem({
                                              label: 'Water',
                                              value: water.value,
                                              unit: process.overrides!.water!.input!.unit.name,
                                            })}
                                          {auxiliaries.length > 0 && (
                                            <>
                                              {renderItem({ label: 'Auxiliary inputs', count: auxiliaries.length, expandSuffix: 'aux' })}
                                              {expandedIds.includes(`${step.id}.aux`) && (
                                                <>
                                                  {auxiliaries.map((auxiliary) => (
                                                    <Fragment key={auxiliary.name}>
                                                      {renderItem({
                                                        label: auxiliary.name,
                                                        value: auxiliary.value,
                                                        unit: process.overrides!.auxiliaries!.find(({ type }) => type === auxiliary.type)!
                                                          .unit.name,
                                                        expandIconPadding: true,
                                                      })}
                                                    </Fragment>
                                                  ))}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )
                                );
                              })()}
                              <div className='w-px bg-zinc-300' />
                              <div className='flex-1 flex flex-col gap-2'>
                                <div className='uppercase text-xs font-semibold text-zinc-600 pl-2'>Outputs</div>
                                <div className='flex flex-col gap-1'>
                                  {(type !== NodeType.Production || node.finalFacility) && step.finalStep ? (
                                    <div
                                      className={cn('flex px-2 py-1 gap-3 rounded-lg font-semibold text-white', {
                                        'bg-[#7D2038]': type === NodeType.Production,
                                        'bg-cyan-700': type === NodeType.Consumption,
                                      })}
                                    >
                                      <div className='flex-1 py-0.5'>
                                        {type === NodeType.Production ? formik.values.name : 'Ready to eat'}
                                      </div>
                                      <div className='w-px bg-neutral-300' />
                                      <div className='self-center w-20 font-semibold text-xs'>
                                        {(type === NodeType.Production ? formik.values.bruttoAmount : formik.values.amount).value}
                                        {formik.values.amount.unit.name}
                                      </div>
                                    </div>
                                  ) : (
                                    step.outputs.map((output) => (
                                      <div key={output.id} className='flex justify-between px-2 py-1 gap-3 rounded-lg bg-zinc-50'>
                                        <div
                                          className={cn('font-semibold py-0.5', {
                                            'text-[#7C2D12]': type === NodeType.Production,
                                            'cyan-900': type === NodeType.Consumption,
                                          })}
                                        >
                                          {output.name}
                                        </div>
                                        <div className='self-center text-xs uppercase text-right'>{output.outputType.name}</div>
                                      </div>
                                    ))
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {type !== NodeType.Consumption && renderTransports()}
                </div>
              )}
              {expanded && getStorageNodes(formik).some(({ id }) => id === node.id) && (
                <div className='flex gap-4 mb-1'>
                  <div className='flex-1 flex flex-col gap-3.5 px-2.5 pt-2 -ml-1 border border-zinc-300 rounded-lg'>
                    <div className='uppercase text-xs font-semibold text-zinc-600'>Stored Items</div>
                    <StorageItemsTable data={node as StorageNode} {...props} />
                  </div>
                  {renderTransports()}
                </div>
              )}
              {expanded && type === NodeType.Disposal && getDisposalPackaging(node) && (
                <div className='grid grid-cols-[calc(theme(spacing.1)*15)_calc(theme(spacing.1)*40)_1fr] gap-x-3 gap-y-3.5 pb-2.5 text-zinc-600'>
                  <div className='col-start-2 text-zinc-800'>Packaging materials</div>
                  <div>{renderMaterials(getDisposalPackaging(node)!)}</div>
                  <div className='col-start-2 text-zinc-800'>Recyclability</div>
                  <div>{node.disposalType.name}</div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export const ListLayoutBars = (props: {
  message: MessageId | '';
  setMessage: (value: MessageId | '') => void;
  stage: LifeCycleStage;
  setStage: (value: LifeCycleStage) => void;
  filter: NodeType[];
  setFilter: (value: NodeType[]) => void;
  search: string;
  setSearch: (value: string) => void;
  messageRef: RefObject<HTMLDivElement>;
  addPackagingModal: RefObject<ModalFormApi>;
  addWarehouseModal: RefObject<ModalFormApi>;
  addStoreModal: RefObject<ModalFormApi>;
}) => {
  const formik = useFormikContext<ProductV3>();
  const final = formik.values.productType === ProductType.Final;
  const intermediate = formik.values.productType === ProductType.Intermediate;
  const stages = [
    { stage: LifeCycleStage.RawMaterials, label: 'Raw materials' },
    { stage: LifeCycleStage.Production, label: 'Production' },
    ...(final || intermediate ? [{ stage: LifeCycleStage.Distribution, label: 'Distribution' }] : []),
    ...(final
      ? [
          { stage: LifeCycleStage.Use, label: 'Use' },
          { stage: LifeCycleStage.EndOfLife, label: 'End of life' },
        ]
      : []),
  ];

  useEffect(() => {
    props.setFilter(getNodeTypesForLifecycleStage(props.stage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stage]);

  return (
    <>
      <div className='flex justify-center bg-[#E8EAF5]'>
        <div className='flex flex-col gap-3 px-12 py-3 w-[theme(screens.xl)]'>
          {props.message && (
            <div className='mt-1'>
              <Message ref={props.messageRef} id={props.message} layout={Layout.List} onClose={() => props.setMessage('')} />
            </div>
          )}
          <div className='flex gap-2'>
            {stages.map(({ stage, label }, i) => (
              <button
                key={stage}
                type='button'
                className={cn(
                  'p-1 flex justify-between items-center flex-1 bg-white rounded-full',
                  stage === props.stage ? 'shadow-[0_0_6px_rgba(79,0,255,0.3)]' : 'shadow-[0_0_3px_rgba(0,0,0,0.25)]',
                )}
                onClick={() => props.setStage(stage)}
              >
                <div className='flex gap-2 items-center'>
                  <div
                    className={cn(
                      'flex justify-center items-center w-6 aspect-square rounded-full shadow-[0_0_3px_rgba(0,0,0,0.25)]',
                      stage === props.stage ? 'text-white bg-[#330099]' : 'text-[#330099] bg-[#E8EAF5]',
                    )}
                  >
                    {i + 1}
                  </div>
                  <div
                    className={cn({
                      'font-semibold text-[#220066]': stage === props.stage,
                    })}
                  >
                    {label}
                  </div>
                </div>
                <div
                  className={cn(
                    'place-self-end flex justify-center items-center w-6 aspect-square rounded-full text-white',
                    getPrimaryAndMaterialSuppliers(formik)
                      .filter(({ type }) => getNodeTypesForLifecycleStage(stage).includes(type))
                      .some(({ edges }) => edges.length > 0)
                      ? 'bg-brand'
                      : 'bg-zinc-500',
                    {
                      invisible: [LifeCycleStage.Use, LifeCycleStage.EndOfLife].includes(stage),
                    },
                  )}
                >
                  <FontAwesomeIcon icon={duotone('truck')} size='sm' />
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className='flex justify-center bg-white'>
        <div className='flex items-center gap-3 px-12 h-16 w-[theme(screens.xl)]'>
          <div className='flex-1 flex items-center gap-4'>
            <div className='font-semibold text-base text-zinc-900'>{stages.find(({ stage }) => stage === props.stage)!.label}</div>
            {![LifeCycleStage.Use, LifeCycleStage.EndOfLife].includes(props.stage) &&
              (() => {
                const separator = <div className='w-px bg-zinc-300 h-7'></div>;
                const button = (label: string, nodeTypes: NodeType[]) => (
                  <button
                    type='button'
                    className={cn('leading-none uppercase font-semibold text-xs flex flex-col gap-1 pt-2', {
                      'text-zinc-800': isEqual(nodeTypes, props.filter),
                    })}
                    onClick={() => props.setFilter(nodeTypes)}
                  >
                    <div className='px-1'>{label}</div>
                    <div
                      className={cn('w-full h-0.5 bg-brand', {
                        invisible: !isEqual(nodeTypes, props.filter),
                      })}
                    />
                  </button>
                );

                return (
                  <div className='flex gap-3 text-zinc-500'>
                    {separator}
                    {button('All', getNodeTypesForLifecycleStage(props.stage))}
                    {separator}
                    {(() => {
                      switch (props.stage) {
                        case LifeCycleStage.RawMaterials:
                          return button('Ingredients', [NodeType.Ingredient]);
                        case LifeCycleStage.Production:
                          return button('Prod. Facilities', [NodeType.Production]);
                        case LifeCycleStage.Distribution:
                          return button('Storage', [NodeType.Warehouse]);
                      }
                    })()}
                    {separator}
                    {(() => {
                      switch (props.stage) {
                        case LifeCycleStage.RawMaterials:
                          return button('Packagings', [NodeType.Packaging]);
                        case LifeCycleStage.Production:
                          return button('Storage', [NodeType.ProductionWarehouse]);
                        case LifeCycleStage.Distribution:
                          return intermediate
                            ? button('Final Destinations', [NodeType.FinalDestination])
                            : button('Stores', [NodeType.Store]);
                      }
                    })()}
                  </div>
                );
              })()}
          </div>
          <div className='flex gap-2.5 font-semibold'>
            {(() => {
              switch (props.stage) {
                case LifeCycleStage.RawMaterials:
                  return renderRawMaterialsAddButtons(props.addPackagingModal, formik);
                case LifeCycleStage.Production:
                  return renderProductionAddButtons(formik);
                case LifeCycleStage.Distribution:
                  return renderDistributionAddButtons(props.addWarehouseModal, props.addStoreModal, formik);
                case LifeCycleStage.Use:
                  return renderUseAddButtons(formik);
                case LifeCycleStage.EndOfLife:
                  return <></>;
              }
            })()}
          </div>
          <div className='flex-1 flex justify-end'>
            <div className='relative flex items-center'>
              <input
                autoFocus
                className='px-3.5 py-1 border border-neutral-500 placeholder:text-neutral-500 rounded-full'
                placeholder='Find…'
                value={props.search}
                onChange={(event) => props.setSearch(event.target.value)}
              />
              <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute right-4 text-neutral-500' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
