import { TooltipV3 } from './TooltipV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { simplify, roundToLong, roundToShort } from '../Root/Main/shared';
import { ImpactStageMatrix } from '../api';

interface Props {
  data: ImpactStageMatrix[];
  totalImpact: number;
  totalPoints: number;
}

export const ByImpactContributionTable = (props: Props) => {
  return (
    <div className='text-dark bg-white overflow-hidden'>
      <div className='flex flex-col gap-2 border-t'>
        <table className='w-full rounded-b-2xl overflow-hidden'>
          <thead className='text-xs'>
            <tr className='text-zinc-500'>
              <th className='py-3 pl-6 uppercase w-42'>impact category</th>
              <th className='uppercase'>physical impact</th>
              <th className='uppercase'>final environmental impact</th>
              <th>
                <TooltipV3
                  content={
                    <div className='flex flex-col gap-3 rounded-lg font-normal text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 max-w-[250px] shadow-[0_0_4px_rgba(0,0,0,0.15)]'>
                      <div>
                        Contribution to the total impact is calculated using the absolute values of the impacts (ignoring the
                        negatives/positives) according to PEF.
                      </div>
                      <div>This is why any negative impact process will appear as having a positive contribution.</div>
                    </div>
                  }
                >
                  <div className='uppercase'>contribution</div>
                </TooltipV3>
              </th>
              <th />
            </tr>
          </thead>
          <tbody className='text-xs divide-y border-t'>
            {props.data.map((impact) => (
              <tr key={impact.impactName} className={cn({ 'bg-amber-50': impact.isMajorImpact })}>
                <td className='py-3 pl-6'>
                  <div className='w-40 truncate'>{impact.impactName}</div>
                </td>
                <td title={roundToLong(impact.physicalValue).toString()}>{`${simplify(impact.physicalValue)} ${impact.unit}`}</td>
                <td>
                  <div className='flex items-center gap-1'>
                    <span title={roundToLong(impact.weightedNormalisedValue)} className='whitespace-nowrap'>
                      {simplify(impact.weightedNormalisedValue)}
                    </span>
                    <span>weighted person.year</span>
                    <span className='text-zinc-500 uppercase text-[70%]'>or</span>
                    <span title={roundToLong(impact.impactPoints)}>{simplify(impact.impactPoints)}</span>
                    <span>impact points</span>
                  </div>
                </td>
                <td>{roundToShort(impact.absSharePercent)}%</td>
                <td className='px-3'>
                  <TooltipV3
                    disabled={!impact.isMajorImpact}
                    content={
                      <div
                        className={cn(
                          'flex flex-col gap-3 rounded-lg font-normal text-xs text-zinc-800 bg-[#DACEFD] px-2 py-1 shadow-[0_0_4px_rgba(0,0,0,0.15)]',
                        )}
                      >
                        Most relevant
                      </div>
                    }
                  >
                    <span>
                      <FontAwesomeIcon
                        className={cn('w-full self-center justify-self-center text-amber-400 text-xl', {
                          invisible: !impact.isMajorImpact,
                        })}
                        icon={solid('seal-exclamation')}
                      />
                    </span>
                  </TooltipV3>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className='bg-slate-50 text-brand text-xs'>
              <td className='pl-6 py-3 font-semibold'>Total</td>
              <td></td>
              <td>
                <div className='flex items-center gap-1'>
                  <span title={roundToLong(props.totalImpact)} className='whitespace-nowrap'>
                    {simplify(props.totalImpact)}
                  </span>
                  <span>weighted person.year</span>
                  <span className='text-zinc-500 uppercase text-[70%]'>or</span>
                  <span title={roundToLong(props.totalPoints)}>{simplify(props.totalPoints)}</span>
                  <span>impact points</span>
                </div>
              </td>
              <td>100%</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
