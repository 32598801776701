import { Formik, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useHref, useLocation, useNavigate, useParams } from 'react-router';
import { ProductState, ProductType, ProductV3, getProductV3, getRevisionV3, updateProductV3, validateProduct } from '../../../../api';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { BasicInfo } from './BasicInfo';
import { Graph } from './Graph';

export const Details = () => {
  const { productId, revision } = useParams<{ productId: string; revision: string }>();
  const revisionQuery = useApiQuery(getRevisionV3(productId!, Number(revision)), { cancel: !revision });
  const productQuery = useApiQuery(getProductV3(productId!), { cancel: !!revision });
  const data = revision ? revisionQuery.data?.product : productQuery.data;

  if (!data) {
    return <></>;
  }

  return (
    <Formik initialValues={data} onSubmit={() => {}}>
      <Content initialData={data} revision={!!revision} />
    </Formik>
  );
};

const Content = (props: { initialData: ProductV3; revision: boolean }) => {
  const info = 'info';
  const graph = 'graph';
  const infoHref = useHref(info);
  const location = useLocation();
  const navigate = useNavigate();
  const onInfoPage = location.pathname === infoHref;
  const isNew = !props.initialData.skuId;

  const formik = useFormikContext<ProductV3>();
  const ignoreValuesChange = useRef(!props.revision);
  const draft = formik.values.state === ProductState.Draft;
  const [waiting, setWaiting] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const undoing = useRef(false);
  const [undoState, setUndoState] = useState({ current: 0, list: [formik.values] });
  const [newUndo, setNewUndo] = useState<ProductV3 | undefined>();
  const canUndo = undoState.current > 0 && !waiting && !undoing.current;
  const canRedo = undoState.current < undoState.list.length - 1 && !waiting && !undoing.current;

  const onUndo = () => {
    undoing.current = true;
    formik.setValues(undoState.list[undoState.current - 1]);
    setUndoState((old) => ({
      ...old,
      current: old.current - 1,
    }));
  };

  const onRedo = () => {
    undoing.current = true;
    formik.setValues(undoState.list[undoState.current + 1]);
    setUndoState((old) => ({
      ...old,
      current: old.current + 1,
    }));
  };

  useEffect(() => {
    if (!ignoreValuesChange.current) {
      const hasChanges = () => {
        const getInfoPageData = (data: ProductV3) => {
          const cloned = cloneDeep(data);
          delete (cloned as any).nodes;
          delete (cloned as any).validation;
          return cloned;
        };

        return !onInfoPage || !isEqual(getInfoPageData(props.initialData), getInfoPageData(formik.values));
      };

      setWaiting(true);
      (draft ? updateProductV3 : validateProduct)(formik.values).call({
        ok: (data) => {
          ignoreValuesChange.current = true;
          setWaiting(false);

          if (!unsavedChanges && !draft) {
            setUnsavedChanges(hasChanges());
          }

          formik.setValues(data);

          if (!undoing.current) {
            setNewUndo(data);
          }

          undoing.current = false;

          if (onInfoPage) {
            navigate(graph);
          }
        },
        fail: () => {
          setWaiting(false);
        },
      });
    }

    ignoreValuesChange.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  useEffect(() => {
    if (newUndo) {
      setUndoState((old) => ({
        current: old.current + 1,
        list: [...old.list.slice(0, old.current + 1), newUndo],
      }));
    }
  }, [newUndo]);

  useEffect(() => {
    const onKey = (event: KeyboardEvent) => {
      if (!waiting && event.key === 'z' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        const redo = event.shiftKey;

        if (redo) {
          if (canRedo) {
            onRedo();
          }
        } else {
          if (canUndo) {
            onUndo();
          }
        }
      }
    };

    window.addEventListener('keydown', onKey);

    return () => window.removeEventListener('keydown', onKey);
  });

  const title = formik.values.name
    ? `${
        formik.values.amount?.value && formik.values.amount?.unit
          ? `${formik.values.amount.value}${formik.values.amount.unit.name} of `
          : ''
      }${formik.values.name}`
    : {
        [ProductType.Final]: 'New Product',
        [ProductType.Intermediate]: 'New Intermediate Product',
        [ProductType.Internal]: 'New Internal Product',
      }[formik.values.productType];

  return (
    <div className='-mt-6 -mb-1 text-neutral-900 text-sm'>
      <Helmet title={title} />
      <Routes>
        <Route path={info} element={<BasicInfo title={title} waiting={waiting} unsavedChanges={unsavedChanges} />} />
        <Route
          path={graph}
          element={
            <Graph
              title={title}
              waiting={waiting}
              unsavedChanges={unsavedChanges}
              canUndo={canUndo}
              canRedo={canRedo}
              onUndo={onUndo}
              onRedo={onRedo}
              revision={props.revision}
            />
          }
        />
        <Route path='*' element={<Navigate to={isNew ? info : graph} replace />} />
      </Routes>
    </div>
  );
};
