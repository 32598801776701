export const ContextualExamplesAssumptions = () => (
  <div className='mt-6 flex flex-col gap-8'>
    <div className='flex flex-col gap-3'>
      <div>
        A life cycle assessment is a scientific method after all. As such, the outputs are not always straightforward and easy to
        contextualise for the average person.
      </div>
      <div>
        To help <span className='text-brandDark'>you and your consumers</span> better understand the extent of the physical impacts we’re
        talking about, we have come up with a number of ‘contextual examples’ for 6 of the 16 impact categories recommended by PEF. Those
        six are the ones that can be most easily associated with something out of our everyday lives and are also considered to be the most
        important under the PEF framework (ie. highest weighting factors).
      </div>
      <div>For each impact category, a number of examples were derived:</div>
    </div>
    <div className='flex flex-col gap-2'>
      <div className='text-brandDark text-lg font-semibold'>Climate Change</div>
      <div className='font-semibold'>
        CO<sub>2</sub> values from a number of activities and processes, such a travelling, trees absorption rate and industrial production,
        are used as reference points when comparing the physical impact values of your product(s) for the climate change category.
      </div>
      <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-4'>
        <li>
          <div className='flex flex-col gap-2'>
            <div className='font-semibold'>
              CO<sub>2</sub> emitted for a specific car journey
            </div>
            <div>The number of car journeys is calculated using the following formula:</div>
            <div className='text-brandDark'>
              kg CO<sub>2</sub>e per car journey = ( Distance / average car efficiency ) * CO<sub>2</sub> emissions per litre of fuel
            </div>
            <div>Where,</div>
            <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-2'>
              <li>
                <div>Distance = one of the reference trip distances from the following list, calculated assuming a straight line.</div>
                <ul className='list-disc marker:text-brandDark ml-6 mt-1 flex flex-col gap-1'>
                  {[
                    { origin: 'London - Luton', value: '59.64km' },
                    { origin: 'Manchester - Liverpool', value: '59.64km' },
                    { origin: 'London - Reading', value: '70.95km' },
                    { origin: 'Leeds - Liverpool', value: '125.34km' },
                    { origin: 'London - Leicester', value: '165.56km' },
                    { origin: 'London - Manchester', value: '346.27km' },
                    { origin: 'London - Edinburgh', value: '656.99km' },
                    { origin: 'Aberdeen - London', value: '875.60km' },
                  ].map(({ origin, value }, i) => (
                    <li key={i}>
                      <div className='grid grid-cols-[250px_auto]'>
                        <div>{origin}</div>
                        <div>{value}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                Average Car efficiency = 8.69 miles per litre of fuel, extrapolated from an average 39.5 miles per gallon for diesel and
                petrol car (
                <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                  source
                </a>
                ), and the fact that 1 gallon = 4.546 litres.
              </li>
              <li>
                CO<sub>2</sub> emissions per litre of fuel = 2.12 kg CO<sub>2</sub> (source)
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className='flex flex-col gap-2'>
            <div className='font-semibold'>
              CO<sub>2</sub> emitted for a specific plane journey
            </div>
            <div>The number of flights is calculated using the following formula:</div>
            <div className='text-brandDark'>
              kg CO<sub>2</sub>e per flight = Distance * Average fuel economy * CO<sub>2</sub> emissions per kg of fuel
            </div>
            <div>Where,</div>
            <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-2'>
              <li>
                <div>Distance = one of the reference trip distances from the following list, calculated assuming a straight line.</div>
                <ul className='list-disc marker:text-brandDark ml-6 mt-1 flex flex-col gap-1'>
                  {[
                    { origin: 'Leeds - Liverpool', value: '77.99km' },
                    { origin: 'London - Leicester', value: '102.94km' },
                    { origin: 'Birmingham - Swansea', value: '144.02km' },
                    { origin: 'London - Manchester', value: '215.01km' },
                    { origin: 'London - Edinburgh', value: '408.01km' },
                    { origin: 'Aberdeen - London', value: '544.02km' },
                  ].map(({ origin, value }, i) => (
                    <li key={i}>
                      <div className='grid grid-cols-[250px_auto]'>
                        <div>{origin}</div>
                        <div>{value}</div>
                      </div>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                Average fuel economy = 3.13 kg/km (
                <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                  source
                </a>
                )
              </li>
              <li>
                CO<sub>2</sub> emissions per kg of fuel = 3.15 kg CO<sub>2</sub> (
                <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                  source
                </a>
                )
              </li>
            </ul>
          </div>
        </li>
        <li>
          <div className='flex flex-col gap-2'>
            <div>
              CO<sub>2</sub> emitted when producing a certain number of kilograms of cement
            </div>
            <div>
              The CO<sub>2</sub> production rate per 1kg of cement produced, of 0.622 kg CO<sub>2</sub>e/kg (
              <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                source
              </a>
              ), is used to derive the number of kilograms of cement that would need to be produced to emit as much CO<sub>2</sub> as your
              product(s).
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div className='flex flex-col gap-2'>
      <div className='text-brandDark text-lg font-semibold'>Land Use</div>
      <div className='font-semibold'>
        The land use impact is compared to the impact of occupying a certain size of land for a year for urban purposes. To do so, the
        ‘points’ values are divided by a factor of 139.88, representative of the impact of occupying land for urban purposes (which is
        different than occupying it for other types of uses ie. industrial). As a result, the actual area of land that would need to be
        occupied for a year to cause said impact is obtained. This m<sup>2</sup> value is finally compared to a set of known sizes of land
        for the land use category. How many of these fixed areas is presented as a contextual example.
      </div>
      <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-4'>
        <li>
          <div className='font-semibold'>
            M<sup>2</sup> from a certain number of the following land uses:
          </div>
          <ul className='list-disc marker:text-brandDark ml-6 mt-1 flex flex-col gap-1'>
            {[
              {
                origin: 'Concrete slab',
                value: (
                  <span>
                    0.2025 m<sup>2</sup> (
                    <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                      source
                    </a>
                    )
                  </span>
                ),
              },
              {
                origin: 'Tennis court',
                value: (
                  <span>
                    260.8 m<sup>2</sup> (
                    <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                      source
                    </a>
                    )
                  </span>
                ),
              },
              {
                origin: 'Basketball court',
                value: (
                  <span>
                    436.6 m<sup>2</sup>
                  </span>
                ),
              },
              {
                origin: 'Football field',
                value: (
                  <span>
                    7140 m<sup>2</sup>
                  </span>
                ),
              },
            ].map(({ origin, value }, i) => (
              <li key={i}>
                <div className='grid grid-cols-[200px_auto]'>
                  <div>{origin}</div>
                  <div>{value}</div>
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
    <div className='flex flex-col gap-2'>
      <div className='text-brandDark text-lg font-semibold'>Water Scarcity</div>
      <div className='font-semibold'>
        The water scarcity impact is compared to the impact of permanently removing a certain amount of water, in known volumes, from the
        ground in the UK. To do so, the ‘m<sup>3</sup> deprivation’ values, the physical impact values for this category, are divided by a
        UK-specific factor of 3.5, representative of how scarce water is in the UK (when considering all existing demands and availability).
        As a result, the actual volume of water that would need to be permanently removed from the ground to cause said impact is obtained.
        This m<sup>3</sup> value is finally compared to a set of volumes that are known to everyone for the water scarcity category. How
        many of these fixed volumes is presented as a contextual example.
      </div>
      <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-4'>
        <li>
          <div className='font-semibold'>
            M<sup>3</sup> from a certain number of:
          </div>
          <ul className='list-disc marker:text-brandDark ml-6 mt-1 flex flex-col gap-1'>
            {[
              {
                origin: 'Glass of water',
                value: (
                  <span>
                    0.00025 m<sup>3</sup>
                  </span>
                ),
              },
              {
                origin: 'Bottle of water',
                value: (
                  <span>
                    0.0005 m<sup>3</sup>
                  </span>
                ),
              },
              {
                origin: 'Toilet flush',
                value: (
                  <span>
                    0.0045 m<sup>3</sup>
                  </span>
                ),
              },
              {
                origin: 'Bathtub',
                value: (
                  <span>
                    0.1 m<sup>3</sup>
                  </span>
                ),
              },
            ].map(({ origin, value }, i) => (
              <li key={i}>
                <div className='grid grid-cols-[200px_auto]'>
                  <div>{origin}</div>
                  <div>{value}</div>
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
    <div className='flex flex-col gap-2'>
      <div className='text-brandDark text-lg font-semibold'>Particulate Matter</div>
      <div className='font-semibold'>
        Disease incidence values, representing the potential for human health impact of inhaling a substance, for dust in the air is used as
        a reference point when comparing the physical impact values of your product(s) for the particulate matter category.
      </div>
      <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-4'>
        <li>
          <div className='font-semibold'>Disease incidence from a certain number of kilograms of dust (PM 2.5) in the air</div>
          <div className='mt-2'>
            The disease incidence of 1kg of dust in the air is 7.00E-04 (
            <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
              source
            </a>
            )
          </div>
        </li>
      </ul>
    </div>
    <div className='flex flex-col gap-2'>
      <div className='text-brandDark text-lg font-semibold'>Resource Use, Fossil</div>
      <div className='font-semibold'>
        The physical impact of this category is measured in terms of the energy density of a fossil resource being extracted from the
        ground, in MJ. To simplify the understanding, we calculate the amount of electricity that could be produced in a coal power plant,
        in kWh, using a certain amount of coal extracted from the ground. These kWh’s derived from the resource use, fossil category output,
        are compared to the electricity use of some routine activities.
      </div>
      <div className='mt-2 font-semibold'>
        In practice, to calculate the kWh values for this impact, the physical impact value in MJ is divided by 29 MJ (the calorific value
        of a kg of coal) to find how many kilograms of coal it is equivalent to. This result is then divided by 0.38kg, the amount of coal
        needed to produce 1kWh of electricity at a coal power plant with a 38% efficiency rate (
        <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
          source
        </a>
        ), The resulting kWhs are then compared to the electricity use of the following:
      </div>
      <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-4 mt-3'>
        <li>
          <div className='font-semibold'>kWh needed to complete a full charge of your phone</div>
          <div>
            An electricity consumption rate of 0.005kWh (
            <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
              source
            </a>
            ) is used to derive how many times you can change your phone fully full charges you can minutes an average microwave can run
            with the resource use impact of your product(s).
          </div>
        </li>
        <li>
          <div className='font-semibold'>kWh needed to microwave for a given number of minutes</div>
          <div>
            An electricity consumption rate of 0.01667kWh is used to derive how many minutes an average microwave can run with the resource
            use impact of your product(s).
          </div>
        </li>
        <li>
          <div className='font-semibold'>kWh needed to power an LED for given number of days</div>
          <div>
            An electricity consumption rate of 0.24 kWh is used to derive how many days you can leave a LED lightbulb on with the resource
            use impact of your product(s).
          </div>
        </li>
        <li>
          <div className='font-semibold'>kWh needed to power your TV for a given number of hours</div>
          <div>
            An electricity consumption rate of 0.08 kWh is used to derive how many hours you can watch TV leave a LED lightbulb on with the
            resource use impact of your product(s)
          </div>
        </li>
      </ul>
    </div>
    <div className='flex flex-col gap-2'>
      <div className='text-brandDark text-lg font-semibold'>Resource Use, Minerals & Metals</div>
      <div>
        Kg Sb eq values, representing the depletion potential of a given resource normalised to antimony as a reference substance, for a set
        of different minerals are used as reference points when comparing the physical impact values of your product(s) for the resource
        use, minerals and metals category.
      </div>
      <ul className='list-disc marker:text-brandDark ml-6 flex flex-col gap-4'>
        <li>
          <div className='font-semibold'>Kg Sb eq impact of extracting known minerals:</div>
          <ul className='list-disc marker:text-brandDark ml-6 mt-1 flex flex-col gap-1'>
            {[
              {
                text: '1g of Aluminium',
                value: (
                  <span>
                    1.09E-12 kg Sb eq (
                    <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                      source
                    </a>
                    )
                  </span>
                ),
              },
              {
                text: '1g of Silver',
                value: (
                  <span>
                    0.00118 kg Sb eq (
                    <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                      source
                    </a>
                    )
                  </span>
                ),
              },
              {
                text: '1g of Gold',
                value: (
                  <span>
                    5.20E-02 kg Sb eq(
                    <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                      source
                    </a>
                    )
                  </span>
                ),
              },
              {
                text: '1g of Copper',
                value: (
                  <span>
                    2.22E-03 kg Sb eq (
                    <a className='underline hover:text-brandDark' href='https://www.sustained.com' rel='noreferrer' target='_blank'>
                      source
                    </a>
                    )
                  </span>
                ),
              },
            ].map(({ text, value }, i) => (
              <li key={i}>
                <div className='grid grid-cols-[200px_auto]'>
                  <div>{text}</div>
                  <div>{value}</div>
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  </div>
);
