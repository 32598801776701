import { NavLink } from 'react-router-dom';
import { ForecastReport, ReportType } from '../../../../../api';
import { NavigationButtons } from '../../../Products/Report/Sku/components/NavigationButtons';
import { Lcia } from '../../components/Lcia';

interface Props {
  data: ForecastReport;
}

export const ForecastLcia = (props: Props) => {
  const description = (
    <div className='flex flex-col gap-8'>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-col gap-y-3 text-brandGray5'>
          <div>
            A comprehensive look at the environmental impact of all units of products selected, intended as the representation of your
            forecasted volume output over a {props.data.cycle}. A product’s total environmental impact is calculated across a number of
            impact categories, 16 in total, each with their own unit of measurement. The absolute impact of each category is referred to as
            the ‘physical output’ in this section and is shown in scientific units (eg. kg CO2e for climate change).
          </div>

          <div>
            In order to make these physical outputs comparable and obtain the final environmental impacts, normalisation and weightings are
            then applied (using the factors presented in the{' '}
            <NavLink to='../appendix' onClick={() => window.scrollTo({ top: 0 })} className='hover:text-brand font-semibold underline'>
              Appendix
            </NavLink>
            ), as per the PEF framework. These final environmental impacts are calculated in weighted person year, which compares the impact
            of your product to the impact an average person has in one year (similarly weighted). For more details, you can read our{' '}
            <NavLink
              to='/methodology'
              target='_blank'
              className='hover:text-brand font-semibold underline'
              onClick={() => window.scrollTo({ top: 0 })}
            >
              methodology
            </NavLink>
            .
          </div>
        </div>
        <NavigationButtons type='icons' back={{ path: '../baseline-comparison' }} next={{ path: '../interpretation' }} />
      </div>
    </div>
  );

  return (
    <div className='flex flex-col gap-y-6'>
      <Lcia description={description} data={props.data} reportType={ReportType.Forecast} />
      <NavigationButtons
        back={{ path: '../baseline-comparison', label: 'Baseline Comparison' }}
        next={{ path: '../interpretation', label: 'Interpretation' }}
        type='buttons'
      />
    </div>
  );
};
