import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';
import { ImpactStageMatrix } from '../api';

interface Props {
  data?: {
    impactStageMatrix?: ImpactStageMatrix[];
    impactStagesMatrix?: ImpactStageMatrix[];
  };
}

export const useCsvByImpactCategoryHref = (props: Props) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    if (props.data) {
      const rows = (props.data.impactStageMatrix ?? props.data.impactStagesMatrix)!.map((impact) => [
        `"${impact.impactName}"`,
        impact.weightedNormalisedValue,
        impact.impactPoints,
        impact.physicalValue,
        impact.unit,
        impact.absSharePercent,
      ]);

      setHref(
        URL.createObjectURL(
          new Blob(
            [
              [
                [
                  'Impact category',
                  'Final env. impact (weighted person.year)',
                  'Final env. impact (impact points)',
                  'Physical impact',
                  'Physical impact unit',
                  'Contribution (%)',
                ].map((label) => `"${label}"`),
                ...[...rows, ['TOTAL', sumBy(rows, (row) => row[1] as number), sumBy(rows, (row) => row[2] as number), '', '', '100%']].map(
                  (row) => row.join(','),
                ),
              ].join('\n'),
            ],
            { type: 'text/csv' },
          ),
        ),
      );
    }
  }, [props.data]);

  return href;
};
