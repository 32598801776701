import DatePicker from 'react-datepicker';
import { TooltipV3 } from '../TooltipV3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import ReactSelect, { StylesConfig } from 'react-select';
import { FieldProps } from 'formik';
import { convertLocalToUTCDate, convertUTCDateToLocal, months, years } from './utils';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import sub from 'date-fns/sub';
import cn from 'classnames';

interface Props {
  openToDate: Date | null;
  model: FieldProps<{ startDate: Date | null; endDate: Date | null }>;
  existedReports:
    | {
        reportName: string;
        dateRange: {
          start: Date;
          end: Date;
        };
      }[]
    | null;
}

export const TimeframeDatePicker = (props: Props) => {
  const onChange = async (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates;
    props.model.form.setFieldValue('range', {
      ...props.model.field.value,
      startDate: convertLocalToUTCDate(startDate),
      endDate: convertLocalToUTCDate(endDate),
    });
  };

  return (
    <div className='range-datepicker flex flex-col rounded-full text-sm'>
      <DatePicker
        isClearable
        selectsRange
        monthsShown={2}
        onChange={onChange}
        maxDate={new Date()}
        dateFormat='dd/MM/yyyy'
        focusSelectedMonth={false}
        adjustDateOnChange={false}
        forceShowMonthNavigation={false}
        renderDayContents={(day: number, date: Date) => <RenderDayContents {...{ existedReports: props.existedReports, day, date }} />}
        openToDate={props.openToDate ?? undefined}
        endDate={props.model.field.value.endDate}
        startDate={props.model.field.value.startDate}
        className={cn(
          'py-1.5 rounded-full w-32 pl-4 text-sm text-dark border',
          props.model.meta.error && 'border-f focus:ring-focusRingError',
        )}
        renderCustomHeader={({ monthDate, date, changeYear, changeMonth, decreaseMonth, increaseMonth, customHeaderCount }) => (
          <CustomHeader {...props} {...{ monthDate, date, changeYear, changeMonth, decreaseMonth, increaseMonth, customHeaderCount }} />
        )}
      />
    </div>
  );
};

interface HeaderProps extends Props {
  date: Date;
  monthDate: Date;
  changeYear: (year: number) => void;
  changeMonth: (month: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  customHeaderCount: number;
}

const CustomHeader = (props: HeaderProps) => {
  const styles: StylesConfig<any> = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0.375rem',
      borderWidth: '1px',
      borderColor: '#e5e7eb',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        color: state.isSelected ? '#ffffff' : '#313030',
        backgroundColor: state.isSelected ? '#4f00ff' : '#fff',
        '&:hover': {
          backgroundColor: state.isSelected ? '#4f00ff' : '#efefef80',
          cursor: 'pointer',
        },
      };
    },
  };

  return (
    <div className='text-lg font-semibold pb-1 pt-3'>
      {props.customHeaderCount === 0 ? (
        <div className='pl-6 flex items-center justify-between -mr-14'>
          <FontAwesomeIcon
            className='hover:cursor-pointer text-dark hover:text-brand'
            icon={regular('chevron-left')}
            onClick={() => props.decreaseMonth()}
          />
          <ReactSelect<{ value: string; label: string }>
            styles={styles}
            className='z-[2]'
            value={{
              value: months[getMonth(props.monthDate)],
              label: months[getMonth(props.monthDate)],
            }}
            isMulti={false}
            options={months.map((month) => ({ value: month, label: month }))}
            onChange={(month) => {
              month && props.changeMonth(months.indexOf(month.value));
            }}
            isSearchable={false}
          />
          <ReactSelect<{ value: number; label: number }>
            styles={styles}
            className='z-[2]'
            value={{
              value: getYear(props.date),
              label: getYear(props.date),
            }}
            isMulti={false}
            isSearchable={false}
            options={years(sub(props.date, { years: 10 }).getFullYear(), new Date().getFullYear() + 1)
              .map((year) => ({
                value: year,
                label: year,
              }))
              .reverse()}
            onChange={(value) => value && props.changeYear(value.value)}
          />
        </div>
      ) : (
        <div className='flex justify-between items-center pr-6'>
          <div />
          <ReactSelect<{ value: string; label: string }>
            {...props}
            styles={styles}
            className='z-[2]'
            value={{
              value: months[getMonth(props.monthDate)],
              label: months[getMonth(props.monthDate)],
            }}
            isMulti={false}
            options={months.map((month) => ({ value: month, label: month }))}
            onChange={(month) => {
              month && props.changeMonth(months.indexOf(month.value) - 1);
            }}
            isSearchable={false}
          />
          <FontAwesomeIcon
            className='hover:cursor-pointer text-dark hover:text-brand'
            icon={regular('chevron-right')}
            onClick={() => props.increaseMonth()}
          />
        </div>
      )}
    </div>
  );
};

interface RenderDayContentsProps {
  day: number;
  date: Date;
  existedReports:
    | {
        reportName: string;
        dateRange: {
          start: Date;
          end: Date;
        };
      }[]
    | null;
}

const RenderDayContents = (props: RenderDayContentsProps) => {
  const excluded = props.existedReports?.find((it) => {
    return (
      (isSameDay(props.date, convertUTCDateToLocal(it.dateRange.start)) ||
        isAfter(props.date, convertUTCDateToLocal(it.dateRange.start))) &&
      (isSameDay(props.date, convertUTCDateToLocal(it.dateRange.end)) || isBefore(props.date, convertUTCDateToLocal(it.dateRange.end)))
    );
  });
  return (
    <div className={cn('relative', excluded && 'text-neutral-400')}>
      <div>{props.day}</div>
      {excluded && (
        <TooltipV3
          content={
            <div className='p-1.5 px-3 text-sm whitespace-nowrap bg-violet-900 text-white rounded-lg shadow'>{excluded.reportName}</div>
          }
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className='pointer-events-auto absolute text-neutral-400 text-[4px] bottom-[-12px] right-[21px]'
          >
            &#11044;
          </div>
        </TooltipV3>
      )}
    </div>
  );
};
