import { EnvironmentalLabel as FeEnvironmentalLabel } from '../../components/EnvironmentalLabel';
import { ProductReport } from '../../../../../../../api';
import { NavigationButtons } from '../../components/NavigationButtons';

interface Props {
  data: ProductReport;
}

export const EnvironmentalLabel = (props: Props) => {
  return (
    <FeEnvironmentalLabel
      description={
        <div className='flex justify-between gap-8'>
          <div className='flex flex-col gap-6'>
            <div>
              This product was assigned a Foundation Earth grade ranging from A+ to G, based on the underlying PEF analysis presented in
              this report.
            </div>
            <div className='text-brandDark'>
              NB: The information provided in this section is indicative only, and represents the ‘estimated’ impact of your product given
              the assumptions and limitations presented in previous sections. the estimated nature of this rating should be clearly
              displayed if you wish to use it in your own experiences, and any marketing claims you wish to make should be proportional to
              the evidence you’re able to provide to back up your claims.
            </div>
          </div>
          <NavigationButtons type='icons' back={{ path: '../impact-analysis' }} next={{ path: '../appendix' }} />
        </div>
      }
      label={
        <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
          <div className='text-xl font-semibold p-6 group-hover:text-brand'>Environmental label</div>
          <div className='px-8 print:px-0 pb-6'>
            <img
              className='mb-6'
              src={`https://cdn.foundation-earth.org/v1/certificate/grade/long/${props.data.consumerView.overallGrade}.svg`}
              width='300'
              alt={props.data.consumerView.overallGrade}
            />
          </div>
        </div>
      }
      bottomNavigationButtons={
        <NavigationButtons
          type='buttons'
          back={{ path: '../impact-analysis', label: 'Impact analysis' }}
          next={{ path: '../appendix', label: 'Appendix' }}
        />
      }
    />
  );
};
