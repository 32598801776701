import { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  IngredientNode,
  Model,
  NodeType,
  PackagingNode,
  ProductionNode,
  ProductionProcessAuxiliary,
  ProductV3,
} from '../../../../../../../../api';
import { getOutputsFromAll } from '../../../../../Details/dataModel';
import uniqBy from 'lodash/uniqBy';
import { OutputPayload } from './index';
import cn from 'classnames';

type FacilitiesPayload = ProductionNode;

interface Props {
  product: ProductV3;
  model: Model;
  productProductionFacility?: FacilitiesPayload;
  modelProductionFacility?: FacilitiesPayload;
  allProductionFacilities: FacilitiesPayload[];
}

export const ProductionFacility = (props: Props) => {
  const [expanded, setExpanded] = useState(true);

  const productIngredientsProductionSteps = props.productProductionFacility?.steps.filter(
    (step) => step.type === NodeType.ProductionIngredient,
  );
  const modelIngredientsProductionSteps = props.modelProductionFacility?.steps.filter(
    (step) => step.type === NodeType.ProductionIngredient,
  );

  const productPackagingProductionSteps = props.productProductionFacility?.steps.filter(
    (step) => step.type === NodeType.ProductionPackaging,
  );
  const modelPackagingProductionSteps = props.modelProductionFacility?.steps.filter((step) => step.type === NodeType.ProductionPackaging);

  const allIngredientsProductionSteps = [
    ...(productIngredientsProductionSteps ? productIngredientsProductionSteps : []),
    ...(modelIngredientsProductionSteps ? modelIngredientsProductionSteps : []),
  ];
  const allIngredientsPackagingSteps = [
    ...(productPackagingProductionSteps ? productPackagingProductionSteps : []),
    ...(modelPackagingProductionSteps ? modelPackagingProductionSteps : []),
  ];

  const allUniqIngredientsProductionSteps = uniqBy(allIngredientsProductionSteps, 'id');
  const allUniqPackagingProductionSteps = uniqBy(allIngredientsPackagingSteps, 'id');

  return (
    <div
      className={cn('flex flex-col gap-y-3 rounded-xl shadow bg-neutral-50 p-3 border', {
        'border-emerald-700': !props.productProductionFacility && props.modelProductionFacility,
        'border-red-500': props.productProductionFacility && !props.modelProductionFacility,
      })}
    >
      <button onClick={() => setExpanded(!expanded)} className='flex w-full items-center justify-between gap-2'>
        <div className='flex items-center gap-2 pl-1 font-semibold'>
          <div className='h-7 aspect-square flex items-center justify-center bg-red-50 rounded-md border'>
            <FontAwesomeIcon icon={duotone('industry-windows')} />
          </div>
          <div>{props.productProductionFacility?.displayName ?? props.modelProductionFacility?.displayName}</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand'
          icon={expanded ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {/* ingredient production steps */}
      {expanded && (
        <div className='flex flex-col gap-y-6'>
          {allUniqIngredientsProductionSteps.length > 0 && (
            <div className='p-3 bg-white rounded-xl shadow flex flex-col gap-y-6'>
              {allUniqIngredientsProductionSteps.map((ingredientStep, index) => {
                const productIngredientStep = productIngredientsProductionSteps?.find((step) => step.id === ingredientStep.id);
                const modelIngredientStep = modelIngredientsProductionSteps?.find((step) => step.id === ingredientStep.id);

                return (
                  <div key={index} className='flex flex-col gap-3 text-sm'>
                    <div className='grid grid-cols-3 gap-y-3'>
                      <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
                        <div className='col-span-3 grid grid-cols-3 items-center'>
                          <div className='col-span-2 flex items-center gap-x-2 self-center text-base'>
                            <div className='h-7 aspect-square flex items-center justify-center bg-[#FFF7ED] text-[#7C2D12] rounded-md border'>
                              <FontAwesomeIcon icon={duotone('cauldron')} />
                            </div>
                            {ingredientStep.displayName}
                          </div>
                          {!productIngredientStep && modelIngredientStep && <div className='text-emerald-700'>Added</div>}
                          {productIngredientStep && !modelIngredientStep && <div className='text-red-500'>Removed</div>}
                        </div>

                        <div></div>
                        <div className='text-violet-900 font-semibold'>Original</div>
                        <div className='text-violet-900 font-semibold'>Modelled</div>

                        <div>Final production step</div>
                        <div>
                          {(() => {
                            if (!productIngredientStep) {
                              return '';
                            }
                            return productIngredientStep.finalStep ? 'Yes' : 'No';
                          })()}
                        </div>
                        <div className='flex'>
                          {(() => {
                            if (
                              (!productIngredientStep && modelIngredientStep) ||
                              (productIngredientStep &&
                                modelIngredientStep &&
                                productIngredientStep.finalStep !== modelIngredientStep.finalStep)
                            ) {
                              return (
                                <div key={index} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                  {modelIngredientStep.finalStep ? 'Yes' : 'No'}
                                </div>
                              );
                            }
                            return '';
                          })()}
                        </div>
                      </div>
                      <div />

                      <div className='col-span-3 border-t'></div>
                      <div className='col-span-3'>
                        <div className='flex items-center gap-2 pl-1.5'>
                          <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
                          <div className='font-semibold'>Inputs</div>
                        </div>
                      </div>
                      <div className='col-span-3 p-3 w-full bg-slate-100 uppercase text-xs font-semibold'>required</div>
                    </div>

                    {(() => {
                      const productIngredientStepInputs = productIngredientStep?.inputs;
                      const modelIngredientStepInputs = modelIngredientStep?.inputs;

                      const allIngredientInputs = [
                        ...(productIngredientStepInputs ? productIngredientStepInputs : []),
                        ...(modelIngredientStepInputs ? modelIngredientStepInputs : []),
                      ];

                      const uniqIngredientInputs = uniqBy(allIngredientInputs, 'id');

                      return (
                        <div className='grid grid-cols-3'>
                          <div className='col-span-2 grid grid-cols-3 gap-y-3'>
                            <div>Input(s)</div>
                            <div className='flex flex-wrap gap-2'>
                              {(() => {
                                if (productIngredientStepInputs?.length === 0) {
                                  return '';
                                } else if (productIngredientStepInputs && productIngredientStepInputs.length > 0) {
                                  return productIngredientStepInputs.map((input, i) => {
                                    const intermediate = getOutputsFromAll(props.product.nodes).find(({ id }) => input?.id === id);

                                    const inputAsNode = props.product.nodes.find(({ id }) => id === input.id) as
                                      | PackagingNode
                                      | IngredientNode;

                                    const inputData = intermediate
                                      ? {
                                          id: intermediate.id,
                                          name: intermediate.name,
                                          amount: intermediate.amount,
                                        }
                                      : {
                                          id: inputAsNode?.id,
                                          name: inputAsNode?.displayName,
                                          amount: inputAsNode?.amount,
                                        };

                                    return (
                                      <div
                                        title={inputData?.name}
                                        key={i}
                                        className='flex self-start truncate text-center py-0.5 px-2 rounded-full bg-slate-100 font-semibold'
                                      >
                                        {inputData?.name}
                                      </div>
                                    );
                                  });
                                }

                                return '';
                              })()}
                            </div>
                            <div className='flex flex-wrap gap-2'>
                              {(() => {
                                if (modelIngredientStepInputs?.length === 0) {
                                  return '';
                                } else if (modelIngredientStepInputs && modelIngredientStepInputs.length > 0) {
                                  return modelIngredientStepInputs
                                    .filter(
                                      (modelInput) =>
                                        !productIngredientStepInputs?.find((productInput) => productInput.id === modelInput.id),
                                    )
                                    .map((input, i) => {
                                      const intermediate = getOutputsFromAll(props.model.nodes).find(({ id }) => input?.id === id);

                                      const inputAsNode = props.model.nodes.find(({ id }) => id === input.id) as
                                        | PackagingNode
                                        | IngredientNode;

                                      const inputData = intermediate
                                        ? {
                                            id: intermediate.id,
                                            name: intermediate.name,
                                            amount: intermediate.amount,
                                          }
                                        : {
                                            id: inputAsNode?.id,
                                            name: inputAsNode?.displayName,
                                            amount: inputAsNode?.amount,
                                          };

                                      return (
                                        <div
                                          key={i}
                                          className='truncate text-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'
                                        >
                                          {inputData?.name}
                                        </div>
                                      );
                                    });
                                }

                                return <></>;
                              })()}
                            </div>
                          </div>
                          <div />

                          <div className='flex flex-col gap-y-3 col-span-3 my-3'>
                            {uniqIngredientInputs.map((input, i) => {
                              const intermediateProduct = getOutputsFromAll(props.product.nodes).find(({ id }) => input?.id === id);

                              const productInputNode = props.product.nodes.find(({ id }) => id === input.id) as
                                | PackagingNode
                                | IngredientNode
                                | undefined;

                              const productInputData = intermediateProduct
                                ? {
                                    id: intermediateProduct.id,
                                    name: intermediateProduct.name,
                                    amount: intermediateProduct.amount,
                                    type: NodeType.Ingredient,
                                  }
                                : productInputNode
                                ? {
                                    id: productInputNode?.id,
                                    name: productInputNode?.displayName,
                                    amount: productInputNode?.amount,
                                    type: productInputNode?.type,
                                  }
                                : undefined;

                              const intermediateModel = getOutputsFromAll(props.model.nodes).find(({ id }) => input?.id === id);

                              const modelInputNode = props.model.nodes.find(({ id }) => id === input.id) as
                                | PackagingNode
                                | IngredientNode
                                | undefined;

                              const modelInputData = intermediateModel
                                ? {
                                    id: intermediateModel.id,
                                    name: intermediateModel.name,
                                    amount: intermediateModel.amount,
                                    type: NodeType.Ingredient,
                                  }
                                : modelInputNode
                                ? {
                                    id: modelInputNode?.id,
                                    name: modelInputNode?.displayName,
                                    amount: modelInputNode?.amount,
                                    type: modelInputNode?.type,
                                  }
                                : undefined;

                              return (
                                <div
                                  key={i}
                                  className={cn('grid grid-cols-3 gap-y-3 rounded-xl shadow py-3 border', {
                                    'border-emerald-700': !productInputData && modelInputData,
                                    'border-red-500': productInputData && !modelInputData,
                                  })}
                                >
                                  <div className='col-span-2 grid grid-cols-3'>
                                    <div className='flex items-center gap-2 col-span-2 pl-3'>
                                      {(productInputData ? productInputData.type : modelInputData?.type) === NodeType.Packaging && (
                                        <div className='h-7 aspect-square flex items-center justify-center text-yellow-900 bg-yellow-50 rounded-md border'>
                                          <FontAwesomeIcon icon={duotone('box-open')} />
                                        </div>
                                      )}
                                      {(productInputData ? productInputData.type : modelInputData?.type) === NodeType.Ingredient && (
                                        <div className='h-7 aspect-square flex items-center justify-center text-green-900 bg-green-50 rounded-md border'>
                                          <FontAwesomeIcon icon={duotone('carrot')} />
                                        </div>
                                      )}
                                      <div className='font-semibold'>{productInputData?.name ?? modelInputData?.name}</div>
                                    </div>
                                    {!productInputData && modelInputData && <div className='text-emerald-700'>Added</div>}
                                    {productInputData && !modelInputData && <div className='text-red-500'>Removed</div>}
                                  </div>

                                  <div className='col-span-2 grid grid-cols-3'>
                                    <div className='pl-3'>
                                      Quantity used in this step ({productInputData?.amount?.unit.name ?? modelInputNode?.amount.unit.name})
                                    </div>
                                    <div>{productInputData?.amount?.value}</div>
                                    <div className='flex'>
                                      {(() => {
                                        if (
                                          (!productInputData && modelInputData) ||
                                          (productInputData &&
                                            modelInputData &&
                                            productInputData.amount?.value !== modelInputData.amount?.value)
                                        ) {
                                          return (
                                            <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                              {modelInputData.amount?.value}
                                            </div>
                                          );
                                        }

                                        return '';
                                      })()}
                                    </div>
                                  </div>
                                  <div />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })()}

                    {(() => {
                      const productIngredientStepOverrides = productIngredientStep?.process?.overrides;
                      const modelIngredientStepOverrides = modelIngredientStep?.process?.overrides;

                      const allElectricityTypes = [
                        ...(productIngredientStepOverrides?.electricity ? productIngredientStepOverrides.electricity.types : []),
                        ...(modelIngredientStepOverrides?.electricity ? modelIngredientStepOverrides.electricity.types : []),
                      ];
                      const uniqElectricityTypes = uniqBy(allElectricityTypes, 'type');

                      return (
                        ((productIngredientStepOverrides && productIngredientStepOverrides.electricity.types.length > 0) ||
                          (modelIngredientStepOverrides && modelIngredientStepOverrides?.electricity.types.length > 0)) && (
                          <div className='grid grid-cols-3 gap-y-3'>
                            <div className='col-span-3 p-3 w-full bg-slate-100 uppercase text-xs font-semibold'>optional</div>

                            <div className='grid grid-cols-3 col-span-2'>
                              <div className='pl-3 flex items-center'>Electricity</div>
                              <div className='flex items-center'>
                                {(() => {
                                  if (productIngredientStepOverrides?.electricity.value) {
                                    return `${productIngredientStepOverrides.electricity.value}
                                  ${(productIngredientStepOverrides.electricity as unknown as { unit: { name: string } }).unit.name}`;
                                  }

                                  return '';
                                })()}
                              </div>
                              <div className='flex'>
                                {(() => {
                                  if (
                                    (!productIngredientStepOverrides?.electricity && modelIngredientStepOverrides?.electricity) ||
                                    (productIngredientStepOverrides &&
                                      modelIngredientStepOverrides &&
                                      productIngredientStepOverrides.electricity.value !== modelIngredientStepOverrides.electricity.value)
                                  ) {
                                    return (
                                      <div className='flex items-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                        {`${modelIngredientStepOverrides.electricity.value}
                                      ${(modelIngredientStepOverrides.electricity as unknown as { unit: { name: string } }).unit.name}`}
                                      </div>
                                    );
                                  }
                                  return '';
                                })()}
                              </div>

                              {uniqElectricityTypes.map((iterator, i) => {
                                const productElectricityType = productIngredientStepOverrides?.electricity?.types?.find(
                                  ({ type }) => iterator.type === type,
                                );

                                const modelElectricityType = modelIngredientStepOverrides?.electricity?.types?.find(
                                  ({ type }) => iterator.type === type,
                                );

                                return (
                                  <Fragment key={i}>
                                    <div className='pl-6'>
                                      <div className='flex items-center pl-3 border-dark border-l py-1 h-full'>
                                        {productElectricityType?.name ?? modelElectricityType?.name}
                                      </div>
                                    </div>
                                    <div className='py-1 flex items-center'>
                                      {productElectricityType && productElectricityType.percent + '%'}
                                    </div>
                                    <div className='py-1 flex items-center'>
                                      {(() => {
                                        if (
                                          (!productElectricityType && modelElectricityType) ||
                                          (productElectricityType &&
                                            modelElectricityType &&
                                            productElectricityType.percent !== modelElectricityType.percent)
                                        ) {
                                          return (
                                            <div className='flex items-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                              {modelElectricityType.percent + '%'}
                                            </div>
                                          );
                                        }

                                        return '';
                                      })()}
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                            <div></div>

                            {(productIngredientStepOverrides?.gas || modelIngredientStepOverrides?.gas) && (
                              <div className='grid grid-cols-3 col-span-2'>
                                <div className='pl-3'>Gas</div>
                                <div>
                                  {(() => {
                                    if (productIngredientStepOverrides?.gas) {
                                      return `${productIngredientStepOverrides.gas.value}
                                  ${(productIngredientStepOverrides.gas as unknown as { unit: { name: string } }).unit.name}`;
                                    }

                                    return '';
                                  })()}
                                </div>
                                <div className='flex gap-2'>
                                  {(() => {
                                    if (
                                      (productIngredientStepOverrides?.gas === undefined && modelIngredientStepOverrides?.gas) ||
                                      (productIngredientStepOverrides &&
                                        modelIngredientStepOverrides &&
                                        productIngredientStepOverrides.gas.value !== modelIngredientStepOverrides.gas.value)
                                    ) {
                                      return (
                                        <div key={index} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                          {modelIngredientStepOverrides.gas.value}{' '}
                                          {(modelIngredientStepOverrides.gas as unknown as { unit: { name: string } }).unit.name}
                                        </div>
                                      );
                                    }
                                    return '';
                                  })()}
                                </div>
                              </div>
                            )}

                            {(productIngredientStepOverrides?.water || modelIngredientStepOverrides?.water) && (
                              <div className='grid grid-cols-3 col-span-2'>
                                <div className='pl-3'>Water</div>
                                <div>
                                  {(() => {
                                    if (productIngredientStepOverrides?.water) {
                                      return `${productIngredientStepOverrides.water.input.value}
                                  ${(productIngredientStepOverrides.water.input as unknown as { unit: { name: string } }).unit.name}`;
                                    }

                                    return '';
                                  })()}
                                </div>
                                <div className='flex'>
                                  {(() => {
                                    if (
                                      (productIngredientStepOverrides?.water === undefined && modelIngredientStepOverrides?.water) ||
                                      (productIngredientStepOverrides &&
                                        modelIngredientStepOverrides &&
                                        productIngredientStepOverrides.water.input.value !== modelIngredientStepOverrides.water.input.value)
                                    ) {
                                      return (
                                        <div key={index} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                          {modelIngredientStepOverrides.water.input.value}{' '}
                                          {(modelIngredientStepOverrides.water.input as unknown as { unit: { name: string } }).unit.name}
                                        </div>
                                      );
                                    } else if (productIngredientStepOverrides?.water && !modelIngredientStepOverrides?.water) {
                                      return (
                                        <div key={index} className='text-red-500'>
                                          Removed
                                        </div>
                                      );
                                    }
                                    return '';
                                  })()}
                                </div>
                              </div>
                            )}

                            {(() => {
                              const productAuxiliaries = productIngredientStepOverrides ? productIngredientStepOverrides.auxiliaries : [];
                              const modelAuxiliaries = modelIngredientStepOverrides ? modelIngredientStepOverrides.auxiliaries : [];

                              const allAuxiliaries = [...productAuxiliaries, ...modelAuxiliaries];

                              const uniqAuxiliaries = uniqBy(allAuxiliaries, 'type');

                              return (
                                uniqAuxiliaries.length > 0 && (
                                  <div className='flex flex-col col-span-2'>
                                    <div className='grid grid-cols-3 col-span-2'>
                                      <div className='pl-3'>Auxiliaries</div>
                                      <div></div>
                                      <div className='flex'></div>
                                    </div>

                                    {uniqAuxiliaries.map((auxiliary, i) => {
                                      const productAuxiliary = productAuxiliaries?.find(({ type }) => type === auxiliary.type) as
                                        | (ProductionProcessAuxiliary & { unit: { name: string } })
                                        | undefined;
                                      const modelAuxiliary = modelAuxiliaries?.find(({ type }) => type === auxiliary.type) as
                                        | (ProductionProcessAuxiliary & { unit: { name: string } })
                                        | undefined;

                                      return (
                                        <div key={i} className='grid grid-cols-3 col-span-2'>
                                          <div className='pl-6'>
                                            <div className='flex items-center pl-3 border-dark border-l py-1 h-full'>{auxiliary.name}</div>
                                          </div>
                                          <div className='flex items-center'>
                                            {(() => {
                                              if (
                                                (productAuxiliary && !modelAuxiliary) ||
                                                (productAuxiliary && productAuxiliary.value === modelAuxiliary?.value)
                                              ) {
                                                return `${productAuxiliary.value} ${productAuxiliary.unit.name}`;
                                              }
                                            })()}
                                          </div>
                                          <div className='flex items-center'>
                                            {(() => {
                                              if (
                                                (!productAuxiliary && modelAuxiliary) ||
                                                (productAuxiliary && modelAuxiliary && productAuxiliary.value !== modelAuxiliary.value)
                                              ) {
                                                return (
                                                  <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                    {modelAuxiliary?.value} {modelAuxiliary?.unit.name}
                                                  </div>
                                                );
                                              }
                                              return '';
                                            })()}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )
                              );
                            })()}

                            {(() => {
                              const productIngredientsStepOutputs = [...(productIngredientStep ? productIngredientStep.outputs : [])];
                              const modelIngredientsStepOutputs = [...(modelIngredientStep ? modelIngredientStep.outputs : [])];

                              const allIngredientOutputs = [...productIngredientsStepOutputs, ...modelIngredientsStepOutputs];

                              const uniqIngredientOutputs = uniqBy(allIngredientOutputs, 'id');

                              const renderOutput = (i: number, productOutput?: OutputPayload, modelOutput?: OutputPayload) => {
                                return (
                                  <div key={i} className='col-span-3 grid grid-cols-3'>
                                    <div
                                      className={cn('col-span-3 border rounded-xl shadow py-3', {
                                        'border-emerald-700': !productOutput && modelOutput,
                                        'border-red-500': productOutput && !modelOutput,
                                      })}
                                    >
                                      <div className='col-span-2 grid grid-cols-3 gap-y-2'>
                                        <div className='col-span-2 grid grid-cols-3 gap-y-2'>
                                          <div className='grid grid-cols-3 col-span-3'>
                                            <div className='font-semibold col-span-2 pl-3'>Output {i + 1}</div>
                                            {!productOutput && modelOutput && <div className='text-emerald-700'>Added</div>}
                                            {productOutput && !modelOutput && <div className='text-red-500'>Removed</div>}
                                          </div>

                                          <div className='col-span-3 grid grid-cols-3 gap-y-2'>
                                            <div className='pl-3'>Type</div>
                                            <div>{productOutput?.outputType.name}</div>
                                            <div className='flex'>
                                              {(() => {
                                                if (
                                                  (!productOutput && modelOutput) ||
                                                  (productOutput &&
                                                    modelOutput &&
                                                    productOutput.outputType.name !== modelOutput.outputType.name)
                                                ) {
                                                  return (
                                                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                      {modelOutput?.outputType.name}
                                                    </div>
                                                  );
                                                }

                                                return '';
                                              })()}
                                            </div>

                                            <div className='pl-3'>Name</div>
                                            <div>{productOutput?.name}</div>
                                            <div className='flex'>
                                              {(() => {
                                                if (
                                                  (!productOutput && modelOutput) ||
                                                  (productOutput && modelOutput && productOutput.name !== modelOutput.name)
                                                ) {
                                                  return (
                                                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                      {modelOutput?.name}
                                                    </div>
                                                  );
                                                }

                                                return '';
                                              })()}
                                            </div>

                                            <div className='pl-3'>Amount</div>
                                            <div className='flex items-center'>
                                              {productOutput?.amount?.value} {productOutput?.amount?.unit.name}
                                            </div>
                                            <div className='flex items-center'>
                                              {(() => {
                                                if (
                                                  (!productOutput && modelOutput) ||
                                                  (productOutput &&
                                                    modelOutput &&
                                                    productOutput.amount?.value !== modelOutput.amount?.value)
                                                ) {
                                                  return (
                                                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                      {modelOutput.amount?.value
                                                        ? `${modelOutput?.amount?.value} ${modelOutput?.amount?.unit.name}`
                                                        : 'default'}
                                                    </div>
                                                  );
                                                }

                                                return '';
                                              })()}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                    <div />
                                  </div>
                                );
                              };

                              return (
                                uniqIngredientOutputs.length > 0 && (
                                  <>
                                    <div className='col-span-3 border-t' />
                                    <div className='col-span-3'>
                                      <div className='flex items-center gap-2 pl-1.5'>
                                        <FontAwesomeIcon icon={regular('arrow-down-to-bracket')} />
                                        <div className='font-semibold'>Outputs</div>
                                      </div>
                                    </div>

                                    {(() => {
                                      const finalProductIfFinalStep = productIngredientStep?.finalStep
                                        ? {
                                            name: props.product.name,
                                            outputType: {
                                              name: 'Final product',
                                            },
                                            amount: props.product.bruttoAmount,
                                          }
                                        : undefined;

                                      const finalModelIfFinalStep = modelIngredientStep?.finalStep
                                        ? {
                                            name: props.product.name,
                                            outputType: {
                                              name: 'Final product',
                                            },
                                            amount: props.product.bruttoAmount,
                                          }
                                        : undefined;

                                      return (
                                        (finalModelIfFinalStep || finalModelIfFinalStep) &&
                                        renderOutput(0, finalProductIfFinalStep, finalModelIfFinalStep)
                                      );
                                    })()}

                                    {uniqIngredientOutputs.map((output, i) => {
                                      const productOutput = productIngredientsStepOutputs?.find(({ id }) => id === output.id);
                                      const modelOutput = modelIngredientsStepOutputs?.find(({ id }) => id === output.id);

                                      return renderOutput(i, productOutput, modelOutput);
                                    })}
                                  </>
                                )
                              );
                            })()}
                          </div>
                        )
                      );
                    })()}
                  </div>
                );
              })}
            </div>
          )}

          {/* packaging production steps */}
          {allUniqPackagingProductionSteps.length > 0 && (
            <div className='flex flex-col gap-6 p-3 bg-white rounded-xl shadow'>
              {allUniqPackagingProductionSteps.map((packaging, index) => {
                const productPackagingStep = productPackagingProductionSteps?.find((step2) => step2.id === packaging.id);
                const modelPackagingStep = modelPackagingProductionSteps?.find((step2) => step2.id === packaging.id);

                return (
                  <div key={index} className='flex flex-col gap-3 text-sm'>
                    <div className='flex items-center gap-2 pl-1 font-semibold'>
                      <div className='h-7 aspect-square flex items-center justify-center text-yellow-900 bg-yellow-50 rounded-md border'>
                        <FontAwesomeIcon icon={duotone('box-open')} />
                      </div>
                      {packaging.displayName}
                    </div>
                    <div className='grid grid-cols-3 gap-y-3'>
                      <div className='col-span-2 grid grid-cols-3 gap-y-3 text-sm'>
                        <div></div>
                        <div className='text-violet-900 font-semibold'>Original</div>
                        <div className='text-violet-900 font-semibold'>Modelled</div>

                        <div>Final production step</div>
                        <div>
                          {(() => {
                            if (!productPackagingStep) {
                              return '';
                            } else if (productPackagingStep) {
                              return productPackagingStep.finalStep ? 'Yes' : 'No';
                            }
                            return <></>;
                          })()}
                        </div>
                        <div className='flex'>
                          {(() => {
                            if (!modelPackagingStep) {
                              return <div className='text-red-500'>Removed</div>;
                            } else if (
                              (!productPackagingStep && modelPackagingStep) ||
                              (productPackagingStep &&
                                modelPackagingStep &&
                                productPackagingStep.finalStep !== modelPackagingStep.finalStep)
                            ) {
                              return (
                                <div key={index} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                  {modelPackagingStep.finalStep ? 'Yes' : 'No'}
                                </div>
                              );
                            }
                            return '';
                          })()}
                        </div>
                      </div>
                      <div />

                      <div className='col-span-3 border-t'></div>
                      <div className='col-span-3'>
                        <div className='flex items-center gap-2 pl-1.5'>
                          <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
                          <div className='font-semibold'>Inputs</div>
                        </div>
                      </div>
                      <div className='col-span-3 p-3 w-full bg-slate-100 uppercase text-xs font-semibold'>required</div>
                    </div>

                    {(() => {
                      const productPackagingStepInputs = productPackagingStep?.inputs;
                      const modelPackagingStepInputs = modelPackagingStep?.inputs;

                      const allPackagingInputs = [
                        ...(productPackagingStepInputs ? productPackagingStepInputs : []),
                        ...(modelPackagingStepInputs ? modelPackagingStepInputs : []),
                      ];

                      const uniqPackagingInputs = uniqBy(allPackagingInputs, 'id');

                      return (
                        <div className='grid grid-cols-3'>
                          <div className='col-span-2 grid grid-cols-3 gap-y-3'>
                            <div>Input(s)</div>
                            <div className='flex flex-wrap gap-2'>
                              {(() => {
                                if (productPackagingStepInputs?.length === 0) {
                                  return '';
                                } else if (productPackagingStepInputs && productPackagingStepInputs.length > 0) {
                                  return productPackagingStepInputs.map((input, i) => {
                                    const intermediate = getOutputsFromAll(props.product.nodes).find(({ id }) => input?.id === id);

                                    const inputAsNode = props.product.nodes.find(({ id }) => id === input.id) as
                                      | PackagingNode
                                      | IngredientNode;

                                    const inputData = intermediate
                                      ? {
                                          id: intermediate.id,
                                          name: intermediate.name,
                                          amount: intermediate.amount,
                                        }
                                      : {
                                          id: inputAsNode?.id,
                                          name: inputAsNode?.displayName,
                                          amount: inputAsNode?.amount,
                                        };

                                    return (
                                      inputData && (
                                        <div
                                          title={inputData?.name}
                                          key={i}
                                          className='flex self-start truncate text-center py-0.5 px-2 rounded-full bg-slate-100 font-semibold'
                                        >
                                          {inputData?.name}
                                        </div>
                                      )
                                    );
                                  });
                                }

                                return '';
                              })()}
                            </div>
                            <div className='flex flex-wrap gap-2'>
                              {(() => {
                                if (modelPackagingStepInputs?.length === 0) {
                                  return '';
                                } else if (productPackagingStepInputs && !modelPackagingStepInputs) {
                                  return <div className='text-red-500'>Removed</div>;
                                } else if (modelPackagingStepInputs && modelPackagingStepInputs.length > 0) {
                                  return modelPackagingStepInputs
                                    .filter(
                                      (modelInput) =>
                                        !productPackagingStepInputs?.find((productInput) => productInput.id === modelInput.id),
                                    )
                                    .map((input, i) => {
                                      const intermediate = getOutputsFromAll(props.model.nodes).find(({ id }) => input?.id === id);

                                      const inputAsNode = props.model.nodes.find(({ id }) => id === input.id) as
                                        | PackagingNode
                                        | IngredientNode;

                                      const inputData = intermediate
                                        ? {
                                            id: intermediate.id,
                                            name: intermediate.name,
                                            amount: intermediate.amount,
                                          }
                                        : {
                                            id: inputAsNode?.id,
                                            name: inputAsNode?.displayName,
                                            amount: inputAsNode?.amount,
                                          };

                                      return (
                                        <div
                                          key={i}
                                          className='flex self-start truncate text-center py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'
                                        >
                                          {inputData?.name}
                                        </div>
                                      );
                                    });
                                }

                                return <></>;
                              })()}
                            </div>
                          </div>
                          <div />

                          <div className='flex flex-col gap-y-3 col-span-3 my-3'>
                            {uniqPackagingInputs.map((input, i) => {
                              const intermediateProduct = getOutputsFromAll(props.product.nodes).find(({ id }) => input?.id === id);

                              const productInputNode = props.product.nodes.find(({ id }) => id === input.id) as
                                | PackagingNode
                                | IngredientNode
                                | undefined;

                              const productInputData = intermediateProduct
                                ? {
                                    id: intermediateProduct.id,
                                    name: intermediateProduct.name,
                                    amount: intermediateProduct.amount,
                                    type: NodeType.Ingredient,
                                  }
                                : productInputNode
                                ? {
                                    id: productInputNode?.id,
                                    name: productInputNode?.displayName,
                                    amount: productInputNode?.amount,
                                    type: productInputNode?.type,
                                  }
                                : undefined;

                              const intermediateModel = getOutputsFromAll(props.model.nodes).find(({ id }) => input?.id === id);

                              const modelInputNode = props.model.nodes.find(({ id }) => id === input.id) as
                                | PackagingNode
                                | IngredientNode
                                | undefined;

                              const modelInputData = intermediateModel
                                ? {
                                    id: intermediateModel.id,
                                    name: intermediateModel.name,
                                    amount: intermediateModel.amount,
                                    type: NodeType.Ingredient,
                                  }
                                : modelInputNode
                                ? {
                                    id: modelInputNode?.id,
                                    name: modelInputNode?.displayName,
                                    amount: modelInputNode?.amount,
                                    type: modelInputNode?.type,
                                  }
                                : undefined;

                              return (
                                <div
                                  key={i}
                                  className={cn('grid grid-cols-3 gap-y-3 border rounded-xl shadow py-3', {
                                    'border-emerald-700': !productInputData && modelInputData,
                                    'border-red-500': productInputData && !modelInputData,
                                  })}
                                >
                                  <div className='col-span-2 grid grid-cols-3 items-center'>
                                    <div className='flex items-center gap-2 col-span-2 pl-3'>
                                      {(productInputData ? productInputData.type : modelInputData?.type) === NodeType.Packaging && (
                                        <div className='h-7 aspect-square flex items-center justify-center text-yellow-900 bg-yellow-50 rounded-md border'>
                                          <FontAwesomeIcon icon={duotone('box-open')} />
                                        </div>
                                      )}
                                      {(productInputData ? productInputData.type : modelInputData?.type) === NodeType.Ingredient && (
                                        <div className='h-7 aspect-square flex items-center justify-center text-green-900 bg-green-50 rounded-md border'>
                                          <FontAwesomeIcon icon={duotone('carrot')} />
                                        </div>
                                      )}
                                      <div className='font-semibold'>{productInputData?.name ?? modelInputData?.name}</div>
                                    </div>
                                    {!productInputData && modelInputData && <div className='text-emerald-700'>Added</div>}
                                    {productInputData && !modelInputData && <div className='text-red-500'>Removed</div>}
                                  </div>

                                  <div className='col-span-2 grid grid-cols-3'>
                                    <div className='pl-3'>
                                      Quantity used in this step ({productInputData?.amount?.unit.name ?? modelInputNode?.amount.unit.name})
                                    </div>
                                    <div>
                                      {(() => {
                                        if (
                                          (productInputData && !modelInputData) ||
                                          (productInputData && productInputData.amount?.value === modelInputData?.amount?.value)
                                        ) {
                                          return productInputData?.amount?.value;
                                        }
                                      })()}
                                    </div>
                                    <div className='flex'>
                                      {(() => {
                                        if (
                                          (!productInputData && modelInputData) ||
                                          (productInputData &&
                                            modelInputData &&
                                            productInputData.amount?.value !== modelInputData.amount?.value)
                                        ) {
                                          return (
                                            <div className='flex self-start py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                              {modelInputData.amount?.value}
                                            </div>
                                          );
                                        }

                                        return '';
                                      })()}
                                    </div>
                                  </div>
                                  <div />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })()}

                    {(() => {
                      const productPackagingStepOverrides = productPackagingStep?.process?.overrides;
                      const modelPackagingStepOverrides = modelPackagingStep?.process?.overrides;

                      const allElectricityTypes = [
                        ...(productPackagingStepOverrides?.electricity ? productPackagingStepOverrides.electricity.types : []),
                        ...(modelPackagingStepOverrides?.electricity ? modelPackagingStepOverrides.electricity.types : []),
                      ];

                      const uniqElectricityTypes = uniqBy(allElectricityTypes, 'type');

                      return (
                        ((productPackagingStepOverrides && productPackagingStepOverrides.electricity.types.length > 0) ||
                          (modelPackagingStepOverrides && modelPackagingStepOverrides.electricity.types.length > 0)) && (
                          <div className='grid grid-cols-3 gap-y-3'>
                            <div className='col-span-3 p-3 w-full bg-slate-100 uppercase text-xs font-semibold'>optional</div>

                            <div className='grid grid-cols-3 col-span-2'>
                              <div className='pl-3 flex items-center'>Electricity</div>
                              <div>
                                {(() => {
                                  if (productPackagingStepOverrides?.electricity.value) {
                                    return `${productPackagingStepOverrides.electricity.value} ${
                                      (productPackagingStepOverrides.electricity as unknown as { unit: { name: string } }).unit.name
                                    }`;
                                  }

                                  return '';
                                })()}
                              </div>
                              <div className='flex'>
                                {(() => {
                                  if (
                                    (!productPackagingStepOverrides?.electricity && modelPackagingStepOverrides?.electricity) ||
                                    (productPackagingStepOverrides &&
                                      modelPackagingStepOverrides &&
                                      productPackagingStepOverrides.electricity.value !== modelPackagingStepOverrides.electricity.value)
                                  ) {
                                    return (
                                      <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                        {`${modelPackagingStepOverrides.electricity.value}
                                    ${(modelPackagingStepOverrides.electricity as unknown as { unit: { name: string } }).unit.name}`}
                                      </div>
                                    );
                                  }
                                  return '';
                                })()}
                              </div>

                              {uniqElectricityTypes.map((iterator, i) => {
                                const productElectricityType = productPackagingStepOverrides?.electricity?.types?.find(
                                  ({ type }) => iterator.type === type,
                                );

                                const modelElectricityType = modelPackagingStepOverrides?.electricity?.types?.find(
                                  ({ type }) => iterator.type === type,
                                );

                                return (
                                  <Fragment key={i}>
                                    <div className='pl-6'>
                                      <div className='flex items-center pl-3 border-dark border-l py-1 h-full'>
                                        {productElectricityType?.name ?? modelElectricityType?.name}
                                      </div>
                                    </div>
                                    <div className='py-1 flex items-center'>
                                      {productElectricityType && productElectricityType.percent + '%'}
                                    </div>
                                    <div className='py-1 flex items-center'>
                                      {(() => {
                                        if (
                                          (!productElectricityType && modelElectricityType) ||
                                          (productElectricityType &&
                                            modelElectricityType &&
                                            productElectricityType.percent !== modelElectricityType.percent)
                                        ) {
                                          return (
                                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                              {modelElectricityType.percent + '%'}
                                            </div>
                                          );
                                        }

                                        return '';
                                      })()}
                                    </div>
                                  </Fragment>
                                );
                              })}
                            </div>
                            <div></div>

                            {(productPackagingStepOverrides?.gas || modelPackagingStepOverrides?.gas) && (
                              <div className='grid grid-cols-3 col-span-2 items-center'>
                                <div className='pl-3'>Gas</div>
                                <div>
                                  {(() => {
                                    if (productPackagingStepOverrides?.gas) {
                                      return `${productPackagingStepOverrides.gas.value}
                                  ${(productPackagingStepOverrides.gas as unknown as { unit: { name: string } }).unit.name}`;
                                    }

                                    return '';
                                  })()}
                                </div>
                                <div className='flex gap-2'>
                                  {(() => {
                                    if (
                                      (productPackagingStepOverrides?.gas === undefined && modelPackagingStepOverrides?.gas) ||
                                      (productPackagingStepOverrides &&
                                        modelPackagingStepOverrides &&
                                        productPackagingStepOverrides.gas.value !== modelPackagingStepOverrides.gas.value)
                                    ) {
                                      return (
                                        <div key={index} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                          {modelPackagingStepOverrides.gas.value}{' '}
                                          {(modelPackagingStepOverrides.gas as unknown as { unit: { name: string } }).unit.name}
                                        </div>
                                      );
                                    }
                                    return '';
                                  })()}
                                </div>
                              </div>
                            )}

                            {(productPackagingStepOverrides?.water || modelPackagingStepOverrides?.water) && (
                              <div className='grid grid-cols-3 col-span-2 items-center'>
                                <div className='pl-3'>Water</div>
                                <div>
                                  {(() => {
                                    if (productPackagingStepOverrides?.water) {
                                      return `${productPackagingStepOverrides.water.input.value}
                                  ${(productPackagingStepOverrides.water.input as unknown as { unit: { name: string } }).unit.name}`;
                                    }

                                    return '';
                                  })()}
                                </div>
                                <div className='flex'>
                                  {(() => {
                                    if (
                                      (productPackagingStepOverrides?.water === undefined && modelPackagingStepOverrides?.water) ||
                                      (productPackagingStepOverrides &&
                                        modelPackagingStepOverrides &&
                                        productPackagingStepOverrides.water.input.value !== modelPackagingStepOverrides.water.input.value)
                                    ) {
                                      return (
                                        <div key={index} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                          {modelPackagingStepOverrides.water.input.value}{' '}
                                          {(modelPackagingStepOverrides.water.input as unknown as { unit: { name: string } }).unit.name}
                                        </div>
                                      );
                                    } else if (productPackagingStepOverrides?.water && !modelPackagingStepOverrides?.water) {
                                      return (
                                        <div key={index} className='text-red-500'>
                                          Removed
                                        </div>
                                      );
                                    }
                                    return '';
                                  })()}
                                </div>
                              </div>
                            )}

                            {(() => {
                              const productAuxiliaries = productPackagingStepOverrides ? productPackagingStepOverrides.auxiliaries : [];
                              const modelAuxiliaries = modelPackagingStepOverrides ? modelPackagingStepOverrides.auxiliaries : [];

                              const allAuxiliaries = [...productAuxiliaries, ...modelAuxiliaries];

                              const uniqAuxiliaries = uniqBy(allAuxiliaries, 'type');

                              return (
                                uniqAuxiliaries.length > 0 && (
                                  <div className='flex flex-col col-span-2'>
                                    <div className='grid grid-cols-3 col-span-2'>
                                      <div className='pl-3'>Auxiliaries</div>
                                      <div></div>
                                      <div className='flex'></div>
                                    </div>

                                    {uniqAuxiliaries.map((auxiliary, i) => {
                                      const productAuxiliary = productAuxiliaries?.find(({ type }) => type === auxiliary.type) as
                                        | (ProductionProcessAuxiliary & { unit: { name: string } })
                                        | undefined;
                                      const modelAuxiliary = modelAuxiliaries?.find(({ type }) => type === auxiliary.type) as
                                        | (ProductionProcessAuxiliary & { unit: { name: string } })
                                        | undefined;

                                      return (
                                        <div key={i} className='grid grid-cols-3 col-span-2'>
                                          <div className='pl-6'>
                                            <div className='flex items-center pl-3 border-dark border-l py-1 h-full'>{auxiliary.name}</div>
                                          </div>
                                          <div className='flex items-center'>
                                            {(() => {
                                              if (
                                                (productAuxiliary && !modelAuxiliary) ||
                                                (productAuxiliary && productAuxiliary.value === modelAuxiliary?.value)
                                              ) {
                                                return `${productAuxiliary.value} ${productAuxiliary.unit.name}`;
                                              }
                                            })()}
                                          </div>
                                          <div className='flex items-center'>
                                            {(() => {
                                              if (
                                                (!productAuxiliary && modelAuxiliary) ||
                                                (productAuxiliary && modelAuxiliary && productAuxiliary.value !== modelAuxiliary.value)
                                              ) {
                                                return (
                                                  <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                    {modelAuxiliary?.value} {modelAuxiliary?.unit.name}
                                                  </div>
                                                );
                                              }
                                              return '';
                                            })()}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )
                              );
                            })()}

                            {(() => {
                              const productPackagingStepOutputs = [...(productPackagingStep ? productPackagingStep.outputs : [])];
                              const modelPackagingStepOutputs = [...(modelPackagingStep ? modelPackagingStep.outputs : [])];

                              const allPackagingOutputs = [...productPackagingStepOutputs, ...modelPackagingStepOutputs];

                              const uniqPackagingOutputs = uniqBy(allPackagingOutputs, 'id');

                              const renderOutput = (i: number, productOutput?: OutputPayload, modelOutput?: OutputPayload) => {
                                return (
                                  <div key={i} className='col-span-3 grid grid-cols-3'>
                                    <div
                                      className={cn('col-span-3 border rounded-xl shadow py-3', {
                                        'border-emerald-700': !productOutput && modelOutput,
                                        'border-red-500': productOutput && !modelOutput,
                                      })}
                                    >
                                      <div className='col-span-2 grid grid-cols-3 gap-y-2'>
                                        <div className='col-span-2 grid grid-cols-3 gap-y-2'>
                                          <div className='grid grid-cols-3 col-span-3'>
                                            <div className='font-semibold col-span-2 pl-3'>Output {i + 1}</div>
                                            {!productOutput && modelOutput && <div className='text-emerald-700'>Added</div>}
                                            {productOutput && !modelOutput && <div className='text-red-500'>Removed</div>}
                                          </div>

                                          <div className='pl-3'>Type</div>
                                          <div>{productOutput?.outputType.name}</div>
                                          <div className='flex'>
                                            {(() => {
                                              if (
                                                (!productOutput && modelOutput) ||
                                                (productOutput &&
                                                  modelOutput &&
                                                  productOutput.outputType.name !== modelOutput.outputType.name)
                                              ) {
                                                return (
                                                  <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                    {modelOutput?.outputType.name}
                                                  </div>
                                                );
                                              } else if (!modelOutput && productOutput) {
                                                return <div className='text-red-500'>Removed</div>;
                                              }

                                              return '';
                                            })()}
                                          </div>

                                          <div className='pl-3'>Name</div>
                                          <div>{productOutput?.name}</div>
                                          <div className='flex'>
                                            {(() => {
                                              if (
                                                (!productOutput && modelOutput) ||
                                                (productOutput && modelOutput && productOutput.name !== modelOutput.name)
                                              ) {
                                                return (
                                                  <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                    {modelOutput?.name}
                                                  </div>
                                                );
                                              } else if (!modelOutput && productOutput) {
                                                return <div className='text-red-500'>Removed</div>;
                                              }

                                              return '';
                                            })()}
                                          </div>

                                          <div className='pl-3'>Amount</div>
                                          <div>
                                            {productOutput?.amount?.value
                                              ? `${productOutput?.amount?.value} ${productOutput?.amount?.unit.name}`
                                              : ''}
                                          </div>
                                          <div className='flex'>
                                            {(() => {
                                              if (
                                                (!productOutput && modelOutput) ||
                                                (productOutput && modelOutput && productOutput.amount?.value !== modelOutput.amount?.value)
                                              ) {
                                                return (
                                                  <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                                    {modelOutput?.amount?.value
                                                      ? `${modelOutput?.amount?.value} ${modelOutput?.amount?.unit.name}`
                                                      : 'default'}
                                                  </div>
                                                );
                                              } else if (!modelOutput && productOutput) {
                                                return <div className='text-red-500'>Removed</div>;
                                              }

                                              return '';
                                            })()}
                                          </div>
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                    <div />
                                  </div>
                                );
                              };

                              return (
                                uniqPackagingOutputs.length > 0 && (
                                  <>
                                    <div className='col-span-3 border-t' />
                                    <div className='col-span-3'>
                                      <div className='flex items-center gap-2 pl-1.5'>
                                        <FontAwesomeIcon icon={regular('arrow-down-to-bracket')} />
                                        <div className='font-semibold'>Outputs</div>
                                      </div>
                                    </div>

                                    {(() => {
                                      const finalProductIfFinalStep = productPackagingStep?.finalStep
                                        ? {
                                            name: props.product.name,
                                            outputType: {
                                              name: 'Final product',
                                            },
                                            amount: props.product.bruttoAmount,
                                          }
                                        : undefined;

                                      const finalModelIfFinalStep = modelPackagingStep?.finalStep
                                        ? {
                                            name: props.product.name,
                                            outputType: {
                                              name: 'Final product',
                                            },
                                            amount: props.product.bruttoAmount,
                                          }
                                        : undefined;

                                      return (
                                        (finalProductIfFinalStep || finalModelIfFinalStep) &&
                                        renderOutput(0, finalProductIfFinalStep, finalModelIfFinalStep)
                                      );
                                    })()}

                                    {uniqPackagingOutputs.map((output, i) => {
                                      const productOutput = productPackagingStepOutputs?.find(({ id }) => id === output.id);
                                      const modelOutput = modelPackagingStepOutputs?.find(({ id }) => id === output.id);

                                      return renderOutput(productPackagingStep?.finalStep ? i + 1 : i, productOutput, modelOutput);
                                    })}
                                  </>
                                )
                              );
                            })()}
                          </div>
                        )
                      );
                    })()}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
