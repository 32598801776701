import { forwardRef } from 'react';
import { PieChart } from '../../../../components/charts/PieChart';
import { ModalV3 } from '../../../../components/ModalV3';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grade, ProductType } from '../../../../api';
import { useProfile } from '../../../../hooks/useProfile';
import { addDays, format } from 'date-fns';
import sample from 'lodash/sample';
import cn from 'classnames';

interface Props {
  title: string;
}

export const EcoLabellingDetails = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const profile = useProfile();
  const mockedProducts = [
    {
      grade: Grade.A,
      value: 4678,
      isMajor: false,
    },
    {
      grade: Grade.B,
      value: 78,
      isMajor: false,
    },
    {
      grade: Grade.C,
      value: 142,
      isMajor: false,
    },
    {
      grade: Grade.D,
      value: 89,
      isMajor: false,
    },
    {
      grade: Grade.E,
      value: 5894,
      isMajor: false,
    },
    {
      grade: Grade.F,
      value: 120,
      isMajor: false,
    },
    {
      grade: Grade.G,
      value: 234,
      isMajor: false,
    },
  ];

  const shareInPercentage = (value: number) => (value / mockedProducts.reduce((acc, curr) => acc + curr.value, 0)) * 100;

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
      </div>
      <div className='self-start flex gap-20 p-6 bg-white border border-zinc-200 rounded-2xl'>
        <div className='flex flex-col gap-2'>
          {mockedProducts.map(({ grade, value }) => (
            <div key={grade} className='flex items-center gap-2 p-1 pr-3 rounded-full border border-zinc-200'>
              <div
                className={cn(
                  'size-6 flex justify-center items-center text-base rounded-full text-white',
                  {
                    [Grade.A]: 'bg-a',
                    [Grade.AA]: 'bg-a',
                    [Grade.B]: 'bg-b',
                    [Grade.C]: 'bg-c',
                    [Grade.D]: 'bg-d',
                    [Grade.E]: 'bg-e',
                    [Grade.F]: 'bg-f',
                    [Grade.G]: 'bg-g',
                  }[grade],
                )}
              >
                {grade}
              </div>
              <div className='text-zinc-700'>{value} products</div>
            </div>
          ))}
        </div>
        <div className='flex items-center -my-6'>
          <PieChart
            showTicks
            size={320}
            outerRadius={110}
            data={mockedProducts.map((product) => ({
              value: shareInPercentage(product.value),
              name: `Grade: ${product.grade}`,
              isMajor: product.isMajor,
              bgColor: {
                [Grade.A]: '#5F8E25',
                [Grade.AA]: '#388E25',
                [Grade.B]: '#82B841',
                [Grade.C]: '#FFB444',
                [Grade.D]: '#F39609',
                [Grade.E]: '#F37214',
                [Grade.F]: '#E82E2E',
                [Grade.G]: '#B90000',
              }[product.grade],
            }))}
          />
        </div>
        <div>
          <ModalV3
            size='wide'
            hideConfirm
            cancelLabel='Close'
            title={
              <div className='flex justify-between items-center'>
                <div>Eco-labelling details</div>
                <button
                  type='button'
                  className='rounded-full leading-none text-sm font-normal text-zinc-600 border py-2 pl-4 pr-3.5 flex items-center gap-2 border-zinc-200'
                >
                  Grade
                  <FontAwesomeIcon icon={regular('chevron-down')} className='text-zinc-500' />
                </button>
              </div>
            }
            body={
              <table className='border-collapse w-full'>
                <thead>
                  <tr className='border-y border-zinc-200 text-zinc-800 whitespace-nowrap h-11'>
                    <th className='pl-6 pr-2'>SKU name</th>
                    <th className='px-2'>Brand</th>
                    <th className='px-2'>Product type</th>
                    <th className='px-2'>Product update</th>
                    <th className='px-2'>SKU ID</th>
                    <th className='pl-2 pr-6'>Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {[
                    {
                      skuId: '34328424238732',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.A,
                    },
                    {
                      skuId: '4234234',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.A,
                    },
                    {
                      skuId: '5353646456',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Intermediate,
                      updated: addDays(new Date(), -10),
                      grade: Grade.A,
                    },
                    {
                      skuId: '64632523423',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.A,
                    },
                    {
                      skuId: '42423543654',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Intermediate,
                      updated: addDays(new Date(), -10),
                      grade: Grade.B,
                    },
                    {
                      skuId: '54364625234',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Intermediate,
                      updated: addDays(new Date(), -10),
                      grade: Grade.C,
                    },
                    {
                      skuId: '3123234234235536',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.C,
                    },
                    {
                      skuId: '756756634524',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Intermediate,
                      updated: addDays(new Date(), -10),
                      grade: Grade.E,
                    },
                    {
                      skuId: '423425346546',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.E,
                    },
                    {
                      skuId: '4234235436456',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Intermediate,
                      updated: addDays(new Date(), -10),
                      grade: Grade.E,
                    },
                    {
                      skuId: '87685674567645',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.F,
                    },
                    {
                      skuId: '1187685674567645',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.F,
                    },
                    {
                      skuId: '1231432423',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Intermediate,
                      updated: addDays(new Date(), -10),
                      grade: Grade.G,
                    },
                    {
                      skuId: '34564745352',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.G,
                    },
                    {
                      skuId: '23234564745352',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.G,
                    },
                    {
                      skuId: '754434564745352',
                      name: 'Product',
                      brand: sample(profile.workspaces)!.name,
                      productType: ProductType.Final,
                      updated: addDays(new Date(), -10),
                      grade: Grade.G,
                    },
                  ].map((product, i) => (
                    <tr
                      key={product.skuId}
                      className={cn('h-12 text-zinc-600', {
                        'bg-slate-50': i % 2 === 1,
                      })}
                    >
                      <td className='pl-6 pr-2'>{product.name}</td>
                      <td className='px-2'>{product.brand}</td>
                      <td className='px-2'>
                        {
                          {
                            [ProductType.Final]: 'Final',
                            [ProductType.Intermediate]: 'Intermediate',
                            [ProductType.Internal]: 'Internal',
                          }[product.productType]
                        }
                      </td>
                      <td className='px-2'>{format(product.updated, 'dd/MM/yyyy')}</td>
                      <td className='px-2'>
                        <div className='w-20 truncate'>{product.skuId}</div>
                      </td>
                      <td className='pl-2 pr-6'>
                        <div
                          className={cn(
                            'size-6 flex justify-center items-center text-base rounded-full text-white',
                            {
                              [Grade.A]: 'bg-a',
                              [Grade.AA]: 'bg-a',
                              [Grade.B]: 'bg-b',
                              [Grade.C]: 'bg-c',
                              [Grade.D]: 'bg-d',
                              [Grade.E]: 'bg-e',
                              [Grade.F]: 'bg-f',
                              [Grade.G]: 'bg-g',
                            }[product.grade],
                          )}
                        >
                          {product.grade}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          >
            <button type='button' className='text-zinc-400 flex justify-center items-center size-8'>
              <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
            </button>
          </ModalV3>
        </div>
      </div>
    </div>
  );
});
