import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';
import { ImpactStageMatrix, LifecycleStageImpact } from '../api';

interface Props {
  data?: {
    lifecycleStageImpacts: LifecycleStageImpact[];
    impactStageMatrix?: ImpactStageMatrix[];
    impactStagesMatrix?: ImpactStageMatrix[];
  };
  selectedImpact?: { id: string; unit?: string };
  hideLevelsAbove3?: boolean;
}

export const useCsvByLifeCycleStageHref = (props: Props) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    if (props.data) {
      const rows = props.selectedImpact
        ? (props.data.impactStageMatrix ?? props.data.impactStagesMatrix)!
            .filter(({ impactId }) => impactId === props.selectedImpact!.id)
            .flatMap(({ stages }) => stages)
            .flatMap((stage) => {
              const row = (
                level: number,
                values: {
                  weightedNormalisedValue: number;
                  impactPoints: number;
                  physicalValue: number;
                  absSharePercent: number;
                },
                stage: { name: string },
                component?: { name: string },
                process?: { name: string },
                flow?: { name: string },
                origin?: { name: string },
              ) => [
                level,
                `"${stage.name}"`,
                component ? `"${component.name}"` : '',
                process ? `"${process.name}"` : '',
                ...(props.hideLevelsAbove3 ? [] : [flow ? `"${flow.name}"` : '', origin ? `"${origin.name}"` : '']),
                values.weightedNormalisedValue,
                values.impactPoints,
                values.physicalValue,
                values.absSharePercent,
              ];
              return [
                row(1, stage, stage),
                ...stage.components.flatMap((component) => [
                  row(2, component, stage, component),
                  ...component.components.flatMap((process) => [
                    row(3, process, stage, component, process),
                    ...process.components.flatMap((flow) => [
                      row(4, flow, stage, component, process, flow),
                      ...(flow.components ?? []).map((origin) => row(5, origin, stage, component, process, flow, origin)),
                    ]),
                  ]),
                ]),
              ];
            })
        : props.data.lifecycleStageImpacts.flatMap((stage) => {
            const row = (
              level: number,
              values: {
                impactValue: number;
                impactPoints: number;
                absImpactSharePercent: number;
              },
              stage: { name: string },
              component?: { name: string },
              process?: { name: string },
              flow?: { name: string },
              origin?: { name: string },
            ) => [
              level,
              `"${stage.name}"`,
              component ? `"${component.name}"` : '',
              process ? `"${process.name}"` : '',
              ...(props.hideLevelsAbove3 ? [] : [flow ? `"${flow.name}"` : '', origin ? `"${origin.name}"` : '']),
              values.impactValue,
              values.impactPoints,
              values.absImpactSharePercent,
            ];
            return [
              row(1, stage, stage),
              ...stage.components.flatMap((component) => [
                row(2, component, stage, component),
                ...component.components.flatMap((process) => [
                  row(3, process, stage, component, process),
                  ...process.components.flatMap((flow) => [
                    row(4, flow, stage, component, process, flow),
                    ...(flow.components ?? []).map((origin) => row(5, origin, stage, component, process, flow, origin)),
                  ]),
                ]),
              ]),
            ];
          });

      setHref(
        URL.createObjectURL(
          new Blob(
            [
              [
                [
                  '"Level in hierarchy"',
                  '"Life cycle stage"',
                  '"Component"',
                  '"Process"',
                  ...(props.hideLevelsAbove3 ? [] : ['"Flow"', '"Supplier / Origin"']),
                  '"Impact (weighted person.year)"',
                  '"Impact points"',
                  ...(props.selectedImpact ? [`"Physical impact (${props.selectedImpact.unit})"`] : []),
                  '"Contribution (%)"',
                ].join(','),
                ...[
                  ...rows,
                  [
                    '',
                    'TOTAL',
                    '',
                    '',
                    ...(props.hideLevelsAbove3 ? [] : ['', '']),
                    'sum',
                    'sum',
                    ...(props.selectedImpact ? ['sum'] : []),
                    '100%',
                  ].map((column, c) =>
                    column === 'sum'
                      ? sumBy(
                          rows.filter((row) => row[0] === 1),
                          (row) => row[c] as number,
                        )
                      : column,
                  ),
                ].map((row) => row.join(',')),
              ].join('\n'),
            ],
            { type: 'text/csv' },
          ),
        ),
      );
    }
  }, [props.data, props.selectedImpact, props.hideLevelsAbove3]);

  return href;
};
