import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { FieldProps } from 'formik';
import { getYear, setMonth } from 'date-fns';
import './styles.css';
import { convertLocalToUTCDate } from './utils';

interface Props {
  model: FieldProps<string | undefined>;
  specificMonth?: number;
  disabled?: boolean;
  inputWidth: string;
  textSize: string;
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  styles?: string;
  border?: string;
}

export const YearPicker = (props: Props) => {
  const getYearsRange = (date: Date, yearItemNumber = 12) => {
    const endPeriod = Math.ceil(getYear(date) / yearItemNumber) * yearItemNumber;
    const startPeriod = endPeriod - (yearItemNumber - 1);
    return { startPeriod, endPeriod };
  };

  return (
    <div
      className={cn(
        'year-picker flex items-center relative rounded-full border',
        props.model.meta.error && !props.disabled ? 'border-f focus:ring-focusRingError' : props.border ?? 'border-transparent',
      )}
    >
      <DatePicker
        strictParsing
        showYearPicker
        dateFormat='yyyy'
        disabled={props.disabled}
        placeholderText={props.placeholder}
        className={cn(
          'rounded-full pl-[18%] py-1 disabled:bg-neutral-100 disabled:cursor-not-allowed',
          props.model.meta.error && 'border-f focus:ring-focusRingError',
          props.inputWidth,
          props.textSize,
          props.styles,
        )}
        selected={props.model.field.value ? new Date(props.model.field.value) : null}
        onChange={(date) => {
          if (props.specificMonth) {
            props.model.form.setFieldValue(props.model.field.name, convertLocalToUTCDate(setMonth(date!, props.specificMonth)));
          } else {
            props.model.form.setFieldValue(props.model.field.name, convertLocalToUTCDate(date));
          }
        }}
        minDate={props.minDate}
        maxDate={props.maxDate}
        renderCustomHeader={({ date, increaseYear, decreaseYear }) => {
          return (
            <div className='flex justify-between items-center w-full px-6 py-2'>
              <button type='button' onClick={decreaseYear}>
                <FontAwesomeIcon className='hover:cursor-pointer' icon={solid('chevron-left')} />
              </button>
              <div className='font-semibold text-dark'>
                {getYearsRange(date).startPeriod} - {getYearsRange(date).endPeriod}
              </div>
              <button type='button' onClick={increaseYear}>
                <FontAwesomeIcon className='hover:cursor-pointer' icon={solid('chevron-right')} />
              </button>
            </div>
          );
        }}
      />
      {props.model.field.value && (
        <button className='absolute right-[15%]'>
          <FontAwesomeIcon
            onClick={() => props.model.form.setFieldValue(props.model.field.name, null)}
            className='text-brandDark'
            icon={regular('xmark')}
          />
        </button>
      )}
    </div>
  );
};
