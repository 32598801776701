import cn from 'classnames';
import { ImpactDelta, ImpactDeltaType } from '../../../../api';

export const getImpactDeltaBg = (data: ImpactDelta) =>
  ({
    [ImpactDeltaType.Higher]: 'bg-red-500',
    [ImpactDeltaType.Lower]: 'bg-emerald-700',
    [ImpactDeltaType.Zero]: 'bg-zinc-500',
  }[data.type]);

export const ImpactDeltaBadge = (props: { data: ImpactDelta; className?: string }) => (
  <div className={cn(props.className, 'text-white', getImpactDeltaBg(props.data))}>{props.data.display}</div>
);
