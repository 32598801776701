import cn from 'classnames';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { NavLink, useNavigate } from 'react-router-dom';
import { ProductRevisionPreview, getListOfRevisionsV3, getRevisionV3, saveProductV3 } from '../../../../api';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { useApiQuery } from '../../../../hooks/useApiQuery';
import { useProfile } from '../../../../hooks/useProfile';
import { BasicDetails } from './BasicDetails';
import { ExtendedDetails } from './ExtendedDetails';
import { RevisionsModal } from './RevisionsModal';
import { ReadOnlyWarning } from '../../../../components/ReadOnlyWarning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

export const RevisionOverview = () => {
  const productId = useParams<{ productId: string }>().productId!;
  const revisionId = parseInt(useParams<{ revisionId: string }>().revisionId!);

  const revision = useApiQuery(getRevisionV3(productId, revisionId));
  const revisions = useApiQuery(getListOfRevisionsV3(productId!));

  const revisionsModalRef = useRef<ModalApi>(null);
  const confirmationModalRef = useRef<ModalApi>(null);

  const [selectedRevision, setSelectedRevision] = useState<ProductRevisionPreview>();
  const [revisionRestored, setRevisionRestored] = useState(false);

  const navigate = useNavigate();
  const profile = useProfile();

  if (!(revision.data && revisions.data)) {
    return <></>;
  }

  const latestRevision = revisionId === revisions.data.revisions.length;

  const title = `${revision.data.product.name} - ${revision.data.comment}`;

  return (
    <div className='flex flex-col gap-8 px-6'>
      <Helmet title={title} />

      <div className='flex gap-6 pb-4 border-b border-gray-300/60'>
        <div className='print:hidden flex justify-center items-center'>
          <button
            onClick={() => navigate('../')}
            className='flex items-center justify-center bg-[#E8EAF5] rounded-lg w-8 h-8 hover:bg-white hover:border-2 hover:border-brand'
          >
            <FontAwesomeIcon className='text-xl text-brand' icon={regular('chevron-left')} />
          </button>
        </div>
        <div className='flex flex-col gap-2 w-full truncate'>
          <ModalV3
            noCloseOnConfirm
            noCloseOnCancel
            size='narrow-'
            ref={confirmationModalRef}
            confirmLabel={revisionRestored ? 'View product' : undefined}
            cancelLabel={revisionRestored ? 'Close' : undefined}
            title={revisionRestored ? 'Restore complete' : 'Restore this version?'}
            body={
              selectedRevision && (
                <div className='-mt-6'>
                  {revisionRestored ? (
                    <>
                      Your product was successfully restored to an earlier version from{' '}
                      <span className='font-semibold'>
                        {formatInTimeZone(parseISO(selectedRevision.createdAt), 'UTC', 'd MMM yy HH:mm')}.
                      </span>
                    </>
                  ) : (
                    <>
                      Your current product will revert to the version from{' '}
                      <span className='font-semibold'>
                        {formatInTimeZone(parseISO(selectedRevision.createdAt), 'UTC', 'd MMM yy HH:mm')} UTC
                      </span>
                      . This will apply to everyone in this workspace and to all models/volume reports.
                    </>
                  )}
                </div>
              )
            }
            onCancel={() => {
              if (revisionRestored) {
                navigate('/products');
              } else {
                setRevisionRestored(false);
                setSelectedRevision(undefined);
                confirmationModalRef.current!.close();
              }
            }}
            onConfirm={() => {
              if (selectedRevision) {
                if (!revisionRestored) {
                  getRevisionV3(selectedRevision.id, selectedRevision.revision).ok(({ product }) => {
                    if (product.validation.errors.length > 0 || product.validation.warnings.length > 0) {
                      navigate(`/products/${selectedRevision.id}/revisions/${selectedRevision.revision}`);
                    } else {
                      saveProductV3(product).ok(() => {
                        setRevisionRestored(true);
                      });
                    }
                  });
                } else {
                  navigate(`/products/${selectedRevision.id}`);
                }
              }
            }}
          />
          <div className='flex items-center justify-between gap-8'>
            <div className='text-2xl font-semibold truncate'>
              {latestRevision ? 'Current product: ' : `Version ${revisionId}: `} {title}
            </div>
            <div className='flex gap-4'>
              <RevisionsModal
                modalRef={revisionsModalRef}
                product={revision.data.product}
                revisionsList={revisions.data.revisions}
                onViewClick={({ id, revisionNumber }) => {
                  navigate(`/products/${id}/revisions/${revisionNumber}/overview`);
                  revisionsModalRef.current!.close();
                }}
              >
                <button
                  onClick={() => setSelectedRevision(undefined)}
                  type='button'
                  className='flex items-center gap-3 border-2 border-[#220066] rounded-full px-4 py-1 text-[#220066] font-semibold active:scale-95 whitespace-nowrap'
                >
                  Return to edit history
                </button>
              </RevisionsModal>
              <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                <button
                  disabled={latestRevision || !profile.selectedWorkspace.permissions.productManagement}
                  onClick={() => {
                    confirmationModalRef.current!.open();
                    setSelectedRevision(revision.data);
                  }}
                  type='button'
                  className={cn(
                    'bg-brand rounded-full px-4 py-1 text-white font-semibold whitespace-nowrap',
                    '[&:active:not(:disabled)]:scale-95 disabled:bg-neutral-300',
                  )}
                >
                  Restore this version
                </button>
              </ReadOnlyWarning>
            </div>
          </div>
        </div>
      </div>

      <BasicDetails
        data={revision.data.product}
        fields={[
          { name: 'Product name', value: revision.data.product.name },
          { name: 'ID', value: revision.data.product.skuId },
          { name: 'GTIN', value: revision.data.product.gtin ?? '—' },
          { name: 'Category', value: revision.data.product.category?.name ?? '—' },
          { name: 'Net amount', value: revision.data.product.amount.value + revision.data.product.amount.unit.name },
          { name: 'Number of servings', value: revision.data.product.servings ? revision.data.product.servings : '—' },
          { name: 'Product type', value: revision.data.product.foodType.name },
          { name: 'Food type', value: revision.data.product.foodType.name },
          ...(() => {
            return revision.data.product.economicValue
              ? [
                  {
                    name: 'Economic value',
                    value: `${revision.data.product.economicValue.price} ${revision.data.product.economicValue.currency.name}/kg`,
                  },
                ]
              : [];
          })(),
          { name: 'Conservation requirements', value: revision.data.product.conservation.requirement.name },
        ]}
      />

      {profile.selectedWorkspace.permissions.productManagement && (
        <div className='flex flex-col gap-8 border rounded-regular shadow-regular p-8 print:hidden'>
          <div className='text-2xl font-semibold'>Life cycle visualisation</div>
          <div>
            You can view or edit your product’s detailed life cycle, from its raw materials to production, to distribution, use and end of
            life, by clicking on the button below. You will be taken into our graph editing interface where you’ll be able to understand the
            properties of the product, its suppliers, any primary data added into the system and more.
          </div>
          <NavLink
            type='button'
            className='px-4 py-1 flex self-center rounded-full bg-brand font-semibold text-white border-brand border-2 active:scale-95'
            to={`/products/${revision.data.product.id}`}
            target='_blank'
          >
            View product
          </NavLink>
        </div>
      )}

      <ExtendedDetails data={revision.data} />
    </div>
  );
};
