import { useApiQuery } from '../../../../../../../hooks/useApiQuery';
import { getManufacturingHistoricalReports, getPefBaseline, HistoricalPefReport, ManufacturingPefReport } from '../../../../../../../api';
import { SetStateAction, useState } from 'react';
import { AppRoutes } from '../../../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import InfiniteScroll from '../../../../../../../components/InfiniteScroll';
import { useDynamicHeight } from '../../../../../../../hooks/useDynamicHeight';
import { format, parseISO } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { convertUTCDateToLocal } from '../../../../../../../components/datepicker/utils';
import { Step2Skeleton } from './2_Step_skeleton';
import cn from 'classnames';

interface Props {
  onNext: () => void;
  selectedReportId: string | undefined;
  setSelectedReportId: (v: SetStateAction<string | undefined>) => void;
}

export const Step2 = (props: Props) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const height = useDynamicHeight(containerRef, undefined, true);

  return (
    <>
      <div className='flex flex-col gap-y-4 h-full'>
        <div className='text-lg font-semibold'>Use timeframe and volumes from a previous report</div>
        <div>
          Select one of the previous reports that you created to pre-populate timeframe, products and volumes of production. You will be
          able to edit all data In the next step.
        </div>
      </div>
      <div ref={setContainerRef}>
        <Content
          height={height}
          setSelectedReportId={props.setSelectedReportId}
          selectedReportId={props.selectedReportId}
          onNext={props.onNext}
        />
      </div>
      <div className='flex justify-center fixed bottom-0 left-0 border-t w-full py-4 bg-white'>
        <div className='flex justify-end w-[calc(theme(screens.xl)_-_theme(spacing.24))]'>
          <button
            type='button'
            disabled={!props.selectedReportId}
            className={cn(
              'flex text-sm items-center gap-2.5 border-2 rounded-full px-4 py-2 font-semibold',
              '[not:(:disabled)]:active:scale-95 bg-brand text-white border-brand',
              'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
            )}
            onClick={props.onNext}
          >
            Next
            <FontAwesomeIcon className='text-base' icon={solid('chevron-right')} />
          </button>
        </div>
      </div>
    </>
  );
};

export const Content = (props: Props & { height?: number }) => {
  const baseline = useApiQuery(getPefBaseline());
  const reports = useApiQuery(
    getManufacturingHistoricalReports({
      pageSize: 1000,
      pageToken: '',
    }),
  );

  if (!reports.data || !baseline.data) return <Step2Skeleton height={props.height} />;

  const payload = {
    ...reports.data,
    reports: [
      ...(() => (baseline.data.errorCode ? [] : [{ ...baseline.data, id: 'baseline', name: 'Baseline' }]))(),
      ...reports.data.reports,
    ].map((report) => ({
      ...report,
      startDate: convertUTCDateToLocal(report.startDate),
      endDate: convertUTCDateToLocal(report.endDate),
    })) as (ManufacturingPefReport & { name: string })[],
  };

  return (
    <InfiniteScroll
      height={props.height}
      dataLength={payload.reports.length}
      next={props.onNext}
      hasMore={payload.nextPageToken !== ''}
      loader={<></>}
    >
      <div className='grid grid-cols-[6fr_2fr_2fr_2fr_2fr_1fr] *:min-h-[65px] *:items-center'>
        <div className={cn('grid grid-cols-subgrid col-span-6 sticky top-0 border-t items-center gap-x-2 bg-white font-semibold h-12')}>
          <div className='pl-6'>Report Name</div>
          <div>Creation Date</div>
          <div>Date range</div>
          <div>Products</div>
          <div>Total Units</div>
          <div className='text-center'>
            <FontAwesomeIcon icon={light('file-chart-column')} />
          </div>
        </div>
        {payload.reports.map((report, i) => (
          <div
            onClick={() => props.setSelectedReportId(report.id)}
            className={cn(
              'grid grid-cols-subgrid col-span-6 py-2 cursor-pointer border-t',
              props.selectedReportId === report.id ? 'bg-slate-100' : i % 2 === 0 ? 'bg-neutral-50' : 'bg-white',
            )}
            key={i}
          >
            <div className='pl-6'>{report.name}</div>
            <div>{format(parseISO(report.createdAt), 'dd/MM/yyyy')}</div>
            <div>
              {format(new Date((report as HistoricalPefReport).startDate), 'dd/MM/yy')}
              <span>-</span>
              {format(new Date((report as HistoricalPefReport).endDate), 'dd/MM/yy')}
            </div>
            <div>{report.productCount}</div>
            <div>{report.totalUnitCount}</div>
            <NavLink
              target='_blank'
              to={report.id === 'baseline' ? AppRoutes().report.baselineOverview : AppRoutes(report.id).report.historicalOverview}
              className='text-center text-brandDark font-semibold hover:underline active:scale-95'
            >
              View
            </NavLink>
          </div>
        ))}
      </div>
    </InfiniteScroll>
  );
};
