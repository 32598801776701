import { TooltipV3 } from './TooltipV3';
import { PropsWithChildren } from 'react';
import { useProfile } from '../hooks/useProfile';

interface Props {
  show: boolean;
}

export const ReadOnlyWarning = (props: PropsWithChildren<Props>) => {
  const profile = useProfile();

  return props.show ? (
    <TooltipV3
      disabled={!props.show}
      content={
        <div className='w-96 rounded-lg border border-amber-400 shadow bg-amber-50 text-sm p-3'>
          To ensure a unique source of truth, these actions can only be performed via{' '}
          <span className='font-semibold'>{profile.selectedWorkspace.integrationName}</span>
        </div>
      }
    >
      {props.children}
    </TooltipV3>
  ) : (
    <>{props.children}</>
  );
};
