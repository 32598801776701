import cn from 'classnames';
import { FormikContextType } from 'formik';
import { Fragment, PropsWithChildren, useState } from 'react';
import { GenericFacilityWithStepsNode, GenericNode, ProductV3, Tag } from '../../../../api';
import { getMetadataOfSpecificTarget, getNodeMetadataItems, getSubNodeMetadataItems, isPlaceholder } from './dataModel';

export const getOrderedTagToBadgeMappings = () => [
  {
    tag: Tag.Unmatched,
    badge: <UnmatchedBadge />,
  },
  {
    tag: Tag.LowQualityMatch,
    badge: <LowQualityMatchBadge />,
  },
  {
    tag: Tag.BestMatch,
    badge: <BestMatchBadge />,
  },
  { tag: Tag.Default, badge: <DefaultBadge /> },
  {
    tag: Tag.ExactMatch,
    badge: <ExactMatchBadge />,
  },
];

export const renderBadgeForTag = (tag: Tag) => getOrderedTagToBadgeMappings().find((item) => item.tag === tag)?.badge;

type BadgeListItem = Tag | 'placeholder';

interface BadgeProps {
  className?: string;
}

export const renderBadges = (
  node: GenericNode | undefined,
  formik: FormikContextType<ProductV3>,
  options?: BadgeProps & { subNodes?: boolean; target?: string },
) => {
  if (node) {
    const list = new Array<BadgeListItem>();

    for (const { tag } of getOrderedTagToBadgeMappings()) {
      if (
        options?.target &&
        getMetadataOfSpecificTarget(node, formik, options.target)
          .flatMap(({ tags }) => tags)
          .includes(tag)
      ) {
        list.push(tag);
      }

      if (
        !options?.target &&
        (options?.subNodes ? getSubNodeMetadataItems(node, formik) : getNodeMetadataItems(node, formik))
          .flatMap(({ tags }) => tags)
          .includes(tag)
      ) {
        list.push(tag);
      }

      if (
        tag === Tag.BestMatch &&
        (options?.subNodes ? ((node as GenericFacilityWithStepsNode).steps ?? []).some(isPlaceholder) : isPlaceholder(node))
      ) {
        list.push('placeholder');
      }
    }

    if (list.length > 0) {
      return <Badges list={list} {...options} />;
    }
  }

  return <></>;
};

const Badges = (props: { list: BadgeListItem[]; className?: string }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className={cn('flex flex-wrap gap-1', props?.className)}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      {props.list.slice(0, expanded ? undefined : 1).map((item) => (
        <Fragment key={item}>{item === 'placeholder' ? <PlaceholderBadge /> : renderBadgeForTag(item)}</Fragment>
      ))}
      {!expanded && props.list.length > 1 && <PlusOthersBadge count={props.list.length - 1} />}
    </div>
  );
};

export const DefaultBadge = (props: BadgeProps) => (
  <Badge className={cn('bg-[#E8EAF5] tracking-wider uppercase', props.className)}>default</Badge>
);

export const PlaceholderBadge = (props: BadgeProps) => (
  <Badge className={cn('bg-zinc-200 tracking-wider uppercase', props.className)}>placeholder</Badge>
);

export const ExactMatchBadge = (props: BadgeProps) => (
  <Badge className={cn('bg-emerald-100 tracking-wider uppercase', props.className)}>exact match</Badge>
);

export const BestMatchBadge = (props: BadgeProps) => (
  <Badge className={cn('bg-orange-100 tracking-wider uppercase', props.className)}>best match</Badge>
);

export const LowQualityMatchBadge = (props: BadgeProps) => (
  <Badge className={cn('bg-orange-100 tracking-wider uppercase', props.className)}>low quality match</Badge>
);

export const UnmatchedBadge = (props: BadgeProps) => (
  <Badge className={cn('bg-red-100 tracking-wider uppercase', props.className)}>unmatched</Badge>
);

const PlusOthersBadge = (props: BadgeProps & { count: number }) => (
  <Badge className={cn('bg-neutral-100', props.className)}>
    + {props.count} other{props.count > 1 && 's'}
  </Badge>
);

const Badge = (props: PropsWithChildren<{ className?: string }>) => (
  <div className={cn('text-zinc-700 text-[8px] font-semibold leading-none px-1.5 py-1 rounded', props.className)}>{props.children}</div>
);
