import {
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFocusTrap,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import cn from 'classnames';
import {
  CSSProperties,
  ForwardedRef,
  HTMLProps,
  PropsWithChildren,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useImperativeHandle,
  useRef,
  useState,
  useReducer,
  useEffect,
} from 'react';

export interface ModalApi {
  open: () => void;
  close: () => void;
}

type Props = PropsWithChildren<{
  title: ReactNode;
  body: ReactNode;
  footer?: (payload: { onConfirm: () => void; onCancel: () => void }) => ReactNode;
  onConfirm?: () => void;
  confirmLabel?: string;
  hideConfirm?: boolean;
  disableConfirm?: boolean;
  waiting?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  hideCancel?: boolean;
  onCancel?: () => void;
  cancelLabel?: string;
  onClose?: () => void;
  noCloseOnConfirm?: boolean;
  noCloseOnCancel?: boolean;
  size?: 'narrow-' | 'narrow' | 'wide' | 'wide+';
  preventDismiss?: boolean;
  fixedHeight?: boolean;
}>;

export const ModalV3 = forwardRef((props: Props, ref: ForwardedRef<ModalApi>) => {
  const [open, setOpen] = useState(props.open ?? false);

  const setOpenAndNotify = (newOpen: boolean) => {
    setOpen(newOpen);

    if (!newOpen && props.onClose) {
      props.onClose();
    }

    if (props.onOpenChange) {
      props.onOpenChange(newOpen);
    }
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenAndNotify(true);
    },
    close: () => {
      setOpenAndNotify(false);
    },
  }));

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: setOpenAndNotify,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocusTrap(context),
    useRole(context, { role: 'dialog' }),
    useDismiss(context, { outsidePointerDown: !props.preventDismiss, escapeKey: !props.preventDismiss }),
  ]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <FloatingPortal>
        {open && <Content {...props} floating={floating} getFloatingProps={getFloatingProps} setOpen={context.onOpenChange} />}
      </FloatingPortal>
    </>
  );
});

const Content = (
  props: Props & {
    floating: (node: HTMLElement | null) => void;
    getFloatingProps: (props?: HTMLProps<HTMLElement>) => any;
    setOpen: (open: boolean) => void;
  },
) => {
  const [, reRender] = useReducer((x) => x + 1, 0);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      reRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, footerRef.current]);

  return (
    <FloatingOverlay lockScroll className='flex justify-center items-center bg-neutral-400/75 z-50'>
      <div
        {...props.getFloatingProps({
          ref: props.floating,
        })}
        className={cn(
          'antialiased text-sm text-body m-8 bg-white shadow-2xl border rounded-xl w-full',
          props.size
            ? {
                'narrow-': 'max-w-lg',
                narrow: 'max-w-xl',
                wide: 'max-w-4xl',
                'wide+': 'max-w-6xl',
              }[props.size]
            : 'max-w-2xl',
        )}
      >
        <div className='flex flex-col'>
          <div ref={headerRef} className='p-6 flex flex-col font-semibold text-xl text-neutral-900'>
            {props.title}
          </div>
          <div
            style={
              {
                '--header-height': `${headerRef.current ? headerRef.current.getBoundingClientRect().height : 0}px`,
                '--footer-height': `${footerRef.current ? footerRef.current.getBoundingClientRect().height : 0}px`,
              } as CSSProperties
            }
            className={cn(
              'overflow-y-auto',
              props.fixedHeight
                ? 'h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height)_-_var(--footer-height))]'
                : 'p-6 max-h-[calc(100vh_-_theme(spacing.8)*2_-_theme(spacing.6)*2_-_var(--header-height)_-_var(--footer-height))]',
            )}
          >
            {props.body}
          </div>
          <div ref={footerRef} className='mx-6 py-6 flex justify-between border-t border-zinc-300'>
            {props.footer ? (
              (() => {
                return props.footer({
                  onConfirm: () => {
                    props.onConfirm?.();
                    props.setOpen(false);
                  },
                  onCancel: () => {
                    props.setOpen(false);
                  },
                });
              })()
            ) : (
              <>
                <button
                  type='button'
                  className={cn(
                    'flex justify-center border-2 border-brand rounded-full px-4 py-1 text-brand font-semibold whitespace-nowrap',
                    'active:scale-95',
                    {
                      invisible: props.hideCancel,
                    },
                  )}
                  onClick={() => {
                    if (props.onCancel) {
                      props.onCancel();
                    }

                    !props.noCloseOnCancel && props.setOpen(false);
                  }}
                >
                  {props.cancelLabel ?? 'Cancel'}
                </button>
                <button
                  disabled={props.disableConfirm || props.waiting}
                  type='button'
                  className={cn(
                    'flex justify-center border-2 border-transparent rounded-full px-4 py-1 font-semibold whitespace-nowrap',
                    'bg-brand text-white disabled:bg-zinc-200 disabled:text-zinc-400 [&:active:not(:disabled)]:scale-95',
                    props.waiting ? 'disabled:cursor-wait' : 'disabled:cursor-not-allowed',
                    { invisible: props.hideConfirm },
                  )}
                  onClick={() => {
                    if (props.onConfirm) {
                      props.onConfirm();
                    }

                    !props.noCloseOnConfirm && props.setOpen(false);
                  }}
                >
                  {props.confirmLabel ?? 'Confirm'}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </FloatingOverlay>
  );
};
