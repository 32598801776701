import { forwardRef } from 'react';
import { simplify } from '../../../../shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
  title: string;
}

export const EcoLabelingOverviewPlaceholder = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div className='flex flex-col gap-4' ref={ref}>
      <div className='text-base flex items-center gap-x-4 bg-white p-4 rounded-lg border'>
        <div className='size-10 bg-brandLime flex items-center justify-center rounded-full'>
          <FontAwesomeIcon className='text-[18px] text-zinc-700' icon={light('lightbulb')} />
        </div>
        <div>We couldn't find any data for this section.</div>
      </div>
      <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
      <div className='flex flex-1 gap-x-6 opacity-50 pointer-events-none'>
        <div className='grid grid-cols-3 divide-x py-4 px-6 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
          <div className='flex flex-col gap-y-4 items-center'>
            <div className='text-zinc-800 font-semibold text-base'>Final products</div>
            <div className='flex items-center flex-col gap-y-2'>
              <div className='text-zinc-900 text-[32px]'>{simplify(12340)}</div>
              <div className='text-zinc-500 text-xs'>/ {simplify(15000)} products</div>
            </div>
          </div>
          <div className='flex flex-col gap-y-4 px-6'>
            <div className='text-zinc-800 font-semibold text-base text-center'>Average grade</div>
            <div className='bg-d size-8 mt-2 text-lg flex self-center justify-center items-center text-white font-semibold rounded-full'>
              D
            </div>
          </div>
          <div className='flex flex-col gap-y-4 pl-6'>
            <div className='text-zinc-800 font-semibold text-base'>Eco-labelling method</div>
          </div>
        </div>
      </div>
    </div>
  );
});
