import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Fragment, forwardRef, useState } from 'react';
import { FeLogo } from '../components/FeLogo';
import { TimeframeDisplay } from '../components/TimeframeDisplay';
import { Methodology, Timeframe } from '../types';
import planeImage from './plane.png';

interface Props {
  title: string;
  methodology: Methodology;
  timeframe: Timeframe;
  physicalImpact: boolean;
  impactCategories: string[];
  workspaceIds: string[];
}

export const Overview = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [categories, setCategories] = useState(true);

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        {props.methodology !== Methodology.Consumer && <TimeframeDisplay value={props.timeframe} />}
      </div>
      <div className='flex gap-3'>
        {props.methodology === Methodology.Consumer ? (
          <div className='whitespace-nowrap flex gap-8 px-6 py-4 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
            {[
              {
                title: 'Final products',
                content: (
                  <div className='flex flex-col gap-1'>
                    <div className='text-zinc-900 text-3xl'>12,340</div>
                    <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>/15,000 products</div>
                  </div>
                ),
              },
              {
                title: 'Average grade',
                content: <div className='flex justify-center items-center size-8 text-lg rounded-full text-white bg-d'>D</div>,
              },
              {
                title: 'Eco-labelling method',
                content: <FeLogo />,
              },
            ].map(({ title, content }, i) => (
              <Fragment key={title}>
                {i > 0 && <div className='shrink-0 w-px bg-zinc-200' />}
                <div className='flex flex-col gap-4'>
                  <div className='text-zinc-800 text-base font-semibold'>{title}</div>
                  <div className='flex justify-center items-center h-14'>{content}</div>
                </div>
              </Fragment>
            ))}
          </div>
        ) : (
          <>
            <div className='whitespace-nowrap flex flex-col gap-4 p-4 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
              <div className='text-zinc-800 text-base font-semibold'>Current impact</div>
              <div className='flex gap-3'>
                <div className='flex-1 flex flex-col gap-4'>
                  <div className='flex flex-col gap-1'>
                    <div className='text-3xl text-zinc-900'>
                      {props.impactCategories.length === 1 && props.impactCategories[0] === '1'
                        ? props.physicalImpact
                          ? '5.89M'
                          : '373M'
                        : props.workspaceIds.length === 1
                        ? '48M'
                        : '734M'}
                    </div>
                    <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>
                      {props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact
                        ? 'mt co₂ eq'
                        : 'Impact points'}
                    </div>
                  </div>
                  <div className='self-start flex gap-2 p-2 pr-3 rounded-full bg-emerald-50'>
                    <div className='flex justify-center items-center size-4 rounded-full text-emerald-50 bg-emerald-700'>
                      <FontAwesomeIcon icon={solid('arrow-down')} className='text-[8px]' />
                    </div>
                    <div className='text-emerald-700 text-base leading-none'>
                      {props.impactCategories.length === 1 && props.impactCategories[0] === '1' && props.physicalImpact ? '-56%' : '-61%'}
                    </div>
                  </div>
                </div>
                <div className='w-px shrink-0 bg-zinc-200' />
                <div className='flex-1 flex flex-col gap-4'>
                  <div className='flex flex-col gap-1'>
                    <div className='text-3xl text-zinc-900'>
                      {props.impactCategories.length === 1 && props.impactCategories[0] === '1'
                        ? '-90%'
                        : props.workspaceIds.length === 1
                        ? '-12.5%'
                        : '-80%'}
                    </div>
                    <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>
                      {props.workspaceIds.length === 1 ? 'On average per year' : 'By 2025'}
                    </div>
                  </div>
                  <div
                    className={cn(
                      'self-start flex items-center gap-2 p-4 py-2 rounded-full',
                      props.impactCategories.length === 1 && props.impactCategories[0] === '1' ? 'bg-red-50' : 'bg-emerald-50',
                    )}
                  >
                    {props.impactCategories.length === 1 && props.impactCategories[0] === '1' ? (
                      <FontAwesomeIcon icon={regular('triangle-exclamation')} className='text-red-500' />
                    ) : (
                      <FontAwesomeIcon icon={solid('check')} className='text-emerald-700' />
                    )}
                    <div
                      className={cn(
                        'font-semibold leading-none',
                        props.impactCategories.length === 1 && props.impactCategories[0] === '1' ? 'text-red-500' : 'text-emerald-700',
                      )}
                    >
                      {props.impactCategories.length === 1 && props.impactCategories[0] === '1' ? 'Insufficient' : 'On track'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex gap-6 p-4 bg-white border border-zinc-200 rounded-2xl'>
              {props.methodology === Methodology.Production && (
                <>
                  <button
                    className='max-w-32 leading-tight grid grid-cols-1 items-stretch gap-2 -m-2 mr-0'
                    onClick={() => setCategories((current) => !current)}
                  >
                    <div
                      className={cn(
                        'flex text-center items-center rounded-2xl transition',
                        categories ? 'text-brandDarkPurple2 bg-indigo-50' : 'text-zinc-500 bg-slate-50',
                      )}
                    >
                      Category top contributors
                    </div>
                    <div
                      className={cn(
                        'flex text-center items-center rounded-2xl transition',
                        !categories ? 'text-orange-900 bg-orange-100' : 'text-zinc-500 bg-slate-50',
                      )}
                    >
                      Process top contributors
                    </div>
                  </button>
                  <div className='grid grid-cols-[1fr_1px_1fr_1px_1fr] gap-4'>
                    {categories
                      ? [
                          {
                            title: 'Climate change',
                            percentage: '60%',
                            value: props.workspaceIds.length === 1 ? '385K' : '5.89M',
                            unit: 'mt co₂ eq',
                            change: props.workspaceIds.length === 1 ? '-402K' : '-7.3M',
                            changeUnit: 'mt co₂ eq',
                          },
                          ...(props.impactCategories.length === 1 && props.impactCategories[0] === '1'
                            ? []
                            : [
                                {
                                  title: 'Water scarcity',
                                  percentage: '20%',
                                  value: props.workspaceIds.length === 1 ? '-167K' : '2.78M',
                                  unit: 'm³ depr.',
                                  change: props.workspaceIds.length === 1 ? '-193K' : '2.78M',
                                  changeUnit: 'm³ depr.',
                                },
                                {
                                  title: 'Land use',
                                  percentage: '14%',
                                  value: props.workspaceIds.length === 1 ? '-263K' : '4.83M',
                                  unit: 'points',
                                  change: props.workspaceIds.length === 1 ? '+30K' : '+800K',
                                  changeUnit: 'points',
                                },
                              ]),
                        ].map(({ title, percentage, value, unit, change, changeUnit }, i) => (
                          <Fragment key={title}>
                            {i > 0 && <div className='bg-zinc-200' />}
                            <div className='flex flex-col justify-between'>
                              <div className='font-semibold text-zinc-800'>{title}</div>
                              <div className='text-2xl text-zinc-800'>{percentage}</div>
                              <div className='whitespace-nowrap flex gap-1 items-center'>
                                <div className='text-zinc-600 text-base'>{value}</div>
                                <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>{unit}</div>
                              </div>
                              <div
                                className={cn(
                                  'self-start flex gap-2 p-2 pr-3 rounded-full bg-zinc-50',
                                  change.startsWith('+') ? 'text-red-500' : 'text-emerald-700',
                                )}
                              >
                                <div
                                  className={cn(
                                    'flex justify-center items-center size-4 rounded-full border',
                                    change.startsWith('+') ? 'border-red-500' : 'border-emerald-700',
                                  )}
                                >
                                  <FontAwesomeIcon
                                    icon={change.startsWith('+') ? solid('arrow-up') : solid('arrow-down')}
                                    className='text-[8px]'
                                  />
                                </div>
                                <div className='whitespace-nowrap flex gap-1 items-center'>
                                  <span>{change}</span>
                                  <span className='uppercase tracking-uppercase text-[10px]'>{changeUnit}</span>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))
                      : [
                          {
                            name: 'JavaJolt 100ml > Caffeteria IT',
                            title: 'Pouch packing',
                            percentage: '2.23%',
                            label: 'production step',
                          },
                          {
                            name: 'MeatyLover 450g',
                            title: 'Beef joint - 210g',
                            percentage: '1.23%',
                            label: 'ingredient',
                          },
                          {
                            name: 'JavaJolt 100ml',
                            title: 'Bottle - 20g',
                            percentage: '0.9%',
                            label: 'packaging',
                          },
                        ].map(({ name, title, percentage, label }, i) => (
                          <Fragment key={title}>
                            {i > 0 && <div className='w-px shrink-0 bg-zinc-200' />}
                            <div className='flex flex-col justify-between'>
                              <div className='text-zinc-600'>{name}</div>
                              <div className='font-semibold text-zinc-800'>{title}</div>
                              <div className='text-2xl text-zinc-800'>{percentage}</div>
                              <div className='self-start leading-none uppercase tracking-uppercase text-[10px] px-3 py-2 rounded-full text-orange-900 bg-orange-50'>
                                {label}
                              </div>
                            </div>
                          </Fragment>
                        ))}
                    {categories && props.impactCategories.length === 1 && props.impactCategories[0] === '1' && (
                      <>
                        <div className='w-px shrink-0 bg-zinc-200' />
                        <div className='col-span-3 flex justify-between gap-2 p-2 pl-4 bg-slate-100 rounded-2xl'>
                          <div className='w-40 flex flex-col justify-center gap-4'>
                            <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>Equivalent to</div>
                            <div className='text-zinc-700'>
                              <span className='font-semibold'>-10,284</span> flights from Birmingham to Swansea
                            </div>
                          </div>
                          <img className='rounded-2xl size-[122px]' src={planeImage} alt='' />
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {props.methodology === Methodology.Ghg && (
                <div className='flex flex-col justify-between'>
                  <div className='flex items-center gap-2'>
                    <div className='flex justify-center items-center size-8 rounded-lg text-[#753BFD] bg-slate-100'>
                      <FontAwesomeIcon icon={light('industry-windows')} />
                    </div>
                    <div className='text-zinc-800'>Purchased goods & services</div>
                  </div>
                  <div className='flex flex-col gap-1 ml-1'>
                    <div className='text-zinc-700 text-2xl'>10,356</div>
                    <div className='uppercase tracking-uppercase text-zinc-500 text-[10px]'>mt co₂ eq</div>
                  </div>
                  <div className='self-start flex items-center gap-2 p-2 pr-3 rounded-full text-emerald-700 bg-zinc-50'>
                    <div className='flex justify-center items-center size-4 rounded-full border border-emerald-700'>
                      <FontAwesomeIcon icon={solid('arrow-down')} className='text-[8px]' />
                    </div>
                    <div className='whitespace-nowrap flex gap-1 items-center'>
                      <span className='text-base leading-none'>-2,026</span>
                      <span className='text-xs uppercase tracking-uppercase'>mt co₂ eq</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
});
