import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { ReactNode, RefObject, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { LifeCycleStage, ProductV3, Tag, TransportNode, getLifeCycleForNodeType } from '../../../../api';
import { Menu } from '../../../../components/Menu';
import { ModalFormSaveCallback } from '../../../../components/ModalForm';
import { DefaultBadge } from './Badges';
import { TransportDetails } from './TransportDetails';
import { getPrimaryAndMaterialSuppliers, getPrimaryTag, hasValidationMessage } from './dataModel';

interface Props {
  disabled: boolean;
  nodeRefs: RefObject<Record<string, RefObject<HTMLDivElement>>>;
  lifeCycleBoxRefs: RefObject<Record<LifeCycleStage, RefObject<HTMLDivElement>>>;
  scrollableRef: RefObject<HTMLDivElement>;
  fromId: string;
  toId: string;
  transport?: TransportNode;
  ignoreLessImportantValidation: boolean;
  onSave?: ModalFormSaveCallback<TransportNode, { fromId: string }>;
  onDelete?: () => void;
}

export const TransportLink = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const [, render] = useReducer((x) => x + 1, 0);
  const fromRef = props.nodeRefs.current![props.fromId]?.current;
  const toRef = props.nodeRefs.current![props.toId]?.current;
  const scrollableRef = props.scrollableRef.current!;
  const [hovering, setHovering] = useState(false);

  useLayoutEffect(render, [formik.values, render]);

  useEffect(() => {
    window.addEventListener('resize', render);
    return () => window.removeEventListener('resize', render);
  }, []);

  useEffect(() => {
    const refs = Object.values(props.lifeCycleBoxRefs.current!);
    const observer = new ResizeObserver(render);

    refs.forEach((ref) => observer.observe(ref.current!));

    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!fromRef || !toRef) {
    return <></>;
  }

  const fromNode = getPrimaryAndMaterialSuppliers(formik).find(({ id }) => id === props.fromId);
  const toNode = getPrimaryAndMaterialSuppliers(formik).find(({ id }) => id === props.toId);

  if (!fromNode || !toNode) {
    return <></>;
  }

  const fromLifeCycle = getLifeCycleForNodeType(fromNode.type);
  const toLifeCycle = getLifeCycleForNodeType(toNode.type);
  const fromBoxRef = props.lifeCycleBoxRefs.current![fromLifeCycle].current;
  const toBoxRef = props.lifeCycleBoxRefs.current![toLifeCycle].current;

  if (!fromBoxRef || !toBoxRef) {
    return <></>;
  }

  const from = fromRef.getBoundingClientRect();
  const to = toRef.getBoundingClientRect();
  const fromBox = fromBoxRef.getBoundingClientRect();
  const toBox = toBoxRef.getBoundingClientRect();
  const sameBox = fromBoxRef === toBoxRef;
  const scrollable = scrollableRef.getBoundingClientRect();
  const editable = !!props.transport;
  const enabled = editable && !props.disabled;
  const error = editable && hasValidationMessage(props.transport!, formik, { ignoreLessImportant: props.ignoreLessImportantValidation });
  const _default = !!props.transport && getPrimaryTag(props.transport, formik) === Tag.Default;

  const onMouseEnter = () => {
    if (enabled) {
      setHovering(true);
    }
  };

  const onMouseLeave = () => {
    setHovering(false);
  };

  const openMenu = (children: ReactNode, onDelete?: () => void) => {
    return enabled && props.onSave ? (
      <Menu
        zIndex={11}
        placement='bottom-end'
        scrollableParent={scrollableRef}
        items={[
          {
            label: 'Edit',
            icon: regular('pen'),
            modal: (button, onOpenChange) => (
              <TransportDetails onOpenChange={onOpenChange} data={props.transport} onSave={props.onSave!}>
                {button}
              </TransportDetails>
            ),
          },
          {
            label: 'Delete',
            icon: regular('trash-alt'),
            onClick: onDelete,
          },
        ]}
      >
        {() => children}
      </Menu>
    ) : (
      children
    );
  };

  if (from.left === to.left) {
    return (
      <Vertical
        from={from}
        to={to}
        scrollableRef={scrollableRef}
        scrollable={scrollable}
        hovering={hovering}
        enabled={enabled}
        editable={editable}
        error={error}
        default={_default}
        onDelete={props.onDelete}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        openMenu={openMenu}
      />
    );
  }

  if (from.left > to.left) {
    return (
      <Backwards
        from={from}
        to={to}
        fromBox={fromBox}
        toBox={toBox}
        sameBox={sameBox}
        scrollableRef={scrollableRef}
        scrollable={scrollable}
        hovering={hovering}
        enabled={enabled}
        editable={editable}
        error={error}
        default={_default}
        onDelete={props.onDelete}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        openMenu={openMenu}
      />
    );
  }

  return (
    <Forwards
      lifeCycleBoxRefs={props.lifeCycleBoxRefs}
      from={from}
      to={to}
      fromLifeCycle={fromLifeCycle}
      toLifeCycle={toLifeCycle}
      fromBox={fromBox}
      toBox={toBox}
      sameBox={sameBox}
      scrollableRef={scrollableRef}
      scrollable={scrollable}
      hovering={hovering}
      enabled={enabled}
      editable={editable}
      error={error}
      default={_default}
      onDelete={props.onDelete}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      openMenu={openMenu}
    />
  );
};

const Forwards = (props: {
  lifeCycleBoxRefs: RefObject<Record<LifeCycleStage, RefObject<HTMLDivElement>>>;
  from: DOMRect;
  to: DOMRect;
  fromLifeCycle: LifeCycleStage;
  toLifeCycle: LifeCycleStage;
  fromBox: DOMRect;
  toBox: DOMRect;
  sameBox: boolean;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  hovering: boolean;
  enabled: boolean;
  editable: boolean;
  error: boolean;
  default: boolean;
  onDelete?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openMenu: (children: ReactNode, onDelete?: () => void) => ReactNode;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromRight = props.from.right + leftOffset;
  const toTop = props.to.top + topOffset;
  const toLeft = props.to.left + leftOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const fullWidth = props.to.left - props.from.right;
  const fromWidthExtraBoxSpace = (() => {
    if (props.fromLifeCycle === LifeCycleStage.Production && props.toLifeCycle === LifeCycleStage.Use) {
      const middleBox = props.lifeCycleBoxRefs.current![LifeCycleStage.Distribution].current!.getBoundingClientRect();
      return middleBox.right - props.fromBox.right;
    }

    return 0;
  })();
  const fromWidth = props.sameBox
    ? fullWidth / 2
    : props.fromBox.right + (props.toBox.left - props.fromBox.right + fromWidthExtraBoxSpace) / 2 - props.from.right;
  const toWidth = fullWidth - fromWidth;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const fromStraightWidth = fromWidth - cornerSize;
  const toStraightWidth = toWidth - cornerSize;

  return (
    <>
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          icon={solid('circle')}
          className={cn(
            'absolute h-[9px] aspect-square',
            props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
          )}
          style={{
            left: `${fromRight - 4}px`,
            top: `${fromCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        icon={solid('circle')}
        className={cn(
          'absolute h-[5px] aspect-square z-10',
          !props.editable ? 'text-zinc-400' : props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand',
        )}
        style={{
          left: `${fromRight - 2}px`,
          top: `${fromCenter - 2}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn(
            'h-[9px] aspect-square absolute rotate-90',
            props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
          )}
          icon={solid('triangle')}
          style={{
            left: `${toLeft - 6}px`,
            top: `${toCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn(
          'h-[5px] aspect-square absolute z-10 rotate-90',
          !props.editable ? 'text-zinc-400' : props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand',
        )}
        icon={solid('triangle')}
        style={{
          left: `${toLeft - 4}px`,
          top: `${toCenter - 2}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <div
          className={cn(
            'corner-from absolute rounded-full border-[5px] rotate-45 aspect-square',
            props.error ? 'border-red-500/40' : props.default ? 'border-[#C2B0F8]/40' : 'border-brand/40',
            {
              'border-l-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
              'border-l-transparent border-r-transparent border-b-transparent': fromCenter < toCenter,
              invisible: cornerSize === 0,
            },
          )}
          style={{
            width: `${cornerSize * 2 + 4}px`,
            left: `${fromRight + fromStraightWidth - cornerSize - 1}px`,
            top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1) - 2}px`,
          }}
        ></div>
      )}

      <div
        className={cn(
          'corner-from absolute rounded-full rotate-45 border aspect-square',
          !props.editable ? 'border-zinc-400' : props.error ? 'border-red-500' : props.default ? 'border-[#C2B0F8]' : 'border-brand',
          {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromRight + fromStraightWidth - cornerSize + 1}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn(
            'corner-to absolute rounded-full border-[5px] rotate-45 aspect-square',
            props.error ? 'border-red-500/40' : props.default ? 'border-[#C2B0F8]/40' : 'border-brand/40',
            {
              'border-r-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
              'border-t-transparent border-r-transparent border-l-transparent': fromCenter < toCenter,
              invisible: cornerSize === 0,
            },
          )}
          style={{
            width: `${cornerSize * 2 + 4}px`,
            left: `${toLeft - toStraightWidth - (cornerSize + 2)}px`,
            top: fromCenter > toCenter ? `${toCenter - 2}px` : `${toCenter - cornerSize * 2 - 1}px`,
          }}
        ></div>
      )}
      <div
        className={cn(
          'corner-to absolute rounded-full rotate-45 border aspect-square',
          !props.editable ? 'border-zinc-400' : props.error ? 'border-red-500' : props.default ? 'border-[#C2B0F8]' : 'border-brand',
          {
            'border-r-transparent border-t-transparent border-b-transparent': fromCenter > toCenter,
            'border-t-transparent border-r-transparent border-l-transparent': fromCenter < toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${toLeft - toStraightWidth - cornerSize}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn(
            'h-line-from absolute h-[5px]',
            !props.editable ? 'bg-zinc-400' : props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40',
          )}
          style={{
            width: `${fromStraightWidth + 1}px`,
            left: `${fromRight}px`,
            top: `${fromCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn(
          'h-line-from absolute h-px',
          !props.editable ? 'bg-zinc-400' : props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand',
        )}
        style={{
          width: `${fromStraightWidth + 1}px`,
          left: `${fromRight}px`,
          top: `${fromCenter}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn('v-line absolute w-[5px]', props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40', {
            invisible: cornerSize === 0,
          })}
          style={{
            height: `${straightHeight + 1}px`,
            left: `${fromRight + fromWidth - 2}px`,
            top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
          }}
        ></div>
      )}
      <div
        className={cn(
          'v-line absolute w-px',
          !props.editable ? 'bg-zinc-400' : props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand',
        )}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${fromRight + fromWidth}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn(
            'h-line-to absolute h-[5px]',
            !props.editable ? 'bg-zinc-400' : props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40',
          )}
          style={{
            width: `${toStraightWidth - 2}px`,
            left: `${toLeft - toStraightWidth}px`,
            top: `${toCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn(
          'h-line-to absolute h-px',
          !props.editable ? 'bg-zinc-400' : props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand',
        )}
        style={{
          width: `${toStraightWidth - 1}px`,
          left: `${toLeft - toStraightWidth}px`,
          top: `${toCenter}px`,
        }}
      ></div>

      {props.hovering && props.default && (
        <div
          className='default pointer-events-none absolute -translate-y-1/2 z-20'
          style={{
            left: `${fromRight + fromStraightWidth / 2}px`,
            top: `${fromCenter}px`,
          }}
        >
          <DefaultBadge className='border border-[#C2B0F8]' />
        </div>
      )}

      {props.openMenu(
        <div
          className={cn('h-line-from absolute h-4 -translate-y-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${fromWidth}px`,
            left: `${fromRight}px`,
            top: `${fromCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('v-line absolute w-4 -translate-x-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            height: `${height}px`,
            left: `${fromRight + fromWidth}px`,
            top: `${Math.min(fromCenter, toCenter)}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('h-line-to absolute h-4 -translate-y-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${toWidth}px`,
            left: `${toLeft - toWidth}px`,
            top: `${toCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
    </>
  );
};

const Backwards = (props: {
  from: DOMRect;
  to: DOMRect;
  fromBox: DOMRect;
  toBox: DOMRect;
  sameBox: boolean;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  hovering: boolean;
  enabled: boolean;
  editable: boolean;
  error: boolean;
  default: boolean;
  onDelete?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openMenu: (children: ReactNode, onDelete?: () => void) => ReactNode;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop + 10;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromTop = props.from.top + topOffset;
  const fromLeft = props.from.left + leftOffset;
  const toTop = props.to.top + topOffset;
  const toRight = props.to.right + leftOffset;

  const fromCenter = fromTop + props.from.height / 2;
  const toCenter = toTop + props.to.height / 2;
  const fullWidth = props.from.left - props.to.right;
  const toWidth = props.sameBox ? fullWidth / 2 : props.toBox.right + (props.fromBox.left - props.toBox.right) / 2 - props.to.right;
  const fromWidth = fullWidth - toWidth;
  const height = Math.abs(fromCenter - toCenter);
  const cornerSize = Math.round(Math.min(12, height / 2));
  const straightHeight = height - cornerSize * 2;
  const fromStraightWidth = fromWidth - cornerSize;
  const toStraightWidth = toWidth - cornerSize;

  return (
    <>
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          icon={solid('circle')}
          className={cn(
            'absolute h-[9px] aspect-square z-10',
            !props.editable ? 'text-zinc-400' : props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
          )}
          style={{
            left: `${fromLeft - 4}px`,
            top: `${fromCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        icon={solid('circle')}
        className={cn(
          'absolute h-[5px] aspect-square z-10',
          props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand',
        )}
        style={{
          left: `${fromLeft - 2}px`,
          top: `${fromCenter - 2}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn(
            'h-[9px] aspect-square absolute z-10 -rotate-90',
            props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
          )}
          icon={solid('triangle')}
          style={{
            left: `${toRight - 3}px`,
            top: `${toCenter - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn(
          'h-[5px] aspect-square absolute z-10 -rotate-90',
          props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand',
        )}
        icon={solid('triangle')}
        style={{
          left: `${toRight - 1}px`,
          top: `${toCenter - 2}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <div
          className={cn(
            'corner-from absolute rounded-full border-[5px] rotate-45 aspect-square',
            props.error ? 'border-red-500/40' : props.default ? 'border-[#C2B0F8]/40' : 'border-brand/40',
            {
              'border-r-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
              'border-t-transparent border-r-transparent border-l-transparent': fromCenter > toCenter,
              invisible: cornerSize === 0,
            },
          )}
          style={{
            width: `${cornerSize * 2 + 4}px`,
            left: `${fromLeft - fromStraightWidth - cornerSize - 2}px`,
            top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1) - 2}px`,
          }}
        ></div>
      )}

      <div
        className={cn(
          'corner-from absolute rounded-full rotate-45 border aspect-square',
          props.error ? 'border-red-500' : props.default ? 'border-[#C2B0F8]' : 'border-brand',
          {
            'border-r-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-t-transparent border-r-transparent border-l-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${fromLeft - fromStraightWidth - cornerSize}px`,
          top: `${fromCenter + (fromCenter > toCenter ? -1 : 0) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn(
            'corner-to absolute rounded-full border-[5px] rotate-45 aspect-square',
            props.error ? 'border-red-500/40' : props.default ? 'border-[#C2B0F8]/40' : 'border-brand/40',
            {
              'border-l-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
              'border-l-transparent border-r-transparent border-b-transparent': fromCenter > toCenter,
              invisible: cornerSize === 0,
            },
          )}
          style={
            fromCenter > toCenter
              ? {
                  width: `${cornerSize * 2 + 6}px`,
                  left: `${toRight + toStraightWidth - cornerSize - 3}px`,
                  top: `${toCenter - 2}px`,
                }
              : {
                  width: `${cornerSize * 2 + 6}px`,
                  left: `${toRight + toStraightWidth - cornerSize - 3}px`,
                  top: `${toCenter - cornerSize * 2 - 3}px`,
                }
          }
        ></div>
      )}
      <div
        className={cn(
          'corner-to absolute rounded-full rotate-45 border aspect-square',
          props.error ? 'border-red-500' : props.default ? 'border-[#C2B0F8]' : 'border-brand',
          {
            'border-l-transparent border-t-transparent border-b-transparent': fromCenter < toCenter,
            'border-l-transparent border-r-transparent border-b-transparent': fromCenter > toCenter,
            invisible: cornerSize === 0,
          },
        )}
        style={{
          width: `${cornerSize * 2}px`,
          left: `${toRight + toStraightWidth - cornerSize + 1}px`,
          top: `${toCenter + (fromCenter > toCenter ? 0 : -1) * (cornerSize * 2 - 1)}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn('h-line-from absolute h-[5px]', props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40')}
          style={{
            width: `${fromStraightWidth + 1}px`,
            left: `${fromLeft - fromStraightWidth}px`,
            top: `${fromCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('h-line-from absolute h-px z-10', props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand')}
        style={{
          width: `${fromStraightWidth}px`,
          left: `${fromLeft - fromStraightWidth}px`,
          top: `${fromCenter}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn('v-line absolute w-[5px]', props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40', {
            invisible: cornerSize === 0,
          })}
          style={{
            height: `${straightHeight + 1}px`,
            left: `${toRight + toWidth - 2}px`,
            top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
          }}
        ></div>
      )}
      <div
        className={cn('v-line absolute w-px', props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand', {
          invisible: cornerSize === 0,
        })}
        style={{
          height: `${straightHeight + 1}px`,
          left: `${toRight + toWidth}px`,
          top: `${Math.min(fromCenter, toCenter) + cornerSize}px`,
        }}
      ></div>

      {props.hovering && props.enabled && (
        <div
          className={cn('h-line-to absolute h-[5px]', props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40')}
          style={{
            width: `${toStraightWidth - 1}px`,
            left: `${toRight + 1}px`,
            top: `${toCenter - 2}px`,
          }}
        ></div>
      )}
      <div
        className={cn('h-line-to absolute h-px', props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand')}
        style={{
          width: `${toStraightWidth + 1}px`,
          left: `${toRight}px`,
          top: `${toCenter}px`,
        }}
      ></div>

      {props.hovering && props.default && (
        <div
          className='default pointer-events-none absolute -translate-x-full -translate-y-1/2 z-20'
          style={{
            left: `${fromLeft - fromStraightWidth / 2}px`,
            top: `${fromCenter}px`,
          }}
        >
          <DefaultBadge className='border border-[#C2B0F8]' />
        </div>
      )}

      {props.openMenu(
        <div
          className={cn('h-line-from absolute h-4 -translate-y-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${fromWidth}px`,
            left: `${fromLeft - fromWidth}px`,
            top: `${fromCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('v-line absolute w-4 -translate-x-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            height: `${height}px`,
            left: `${toRight + toWidth}px`,
            top: `${Math.min(fromCenter, toCenter)}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
      {props.openMenu(
        <div
          className={cn('h-line-to absolute h-4 -translate-y-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            width: `${toWidth}px`,
            left: `${toRight}px`,
            top: `${toCenter}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
    </>
  );
};

const Vertical = (props: {
  from: DOMRect;
  to: DOMRect;
  scrollableRef: HTMLDivElement;
  scrollable: DOMRect;
  hovering: boolean;
  enabled: boolean;
  editable: boolean;
  error: boolean;
  default: boolean;
  onDelete?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  openMenu: (children: ReactNode, onDelete?: () => void) => ReactNode;
}) => {
  const topOffset = -props.scrollable.top + props.scrollableRef.scrollTop;
  const leftOffset = -props.scrollable.left + props.scrollableRef.scrollLeft;
  const fromBottom = props.from.bottom + topOffset;
  const toBottom = props.to.bottom + topOffset;
  const fromTop = props.from.top + topOffset;
  const toTop = props.to.top + topOffset;

  const topToBottom = toTop > fromTop;
  const center = props.from.left + props.from.width / 2 + leftOffset;
  const height = (topToBottom ? toTop - fromBottom : fromTop - toBottom) - 1;
  const top = topToBottom ? fromBottom : toBottom;

  return (
    <>
      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          icon={solid('circle')}
          className={cn(
            'absolute h-[9px] aspect-square',
            props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
          )}
          style={{
            left: `${center - 3}px`,
            top: `${(topToBottom ? fromBottom : fromTop) - 5}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        icon={solid('circle')}
        className={cn('absolute h-[5px] aspect-square', props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand')}
        style={{
          left: `${center - 1}px`,
          top: `${(topToBottom ? fromBottom : fromTop) - 3}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn(
            'h-[9px] aspect-square absolute rotate-180',
            props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
            {
              invisible: !topToBottom,
            },
          )}
          icon={solid('triangle')}
          style={{
            left: `${center - 3}px`,
            top: `${toTop - 6}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn(
          'h-[5px] aspect-square absolute z-10 rotate-180',
          props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand',
          {
            invisible: !topToBottom,
          },
        )}
        icon={solid('triangle')}
        style={{
          left: `${center - 1}px`,
          top: `${toTop - 5}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <FontAwesomeIcon
          className={cn(
            'h-[9px] aspect-square absolute',
            props.error ? 'text-red-500/40' : props.default ? 'text-[#C2B0F8]/40' : 'text-brand/40',
            {
              invisible: topToBottom,
            },
          )}
          icon={solid('triangle')}
          style={{
            left: `${center - 3}px`,
            top: `${toBottom - 4}px`,
          }}
        />
      )}
      <FontAwesomeIcon
        className={cn(
          'h-[5px] aspect-square absolute z-10',
          props.error ? 'text-red-500' : props.default ? 'text-[#C2B0F8]' : 'text-brand',
          {
            invisible: topToBottom,
          },
        )}
        icon={solid('triangle')}
        style={{
          left: `${center - 1}px`,
          top: `${toBottom - 2}px`,
        }}
      />

      {props.hovering && props.enabled && (
        <div
          className={cn('v-line absolute w-[5px]', props.error ? 'bg-red-500/40' : props.default ? 'bg-[#C2B0F8]/40' : 'bg-brand/40')}
          style={{
            height: `${height - 6}px`,
            left: `${center - 1}px`,
            top: `${top + 3}px`,
          }}
        ></div>
      )}
      <div
        className={cn('v-line absolute w-px', props.error ? 'bg-red-500' : props.default ? 'bg-[#C2B0F8]' : 'bg-brand')}
        style={{
          height: `${height - 2}px`,
          left: `${center + 1}px`,
          top: `${top}px`,
        }}
      ></div>

      {props.hovering && props.default && (
        <div
          className='default pointer-events-none absolute -translate-x-1/2 -translate-y-1/2 z-20'
          style={{
            left: `${center + 1}px`,
            top: `${top + height / 2}px`,
          }}
        >
          <DefaultBadge className='border border-[#C2B0F8]' />
        </div>
      )}

      {props.openMenu(
        <div
          className={cn('v-line absolute w-4 -translate-x-1/2', { 'z-20': props.hovering, 'cursor-pointer': props.enabled })}
          style={{
            height: `${height - 2}px`,
            left: `${center + 1}px`,
            top: `${top + 1}px`,
          }}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        ></div>,
        props.onDelete,
      )}
    </>
  );
};
