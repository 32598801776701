import { Methodology, StaticEntity, request } from '.';

export enum BillingModeType {
  Free = 'free',
  Standalone = 'standalone',
  Volume = 'volume',
}

export interface Customer {
  customerSid: string;
  companyName: string;
  logoUrl?: string;
  methodology: StaticEntity<Methodology>;
}

export interface Workspace {
  customerSid: string;
  workspaceSid: string;
  name: string;
  integrationName?: string;
  logoUrl?: string;
  brand: StaticEntity;
  methodology: StaticEntity<Methodology>;
  limits: {
    products: number;
    modelsPerProduct: number;
    manufacturingReports: number;
  };
  permissions: {
    productManagement: boolean;
  };
  targetSkuCount: number;
  trialUntil?: string;
  billingMode?: StaticEntity<BillingModeType>;
}

export interface User {
  customerSid: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  company: string;
  job: string;
  internalUser: boolean;
}

export const getCustomers = () => request<Customer[]>('GET', '/v3/customers');
export const createCustomer = (payload: Customer) => request<Customer>('POST', '/v3/customers', { body: payload });

export const getWorkspaces = (customerSid: string) => request<Workspace[]>('GET', `/v3/workspaces?customerSid=${customerSid}`);
export const createWorkspace = (payload: Workspace) => request<Workspace>('POST', '/v3/workspaces', { body: payload });

export const getUsers = (customerSid: string) => request<User[]>('GET', `/v3/users?customerSid=${customerSid}`);
export const createUser = (payload: User) => request<User & { errorCode?: string }>('POST', '/v3/users', { body: payload });
