import cn from 'classnames';
import { ReactNode } from 'react';
import { ReadOnlyWarning } from '../../../components/ReadOnlyWarning';

interface Props {
  primary?: {
    label: string;
    waiting?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    readOnlyWarning?: boolean;
  };
  secondary?: {
    label: string | ReactNode;
    waiting?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    readOnlyWarning?: boolean;
  };
}
export const Buttons = (props: Props) => {
  return (
    <>
      <div className='flex gap-4 mt-3'>
        {props.primary && (
          <ReadOnlyWarning show={props.primary.readOnlyWarning === true}>
            <button
              disabled={props.primary.waiting || props.primary.disabled}
              onClick={props.primary.onClick}
              className={cn(
                'rounded-lg px-3 py-1 font-semibold bg-brand border border-brand text-white',
                props.primary.waiting
                  ? 'disabled:cursor-wait'
                  : 'disabled:cursor-not-allowed disabled:bg-neutral-300 disabled:border-transparent',
              )}
            >
              {props.primary.label}
            </button>
          </ReadOnlyWarning>
        )}
        {props.secondary && (
          <ReadOnlyWarning show={props.secondary.readOnlyWarning === true}>
            <button
              disabled={props.secondary.waiting || props.secondary.disabled}
              onClick={props.secondary.onClick}
              className={cn(
                'rounded-lg px-3 py-1 font-semibold border border-[#4a2e82] text-[#4a2e82]',
                props.secondary.waiting
                  ? 'disabled:cursor-wait'
                  : 'disabled:cursor-not-allowed disabled:text-neutral-400 disabled:border-neutral-400',
              )}
            >
              {props.secondary.label}
            </button>
          </ReadOnlyWarning>
        )}
      </div>
    </>
  );
};
