import { Appendix } from '../../../components/Appendix';
import { BaselinePefReport, ReportType } from '../../../../../../api';

interface Props {
  data: BaselinePefReport;
}

export const BaselineAppendix = (props: Props) => {
  return <Appendix data={props.data} reportType={ReportType.Baseline} />;
};
