export const lifeCycleStagesColorHexSchema: { [key: string]: string } = {
  'Raw Materials': '#5BB7D0',
  Production: '#31A383',
  Distribution: '#96BF73',
  Use: '#8F7DB3',
  'End Of Life': '#9BB8DF',
};

export const lifeCycleStagesColorRgbaSchema = (opacity: number): any => {
  return {
    'Raw Materials': `rgba(91, 183, 208, ${opacity})`,
    Production: `rgba(49, 163, 131, ${opacity})`,
    Distribution: `rgba(150, 191, 115, ${opacity})`,
    Use: `rgba(143, 125, 179, ${opacity})`,
    'End Of Life': `rgba(155, 184, 223, ${opacity})`,
  };
};

export const setColor = (item: { key: string }, template: { [key: string]: string }) => template[item.key];

export const alignTickPosition = ({
  x,
  y,
  textAnchor,
  coordinate,
}: {
  x: number;
  y: number;
  textAnchor: 'start' | 'end' | 'middle';
  coordinate: number;
}) => {
  switch (textAnchor) {
    case 'start':
      return { x: x + 10, y: y + 10 };
    case 'end':
      return { x: x - 10, y: y + 10 };
    case 'middle':
      return { x, y: y + (coordinate < 0 ? 15 : -15) };
    default:
      break;
  }
};
