import { Details as ProductionDetails } from '../components/Details';
import { Lens, ProductReport } from '../../../../../../api';

interface Props {
  data: ProductReport;
}

export const Details = (props: Props) => {
  return <ProductionDetails lens={Lens.Production} data={props.data} />;
};
