import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  ForecastReport,
  HistoricalPefReport,
  ReportType,
  ReportsListPagination,
  deleteManufacturingForecast,
  deleteManufacturingHistoricalReport,
  ManufacturingPefReport,
} from '../../../../../api';
import InfiniteScroll from '../../../../../components/InfiniteScroll';
import { LimitTooltip } from '../../../../../components/LimitTooltip';
import { ModalV3 } from '../../../../../components/ModalV3';
import { createProduct } from '../../../Products/Details/dataModel';
import { useProfile } from '../../../../../hooks/useProfile';
import { simplify, roundToLong } from '../../../shared';
import { ReadOnlyWarning } from '../../../../../components/ReadOnlyWarning';
import { useDynamicHeight } from '../../../../../hooks/useDynamicHeight';

interface Props {
  onNext: () => void;
  reload: () => void;
  reportType: ReportType.Historical | ReportType.Forecast;
  data: ReportsListPagination<(ManufacturingPefReport & { name: string })[]>;
  pathToReport: (id: string) => string;
}

const gridSchema = (reportType: ReportType) =>
  reportType === ReportType.Forecast ? 'grid-cols-[4fr_2fr_2fr_1fr_2fr_3fr_1fr_1fr]' : 'grid-cols-[4fr_2fr_2fr_2fr_3fr_1fr_1fr]';

export const Reports = (props: Props) => {
  const navigate = useNavigate();
  const profile = useProfile();
  const [createLimit, setCreateLimit] = useState(false);
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const height = useDynamicHeight(containerRef, undefined, false);

  const createNewProduct = () =>
    createProduct().call({
      ok: ({ id, errorCode }) => {
        if (errorCode) {
          setCreateLimit(true);
        } else {
          navigate(`/products/${id}`);
        }
      },
    });

  return (
    <div className='relative text-[90%]'>
      {(() => {
        if (!props.data.hasCompleteProducts && props.data.reports.length === 0) {
          return (
            <div className='flex flex-col gap-y-3 items-center shadow-md rounded-2xl p-4 mt-5 mx-[15%]'>
              <div className='flex items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                <FontAwesomeIcon className='h-5 aspect-square' icon={light('lightbulb')} />
              </div>
              <div className='text-lg font-semibold text-center'>
                You need to create some products before uploading your first set of historical data
              </div>
              <div className='text-center'>
                Here is where all historical volume data will be stored. You’ll be able to access and delete them. In order to use this
                functionality you need to first create a few products.
              </div>
              <LimitTooltip
                placement='top-start'
                enabled={createLimit}
                entityName='products limit'
                valueKey='maxProductSkuCount'
                onDismiss={() => setCreateLimit(false)}
              >
                <div>
                  <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                    <button
                      onClick={createNewProduct}
                      disabled={!profile.selectedWorkspace.permissions.productManagement}
                      className='flex self-center shadow-regular [&:active:not(:disabled)]:scale-95 bg-brand text-white font-semibold px-3.5 py-1.5 rounded-full disabled:bg-neutral-300 disabled:cursor-not-allowed'
                    >
                      Create new product
                    </button>
                  </ReadOnlyWarning>
                </div>
              </LimitTooltip>
            </div>
          );
        } else {
          return props.data.reports.length === 0 ? (
            <div className='flex flex-col gap-y-3 items-center border shadow-md rounded-2xl p-4 mt-5 mx-[15%] text-base'>
              <div className='flex items-center justify-center h-10 aspect-square rounded-full bg-brandLime'>
                <FontAwesomeIcon className='h-5 aspect-square' icon={light('lightbulb')} />
              </div>
              <div className='text-lg font-semibold text-center'>
                {props.reportType === ReportType.Historical && 'Upload your first set of data.'}
                {props.reportType === ReportType.Forecast && 'Create your first forecast.'}
              </div>
              <div className='text-center px-24'>
                {props.reportType === ReportType.Historical &&
                  'Once you upload your first set of historical data, you’ll be able to see and manage the various impact reports in this table.'}
                {props.reportType === ReportType.Forecast &&
                  'Once you create your first volume forecasts, this is where you’ll be able to manage them.'}
              </div>
            </div>
          ) : (
            <div ref={setContainerRef}>
              <InfiniteScroll
                height={height}
                dataLength={props.data.reports.length}
                next={props.onNext}
                hasMore={props.data.nextPageToken !== ''}
                loader={<></>}
              >
                <div
                  className={cn(
                    'grid sticky top-0 border-y items-center gap-x-2 bg-white font-semibold h-12',
                    gridSchema(props.reportType),
                  )}
                >
                  <div className='pl-6'>Report Name</div>
                  <div>Creation Date</div>
                  {props.reportType === ReportType.Forecast && <div>Cycle Length</div>}
                  {props.reportType === ReportType.Historical && <div>Date range</div>}
                  {props.reportType === 'forecast' && <div>Products</div>}
                  <div>Total Units</div>
                  <div>Total Impact</div>
                  <div className='text-center'>
                    <FontAwesomeIcon icon={light('file-chart-column')} />
                  </div>
                  <div />
                </div>
                <div className='divide-y'>
                  {props.data.reports.map((report, i) => (
                    <Row
                      key={i}
                      index={i}
                      item={report}
                      onDeleted={props.reload}
                      reportType={props.reportType}
                      pathToReport={props.pathToReport}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          );
        }
      })()}
    </div>
  );
};

interface RowProps {
  item: ManufacturingPefReport & { name: string };
  index: number;
  onDeleted: () => void;
  reportType: ReportType;
  selectedReportId?: string;
  setSelectedReportId?: (v: SetStateAction<string | undefined>) => void;
  pathToReport: (id: string) => string;
}

const Row = (props: RowProps) => {
  const { item } = props;
  const [deleting, setDeleting] = useState(false);

  return (
    <div
      className={cn('grid py-3 items-center gap-x-2', gridSchema(props.reportType), props.index % 2 === 0 ? 'bg-neutral-50' : 'bg-white')}
    >
      <div className='pl-6'>{item.name}</div>
      <div>{format(parseISO(item.createdAt), 'dd/MM/yyyy')}</div>
      {props.reportType === ReportType.Forecast && (item as ForecastReport).cycle && (
        <div>{capitalize((item as ForecastReport).cycle)}</div>
      )}
      {props.reportType === ReportType.Historical && (item as HistoricalPefReport).startDate && (item as HistoricalPefReport).endDate && (
        <div>
          {format(new Date((item as HistoricalPefReport).startDate), 'dd/MM/yy')}
          <span>-</span>
          {format(new Date((item as HistoricalPefReport).endDate), 'dd/MM/yy')}
        </div>
      )}
      {props.reportType === 'forecast' && <div>{item.productCount}</div>}
      <div>{item.totalUnitCount}</div>
      <div>
        <div title={roundToLong(item.impactPoints)}>{simplify(item.impactPoints)} Impact points</div>
      </div>
      <NavLink to={props.pathToReport(item.id)} className='text-center text-brandDark font-semibold hover:underline active:scale-95'>
        View
      </NavLink>
      <div className='mx-auto'>
        <ModalV3
          title={`Delete ${item.name}?`}
          body={<div className='text-base -mt-6'>You will not be able to recover it.</div>}
          waiting={deleting}
          onConfirm={() => {
            setDeleting(true);
            props.reportType === 'forecast'
              ? deleteManufacturingForecast(item.id).call({
                  ok: () => {
                    setDeleting(false);
                    props.onDeleted();
                  },
                  fail: () => {
                    setDeleting(false);
                  },
                })
              : deleteManufacturingHistoricalReport(item.id).call({
                  ok: () => {
                    props.onDeleted();
                    setDeleting(false);
                  },
                  fail: () => {
                    setDeleting(false);
                  },
                });
          }}
        >
          <button type='button' className='flex self-center hover:text-brandDark hover:scale-110'>
            <FontAwesomeIcon icon={light('trash-can')} />
          </button>
        </ModalV3>
      </div>
    </div>
  );
};
