import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { FieldProps } from 'formik';
import { RefObject, useEffect, useRef, useState } from 'react';
import ReactSelect, { OptionProps, components } from 'react-select';
import { v4 as uuid } from 'uuid';
import { Country, CountryBadge, Entity, searchCountries } from '../../../../api';
import { Components, SelectV3 } from '../../../../components/SelectV3';
import { TooltipV3 } from '../../../../components/TooltipV3';
import { useControlEvents } from '../../../../hooks/useControlEvents';
import { useLists } from '../../../../hooks/useLists';
import { TaggableField } from './TaggableFields';

interface Props {
  name: string;
  ingredient?: Entity;
  includeOption?: (option: Entity) => boolean;
  formRef: RefObject<HTMLElement>;
  disabled?: boolean;
}

export const LocationSelect = (props: Props) => (
  <TaggableField name={props.name} card>
    {(model: FieldProps<Entity>) => <Content model={model} {...props} />}
  </TaggableField>
);

const Content = (props: Props & { model: FieldProps<Entity> }) => {
  const lists = useLists();
  const controlEvents = useControlEvents();
  const checkboxId = useRef(uuid()).current;
  const [region, setRegion] = useState(lists.regions.some(({ id }) => id === props.model.field.value?.id));

  return (
    <div className='flex flex-col gap-1'>
      <div className='pl-1.5'>Country</div>
      <div>
        <CountrySelect disabled={props.disabled} region={region} {...props} />
      </div>
      <div className='pl-1.5 flex items-center gap-2 mt-1'>
        <input
          disabled={props.disabled}
          id={checkboxId}
          type='checkbox'
          checked={region}
          onChange={() => {
            if (controlEvents) {
              controlEvents.touched(props.model);
            }

            props.model.form.setFieldValue(props.name, null);
            setRegion(!region);
          }}
        />
        <label htmlFor={checkboxId} className='select-none'>
          I don't know the country
        </label>
      </div>
      {region && (
        <>
          <div className='pl-1.5 mt-2'>Region</div>
          <SelectV3
            model={region ? props.model : undefined}
            menuPortalTarget={props.formRef.current}
            options={lists.regions.filter(props.includeOption ?? (() => true))}
          />
        </>
      )}
    </div>
  );
};

const CountrySelect = (
  props: Props & {
    model: FieldProps<Entity>;
    region: boolean;
  },
) => {
  const controlEvents = useControlEvents();
  const [list, setList] = useState<Country[]>([]);
  const options = list.filter(props.includeOption ?? (() => true));
  const specificDataOptions = options.filter(({ badge }) => badge === CountryBadge.SpecificData);

  useEffect(() => {
    setList([]);

    if (!props.region) {
      searchCountries(props.model.field.value, props.ingredient).ok(({ countries }) => setList(countries));
    }

    return () => {
      setList([]);
    };
  }, [props.region, props.ingredient, props.model.field.value]);

  return (
    <ReactSelect
      isDisabled={props.region || props.disabled}
      placeholder={props.region ? 'Select a region below…' : 'Choose…'}
      noOptionsMessage={() => (list.length === 0 ? 'Loading countries…' : 'No matches found…')}
      getOptionValue={({ id }) => id}
      getOptionLabel={({ name }) => name}
      value={props.region || props.model.field.value === null ? ('' as any) : props.model.field.value}
      options={
        specificDataOptions.length > 0
          ? [
              {
                label: 'Specific Data Available',
                options: specificDataOptions,
              },
              {
                label: 'Other Countries',
                options: options.filter((option) => !specificDataOptions.some(({ id }) => id === option.id)),
              },
            ]
          : options
      }
      onChange={(value) => {
        if (controlEvents) {
          controlEvents.touched(props.model);
        }

        props.model.form.setFieldValue(props.model.field.name, value);
      }}
      onMenuOpen={() => {
        if (controlEvents) {
          controlEvents.touched(props.model);
        }
      }}
      menuPortalTarget={props.formRef.current}
      formatGroupLabel={(data) => (
        <div className='flex items-center gap-1.5'>
          <div>{data.label}</div>
          {data.options === specificDataOptions && (
            <TooltipV3
              disabled={data.options !== specificDataOptions}
              content={
                <div
                  className='text-sm rounded-lg text-zinc-800 bg-[#E8EAF5] w-11/12 px-4 py-2.5 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)]'
                  style={{ textTransform: 'none' }}
                >
                  Impact changes are more accurate for countries where specific data is available.
                </div>
              }
            >
              <span>
                <FontAwesomeIcon icon={regular('info-circle')} />
              </span>
            </TooltipV3>
          )}
        </div>
      )}
      components={{
        ...Components,
        Option: CountrySelectOption,
      }}
      {...{ model: props.model }}
    />
  );
};

const CountrySelectOption = (props: OptionProps<Country>) => (
  <components.Option
    {...props}
    getStyles={() => ({
      ...props.getStyles('option', props),
      padding: '',
      cursor: 'pointer',
      color: '',
      backgroundColor: '',
      ':active': {},
    })}
    className={cn(
      'px-2 py-1',
      (() => {
        if (props.isSelected) {
          if (props.isFocused) {
            return 'bg-brandDark text-white';
          }

          return 'bg-brand text-white';
        }

        if (props.isFocused) {
          return 'bg-neutral-100';
        }

        return '';
      })(),
    )}
  >
    <div
      className={cn('flex items-center justify-between gap-4 px-2 py-1 rounded-xl', {
        'bg-amber-50': !props.isSelected && !props.isFocused && props.data.badge === CountryBadge.SpecificData,
      })}
    >
      <div>{props.children}</div>
      {props.data.id !== (props.selectProps.value as Country)?.id && props.data.badge === CountryBadge.SameImpact && (
        <TooltipV3
          content={
            <div className='rounded-lg text-zinc-800 bg-[#E8EAF5] w-11/12 px-4 py-2.5 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)]'>
              Switching to a country in the same world region as your original input - marked with the equal icon - will not have any
              impact.
            </div>
          }
        >
          <div className='flex items-center justify-center h-4 aspect-square rounded-full bg-zinc-300 text-white'>
            <FontAwesomeIcon size='sm' icon={solid('equals')} />
          </div>
        </TooltipV3>
      )}
    </div>
  </components.Option>
);
