import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

interface Props {
  start: Date;
  end: Date;
}

export const TimeframeDisplay = (props: Props) => {
  const formatTimeframe = (start: Date, end: Date, options?: { compact: boolean }) => {
    return `${format(start, `MMM dd${start.getFullYear() === end.getFullYear() ? '' : options?.compact ? ', yy' : ', yyyy'}`)}${
      options?.compact ? '-' : ' — '
    }${format(end, `MMM dd, ${start.getFullYear() === end.getFullYear() ? 'yyyy' : options?.compact ? 'yy' : 'yyyy'}`)}`;
  };
  return (
    <div className='flex items-center gap-2 px-2 py-0.5 -my-0.5 border border-slate-200 bg-slate-100 rounded'>
      <FontAwesomeIcon icon={light('calendar')} className='text-brand' />
      <div className='text-zinc-700'>{formatTimeframe(props.start, props.end)}</div>
    </div>
  );
};
