import { useState } from 'react';
import { ModellingReport } from '../../../../../api';
import { HeatMapTableByImpact } from '../../../../../components/HeatMapTableByImpact';
import { ImpactSelect } from '../../../../../components/ImpactSelect';
import { CollapsibleSection } from '../CollapsibleSection';
import { ImpactValueType } from '../Sku/Overview';
import { NavigationButtons } from '../Sku/components/NavigationButtons';
import { ModellingStackedBarChart } from './components/ModellingStackedBarChart';
import { LifeCycleStagesLegend } from '../../../../../components/charts/LifeCycleStagesLegend';
import {
  convertFromImpactStagesMatrix,
  convertFromLifecycleStageImpacts,
  SunburstChart,
} from '../../../../../components/charts/SunburstChart/SunburstChart';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

interface Props {
  data: ModellingReport;
}

export const Appendix = (props: Props) => {
  const overallImpact = { id: 'overall_impact', name: 'Total environmental impact' };
  const [selectedImpact, setSelectedImpact] = useState<{ id: string; name: string }>(overallImpact);
  const [selectedImpactValueType, setSelectedImpactValueType] = useState<ImpactValueType>(ImpactValueType.Points);
  const [selectedEntity, setSelectedEntity] = useState<'original' | 'modelled'>('original');

  const allImpactsCategories = [
    overallImpact,
    ...props.data.calculations.product.impacts.map((impact) => ({ id: impact.id, name: impact.name, unit: impact.unit })),
  ];

  return (
    <div>
      <div className='flex flex-col gap-8 px-6 print:text-sm w-full'>
        <div className='flex justify-between gap-8'>
          <div className='text-zinc-500 ml-3 flex flex-col gap-6'>
            <div>
              This section of the report contains a number of Appendices with additional information on your impact change results, our
              methodology and more. For ease of navigation and reference, these have been broken down into separate sections.
            </div>
            <div>
              If you are left with some questions pertaining to your product’s environmental impact after reading this report, please{' '}
              <a href="mailto:impact@sustained.com?subject=Trial has ended - What's next?" className='font-semibold underline'>
                contact us
              </a>{' '}
              and we’ll be happy to help you in any way we can!
            </div>
          </div>
          <NavigationButtons type='icons' back={{ path: '../consumer-view' }} />
        </div>

        <CollapsibleSection title='Appendix 1 - Detailed breakdown by impact category'>
          <div className='flex flex-col gap-6'>
            <div className='text-zinc-500'>
              A closer look at each of the 16 impact categories and how their absolute impacts are broken down by life cycle stage as well
              as a deep dive into each life cycle stage and the contribution of underlying processes.
            </div>
            <div className='text-zinc-500'>
              On the left hand side, the Original product values are represented, the absolute impact by impact category as a bar chart, and
              the breakdown by life cycle stage showing the contribution of the underlying processes to the absolute impact as an
              interactive sunburst chart. On the right hand side, the Modelled results are displayed. Toggle between impact categories to
              see the difference or click on various portions of the sunburst charts to better explore a branch of the tree. A table
              summarizing those numerical results is also included.
            </div>
            <div className='border shadow-regular rounded-regular pb-6 print:break-inside-avoid-page'>
              <div className='text-lg font-semibold border-b border-zinc-200 py-3 text-center mb-6'>
                Contribution per life cycle stage and process
              </div>
              <ImpactSelect
                theme='dark'
                impacts={allImpactsCategories}
                selectedImpact={selectedImpact}
                setSelectedImpact={setSelectedImpact}
                selectedImpactValueType={selectedImpactValueType}
                setSelectedImpactValueType={setSelectedImpactValueType}
              />

              <div className='mx-auto w-3/4 print:scale-75 mt-6'>
                <ModellingStackedBarChart
                  data={props.data.calculations}
                  selectedImpact={selectedImpact}
                  selectedImpactValueType={selectedImpactValueType}
                />
              </div>

              <div className='flex justify-center mt-6'>
                <div className='grid grid-cols-2 w-[70%] h-[300px] print:w-full print:scale-75'>
                  {selectedImpact.id === overallImpact.id && (
                    <>
                      <SunburstChart
                        size={300}
                        data={convertFromLifecycleStageImpacts(props.data.calculations.product.analysis.lifecycleStageImpacts)}
                      />
                      <SunburstChart
                        size={300}
                        data={convertFromLifecycleStageImpacts(props.data.calculations.model.analysis.lifecycleStageImpacts)}
                      />
                    </>
                  )}

                  {selectedImpact.id !== overallImpact.id && (
                    <>
                      <SunburstChart
                        size={300}
                        data={convertFromImpactStagesMatrix(
                          props.data.calculations.product.analysis.impactStagesMatrix.find(
                            ({ impactName }) => impactName === selectedImpact.name,
                          )!.stages,
                        )}
                        selectedCategory={props.data.calculations.product.analysis.impactStagesMatrix.find(
                          ({ impactId }) => selectedImpact.id === impactId,
                        )}
                        showPhysicalImpact={selectedImpactValueType === ImpactValueType.Physical}
                        selectedCategoryPhysicalUnit={
                          props.data.calculations.product.analysis.impactStagesMatrix.find(
                            ({ impactId }) => selectedImpact.id === impactId,
                          )!.unit
                        }
                      />
                      <SunburstChart
                        size={300}
                        data={convertFromImpactStagesMatrix(
                          props.data.calculations.model.analysis.impactStagesMatrix.find(
                            ({ impactName }) => impactName === selectedImpact.name,
                          )!.stages,
                        )}
                        selectedCategory={props.data.calculations.model.analysis.impactStagesMatrix.find(
                          ({ impactId }) => selectedImpact.id === impactId,
                        )}
                        showPhysicalImpact={selectedImpactValueType === ImpactValueType.Physical}
                        selectedCategoryPhysicalUnit={
                          props.data.calculations.model.analysis.impactStagesMatrix.find(({ impactId }) => selectedImpact.id === impactId)!
                            .unit
                        }
                      />
                    </>
                  )}
                </div>
              </div>

              <div className='flex justify-center mt-8'>
                <LifeCycleStagesLegend renderMajor />
              </div>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='text-zinc-500'>
                The table below shows the detailed breakdown by life cycle stage and underlying processes of each impact category. This is
                done using physical values of each impact categories to understand the absolute impact.
              </div>

              <div className='text-zinc-500'>
                Each row is colour coded independently from one another, showing for each impact category and process, the absolute impacts
                at the life cycle stage level, colour coded according to its relative importance: a red to green colour scale, from highest
                to lowest impact.
              </div>

              <div className='text-zinc-500'>
                This heat map is included to provide visual cues as to which life cycle stages or processes are the most concerning for each
                impact category, to identify general trends and later help you improve your product.
              </div>
            </div>

            <div className='grid grid-cols-2 gap-x-3 bg-slate-100 rounded-xl border p-0.5'>
              {[
                {
                  id: 'original',
                  label: 'Original',
                  isActive: selectedEntity === 'original',
                  onClick: () => setSelectedEntity('original'),
                },
                {
                  id: 'modelled',
                  label: 'Modelled',
                  isActive: selectedEntity === 'modelled',
                  onClick: () => setSelectedEntity('modelled'),
                },
              ].map(({ id, label, isActive, onClick }) => (
                <button
                  key={id}
                  onClick={onClick}
                  className={cn('flex gap-3 items-center py-2 px-4 rounded-2xl text-xl', {
                    'bg-white border': isActive,
                    'text-zinc-500': !isActive,
                  })}
                >
                  <FontAwesomeIcon
                    className={cn('w-5 aspect-square p-2 rounded-full', isActive ? 'rotate-90 bg-slate-50 text-brand' : 'bg-white')}
                    icon={light('arrow-right')}
                  />
                  {label}
                </button>
              ))}
            </div>

            <div className='border shadow-regular rounded-regular pb-6'>
              {selectedEntity === 'original' && <HeatMapTableByImpact data={props.data.calculations.product.analysis.impactStagesMatrix} />}
              {selectedEntity === 'modelled' && <HeatMapTableByImpact data={props.data.calculations.model.analysis.impactStagesMatrix} />}
            </div>
          </div>
        </CollapsibleSection>

        <CollapsibleSection title='Appendix 2 - Assumptions behind the contextual examples'>
          <div className='flex flex-col gap-6 text-zinc-500'>
            <div className='flex flex-col gap-3'>
              <div>
                A life cycle assessment is a scientific method after all. As such, the outputs are not always straightforward and easy to
                contextualise for the average person.
              </div>
              <div>
                To help <span className='text-violet-800'>you and your consumers</span> better understand the extent of the physical impacts
                we’re talking about, we have come up with a number of ‘contextual examples’ for 6 of the 16 impact categories recommended by
                PEF. Those six are the ones that can be most easily associated with something out of our everyday lives and are also
                considered to be the most important under the PEF framework (ie. highest weighting factors).
              </div>
              <div>For each impact category, a number of examples were derived:</div>
            </div>
            <div>
              <div className='text-violet-800 text-lg font-semibold'>Climate Change</div>
              <div className='font-semibold mt-3'>
                CO2 values from a number of activities and processes, such a travelling, trees absorption rate and industrial production,
                are used as reference points when comparing the physical impact values of your product(s) for the climate change category.
              </div>
            </div>
            <ul className='list-disc ml-6'>
              <li>
                <div className='font-semibold'>CO2 emitted for a specific car journey</div>
                <div className='mt-1.5'>The number of car journeys is calculated using the following formula:</div>
                <div className='text-violet-800 mt-1.5'>
                  kg CO2e per car journey = ( Distance / average car efficiency ) * CO2 emissions per litre of fuel
                </div>
                <div className='mt-1.5'>Where,</div>
                <ul className='list-disc ml-6 mt-1.5'>
                  <li>
                    <div>Distance = one of the reference trip distances from the following list, calculated assuming a straight line.</div>
                    <ul className='list-disc ml-6'>
                      <li>London - Luton</li>
                      <li>Manchester - Liverpool</li>
                      <li>London - Reading</li>
                      <li>Leeds - Liverpool</li>
                      <li>London - Leicester</li>
                      <li>London - Manchester</li>
                      <li>London - Edinburgh</li>
                      <li>Aberdeen - London</li>
                    </ul>
                  </li>
                  <li>
                    Average Car efficiency = 8.69 miles per litre of fuel, extrapolated from an average 39.5 miles per gallon for diesel and
                    petrol car (
                    <a
                      className='underline'
                      rel='noreferrer'
                      target='_blank'
                      href='https://www.nimblefins.co.uk/cheap-car-insurance/average-mpg'
                    >
                      source
                    </a>
                    ), and the fact that 1 gallon = 4.546 litres.
                  </li>
                  <li>
                    CO2 emissions per litre of fuel = 2.12 kg CO2 (
                    <a
                      className='underline'
                      rel='noreferrer'
                      target='_blank'
                      href='https://www.gov.uk/government/collections/calorific-values'
                    >
                      source
                    </a>
                    )
                  </li>
                </ul>
              </li>
              <li className='mt-6'>
                <div className='font-semibold mt-1.5'>CO2 emitted for a specific plane journey</div>
                <div className='mt-1.5'>The number of flights is calculated using the following formula:</div>
                <div className='mt-1.5 text-violet-800'>
                  kg CO2e per flight = Distance * Average fuel economy * CO2 emissions per kg of fuel
                </div>
                <div className='mt-1.5'>Where,</div>
                <ul className='list-disc ml-6 mt-1.5'>
                  <li>
                    <div>Distance = one of the reference trip distances from the following list, calculated assuming a straight line.</div>
                    <ul className='list-disc ml-6'>
                      <li>Leeds - Liverpool</li>
                      <li>London - Leicester</li>
                      <li>Birmingham - Swansea</li>
                      <li>London - Manchester</li>
                      <li>London - Edinburgh</li>
                      <li>Aberdeen - London</li>
                    </ul>
                  </li>
                  <li>
                    Average fuel economy = 3.13 kg/km (
                    <a
                      className='underline'
                      rel='noreferrer'
                      target='_blank'
                      href='https://en.wikipedia.org/wiki/Fuel_economy_in_aircraft#Short-haul_flights'
                    >
                      source
                    </a>
                    )
                  </li>
                  <li>
                    CO2 emissions per kg of fuel = 3.15 kg CO2 (
                    <a className='underline' rel='noreferrer' target='_blank' href='https://www.carbonindependent.org/files/B851vs2.4.pdf'>
                      source
                    </a>
                    )
                  </li>
                </ul>
              </li>
              <li className='mt-6'>
                <div className='font-semibold'>CO2 emitted when producing a certain number of kilograms of cement</div>
                <div className='mt-1.5'>
                  The CO2 production rate per 1kg of cement produced, of 0.622 kg CO2e/kg (source), is used to derive the number of
                  kilograms of cement that would need to be produced to emit as much CO2 as your product(s).
                </div>
              </li>
            </ul>

            <div className='flex flex-col gap-3'>
              <div className='text-violet-800 text-lg font-semibold'>Land use</div>
              <div className='font-semibold'>
                The land use impact is compared to the impact of occupying a certain size of land for a year for urban purposes. To do so,
                the ‘points’ values are divided by a factor of 139.88, representative of the impact of occupying land for urban purposes
                (which is different than occupying it for other types of uses ie. industrial). As a result, the actual area of land that
                would need to be occupied for a year to cause said impact is obtained. This m2 value is finally compared to a set of known
                sizes of land for the land use category. How many of these fixed areas is presented as a contextual example.
              </div>
              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>M2 from a certain number of the following land uses:</div>
                  <ul className='list-disc ml-6'>
                    <li>Concrete slab</li>
                    <li>Tennis court</li>
                    <li>Basketball court</li>
                    <li>Football field</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='text-violet-800 text-lg font-semibold'>Water Scarcity</div>
              <div className='font-semibold'>
                The water scarcity impact is compared to the impact of permanently removing a certain amount of water, in known volumes,
                from the ground in the UK. To do so, the ‘m3 deprivation’ values, the physical impact values for this category, are divided
                by a UK-specific factor of 3.5, representative of how scarce water is in the UK (when considering all existing demands and
                availability). As a result, the actual volume of water that would need to be permanently removed from the ground to cause
                said impact is obtained. This m3 value is finally compared to a set of volumes that are known to everyone for the water
                scarcity category. How many of these fixed volumes is presented as a contextual example.
              </div>
              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>M3 from a certain number of:</div>
                  <ul className='list-disc ml-6'>
                    <li>Glass of water</li>
                    <li>Bottle of water</li>
                    <li>Toilet flush</li>
                    <li>Bathtub</li>
                  </ul>
                </li>
              </ul>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='text-violet-800 text-lg font-semibold'>Particulate Matter</div>
              <div className='font-semibold'>
                Disease incidence values, representing the potential for human health impact of inhaling a substance, for dust in the air is
                used as a reference point when comparing the physical impact values of your product(s) for the particulate matter category.
              </div>
              <ul className='list-disc ml-6'>
                <li className='font-semibold'>Disease incidence from a certain number of kilograms of dust (PM 2.5) in the air</li>
              </ul>
              <div className='ml-6'>
                The disease incidence of 1kg of dust in the air is 7.00E-04 (
                <a
                  className='underline'
                  rel='noreferrer'
                  target='_blank'
                  href='https://www.universiteitleiden.nl/en/research/research-output/science/cml-ia-characterisation-factors'
                >
                  source
                </a>
                )
              </div>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='text-violet-800 text-lg font-semibold'>Resource Use, Fossil</div>
              <div className='font-semibold'>
                The physical impact of this category is measured in terms of the energy density of a fossil resource being extracted from
                the ground, in MJ. To simplify the understanding, we calculate the amount of electricity that could be produced in a coal
                power plant, in kWh, using a certain amount of coal extracted from the ground. These kWh’s derived from the resource use,
                fossil category output, are compared to the electricity use of some routine activities.
              </div>
              <div className='font-semibold'>
                In practice, to calculate the kWh values for this impact, the physical impact value in MJ is divided by 29 MJ (the calorific
                value of a kg of coal) to find how many kilograms of coal it is equivalent to. This result is then divided by 0.38kg, the
                amount of coal needed to produce 1kWh of electricity at a coal power plant with a 38% efficiency rate (source), The
                resulting kWhs are then compared to the electricity use of the following:
              </div>
              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>kWh needed to complete a full charge of your phone</div>
                </li>
              </ul>
              <div className='ml-6'>
                An electricity consumption rate of 0.005kWh (
                <a
                  className='underline'
                  rel='noreferrer'
                  target='_blank'
                  href='https://news.energysage.com/how-many-watts-does-a-phone-charger-use/#:~:text=Assuming%20one%20full%20charge%20takes,about%203.65%20kWh%20per%20year.'
                >
                  source
                </a>
                ) is used to derive how many times you can change your phone fully full charges you can minutes an average microwave can run
                with the resource use impact of your product(s).
              </div>
              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>kWh needed to microwave for a given number of minutes</div>
                </li>
              </ul>
              <div className='ml-6'>
                An electricity consumption rate of 0.01667kWh is used to derive how many minutes an average microwave can run with the
                resource use impact of your product(s).
              </div>

              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>kWh needed to power an LED for given number of days</div>
                </li>
              </ul>
              <div className='ml-6'>
                An electricity consumption rate of 0.24 kWh is used to derive how many days you can leave a LED light bulb on with the
                resource use impact of your product(s).
              </div>

              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>kWh needed to power your TV for a given number of hours</div>
                </li>
              </ul>
              <div className='ml-6'>
                An electricity consumption rate of 0.08 kWh is used to derive how many hours you can watch TV leave a LED lightbulb on with
                the resource use impact of your product(s)
              </div>
            </div>

            <div className='flex flex-col gap-3'>
              <div className='text-violet-800 text-lg font-semibold'>Resource Use, Minerals & Metals</div>
              <div className='font-semibold'>
                Kg Sb eq values, representing the depletion potential of a given resource normalised to antimony as a reference substance,
                for a set of different minerals are used as reference points when comparing the physical impact values of your product(s)
                for the resource use, minerals and metals category.
              </div>
              <ul className='list-disc ml-6'>
                <li>
                  <div className='font-semibold'>Kg Sb eq impact of extracting known minerals:</div>
                  <ul className='list-disc ml-6'>
                    <li>1g of Aluminium</li>
                    <li>1g of Silver</li>
                    <li>1g of Gold</li>
                    <li>1g of Copper</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </CollapsibleSection>
        <NavigationButtons type='buttons' back={{ path: '../consumer-view', label: 'Consumer View' }} />
      </div>
    </div>
  );
};
