import cn from 'classnames';
import { FieldProps } from 'formik';
import { useControlEvents } from '../hooks/useControlEventsV2';

interface Props {
  model: FieldProps;
  number?: boolean;
  integer?: boolean;
  positive?: boolean;
  password?: boolean;
  placeholder?: string;
  brandText?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
}

export const Input = (props: Props) => {
  const controlEvents = useControlEvents();
  const step = props.integer ? 1 : 0.01;

  return (
    <input
      disabled={props.disabled}
      type={props.number || props.integer ? 'number' : props.password ? 'password' : 'text'}
      min={props.positive ? step : 0}
      step={step}
      autoComplete='off'
      autoFocus={props.autoFocus}
      placeholder={props.placeholder ?? (props.number || props.integer ? (props.integer ? '0' : '0.0') : undefined)}
      className={
        props.className
          ? cn(props.className, props.model.meta.error ? 'border-f focus:ring-focusRingError' : 'border-zinc-500')
          : cn('rounded-full border px-4 py-2', props.model.meta.error ? 'border-f focus:ring-focusRingError' : 'border-brand', {
              'text-brand': props.brandText,
            })
      }
      {...props.model.field}
      value={props.model.field.value ?? ''}
      onChange={(event) => {
        if (event.target.value === '') {
          props.model.form.setFieldValue(props.model.field.name, null);
        } else {
          props.model.field.onChange(event);
        }

        if (controlEvents) {
          controlEvents.emitTouchedInput(props.model.field.name);
        }
      }}
    />
  );
};
