import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import { AnimatePresence, motion } from 'framer-motion';
import { PropsWithChildren, cloneElement, isValidElement, useEffect, useState } from 'react';
import { Limits } from '../hooks/useLimits';
import { Placement } from '@floating-ui/core';

type Props = PropsWithChildren<{
  enabled: boolean;
  entityName: string;
  valueKey: keyof Limits;
  onDismiss: () => void;
  placement?: Placement;
}>;

export const LimitTooltip = (props: Props) => {
  const [open, setOpen] = useState(props.enabled);

  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    placement: props.placement ?? 'bottom',
    open,
    onOpenChange: setOpen,
    middleware: [offset(20), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([useRole(context, { role: 'tooltip' }), useDismiss(context)]);

  useEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [refs.reference, refs.floating, update, open]);

  useEffect(() => {
    setOpen(props.enabled);
  }, [props.enabled]);

  useEffect(() => {
    if (!open) {
      props.onDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      {isValidElement(props.children) && cloneElement(props.children, getReferenceProps({ ref: reference }))}
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, translateY: -4 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 4 }}
            {...getFloatingProps({
              ref: floating,
              className:
                'antialiased z-10 whitespace-nowrap flex flex-col items-center gap-2 px-8 py-6 bg-white border rounded-xl shadow-xl text-dark',
              style: {
                position: strategy,
                top: y ?? '',
                left: x ?? '',
              },
            })}
          >
            <div>
              You reached your workspace <span className='font-semibold'>{props.entityName}</span>.
            </div>
            <div>
              Please{' '}
              <a href='mailto:impact@sustained.com?subject=Trial limits' className='font-semibold text-brand rounded-sm'>
                contact us
              </a>{' '}
              to increase it.
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
