import { GhgProductReport, ProductType } from '../../../../../../api';
import { NavigationButtons } from '../components/NavigationButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BasicDetails } from '../../BasicDetails';
import { simplify } from '../../../../shared';
import { format } from 'date-fns';

interface Props {
  data: GhgProductReport;
}

/* TODO: add warning */
export const Summary = (props: Props) => {
  const scope3totalEmission = props.data.analysis.scopeEmissions.find(({ id }) => id === 'scope_3')?.totalEmission!;
  const categoriesInScope = {
    count: props.data.analysis.categories.filter(({ isOutOfScope }) => !isOutOfScope).length,
    total: props.data.analysis.categories.length,
  };

  const fields = [
    { name: 'Product name', value: props.data.product.name },
    {
      name: 'Product type',
      value: {
        [ProductType.Final]: 'Final',
        [ProductType.Intermediate]: 'Intermediate',
        [ProductType.Internal]: 'Internal',
      }[props.data.product.productType],
    },
    {
      name: 'Country of final production',
      value: props.data.locations.production.map(({ name }) => name).join(', '),
    },
    {
      name: 'Estimated total emissions (scope 1/2/3)',
      value: `${simplify(props.data.analysis.totalEmission.value)} ${props.data.analysis.totalEmission.unit}`,
    },
    {
      name: 'Estimated Scope 3 contribution',
      value: `${simplify(scope3totalEmission.value)} ${scope3totalEmission.unit}`,
    },
    {
      name: (
        <div className='flex gap-x-1.5 border border-amber-400 rounded-lg bg-amber-50 px-2 p-1 mr-16'>
          <FontAwesomeIcon className='text-amber-400 mt-1' icon={regular('exclamation-triangle')} />
          <div className='text-sm'>
            Only {categoriesInScope.count} out of {categoriesInScope.total} Scope 3 categories are in scope of this assessment.
          </div>
        </div>
      ),
      value: '',
    },
    {
      name: 'Report creator',
      value: props.data.product.author,
    },
    {
      name: 'Date of report creation',
      value: format(new Date(props.data.product.createdAt), 'dd/MM/yyyy  HH:mm'),
    },
    {
      name: 'Versioning',
      value: (
        <ul className='list-disc ml-3.5'>
          <li>Methodology v.{props.data.metadata.methodologyVersion}</li>
          <li>Web application v.{props.data.metadata.webAppVersion}</li>
        </ul>
      ),
    },
  ];

  return (
    <div className='flex flex-col gap-8 px-6 print:text-sm'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>A brief overview of the product and results obtained</div>
        <NavigationButtons type='icons' next={{ path: '../details' }} />
      </div>
      <BasicDetails data={props.data.product} fields={fields} />
      <NavigationButtons type='buttons' next={{ path: '../details', label: 'Product Details' }} />
    </div>
  );
};
