import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Facility, FacilityType, Supplier, SupplierService, getFacilities } from '../../../../api';

interface Props {
  supplier: Supplier;
}

export const OwnedFacilities = (props: Props) => {
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const [expandedStores, setExpandedStores] = useState(false);
  const [expandedWarehouses, setExpandedWarehouses] = useState(false);
  const [expandedManufacturers, setExpandedManufacturers] = useState(false);
  const brandOwner = props.supplier.services.find((service) => service === SupplierService.BrandOwner);

  useEffect(() => {
    getFacilities({ owner: props.supplier.id, pageSize: 500, sortAscending: true }).ok(({ facilities }) => {
      setExpandedStores(false);
      setExpandedWarehouses(false);
      setExpandedManufacturers(false);
      setFacilities(facilities);
    });
  }, [props.supplier.id]);

  const renderTable = (
    id: FacilityType,
    title: string,
    items: { id: string; name: string; location: string; address?: string }[],
    expanded: boolean,
    setExpanded: (extended: boolean) => void,
  ) => {
    return (
      <div className='flex flex-col bg-white'>
        <div className='text-lg font-semibold pl-6 py-3'>{title}</div>
        <div className='grid grid-cols-[1fr_1fr_2fr] bg-slate-100 text-zinc-500 uppercase text-[70%] py-1.5 px-6'>
          <div>{id === FacilityType.Warehouse ? '' : 'owner of'}</div>
          <div>country</div>
          <div>address</div>
        </div>

        <button
          onClick={() => setExpanded(!expanded)}
          className={cn(
            'flex self-start items-center border rounded-full',
            'hover:cursor-pointer hover:bg-slate-50',
            'pl-1.5 pr-3 gap-1 py-1 my-3 ml-6',
          )}
        >
          <FontAwesomeIcon size='xs' className={cn('transition-transform', { 'rotate-90': expanded })} icon={regular('chevron-right')} />
          <div className='h-4 aspect-square bg-slate-200 flex items-center justify-center rounded-full text-brand text-xs'>
            {items.length}
          </div>
          {
            {
              production: 'production facilities',
              warehouse: 'production storage',
              store: 'stores',
              consumption: 'consumption',
            }[id]
          }
        </button>

        {expanded && (
          <div className={cn('divide-y my-2')}>
            {items.map((item, i) => {
              return (
                <Link
                  to={`/manage/locations/all/${item.id}`}
                  target='_blank'
                  className='grid grid-cols-[1fr_1fr_2fr] gap-x-2 py-2 px-6 items-center h-[56px] hover:bg-slate-100'
                  key={i}
                >
                  <div className='truncate'>{item.name}</div>
                  <div>{item.location}</div>
                  <div className='flex justify-between items-center gap-6'>
                    <div>{item.address}</div>
                    <FontAwesomeIcon size='lg' className='rotate-45 text-brand' icon={light('arrow-up')} />
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const manufacturers = facilities.filter((facility) => facility.type === FacilityType.Production);
  const stores = facilities.filter((facility) => facility.type === FacilityType.Store);
  const warehouses = facilities.filter((facility) => facility.type === FacilityType.Warehouse);

  return (
    <div className='flex flex-col bg-white'>
      {brandOwner && (
        <div className='flex flex-col bg-white'>
          <div className='text-lg font-semibold pl-6 py-3'>Brand owner</div>
          <div className='bg-slate-100 text-zinc-500 uppercase text-[70%] py-1.5 px-6'>
            <div>owner of</div>
          </div>
          <div className='flex self-start items-center border rounded-full gap-1 py-1 px-2 my-3 ml-6'>{props.supplier.name}</div>
        </div>
      )}
      {manufacturers.length > 0 &&
        renderTable(
          FacilityType.Production,
          'Manufacturer',
          manufacturers.map(({ id, name, location, address }) => ({
            id,
            name,
            location: location.name,
            address: address?.text,
          })),
          expandedManufacturers,
          setExpandedManufacturers,
        )}
      {stores.length > 0 &&
        renderTable(
          FacilityType.Store,
          'Retailer',
          stores.map(({ id, name, location, address }) => ({
            id,
            name,
            location: location.name,
            address: address?.text,
          })),
          expandedStores,
          setExpandedStores,
        )}
      {warehouses.length > 0 &&
        renderTable(
          FacilityType.Warehouse,
          'Storage',
          warehouses.map(({ id, name, location, address }) => ({
            id,
            name,
            location: location.name,
            address: address?.text,
          })),
          expandedWarehouses,
          setExpandedWarehouses,
        )}
    </div>
  );
};
