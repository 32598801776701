import { request, Targets, Tracking } from '.';

export enum PortfolioState {
  Empty = 'empty',
  BaselineNotSet = 'baseline_not_set',
  BaselineSet = 'baseline_set',
  HistoricalCreated = 'historical_created',
}

export enum Flow {
  ReduceExisting = 'reduce_existing',
  DesignNew = 'design_new',
  AskUser = 'ask_user',
}

type StepId<T> = T extends Flow.ReduceExisting
  ? '1.1' | '1.2' | '1.3' | '2.1' | '2.2' | '2.3' | '2.4' | '3.1' | '4'
  : '1' | '2.1' | '2.2' | '2.3' | '2.4' | '3.1' | '4';

export interface DashboardStatusImpact {
  id: string;
  impactPoints: number;
  isToxicityRelated: boolean;
  name: string;
  physicalValue: number;
  unit: string;
  weightedNormalisedValue: number;
  absSharePercent: number;
}

export interface DashboardStatusProduct {
  id: string;
  name: string;
  impactPoints: number;
  contribution: number;
  isMajor: boolean;
}

export interface ProductsEntriesCompletion {
  complete: number;
  drafts: number;
  target: number;
  missing: number;
}

export interface PortfolioFirstPartyData {
  averageFirstParty: number;
  products: {
    id: string;
    name: string;
    firstParty: number;
  }[];
}

interface InDevelopmentProduct {
  id: string;
  name: string;
  impactPoints: number;
}

export interface InDevelopment {
  complete: number;
  drafts: number;
  total: number;
  highestImpactProducts: InDevelopmentProduct[];
  lowestImpactProducts: InDevelopmentProduct[];
}

export interface PortfolioHistoricalImpact extends PortfolioBaselineImpact {
  latestReportId: string;
}

export interface PortfolioBaselineImpact {
  author: string;
  createdAt: string;
  productCount: number;
  totalUnitCount: number;
  startDate: string;
  endDate: string;
  overallImpact: number;
  impactPoints: number;
  impacts: DashboardStatusImpact[];
  products: DashboardStatusProduct[];
  otherProductsCount: number;
}

interface LatestEditsByUser {
  id: string;
  modelId?: string;
  name: string;
  type: 'baseline' | 'historical' | 'forecast' | 'product' | 'model';
  updatedAt: string;
}

interface PortfolioGeneral<T> {
  state: T;
  completion: ProductsEntriesCompletion;
  inDevelopment: InDevelopment;
  defaultFlow: Flow;
  defaultStep?: StepId<Flow>;
  latestEditsByUser: LatestEditsByUser[];
  tracking?: Tracking;
}

export interface PortfolioEmpty extends PortfolioGeneral<PortfolioState.Empty> {}

export interface PortfolioBaselineNotSet extends PortfolioGeneral<PortfolioState.BaselineNotSet> {
  productsImpact?: {
    highestImpactProduct: {
      id: string;
      name: string;
      impactPoints: number;
    };
    lowestImpactProduct: {
      id: string;
      name: string;
      impactPoints: number;
    };
  };
  firstParty?: PortfolioFirstPartyData;
}

export interface PortfolioBaselineSet extends PortfolioGeneral<PortfolioState.BaselineSet> {
  firstParty: PortfolioFirstPartyData;
  baselineImpact: PortfolioBaselineImpact;
  targets: Targets;
}

export interface PortfolioHistoricalCreated extends PortfolioGeneral<PortfolioState.HistoricalCreated> {
  state: PortfolioState.HistoricalCreated;
  firstParty: PortfolioFirstPartyData;
  baselineImpact: PortfolioBaselineImpact;
  historicalImpact: PortfolioHistoricalImpact;
  tracking: Tracking;
  targets: Targets;
  inDevelopment: InDevelopment;
}

export type PortfolioDashboard = PortfolioEmpty | PortfolioBaselineNotSet | PortfolioBaselineSet | PortfolioHistoricalCreated;

export type PortfolioJourney = Omit<PortfolioEmpty, 'state'> &
  Omit<PortfolioBaselineNotSet, 'state'> &
  Omit<PortfolioBaselineSet, 'state'> &
  Omit<PortfolioHistoricalCreated, 'state'> & { state: PortfolioState };

export const getPortfolioDashboard = <T>() => request<T>('GET', '/v3/dashboards/portfolio/status');
