import { useId } from '@floating-ui/react-dom-interactions';
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import { PropsWithChildren, RefObject, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import MultiRef from 'react-multi-ref';
import {
  Amount,
  Entity,
  EntityWithUnit,
  NodeType,
  OutputType,
  ProcessType,
  ProductState,
  ProductV3,
  ProductionIngredientNode,
  ProductionNode,
  ProductionProcess,
  ProductionProcessAuxiliary,
  ProductionProcessElectricityType,
  StaticEntity,
  StepInput,
  StepOutput,
  WasteSubType,
  WasteType,
  searchEmissions,
} from '../../../../../api';
import { InputV3 } from '../../../../../components/InputV3';
import { ModalForm, ModalFormSaveCallback } from '../../../../../components/ModalForm';
import { SelectV3 } from '../../../../../components/SelectV3';
import { Toggle } from '../../../../../components/Toggle';
import { UnitInputV3 } from '../../../../../components/UnitInputV3';
import { useEffectOnNextRenders } from '../../../../../hooks/useEffectOnNextRenders';
import { useLists } from '../../../../../hooks/useLists';
import { ExtractedData } from '../ExtractedData';
import { ModalHeaderRightBar } from '../ModalHeaderRightBar';
import { TaggableField, TaggableFieldsContainer, TouchedAwareFieldArray } from '../TaggableFields';
import {
  StepInputAmountSideEffect,
  UpdateSideEffects,
  getIngredients,
  getInputNode,
  getOutputs,
  getPackagings,
  getRawMaterials,
  getTransports,
  newOutputId,
  newStepId,
  stepValidationSchema,
} from '../dataModel';

type Props = PropsWithChildren<{
  parentNode: ProductionNode;
  data?: ProductionIngredientNode;
  onSave: ModalFormSaveCallback<ProductionIngredientNode, { sideEffects: UpdateSideEffects }>;
}>;

export const IngredientStepDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductV3>();
  const lists = useLists();
  const [outputRefs] = useState(() => new MultiRef<number, OutputApi>());

  return (
    <ModalForm
      size='wide'
      formRef={formRef}
      title={props.data ? `Editing ${props.data.displayName}` : 'New food production step'}
      body={<Body parentNode={props.parentNode} productFormik={formik} formRef={formRef} outputRefs={outputRefs} edit={!!props.data} />}
      headerRight={<ModalHeaderRightBar />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>Packaging your food product also has an impact.</div>
          <div>
            Start by selecting a process from our list and specifying if this is the last step happening in this particular facility.
            Remember, if the process you’re looking for doesn’t exist,{' '}
            <a className='underline hover:text-brand' href='mailto:impact@sustained.com?subject=Sustained Impact: Process missing'>
              let us know
            </a>{' '}
            so we can get our team of experts working on adding what you need right away!
          </div>
          <div>From here, tell us what the inputs to this process are and what amounts are being used in this particular step.</div>
          <div>
            An optional override section may be available. This is where you can add primary data about your specific process. This is
            simple, either we use our default assumptions, or our assumptions (including energy and water) get replaced with your own
            collected primary data. When looking for this data, make sure you input the amounts required for this specific process (ie.
            boiling, frying etc.) to produce one product only. Good LCA practice suggests taking an average of the inputs and outputs over a
            representative period of time, e.g. one year for an industrial plant.
          </div>
          <div>
            Now onto outputs, what is the resulting product of this step? If this isn’t the very final step of the process, resulting in the
            product, you should give it a recognizable name as you’ll likely need to select this intermediate product as an input to another
            step down the line. You may also have other outputs resulting from this step including waste, emissions, by products or co
            products. Add as many as you need in here!
          </div>
        </div>
      }
      emptyData={{
        id: newStepId(),
        displayName: '',
        type: NodeType.ProductionIngredient,
        flagged: false,
        // needs to be null not undefined, otherwise empty process is not validated due to .shape()
        process: null as any as ProductionProcess,
        inputs: [{} as any as StepInput],
        outputs: new Array<StepOutput>(),
        finalStep: false,
      }}
      data={props.data}
      metadata={formik.values.metadata}
      validationSchema={stepValidationSchema({
        productionNode: props.parentNode,
        packaging: false,
        productFormik: formik,
        lists,
      })}
      getCustomErrors={(errors) => [
        {
          message: 'Electricity composition must add up to 100%.',
          expected: 'splitsNot100',
          actual: errors.process?.overrides?.electricity?.types,
        },
        { message: 'Add at least one intermediate product.', expected: 'needsAtLeastOneIntermediate', actual: errors.outputs },
        {
          message: 'Both electricity type and amount are required to override electricity input.',
          expected: 'missingValueOrTypes',
          actual: errors.process?.overrides?.electricity,
        },
      ]}
      entityName='step'
      saveLabel={formik.values.state === ProductState.Complete ? 'Confirm changes' : undefined}
      onSave={({ values, ...rest }) => {
        const savedValues = cloneDeep(values);
        savedValues.process.overrides.electricity.types = savedValues.process.overrides.electricity.types.filter(({ type }) => type);
        savedValues.process.overrides.auxiliaries = savedValues.process.overrides.auxiliaries.filter(({ type }) => type);
        props.onSave({
          values: savedValues,
          sideEffects: { stepInputAmounts: Array.from(outputRefs.map.values()).flatMap((ref) => ref.getSideEffects()) },
          ...rest,
        });
      }}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  parentNode: ProductionNode;
  productFormik: FormikContextType<ProductV3>;
  formRef: RefObject<HTMLDivElement>;
  outputRefs: MultiRef<number, OutputApi>;
  edit: boolean;
}

const Body = (props: BodyProps) => {
  const formik = useFormikContext<ProductionIngredientNode>();
  const lists = useLists();

  const { parentNode, productFormik } = props;
  const outputsFinalProduct = parentNode.finalFacility && formik.values.finalStep;
  const process = lists.processes.find(({ id }) => id === formik.values.process?.id);
  const defaultUnit = lists.foodTypes.find(({ type }) => type === productFormik.values.foodType.type)!.unit;
  const previousProcessPlaceholder = useRef(!!formik.values.process?.placeholder);
  const processEffectFirstRender = useRef(true);

  const inputOptions = uniqBy(
    [
      ...parentNode.steps
        .filter(({ id }) => id !== formik.values.id)
        .flatMap(({ outputs }) => outputs)
        .filter(({ outputType: { type } }) => type === OutputType.IntermediateProduct),
      ...getTransports(productFormik)
        .filter(({ edges }) => edges.some((id) => id === parentNode.id))
        .flatMap(({ items }) => items)
        .filter((item) => !getPackagings(productFormik).some(({ id }) => id === item.id))
        .flatMap((item) => [...getOutputs(productFormik), ...getRawMaterials(productFormik)].filter(({ id }) => id === item.id)),
      ...getIngredients(productFormik).filter((ingredient) => ingredient.localSupply),
    ],
    ({ id }) => id,
  ).map((node) => ({
    ...node,
    amountValue: node.amount!.value,
  }));

  const addEmptyIntermediateOutputIfNeeded = (values: ProductionIngredientNode) => {
    if (!outputsFinalProduct) {
      values.outputs = [
        ...values.outputs,
        ...(values.outputs.some(({ outputType }) => outputType?.type === OutputType.IntermediateProduct)
          ? []
          : [
              {
                id: newOutputId(),
                outputType: lists.outputTypes.find(({ type }) => type === OutputType.IntermediateProduct)!,
                name: '',
                amount: {
                  unit: defaultUnit,
                } as Amount,
              },
            ]),
      ];
    }
  };

  useEffect(() => {
    formik.setValues((oldValues) => {
      const newValues = cloneDeep(oldValues);

      if (!processEffectFirstRender.current) {
        newValues.displayName = '';
        delete newValues.index;
      }

      if (process) {
        if (newValues.process.overrides.electricity.types.length === 0) {
          newValues.process.overrides.electricity.types = [{} as ProductionProcessElectricityType];
        }

        if (newValues.process.overrides.auxiliaries.length === 0) {
          newValues.process.overrides.auxiliaries = [{} as ProductionProcessAuxiliary];
        }
      }

      addEmptyIntermediateOutputIfNeeded(newValues);

      return newValues;
    });

    if (!processEffectFirstRender.current) {
      previousProcessPlaceholder.current = false;
    }

    processEffectFirstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [process?.id]);

  useEffectOnNextRenders(() => {
    formik.setValues((oldValues) => {
      const newValues = cloneDeep(oldValues);

      if (outputsFinalProduct) {
        newValues.outputs = newValues.outputs.filter(({ outputType }) => outputType?.type !== OutputType.IntermediateProduct);
      }

      addEmptyIntermediateOutputIfNeeded(newValues);

      return newValues;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputsFinalProduct]);

  return (
    <TaggableFieldsContainer pathPrefix={`nodes/${parentNode.id}/steps`}>
      <div className='flex flex-col gap-6'>
        <ExtractedData {...props} />
        <div className='flex flex-col gap-4'>
          <div className='grid grid-cols-[repeat(2,max-content)] gap-4'>
            <TaggableField name='process'>
              {(model: FieldProps<Entity>) => (
                <div className='w-80 flex flex-col gap-1'>
                  <div className='pl-1.5'>Process</div>
                  <div className='flex flex-col'>
                    <SelectV3<Entity>
                      autoFocus
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      options={lists.processes
                        .filter(({ type }) => type === ProcessType.Industrial)
                        .filter(({ id }) => !lists.packagingTypes.flatMap(({ packagingProcesses }) => packagingProcesses).includes(id))
                        .map((process) => ({
                          ...process,
                          overrides: {
                            electricity: {
                              types: [],
                            },
                            auxiliaries: [],
                          },
                        }))}
                    />
                  </div>
                </div>
              )}
            </TaggableField>
            <Field name='displayName'>
              {(model: FieldProps<Entity>) => (
                <div className='w-80 flex flex-col gap-1'>
                  <div className='pl-1.5'>Display name</div>
                  <div className='flex flex-col'>
                    <InputV3 model={model} placeholder={formik.values.process?.name ?? 'Defaults to process name if left empty…'} />
                  </div>
                </div>
              )}
            </Field>
          </div>
          {formik.values.process?.placeholder && (
            <Field name='process.comment'>
              {(model: FieldProps<Entity>) => (
                <div className='flex flex-col gap-1'>
                  <div className='pl-1.5'>Comment for process</div>
                  <div className='flex flex-col'>
                    <InputV3 model={model} placeholder='Describe the process…' />
                  </div>
                </div>
              )}
            </Field>
          )}
          <TaggableField name='finalStep'>
            {(model: FieldProps) => (
              <div className='flex flex-col gap-4 text-neutral-900'>
                <div className='flex flex-col gap-1'>
                  <div className='font-semibold'>Final production step</div>
                  <div>Please let us know if this is the last production step happening in the {parentNode.displayName} facility.</div>
                </div>
                <Toggle model={model} />
              </div>
            )}
          </TaggableField>
        </div>
        <div className='border-b border-neutral-300' />
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-2 text-lg text-neutral-900'>
            <FontAwesomeIcon icon={regular('arrow-up-from-bracket')} />
            <div className='font-semibold'>Inputs</div>
          </div>
          <TouchedAwareFieldArray name='inputs'>
            {(arrayModel) => (
              <>
                {formik.values.inputs.map((input, i) => (
                  <div key={input.id ?? i} className='flex gap-2'>
                    <TaggableField name={`${arrayModel.name}.${i}.id`}>
                      {(model: FieldProps<string>) => (
                        <div className='flex flex-col gap-1'>
                          {i === 0 && <div className='pl-1.5'>Production step input</div>}
                          <div className='w-80'>
                            <SelectV3<StepInput>
                              model={model}
                              getOptionLabel={(option) => getInputNode(option, productFormik)!.name}
                              menuPortalTarget={props.formRef.current}
                              options={inputOptions.filter((option) => !formik.values.inputs.some((input) => input.id === option.id))}
                              onActions={{
                                add: (option: StepInput) => arrayModel.replace(i, option),
                                remove: () => arrayModel.remove(i),
                                clear: () => arrayModel.replace(i, {}),
                              }}
                              convertOptions={{
                                fromModel: () => (input.id ? input : null),
                                toModel: (value: StepInput) => value?.id,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </TaggableField>
                    <TaggableField name={`${arrayModel.name}.${i}.amountValue`}>
                      {(model: FieldProps<number>) => (
                        <div className='flex flex-col gap-1'>
                          {i === 0 && <div className='pl-1.5'>Amount</div>}
                          <div className='w-44'>
                            <UnitInputV3
                              model={model}
                              unit={{
                                options: (
                                  (() => {
                                    const unit = getInputNode(input, productFormik)?.amount?.unit;
                                    return unit ? [unit] : [];
                                  }) as () => Entity[]
                                )(),
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </TaggableField>
                    <div className='flex flex-col gap-1'>
                      {i === 0 && <div>&nbsp;</div>}
                      <button
                        type='button'
                        className='flex bg-[#F5F7FA] text-zinc-400 hover:text-red-500 rounded-lg justify-center items-center aspect-square h-8 p-0.5'
                        onClick={arrayModel.handleRemove(i)}
                      >
                        <FontAwesomeIcon size='lg' icon={regular('trash-can')} />
                      </button>
                    </div>
                  </div>
                ))}
                {formik.values.inputs.length < inputOptions.length && (
                  <Field name={arrayModel.name}>
                    {(model: FieldProps) => (
                      <button
                        type='button'
                        className={cn(
                          'pl-1.5 self-start flex items-center gap-2 hover:text-brand',
                          model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                        )}
                        onClick={arrayModel.handlePush({})}
                      >
                        <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                        <div className='font-semibold'>Add input</div>
                      </button>
                    )}
                  </Field>
                )}
              </>
            )}
          </TouchedAwareFieldArray>
          <div className='self-start flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
            <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
            Please note that only raw materials and outputs transported to this facility can be selected.
          </div>
        </div>
        {process?.overrides && (
          <div className='flex flex-col gap-3 p-4 rounded-xl bg-[#F5F7FA]'>
            <div className='text-base font-semibold text-neutral-900'>Optional overrides</div>
            <div className='flex items-center p-2 gap-2 bg-brand/5 text-neutral-900 rounded-lg'>
              <FontAwesomeIcon size='lg' icon={light('info-circle')} />
              <div>
                Adding your own primary data into these optional override fields will make your impact assessment more accurate and
                reflective of your processes rather than the average process. If left empty, secondary data will be used instead.
              </div>
            </div>
            <div className='flex gap-2 mt-2'>
              {process.overrides.electricity && (
                <>
                  <Field name='process.overrides.electricity.value'>
                    {(model: FieldProps<number>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Electricity amount</div>
                        <div className='flex flex-col gap-2 w-56'>
                          <UnitInputV3
                            model={model}
                            placeholder='Default amount'
                            unit={{ options: [process.overrides!.electricity!.unit] }}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                  <FieldArray
                    name='process.overrides.electricity.types'
                    render={(arrayModel) => (
                      <div className='flex flex-col gap-2'>
                        {formik.values.process.overrides.electricity.types.map((typeObject, i) => (
                          <div key={typeObject.type ?? i} className='flex flex-col gap-1'>
                            <div className='flex gap-2'>
                              <Field name={`${arrayModel.name}.${i}.type`}>
                                {(model: FieldProps<string>) => (
                                  <div className='flex flex-col gap-1'>
                                    {i === 0 && <div className='pl-1.5'>Type</div>}
                                    <div className='w-56'>
                                      <SelectV3<ProductionProcessElectricityType>
                                        model={model}
                                        menuPortalTarget={props.formRef.current}
                                        options={process
                                          .overrides!.electricity!.types.map((processType) => ({
                                            ...processType,
                                            percent: undefined as any as number,
                                          }))
                                          .filter(
                                            ({ type }) =>
                                              !formik.values.process.overrides.electricity.types.some(
                                                (selectedType) => selectedType.type === type,
                                              ),
                                          )}
                                        onActions={{
                                          add: (option: ProductionProcessElectricityType) => {
                                            arrayModel.replace(i, {
                                              ...option,
                                              ...(formik.values.process.overrides.electricity.types.length === 1 ? { percent: 100 } : {}),
                                            });
                                          },
                                          remove: () => arrayModel.remove(i),
                                          clear: () => arrayModel.replace(i, {}),
                                        }}
                                        convertOptions={{
                                          fromModel: () => (typeObject.type ? typeObject : null),
                                          toModel: (value: ProductionProcessElectricityType) => value?.type,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Field>
                              <Field name={`${arrayModel.name}.${i}.percent`}>
                                {(model: FieldProps<number>) => (
                                  <div className='flex flex-col gap-1'>
                                    {i === 0 && <div className='pl-1.5'>Percentage</div>}
                                    <div className='w-56'>
                                      <UnitInputV3 model={model} unit={{ options: [{ id: '', name: '%' }] }} />
                                    </div>
                                  </div>
                                )}
                              </Field>
                              {formik.values.process.overrides.electricity.types.length > 1 && (
                                <button
                                  type='button'
                                  className='self-end flex bg-[#F5F7FA] text-zinc-400 hover:text-red-500 rounded-lg justify-center items-center aspect-square h-8 p-0.5'
                                  onClick={arrayModel.handleRemove(i)}
                                >
                                  <FontAwesomeIcon size='lg' icon={regular('trash-can')} />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <div className='pl-1.5 flex flex-col gap-2'>
                          {formik.values.process.overrides.electricity.types.length < process.overrides!.electricity!.types.length && (
                            <button
                              type='button'
                              className='self-start flex items-center gap-2 text-brandDarkPurple2 hover:text-brand'
                              onClick={arrayModel.handlePush({})}
                            >
                              <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                              <div className='font-semibold'>Add electricity type</div>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </>
              )}
            </div>
            {process.overrides.gas && (
              <Field name='process.overrides.gas.value'>
                {(model: FieldProps<number>) => (
                  <div className='flex flex-col gap-1'>
                    <div className='pl-1.5'>Gas amount</div>
                    <div className='w-56'>
                      <UnitInputV3 model={model} placeholder='Default amount' unit={{ options: [process.overrides!.gas!.unit] }} />
                    </div>
                  </div>
                )}
              </Field>
            )}
            {process.overrides.water && (
              <Field name='process.overrides.water.input.value'>
                {(model: FieldProps<number>) => (
                  <div className='flex flex-col gap-1'>
                    <div className='pl-1.5'>Water amount</div>
                    <div className='w-56'>
                      <UnitInputV3
                        model={model}
                        unit={{ options: [process.overrides!.water!.input.unit] }}
                        placeholder={(() => {
                          const outputAmount = formik.values.process?.overrides?.water?.output?.value;

                          if (typeof outputAmount === 'number') {
                            return outputAmount.toString();
                          }

                          return 'Default amount';
                        })()}
                      />
                    </div>
                  </div>
                )}
              </Field>
            )}
            {process.overrides.auxiliaries && (
              <FieldArray
                name='process.overrides.auxiliaries'
                render={(arrayModel) => (
                  <div className='flex flex-col gap-2'>
                    {formik.values.process.overrides.auxiliaries.map((auxiliary, i) => (
                      <div key={auxiliary.type ?? i} className='flex flex-col gap-1'>
                        <div className='flex gap-2'>
                          <Field name={`${arrayModel.name}.${i}.type`}>
                            {(model: FieldProps<string>) => (
                              <div className='flex flex-col gap-1'>
                                {i === 0 && <div className='pl-1.5'>Auxiliary input</div>}
                                <div className='w-56'>
                                  <SelectV3<ProductionProcessAuxiliary>
                                    model={model}
                                    menuPortalTarget={props.formRef.current}
                                    options={process
                                      .overrides!.auxiliaries!.map((processAuxiliary) => ({
                                        ...processAuxiliary,
                                        value: undefined as any as number,
                                      }))
                                      .filter(
                                        ({ type }) =>
                                          !formik.values.process.overrides.auxiliaries.some(
                                            (selectedAuxiliary) => selectedAuxiliary.type === type,
                                          ),
                                      )}
                                    onActions={{
                                      add: (option: ProductionProcessAuxiliary) => arrayModel.replace(i, option),
                                      remove: () => arrayModel.remove(i),
                                      clear: () => arrayModel.replace(i, {}),
                                    }}
                                    convertOptions={{
                                      fromModel: () => (auxiliary.type ? auxiliary : null),
                                      toModel: (value: ProductionProcessAuxiliary) => value?.type,
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                          <Field name={`${arrayModel.name}.${i}.value`}>
                            {(model: FieldProps<number>) => (
                              <div className='flex flex-col gap-1'>
                                {i === 0 && <div className='pl-1.5'>Amount</div>}
                                <div className='w-44'>
                                  <UnitInputV3
                                    model={model}
                                    unit={{
                                      options: auxiliary.type
                                        ? [process.overrides!.auxiliaries!.find(({ type }) => type === auxiliary.type)!.unit]
                                        : [],
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                          {formik.values.process.overrides.auxiliaries.length > 1 && (
                            <button
                              type='button'
                              className='self-end flex bg-[#F5F7FA] text-zinc-400 hover:text-red-500 rounded-lg justify-center items-center aspect-square h-8 p-0.5'
                              onClick={arrayModel.handleRemove(i)}
                            >
                              <FontAwesomeIcon size='lg' icon={regular('trash-can')} />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    {formik.values.process.overrides.auxiliaries.length < process.overrides!.auxiliaries!.length && (
                      <button
                        type='button'
                        className='pl-1.5 self-start flex items-center gap-2 text-brandDarkPurple2 hover:text-brand'
                        onClick={arrayModel.handlePush({})}
                      >
                        <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                        <div className='font-semibold'>Add auxiliary</div>
                      </button>
                    )}
                  </div>
                )}
              />
            )}
          </div>
        )}
        <div className='flex flex-col gap-4'>
          <div className='border-t pt-6 flex items-center gap-2 text-lg text-neutral-900'>
            <FontAwesomeIcon icon={regular('arrow-down-to-bracket')} />
            <div className='font-semibold'>Outputs</div>
          </div>
          {!process ? (
            <div>Please select a process first</div>
          ) : (
            <TouchedAwareFieldArray name='outputs'>
              {(arrayModel) => (
                <>
                  {outputsFinalProduct && (
                    <div className='flex gap-2'>
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Output type</div>
                        <div className='w-44'>
                          <SelectV3
                            value={{ id: 'final_product', name: 'Final product' }}
                            options={[]}
                            menuPortalTarget={props.formRef.current}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Name</div>
                        <div className='w-36 flex flex-col'>
                          <InputV3 value={productFormik.values.name} disabled />
                        </div>
                      </div>
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Quantity</div>
                        <div className='w-40'>
                          <UnitInputV3
                            value={productFormik.values.bruttoAmount.value}
                            unit={{ options: [productFormik.values.bruttoAmount.unit] }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {formik.values.outputs.map((output, index) => (
                    <Output
                      key={output.id}
                      ref={props.outputRefs.ref(index)}
                      index={index}
                      output={output}
                      arrayModel={arrayModel}
                      outputsFinalProduct={outputsFinalProduct}
                      {...props}
                    />
                  ))}
                  <div className='pl-1.5 flex flex-col gap-2'>
                    <Field name={arrayModel.name}>
                      {(model: FieldProps) => (
                        <button
                          type='button'
                          className={cn(
                            'self-start flex items-center gap-2 hover:text-brand',
                            model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                          )}
                          onClick={arrayModel.handlePush({
                            id: newOutputId(),
                            amount: {
                              unit: defaultUnit,
                            },
                          })}
                        >
                          <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                          <div className='font-semibold'>Add output</div>
                        </button>
                      )}
                    </Field>
                  </div>
                  {process?.overrides?.water && (
                    <div className='flex flex-col gap-3 p-4 rounded-xl bg-[#F5F7FA]'>
                      <div className='text-base font-semibold text-neutral-900'>Optional overrides</div>
                      <div className='flex items-center p-2 gap-2 bg-brand/5 text-neutral-900 rounded-lg'>
                        <FontAwesomeIcon size='lg' icon={light('info-circle')} />
                        <div>
                          Adding your own primary data into these optional override fields will make your impact assessment more accurate
                          and reflective of your processes rather than the average process. If left empty, secondary data will be used
                          instead.
                        </div>
                      </div>
                      <div className='flex gap-2'>
                        <div className='flex flex-col gap-1'>
                          <div className='pl-1.5'>Output type</div>
                          <div className='w-44'>
                            <SelectV3
                              value={lists.outputTypes.find(({ type }) => type === OutputType.Waste)}
                              options={[]}
                              menuPortalTarget={props.formRef.current}
                              disabled
                            />
                          </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                          <div className='pl-1.5'>Waste type</div>
                          <div className='w-36'>
                            <SelectV3
                              value={lists.wasteTypes.find(({ type }) => type === WasteType.Liquid)}
                              options={[]}
                              menuPortalTarget={props.formRef.current}
                              disabled
                            />
                          </div>
                        </div>
                        <div className='flex flex-col gap-1'>
                          <div className='pl-1.5'>Sub type</div>
                          <div className='w-36'>
                            <SelectV3
                              value={lists.wasteTypes
                                .flatMap(({ subTypes }) => subTypes)
                                .find(({ type }) => type === WasteSubType.Wastewater)}
                              options={[]}
                              menuPortalTarget={props.formRef.current}
                              disabled
                            />
                          </div>
                        </div>
                        <Field name='process.overrides.water.output.value'>
                          {(model: FieldProps<number>) => (
                            <div className='flex flex-col gap-1'>
                              <div className='pl-1.5'>Quantity</div>
                              <div className='w-52'>
                                <UnitInputV3
                                  model={model}
                                  unit={{
                                    options: [lists.wasteTypes.find(({ type }) => type === WasteType.Liquid)!.unit],
                                  }}
                                  placeholder={(() => {
                                    const inputAmount = formik.values.process?.overrides?.water?.input?.value;

                                    if (typeof inputAmount === 'number') {
                                      return inputAmount.toString();
                                    }

                                    return 'Default quantity';
                                  })()}
                                />
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className='self-start flex items-center bg-yellow-50 shadow-sm rounded-lg border py-0.5 px-2 text-sm'>
                        This output has been pre-filled from your inputs. You can still modify the quantity by clicking on the text.
                      </div>
                    </div>
                  )}
                </>
              )}
            </TouchedAwareFieldArray>
          )}
        </div>
      </div>
    </TaggableFieldsContainer>
  );
};

interface OutputApi {
  getSideEffects: () => StepInputAmountSideEffect[];
}

const Output = forwardRef<
  OutputApi,
  {
    index: number;
    output: StepOutput;
    arrayModel: FieldArrayRenderProps;
    outputsFinalProduct: boolean;
  } & BodyProps
>((props, ref) => {
  const formik = useFormikContext<ProductionIngredientNode>();
  const lists = useLists();

  const { index, output, arrayModel } = props;
  const originalAmountValue = useRef(output.amount?.value);
  const updateNextStepInputCheckboxId = useId();
  const [updateNextStepInput, setUpdateNextStepInput] = useState(true);

  const getEmissionSubTypes = () => lists.emissionDestinations.find(({ type }) => type === output.emission?.destination?.type)?.subTypes;

  const getSingleNextStepUsingOutput = () => {
    const inputs = props.parentNode.steps
      .flatMap((step) => step.inputs.map((input) => ({ step, input })))
      .filter(({ input: { id } }) => id === output.id);
    return inputs.length === 1 ? inputs[0].step : undefined;
  };

  const canUpdateNextStepInput =
    props.edit &&
    output.outputType?.type === OutputType.IntermediateProduct &&
    typeof originalAmountValue.current === 'number' &&
    typeof output.amount?.value === 'number' &&
    originalAmountValue.current !== output.amount?.value &&
    getSingleNextStepUsingOutput();

  useImperativeHandle(ref, () => ({
    getSideEffects: () => {
      if (canUpdateNextStepInput && updateNextStepInput) {
        const step = getSingleNextStepUsingOutput()!;
        return [{ stepId: step!.id, inputId: output.id, value: output.amount!.value }];
      }

      return [];
    },
  }));

  useEffectOnNextRenders(() => {
    const unit = (output.emission as any as EntityWithUnit)?.unit;

    if (unit) {
      formik.setFieldValue(`${arrayModel.name}.${index}.amount.unit`, unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output.emission]);

  useEffectOnNextRenders(() => {
    if (output.emission?.destination) {
      if (!getEmissionSubTypes()) {
        formik.setFieldValue(`${arrayModel.name}.${index}.emission.subType`, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [output.emission?.destination]);

  return (
    <div key={output.id} className='flex flex-col gap-2'>
      <div className='flex gap-2'>
        <Field name={`${arrayModel.name}.${index}.outputType`}>
          {(model: FieldProps<StaticEntity>) => (
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Output type</div>
              <div className='w-44'>
                <SelectV3
                  model={model}
                  menuPortalTarget={props.formRef.current}
                  options={lists.outputTypes.filter(({ type }) => !(props.outputsFinalProduct && type === OutputType.IntermediateProduct))}
                />
              </div>
            </div>
          )}
        </Field>
        {output.outputType?.type === OutputType.Emission && (
          <>
            <Field name={`${arrayModel.name}.${index}.emission`}>
              {(model: FieldProps<Entity>) => (
                <div className='flex flex-col gap-1'>
                  <div className='pl-1.5'>Substance</div>
                  <div className='w-44'>
                    <SelectV3
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      loadOptions={(input, callback) => searchEmissions(input).ok(({ emissions }) => callback(emissions))}
                    />
                  </div>
                </div>
              )}
            </Field>
            {output.emission && (
              <>
                <Field name={`${arrayModel.name}.${index}.emission.destination`}>
                  {(model: FieldProps<Entity>) => (
                    <div className='flex flex-col gap-1'>
                      <div className='pl-1.5'>Emitted into</div>
                      <div className='w-32'>
                        <SelectV3 model={model} menuPortalTarget={props.formRef.current} options={lists.emissionDestinations} />
                      </div>
                    </div>
                  )}
                </Field>
                {output.emission.destination && getEmissionSubTypes() && (
                  <Field name={`${arrayModel.name}.${index}.emission.subType`}>
                    {(model: FieldProps<Entity>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Type</div>
                        <div className='w-32'>
                          <SelectV3 model={model} menuPortalTarget={props.formRef.current} options={getEmissionSubTypes()} />
                        </div>
                      </div>
                    )}
                  </Field>
                )}
              </>
            )}
          </>
        )}
        {output.outputType?.type === OutputType.Waste && (
          <>
            <Field name={`${arrayModel.name}.${index}.waste`}>
              {(model: FieldProps<StaticEntity>) => (
                <div className='flex flex-col gap-1'>
                  <div className='pl-1.5'>Waste type</div>
                  <div className='w-36'>
                    <SelectV3
                      model={model}
                      menuPortalTarget={props.formRef.current}
                      options={lists.wasteTypes.map((type) => ({ ...type, subType: null, destination: null, packaging: null }))}
                    />
                  </div>
                </div>
              )}
            </Field>
            {output.waste && (
              <>
                {output.waste.type === WasteType.Liquid && (
                  <Field name={`${arrayModel.name}.${index}.waste.subType`}>
                    {(model: FieldProps<StaticEntity>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Sub type</div>
                        <div className='w-36'>
                          <SelectV3
                            model={model}
                            menuPortalTarget={props.formRef.current}
                            options={
                              lists.wasteTypes
                                .filter(({ type }) => type === output.waste?.type)
                                .flatMap(({ subTypes }) => subTypes)
                                .filter(
                                  ({ type }) =>
                                    typeof formik.values.process?.overrides?.water?.output?.value !== 'number' ||
                                    type !== WasteSubType.Wastewater,
                                ) ?? []
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                )}
                {[WasteType.Solid, WasteType.Packaging].includes(output.waste.type) && (
                  <Field name={`${arrayModel.name}.${index}.waste.destination`}>
                    {(model: FieldProps<StaticEntity>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Destination</div>
                        <div className='w-36'>
                          <SelectV3
                            model={model}
                            menuPortalTarget={props.formRef.current}
                            options={
                              lists.wasteTypes
                                .filter(({ type }) => type === output.waste?.type)
                                .flatMap(({ destinations }) => destinations) ?? []
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                )}
                {output.waste.type === WasteType.Packaging && (
                  <Field name={`${arrayModel.name}.${index}.waste.packaging`}>
                    {(model: FieldProps<Entity>) => (
                      <div className='flex flex-col gap-1'>
                        <div className='pl-1.5'>Packaging</div>
                        <div className='w-36'>
                          <SelectV3
                            model={model}
                            menuPortalTarget={props.formRef.current}
                            options={getPackagings(props.productFormik).map((packaging) => ({ ...packaging, name: packaging.displayName }))}
                          />
                        </div>
                      </div>
                    )}
                  </Field>
                )}
              </>
            )}
          </>
        )}
        {output.outputType &&
          output.outputType.type !== OutputType.Emission &&
          (output.outputType.type !== OutputType.Waste || output.waste?.type === WasteType.Solid) && (
            <TaggableField name={`${arrayModel.name}.${index}.name`}>
              {(model: FieldProps<string>) => (
                <div className='flex flex-col gap-1'>
                  <div className='pl-1.5'>Name</div>
                  <div className='w-36 flex flex-col'>
                    <InputV3 model={model} placeholder='Name the output…' />
                  </div>
                </div>
              )}
            </TaggableField>
          )}
        <TaggableField name={`${arrayModel.name}.${index}.amount.value`}>
          {(model: FieldProps<number>) => (
            <Field name={`${arrayModel.name}.${index}.amount.unit`}>
              {(unitModel: FieldProps<Entity>) => (
                <div className='flex flex-col gap-1'>
                  <div className='pl-1.5'>Quantity</div>
                  <div className='w-40'>
                    <UnitInputV3
                      model={model}
                      unit={(() => {
                        if (output.outputType?.type === OutputType.Emission) {
                          return { options: unitModel.field.value ? [unitModel.field.value] : [] };
                        }

                        if (output.outputType?.type === OutputType.Waste) {
                          const unit = lists.wasteTypes.find(({ type }) => type === output.waste?.type)?.unit;
                          return { options: unit ? [unit] : [] };
                        }

                        if (output.outputType) {
                          return { model: unitModel, options: lists.units };
                        }

                        return { options: [] };
                      })()}
                      placeholder={(() => {
                        if (
                          output.outputType?.type === OutputType.IntermediateProduct &&
                          formik.values.inputs.some(({ amountValue }) => typeof amountValue === 'number')
                        ) {
                          return sumBy(formik.values.inputs, ({ amountValue }) => amountValue).toString();
                        }

                        return undefined;
                      })()}
                    />
                  </div>
                </div>
              )}
            </Field>
          )}
        </TaggableField>
        {output.outputType?.type === OutputType.CoProduct && (
          <Field name={`${arrayModel.name}.${index}.economicValue.price`}>
            {(model: FieldProps<number>) => (
              <Field name={`${arrayModel.name}.${index}.economicValue.currency`}>
                {(unitModel: FieldProps<Entity>) => (
                  <div className='flex flex-col gap-1'>
                    <div className='pl-1.5'>Economic Value</div>
                    <div className='w-44'>
                      <UnitInputV3 model={model} unit={{ model: unitModel, options: lists.currencies }} />
                    </div>
                  </div>
                )}
              </Field>
            )}
          </Field>
        )}
        {(props.outputsFinalProduct ||
          index !== formik.values.outputs.findIndex(({ outputType }) => outputType?.type === OutputType.IntermediateProduct)) && (
          <div className='flex flex-col gap-1'>
            <div>&nbsp;</div>
            <button
              type='button'
              className='flex bg-[#F5F7FA] text-zinc-400 hover:text-red-500 rounded-lg justify-center items-center aspect-square h-8 p-0.5'
              onClick={arrayModel.handleRemove(index)}
            >
              <FontAwesomeIcon size='lg' icon={regular('trash-can')} />
            </button>
          </div>
        )}
      </div>
      {canUpdateNextStepInput && (
        <div className='flex gap-2 ml-2'>
          <input
            id={updateNextStepInputCheckboxId}
            type='checkbox'
            checked={updateNextStepInput}
            onChange={() => setUpdateNextStepInput((value) => !value)}
          />
          <label htmlFor={updateNextStepInputCheckboxId} className='select-none'>
            Automatically change input amount of the next step ({getSingleNextStepUsingOutput()!.displayName}) to {output.amount!.value}
            {output.amount!.unit.name}
          </label>
        </div>
      )}
    </div>
  );
});
