import { createContext, PropsWithChildren, useContext } from 'react';

export interface Limits {
  maxProductSkuCount: number;
  currentProductSkuCount: number;
  maxProductModelCount: number;
  currentProductModelCount: number;
  maxManufacturingReportCount: number;
  currentManufacturingReportCount: number;
}

const Context = createContext<Limits>({} as any);

export const useLimits = () => useContext(Context);

export const LimitsProvider = (props: PropsWithChildren<{}>) => {
  /*const { data } = useApiQuery(getLimits());

  if (!data) {
    return <></>;
  }*/

  return (
    <Context.Provider
      value={{
        maxProductSkuCount: 5,
        currentProductSkuCount: 0,
        maxProductModelCount: 20,
        currentProductModelCount: 0,
        maxManufacturingReportCount: 100,
        currentManufacturingReportCount: 0,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
