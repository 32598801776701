import { FormikContextType } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import { v4 as uuid } from 'uuid';
import * as yup from 'yup';
import {
  ConsumptionNode,
  ConsumptionPreparationNode,
  createProductV3,
  DisposalNode,
  FacilityNode,
  facilityNodeTypes,
  FinalDestinationNode,
  GenericFacilityNode,
  GenericFacilityWithStepsNode,
  GenericMovableFacilityNode,
  GenericNode,
  GenericNodeWithEdges,
  GenericRawMaterialNode,
  GenericStepNode,
  GenericSupplierNode,
  getNodeTypesForLifecycleStage,
  IngredientNode,
  IngredientType,
  IngredientV3,
  LifeCycleStage,
  Lists,
  Metadata,
  MetadataItem,
  NodeReference,
  NodeType,
  OutputType,
  PackagingNode,
  PackagingNodeMaterial,
  PrimaryNode,
  PrimaryOrMaterialSupplierNode,
  ProcessV3,
  ProductionNode,
  ProductionProcessAuxiliary,
  ProductionProcessElectricityType,
  ProductionWarehouseNode,
  ProductType,
  ProductV3,
  RawMaterialNode,
  Size,
  StaticEntity,
  StepInput,
  StepOutput,
  StorageNode,
  storageNodeTypes,
  StoredItem,
  StoreNode,
  Tag,
  TransportNode,
  visibleTags,
  WarehouseNode,
  WasteType,
} from '../../../../api';
import { ModalFormSaveParams } from '../../../../components/ModalForm';
import { getOrderedTagToBadgeMappings } from './Badges';

export const defaultGrids = {
  production: {
    width: 1,
    height: 2,
  },
  distribution: {
    width: 2,
    height: 2,
  },
  productionFacility: {
    width: 4,
    height: 3,
  },
  consumptionLocation: {
    width: 4,
    height: 3,
  },
};

export const createProduct = () =>
  createProductV3({
    layoutGrids: defaultGrids,
  });

const newId = (type: string) => `${type}.${uuid().replaceAll('-', '')}`;

export const newNodeId = () => newId('node');

export const newStepId = () => newId('step');

export const newOutputId = () => newId('output');

const percentPrecision = Math.pow(10, 2);

const roundPercent = (value: number) => Math.round(value * percentPrecision) / percentPrecision;

export const roundAmount = (value: number) => {
  const defaultPrecision = Math.pow(10, 1);
  const belowOnePrecision = Math.pow(10, 5);

  if (value < 1) {
    return Math.round(value * belowOnePrecision) / belowOnePrecision;
  }

  return Math.round(value * defaultPrecision) / defaultPrecision;
};

export const is100Percent = (values: number[]) => {
  return sumBy(values.map((value) => Math.round(value * percentPrecision))) === 100 * percentPrecision;
};

export const shouldAutoAdjustSplit = (
  node: GenericRawMaterialNode,
  subNode: { id: string },
  formik: FormikContextType<ProductV3>,
  subNodes: string,
  field: string,
) =>
  formik.values.metadata.user.some(
    ({ path, tags }) => path === `nodes/${node.id}/${subNodes}/${subNode.id}/${field}` && tags.includes(Tag.Default),
  );

export const adjustSupplierSplits = <T extends GenericRawMaterialNode>(
  formik: FormikContextType<T>,
  editedSupplier?: GenericSupplierNode,
) => {
  formik.setValues((oldValues) => {
    const newValues = cloneDeep(oldValues);
    const nodes = newValues.nodes as (GenericSupplierNode & { autoAdjustSplit: boolean })[];

    if (editedSupplier) {
      nodes.find(({ id }) => id === editedSupplier.id)!.autoAdjustSplit = false;
    }

    const autoAdjustedNodes = nodes.filter(({ autoAdjustSplit }) => autoAdjustSplit);
    const adjustableTotal =
      100 -
      sumBy(
        nodes.filter((node) => !autoAdjustedNodes.some(({ id }) => id === node.id)),
        ({ splitPercent }) => splitPercent ?? 0,
      );
    const adjustedSplitPercent = roundPercent(adjustableTotal / autoAdjustedNodes.length);

    if (adjustedSplitPercent > 0) {
      autoAdjustedNodes.forEach((node) => (node.splitPercent = adjustedSplitPercent));

      if (autoAdjustedNodes.length > 0) {
        last(autoAdjustedNodes)!.splitPercent = roundPercent(
          last(autoAdjustedNodes)!.splitPercent + adjustableTotal - adjustedSplitPercent * autoAdjustedNodes.length,
        );
      }
    } else {
      autoAdjustedNodes.forEach((node) => ((node as any).splitPercent = ''));
    }

    return newValues;
  });
};

export const adjustMaterialSplits = (formik: FormikContextType<PackagingNode>, editedMaterial?: PackagingNodeMaterial) => {
  formik.setValues((oldValues) => {
    const newValues = cloneDeep(oldValues);
    const materials = newValues.materials as (PackagingNodeMaterial & { autoAdjustSplit: boolean })[];

    if (editedMaterial) {
      materials.find(({ id }) => id === editedMaterial.id)!.autoAdjustSplit = false;
    }

    const autoAdjustedMaterials = materials.filter(({ autoAdjustSplit }) => autoAdjustSplit);
    const adjustableTotal =
      100 -
      sumBy(
        materials.filter((material) => !autoAdjustedMaterials.some(({ id }) => id === material.id)),
        ({ compositionPercent }) => compositionPercent ?? 0,
      );
    const adjustedCompositionPercent = roundPercent(adjustableTotal / autoAdjustedMaterials.length);

    if (adjustedCompositionPercent > 0) {
      autoAdjustedMaterials.forEach((material) => (material.compositionPercent = adjustedCompositionPercent));

      if (autoAdjustedMaterials.length > 0) {
        last(autoAdjustedMaterials)!.compositionPercent = roundPercent(
          last(autoAdjustedMaterials)!.compositionPercent + adjustableTotal - adjustedCompositionPercent * autoAdjustedMaterials.length,
        );
      }
    } else {
      autoAdjustedMaterials.forEach((material) => ((material as any).compositionPercent = ''));
    }

    return newValues;
  });
};

export const getCookedAmount = (
  formik: FormikContextType<ProductV3>,
  consumption: ConsumptionNode,
  editedStep?: FormikContextType<ConsumptionPreparationNode>,
) => ({
  value:
    formik.values.amount.value +
    sumBy(
      [...consumption.steps.filter(({ id }) => id !== editedStep?.values.id), ...(editedStep ? [editedStep.values] : [])]
        .flatMap(({ inputs }) => inputs)
        .filter(({ item }) => (item as IngredientV3)?.type === IngredientType.Consumer),
      ({ amountValue }) => amountValue ?? 0,
    ),
  unit: formik.values.amount.unit,
});

export const canApplyDefaults = (formik: FormikContextType<ProductV3>) =>
  getRawMaterials(formik).length > 0 &&
  !formik.values.nodes.some(({ type }) =>
    [LifeCycleStage.Distribution, LifeCycleStage.Use, LifeCycleStage.EndOfLife].flatMap(getNodeTypesForLifecycleStage).includes(type),
  );

export const isPlaceholder = (node?: GenericNode) =>
  !!(
    node &&
    ((node as IngredientNode).ingredient?.placeholder ||
      (node as PackagingNode).packaging?.placeholder ||
      (node as GenericStepNode).process?.placeholder)
  );

export const getNodeMetadataItems = (node: GenericNode, formik: FormikContextType<ProductV3>) =>
  formik.values.metadata.system.filter(({ path }) => ['nodes', 'steps'].some((prefix) => path.endsWith(`${prefix}/${node.id}`)));

export const getSubNodeMetadataItems = (node: GenericNode, formik: FormikContextType<ProductV3>) =>
  formik.values.metadata.system.filter(({ path }) => ['steps', 'items'].some((suffix) => path.endsWith(`${node.id}/${suffix}`)));

export const getMetadataOfSpecificTarget = (node: GenericNode, formik: FormikContextType<ProductV3>, scope: string) => {
  return formik.values.metadata.user.filter(({ path }) => {
    return path.endsWith(`${node.id}/${scope}`);
  });
};

export const getPrimaryTag = (node: GenericNode, formik: FormikContextType<ProductV3>) =>
  getPrimaryTagFromItems(getNodeMetadataItems(node, formik));

export const getPrimaryTagFromItems = (items: MetadataItem[]): Tag | undefined =>
  sortBy(
    items.flatMap(({ tags }) => tags).filter((tag) => visibleTags.includes(tag)),
    (tag) => getOrderedTagToBadgeMappings().findIndex((mapping) => mapping.tag === tag),
  )[0];

export const getValidationMessages = (formik: FormikContextType<ProductV3>, options: { ignoreLessImportant: boolean }) => [
  ...formik.values.validation.warnings.filter(({ showAlways }) => !options.ignoreLessImportant || showAlways),
  ...formik.values.validation.errors,
];

export const hasValidationMessage = (node: GenericNode, formik: FormikContextType<ProductV3>, options: { ignoreLessImportant: boolean }) =>
  getValidationMessages(formik, options).some(({ nodeId }) => nodeId === node.id);

export const sizeToPositions = (size: Size) => range(0, size.height).flatMap((y) => range(0, size.width).map((x) => ({ x, y })));

export const sortAndFilterNodes = <T extends GenericNode>(
  nodes: T[],
  formik: FormikContextType<ProductV3>,
  options: { ignoreLessImportantValidation: boolean },
) =>
  orderBy(
    nodes.filter(({ displayName }) => displayName),
    [
      (node) =>
        getRawMaterials(formik).some(({ id }) => id === node.id)
          ? hasValidationMessage(node, formik, { ignoreLessImportant: options.ignoreLessImportantValidation })
          : '',
      (node) => (getRawMaterials(formik).some(({ id }) => id === node.id) ? (node as any as GenericRawMaterialNode).amount.value : ''),
      ({ displayName }) => displayName,
    ],
    ['desc', 'desc', 'asc'],
  );

export const getTransportsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Transport) as TransportNode[];

export const getTransports = (formik: FormikContextType<ProductV3>) => getTransportsFromAll(formik.values.nodes);

export const getConnectedTransports = (node: GenericNodeWithEdges, nodes: PrimaryNode[]) => [
  ...getTransportsFromAll(nodes).filter(({ edges }) => edges.includes(node.id)),
  ...getTransportsFromAll(nodes).filter(({ id }) => node.edges.includes(id)),
];

export const getIngredientsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Ingredient) as IngredientNode[];

export const getIngredients = (formik: FormikContextType<ProductV3>) => getIngredientsFromAll(formik.values.nodes);

export const getPackagingsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Packaging) as PackagingNode[];

export const getPackagings = (formik: FormikContextType<ProductV3>) => getPackagingsFromAll(formik.values.nodes);

export const getRawMaterialsFromAll = (nodes: PrimaryNode[]) => [...getIngredientsFromAll(nodes), ...getPackagingsFromAll(nodes)];

export const getRawMaterials = (formik: FormikContextType<ProductV3>) => getRawMaterialsFromAll(formik.values.nodes);

export const getRawMaterialSuppliersFromAll = (nodes: PrimaryNode[]) => [
  ...getIngredientsFromAll(nodes).flatMap(({ nodes }) => nodes),
  ...getPackagingsFromAll(nodes).flatMap(({ nodes }) => nodes),
];

export const getRawMaterialSuppliers = (formik: FormikContextType<ProductV3>) => getRawMaterialSuppliersFromAll(formik.values.nodes);

export const getPrimaryAndMaterialSuppliersFromAll = (nodes: PrimaryNode[]) => [...nodes, ...getRawMaterialSuppliersFromAll(nodes)];

export const getPrimaryAndMaterialSuppliers = (formik: FormikContextType<ProductV3>): PrimaryOrMaterialSupplierNode[] =>
  getPrimaryAndMaterialSuppliersFromAll(formik.values.nodes);

export const getFacilityNodesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => facilityNodeTypes.includes(type)) as GenericFacilityNode[];

export const getFacilityNodes = (formik: FormikContextType<ProductV3>) => getFacilityNodesFromAll(formik.values.nodes);

const getStorageNodesFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => storageNodeTypes.includes(type)) as StorageNode[];

export const getStorageNodes = (formik: FormikContextType<ProductV3>) => getStorageNodesFromAll(formik.values.nodes);

export const getProductionFacilitiesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.Production) as ProductionNode[];

export const getProductionFacilities = (formik: FormikContextType<ProductV3>) => getProductionFacilitiesFromAll(formik.values.nodes);

export const getProductionWarehousesFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.ProductionWarehouse) as ProductionWarehouseNode[];

export const getProductionWarehouses = (formik: FormikContextType<ProductV3>) => getProductionWarehousesFromAll(formik.values.nodes);

const getWarehousesFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Warehouse) as WarehouseNode[];

export const getWarehouses = (formik: FormikContextType<ProductV3>) => getWarehousesFromAll(formik.values.nodes);

const getStoresFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Store) as StoreNode[];

export const getStores = (formik: FormikContextType<ProductV3>) => getStoresFromAll(formik.values.nodes);

const getFinalDestinationsFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.FinalDestination) as FinalDestinationNode[];

export const getFinalDestinations = (formik: FormikContextType<ProductV3>) => getFinalDestinationsFromAll(formik.values.nodes);

export const getProductionMovableNodesFromAll = (nodes: PrimaryNode[]) => [
  ...getProductionFacilitiesFromAll(nodes),
  ...getProductionWarehousesFromAll(nodes),
];

export const getDistributionMovableNodesFromAll = (nodes: PrimaryNode[]) => [
  ...getWarehousesFromAll(nodes),
  ...getStoresFromAll(nodes),
  ...getFinalDestinationsFromAll(nodes),
];

export const getMovableNodesFromAll = (nodes: PrimaryNode[]) => [
  ...getProductionMovableNodesFromAll(nodes),
  ...getDistributionMovableNodesFromAll(nodes),
];

export const getConsumptionLocationsFromAll = (nodes: PrimaryNode[]) =>
  nodes.filter(({ type }) => type === NodeType.Consumption) as ConsumptionNode[];

export const getConsumptionLocations = (formik: FormikContextType<ProductV3>) => getConsumptionLocationsFromAll(formik.values.nodes);

export const getDisposalsFromAll = (nodes: PrimaryNode[]) => nodes.filter(({ type }) => type === NodeType.Disposal) as DisposalNode[];

export const getDisposals = (formik: FormikContextType<ProductV3>) => getDisposalsFromAll(formik.values.nodes);

export const getFacilitiesWithStepsFromAll = (nodes: PrimaryNode[]) =>
  [...getProductionFacilitiesFromAll(nodes), ...getConsumptionLocationsFromAll(nodes)] as GenericFacilityWithStepsNode[];

export const getFacilitiesWithSteps = (formik: FormikContextType<ProductV3>) => getFacilitiesWithStepsFromAll(formik.values.nodes);

export const getStepsFromAll = (nodes: PrimaryNode[]) => getFacilitiesWithStepsFromAll(nodes).flatMap(({ steps }) => steps);

export const getSteps = (formik: FormikContextType<ProductV3>) => getStepsFromAll(formik.values.nodes);

export const getOutputsFromAll = (nodes: PrimaryNode[]) => getStepsFromAll(nodes).flatMap(({ outputs }) => outputs);

export const getOutputs = (formik: FormikContextType<ProductV3>) => getOutputsFromAll(formik.values.nodes);

export const flattenAllNodes = (nodes: PrimaryNode[]) => [
  ...getStepsFromAll(nodes),
  ...getOutputsFromAll(nodes),
  ...getPrimaryAndMaterialSuppliersFromAll(nodes),
];

export const getInputNode = (input: StepInput, formik: FormikContextType<ProductV3>) => {
  if (input.item) {
    return { ...input.item, amount: { ...input.item, value: input.amountValue } };
  }

  const rawMaterial = getRawMaterials(formik).find(({ id }) => id === input.id);
  return (
    input.id === formik.values.id
      ? {
          ...formik.values,
          name: 'Final product',
        }
      : rawMaterial
      ? { ...rawMaterial, name: rawMaterial.displayName }
      : getOutputs(formik).find(({ id }) => id === input.id)
  )!;
};

export const isUsedInProcess = (node: PackagingNode, process: ProcessV3, lists: Lists) =>
  lists.packagingTypes.find(({ id }) => id === node.packaging.id)!.packagingProcesses.includes(process.id);

export const commentSchema = () => ({
  comment: yup.string().when('placeholder', {
    is: true,
    then: (schema) => schema.required(),
  }),
});

export const stepValidationSchema = (params: {
  productionNode?: ProductionNode;
  packaging: boolean;
  productFormik: FormikContextType<ProductV3>;
  lists: Lists;
}) =>
  yup.object().shape({
    process: params.productionNode
      ? yup.object().shape({
          ...commentSchema(),
          overrides: yup.object().shape({
            electricity: yup
              .object()
              .nullable()
              .default(null)
              .shape({
                value: yup.number().min(0).nullable(),
                types: yup
                  .array()
                  .when({
                    is: (value: ProductionProcessElectricityType[]) => value.length > 1 || value[0]?.type || value[0]?.percent,
                    then: (schema) =>
                      schema.of(
                        yup.object().shape({
                          type: yup.string().required(),
                          percent: yup.number().positive().max(100).required(),
                        }),
                      ),
                  })
                  .test('', 'splitsNot100', function () {
                    const parent = this.parent as { types: { percent: number }[] };
                    return (
                      !parent.types.every(({ percent }) => typeof percent === 'number') ||
                      is100Percent(parent.types.map(({ percent }) => percent))
                    );
                  }),
              })
              .test(
                '',
                'missingValueOrTypes',
                (electricity: { value: any; types?: ProductionProcessElectricityType[] } | null) =>
                  (typeof electricity!.value === 'number' &&
                    (electricity!.types!.length > 1 || !!electricity!.types![0]?.type || !!electricity!.types![0]?.percent)) ||
                  (typeof electricity!.value !== 'number' &&
                    electricity!.types!.length === 1 &&
                    !electricity!.types![0].type &&
                    !electricity!.types![0].percent),
              ),
            gas: yup
              .object()
              .nullable()
              .default(null)
              .shape({
                value: yup.number().min(0).nullable(),
              }),
            water: yup
              .object()
              .nullable()
              .default(null)
              .shape({
                input: yup
                  .object()
                  .nullable()
                  .default(null)
                  .shape({
                    value: yup.number().min(0).nullable(),
                  }),
              }),
            auxiliaries: yup.array().when({
              is: (value: ProductionProcessAuxiliary[]) => value.length > 1 || value[0]?.type || typeof value[0]?.value === 'number',
              then: (schema) =>
                schema.of(
                  yup.object().shape({
                    type: yup.string().required(),
                    value: yup.number().min(0).required(),
                  }),
                ),
            }),
          }),
        })
      : yup.object().shape(commentSchema()).required(),
    inputs: yup
      .array()
      .min(1)
      .test(
        '',
        'noPackagingInput',
        (inputs?: NodeReference[]) =>
          !params.packaging || inputs!.some((input) => getPackagings(params.productFormik).some(({ id }) => id === input.id)),
      )
      .test(
        '',
        'noNonPackagingInput',
        (inputs?: NodeReference[]) =>
          !params.packaging || inputs!.some((input) => !getPackagings(params.productFormik).some(({ id }) => id === input.id)),
      )
      .test('', 'needsPackagingUsedInProcess', (inputs?: NodeReference[], context?: yup.TestContext) => {
        const process = params.lists.processes.find(({ id }) => id === (context!.parent as GenericStepNode).process?.id);
        return (
          !params.packaging ||
          !process ||
          inputs!.some((input) => {
            const packaging = getPackagings(params.productFormik).find(({ id }) => id === input.id);
            return packaging && isUsedInProcess(packaging, process, params.lists);
          })
        );
      })
      .of(
        yup.object().shape({
          id: yup.string().required(),
          amountValue: yup.number().positive().required(),
        }),
      ),
    outputs: yup
      .array()
      .when('finalStep', {
        is: false,
        then: (schema) => schema.min(1),
      })
      .test('', 'needsAtLeastOneIntermediate', function () {
        const step = this.parent as GenericStepNode;
        return (
          (step.finalStep && (params.productionNode?.finalFacility ?? true)) ||
          step.outputs.some(({ outputType }) => outputType?.type === OutputType.IntermediateProduct)
        );
      })
      .of(
        yup.object().shape({
          outputType: yup.object().required(),
          amount: yup.object().when('outputType.type', {
            is: (type: OutputType) => [OutputType.Emission, OutputType.Waste].includes(type),
            then: (schema) =>
              schema.shape({
                value: yup
                  .number()
                  .min(0)
                  .nullable()
                  .test('', 'required', (value, context) => {
                    const step = (context as any).from[2].value as GenericStepNode;
                    const output = (context as any).from[1].value as StepOutput;
                    const process = params.lists.processes.find(({ id }) => id === step.process?.id);

                    if (
                      !params.productionNode ||
                      !process?.overrides?.water ||
                      step.outputs.findIndex(({ id }) => id === output.id) !== 0
                    ) {
                      return !!value;
                    }

                    return true;
                  }),
              }),
            otherwise: (schema) =>
              schema.shape({
                value: yup.number().positive().required(),
                unit: yup.object().required(),
              }),
          }),
          economicValue: yup.object().when('outputType.type', {
            is: OutputType.CoProduct,
            then: (schema) =>
              schema.shape({
                price: yup.number().positive().required(),
                currency: yup.object().required(),
              }),
            otherwise: (schema) => schema.nullable(),
          }),
          emission: yup.object().when('outputType.type', {
            is: OutputType.Emission,
            then: (schema) =>
              schema.shape({
                destination: yup.object().required(),
                subType: yup.object().when('destination', {
                  is: (value: StaticEntity) =>
                    params.lists.emissionDestinations.some(({ type, subTypes }) => type === value?.type && subTypes),
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
              }),
            otherwise: (schema) => schema.nullable(),
          }),
          name: yup.string().when(['outputType.type', 'waste.type'], {
            is: (outputType: OutputType, wasteType: WasteType) =>
              outputType !== OutputType.Emission && (outputType !== OutputType.Waste || wasteType === WasteType.Solid),
            then: (schema) => schema.required(),
          }),
          waste: yup.object().when('outputType.type', {
            is: OutputType.Waste,
            then: (schema) =>
              schema.shape({
                type: yup.string().required(),
                subType: yup.object().when('type', {
                  is: WasteType.Liquid,
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
                destination: yup.object().when('type', {
                  is: (type: WasteType) => [WasteType.Solid, WasteType.Packaging].includes(type),
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
                packaging: yup.object().when('type', {
                  is: WasteType.Packaging,
                  then: (schema) => schema.required(),
                  otherwise: (schema) => schema.nullable(),
                }),
              }),
            otherwise: (schema) => schema.nullable(),
          }),
        }),
      ),
  });

export interface StepInputAmountSideEffect {
  stepId: string;
  inputId: string;
  value: number;
}

export interface UpdateSideEffects {
  stepInputAmounts: StepInputAmountSideEffect[];
}

export const addReplaceTransport = (node: TransportNode, metadata: Metadata, fromId: string, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);

    getPrimaryAndMaterialSuppliersFromAll(newValues.nodes).forEach((n) => {
      n.edges = n.edges.filter((edgeId) => edgeId !== node.id);
    });
    getPrimaryAndMaterialSuppliersFromAll(newValues.nodes)
      .find(({ id }) => id === fromId)!
      .edges.push(node.id);

    newValues.metadata = metadata;
    newValues.nodes = [...newValues.nodes.filter(({ id }) => id !== node.id), node];
    graphCleanup(newValues);

    return newValues;
  });
};

export const add = (node: PrimaryNode, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    newValues.nodes = [...newValues.nodes, node];
    assignLayoutPositions(newValues, [node]);
    return newValues;
  });
};

export const addAll = (nodes: PrimaryNode[], formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    newValues.nodes = [...newValues.nodes, ...nodes];
    assignLayoutPositions(newValues, nodes);
    return newValues;
  });
};

export const copyRawMaterialToProduct = (node: RawMaterialNode, product: ProductV3) => {
  const newNode = cloneDeep(node);
  newNode.id = newNodeId();
  delete newNode.index;
  newNode.displayName = '';
  newNode.nodes.forEach((supplier) => {
    supplier.id = newNodeId();
    supplier.edges = [];
  });
  product.nodes = [...product.nodes, newNode];
};

export const duplicate = (node: RawMaterialNode, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    copyRawMaterialToProduct(node, newValues);
    return newValues;
  });
};

export const replace = (
  node: PrimaryNode,
  metadata: Metadata,
  sideEffects: UpdateSideEffects | undefined,
  formik: FormikContextType<ProductV3>,
) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    const oldNode = newValues.nodes.find(({ id }) => id === node.id)!;

    newValues.metadata = metadata;
    newValues.nodes = [...newValues.nodes.filter(({ id }) => id !== node.id), node];

    if (
      getFacilityNodesFromAll(newValues.nodes).some(({ id }) => id === node.id) &&
      (oldNode as FacilityNode).facility.id !== (node as FacilityNode).facility.id
    ) {
      getConnectedTransports(node, newValues.nodes).forEach((transport) => {
        transport.legs = [];
      });
    }

    if (getRawMaterialsFromAll(newValues.nodes).some(({ id }) => id === node.id)) {
      const oldSuppliers = (oldNode as GenericRawMaterialNode).nodes;
      const suppliers = (node as GenericRawMaterialNode).nodes;

      suppliers
        .filter((supplier) => {
          const oldSupplier = oldSuppliers.find(({ id }) => id === supplier.id);
          return oldSupplier && oldSupplier.location.id !== supplier.location.id;
        })
        .forEach((supplier) => {
          getConnectedTransports(supplier, newValues.nodes).forEach((transport) => {
            transport.legs = [];
          });
        });

      if (oldSuppliers.length === 1 && oldSuppliers[0].edges.length === 1 && suppliers.length === 1 && suppliers[0].edges.length === 0) {
        suppliers[0].edges = oldSuppliers[0].edges;

        getConnectedTransports(suppliers[0], newValues.nodes).forEach((transport) => {
          transport.legs = [];
        });
      }
    }

    sideEffects?.stepInputAmounts.forEach(({ stepId, inputId, value }) => {
      getStepsFromAll(newValues.nodes)
        .find(({ id }) => id === stepId)!
        .inputs.find(({ id }) => id === inputId)!.amountValue = value;
    });

    graphCleanup(newValues);

    return newValues;
  });
};

export const remove = (node: PrimaryNode, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    newValues.nodes = newValues.nodes.filter(({ id }) => id !== node.id);
    graphCleanup(newValues);
    return newValues;
  });
};

export const addStep = (step: GenericStepNode, facility: GenericFacilityWithStepsNode, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    const facilityForUpdate = getFacilitiesWithStepsFromAll(newValues.nodes).find(({ id }) => id === facility.id)!;

    facilityForUpdate.steps = [...facilityForUpdate.steps, step];
    graphCleanup(newValues);
    assignStepLayoutPosition(facilityForUpdate, step);

    return newValues;
  });
};

export const replaceStep = (
  step: GenericStepNode,
  metadata: Metadata,
  sideEffects: UpdateSideEffects,
  facility: GenericFacilityWithStepsNode,
  formik: FormikContextType<ProductV3>,
) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    const facilityForUpdate = getFacilitiesWithStepsFromAll(newValues.nodes).find(({ id }) => id === facility.id)!;

    newValues.metadata = metadata;
    facilityForUpdate.steps = [...facilityForUpdate.steps.filter(({ id }) => id !== step.id), step];
    sideEffects.stepInputAmounts.forEach(({ stepId, inputId, value }) => {
      facilityForUpdate.steps.find(({ id }) => id === stepId)!.inputs.find(({ id }) => id === inputId)!.amountValue = value;
    });
    graphCleanup(newValues);

    return newValues;
  });
};

export const removeStep = (step: GenericStepNode, facility: GenericFacilityWithStepsNode, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);
    const facilityForUpdate = getFacilitiesWithStepsFromAll(newValues.nodes).find(({ id }) => id === facility.id)!;

    facilityForUpdate.steps = facilityForUpdate.steps.filter(({ id }) => id !== step.id);
    graphCleanup(newValues);

    return newValues;
  });
};

export const removeStoredItem = (item: StoredItem, storage: StorageNode, formik: FormikContextType<ProductV3>) => {
  formik.setValues((values) => {
    const newValues = cloneDeep(values);

    getTransportsFromAll(newValues.nodes)
      .filter(({ items }) => items.some(({ id }) => id === item.id))
      .filter(({ edges }) => edges.includes(storage.id))
      .forEach((node) => {
        node.items = node.items.filter(({ id }) => id !== item.id);
      });
    graphCleanup(newValues);

    return newValues;
  });
};

const assignLayoutPositions = (values: ProductV3, nodes: PrimaryNode[]) => {
  (nodes as GenericMovableFacilityNode[]).forEach((node) => {
    [
      { gridNodes: getProductionMovableNodesFromAll(values.nodes), grid: values.layoutGrids.production },
      { gridNodes: getDistributionMovableNodesFromAll(values.nodes), grid: values.layoutGrids.distribution },
    ].forEach(({ gridNodes, grid }) => {
      if (gridNodes.some(({ id }) => id === node.id)) {
        let firstEmptyRowIndex = range(0, grid.height).find((y) => !gridNodes.some(({ layoutPosition }) => layoutPosition?.y === y));

        if (firstEmptyRowIndex === undefined) {
          firstEmptyRowIndex = grid.height++;
        }

        node.layoutPosition = { x: 0, y: firstEmptyRowIndex };
      }
    });
  });
};

const assignStepLayoutPosition = (node: GenericFacilityWithStepsNode, step: GenericStepNode) => {
  let firstEmptyColumnIndex = range(0, node.layoutGrid.width).find(
    (x) => !node.steps.some(({ layoutPosition }) => layoutPosition?.x === x),
  );

  if (firstEmptyColumnIndex === undefined) {
    firstEmptyColumnIndex = node.layoutGrid.width++;
  }

  step.layoutPosition = { x: firstEmptyColumnIndex, y: 0 };
};

const graphCleanup = (values: ProductV3) => {
  // 1. remove primary edges, nodes and transports
  // order matters
  getPrimaryAndMaterialSuppliersFromAll(values.nodes).forEach((node) => {
    node.edges = node.edges.filter((edgeId) => getPrimaryAndMaterialSuppliersFromAll(values.nodes).some(({ id }) => id === edgeId));
  });
  getProductionFacilitiesFromAll(values.nodes).forEach((productionFacility) => {
    productionFacility.edges = productionFacility.edges.filter((productionFacilityEdgeId) =>
      getTransportsFromAll(values.nodes)
        .filter(({ id }) => id === productionFacilityEdgeId)
        .flatMap(({ edges }) => edges)
        .flatMap((id) => values.nodes.filter((node) => node.id === id))
        .some(({ type }) =>
          (productionFacility.finalFacility
            ? [
                ...(values.productType === ProductType.Internal ? [NodeType.ProductionWarehouse] : []),
                ...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution),
                ...getNodeTypesForLifecycleStage(LifeCycleStage.Use),
              ]
            : getNodeTypesForLifecycleStage(LifeCycleStage.Production)
          ).includes(type),
        ),
    );
  });
  values.nodes = values.nodes.filter(
    (node) =>
      node.type !== NodeType.Transport ||
      (node.edges.length > 0 && getPrimaryAndMaterialSuppliersFromAll(values.nodes).some(({ edges }) => edges.includes(node.id))),
  );
  getPrimaryAndMaterialSuppliersFromAll(values.nodes).forEach((node) => {
    node.edges = node.edges.filter((edgeId) => getPrimaryAndMaterialSuppliersFromAll(values.nodes).some(({ id }) => id === edgeId));
  });

  // 2. remove outputs, items, empty transports and inputs
  // order matters
  getStepsFromAll(values.nodes).forEach((step) => {
    const productionFacility = getProductionFacilitiesFromAll(values.nodes).find(({ steps }) => steps.some(({ id }) => id === step.id));
    step.outputs = step.outputs.filter(({ outputType, waste }) => {
      if (productionFacility) {
        if (productionFacility.finalFacility && step.finalStep) {
          return outputType.type !== OutputType.IntermediateProduct;
        }
      } else {
        if (step.finalStep) {
          return outputType.type !== OutputType.IntermediateProduct;
        }
      }

      if (outputType.type === OutputType.Waste && waste?.type === WasteType.Packaging) {
        return getPackagingsFromAll(values.nodes).some(({ id }) => id === waste.packaging.id);
      }

      return true;
    });
  });
  getTransportsFromAll(values.nodes).forEach((transport) => {
    transport.items = transport.items.filter((item) => {
      const output = getOutputsFromAll(values.nodes).find(({ id }) => id === item.id);

      if (output) {
        return output.outputType.type === OutputType.IntermediateProduct;
      }

      return getRawMaterialsFromAll(values.nodes).some(({ id }) => id === item.id);
    });
  });
  const isTransportedToFacility = (item: { id: string }, facility: GenericFacilityNode) =>
    getTransportsFromAll(values.nodes)
      .filter(({ items }) => items.some(({ id }) => id === item.id))
      .flatMap(({ edges }) => edges)
      .some((id) => id === facility.id);
  getProductionWarehousesFromAll(values.nodes).forEach((warehouse) => {
    warehouse.items = warehouse.items.filter((item) => item.id === values.id || isTransportedToFacility(item, warehouse));
  });
  getTransportsFromAll(values.nodes).forEach((transport) => {
    const fromProductionWarehouse = getProductionWarehousesFromAll(values.nodes).find(({ edges }) => edges.includes(transport.id));

    if (fromProductionWarehouse) {
      transport.items = transport.items.filter((item) => fromProductionWarehouse.items.some(({ id }) => id === item.id));
    }
  });
  const isTransportingFinalProduct = (transport: TransportNode) =>
    transport.edges.every(
      (edgeId) =>
        values.nodes
          .filter((node) =>
            [...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution), ...getNodeTypesForLifecycleStage(LifeCycleStage.Use)].includes(
              node.type,
            ),
          )
          .some(({ id }) => id === edgeId) ||
        getProductionFacilitiesFromAll(values.nodes)
          .filter(({ finalFacility }) => finalFacility)
          .flatMap(({ edges }) => edges)
          .some((edgeId) => edgeId === transport.id),
    );
  values.nodes = values.nodes.filter(
    (node) => node.type !== NodeType.Transport || isTransportingFinalProduct(node) || node.items.length > 0,
  );
  getStepsFromAll(values.nodes).forEach((step) => {
    const facility = getFacilitiesWithStepsFromAll(values.nodes).find(({ steps }) => steps.some(({ id }) => id === step.id))!;
    const isConsumptionLocation = () =>
      getConsumptionLocationsFromAll(values.nodes).some(({ steps }) => steps.some(({ id }) => id === step.id));

    step.inputs = step.inputs.filter((input) => {
      if (input.item) {
        return true;
      }

      const output = getOutputsFromAll(values.nodes).find(({ id }) => id === input.id);
      const rawMaterial = getRawMaterialsFromAll(values.nodes).find(({ id }) => id === input.id);
      const isSameFacilityOutput = () => facility.steps.flatMap(({ outputs }) => outputs).some(({ id }) => id === output?.id);

      if (output) {
        return (
          output.outputType.type === OutputType.IntermediateProduct &&
          (isConsumptionLocation() || isSameFacilityOutput() || isTransportedToFacility(input, facility))
        );
      }

      if (rawMaterial) {
        return (
          isConsumptionLocation() ||
          (rawMaterial.type === NodeType.Ingredient && rawMaterial.localSupply) ||
          isTransportedToFacility(input, facility)
        );
      }

      return input.id === values.id;
    });
  });
};

export const onSaveTransport = (params: ModalFormSaveParams<TransportNode, { fromId: string }>, formik: FormikContextType<ProductV3>) => {
  addReplaceTransport(params.values, params.metadata!, params.fromId, formik);
  params.closeModal();
};

export const onAdd = (params: ModalFormSaveParams<PrimaryNode>, formik: FormikContextType<ProductV3>) => {
  add(params.values, formik);
  params.closeModal();
};

export const onMultiAdd = (params: ModalFormSaveParams<PrimaryNode[]>, formik: FormikContextType<ProductV3>) => {
  addAll(params.values, formik);
  params.closeModal();
};

export const onEdit = (
  params: ModalFormSaveParams<PrimaryNode, { sideEffects?: UpdateSideEffects }>,
  formik: FormikContextType<ProductV3>,
) => {
  replace(params.values, params.metadata!, params.sideEffects, formik);
  params.closeModal();
};

export const onEditFromMulti = (params: ModalFormSaveParams<PrimaryNode[]>, formik: FormikContextType<ProductV3>) => {
  replace(params.values[0], params.metadata!, undefined, formik);
  params.closeModal();
};
