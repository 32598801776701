import { CreateResponse, request } from '.';
import { ProductV3 } from './productV3';

export interface ProductModelV3 extends ProductV3 {
  title: string;
}

export enum ImpactDeltaType {
  Lower = 'lower',
  Higher = 'higher',
  Zero = 'zero',
}

export interface ImpactDelta {
  raw: number;
  formatted: string;
  display: string;
  type: ImpactDeltaType;
}

export interface ProductModelListItem {
  id: string;
  author: string;
  title: string;
  updatedAt: string;
  createdAt: string;
  proposedChanges: number;
  impactDelta: ImpactDelta;
}

export enum ModellingChangeAction {
  Added = 'added',
  Updated = 'updated',
  Deleted = 'deleted',
}

export enum ModellingBadgeType {
  HighImpact = 'high_impact',
}

export interface ModellingImpact {
  original: number;
  model: number;
  modelImpactPoints: number;
  originalImpactPoints: number;
  impactDelta: ImpactDelta;
  categories: {
    id: string;
    name: string;
    original: number;
    model: number;
    impactDelta: ImpactDelta;
    originalPhysical: number;
    modelPhysical: number;
    impactDeltaPhysical: ImpactDelta;
    unit: string;
  }[];
}

interface ModellingChangeBase {
  id: string;
  action: ModellingChangeAction;
}

export interface ModellingChange<SC = object> extends ModellingChangeBase {
  impactDelta: ImpactDelta;
  subChanges: Record<keyof SC, ModellingChangeBase[]>;
}

export interface ModellingBadge {
  id: string;
  type: ModellingBadgeType;
}

export interface MajorContributor {
  id: string;
  parents: string[];
  name: string;
}

export interface ModellingPayload {
  product: ProductV3;
  model: ProductModelV3;
  impact: ModellingImpact;
  changes: ModellingChange[];
  badges: ModellingBadge[];
  majorContributors: MajorContributor[];
}

export const getProductModelsV3 = (productId: string) =>
  request<{ models: ProductModelListItem[] }>('GET', `/v3/products/${productId}/models`);
export const getProductModelV3 = (productId: string, id: string) =>
  request<ModellingPayload>('GET', `/v3/products/${productId}/models/${id}`);
export const createProductModelV3 = (productId: string) =>
  request<CreateResponse<ModellingPayload>>('POST', `/v3/products/${productId}/models`, { body: {} });
export const updateProductModelV3 = (payload: ModellingPayload) =>
  request<ModellingPayload>('PUT', `/v3/products/${payload.product.id}/models/${payload.model.id}`, { body: { model: payload.model } });
export const calculateProductModelV3 = (payload: ModellingPayload) =>
  request<ModellingPayload>('POST', `/v3/products/${payload.product.id}/models/${payload.model.id}/calculator`, {
    body: { model: payload.model },
  });
export const deleteProductModelV3 = (productId: string, id: string) => request('DELETE', `/v3/products/${productId}/models/${id}`);
