import cn from 'classnames';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Fragment, PropsWithChildren, RefObject, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductGeneral, ProductRevisionPreview, getRevisionV3, saveProductV3 } from '../../../../api';
import { MenuApi } from '../../../../components/Menu';
import { ModalApi, ModalV3 } from '../../../../components/ModalV3';
import { useProfile } from '../../../../hooks/useProfile';

interface Props {
  menuRef?: RefObject<MenuApi>;
  modalRef: RefObject<ModalApi>;
  onOpenChange?: (open: boolean) => void;
  onViewClick: ({ id, revisionNumber }: { id: string; revisionNumber: number }) => void;
  product: ProductGeneral;
  revisionsList: ProductRevisionPreview[];
  onRestored?: (data: { id: string; updatedAt: string } | undefined) => void;
}

export const RevisionsModal = (props: PropsWithChildren<Props>) => {
  const [selectedRevision, setSelectedRevision] = useState<ProductRevisionPreview | undefined>();
  const [restoredRevision, setRestoredRevision] = useState<{ id: string; updatedAt: string }>();
  const [status, setStatus] = useState<'selected' | 'confirmed' | ''>('');
  const navigate = useNavigate();
  const profile = useProfile();

  return (
    <ModalV3
      size='narrow'
      ref={props.modalRef}
      onClose={() => {
        setSelectedRevision(undefined);
        setStatus('');
      }}
      onOpenChange={props.onOpenChange}
      cancelLabel={status === 'confirmed' ? 'Close' : undefined}
      onCancel={() => {
        switch (status) {
          case 'selected':
            setSelectedRevision(undefined);
            setStatus('');
            break;

          case 'confirmed':
            setStatus('');
            setSelectedRevision(undefined);
            props.modalRef.current!.close();
            break;

          default:
            props.menuRef?.current!.close();
            props.modalRef.current!.close();
            break;
        }
      }}
      hideConfirm={!status}
      confirmLabel={status === 'confirmed' ? 'View product' : undefined}
      noCloseOnConfirm
      noCloseOnCancel
      onConfirm={() => {
        if (selectedRevision) {
          if (status === 'selected') {
            getRevisionV3(selectedRevision.id, selectedRevision.revision).ok(({ product }) => {
              if (product.validation.errors.length > 0 || product.validation.warnings.length > 0) {
                navigate(`/products/${selectedRevision.id}/revisions/${selectedRevision.revision}`);
              } else {
                saveProductV3(product).ok(() => {
                  setRestoredRevision({ id: selectedRevision.id, updatedAt: selectedRevision.createdAt });
                  setStatus('confirmed');
                });

                if (props.onRestored) {
                  props.onRestored({ id: selectedRevision.id, updatedAt: selectedRevision.createdAt! });
                  props.modalRef.current!.close();
                }
              }
            });
          }
          if (status === 'confirmed') {
            navigate(`/products/${selectedRevision.id}`);
          }
        }
      }}
      title={(() => {
        switch (status) {
          case 'selected':
            return 'Restore this version?';
          case 'confirmed':
            return 'Restore complete';
          default:
            return (
              <div className='flex flex-col gap-1'>
                <div title={`${props.product.name} Version History`} className='truncate text-lg font-semibold'>
                  {props.product.name} Edit History
                </div>
                <div title={props.product.skuId} className='truncate text-xs font-normal'>
                  ID: {props.product.skuId}
                </div>
              </div>
            );
        }
      })()}
      body={
        <div className='-mt-6'>
          {(() => {
            switch (status) {
              case 'selected':
                return (
                  <>
                    Your current product will revert to the version from{' '}
                    <span className='font-semibold'>
                      {selectedRevision && formatInTimeZone(parseISO(selectedRevision.createdAt), 'UTC', 'd MMM yy HH:mm')} UTC
                    </span>
                    . This will apply to everyone in this workspace and to all models/volume reports.
                  </>
                );
              case 'confirmed':
                return (
                  <>
                    Your product was successfully restored to an earlier version from{' '}
                    <span className='font-semibold'>
                      {restoredRevision && formatInTimeZone(parseISO(restoredRevision.updatedAt), 'UTC', 'd MMM yy HH:mm')}.
                    </span>
                  </>
                );
              default:
                return (
                  <div className='grid grid-cols-[30px_auto_140px] px-7'>
                    {(() => {
                      const row = (
                        index: number,
                        name: string,
                        comment: string,
                        id?: string,
                        revisionNumber: number = 0,
                        onRevisionSelect?: () => void,
                      ) => (
                        <Fragment key={index}>
                          <div className='relative top-2'>
                            <div className='h-[7px] aspect-square rounded-full outline outline-brandDark z-10 bg-white' />
                            <div
                              className={cn('relative w-px left-[3px] bg-brandLight/30 h-full', {
                                hidden: index === props.revisionsList.length + 1,
                              })}
                            />
                          </div>
                          <div className='flex flex-col gap-1 mb-5'>
                            <div className='font-semibold'>{name}</div>
                            <div className='text-zinc-500 text-xs'>{comment}</div>
                          </div>
                          {id && (
                            <div className='grid grid-cols-2 text-xs'>
                              <button
                                className='flex self-start justify-self-center items-start text-brandDark [&:hover:not(:disabled)]:underline disabled:text-zinc-400 disabled:cursor-not-allowed'
                                onClick={() => props.onViewClick({ id, revisionNumber })}
                              >
                                View
                              </button>
                              {index !== 0 && (
                                <button
                                  className='flex self-start justify-self-center text-brandDark [&:hover:not(:disabled)]:underline disabled:text-zinc-400 disabled:cursor-not-allowed'
                                  onClick={onRevisionSelect}
                                  disabled={!profile.selectedWorkspace.permissions.productManagement}
                                >
                                  Restore
                                </button>
                              )}
                            </div>
                          )}
                        </Fragment>
                      );

                      return (
                        <>
                          {props.revisionsList.map((revision, index) =>
                            row(
                              index,
                              index === 0 ? 'Current product' : `Version ${revision.revision}`,
                              revision.comment,
                              revision.id,
                              revision.revision,
                              () => {
                                setSelectedRevision(revision);
                                setStatus('selected');
                              },
                            ),
                          )}
                          {row(
                            props.revisionsList.length + 1,
                            'Product creation',
                            `${formatInTimeZone(parseISO(props.product.createdAt), 'UTC', 'd MMM yy HH:mm')} UTC by ${
                              props.product.author
                            }`,
                          )}
                        </>
                      );
                    })()}
                  </div>
                );
            }
          })()}
        </div>
      }
    >
      {props.children}
    </ModalV3>
  );
};
