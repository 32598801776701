import { ForecastReport, ReportType } from '../../../../../api';
import { Appendix } from '../../components/Appendix';

interface Props {
  data: ForecastReport;
}

export const ForecastAppendix = (props: Props) => {
  return <Appendix data={props.data} reportType={ReportType.Forecast} />;
};
