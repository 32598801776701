import { Field, FieldProps, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import {
  BillingModeType,
  createCustomer,
  createUser,
  createWorkspace,
  Customer,
  getCustomers,
  getUsers,
  getWorkspaces,
  StaticEntity,
  User,
  Workspace,
} from '../../../api';
import { InputV3 } from '../../../components/InputV3';
import { SelectV3 } from '../../../components/SelectV3';
import { useApiQuery } from '../../../hooks/useApiQuery';
import { useLists } from '../../../hooks/useLists';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import cn from 'classnames';
import { differenceInDays } from 'date-fns';
import { convertUTCDateToLocal } from '../../../components/datepicker/utils';

export const Customers = () => {
  const { data, reload } = useApiQuery(getCustomers());
  const lists = useLists();
  const [selectedCustomer, setSelectedCustomer] = useState<string | undefined>(undefined);
  const [add, setAdd] = useState(false);

  if (!data) {
    return <></>;
  }

  return (
    <div className='-mt-10 -mx-6'>
      {(() => {
        if (add) {
          return (
            <Formik
              initialValues={{} as Customer}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                createCustomer(values).call({
                  ok: () => {
                    setAdd(false);
                    reload();
                  },
                  fail: () => {
                    setSubmitting(false);
                  },
                });
              }}
            >
              {(formik) => (
                <Form className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'>
                  <div>
                    <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
                      <div className='flex items-center gap-x-4 py-6 px-12 w-full max-w-[theme(screens.xl)] text-black'>
                        <button
                          type='button'
                          onClick={() => setAdd(false)}
                          className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'
                        >
                          <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                        </button>
                        <div className='text-lg font-semibold bg-white'>New Organisation</div>
                      </div>
                    </div>
                    <div className='flex flex-col items-center justify-center pt-10'>
                      <div className='flex flex-col w-full xl:w-[theme(screens.xl)] px-12'>
                        <div className='flex gap-x-4'>
                          <Field name='companyName'>
                            {(model: FieldProps<string>) => (
                              <div className='flex flex-col gap-1.5 w-60'>
                                <div className='font-semibold text-sm'>Organisation name</div>
                                <InputV3 autoFocus model={model} />
                              </div>
                            )}
                          </Field>
                          <Field name='methodology'>
                            {(model: FieldProps<StaticEntity>) => (
                              <div className='flex flex-col gap-1.5 w-60'>
                                <div className='font-semibold text-sm'>Methodology</div>
                                <SelectV3 model={model} options={lists.methodologies} />
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='border-t py-4 flex items-center justify-center bg-white'>
                    <div className='flex justify-end w-ful w-full xl:w-[theme(screens.xl)] px-12'>
                      <button
                        type='submit'
                        disabled={formik.isSubmitting}
                        //onClick={() => formik.handleSubmit()}
                        className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
                      >
                        Create organisation
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          );
        }

        if (selectedCustomer) {
          return (
            <EditCustomer
              data={data.find(({ customerSid }) => customerSid === selectedCustomer)!}
              onBack={() => setSelectedCustomer(undefined)}
            />
          );
        }

        return (
          <div className='flex flex-col mb-20'>
            <div className='sticky top-0 bg-white py-6 xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))]'>
              <div>
                <div className='flex flex-1 justify-center'>
                  <div className='flex justify-between items-center px-12 w-[theme(screens.xl)]'>
                    <div className='text-lg font-semibold leading-tight text-zinc-900'>Sustained Impact Customers</div>
                    <button
                      className='rounded-full px-3 py-1.5 bg-brand text-white text-sm font-semibold'
                      type='button'
                      onClick={() => setAdd(true)}
                    >
                      New Customer
                    </button>
                  </div>
                </div>
                <div className='h-[1px] w-full bg-zinc-200 pb-px mt-6'></div>
              </div>
            </div>

            <div className='grid gap-x-3 grid-cols-[3fr_5fr_40px] px-12'>
              <div className='col-span-3 sticky top-[calc(5rem+1px)] bg-slate-50 py-2'>
                <div className='grid gap-x-3 grid-cols-[3fr_5fr_40px] px-2 text-xs text-zinc-600 uppercase tracking-wide'>
                  <div>name</div>
                  <div>id</div>
                  <div></div>
                </div>
              </div>

              {data.map((customer, i) => (
                <div
                  onClick={() => setSelectedCustomer(customer.customerSid)}
                  className='col-span-3 grid items-center grid-cols-subgrid gap-x-3 py-4 p-2 border-b border-zinc-200 text-sm hover:bg-slate-100 cursor-pointer'
                  key={i}
                >
                  <div className='flex items-center gap-x-3 truncate'>
                    {customer.logoUrl ? (
                      <img className='shrink-0 size-8 rounded-full overflow-hidden' src={customer.logoUrl} alt='' />
                    ) : (
                      <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                        <FontAwesomeIcon icon={regular('building')} />
                      </div>
                    )}
                    <div title={customer.companyName} className='truncate'>
                      {customer.companyName}
                    </div>
                  </div>
                  <div className='flex items-center gap-x-2 leading-relaxed truncate'>
                    <div className='truncate'>{customer.customerSid}</div>
                    <button
                      className='active:scale-95 hover:text-brand'
                      onClick={async (e) => {
                        e.stopPropagation();
                        await navigator.clipboard.writeText(customer.customerSid);
                      }}
                    >
                      <FontAwesomeIcon icon={regular('copy')} />
                    </button>
                  </div>
                  <FontAwesomeIcon className='flex justify-self-center' icon={regular('pencil')} />
                </div>
              ))}
            </div>
          </div>
        );
      })()}
    </div>
  );
};

enum Tabs {
  Details = 'details',
  Workspaces = 'workspaces',
  Users = 'users',
}

const EditCustomer = (props: { data: Customer; onBack: () => void }) => {
  const workspaces = useApiQuery(getWorkspaces(props.data.customerSid));
  const users = useApiQuery(getUsers(props.data.customerSid));
  const lists = useLists();
  const [addWorkspace, setAddWorkspace] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Details);
  const billingOptions = [
    {
      type: BillingModeType.Free,
      name: 'No billing (free)',
    },
    {
      type: BillingModeType.Standalone,
      name: 'Stand-alone billing',
    },
    {
      type: BillingModeType.Volume,
      name: 'Volume billing',
    },
  ];

  if (!workspaces.data || !users.data) {
    return <></>;
  }

  if (addWorkspace) {
    return (
      <Formik
        initialValues={{ customerSid: props.data.customerSid } as Workspace}
        onSubmit={(values, { setSubmitting }) => {
          createWorkspace(values).call({
            ok: () => {
              setAddWorkspace(false);
              workspaces.reload();
            },
            fail: () => {
              setSubmitting(false);
            },
          });
        }}
      >
        {(formik) => (
          <Form className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'>
            <div>
              <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
                <div className='flex items-center gap-x-4 py-6 w-full xl:w-[theme(screens.xl)] px-12 text-black'>
                  <button
                    type='button'
                    onClick={() => setAddWorkspace(false)}
                    className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'
                  >
                    <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                  </button>
                  <div className='flex items-center gap-x-2 text-lg font-semibold bg-white'>
                    {props.data.companyName} Organisation <FontAwesomeIcon className='text-xs' icon={regular('chevron-right')} /> New
                    Workspace
                  </div>
                </div>
              </div>
              <div className='flex flex-col h-full w-full items-center pt-10'>
                <div className='grid grid-cols-3 gap-6 w-full xl:w-[theme(screens.xl)] px-12 *:text-black *:flex *:flex-col *:gap-1.5'>
                  <Field name='name'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Workspace name</div>
                        <InputV3 autoFocus model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='targetSkuCount'>
                    {(model: FieldProps<number>) => (
                      <div>
                        <div className='font-semibold text-sm'>Target product count</div>
                        <InputV3 integer positive model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='methodology'>
                    {(model: FieldProps<StaticEntity>) => (
                      <div>
                        <div className='font-semibold text-sm'>Methodology</div>
                        <SelectV3 model={model} options={lists.methodologies} />
                      </div>
                    )}
                  </Field>
                  <Field name='brand'>
                    {(model: FieldProps<StaticEntity>) => (
                      <div>
                        <div className='font-semibold text-sm'>Brand</div>
                        <SelectV3 model={model} options={lists.brands} />
                      </div>
                    )}
                  </Field>
                  <Field name='trialDays'>
                    {(model: FieldProps<number>) => (
                      <div>
                        <div className='font-semibold text-sm'>Trial days</div>
                        <InputV3 integer positive model={model} placeholder='Leave empty if not needed…' />
                      </div>
                    )}
                  </Field>
                  <Field name='billingMode'>
                    {(model: FieldProps<string>) => (
                      <div>
                        <div className='font-semibold text-sm'>Billing mode</div>
                        <SelectV3 model={model} options={billingOptions} />
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </div>
            <div className='border-t py-4 flex items-center justify-center bg-white'>
              <div className='flex justify-end w-full xl:w-[theme(screens.xl)] px-12'>
                <button
                  type='submit'
                  disabled={formik.isSubmitting}
                  className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
                >
                  Create workspace
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  if (addUser) {
    return (
      <Formik
        initialValues={
          { customerSid: props.data.customerSid, company: props.data.companyName, job: props.data.companyName, internalUser: false } as User
        }
        onSubmit={(values, { setSubmitting }) => {
          createUser(values).call({
            ok: ({ errorCode }) => {
              if (errorCode) {
                alert(errorCode);
                setSubmitting(false);
                return;
              }

              setAddUser(false);
              users.reload();
            },
            fail: () => {
              setSubmitting(false);
            },
          });
        }}
      >
        {(formik) => (
          <Form className='flex flex-col justify-between h-[calc(100vh-80px)] xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))] bg-neutral-50'>
            <div>
              <div className='flex flex-col w-full items-center justify-center border-b border-zinc-200 bg-white'>
                <div className='flex items-center gap-x-4 py-6 w-full xl:w-[theme(screens.xl)] px-12 text-black'>
                  <button
                    type='button'
                    onClick={() => setAddUser(false)}
                    className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'
                  >
                    <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
                  </button>
                  <div className='flex items-center gap-x-2 text-lg font-semibold bg-white'>
                    {props.data.companyName} Organisation <FontAwesomeIcon className='text-xs' icon={regular('chevron-right')} /> New
                    Workspace
                  </div>
                </div>
              </div>

              <div className='flex flex-col h-full w-full items-center pt-10'>
                <div className='grid grid-cols-3 gap-6 w-full xl:w-[theme(screens.xl)] px-12 *:text-black'>
                  <Field name='email'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Email</div>
                        <InputV3 autoFocus model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='firstName'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>First Name</div>
                        <InputV3 model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='lastName'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Last Name</div>
                        <InputV3 model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='company'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Company</div>
                        <InputV3 model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='job'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Job</div>
                        <InputV3 model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='password'>
                    {(model: FieldProps<string>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Password</div>
                        <InputV3 model={model} />
                      </div>
                    )}
                  </Field>
                  <Field name='internalUser'>
                    {(model: FieldProps<boolean>) => (
                      <div className='flex flex-col gap-1.5'>
                        <div className='font-semibold text-sm'>Internal user</div>
                        <div className='relative flex items-center gap-4 mt-1.5 pl-1'>
                          <input
                            checked={model.field.value}
                            onChange={(event) => {
                              model.field.onChange(event);
                              if (event.target.value) {
                                formik.setFieldValue('internalUser', !model.field.value);
                              }
                            }}
                            className='opacity-0 absolute h-3 w-6 aspect-square z-10 hover:cursor-pointer disabled:opacity-0 disabled:cursor-not-allowed'
                            type='checkbox'
                          />
                          <div
                            className={cn(
                              'border border-white px-0.5 outline flex items-center rounded-full relative h-3 w-6',
                              model.field.value ? 'bg-brand' : 'bg-lightBg',
                            )}
                          >
                            <div
                              className={cn('absolute rounded-full h-2.5 aspect-square transition-[left] duration-25', {
                                'left-3 bg-white': model.field.value,
                                'left-px bg-brand': !model.field.value,
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Field>
                </div>
              </div>
            </div>

            <div className='border-t py-4 flex items-center justify-center bg-white'>
              <div className='flex justify-end w-full xl:w-[theme(screens.xl)] px-12'>
                <button
                  type='submit'
                  disabled={formik.isSubmitting}
                  className='rounded-full py-2 px-3 bg-brand text-white font-semibold text-sm'
                >
                  Create user
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  return (
    <div className='xl:-mx-[calc((100vw-theme(screens.xl))/2+theme(spacing.12))]'>
      <div className='flex flex-1 justify-center py-6 border-b border-zinc-200'>
        <div className='flex justify-start gap-x-4 items-center px-12 w-full xl:w-[theme(screens.xl)] font-semibold text-lg text-zinc-900'>
          <button type='button' onClick={props.onBack} className='size-8 rounded-lg bg-slate-200 flex items-center justify-center'>
            <FontAwesomeIcon className='text-lg' icon={regular('chevron-left')} />
          </button>
          <div>{props.data.companyName} Organisation</div>
        </div>
      </div>
      <div className='flex flex-1 justify-center pt-6'>
        <div className='flex flex-col gap-y-6 justify-between px-12 w-[theme(screens.xl)]'>
          <div className='flex items-center'>
            {[Tabs.Details, Tabs.Workspaces, Tabs.Users].map((tab, i) => (
              <Fragment key={i}>
                {i === 1 && <div className='w-px h-6 bg-zinc-300 mx-4'></div>}
                <button
                  className={cn(
                    'text-xs font-semibold px-3 py-1.5 border-b-2 uppercase',
                    selectedTab === tab ? 'text-zinc-800 border-brand' : 'text-zinc-500 hover:text-brand border-transparent',
                  )}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab}
                </button>
                {i === 1 && <div className='w-px h-6 bg-zinc-300 mx-4'></div>}
              </Fragment>
            ))}
          </div>
          <div className='flex flex-col gap-y-6'>
            {(() => {
              switch (selectedTab) {
                case Tabs.Details:
                  return (
                    <>
                      <div className='flex items-center font-semibold text-base text-zinc-800 h-10'>Organisation details</div>
                      <div className='grid grid-cols-[auto_1fr] items-center gap-y-6 gap-x-16'>
                        <div>Name</div>
                        <div className='font-semibold'>{props.data.companyName}</div>
                        <div>Id</div>
                        <div className='flex gap-x-4 items-center'>
                          {props.data.customerSid}
                          <button
                            className='active:scale-95 hover:text-brand'
                            onClick={() => navigator.clipboard.writeText(props.data.customerSid)}
                          >
                            <FontAwesomeIcon icon={regular('copy')} />
                          </button>
                        </div>
                        <div>Paying customer</div>
                        <div>{workspaces.data?.map(({ billingMode }) => billingMode)[0]?.name}</div>
                        <div>Organisation Logo</div>
                        <div>
                          {props.data.logoUrl ? (
                            <img className='shrink-0 size-8 rounded-full overflow-hidden' src={props.data.logoUrl} alt='_' />
                          ) : (
                            <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                              <FontAwesomeIcon icon={regular('building')} />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  );
                case Tabs.Workspaces:
                  return (
                    <>
                      <div className='flex items-center justify-between h-10'>
                        <div className='font-semibold text-base text-zinc-800'>Workspaces</div>
                        <button
                          onClick={() => setAddWorkspace(true)}
                          className='text-violet-950 text-sm font-semibold leading-tight px-4 py-2 bg-slate-200 rounded-lg'
                        >
                          Create new workspace
                        </button>
                      </div>
                      <div className='grid grid-cols-[2fr_3fr_1fr_2fr_2fr_2fr_1fr] gap-x-4'>
                        <div className='grid grid-cols-subgrid col-span-7 uppercase text-xs text-zinc-600 bg-slate-50 p-2'>
                          <div>name</div>
                          <div>id</div>
                          <div>trial</div>
                          <div>billing mode</div>
                          <div>target product count</div>
                          <div>methodology</div>
                          <div>brand</div>
                        </div>

                        <div className='grid grid-cols-subgrid col-span-7 divide-y'>
                          {workspaces.data.map((workspace, i) => (
                            <div className='grid grid-cols-subgrid col-span-7 items-center *:truncate' key={i}>
                              <div title={workspace.name} className='flex items-center gap-x-3 py-4'>
                                {workspace.logoUrl ? (
                                  <img className='shrink-0 size-8 rounded-full overflow-hidden' src={workspace.logoUrl} alt='' />
                                ) : (
                                  <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                                    <FontAwesomeIcon icon={regular('building')} />
                                  </div>
                                )}
                                <div className='truncate'>{workspace.name}</div>
                              </div>
                              <div className='flex items-center gap-x-1' title={workspace.workspaceSid}>
                                <div className='truncate'>{workspace.workspaceSid}</div>
                                <button
                                  className='active:scale-95 hover:text-brand'
                                  onClick={() => navigator.clipboard.writeText(workspace.workspaceSid)}
                                >
                                  <FontAwesomeIcon icon={regular('copy')} />
                                </button>
                              </div>
                              <div title={workspace.trialUntil ?? ''}>
                                {workspace.trialUntil
                                  ? `${differenceInDays(new Date(convertUTCDateToLocal(workspace.trialUntil)), new Date()) + 1} days`
                                  : '-'}
                              </div>
                              <div title={workspace.billingMode?.name}>{workspace.billingMode?.name ?? '-'}</div>
                              <div title={workspace.targetSkuCount.toString()}>{workspace.targetSkuCount}</div>
                              <div title={workspace.methodology.name}>{workspace.methodology.name}</div>
                              <div title={workspace.brand.name}>{workspace.brand.name}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  );
                case Tabs.Users:
                  return (
                    <>
                      <div className='flex items-center justify-between h-10'>
                        <div className='font-semibold text-base text-zinc-800'>Users</div>
                        <button
                          onClick={() => setAddUser(true)}
                          className='text-violet-950 text-sm font-semibold leading-tight px-4 py-2 bg-slate-200 rounded-lg'
                        >
                          Add new user
                        </button>
                      </div>

                      <div className='grid grid-cols-[auto_1fr]'>
                        <div className='col-span-2 grid grid-cols-subgrid uppercase text-xs text-zinc-600 bg-slate-50 p-2'>
                          <div>Name</div>
                          <div></div>
                        </div>

                        <div className='col-span-2 grid grid-cols-subgrid divide-y'>
                          {users.data?.map((user, i) => (
                            <div className='col-span-2 grid grid-cols-subgrid gap-x-4' key={i}>
                              <div className='flex gap-x-3 items-center py-2.5'>
                                <div className='shrink-0 size-8 flex justify-center items-center bg-violet-200 rounded-full'>
                                  <FontAwesomeIcon icon={regular('user')} />
                                </div>
                                <div className='flex flex-col'>
                                  <div>{user.fullName}</div>
                                  <div className='text-sm'>{user.email}</div>
                                </div>
                              </div>
                              <div className='flex'>
                                {user.internalUser ? (
                                  <div className='flex self-center rounded-lg justify-self-start text-xs py-1 px-2 bg-violet-100'>
                                    internal
                                  </div>
                                ) : undefined}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  );
                default:
                  return <></>;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
