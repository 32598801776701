import { EnvironmentalLabel as SustainedEnvironmentalLabel } from '../../components/EnvironmentalLabel';
import { ProductReport } from '../../../../../../../api';
import { NavigationButtons } from '../../components/NavigationButtons';
import { GradeBadge } from '../../../../../../../components/GradeBadge';
import { ProductDetailsConsumerView } from '../../components/ProductDetailsConsumerView';
import bottle from '../../../bottle.png';
import laptop from '../../../consumer-view-macbook.png';
import { CollapsibleSection } from '../../../CollapsibleSection';

interface Props {
  data: ProductReport;
}

export const EnvironmentalLabel = (props: Props) => {
  return (
    <SustainedEnvironmentalLabel
      description={
        <div className='flex justify-between gap-8 text-brandGray5'>
          <div className='flex flex-col gap-6'>
            <div>
              Sustained consumer experiences translate the underlying impact assessment to an estimated product impact rating from A to G,
              where A is great and G is bad.
            </div>
            <div>
              The Sustained consumer experience also groups impact categories into 6 overarching categories (from the 16 environmental
              categories impacts are calculated for) and displayed from best to worst. This was done to ensure consumers' understanding of
              the impacts. Our eco-rating pills, showing the product's average environmental rating, can be seamlessly integrated in your
              consumer experiences, physical or digital, as demonstrated here.
            </div>
            <div className='text-brandDark'>
              NB: The information provided in this section is indicative only, and represents the ‘estimated’ impact of your product given
              the assumptions and limitations presented in previous sections. the estimated nature of this rating should be clearly
              displayed if you wish to use it in your own experiences, and any marketing claims you wish to make should be proportional to
              the evidence you’re able to provide to back up your claims.
            </div>
          </div>
          <NavigationButtons type='icons' back={{ path: '../impact-analysis' }} next={{ path: '../appendix' }} />
        </div>
      }
      label={
        <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
          <div className='text-xl font-semibold p-6 group-hover:text-brand'>Environmental label</div>
          <div className='scale-[200%] w-0 p-6 mb-10'>
            <GradeBadge grade={props.data.consumerView.overallGrade} />
          </div>
        </div>
      }
      communicationExamples={
        <CollapsibleSection title='Communication examples'>
          <div className='flex flex-col'>
            <div className='grid xl:grid-cols-3 grid-cols-1'>
              <div className='flex flex-col justify-start'>
                <div className='text-lg font-semibold text-brandDark text-center'>Detail View</div>
                <ProductDetailsConsumerView
                  scale='scale-75'
                  name={props.data.product.name}
                  impacts={props.data.consumerView.impacts}
                  overallGrade={props.data.consumerView.overallGrade}
                  amountValue={props.data.product.amount.value}
                  amountUnit={props.data.product.amount.unit.name}
                  image={props.data.product.imageUrl ? props.data.product.imageUrl : bottle}
                />
              </div>
              <div className='flex flex-col'>
                <div className='text-lg font-semibold text-brandDark text-center'>Packaging View</div>
                <div className='bg-white flex justify-center items-center relative m-[auto]'>
                  <img src={props.data.product.imageUrl ? props.data.product.imageUrl : bottle} alt='' className='max-h-60 m-auto' />
                  <div className='absolute translate-x-1 -translate-y-12'>
                    <GradeBadge grade={props.data.consumerView.overallGrade} />
                  </div>
                </div>
              </div>
              <div className='flex flex-col justify-self-center max-w-[372px]'>
                <div className='text-lg font-semibold text-brandDark text-center'>Overlay View</div>
                <div className='rounded-3xl bg-white flex justify-center m-[auto] relative'>
                  <img className='min-w-60' src={laptop} alt='' />
                  <div className='absolute scale-50 top-[32px] left-[120px] z-10'>
                    <GradeBadge grade={props.data.consumerView.overallGrade} />
                  </div>
                  <img
                    className='absolute top-[50px] left-[62px] max-h-[120px] max-w-[80px]'
                    height='auto'
                    src={props.data.product.imageUrl ? props.data.product.imageUrl : bottle}
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsibleSection>
      }
      cta={
        <CollapsibleSection title='Interested in a potential integration into your digital retail experiences?'>
          <div className='flex flex-col gap-6'>
            <div>
              This is where the <span className='text-brandDark'>Sustained Impact API</span> comes into play!
            </div>

            <div>
              An API which allows you to communicate on your product’s environmental footprint with your consumers directly, using our
              straightforward A to G rated pills, or displaying the detail view of any product to provide additional information as to why
              the product grade is what it is!
            </div>

            <div>
              <a href='mailto:impact@sustained.com' className='text-brandDark hover:underline'>
                Contact us at impact@sustained.com
              </a>{' '}
              to learn more.
            </div>
          </div>
        </CollapsibleSection>
      }
      bottomNavigationButtons={
        <NavigationButtons
          type='buttons'
          back={{ path: '../impact-analysis', label: 'Impact analysis' }}
          next={{ path: '../appendix', label: 'Appendix' }}
        />
      }
    />
  );
};
