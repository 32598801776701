import { Fragment, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react';
import { ModalV3 } from '../../../../../components/ModalV3';
import {
  getProductModelsV3,
  ImpactDeltaType,
  ProductModelListItem,
  ProductState,
  ProductWithAmount,
  searchProducts,
  SearchProductsParams,
} from '../../../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import InfiniteScroll from '../../../../../components/InfiniteScroll';
import { ListItem } from './SelectProducts';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { simplify } from '../../../shared';
import { NavLink } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import { AppRoutes } from '../../../index';
import { useDebounce } from '../../../../../hooks/useDebounce';

const defaultSearchParams: SearchProductsParams = {
  pageSize: 50,
  sortBy: 'updatedAt',
  sortAscending: false,
  contains: '',
  pageToken: '',
  state: ProductState.Complete,
};

export const SelectProductsModal = (props: PropsWithChildren<{}>) => {
  const [products, setProducts] = useState<ListItem[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string>('');
  const formik = useFormikContext<{ products: ProductWithAmount[] }>();
  const [searchString, setSearchString] = useState('');
  const debouncedSearchInputValue = useDebounce(searchString, 300);
  const firstRender = useRef(true);

  const [searchParams, setSearchParams] = useState({
    ...defaultSearchParams,
    forecastEligible: true,
  });

  useEffect(() => {
    if (!firstRender.current) {
      setSearchParams((oldParams) => ({ ...oldParams, contains: debouncedSearchInputValue }));
    }
  }, [debouncedSearchInputValue]);

  useEffect(() => {
    if (!firstRender.current) {
      searchProducts(searchParams).ok(({ products, nextPageToken }) => {
        setNextPageToken(nextPageToken);
        setProducts((currentValues) => {
          const selectedItems = currentValues.filter((item) => item.selected || item.models.find(({ parentId }) => parentId === item.id));
          return [
            ...selectedItems,
            ...products
              .filter(({ totalImpact }) => totalImpact !== 0)
              .filter(({ id, totalImpact }) => totalImpact !== 0 && !selectedItems.find(({ id: id2 }) => id === id2))
              .map((product) => {
                return {
                  ...product,
                  id: product.id,
                  skuId: product.skuId,
                  count: 0,
                  selected: false,
                  models: [],
                  disabled:
                    formik.values.products.find(({ id }) => id === product.id) !== undefined ||
                    selectedItems.find((value) => value.id === product.id) !== undefined,
                };
              }),
          ];
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const fetchProducts = () =>
    searchProducts({ ...searchParams, pageToken: nextPageToken }).ok(({ products, nextPageToken }) => {
      setNextPageToken(nextPageToken);
      setProducts((current) => [
        ...current,
        ...products
          .filter(({ totalImpact }) => totalImpact !== 0)
          .map((product) => {
            return {
              ...product,
              selected: false,
              models: [],
              disabled: formik.values.products.find(({ id }) => id === product.id) !== undefined,
            };
          }),
      ]);
    });

  const onSortClick = (field: SearchProductsParams['sortBy']) => {
    setSearchParams((oldParams) =>
      field === searchParams.sortBy
        ? { ...oldParams, sortAscending: !oldParams.sortAscending }
        : { ...oldParams, sortBy: field, sortAscending: true },
    );
  };

  const sortIcon = (field: SearchProductsParams['sortBy']) => (
    <FontAwesomeIcon
      className={cn('text-brand', {
        invisible: field !== searchParams.sortBy,
      })}
      icon={searchParams.sortAscending ? regular('arrow-up') : regular('arrow-down')}
    />
  );

  if (formik.values.products.length === 0) {
    return <></>;
  }

  return (
    <ModalV3
      size='wide+'
      title={
        <div className='flex justify-between'>
          <div className='text-lg'>Adding products to forecast</div>
          <div className='flex items-center relative text-sm'>
            <input
              type='search'
              className='rounded-full border [&:not(:disabled)]:border-neutral-500 h-full pl-12 pr-6 py-2'
              autoFocus
              placeholder='Find Product…'
              onChange={(event) => setSearchString(event.target.value)}
            />
            <FontAwesomeIcon icon={regular('magnifying-glass')} className='absolute left-6 text-light' />
          </div>
        </div>
      }
      onConfirm={() => {
        const selected = products.filter((it) => it.selected || it.models.length > 0);
        formik.setFieldValue('products', [
          ...selected.map((product) => ({
            ...product,
            sku: product.skuId,
            count: 0,
          })),
          ...formik.values.products,
        ]);
      }}
      onOpenChange={(open) => {
        if (open) {
          firstRender.current = false;
          fetchProducts();
        } else {
          firstRender.current = true;
          setProducts([]);
          setNextPageToken('');
          setSearchString('');
        }
      }}
      body={
        <div className='-my-6'>
          <InfiniteScroll
            height={document.body.offsetHeight * 0.5}
            dataLength={products.length}
            next={fetchProducts}
            hasMore={nextPageToken !== ''}
            loader={<></>}
          >
            <div className='w-full sticky py-2 top-0 bg-white gap-2 grid grid-cols-[3fr_1fr_1fr_1fr_1fr_2fr_1fr] items-center border-y text-[90%] font-semibold px-3'>
              <div className='grid grid-cols-[50px_auto] items-center'>
                <input
                  disabled={products.length === 0}
                  type='checkbox'
                  className='w-5 aspect-square'
                  checked={
                    products.length > 0 && products.filter(({ disabled }) => !disabled).every((it) => it.selected || it.models.length > 0)
                  }
                  onChange={(event) => {
                    setProducts((current) =>
                      current.map((product) => ({
                        ...product,
                        models: [],
                        selected: !product.disabled ? event.target.checked : false,
                      })),
                    );
                  }}
                />
                <button type='button' onClick={() => onSortClick('name')} className='flex items-center gap-2'>
                  Name
                  {sortIcon('name')}
                </button>
              </div>
              <div>
                <button className='flex justify-center items-center gap-2' onClick={() => onSortClick('updatedAt')} type='button'>
                  Last Updated
                  {sortIcon('updatedAt')}
                </button>
              </div>
              <div>
                <button className='flex gap-2 items-center' onClick={() => onSortClick('amount')} type='button'>
                  Net amount
                  {sortIcon('amount')}
                </button>
              </div>
              <div>ID</div>
              <div>
                <button
                  className='flex gap-2 items-center text-center'
                  onClick={() => onSortClick('firstPartyDataPercentage')}
                  type='button'
                >
                  First-party data
                  {sortIcon('firstPartyDataPercentage')}
                </button>
              </div>
              <div className='text-center'>
                <button className='flex gap-2 items-center text-center' onClick={() => onSortClick('totalImpact')} type='button'>
                  Total Impact (per net amount)
                  {sortIcon('totalImpact')}
                </button>
              </div>
              <div className='flex items-center justify-center'>
                <FontAwesomeIcon className='text-lg' icon={regular('file-chart-column')} />
              </div>
            </div>

            <div className='grid grid-cols-subgrid divide-y'>
              {products
                .filter(({ totalImpact }) => totalImpact !== 0)
                .map((product, index) => (
                  <Fragment key={index}>
                    <Row
                      index={index}
                      item={product}
                      list={products}
                      setList={setProducts}
                      searchString={searchString}
                      bgColor={index % 2 ? 'bg-white' : 'bg-slate-50'}
                      disabled={product.disabled}
                    />
                  </Fragment>
                ))}
            </div>
          </InfiniteScroll>
        </div>
      }
      footer={({ onConfirm, onCancel }) => {
        const selectedCount = products.filter((it) => it.selected).length + products.filter((it) => it.models.length > 0).length;

        return (
          <div className='w-full flex items-center justify-between gap-x-6'>
            <div className='flex items-center gap-x-1 p-2 pr-4 border rounded-full'>
              <div className='text-center text-[12px] text-brand px-[6px] py-[2px] bg-slate-100 rounded-full min-w-[24px]'>
                {simplify(selectedCount)}
              </div>
              <div className='text-sm text-zinc-500'>Product{selectedCount === 1 ? '' : 's'} selected</div>
            </div>
            <div className='flex items-center gap-x-4'>
              <button className='border rounded-full py-2 px-4 bg-white text-violet-950 border-violet-950' onClick={() => onCancel()}>
                cancel
              </button>
              <button
                type='button'
                disabled={selectedCount === 0}
                className={cn(
                  'items-center border rounded-full px-4 py-2 font-semibold',
                  'bg-brand text-white border-brand',
                  'disabled:bg-zinc-200 disabled:text-zinc-400 disabled:border-zinc-200 disabled:cursor-not-allowed',
                )}
                onClick={() => onConfirm()}
              >
                confirm selection
              </button>
            </div>
          </div>
        );
      }}
    >
      {props.children}
    </ModalV3>
  );
};

const Row = (props: {
  item: ListItem;
  searchString: string;
  index: number;
  list: ListItem[];
  setList: (value: SetStateAction<ListItem[]>) => void;
  bgColor: string;
  disabled: boolean;
}) => {
  const item = props.item;
  const [expanded, setExpanded] = useState(false);
  const [models, setModels] = useState<ProductModelListItem[]>([]);
  const showExpand = item.modelCount > 0;

  useEffect(() => {
    setModels([]);
  }, [item.id]);

  useEffect(() => {
    if (!showExpand) {
      setExpanded(false);
    }
  }, [showExpand]);

  useEffect(() => {
    if (item.models.length > 0) {
      setExpanded(true);
    }
  }, [item.models]);

  useEffect(() => {
    if (expanded) {
      getProductModelsV3(item.id).ok((response) => {
        setModels(response.models);
      });
    } else {
      props.setList((listItems) =>
        listItems.map((listItem) => ({
          ...listItem,
          models: listItem.id === item.id ? (item.models.length > 0 ? item.models : []) : listItem.models,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id, expanded]);

  return (
    <>
      <div className={cn('w-full gap-2 grid grid-cols-[3fr_1fr_1fr_1fr_1fr_2fr_1fr] items-center text-[90%] px-3', props.bgColor)}>
        <div
          className={cn(
            { 'text-zinc-400': props.disabled },
            'h-12 grid items-center truncate',
            showExpand ? 'grid-cols-[30px_auto]' : 'grid-cols-[50px_auto]',
          )}
          title={item.name ?? ''}
        >
          <input
            type='checkbox'
            className='w-5 aspect-square'
            disabled={props.disabled}
            checked={props.list.find(({ id }) => id === item.id)?.selected ?? false}
            onChange={() => {
              props.setList((current) =>
                current.map((currentItem) =>
                  currentItem.id === item.id
                    ? { ...currentItem, selected: !currentItem.selected, models: !currentItem.selected ? [] : currentItem.models }
                    : { ...currentItem },
                ),
              );
            }}
          />

          {showExpand ? (
            <button
              type='button'
              disabled={props.disabled}
              className='flex items-center gap-2 truncate'
              onClick={() => setExpanded(!expanded)}
            >
              <FontAwesomeIcon
                className={cn('h-3 aspect-square transition-all', { 'rotate-90': expanded })}
                icon={solid('chevron-right')}
              />
              <div className='truncate'>{item.name || '(Unnamed)'}</div>
            </button>
          ) : (
            <div className='truncate'>{item.name || '(Unnamed)'}</div>
          )}
        </div>
        <div className=''>{format(parseISO(item.updatedAt), 'dd/MM/yyyy')}</div>
        <div className=''>{item.amount?.value && item.amount?.unit && `${item.amount.value}${item.amount.unit.name}`}</div>
        <div className='truncate' title={item.skuId}>
          {item.skuId}
        </div>
        <div className='text-center'>{item.firstPartyDataPercentage} %</div>
        <div>
          <div className='flex items-center justify-center'>{simplify(item.impactPoints)} Impact points</div>
        </div>
        <div className='px-6 text-center'>
          <NavLink
            className='text-brandDarkPurple2 hover:underline font-semibold text-[90%]'
            to={AppRoutes(item.id).report.productOverview}
            target='_blank'
          >
            {item.totalImpact === 0 ? 'Generate' : 'View'}
          </NavLink>
        </div>
      </div>

      {expanded && models.length > 0 && (
        <div>
          <div className={cn('py-2 flex items-center text-brand pl-[60px] text-xs font-semibold uppercase', props.bgColor)}>models</div>
          <div className='divide-y'>
            {orderBy(models, ({ updatedAt }) => updatedAt, 'desc').map((model, i) => (
              <div
                key={i}
                className={cn('w-full gap-x-2 grid grid-cols-[3fr_1fr_1fr_1fr_1fr_2fr_1fr] items-center text-[90%] px-3', props.bgColor)}
              >
                <div className='h-14 grid items-center truncate grid-cols-[50px_auto]' title={model.title}>
                  <input
                    type='checkbox'
                    className='w-5 aspect-square'
                    checked={props.list.find(({ id }) => id === item.id)?.models.find(({ id }) => id === model.id)?.id !== undefined}
                    onChange={() =>
                      props.setList((current) =>
                        current.map((product) => ({
                          ...product,
                          selected:
                            product.id === item.id
                              ? (product.models.find((model2) => model2.id === model.id) && product.models.length === 0) !== undefined
                              : product.selected,
                          models:
                            item.id === product.id
                              ? product.models.find((m2) => m2.id === model.id)
                                ? []
                                : [
                                    {
                                      id: model.id,
                                      skuId: item.skuId,
                                      name: model.title,
                                      author: model.author,
                                      parentId: item.id,
                                      count: 0,
                                    },
                                  ]
                              : product.models,
                        })),
                      )
                    }
                  />

                  <div className='flex flex-col gap-y-1 truncate'>
                    <div className='truncate'>{model.title}</div>
                    <div className='flex items-center text-zinc-500 gap-x-2 text-[95%]'>
                      <FontAwesomeIcon icon={light('user')} />
                      {model.author}
                    </div>
                  </div>
                </div>
                <div>{format(parseISO(model.updatedAt), 'dd/MM/yyyy')}</div>
                <div></div>
                <div className='flex'>
                  <div className='bg-violet-200 px-1 flex self-start rounded-md text-zinc-700 text-[10px] font-semibold uppercase'>
                    model
                  </div>
                </div>
                <div className='text-center'>
                  {model.proposedChanges} change{model.proposedChanges === 1 ? '' : 's'}
                </div>
                <div
                  className={cn('text-center', {
                    'text-red-500': model.impactDelta.type === ImpactDeltaType.Higher,
                    'text-emerald-700': model.impactDelta.type === ImpactDeltaType.Lower,
                    'text-neutral-400': model.impactDelta.type === ImpactDeltaType.Zero,
                  })}
                >
                  {model.impactDelta.type === ImpactDeltaType.Zero
                    ? 'No impact change'
                    : `${model.impactDelta.display} ${model.impactDelta.type === ImpactDeltaType.Higher ? 'higher' : 'lower'} impact`}
                </div>
                <div className='text-center'>
                  <NavLink
                    className='text-brandDarkPurple2 hover:underline font-semibold text-[90%]'
                    to={AppRoutes(item.id, model.id).report.modelOverview}
                    target='_blank'
                  >
                    View
                  </NavLink>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
