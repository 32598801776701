import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { GhgAnalysis } from '../../../../../../api';
import { simplify } from '../../../../shared';
import { requiredReportingData } from '../../../../Manufacturing/components/GhgProtocol.tsx/dataModel';
import cn from 'classnames';

interface Props {
  data?: GhgAnalysis;
}

export const RequiredReportingEmissionsTable = (props: Props) => {
  const [expanded, setExpanded] = useState(['scope_3']);

  if (!props.data) {
    return <></>;
  }
  const categories = props.data.categories.map((category, index) => ({
    ...category,
    content: requiredReportingData[index].content,
  }));

  const handleExpandedState = (id1: string) =>
    setExpanded((current) =>
      current.includes(id1) ? current.filter((id2) => !(id2.startsWith(id1) && id2.length >= id1.length)) : [...current, id1],
    );

  const gridFrames = 'grid-cols-[4fr_3fr_3fr]';

  return (
    <div className='gap-x-2 mx-3 border-b border-zinc-200 divide-zinc-200 divide-y text-sm text-dark'>
      <div className={cn('col-span-3 grid gap-2 border-t text-xs uppercase py-3 text-zinc-500', gridFrames)}>
        <div className='pl-3'>scope / category</div>
        <div>Total emissions (excl, Biogenic)</div>
        <div>Biogenic Emissions</div>
      </div>

      {props.data.scopeEmissions.map((item, index) => (
        <div key={index} className={cn('col-span-3 grid gap-2 font-semibold', gridFrames)}>
          <div>
            <button
              onClick={() => {
                if (item.id === 'scope_3') {
                  expanded.includes('scope_3') ? setExpanded([]) : setExpanded(['scope_3']);
                }
              }}
              className='flex self-start py-2.5 pl-3 hover:text-brandDark'
            >
              <div className='size-5'>
                {item.id === 'scope_3' && (
                  <FontAwesomeIcon className={cn({ 'rotate-90': expanded.includes('scope_3') })} icon={light('chevron-circle-right')} />
                )}
              </div>
              {item.name}
            </button>
          </div>
          <div className='py-2.5'>
            {simplify(item.totalEmission.value)} {item.totalEmission.unit}
          </div>
          <div className='py-2.5'>
            {simplify(item.totalBiogenic.value)} {item.totalBiogenic.unit}
          </div>
        </div>
      ))}

      {expanded.includes('scope_3') &&
        categories.map((category, index) => (
          <div key={index}>
            <div className={cn('col-span-3 gap-x-2 grid items-center', gridFrames)}>
              <div className='flex py-2.5'>
                <button
                  onClick={() => handleExpandedState(category.name)}
                  className='flex self-start items-center gap-2 pl-10 hover:text-brandDark'
                >
                  <FontAwesomeIcon
                    className={cn('h-1.5 aspect-square print:rotate-180', expanded.includes(category.name) ? 'rotate-180' : 'rotate-90')}
                    icon={solid('triangle')}
                  />
                  {category.name}
                </button>
              </div>
              <div className={cn({ 'text-sm text-gray-400': category.isOutOfScope })}>
                {category.isOutOfScope ? (
                  <span className='uppercase text-zinc-500 text-xs'>out of scope</span>
                ) : (
                  <span>
                    {simplify(category.totalEmission.value)} {category.totalEmission.unit}
                  </span>
                )}
              </div>
              <div className={cn({ 'text-sm text-gray-400': category.isOutOfScope })}>
                {category.isOutOfScope ? (
                  <span className='uppercase text-zinc-500 text-xs'>out of scope</span>
                ) : (
                  <span>
                    {simplify(category.totalBiogenic.value)} {category.totalBiogenic.unit}
                  </span>
                )}
              </div>
            </div>

            <div className='hidden print:block pl-[106px] gap-3 py-3 border-t border-zinc-300 bg-neutral-50'>
              {category.isOutOfScope && <div className='uppercase text-xs'>out of scope</div>}
              {category.content.description && <div className='text-xs'>{category.content.description}</div>}

              {!category.isOutOfScope && (
                <div className='flex flex-col gap-3'>
                  <div className='flex flex-col gap-1.5'>
                    <div className='text-xs uppercase'>includes</div>
                    <ul className='ml-3.5 list-disc'>
                      {category.content.includes?.items.map(({ item }, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>

                  <div className='flex flex-col gap-1.5'>
                    <div className='text-xs uppercase'>excludes</div>
                    <ul className='list-disc ml-3.5'>
                      {category.content.excludes?.items.map(({ item }, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>

            {expanded.includes(category.name) && (
              <div className=' pl-[106px] flex flex-col gap-3 py-3 border-t border-zinc-300 bg-neutral-50'>
                {category.isOutOfScope && <div className='uppercase text-xs'>out of scope</div>}
                {category.content.description && <div className='text-xs'>{category.content.description}</div>}

                {!category.isOutOfScope && (
                  <div className='flex flex-col gap-3'>
                    <div className='flex flex-col gap-1.5'>
                      <div className='text-xs uppercase'>includes</div>
                      <ul className='ml-3.5 list-disc'>
                        {category.content.includes?.items.map(({ item }, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <div className='flex flex-col gap-1.5'>
                      <div className='text-xs uppercase'>excludes</div>
                      <ul className='list-disc ml-3.5'>
                        {category.content.excludes?.items.map(({ item }, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
