import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import html2canvas from 'html2canvas';
import { jsPDF as JsPdf } from 'jspdf';
import { PropsWithChildren, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import { GhgProductReport, Lens, ProductReport, ProductType, WarningType, getProductReport } from '../../../../../api';
import { ReportWarning } from '../../../../../components/ReportWarning';
import { TooltipV3 } from '../../../../../components/TooltipV3';
import { useProfile } from '../../../../../hooks/useProfile';
import { ConsumerLensOnePagerReport } from './Consumer/ConsumerLensOnePagerReport';
import { GhgLensOnePagerReport } from './Ghg/GhgLensOnePagerReport';
import { ProductionLensOnePagerReport } from './Production/ProductionLensOnePagerReport';
import { ProductSkeletonLoader } from './components/ProductSkeletonLoader';
import { A4_HEIGHT, A4_WIDTH } from './index';

interface Props {
  data?: ProductReport | GhgProductReport;
  lens: Lens;
  setSelectedReportType: (value: Lens) => void;
  isFoundationEarthWorkspace: boolean;
}

export enum ImpactsRepresentationType {
  Graph = 'Graph view',
  Table = 'Table view',
}

// TODO: move this enum to shared
export enum ImpactValueType {
  Physical = 'physical',
  Points = 'impactPoints',
}

export const executeOnePagerReport = async (ref: any, name: string, onDone: () => void) => {
  const canvas = await html2canvas(ref.current, {
    scale: 4,
    imageTimeout: 2000,
    useCORS: true,
    allowTaint: true,
  });

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const snapshotWidth = A4_WIDTH - 20;
  const snapshotHeight = (snapshotWidth / canvasWidth) * canvasHeight;
  const snapshot = canvas.toDataURL('image/png', 4.0);
  const pdf = new JsPdf('p', 'pt', 'a4');

  pdf.addImage(snapshot, 'PNG', 10, 10, snapshotWidth, snapshotHeight);
  pdf.save(`${name}.pdf`);

  onDone();
};

export const Overview = (props: PropsWithChildren<Props>) => {
  const onePagerReportRef = useRef(null);
  const [scaledTo1kgReportData, setScaledTo1kgReportData] = useState<ProductReport | GhgProductReport>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const profile = useProfile();

  if (!props.data) {
    return <ProductSkeletonLoader lens={props.lens} />;
  }

  const title = `${props.data.product.name} (${props.data.product.amount.value}${props.data.product.amount.unit.name}) - Report Overview`;

  return (
    <div className='flex flex-col print:mt-0 -mt-10 mb-20'>
      {scaledTo1kgReportData && (
        <div
          style={{
            width: A4_WIDTH,
            height: A4_HEIGHT,
          }}
          className='absolute -translate-y-full text-dark'
        >
          {(() => {
            switch (props.lens) {
              case Lens.Production:
                return (
                  <ProductionLensOnePagerReport
                    ref={onePagerReportRef}
                    setLoading={setLoading}
                    data={scaledTo1kgReportData as ProductReport}
                    setData={setScaledTo1kgReportData}
                  />
                );
              case Lens.Consumer:
                return (
                  <ConsumerLensOnePagerReport
                    ref={onePagerReportRef}
                    setLoading={setLoading}
                    data={scaledTo1kgReportData as ProductReport}
                    setData={setScaledTo1kgReportData}
                  />
                );
              case Lens.Ghg:
                return (
                  <GhgLensOnePagerReport
                    ref={onePagerReportRef}
                    setLoading={setLoading}
                    data={scaledTo1kgReportData as GhgProductReport}
                    setData={setScaledTo1kgReportData}
                  />
                );
              default:
                return;
            }
          })()}
        </div>
      )}
      <Helmet title={title} />
      <div className='sticky print:static top-0 z-[1] bg-white'>
        <div className='flex items-center justify-between gap-2 h-20 mx-3 px-3'>
          <div className='print:hidden flex items-center gap-3 text-lg font-semibold truncate'>
            <NavLink className='hover:underline' to='/products'>
              Products
            </NavLink>
            <FontAwesomeIcon size='xs' icon={solid('chevron-right')} />
            <div className='truncate'>{title}</div>
          </div>

          <div className='hidden print:block text-lg font-semibold truncate'>{title}</div>

          <div className='flex items-center gap-4 print:hidden'>
            {profile.selectedWorkspace.permissions.productManagement && (
              <button
                type='button'
                className='text-violet-950 whitespace-nowrap text-sm bg-slate-200 font-semibold active:scale-95 px-4 py-2 rounded-full'
                onClick={() => navigate(`/products/${props.data!.product.id}`)}
              >
                Edit Product
              </button>
            )}
          </div>
        </div>
        <div className='border-t mx-3' />
      </div>

      <div className='flex flex-col gap-x-4 px-6'>
        <div className='flex justify-between gap-x-2 w-full my-3'>
          <div
            className={cn(
              'print:hidden grid divide-x',
              { 'grid-cols-3': props.data.product.productType === ProductType.Final },
              { 'grid-cols-2': props.data.product.productType !== ProductType.Final },
            )}
          >
            {[
              {
                label: 'PEF - Production impact',
                description: `Cradle-to-grave impact of 1 produced unit (${props.data.product.amount.value}${props.data.product.amount.unit.name})`,
                isActive: props.lens === Lens.Production,
                onClick: () => navigate(`../../${Lens.Production}/overview`),
              },
              ...(props.data.product.productType === ProductType.Final
                ? [
                    {
                      label: 'PEF - Consumer impact',
                      description: `Cradle-to-grave impact of ${props.isFoundationEarthWorkspace ? 'consumed' : 'sold'} product`,
                      isActive: props.lens === Lens.Consumer,
                      onClick: () => navigate(`../../${Lens.Consumer}/overview`),
                    },
                  ]
                : []),
              {
                label: 'GHG Protocol emissions',
                description: `Est. GHG emissions of 1 unit of product (${props.data?.product.amount.value}${props.data?.product.amount.unit.name})`,
                isActive: props.lens === Lens.Ghg,
                onClick: () => navigate(`../../ghg/overview`),
              },
            ].map((item, i, arr) => (
              <div
                key={i}
                className={cn('flex flex-col justify-between truncate', i === 0 ? 'pr-3' : i === arr.length - 1 ? 'pl-3' : 'px-3')}
              >
                <button onClick={item.onClick} className={cn('flex gap-2 py-2 cursor-pointer')} key={item.label}>
                  <div className='flex flex-col gap-0.5 truncate'>
                    <div className={cn('font-semibold', item.isActive ? 'text-dark' : 'text-zinc-500')}>{item.label}</div>
                    <div
                      title={item.description}
                      className={cn('text-sm whitespace-nowrap truncate', item.isActive ? 'text-zinc-500' : 'text-zinc-400')}
                    >
                      {item.description}
                    </div>
                  </div>
                </button>
                <div className={cn('h-1', item.isActive ? 'bg-brand' : 'bg-transparent')}></div>
              </div>
            ))}
          </div>

          <div className='flex items-center gap-x-3'>
            {props.data && props.data?.warnings.length === 0 && (
              <button
                type='button'
                onClick={() => navigate(`../summary`)}
                className={cn(
                  'h-8 self-center focus:scale-95 whitespace-nowrap text-base font-semibold bg-brand text-white px-4 rounded-lg',
                  {
                    hidden: props.data.firstPartyDataPercentage < 100,
                  },
                )}
              >
                {
                  {
                    [Lens.Production]: 'See Screening LCA',
                    [Lens.Consumer]: 'See Consumer Report',
                    [Lens.Ghg]: 'See Scope 3 assessment',
                  }[props.lens]
                }
              </button>
            )}

            {props.data.product.productType !== ProductType.Internal && (
              <TooltipV3
                placement='bottom-end'
                content={<div className='bg-[#220065] px-2 py-1 rounded-lg shadow text-white text-xs'>Download summary report</div>}
              >
                <button
                  disabled={loading}
                  className={cn(
                    'size-8 focus:scale-95 flex self-center items-center justify-center border border-[#220065] rounded-lg',
                    loading && 'cursor-wait',
                  )}
                  onClick={() => {
                    setLoading(true);
                    getProductReport(props.data!.product.id, props.lens, 1, 'kg').call({
                      ok: (data) => setScaledTo1kgReportData(data),
                    });
                  }}
                >
                  <FontAwesomeIcon className='size-5 text-[#220065]' icon={light('file-arrow-down')} />
                </button>
              </TooltipV3>
            )}
          </div>
        </div>

        <div className='border-t -mx-3' />

        {props.data.warnings.length > 0 && (
          <div className='mt-6'>
            {(() => {
              switch (props.data?.warnings[0].id) {
                case WarningType.DraftProductPlaceholders:
                  return (
                    <ReportWarning
                      theme='amber'
                      icon={<FontAwesomeIcon className='text-amber-400' size='3x' icon={regular('exclamation-triangle')} />}
                      title='Interim Results - Incomplete product'
                      text='This product contains some placeholders which were purposely ignored to show you some preliminary results on this dashboard. Placeholders are created when we are missing a process in our database required in the making of your product. Our team is actively working on getting those ready for you and results will automatically be recalculated once those processes become available.'
                    />
                  );
                case WarningType.DraftProductDraftInternal:
                  return (
                    <ReportWarning
                      theme='amber'
                      icon={<FontAwesomeIcon className='text-amber-400' size='3x' icon={regular('exclamation-triangle')} />}
                      title='Interim Results - Incomplete product'
                      text='One or more internal product used as raw material in this product are incomplete (draft state). Their interim impact is used to estimate the impact of the product below. Once all errors in the internal products are resolved, and the products become complete, the impact of this product will be automatically recalculated and its full report will become available.'
                    />
                  );
                case WarningType.DraftProductPlaceholdersDraftInternal:
                  return (
                    <ReportWarning
                      theme='amber'
                      icon={<FontAwesomeIcon className='text-amber-400' size='3x' icon={regular('exclamation-triangle')} />}
                      title='Interim Results - Incomplete product'
                      text='The results presented below are non-final as both placeholders and incomplete internal products were found in this product. In order to show you some preliminary results on this dashboard, placeholders were ignored and the interim impact of the draft internal product(s) used. Our team is actively working on creating the missing processes. Once these are replaced by our team and all errors in the internal product(s) are resolved, making those products complete, the impact of the product below will be automatically recalculated and its full report will become available.'
                    />
                  );
                case WarningType.CompleteProductDraftInternal:
                  return (
                    <ReportWarning
                      theme='amber'
                      icon={<FontAwesomeIcon className='text-amber-400' size='3x' icon={regular('exclamation-triangle')} />}
                      title='Interim Results - Incomplete product'
                      text='One or more internal product used as raw material in this product are incomplete (draft state). Their interim impact is used to estimate the impact of the product below. Once all errors in the internal products are resolved, and the products become complete, the impact of this product will be automatically recalculated and its full report will become available.'
                    />
                  );
                case WarningType.CompleteProductScreeningLca:
                  return (
                    <ReportWarning
                      theme='red'
                      icon={
                        <div className='size-9 bg-rose-500 rounded-full flex items-center justify-center p-2'>
                          <FontAwesomeIcon className='size-5 text-white' icon={solid('percent')} />
                        </div>
                      }
                      title='Screening LCA threshold not met'
                      text='Not enough first-party data was provided for this product to meet the screening LCA threshold level (first-party data < 100%). A lot of defaults were automatically made by the system and as such, all results presented on this page hold a lot of uncertainty and should be interpreted with caution.'
                    />
                  );
                case WarningType.DraftProduct:
                  return (
                    <ReportWarning
                      theme='amber'
                      icon={<FontAwesomeIcon className='text-amber-400' size='3x' icon={regular('exclamation-triangle')} />}
                      title='Interim Results - Incomplete product'
                      text='This product is a draft and is incomplete. The impact presented below reflects the impact of what is currently in the graph, with any disconnected nodes purposely ignored to show you some preliminary results.'
                    />
                  );

                default:
                  return <></>;
              }
            })()}
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};
