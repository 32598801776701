import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { GenericNode } from '../../../../api';
import { BadgesHelpPopover } from './BadgesHelp';

interface Props {
  noFlag?: boolean;
}

// everything here must not be a button, otherwise it steals autofocus
export const ModalHeaderRightBar = (props: Props) => {
  const formik = useFormikContext<GenericNode>();
  return (
    <div className='flex gap-4'>
      {!props.noFlag && (
        <div
          className='cursor-pointer select-none flex items-center text-brandDarkPurple2'
          onClick={() => formik.setFieldValue('flagged', !formik.values.flagged)}
        >
          <FontAwesomeIcon icon={formik.values.flagged ? solid('flag') : regular('flag')} />
        </div>
      )}
      <BadgesHelpPopover />
    </div>
  );
};
