import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface Button {
  label: string;
  icon?: IconDefinition;
  active: boolean;
  disabled?: boolean;
}

interface Props {
  button1: Button;
  button2: Button;
  toggleView: () => void;
  theme: 'light' | 'dark';
}

export const ViewToggle = (props: Props) => {
  const activeTheme = props.theme === 'light' ? 'bg-slate-200 text-violet-950' : 'bg-brandDark disabled:bg-brandDark/50 text-white';
  const staticStyle =
    'flex items-center gap-2 text-sm h-full px-4 py-2 font-semibold rounded-full disabled:opacity-60 disabled:cursor-not-allowed';

  return (
    <div className='print:hidden flex self-center rounded-full border shadow-xs shadow-inner bg-white whitespace-nowrap'>
      <button
        disabled={props.button1.disabled}
        onClick={() => props.toggleView()}
        className={cn(staticStyle, props.button1.active ? activeTheme : 'text-neutral-500 disabled:text-zinc-600')}
      >
        {props.button1.icon && <FontAwesomeIcon icon={props.button1.icon} />}
        {props.button1.label}
      </button>
      <button
        disabled={props.button2.disabled}
        onClick={() => props.toggleView()}
        className={cn(staticStyle, props.button2.active ? activeTheme : 'text-neutral-500 disabled:text-zinc-600')}
      >
        {props.button2.icon && <FontAwesomeIcon icon={props.button2.icon} />}
        {props.button2.label}
      </button>
    </div>
  );
};
