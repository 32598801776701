import { format } from 'date-fns';
import { CreateReport } from '../../../../components/Lists/CreateReport';
import { ReportType } from '../../../../../../../api';
import { useRef } from 'react';
import { Import } from '../../../../components/Import';
import { ModalApi } from '../../../../../../../components/ModalV3';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  title: string;
  onNext: () => void;
}

export const Step3 = (props: Props) => {
  const modalRef = useRef<ModalApi>(null);

  return (
    <>
      <Import preventDismiss modalRef={modalRef} />
      <div className='font-semibold text-xl'>Adding actual manufactured quantities</div>
      <div>
        How many of each product did you manufacture over the given time period{' '}
        <span className='font-semibold'>{format(props.startDate!, 'dd/MM/yyyy')}</span> and{' '}
        <span className='font-semibold'>{format(props.endDate!, 'dd/MM/yyyy')}</span>? Add those in the table below.
      </div>
      <CreateReport
        dataFromCsv
        reportType={ReportType.Baseline}
        reportTitle={props.title}
        dateRange={{ startDate: props.startDate!, endDate: props.endDate! }}
        onNext={props.onNext}
      />
    </>
  );
};
