import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldProps } from 'formik';
import { Fragment, PropsWithChildren, useState } from 'react';
import * as yup from 'yup';
import { setSelectedWorkspaceId, updatePassword } from '../../api';
import { InputV3 } from '../../components/InputV3';
import { ModalForm } from '../../components/ModalForm';
import { useProfile } from '../../hooks/useProfile';

enum Tab {
  Details,
  Workspaces,
}

export const Account = () => {
  const profile = useProfile();
  const [tab, setTab] = useState(Tab.Details);
  const [passwordChanged, setPasswordChanged] = useState(false);

  return (
    <div className='flex flex-col gap-6 text-zinc-800'>
      <div className='font-semibold text-lg px-6 text-zinc-900'>{profile.selectedWorkspace.name}'s Workspace</div>
      <div className='shrink-0 h-px bg-zinc-300' />
      <div className='flex gap-6 px-6'>
        {[
          { label: 'Details', id: Tab.Details },
          { label: 'My Workspaces', id: Tab.Workspaces },
        ].map(({ label, id }, i) => (
          <Fragment key={id}>
            {i > 0 && <div className='shrink-0 w-px bg-zinc-300' />}
            <button type='button' className='flex flex-col gap-1' onClick={() => setTab(id)}>
              <div className='w-full h-[3px]' />
              <div className='px-1.5 uppercase font-semibold text-xs'>{label}</div>
              <div
                className={cn('bg-brand w-full h-[3px]', {
                  invisible: tab !== id,
                })}
              />
            </button>
          </Fragment>
        ))}
      </div>
      <div className='font-semibold px-6 my-4'>{tab === Tab.Details ? 'Personal details' : 'My Workspaces'}</div>
      {tab === Tab.Details ? (
        <>
          <div className='grid grid-cols-[max-content_1fr] gap-x-12 gap-y-6 px-6'>
            <div className='text-zinc-600'>Name</div>
            <div className='font-semibold'>{profile.user.name}</div>
            <div className='text-zinc-600'>Organisation</div>
            <div className='flex items-center gap-2'>
              {profile.user.logoUrl && <img src={profile.user.logoUrl} alt='Logo' className='w-6 aspect-square rounded-full' />}
              <div className='font-semibold text-sm'>{profile.user.company}</div>
            </div>
            <div className='text-zinc-600'>Organisation ID</div>
            <div className='font-semibold'>{profile.selectedWorkspace.customerSid}</div>
            <div className='text-zinc-600'>Password</div>
            <div>
              {passwordChanged ? (
                <div className='flex items-center gap-2 font-semibold text-lime-600'>
                  <FontAwesomeIcon icon={regular('check-circle')} />
                  Password changed
                </div>
              ) : (
                <PasswordModal onSave={() => setPasswordChanged(true)}>
                  <button type='button' className='text-brand'>
                    Change password
                  </button>
                </PasswordModal>
              )}
            </div>
          </div>
          <div className='shrink-0 h-px bg-zinc-300' />
          <div className='flex flex-col gap-1'>
            <div className='font-semibold px-6'>Delete account</div>
            <div className='text-sm px-6'>
              To permanently delete your account, please contact our{' '}
              <a href='mailto:impact-help@sustained.com' className='underline'>
                customer support
              </a>
              .
            </div>
          </div>
          <div className='shrink-0 h-px bg-zinc-300' />
        </>
      ) : (
        <div className='grid grid-cols-[max-content_1fr] text-sm px-6'>
          <div className='uppercase text-[10px] text-zinc-600 bg-slate-50 pl-3 pr-6 py-1'>Name</div>
          <div className='pl-6 bg-slate-50' />
          {profile.workspaces.map((workspace) => (
            <Fragment key={workspace.workspaceSid}>
              <div className='flex items-center gap-3 pl-3 pr-6 h-16'>
                <div className='flex w-8 aspect-square rounded-full'>
                  {workspace.logoUrl ? (
                    <img src={workspace.logoUrl} alt='Logo' />
                  ) : (
                    <div className='w-full h-full flex justify-center items-center bg-[#E8EAF5] rounded-full'>
                      <FontAwesomeIcon icon={regular('building')} />
                    </div>
                  )}
                </div>
                <div className='text-zinc-600'>{workspace.name}</div>
              </div>
              <div className='flex items-center pl-6'>
                {workspace.workspaceSid === profile.selectedWorkspace.workspaceSid ? (
                  <div className='uppercase px-2 py-1 rounded-lg font-semibold text-xs text-zinc-500 bg-brandLime shadow-[0_0_6px_2px_rgba(0,0,0,0.1)]'>
                    Current Workspace
                  </div>
                ) : (
                  <button
                    type='button'
                    className='text-brand py-2'
                    onClick={() => {
                      setSelectedWorkspaceId(workspace.workspaceSid);
                      profile.onWorkspaceChanged();
                    }}
                  >
                    Switch to this workspace
                  </button>
                )}
              </div>
              <div className='col-span-2 h-px bg-zinc-300' />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

type Props = PropsWithChildren<{
  onSave: () => void;
}>;

export const PasswordModal = (props: Props) => (
  <ModalForm
    size='narrow-'
    title='Change password'
    body={
      <div className='flex flex-col gap-8'>
        <div>Create a new password that is at least 8 characters long, with at least 1 uppercase, 1 lowercase and 1 number.</div>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>New password</div>
          <Field name='newPassword'>
            {(model: FieldProps) => (
              <>
                <InputV3 model={model} password placeholder='Type in your new password…' />
                <div className='pl-1.5 text-red-500 text-xs'>{model.meta.error}</div>
              </>
            )}
          </Field>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='pl-1.5'>Confirm new password</div>
          <Field name='password2'>
            {(model: FieldProps) => (
              <>
                <InputV3 model={model} password placeholder='Type in your new password again to confirm it…' />
                <div className='pl-1.5 text-red-500 text-xs'>{model.meta.error}</div>
              </>
            )}
          </Field>
        </div>
      </div>
    }
    data={{
      newPassword: '',
      password2: '',
    }}
    validationSchema={yup.object({
      newPassword: yup
        .string()
        .min(8, 'Must be at least 8 characters long')
        .test('lower', 'Must contain at least 1 lowercase letter', (value) => /[a-z]/.test(value as string))
        .test('upper', 'Must contain at least 1 uppercase letter', (value) => /[A-Z]/.test(value as string))
        .test('number', 'Must contain at least 1 number', (value) => /[0-9]/.test(value as string)),
      password2: yup.string().test('matching', 'Passwords must match', (value, context) => context.parent.newPassword === value),
    })}
    onSave={({ values, closeModal }) => {
      updatePassword(values).ok(() => {
        props.onSave();
        closeModal();
      });
    }}
  >
    {props.children}
  </ModalForm>
);
