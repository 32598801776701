import { ManufacturingGhgReport } from '../../../../../../api';
import { NavigationButtons } from '../../../../Products/Report/Sku/components/NavigationButtons';
import { CollapsibleSection } from '../../../../Products/Report/CollapsibleSection';
import cn from 'classnames';

interface Props {
  data: ManufacturingGhgReport;
}

export const GoalAndScope = (props: Props) => {
  return (
    <div className='flex flex-col gap-8 print:text-sm text-dark'>
      <div className='flex justify-between gap-8'>
        <div className='text-brandGray5 ml-3'>
          An overview of the goal and scope as well as methodology used to carry out this assessment.
        </div>
        <NavigationButtons type='icons' back={{ path: '../summary' }} next={{ path: '../results' }} />
      </div>
      <CollapsibleSection title='Goal and scope'>
        <div className='flex flex-col gap-6'>
          <div>
            The goal of this analysis is to provide an estimate of the scope 3 contribution of all produced products over a given timeframe
            based on the underlying product-level assessment carried out. Scope 1 and 2 emissions are provided as reference but rely mostly
            on secondary data. A detailed list of all scope 3 categories is provided here with details on whether they are in or out of
            scope of this assessment:
          </div>
          <div className='divide-y'>
            {props.data.analysis.categories.map((category, i) => (
              <div key={i} className='grid grid-cols-2 py-3'>
                <div>
                  Category {i + 1}: {category.name}
                </div>
                <div className={cn('uppercase text-xs', { 'text-gray-400': category.isOutOfScope })}>
                  {category.isOutOfScope ? 'Out of scope' : 'In scope'}
                </div>
              </div>
            ))}
          </div>
        </div>
      </CollapsibleSection>
      <CollapsibleSection title='Methodology'>
        <div className='flex flex-col gap-y-3'>
          <div>
            The results presented in this report were obtained by following the{' '}
            <a
              rel='noreferrer'
              className='underline hover:text-brand'
              target='_blank'
              href='https://ghgprotocol.org/sites/default/files/standards/Corporate-Value-Chain-Accounting-Reporing-Standard_041613_2.pdf'
            >
              Corporate Value Chain (Scope 3) Accounting and Reporting Standard
            </a>
            , using the OpenLCA v.1.11.0 software for emission calculations. Details on the Global Warming Potential (GWP) values assumed
            for each individual gas are presented in the appendix.
          </div>
          <div>The final analysis was made with the following Sustained Impact web application and methodology versions:</div>
          <ul>
            <li>Sustained Impact product v.{props.data.metadata.webAppVersion}</li>
            <li>Sustained Impact methodology v.{props.data.metadata.methodologyVersion}</li>
          </ul>
        </div>
      </CollapsibleSection>
      <NavigationButtons type='buttons' back={{ path: '../volumes', label: 'Volumes' }} next={{ path: '../results', label: 'Results' }} />
    </div>
  );
};
