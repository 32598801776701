import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { format } from 'date-fns';
import { Fragment, ReactNode, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  BaselinePefReport,
  DashboardStatusProduct,
  Flow,
  HistoricalPefReport,
  ImpactTracking,
  InDevelopment,
  TargetType,
  PortfolioBaselineImpact,
  PortfolioBaselineNotSet,
  PortfolioBaselineSet,
  PortfolioDashboard,
  PortfolioEmpty,
  PortfolioFirstPartyData,
  PortfolioHistoricalCreated,
  PortfolioState,
  ProductsEntriesCompletion,
  Targets,
  Tracking,
  getPefBaseline,
  getPefHistoricalReport,
  ReportType,
} from '../../../api';
import { ByImpactContributionTable } from '../../../components/ByImpactContributionTable';
import { LimitTooltip } from '../../../components/LimitTooltip';
import { ModalV3 } from '../../../components/ModalV3';
import { SingleSelect } from '../../../components/SingleSelect';
import { TooltipV3 } from '../../../components/TooltipV3';
import { ViewToggle } from '../../../components/ViewToggle';
import { ProgressChart } from '../../../components/charts/ProgressTracking/ProgressChart';
import { ProgressChartLegend } from '../../../components/charts/ProgressTracking/ProgressChartLegend';
import { getImpactIcon } from '../../../icons/Impact';
import { createProduct } from '../Products/Details/dataModel';
import { ImpactValueType } from '../Products/Report/Sku/Overview';
import { useProfile } from '../../../hooks/useProfile';
import { simplify, roundToLong, roundToShort } from '../shared';
import { ListOfProducts } from './ListOfProducts';
import { SkeletonLoader } from './SkeletonLoader';
import { TargetsCarousel } from './TargetsCarousel';
import { useProgressChartEnabled } from '../../../hooks/useProgressChartEnabled';
import { ReadOnlyWarning } from '../../../components/ReadOnlyWarning';
import { convert } from '../Prototype/sections/trackigDataConverterV2';
import { ImpactTargetHints } from '../../../components/ImpactTargetHints';

interface Props {
  data: PortfolioDashboard;
  selectedFlow: Flow;
  targetWidgetRef: RefObject<HTMLDivElement>;
  baselineReport?: BaselinePefReport;
  setBaselineReport: (report: SetStateAction<BaselinePefReport | undefined>) => void;
  latestHistoricalReport?: HistoricalPefReport;
  setLatestHistoricalReport: (report: SetStateAction<HistoricalPefReport | undefined>) => void;
}

export const DynamicStatus = (props: Props) => {
  const state = props.data.state;
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [createLimit, setCreateLimit] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [selectedImpact, setSelectedImpact] = useState<ImpactTracking | null>(null);
  const [selectedImpactUnit, setSelectedImpactUnit] = useState<ImpactValueType>(ImpactValueType.Points);
  const navigate = useNavigate();
  const enabled = useProgressChartEnabled({
    isBaselineSet: props.data?.tracking?.impacts[0].baseline !== undefined,
    data: props.data.tracking?.impacts,
    selectedImpact: selectedImpact,
  });
  const profile = useProfile();

  useEffect(() => {
    if (props.data.state === PortfolioState.HistoricalCreated) {
      setSelectedImpact((props.data as PortfolioHistoricalCreated).tracking.impacts[0]);
    }
  }, [props.data]);

  useEffect(() => {
    const onScroll = (event: Event) => {
      const scrollable = event.target as HTMLDivElement;
      setCanScrollLeft(scrollable.scrollLeft > 0);
      setCanScrollRight(Math.round((scrollable.scrollWidth - scrollable.scrollLeft) / 10) * 10 !== scrollable.clientWidth);
    };

    const ref = scrollableRef.current;

    if (ref) {
      setCanScrollRight(ref.clientWidth < ref.scrollWidth);
      ref.addEventListener('scroll', onScroll);
    }

    return () => ref?.removeEventListener('scroll', onScroll);
  }, []);

  const tile = (children: ReactNode) => (
    <div className='break-inside-avoid-page flex flex-col gap-3 border py-4 px-6 rounded-xl bg-white'>{children}</div>
  );

  return (
    <div className='relative w-full xl:w-[theme(screens.xl)]'>
      <div className='print:hidden absolute z-10 top-0 left-0 w-14 h-full bg-gradient-to-r from-slate-50 via-65% via-slate-50 flex items-center justify-center'>
        <button
          onClick={() => scrollableRef.current && scrollableRef.current.scrollTo({ left: 0, behavior: 'smooth' })}
          className={cn('text-zinc-400 text-3xl hover:text-brand transition-all', { invisible: !canScrollLeft })}
        >
          <FontAwesomeIcon icon={regular('chevron-left')} />
        </button>
      </div>
      <div className='print:hidden absolute z-10 top-0 right-0 w-14 h-full bg-gradient-to-l from-slate-50 via-65% via-slate-50 flex items-center justify-center'>
        <button
          onClick={() =>
            scrollableRef.current && scrollableRef.current.scrollTo({ left: scrollableRef.current.clientWidth, behavior: 'smooth' })
          }
          className={cn('text-zinc-400 text-3xl hover:text-brand transition-all', { invisible: !canScrollRight })}
        >
          <FontAwesomeIcon icon={regular('chevron-right')} />
        </button>
      </div>

      <div ref={scrollableRef} className='relative overflow-y-auto flex bg-slate-50 print:bg-white'>
        <div className='flex-1 flex flex-col'>
          <div className='print:flex-col print:flex print:self-start grid grid-flow-col auto-cols-max gap-6 py-6 px-12'>
            {(() => {
              const productEntriesTile = (completion: ProductsEntriesCompletion, showAsPlaceholder?: boolean) => {
                return tile(
                  <Fragment>
                    <div className='font-semibold text-lg'>Product entries</div>
                    {!showAsPlaceholder && (
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-1.5'>
                          <span>Total:</span>
                          <span className='text-lg'>
                            <span className='font-semibold'>{completion.complete}</span>
                            <span>/{completion.target}</span>
                          </span>
                        </div>
                      </div>
                    )}

                    <div className='flex mt-1'>
                      <div className='flex h-14 w-64 rounded-md overflow-hidden'>
                        {(() => {
                          const completed = completion.complete;
                          const drafts = completion.drafts;
                          const missing = completion.missing;

                          const total = completed + drafts + missing;

                          const completedShare = (completed / total) * 100;
                          const draftsShare = (drafts / total) * 100;
                          const missingShare = (missing / total) * 100;

                          return total > 0 ? (
                            <>
                              <div
                                title={String(completion.complete)}
                                className='flex items-center justify-center text-sm font-semibold bg-indigo-200'
                                style={{
                                  width: `${completedShare}%`,
                                }}
                              >
                                {completedShare < 10 ? '' : completed}
                              </div>
                              <div
                                title={String(completion.drafts)}
                                className='flex items-center justify-center text-sm font-semibold bg-blue-100'
                                style={{
                                  width: `${draftsShare}%`,
                                }}
                              >
                                {draftsShare < 10 ? '' : drafts}
                              </div>
                              <div
                                title={String(completion.missing)}
                                className='flex items-center justify-center text-sm font-semibold bg-slate-100'
                                style={{
                                  width: `${missingShare}%`,
                                }}
                              >
                                {missingShare < 10 ? '' : missing}
                              </div>
                            </>
                          ) : (
                            <div
                              title={String(completion.missing)}
                              className='flex items-center justify-center text-sm font-semibold bg-slate-100'
                              style={{
                                width: '100%',
                              }}
                            >
                              {missing}/{completion.target}
                            </div>
                          );
                        })()}
                      </div>
                    </div>

                    <div className='text-sm flex justify-between gap-1'>
                      <div className='flex items-center gap-2'>
                        <div className='h-2 aspect-square rounded-full bg-indigo-200 text-4xl' />
                        Complete
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='h-2 aspect-square rounded-full bg-blue-100 text-4xl' />
                        Drafts
                      </div>
                      <div className='flex items-center gap-2'>
                        <div className='h-2 aspect-square rounded-full bg-slate-100 border' />
                        Missing
                      </div>
                    </div>
                    {!showAsPlaceholder && (
                      <LimitTooltip
                        placement='top-start'
                        enabled={createLimit}
                        entityName='products limit'
                        valueKey='maxProductSkuCount'
                        onDismiss={() => setCreateLimit(false)}
                      >
                        <div>
                          <ReadOnlyWarning show={!profile.selectedWorkspace.permissions.productManagement}>
                            <button
                              onClick={() => {
                                createProduct().call({
                                  ok: ({ id, errorCode }) => {
                                    if (errorCode) {
                                      setCreateLimit(true);
                                    } else {
                                      window.open(`/products/${id}`, '_blank');
                                    }
                                  },
                                });
                              }}
                              disabled={!profile.selectedWorkspace.permissions.productManagement}
                              className='flex self-start text-[#330099] disabled:text-zinc-400 disabled:cursor-not-allowed text-xs mt-auto [&:hover:not(:disabled)]:underline'
                            >
                              Create new product
                            </button>
                          </ReadOnlyWarning>
                        </div>
                      </LimitTooltip>
                    )}
                  </Fragment>,
                );
              };

              const firstPartyDataCompletion = (firstParty: PortfolioFirstPartyData) =>
                tile(
                  <Fragment>
                    <div className='font-semibold text-lg'>First-party data completion</div>
                    <div className='flex items-center justify-between gap-6'>
                      <div className='flex items-center gap-1.5'>
                        <div>Average:</div>
                        <div className='text-lg font-semibold'>{firstParty.averageFirstParty}%</div>
                        <div className='uppercase text-xs mt-0.5'>complete</div>
                      </div>
                      <NavLink
                        to='/products?sortBy=firstPartyDataPercentage&sortAscending=true'
                        target='_blank'
                        className='text-[#330099] text-sm hover:underline'
                      >
                        View all
                      </NavLink>
                    </div>

                    <div className='flex gap-4 px-3 mt-auto'>
                      {firstParty.products
                        .filter(({ firstParty }) => firstParty < 100)
                        .sort((a, b) => a.firstParty - b.firstParty)
                        .slice(0, 4)
                        .map(({ id, name, firstParty }, i) => (
                          <NavLink
                            to={`/products/${id}/report/production/overview`}
                            target='_blank'
                            key={i}
                            className='flex flex-col gap-1.5'
                          >
                            <div className='h-24'>
                              <div title={`${firstParty}%`} className='flex items-end h-full w-16 bg-slate-100 rounded-md' key={id}>
                                <div
                                  style={{ height: `${firstParty}%` }}
                                  className='flex items-end justify-center w-full bg-indigo-200 rounded-b-md'
                                >
                                  {firstParty > 30 ? (
                                    <div className='flex gap-x-0.5 items-center pb-1.5'>
                                      <div className='text-xl font-semibold'>{roundToShort(firstParty)}</div>
                                      <div className='text-sm'>%</div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div title={name} className='w-16 truncate text-xs text-[#330099] text-center'>
                              {name}
                            </div>
                          </NavLink>
                        ))}
                    </div>
                  </Fragment>,
                );

              const highestLowestImpactProducts = ({
                highestImpactProduct,
                lowestImpactProduct,
              }: {
                highestImpactProduct: { id: string; name: string; impactPoints: number };
                lowestImpactProduct: { id: string; name: string; impactPoints: number };
              }) => {
                return [
                  { ...highestImpactProduct, title: 'Highest impact product', textColor: 'text-red-600', bgColor: 'bg-red-50' },
                  { ...lowestImpactProduct, title: 'Lowest impact product', textColor: 'text-emerald-700', bgColor: 'bg-emerald-50' },
                ].map((product, i) => (
                  <Fragment key={i}>
                    {tile(
                      <Fragment>
                        <div className='font-semibold text-lg'>{product.title}</div>
                        <div className={cn('flex flex-col gap-y-3 p-3 rounded-md mt-4', product.bgColor)}>
                          <div title={product.name} className='w-56 truncate text-xl'>
                            {product.name}
                          </div>
                          <div className='flex flex-col gap-2'>
                            <div className='flex gap-1 items-center'>
                              <div className={cn('text-xl', product.textColor)}>{simplify(product.impactPoints)}</div>
                              <div className='uppercase text-xs mt-0.5'>impact points</div>
                            </div>
                            <div className='text-zinc-500 text-xs'>Per product unit</div>
                          </div>
                        </div>
                        <NavLink
                          to={`/products/${product.id}/report/production/overview`}
                          target='_blank'
                          className='text-xs text-[#330099] hover:underline mt-auto'
                        >
                          Open full report
                        </NavLink>
                      </Fragment>,
                    )}
                  </Fragment>
                ));
              };

              const baselineImpact = (baselineImpact: PortfolioBaselineImpact) =>
                tile(
                  <Fragment>
                    <div className='flex justify-between items-center gap-4'>
                      <div className='font-semibold text-lg'>Baseline impact</div>
                      <ModalV3
                        size='wide+'
                        confirmLabel='View full report'
                        onConfirm={() => {
                          navigate(`/manufacturing/baseline/overview`);
                        }}
                        onOpenChange={(open) => {
                          if (open && !props.baselineReport) {
                            getPefBaseline().call({
                              ok: (report) => {
                                if (report) {
                                  props.setBaselineReport(report);
                                }
                              },
                            });
                          }
                        }}
                        title='Baseline impact'
                        body={
                          <div className='flex flex-col gap-6 -mt-6 text-base'>
                            <div>
                              <div>{`Timeframe: between ${format(new Date(baselineImpact.startDate), 'dd/MM/yy')} and ${format(
                                new Date(baselineImpact.endDate),
                                'dd/MM/yy',
                              )}`}</div>
                              <div title={roundToLong(baselineImpact.impactPoints)}>
                                Total impact: <span className='font-semibold'>{simplify(baselineImpact.impactPoints)} impact points</span>
                              </div>
                            </div>
                            {props.baselineReport ? (
                              <ByImpactContributionTable
                                data={props.baselineReport.analysis.impactStageMatrix}
                                totalImpact={baselineImpact.overallImpact}
                                totalPoints={baselineImpact.impactPoints}
                              />
                            ) : (
                              <SkeletonLoader />
                            )}
                          </div>
                        }
                      >
                        <button className='hover:text-brand'>
                          <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
                        </button>
                      </ModalV3>
                    </div>
                    <div className='flex items-center gap-1.5'>
                      <div>Total impact:</div>
                      <div className='text-lg font-semibold'>{simplify(baselineImpact.impactPoints)}</div>
                      <div className='uppercase text-xs mt-0.5'>impact points</div>
                    </div>
                    <div className='grid grid-cols-3 gap-3'>
                      {baselineImpact.impacts
                        .filter((impact) => ['pef_climate_change', 'pef_land_use', 'pef_water_scarcity'].includes(impact.id))
                        .map((impact) => (
                          <div key={impact.id} className='p-3 bg-slate-50 rounded-md'>
                            <div className='flex flex-col h-full justify-between gap-2'>
                              <div className='mx-auto w-7 aspect-square text-[#330099]'>
                                {getImpactIcon(
                                  {
                                    pef_climate_change: 'impact-climate-change',
                                    pef_land_use: 'impact-land-use',
                                    pef_water_scarcity: 'impact-water-use',
                                  }[impact.id]!,
                                )}
                              </div>
                              <div title={impact.name} className='text-center text-sm line-clamp-2'>
                                {impact.name}
                              </div>
                              <div
                                title={`${roundToLong(impact.physicalValue)} ${impact.unit}`}
                                className='flex items-center justify-center gap-1 whitespace-nowrap'
                              >
                                <div className='font-semibold'>{simplify(impact.physicalValue)}</div>
                                <div className='text-xs'>{impact.unit}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Fragment>,
                );

              const highestImpactProductsTile = (
                products: DashboardStatusProduct[],
                otherProductsCount: number,
                state: PortfolioState.BaselineSet | PortfolioState.HistoricalCreated,
              ) => {
                const productsOverallContribution = products.reduce((acc, product) => acc + product.contribution, 0);
                const data = state === PortfolioState.BaselineSet ? props.baselineReport : props.latestHistoricalReport;
                return tile(
                  <Fragment>
                    <div className='flex justify-between items-center gap-4'>
                      <div className='font-semibold text-lg'>Cumulative Impact Product Ranking</div>
                      <ModalV3
                        size='wide+'
                        hideConfirm
                        onOpenChange={(isOpen) => {
                          if (isOpen) {
                            if (state === PortfolioState.BaselineSet && !props.baselineReport) {
                              getPefBaseline().call({
                                ok: (report) => {
                                  if (report) {
                                    props.setBaselineReport(report);
                                  }
                                },
                              });
                            }

                            const { latestReportId } = (props.data as PortfolioHistoricalCreated)?.historicalImpact;

                            if (state === PortfolioState.HistoricalCreated && latestReportId && !props.latestHistoricalReport) {
                              getPefHistoricalReport(latestReportId).call({
                                ok: (report) => {
                                  if (report) {
                                    props.setLatestHistoricalReport(report);
                                  }
                                },
                              });
                            }
                          }
                        }}
                        title={
                          <div className='flex flex-col gap-3'>
                            {state === PortfolioState.BaselineSet && <div>Product contribution to the overall baseline impact</div>}
                            {state === PortfolioState.HistoricalCreated && <div>Product contribution to the latest historical impact</div>}
                            {data && (
                              <div className='text-base font-normal'>
                                <div className='text-base'>
                                  Timeframe between: {format(new Date(data.startDate), 'dd/MM/yy')} and{' '}
                                  {format(new Date(data.endDate), 'dd/MM/yy')}
                                </div>
                                <div className='flex items-center gap-1 text-base'>
                                  Total impact: <div className='font-semibold'>{simplify(data.impactPoints)} impact points</div>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        body={
                          data ? (
                            <ListOfProducts showReportButton showModellingButton={false} products={data.products} />
                          ) : (
                            <SkeletonLoader />
                          )
                        }
                      >
                        <button className='hover:text-brand'>
                          <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
                        </button>
                      </ModalV3>
                    </div>

                    <div className={cn('flex flex-col', productsOverallContribution < 100 ? 'gap-1' : 'gap-3')}>
                      {state === PortfolioState.BaselineSet && <div>Top 10 highest impact products in your baseline</div>}
                      {state === PortfolioState.HistoricalCreated && <div>Top 10 based on your latest data upload</div>}

                      <div className='flex items-center gap-2'>
                        <FontAwesomeIcon className='text-brand' icon={light('circle-info')} />
                        <div className='text-sm'>Move over graph to see details</div>
                      </div>
                    </div>

                    <div className='flex mt-1'>
                      <div className='flex w-[350px] overflow-hidden'>
                        {(() => {
                          const productsWithRelativeContribution = products.map((product) => ({
                            ...product,
                            relativeContribution: (product.contribution / productsOverallContribution) * 100,
                          }));

                          return productsWithRelativeContribution.map((product, i, arr) => (
                            <TooltipV3
                              key={i}
                              content={
                                <div className='flex items-center gap-2 rounded-lg shadow-md px-2 py-1 bg-[#330099] text-white whitespace-nowrap'>
                                  <span className='text-xs'>{product.name}</span>
                                  <span className='flex items-center gap-1'>
                                    <div className='text-base font-semibold'>{roundToShort(product.contribution)}</div>
                                    <div className='text-xs'>%</div>
                                  </span>
                                </div>
                              }
                            >
                              <div key={i} style={{ width: `${product.relativeContribution}%` }} className={cn('flex flex-col gap-1.5')}>
                                <div
                                  className={cn(
                                    'h-14 flex items-center justify-center',
                                    [
                                      'bg-indigo-50',
                                      'bg-indigo-200',
                                      'bg-green-50',
                                      'bg-sky-200',
                                      'bg-green-200',
                                      'bg-yellow-50',
                                      'bg-yellow-200',
                                      'bg-sky-50',
                                      'bg-purple-200',
                                      'bg-zinc-100',
                                    ][i],
                                    i === 0 && 'rounded-l-lg',
                                    i === arr.length - 1 && 'rounded-r-lg',
                                  )}
                                >
                                  {(() => {
                                    if (product.contribution > 10) {
                                      return <div className='text-center text-xs'>{roundToShort(product.contribution)}%</div>;
                                    } else if (product.contribution > 5) {
                                      return <div className='text-center text-xs'>{roundToShort(product.contribution)}</div>;
                                    } else {
                                      return '';
                                    }
                                  })()}
                                </div>
                                <div className='truncate text-zinc-500 text-xs'>
                                  {product.relativeContribution > 10 ? product.name : ''}
                                </div>
                              </div>
                            </TooltipV3>
                          ));
                        })()}
                      </div>
                    </div>

                    {productsOverallContribution < 100 && (
                      <div className='flex justify-between text-sm gap-1 -my-1'>
                        {state === PortfolioState.BaselineSet && <div>+{otherProductsCount} other products in your baseline</div>}
                        {state === PortfolioState.HistoricalCreated && (
                          <div>+{otherProductsCount} other products in your latest historical data</div>
                        )}

                        <ModalV3
                          size='wide+'
                          hideConfirm
                          onOpenChange={(isOpen) => {
                            if (isOpen) {
                              if (state === PortfolioState.BaselineSet && !props.baselineReport) {
                                getPefBaseline().call({
                                  ok: (report) => {
                                    if (report) {
                                      props.setBaselineReport(report);
                                    }
                                  },
                                });
                              }

                              const { latestReportId } = (props.data as PortfolioHistoricalCreated)?.historicalImpact;

                              if (state === PortfolioState.HistoricalCreated && latestReportId && !props.latestHistoricalReport) {
                                getPefHistoricalReport(latestReportId).call({
                                  ok: (report) => {
                                    if (report) {
                                      props.setLatestHistoricalReport(report);
                                    }
                                  },
                                });
                              }
                            }
                          }}
                          title={
                            data && (
                              <div className='flex flex-col gap-3'>
                                {state === PortfolioState.BaselineSet && <div>Product contribution to the overall baseline impact</div>}
                                {state === PortfolioState.HistoricalCreated && (
                                  <div>Product contribution to the latest historical impact</div>
                                )}
                                <div className='text-base font-normal'>
                                  <div className='text-base'>
                                    Timeframe between: {format(new Date(data.startDate), 'dd/MM/yy')} and{' '}
                                    {format(new Date(data.endDate), 'dd/MM/yy')}
                                  </div>
                                  <div className='flex items-center gap-1 text-base'>
                                    Total impact: <div className='font-semibold'>{simplify(data.impactPoints)} impact points</div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                          body={
                            data ? (
                              <ListOfProducts showReportButton showModellingButton={false} products={data.products} />
                            ) : (
                              <SkeletonLoader />
                            )
                          }
                        >
                          <button className='hover:underline text-brandDark'>see all</button>
                        </ModalV3>
                      </div>
                    )}
                  </Fragment>,
                );
              };

              const impactFromLatestEntry = (impactFromLatestEntry: PortfolioBaselineImpact) =>
                tile(
                  <Fragment>
                    <div className='flex justify-between items-center gap-4'>
                      <div className='font-semibold text-lg'>Impact from latest entry</div>
                      <ModalV3
                        onOpenChange={(isOpen) => {
                          if (isOpen) {
                            const id = (props.data as PortfolioHistoricalCreated)?.historicalImpact.latestReportId;
                            if (id && !props.latestHistoricalReport) {
                              getPefHistoricalReport(id).call({
                                ok: (report) => {
                                  if (report) {
                                    props.setLatestHistoricalReport(report);
                                  }
                                },
                              });
                            }
                          }
                        }}
                        size='wide+'
                        hideConfirm
                        title={
                          <div className='flex flex-col gap-3'>
                            <div>Impact from latest entry</div>
                            <div className='text-base font-normal'>
                              <div>{`Timeframe: between ${format(new Date(impactFromLatestEntry.startDate), 'dd/MM/yy')} and ${format(
                                new Date(impactFromLatestEntry.endDate),
                                'dd/MM/yy',
                              )}`}</div>
                              <div title={roundToLong(impactFromLatestEntry.impactPoints)}>
                                Total impact:{' '}
                                <span className='font-semibold'>{simplify(impactFromLatestEntry.impactPoints)} impact points</span>
                              </div>
                            </div>
                          </div>
                        }
                        body={
                          props.latestHistoricalReport ? (
                            <div className='flex flex-col gap-6 -mt-6 text-base'>
                              <ByImpactContributionTable
                                data={props.latestHistoricalReport.analysis.impactStageMatrix}
                                totalImpact={impactFromLatestEntry.overallImpact}
                                totalPoints={impactFromLatestEntry.impactPoints}
                              />
                            </div>
                          ) : (
                            <SkeletonLoader />
                          )
                        }
                      >
                        <button className='hover:text-brand'>
                          <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
                        </button>
                      </ModalV3>
                    </div>
                    <div className='flex items-center gap-1.5'>
                      <div>Total impact:</div>
                      <div className='text-lg font-semibold'>{simplify(impactFromLatestEntry.impactPoints)}</div>
                      <div className='uppercase text-xs mt-0.5'>impact points</div>
                    </div>
                    <div className='grid grid-cols-3 gap-3'>
                      {impactFromLatestEntry.impacts
                        .filter((impact) => ['pef_climate_change', 'pef_land_use', 'pef_water_scarcity'].includes(impact.id))
                        .map((impact) => (
                          <div key={impact.id} className='p-3 bg-slate-50 rounded-md'>
                            <div className='flex flex-col h-full justify-between gap-2'>
                              <div className='mx-auto w-7 aspect-square text-[#330099]'>
                                {getImpactIcon(
                                  {
                                    pef_climate_change: 'impact-climate-change',
                                    pef_land_use: 'impact-land-use',
                                    pef_water_scarcity: 'impact-water-use',
                                  }[impact.id]!,
                                )}
                              </div>
                              <div title={impact.name} className='text-center text-sm line-clamp-2'>
                                {impact.name}
                              </div>
                              <div
                                title={`${roundToLong(impact.physicalValue)} ${impact.unit}`}
                                className='flex items-center justify-center gap-1'
                              >
                                <div className='font-semibold whitespace-nowrap'>{simplify(impact.physicalValue)}</div>
                                <div className='text-xs truncate'>{impact.unit}</div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Fragment>,
                );

              const progressTrackingTile = (tracking: Tracking, targets: Targets) => {
                return (
                  enabled &&
                  tile(
                    <Fragment>
                      <div className='flex justify-between items-center gap-4'>
                        <div className='flex items-center gap-x-4 font-semibold text-lg'>
                          <div>Progress tracking</div>
                        </div>
                        <div className='flex items-center gap-x-8'>
                          <>
                            <select
                              className='w-60 truncate text-sm text-right pr-0.5 focus:ring-0 text-brand font-semibold hover:cursor-pointer'
                              value={selectedImpact?.name}
                              onChange={(e) => {
                                setSelectedImpact(tracking.impacts.find((impact) => impact.name === e.target.value)!);
                              }}
                            >
                              {tracking.impacts.map((impact) => (
                                <option key={impact.id} label={impact.name} value={impact.name} />
                              ))}
                            </select>

                            <ModalV3
                              size='wide+'
                              hideConfirm
                              title='Progress tracking'
                              body={
                                <>
                                  {selectedImpact && (
                                    <div className='flex flex-col gap-y-6 -mt-6 pt-2'>
                                      <div className='flex flex-col items-center gap-y-4'>
                                        <div className='w-60'>
                                          <SingleSelect
                                            options={(props.data as { tracking: Tracking }).tracking.impacts.map((item) => ({
                                              ...item,
                                              disabled: false,
                                              renderIcon: item.target !== undefined,
                                            }))}
                                            value={{
                                              value: selectedImpact.id,
                                              label: selectedImpact.name,
                                            }}
                                            setSelectedId={(v) => {
                                              if (v === 'overall') {
                                                setSelectedImpactUnit(ImpactValueType.Points);
                                              }
                                              setSelectedImpact(
                                                (props.data as { tracking: Tracking }).tracking.impacts.find((impact) => impact.id === v)!,
                                              );
                                            }}
                                            selectedId={selectedImpact.id}
                                          />
                                        </div>

                                        <ViewToggle
                                          theme='light'
                                          button1={{
                                            label: 'Final environmental impact',
                                            active: selectedImpactUnit === ImpactValueType.Points,
                                            disabled: selectedImpact.id === 'overall',
                                          }}
                                          button2={{
                                            label: 'Physical Impact',
                                            active: selectedImpactUnit === ImpactValueType.Physical,
                                            disabled: selectedImpact.id === 'overall',
                                          }}
                                          toggleView={() => {
                                            setSelectedImpactUnit((current) =>
                                              current === ImpactValueType.Points ? ImpactValueType.Physical : ImpactValueType.Points,
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className='flex flex-col items-center shadow-regular rounded-2xl'>
                                        <div className='text-lg border-b border-zinc-300 font-semibold text-center p-4 w-full'>
                                          Actual and estimated volume impact over time
                                        </div>
                                        <div className='p-6'>
                                          <ImpactTargetHints
                                            reportType={ReportType.Historical}
                                            selectedUnit={selectedImpactUnit}
                                            selectedImpact={selectedImpact}
                                            targetData={convert(selectedImpact).getTargetData()}
                                            targetHint={convert(selectedImpact).getTargetHint()}
                                            targets={targets}
                                          />
                                        </div>
                                        <div className='grid grid-cols-[8fr_3fr] gap-6 p-6 pb-2'>
                                          <div className='h-64 text-xs ml-20'>
                                            <ProgressChart
                                              isParentModal
                                              selectedType={selectedImpactUnit}
                                              selectedImpact={selectedImpact}
                                              impacts={tracking.impacts}
                                              targets={targets}
                                            />
                                          </div>
                                          <div className='text-sm leading-4'>
                                            <ProgressChartLegend />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              }
                            >
                              <button className='hover:text-brand'>
                                <FontAwesomeIcon icon={regular('arrow-up-right-and-arrow-down-left-from-center')} />
                              </button>
                            </ModalV3>
                          </>
                        </div>
                      </div>

                      {selectedImpact && (
                        <div className='grid grid-cols-[4fr_1fr] gap-x-3 h-full'>
                          <div className='h-full w-[390px] pl-16 text-xs pt-2'>
                            <ProgressChart
                              small
                              selectedType={ImpactValueType.Points}
                              selectedImpact={selectedImpact}
                              impacts={tracking.impacts}
                              targets={targets}
                            />
                          </div>
                          <div className='flex flex-col gap-y-2 -mr-3'>
                            <div className='uppercase text-zinc-400 text-[10px]'>key</div>
                            <div className='flex items-center gap-x-2'>
                              <div className='relative -top-[5px] w-8'>
                                <div className='absolute border-l-[5px] border-l-brandDarkPurple2 border-t-[4px] border-b-[4px] border-r-brandDarkPurple2 border-transparent' />
                                <div className='absolute top-[3px] h-[2px] bg-brandDarkPurple2 w-full' />
                                <div className='absolute right-0 border-r-[5px] border-r-brandDarkPurple2 border-t-[4px] border-b-[4px] border-transparent' />
                              </div>
                              <div className='text-xs'>Baseline</div>
                            </div>
                            <div className='flex items-center gap-x-2 w-full'>
                              <div className='h-[2px] bg-fuchsia-400 w-8' />
                              <div className='text-xs'>Target</div>
                            </div>
                            <div className='flex items-center gap-x-2 w-full'>
                              <div className='relative w-8 h-3 bg-gray-100'>
                                <svg className='absolute' width='100%' height='100%'>
                                  <pattern width='6' height='6' id='pattern_1' patternTransform='rotate(45)' patternUnits='userSpaceOnUse'>
                                    <line x1='0' y='0' x2='0' y2='6' strokeWidth='4' stroke='#e9d5ff' />
                                  </pattern>
                                  <rect fill='url(#pattern_1)' width='100%' height='100%' />
                                </svg>
                              </div>
                              <div className='text-xs whitespace-nowrap'>Missing data</div>
                            </div>
                            <div className='flex items-center gap-x-2 w-full'>
                              <div className='relative w-8 h-3 bg-zinc-100'></div>
                              <div className='text-xs'>Estimation</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Fragment>,
                  )
                );
              };

              const targetsTile = (targets: Targets) =>
                tile(
                  <Fragment>
                    <div className='font-semibold text-lg'>Targets</div>
                    <div className='flex items-center gap-1.5'>
                      <div>Total:</div>
                      <div className='text-lg font-semibold'>{targets.totalTargets}</div>
                      <div className='uppercase text-xs mt-0.5'>{`target${targets.totalTargets === 1 ? '' : 's'} set`}</div>
                    </div>

                    <TargetsCarousel
                      ref={props.targetWidgetRef}
                      items={targets.targetImpacts.filter(({ type }) => type !== TargetType.DISABLED)}
                    />
                  </Fragment>,
                );

              const inDevelopmentProductsTile = (inDevelopment: InDevelopment) =>
                tile(
                  <Fragment>
                    <div className='font-semibold text-lg'>In-development products</div>
                    <div className='flex gap-x-6 items-center text-[#220065]'>
                      {[
                        { label: 'Total', value: inDevelopment.total },
                        { label: 'Complete', value: inDevelopment.complete },
                        { label: 'Drafts', value: inDevelopment.drafts },
                      ].map(({ label, value }, i) => (
                        <div key={i} className='flex gap-1 items-center'>
                          <div className='text-sm'>{label}:</div>
                          <div className='text-lg font-semibold'>{value}</div>
                        </div>
                      ))}
                    </div>
                    <div className='text-zinc-500 text-xs uppercase'>Best performing products:</div>
                    <div className='grid grid-flow-row auto-rows-max bg-emerald-50 rounded-lg px-3 divide-y'>
                      {inDevelopment.lowestImpactProducts.map((product, i) => (
                        <div key={i}>
                          <NavLink
                            to={`/products/${product.id}/report/production/overview`}
                            target='_blank'
                            className='flex items-center justify-between gap-2 h-8 hover:scale-[101%]'
                          >
                            <div title={product.name} className='w-36 text-sm truncate'>
                              {product.name}
                            </div>
                            <div className='flex items-center gap-1'>
                              <div className='text-emerald-700 font-semibold'>{simplify(product.impactPoints)}</div>
                              <div className='text-[65%] uppercase'>impact points/unit</div>
                            </div>
                          </NavLink>
                        </div>
                      ))}
                    </div>
                  </Fragment>,
                );

              switch (state) {
                case PortfolioState.Empty:
                  const empty = props.data as PortfolioEmpty;
                  return (
                    <>
                      <div className='flex flex-col gap-3 border py-4 px-6 rounded-xl shadow-md bg-white w-[450px]'>
                        <div className='flex gap-x-3'>
                          <FontAwesomeIcon className='h-5 aspect-square p-2 bg-brandLime rounded-full' icon={light('lightbulb')} />
                          <div className='flex flex-col gap-y-3'>
                            <div className='font-semibold text-lg'>Get started</div>
                            <div className='text-sm'>
                              Follow the instructions below to start your journey to impact reduction. In this section, you’ll find the most
                              relevant insights about your workspace, tailored to reflect where you are on your sustainability journey.
                            </div>
                          </div>
                        </div>
                      </div>
                      {productEntriesTile(empty.completion, true)}
                      {tile(
                        <Fragment>
                          <div className='font-semibold text-lg'>Highest impact product</div>
                          <div className='flex items-center justify-center bg-red-50 text-zinc-400 w-full h-full rounded-lg'>
                            No products available
                          </div>
                        </Fragment>,
                      )}
                      {tile(
                        <Fragment>
                          <div className='font-semibold text-lg'>Lowest impact product</div>
                          <div className='flex items-center justify-center bg-emerald-50 text-zinc-400 w-full h-full rounded-lg'>
                            No products available
                          </div>
                        </Fragment>,
                      )}
                    </>
                  );
                case PortfolioState.BaselineNotSet:
                  const baselineNotSet = props.data as PortfolioBaselineNotSet;
                  return (
                    <>
                      {props.selectedFlow === Flow.DesignNew &&
                        baselineNotSet.inDevelopment.total > 0 &&
                        inDevelopmentProductsTile(baselineNotSet.inDevelopment)}
                      {productEntriesTile(baselineNotSet.completion)}
                      {baselineNotSet.firstParty?.products.some(({ firstParty }) => firstParty < 100) &&
                        firstPartyDataCompletion(baselineNotSet.firstParty)}
                      {baselineNotSet.productsImpact && highestLowestImpactProducts(baselineNotSet.productsImpact)}
                      {props.selectedFlow === Flow.ReduceExisting &&
                        baselineNotSet.inDevelopment.total > 0 &&
                        inDevelopmentProductsTile(baselineNotSet.inDevelopment)}
                    </>
                  );
                case PortfolioState.BaselineSet:
                  const baselineSet = props.data as PortfolioBaselineSet;
                  return (
                    <>
                      {props.selectedFlow === Flow.DesignNew &&
                        baselineSet.inDevelopment.total > 0 &&
                        inDevelopmentProductsTile(baselineSet.inDevelopment)}
                      {baselineSet.completion.complete < baselineSet.completion.target && productEntriesTile(baselineSet.completion)}
                      {baselineSet.firstParty?.products.some(({ firstParty }) => firstParty < 100) &&
                        firstPartyDataCompletion(baselineSet.firstParty)}
                      {baselineImpact(baselineSet.baselineImpact)}
                      {highestImpactProductsTile(
                        baselineSet.baselineImpact.products,
                        baselineSet.baselineImpact.otherProductsCount,
                        PortfolioState.BaselineSet,
                      )}
                      {baselineSet.targets.totalTargets > 0 && targetsTile(baselineSet.targets)}
                      {props.selectedFlow === Flow.ReduceExisting &&
                        baselineSet.inDevelopment.total > 0 &&
                        inDevelopmentProductsTile(baselineSet.inDevelopment)}
                    </>
                  );
                case PortfolioState.HistoricalCreated:
                  const historicalCreated = props.data as PortfolioHistoricalCreated;
                  return (
                    <>
                      {props.selectedFlow === Flow.DesignNew &&
                        historicalCreated.inDevelopment.total > 0 &&
                        inDevelopmentProductsTile(historicalCreated.inDevelopment)}
                      {progressTrackingTile(historicalCreated.tracking, historicalCreated.targets)}
                      {impactFromLatestEntry(historicalCreated.historicalImpact)}
                      {historicalCreated.firstParty?.products.some(({ firstParty }) => firstParty < 100) &&
                        firstPartyDataCompletion(historicalCreated.firstParty)}
                      {highestImpactProductsTile(
                        historicalCreated.historicalImpact.products,
                        historicalCreated.historicalImpact.otherProductsCount,
                        PortfolioState.HistoricalCreated,
                      )}
                      {historicalCreated.targets.totalTargets > 0 && targetsTile(historicalCreated.targets)}
                      {props.selectedFlow === Flow.ReduceExisting &&
                        historicalCreated.inDevelopment.total > 0 &&
                        inDevelopmentProductsTile(historicalCreated.inDevelopment)}
                    </>
                  );
                default:
                  return <></>;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};
