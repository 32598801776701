import { duotone, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren } from 'react';
import { NodeType, ProductV3, StorageNode } from '../../../../../api';
import { renderBadgeForTag } from '../Badges';
import { removeStoredItem, replace } from '../dataModel';
import { ItemDetails } from './ItemDetails';

type Props = PropsWithChildren<
  {
    onBack: () => void;
  } & TableProps
>;

export const StorageItems = (props: Props) => (
  <div className='text-xs flex-1 mx-12 flex flex-col rounded-lg bg-white shadow-[0_0_6px_rgba(0,0,0,0.1)]'>
    <div className='flex items-center gap-4 p-4 bg-[#E8EAF5] rounded-t-lg'>
      <button
        type='button'
        onClick={props.onBack}
        className={cn(
          'h-8 aspect-square flex justify-center items-center rounded-lg text-lg my-0.5 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-90',
          props.data.type === NodeType.Store ? 'bg-blue-50 text-blue-900' : 'bg-fuchsia-50 text-fuchsia-900',
        )}
      >
        <FontAwesomeIcon icon={solid('times')} />
      </button>
      <div className='flex rounded-lg p-1 pr-3 bg-white shadow-[0_0_2px_0_rgba(0,0,0,0.2)]'>
        <div className='flex justify-center items-center gap-2'>
          <div
            className={cn(
              'flex justify-center items-center rounded-md h-6 aspect-square',
              props.data.type === NodeType.Store ? 'bg-blue-50 text-blue-900' : 'bg-fuchsia-50 text-fuchsia-900',
            )}
          >
            <FontAwesomeIcon icon={props.data.type === NodeType.Store ? duotone('store') : duotone('warehouse-full')} />
          </div>
          <div className='font-semibold'>{props.data.facility.name}</div>
        </div>
      </div>
    </div>
    <StorageItemsTable {...props} />
  </div>
);

interface TableProps {
  data: StorageNode;
}

export const StorageItemsTable = (props: TableProps) => {
  const formik = useFormikContext<ProductV3>();

  return (
    <table className='border-collapse table-fixed w-full text-xs text-zinc-600'>
      <thead>
        <tr className='h-8 uppercase bg-slate-50 text-[10px]'>
          <th className='font-normal pl-6'>Name</th>
          <th className='font-normal'>Amount</th>
          <th className='font-normal'>Type</th>
          <th className='font-normal'>Storage condition</th>
          <th className='font-normal'>Storage duration</th>
          <th className='pr-6' />
        </tr>
      </thead>
      <tbody>
        {props.data.items.map((item, i) => {
          const pathPrefix = `${props.data.id}/items/${item.id}/`;
          return (
            <tr key={item.id} className='h-14 border-t border-neutral-300'>
              <td className='font-semibold text-zinc-700 pl-6 truncate'>{item.name}</td>
              <td>
                {item.amount.value}
                {item.amount.unit.name}
              </td>
              <td>{item.type}</td>
              <td>
                <div className='flex gap-1.5'>
                  {item.conservation.name}
                  {(() => {
                    const tag = formik.values.metadata.user.find(({ path }) => path.endsWith(`${pathPrefix}conservation/id`))?.tags[0];
                    return tag && renderBadgeForTag(tag);
                  })()}
                </div>
              </td>
              <td>
                <div className='flex gap-1.5'>
                  {item.durationWeeks} week{item.durationWeeks === 1 ? '' : 's'}
                  {(() => {
                    const tag = formik.values.metadata.user.find(({ path }) => path.endsWith(`${pathPrefix}durationWeeks`))?.tags[0];
                    return tag && renderBadgeForTag(tag);
                  })()}
                </div>
              </td>
              <td className='pr-6'>
                <div className='flex justify-end gap-5 text-sm'>
                  <ItemDetails
                    parentNode={props.data}
                    data={item}
                    onSave={({ values, metadata, closeModal }) => {
                      const newData = cloneDeep(props.data);
                      newData.items[i] = values;
                      replace(newData, metadata!, undefined, formik);
                      closeModal();
                    }}
                  >
                    <button type='button'>
                      <FontAwesomeIcon icon={light('edit')} />
                    </button>
                  </ItemDetails>
                  {(props.data.items.length > 1 || props.data.items[0].id !== formik.values.id) && (
                    <button type='button' onClick={() => removeStoredItem(item, props.data, formik)}>
                      <FontAwesomeIcon icon={light('trash-alt')} />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
