import { duotone, light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren } from 'react';
import { ModellingChangeAction, ModellingPayload, NodeType, ProductModelV3, StorageNode, StoredItem } from '../../../../../api';
import { useLists } from '../../../../../hooks/useLists';
import { Badge } from '../Badge';
import { ImpactDeltaBadge } from '../ImpactDeltaBadge';
import { getStorageItemsWithDeleted, getStoredItemChange, getTransportsFromAll, graphCleanup } from '../dataModel';
import { ItemDetails } from './ItemDetails';

type Props = PropsWithChildren<{
  payload: ModellingPayload;
  data: StorageNode;
  readOnlyMode: boolean;
  replace: (node: StorageNode) => void;
  onBack: () => void;
}>;

export const StorageItems = (props: Props) => {
  const formik = useFormikContext<ProductModelV3>();
  const lists = useLists();
  const { payload, readOnlyMode } = props;
  const change = payload.changes.find(({ id }) => id === props.data.id);

  const removeItem = (item: StoredItem) => {
    formik.setValues((values) => {
      const newValues = cloneDeep(values);

      getTransportsFromAll(newValues.nodes)
        .filter(({ items }) => items.some(({ id }) => id === item.id))
        .filter(({ edges }) => edges.includes(props.data.id))
        .forEach((node) => {
          node.items = node.items.filter(({ id }) => id !== item.id);
        });

      graphCleanup(newValues, payload, lists);

      return newValues;
    });
  };

  const isItemAdded = (item: StoredItem) => getStoredItemChange(item, props.data, payload)?.action === ModellingChangeAction.Added;

  const isItemUpdated = (item: StoredItem) => getStoredItemChange(item, props.data, payload)?.action === ModellingChangeAction.Updated;

  const isItemDeleted = (item: StoredItem) => getStoredItemChange(item, props.data, payload)?.action === ModellingChangeAction.Deleted;

  return (
    <div className='text-xs flex-1 mx-12 flex flex-col rounded-lg bg-white shadow-[0_0_6px_rgba(0,0,0,0.1)]'>
      <div className='flex justify-between items-center p-4 pr-6 bg-[#E8EAF5] rounded-t-lg'>
        <div className='flex gap-4'>
          <button
            type='button'
            onClick={props.onBack}
            className={cn(
              'h-8 aspect-square flex justify-center items-center rounded-lg text-lg my-0.5 shadow-[0_0_2px_0_rgba(0,0,0,0.2)] active:scale-90',
              props.data.type === NodeType.Store ? 'bg-blue-50 text-blue-900' : 'bg-fuchsia-50 text-fuchsia-900',
            )}
          >
            <FontAwesomeIcon icon={solid('times')} />
          </button>
          <div className='flex rounded-lg p-1 pr-3 bg-white shadow-[0_0_2px_0_rgba(0,0,0,0.2)]'>
            <div className='flex justify-center items-center gap-2'>
              <div
                className={cn(
                  'flex justify-center items-center rounded-md h-6 aspect-square',
                  props.data.type === NodeType.Store ? 'bg-blue-50 text-blue-900' : 'bg-fuchsia-50 text-fuchsia-900',
                )}
              >
                <FontAwesomeIcon icon={props.data.type === NodeType.Store ? duotone('store') : duotone('warehouse-full')} />
              </div>
              <div className='font-semibold'>{props.data.facility.name}</div>
            </div>
          </div>
        </div>
        {change && <ImpactDeltaBadge data={change.impactDelta} className='leading-none text-[10px] tracking-wider px-2.5 py-1.5 rounded' />}
      </div>
      <table className='border-collapse table-fixed w-full text-zinc-600'>
        <thead>
          <tr className='h-8 uppercase bg-slate-50 text-[10px]'>
            <th className='font-normal pl-6'>Name</th>
            <th className='font-normal'>Amount</th>
            <th className='font-normal'>Type</th>
            <th className='font-normal'>Storage condition</th>
            <th className='font-normal'>Storage duration</th>
            <th />
            <th className='pr-6' />
          </tr>
        </thead>
        <tbody>
          {getStorageItemsWithDeleted(props.data, payload, readOnlyMode).map((item, i) => (
            <tr key={item.id} className='h-14 border-t border-neutral-300'>
              <td className={cn('font-semibold text-zinc-700 pl-6 truncate', { 'opacity-20': isItemDeleted(item) })}>{item.name}</td>
              <td className={cn({ 'opacity-20': isItemDeleted(item) })}>
                {item.amount.value}
                {item.amount.unit.name}
              </td>
              <td className={cn({ 'opacity-20': isItemDeleted(item) })}>{item.type}</td>
              <td className={cn({ 'opacity-20': isItemDeleted(item) })}>{item.conservation.name}</td>
              <td className={cn({ 'opacity-20': isItemDeleted(item) })}>
                {item.durationWeeks} week{item.durationWeeks === 1 ? '' : 's'}
              </td>
              <td>
                {!readOnlyMode && getStoredItemChange(item, props.data, payload) && (
                  <div className='flex'>
                    <Badge added={isItemAdded(item)} updated={isItemUpdated(item)} deleted={isItemDeleted(item)} />
                  </div>
                )}
              </td>
              <td className='pr-6'>
                {!readOnlyMode && !isItemDeleted(item) && (
                  <div className='flex justify-end gap-5'>
                    <ItemDetails
                      payload={payload}
                      parentNode={props.data}
                      data={item}
                      onSave={({ values, closeModal }) => {
                        const newData = cloneDeep(props.data);
                        newData.items[i] = values;
                        props.replace(newData);
                        closeModal();
                      }}
                    >
                      <button type='button'>
                        <FontAwesomeIcon icon={light('edit')} size='lg' />
                      </button>
                    </ItemDetails>
                    {(props.data.items.length > 1 || props.data.items[0].id !== payload.product.id) && (
                      <button type='button' onClick={() => removeItem(item)}>
                        <FontAwesomeIcon icon={light('trash-alt')} size='lg' />
                      </button>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
