import { forwardRef } from 'react';
import { Grade, Methodology } from '../../../../../../api';
import { simplify } from '../../../../shared';
import { useProfile } from '../../../../../../hooks/useProfile';
import { GradeBadge } from '../../../../../../components/GradeBadge';
import feLogo from '../../../assets/fe-logo.png';
import cn from 'classnames';

interface Props {
  title: string;
  productsStats?: { final: number; total: number; averageGrade: Grade };
}

export const Overview = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const isFoundationEarthWorkspace = useProfile().selectedWorkspace.methodology.type === Methodology.FoundationEarth;

  if (!props.productsStats) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
        <div
          className='loading-skeleton'
          style={{
            height: '130px',
            width: '600px',
            borderRadius: '1rem',
          }}
        />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-4' ref={ref}>
      <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
      <div className='flex flex-1 gap-x-6'>
        <div className='flex divide-x py-4 bg-white border border-slate-200 rounded-2xl shadow-[0_1px_10px_rgba(0,0,0,0.05)]'>
          <div className='flex flex-col gap-y-4 items-center px-6'>
            <div className='text-zinc-800 font-semibold text-base'>Final products</div>
            <div className='flex items-center flex-col gap-y-2'>
              <div className='text-zinc-900 text-[32px]'>{simplify(props.productsStats.final)}</div>
              <div className='text-zinc-500 text-xs'>/ {simplify(props.productsStats.total)} products</div>
            </div>
          </div>
          <div className='flex flex-col gap-y-4 px-6'>
            <div className='text-zinc-800 font-semibold text-base text-center'>Average grade</div>
            {!isFoundationEarthWorkspace ? (
              <div
                className={cn('size-8 mt-2 text-lg flex self-center justify-center items-center text-white font-semibold rounded-full', {
                  'bg-aa': props.productsStats.averageGrade === Grade.AA,
                  'bg-a': props.productsStats.averageGrade === Grade.A,
                  'bg-b': props.productsStats.averageGrade === Grade.B,
                  'bg-c': props.productsStats.averageGrade === Grade.C,
                  'bg-d': props.productsStats.averageGrade === Grade.D,
                  'bg-e': props.productsStats.averageGrade === Grade.E,
                  'bg-f': props.productsStats.averageGrade === Grade.F,
                  'bg-g': props.productsStats.averageGrade === Grade.G,
                })}
              >
                {props.productsStats.averageGrade}
              </div>
            ) : (
              <GradeBadge grade={props.productsStats.averageGrade} />
            )}
          </div>
          <div className='flex flex-col items-center gap-y-4  px-6'>
            <div className='text-zinc-800 font-semibold text-base'>Eco-labelling method</div>

            {!isFoundationEarthWorkspace ? (
              <div className='h-full mt-4 w-48'>
                <svg xmlns='http://www.w3.org/2000/svg' width='100%' viewBox='0 0 740 48' fill='none'>
                  <path
                    d='M715.519 47.7762C728.653 47.7762 739.3 37.1291 739.3 23.9951C739.3 10.8611 728.653 0.213921 715.519 0.213921C702.385 0.213921 691.738 10.8611 691.738 23.9951C691.738 37.1291 702.385 47.7762 715.519 47.7762Z'
                    fill='#4F00FF'
                  />
                  <path
                    d='M642.138 40.6122C651.315 40.6122 658.755 33.1725 658.755 23.9952C658.755 14.8178 651.315 7.37811 642.138 7.37811C632.961 7.37811 625.521 14.8178 625.521 23.9952C625.521 33.1725 632.961 40.6122 642.138 40.6122Z'
                    fill='#4F00FF'
                  />
                  <path
                    d='M21.4366 20.6736L17.2869 19.7432C11.3696 18.2825 9.1645 16.5147 9.1645 13.2304C9.1645 9.26684 12.2814 6.89431 17.5381 6.89431C22.7949 6.89431 26.0607 8.29922 29.6334 12.2441L29.7916 12.4209L35.0856 7.27576L34.9367 7.1083C30.6848 2.39114 25.0372 0 18.1894 0C7.84332 0 1.44213 5.22887 1.44213 13.6304C1.44213 20.5527 5.74991 24.6557 15.3982 26.9166L19.5478 27.847C25.7722 29.4008 27.875 31.1221 27.875 34.6483C27.875 38.7142 24.5906 41.0495 18.8686 41.0495C13.1466 41.0495 9.25754 39.3003 5.44288 35.0298L5.28471 34.853L0 39.9888L0.139555 40.1563C4.22404 45.1712 10.8299 47.9438 18.7477 47.9438C29.0938 47.9438 35.7834 42.594 35.7834 34.3133C35.6811 27.1213 31.4198 23.0554 21.4366 20.6736Z'
                    fill='black'
                  />
                  <path
                    d='M102.047 27.8004C102.047 36.1275 98.2136 40.5656 90.9657 40.6307C83.5783 40.5656 79.6799 36.0345 79.6799 27.5306V0.651181H71.7808V28.2749C71.7808 40.7424 78.7216 47.9344 90.8355 47.9995C102.949 48.0646 109.946 40.6493 109.946 28.0051V0.651181H102.047V27.8004Z'
                    fill='black'
                  />
                  <path
                    d='M166.822 20.6736L162.672 19.7432C156.746 18.2825 154.55 16.5147 154.55 13.2304C154.55 9.26684 157.667 6.89431 162.923 6.89431C168.18 6.89431 171.437 8.29922 175.019 12.2441L175.177 12.4209L180.471 7.27576L180.313 7.1083C176.061 2.39114 170.422 0 163.565 0C153.219 0 146.818 5.22887 146.818 13.6304C146.818 20.5527 151.126 24.6557 160.774 26.9166L164.924 27.847C171.148 29.4008 173.251 31.1221 173.251 34.6483C173.251 38.7142 169.967 41.0495 164.245 41.0495C158.523 41.0495 154.624 39.3003 150.819 35.0298L150.661 34.853L145.376 39.9888L145.506 40.1563C149.591 45.1712 156.197 47.9438 164.114 47.9438C174.47 47.9438 181.159 42.594 181.159 34.3133C181.066 27.1213 176.805 23.0554 166.822 20.6736Z'
                    fill='black'
                  />
                  <path d='M212.067 7.94556H226.935V47.3203H234.769V7.94556H249.637V0.641886H212.067V7.94556Z' fill='black' />
                  <path
                    d='M301.731 0.651181H293.041L275.037 47.0133L274.916 47.3296H283.29L287.709 35.5414H307.071L311.425 47.3296H319.799L301.796 0.809348L301.731 0.651181ZM297.367 9.35048L298.074 11.416L304.457 28.5633H290.259L296.706 11.3229C296.958 10.6251 297.134 10.1041 297.367 9.34117V9.35048Z'
                    fill='black'
                  />
                  <path d='M362.002 0.641886H354.103V47.3203H362.002V0.641886Z' fill='black' />
                  <path
                    d='M432.731 34.4342C432.731 34.8901 432.731 35.3646 432.731 35.7926C432.451 35.2782 432.141 34.781 431.801 34.3039L409.983 0.651181H400.986V47.3296H408.681V13.6024C408.681 13.1279 408.681 12.672 408.681 12.2161C408.969 12.7185 409.257 13.2117 409.611 13.7327L431.373 47.2273L431.447 47.3296H440.444V0.651181H432.75L432.731 34.4342Z'
                    fill='black'
                  />
                  <path
                    d='M487.3 27.0933H508.057V19.9943H487.3V7.74087H511.611V0.641886H479.401V47.3203H511.807V40.2213H487.3V27.0933Z'
                    fill='black'
                  />
                  <path
                    d='M564.551 0.651181H548.241V47.3296H564.551C579.829 47.3296 590.091 37.8953 590.091 23.8555C590.091 9.95524 579.829 0.651181 564.551 0.651181ZM556.178 7.94556H564.551C575.363 7.94556 582.071 14.049 582.071 23.8555C582.071 33.662 575.195 40.0352 564.551 40.0352H556.178V7.94556Z'
                    fill='black'
                  />
                </svg>
              </div>
            ) : (
              <img className='w-28' src={feLogo} alt='fe' />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
