import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onClick: () => void;
  name: string;
  label: string;
}

export const SelectFooterAddButton = (props: Props) => (
  <button type='button' onClick={props.onClick} className='flex items-center justify-center gap-1 group border-t w-full py-3'>
    <FontAwesomeIcon className='text-brand' icon={regular('plus')} />
    <div className='text-zinc-400 group-hover:text-dark'>{props.name || `Create ${props.label}`}</div>
  </button>
);
