import { Link } from 'react-router-dom';
import { simplify, roundToLong } from '../../../../shared';
import { MethodFactors } from '../../../../../../api';

interface Props {
  data: MethodFactors;
}

export const NormalisationFactor = (props: Props) => {
  return (
    <div className='text-base'>
      <div className='mt-3'>
        By following PEF guidelines, the results of each individual impact category are first normalised using a set of factors and later
        weighted using another set of factors. The {props.data.version} factors are used in this analysis and presented below. While the
        first set is used to normalise the impacts to that of an average person over one year, the second is used to attribute relative
        importance to the various impact categories. You can read more about how impacts are calculated on our{' '}
        <Link
          to='/methodology'
          target='_blank'
          className='hover:text-brandDark font-semibold text-gray-500 underline'
          onClick={() => window.scrollTo(0, 0)}
        >
          methodology
        </Link>{' '}
        page.
      </div>
      <div className='flex'>
        <div className='rounded-lg text-black p-3 print:p-0'>
          <table>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th className='p-3 text-center'>Normalisation Factor</th>
                <th className='px-6 text-center'>Weighting Factor</th>
              </tr>
            </thead>
            <tbody>
              {props.data.factors.map((item) => (
                <tr className='h-10' key={item.name}>
                  <td className='pr-2 font-semibold'>{item.name}</td>
                  <td title={roundToLong(item.normalisationFactor)} className='px-6'>
                    <div>{`${simplify(item.normalisationFactor)} ${item.normalisationUnit}`}</div>
                  </td>
                  <td className='px-6 text-center'>{item.weightingPercent}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
