import { Placement } from '@floating-ui/core';
import { flip, offset, shift, useClick, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { Amount, Entity, GenericStepNode } from '../../../../api';
import { useLists } from '../../../../hooks/useLists';

interface Props {
  placement: Placement;
  step: GenericStepNode;
  finalProduct?: {
    type: string;
    name: string;
    amount: Amount;
  };
}

export const StepOutputsPill = (props: Props) => {
  const [open, setOpen] = useState(false);
  const lists = useLists();

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: props.placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 8, crossAxis: 12 }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ]);

  const count = props.step.outputs.length + (props.finalProduct ? 1 : 0);

  return (
    <>
      <button
        type='button'
        className='rounded-full bg-white border px-2 py-0.5 border-neutral-500 text-neutral-500 hover:border-brand hover:text-brand'
        {...getReferenceProps({
          ref: reference,
          onClick: (event) => event.stopPropagation(),
        })}
      >
        {count} output{count > 1 ? 's' : ''}
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
              },
            })}
            className='antialiased shadow-xl bg-white border rounded-xl relative min-w-[200px] z-20 p-2'
          >
            <button
              type='button'
              onClick={(event) => {
                event.stopPropagation();
                setOpen(false);
              }}
              className='absolute flex justify-center items-center rounded-full -right-2 -top-2.5 h-8 aspect-square bg-[#DACEFD] hover:text-brand active:scale-95'
            >
              <FontAwesomeIcon className='text-lg' icon={regular('times')} />
            </button>
            <div className='divide-y flex flex-col hover:cursor-default'>
              {(() => {
                const renderItem = (key: string, type: string, name: string, value?: number, unit?: Entity) => (
                  <div key={key} className='flex flex-col gap-1 px-1.5 py-3 truncate'>
                    <div className='text-neutral-500 uppercase'>{type}</div>
                    <div className='font-semibold'>
                      {name}
                      {value && unit ? ` - ${value}${unit.name}` : ''}
                    </div>
                  </div>
                );

                return (
                  <>
                    {props.step.outputs.map((output) =>
                      renderItem(
                        output.id,
                        lists.outputTypes.find((item) => item.type === output.outputType.type)!.name,
                        output.name,
                        output.amount?.value,
                        (lists.wasteTypes.find(({ type }) => type === output.waste?.type) ?? output.amount)?.unit,
                      ),
                    )}
                    {props.finalProduct &&
                      renderItem(
                        props.finalProduct.name,
                        props.finalProduct.type,
                        props.finalProduct.name,
                        props.finalProduct.amount.value,
                        props.finalProduct.amount.unit,
                      )}
                  </>
                );
              })()}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
