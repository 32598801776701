import cn from 'classnames';
import { FieldProps } from 'formik';
import { useControlEvents } from '../hooks/useControlEvents';

interface Props {
  model: FieldProps;
}

export const Toggle = (props: Props) => {
  const controlEvents = useControlEvents();
  return (
    <div className='flex justify-start'>
      <div className='flex items-center gap-2 relative'>
        <div className={cn('h-4 w-7 p-0.5 rounded-full transition', props.model.field.value ? 'bg-brand' : 'bg-neutral-300')}>
          <div
            className={cn('rounded-full h-3 aspect-square bg-white transition duration-25', {
              'translate-x-[12px]': props.model.field.value,
            })}
          />
        </div>
        <label className='leading-[0]'>{props.model.field.value ? 'Yes' : 'No'}</label>
        <input
          {...props.model.field}
          type='checkbox'
          checked={props.model.field.value}
          onChange={(event) => {
            if (controlEvents) {
              controlEvents.touched(props.model);
            }

            props.model.field.onChange(event);
          }}
          className='absolute inset-0 opacity-0 cursor-pointer'
        />
      </div>
    </div>
  );
};
