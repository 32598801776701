import cn from 'classnames';
import { ImpactDelta } from '../../../../api';
import { getImpactDeltaBg } from './ImpactDeltaBadge';

export const InteractiveImpactBadge = (props: { data?: ImpactDelta; calculating: boolean }) =>
  props.data || props.calculating ? (
    <div
      className={cn(
        'flex justify-center items-center gap-2 shrink-0 px-2.5 py-1 rounded-md text-white transition-[background]',
        {
          'animate-pulse': props.calculating,
          invisible: !props.data && props.calculating,
        },
        props.data ? getImpactDeltaBg(props.data) : '',
      )}
    >
      {props.data?.display}
    </div>
  ) : (
    <></>
  );
