import { duotone, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { Fragment, useState } from 'react';
import {
  Model,
  NodeReference,
  NodeType,
  PrimaryNode,
  ProductV3,
  StoreNode,
  Tag,
  TransportNode,
  WarehouseNode,
} from '../../../../../../../api';
import { getConsumptionLocationsFromAll, getDisposalsFromAll, getTransportsFromAll } from '../../../../Details/dataModel';

interface Props {
  product: ProductV3;
  model: Model;
}

export const Distribution = (props: Props) => {
  const [expandedWrapper, setExpandedWrapper] = useState(true);
  const [expandedWarehouses, setExpandedWarehouses] = useState(true);
  const [expandedStores, setExpandedStores] = useState(true);
  const [expandedTransportLinks, setExpandedTransportLinks] = useState(true);

  const content = (() => {
    const productDistributionNodes = props.product.nodes.filter(({ type }) =>
      [NodeType.Store, NodeType.Warehouse, NodeType.FinalDestination].includes(type),
    );

    const productDisposalNodes = getDisposalsFromAll(props.product.nodes);
    const productConsumptionNodes = getConsumptionLocationsFromAll(props.product.nodes);

    const productTransportLinks = getTransportsFromAll(props.product.nodes)
      .filter((transport) => productDistributionNodes.find((facility) => facility.edges.find((edge) => edge === transport.id)))
      .map((transportLink) => ({
        ...transportLink,
        from: productDistributionNodes.find((facility) => facility.edges.includes(transportLink.id))!,
        to: [...productDistributionNodes, ...productDisposalNodes, ...productConsumptionNodes].find(({ id }) =>
          transportLink.edges.includes(id),
        )!,
      }));

    const modelDistributionNodes = props.model.nodes.filter(({ type }) =>
      [NodeType.Store, NodeType.Warehouse, NodeType.FinalDestination].includes(type),
    );

    const modelDisposalNodes = getDisposalsFromAll(props.model.nodes);
    const modelConsumptionNodes = getConsumptionLocationsFromAll(props.model.nodes);

    const modelTransportLinks = getTransportsFromAll(props.model.nodes)
      .filter((transport) => modelDistributionNodes.find((facility) => facility.edges.find((edge) => edge === transport.id)))
      .map((transportLink) => {
        return {
          ...transportLink,
          from: modelDistributionNodes.find((facility) => facility.edges.includes(transportLink.id))!,
          to: [...modelDistributionNodes, ...modelDisposalNodes, ...modelConsumptionNodes].find(({ id }) =>
            transportLink.edges.includes(id),
          )!,
        };
      });

    const productWarehouses = props.product.nodes.filter(({ type }) => NodeType.Warehouse === type) as WarehouseNode[];

    const modelWarehouses = props.model.nodes.filter(({ type }) => NodeType.Warehouse === type) as WarehouseNode[];

    const uniqWarehouses = uniqBy([...productWarehouses, ...modelWarehouses], 'id');

    const productStores = props.product.nodes.filter(({ type }) =>
      [NodeType.Store, NodeType.FinalDestination].includes(type),
    ) as StoreNode[];

    const modelStores = props.model.nodes.filter(({ type }) => [NodeType.Store, NodeType.FinalDestination].includes(type)) as StoreNode[];

    const uniqStores = uniqBy([...productStores, ...modelStores], 'id');

    const uniqTransportLinks = uniqBy([...productTransportLinks, ...modelTransportLinks], 'id');

    return (
      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-3'>
          <button onClick={() => setExpandedWarehouses(!expandedWarehouses)} className='flex items-center gap-2'>
            <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedWarehouses })} icon={regular('chevron-right')} />
            <div className='h-7 aspect-square flex items-center justify-center text-fuchsia-900 bg-fuchsia-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('warehouse-full')} />
            </div>
            <div>Warehouses</div>
          </button>
          {expandedWarehouses && (
            <div className='flex flex-col gap-3'>
              {uniqWarehouses.map((warehouse, i) => (
                <Fragment key={i}>
                  <DistributionFacilities
                    type={warehouse.type}
                    product={props.product}
                    model={props.model}
                    productWarehouse={productWarehouses.find(({ id }) => id === warehouse.id)}
                    modelWarehouse={modelWarehouses.find(({ id }) => id === warehouse.id)}
                    allProductionWarehouses={[...productWarehouses, ...modelWarehouses]}
                  />
                </Fragment>
              ))}
            </div>
          )}
        </div>

        <div className='flex flex-col gap-3'>
          <button onClick={() => setExpandedStores(!expandedStores)} className='flex items-center gap-2'>
            <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedStores })} icon={regular('chevron-right')} />
            <div className='h-7 aspect-square flex items-center justify-center text-blue-900 bg-blue-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('store')} />
            </div>
            <div>Stores</div>
          </button>
          {expandedStores && (
            <div className='flex flex-col gap-3'>
              {uniqStores.map((store, i) => (
                <Fragment key={i}>
                  <DistributionFacilities
                    type={store.type}
                    product={props.product}
                    model={props.model}
                    productWarehouse={productStores.find(({ id }) => id === store.id)}
                    modelWarehouse={modelStores.find(({ id }) => id === store.id)}
                    allProductionWarehouses={[...productStores, ...modelStores]}
                  />
                </Fragment>
              ))}
            </div>
          )}
        </div>

        <div className='flex flex-col gap-3'>
          <button onClick={() => setExpandedTransportLinks(!expandedTransportLinks)} className='flex items-center gap-2'>
            <FontAwesomeIcon className={cn('text-xs', { 'rotate-90': expandedTransportLinks })} icon={regular('chevron-right')} />
            <div className='h-7 aspect-square flex items-center justify-center bg-slate-200 rounded-md border'>
              <FontAwesomeIcon icon={duotone('truck')} />
            </div>
            <div>Transport links</div>
          </button>
          {expandedTransportLinks &&
            uniqTransportLinks.map((transportLink, index) => {
              const productTransportLink = productTransportLinks.find(({ id }) => transportLink.id === id);
              const modelTransportLink = modelTransportLinks.find(({ id }) => transportLink.id === id);

              return (
                <Fragment key={index}>
                  <TransportLink
                    index={index}
                    product={props.product}
                    model={props.model}
                    productTransportLink={productTransportLink}
                    modelTransportLink={modelTransportLink}
                  />
                </Fragment>
              );
            })}
        </div>
      </div>
    );
  })();

  return (
    <div className='border print:border-none rounded-regular print:rounded-none shadow-regular print:shadow-none'>
      <button
        disabled
        onClick={() => {
          setExpandedWrapper(!expandedWrapper);
          if (expandedWrapper) {
            setExpandedWarehouses(false);
            setExpandedStores(false);
          }
        }}
        className='grid grid-cols-3 items-center w-full px-8 print:px-0'
      >
        <div className='grid grid-cols-3 col-span-2 items-center'>
          <div className='text-xl font-semibold py-6 group-hover:text-brand'>Distribution</div>
        </div>
        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand invisible'
          icon={expandedWrapper ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>
      {expandedWrapper && <div className='px-8 pb-8 text-dark print:hidden'>{content}</div>}
      <div className='px-8 print:px-0 pb-8 hidden print:block'>{content}</div>
    </div>
  );
};

type FacilitiesPayload = WarehouseNode | StoreNode;

interface WarehouseProps {
  type: NodeType;
  product: ProductV3;
  model: Model;
  productWarehouse?: FacilitiesPayload;
  modelWarehouse?: FacilitiesPayload;
  allProductionWarehouses: FacilitiesPayload[];
}

const DistributionFacilities = (props: WarehouseProps) => {
  const [expanded, setExpanded] = useState(true);

  const productWarehouseStoredItems = props.productWarehouse?.items;
  const modelWarehouseStoredItems = props.modelWarehouse?.items;

  const allStoredItems = [
    ...(productWarehouseStoredItems ? productWarehouseStoredItems : []),
    ...(modelWarehouseStoredItems ? modelWarehouseStoredItems : []),
  ];

  const uniqueStoredItems = uniqBy(allStoredItems, (item) => item.id);

  return (
    <div
      className={cn('flex flex-col gap-6 rounded-xl shadow bg-neutral-50 p-3 border', {
        'border-emerald-700': !props.productWarehouse && props.modelWarehouse,
        'border-red-500': props.productWarehouse && !props.modelWarehouse,
      })}
    >
      <button
        disabled={props.type === NodeType.FinalDestination}
        onClick={() => setExpanded(!expanded)}
        className='flex w-full items-center justify-between gap-2'
      >
        <div className='flex items-center gap-2 pl-1 font-semibold'>
          {props.type === NodeType.Warehouse && (
            <div className='h-7 aspect-square flex items-center justify-center text-fuchsia-900 bg-fuchsia-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('warehouse-full')} />
            </div>
          )}
          {props.type === NodeType.Store && (
            <div className='h-7 aspect-square flex items-center justify-center bg-blue-50 text-blue-900 rounded-md border'>
              <FontAwesomeIcon icon={duotone('store')} />
            </div>
          )}
          {props.type === NodeType.FinalDestination && (
            <div className='h-7 aspect-square flex items-center justify-center text-blue-900 bg-blue-50 rounded-md border'>
              <FontAwesomeIcon icon={duotone('flag-checkered')} />
            </div>
          )}
          <div>{props.productWarehouse?.displayName ?? props.modelWarehouse?.displayName}</div>
        </div>
        <FontAwesomeIcon
          className={cn('flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand', {
            invisible: props.type === NodeType.FinalDestination,
          })}
          icon={expanded ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>

      {expanded && (
        <div className='flex flex-col gap-2'>
          {uniqueStoredItems.map((item, i) => {
            const productStoredItem = props.productWarehouse?.items.find(({ id }) => item.id === id);
            const modelStoredItem = props.modelWarehouse?.items.find(({ id }) => item.id === id);

            return (
              <div key={i} className='flex flex-col gap-y-2 p-3 bg-white rounded-xl shadow'>
                <div className='grid grid-cols-3 text-sm'>
                  <div className='grid grid-cols-3 col-span-2 gap-y-2'>
                    <div className='col-span-3 grid grid-cols-3'>
                      <div className='flex items-center gap-2 col-span-2 font-semibold'>Final product</div>
                      {!productStoredItem && modelStoredItem && <div className='text-emerald-700'>Added</div>}
                      {productStoredItem && !modelStoredItem && <div className='text-red-500'>Removed</div>}
                    </div>

                    <div>Condition</div>
                    <div>{productStoredItem?.conservation.name}</div>
                    <div className='flex'>
                      {(() => {
                        if (
                          (modelStoredItem && !productStoredItem) ||
                          (modelStoredItem && productStoredItem && modelStoredItem.conservation.id !== productStoredItem.conservation.id)
                        ) {
                          return (
                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                              {modelStoredItem.conservation.name}
                            </div>
                          );
                        }

                        return '';
                      })()}
                    </div>

                    <div>Duration</div>
                    <div>
                      {(() => {
                        if (
                          props.product.metadata.system.some(
                            ({ path, tags }) =>
                              path.endsWith(`${props.productWarehouse?.id}/items/${productStoredItem?.id}`) && tags.includes(Tag.Default),
                          )
                        ) {
                          return 'Default';
                        }
                        return productStoredItem && `${productStoredItem.durationWeeks} weeks`;
                      })()}
                    </div>
                    <div className='flex'>
                      {(() => {
                        if (
                          (modelStoredItem && !productStoredItem) ||
                          (modelStoredItem && productStoredItem && modelStoredItem.durationWeeks !== productStoredItem.durationWeeks)
                        ) {
                          return (
                            <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                              {`${modelStoredItem?.durationWeeks} weeks`}
                            </div>
                          );
                        }

                        return '';
                      })()}
                    </div>
                  </div>
                  <div />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export type TransportPayload = TransportNode & {
  from: PrimaryNode | undefined;
  to: PrimaryNode | undefined;
  items: NodeReference[];
};

interface RawMaterialsTransportLinksProps {
  index: number;
  product: ProductV3;
  model: Model;
  productTransportLink?: TransportPayload;
  modelTransportLink?: TransportPayload;
}

const TransportLink = (props: RawMaterialsTransportLinksProps) => {
  const [expandedLink, setExpandedLink] = useState(true);
  const [expandedLegs, setExpandedLegs] = useState(true);

  const productTransportedItems =
    props.productTransportLink?.items && props.productTransportLink.items.length > 0
      ? props.productTransportLink.items.map((item) => props.product.nodes.find(({ id }) => item.id === id)?.displayName)
      : ['Final product'];

  const modelTransportedItems =
    props.modelTransportLink?.items && props.modelTransportLink.items.length > 0
      ? props.modelTransportLink.items.map((item) => props.model.nodes.find(({ id }) => item.id === id)?.displayName)
      : ['Final product'];

  return (
    <div
      className={cn('flex flex-col rounded-xl shadow bg-neutral-50 py-3 border', {
        'border-emerald-700': !props.productTransportLink && props.modelTransportLink,
        'border-red-500': props.productTransportLink && !props.modelTransportLink,
      })}
    >
      <button onClick={() => setExpandedLink(!expandedLink)} className='grid grid-cols-3 items-center'>
        <div className='flex flex-col text-sm col-span-2'>
          <div className='grid grid-cols-3 items-center gap-y-3'>
            <div className='col-span-3 flex items-center gap-x-2 self-center text-base pl-4 font-semibold'>
              <div className='h-7 aspect-square flex items-center justify-center bg-slate-200 rounded-md border'>
                <FontAwesomeIcon icon={duotone('truck')} />
              </div>
              Link {props.index + 1}
            </div>
          </div>
        </div>

        <FontAwesomeIcon
          className='flex justify-self-end text-gray-500 text-xl print:hidden group-hover:text-brand pr-3'
          icon={expandedLink ? regular('circle-minus') : regular('circle-plus')}
        />
      </button>

      {expandedLink && (
        <div className='grid grid-cols-3 text-sm px-6 pb-3'>
          <div className='grid grid-cols-3 col-span-2 gap-y-3'>
            <div className='col-span-2' />
            <div>
              {props.productTransportLink && !props.modelTransportLink && <div className='text-red-500'>Removed</div>}
              {!props.productTransportLink && props.modelTransportLink && <div className='text-emerald-600'>Added</div>}
            </div>

            <div></div>
            <div className='text-violet-900 text-sm font-semibold'>Original</div>
            <div className='text-violet-900 text-sm font-semibold'>Modelled</div>

            <div>From</div>
            <div>{props.productTransportLink?.from?.displayName}</div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productTransportLink && props.modelTransportLink) ||
                  (props.productTransportLink &&
                    props.modelTransportLink &&
                    props.productTransportLink.from?.displayName !== props.modelTransportLink.from?.displayName)
                ) {
                  return (
                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelTransportLink?.from?.displayName}
                    </div>
                  );
                }

                return '';
              })()}
            </div>

            <div>To</div>
            <div>{props.productTransportLink?.to?.displayName}</div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productTransportLink && props.modelTransportLink) ||
                  (props.productTransportLink &&
                    props.modelTransportLink &&
                    props.productTransportLink.to?.displayName !== props.modelTransportLink.to?.displayName)
                ) {
                  return (
                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelTransportLink?.to?.displayName}
                    </div>
                  );
                }

                return '';
              })()}
            </div>

            <div>Transported good</div>
            <div>{props.productTransportLink && productTransportedItems.join(', ')}</div>
            <div className='flex gap-2'>
              {props.modelTransportLink &&
                modelTransportedItems
                  .filter(
                    (modelItem) =>
                      (!props.productTransportLink && props.modelTransportLink) ||
                      !productTransportedItems.find((productItem) => productItem === modelItem),
                  )
                  .map((item, i) => {
                    return (
                      <div key={i} className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                        {item}
                      </div>
                    );
                  })}
            </div>

            <div>Supplier</div>
            <div>
              {(() => {
                if (props.productTransportLink?.supplier === null) {
                  return 'Default';
                } else {
                  return props.productTransportLink?.supplier?.name;
                }
              })()}
            </div>
            <div className='flex'>
              {(() => {
                if (
                  (!props.productTransportLink && props.modelTransportLink) ||
                  (props.productTransportLink?.supplier &&
                    props.modelTransportLink?.supplier &&
                    props.productTransportLink.supplier.name !== props.modelTransportLink.supplier.name)
                ) {
                  return (
                    <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                      {props.modelTransportLink?.supplier ? (
                        props.modelTransportLink?.supplier.name
                      ) : (
                        <span className='text-gray-400'>Default</span>
                      )}
                    </div>
                  );
                }

                return '';
              })()}
            </div>
          </div>

          {(() => {
            const productLegs = props.productTransportLink?.legs ?? [];
            const modelLegs = props.modelTransportLink?.legs ?? [];

            const uniqLegs = uniqBy([...productLegs, ...modelLegs], 'id');

            return (
              uniqLegs.length > 0 && (
                <Fragment>
                  <div className='col-span-3'>
                    <div className='flex items-center justify-between mt-3 font-semibold'>
                      <button
                        disabled={uniqLegs.length === 0}
                        onClick={() => setExpandedLegs(!expandedLegs)}
                        className='[&:not(:disabled)]:hover:text-violet-800'
                      >
                        Primary data
                      </button>
                      <FontAwesomeIcon size='sm' className={cn('', { 'rotate-180': expandedLegs })} icon={solid('chevron-down')} />
                    </div>
                  </div>
                  {(() => {
                    return (
                      expandedLegs &&
                      uniqLegs.map((leg, i) => {
                        const productLeg = productLegs.find(({ id }) => leg.id === id);
                        const modelLeg = modelLegs.find(({ id }) => leg.id === id);

                        return (
                          <div
                            key={i}
                            className={cn('grid grid-cols-3 gap-y-2 col-span-3 bg-white py-3 mt-3 rounded-lg shadow border', {
                              'border-emerald-700': !productLeg && modelLeg,
                              'border-red-500': productLeg && !modelLeg,
                            })}
                          >
                            <div className='grid grid-cols-3 gap-y-2 col-span-2'>
                              <div className='col-span-3 pl-3 font-semibold'>Leg {i + 1}</div>

                              <div className='pl-3'>Distance (km)</div>
                              <div>{productLeg?.distance}</div>
                              <div className='flex'>
                                {(() => {
                                  if (!modelLeg && productLeg) {
                                    return <div className='text-red-500'>Removed</div>;
                                  } else if (
                                    (modelLeg && !productLeg) ||
                                    (modelLeg && productLeg && modelLeg.distance !== productLeg.distance)
                                  ) {
                                    return (
                                      <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                        {modelLeg.distance}
                                      </div>
                                    );
                                  }

                                  return '';
                                })()}
                              </div>

                              <div className='pl-3'>Mode of transport</div>
                              <div>{productLeg?.mode.name}</div>
                              <div className='flex'>
                                {(() => {
                                  if (
                                    (modelLeg && !productLeg) ||
                                    (modelLeg && productLeg && modelLeg.mode.name !== productLeg.mode.name)
                                  ) {
                                    return (
                                      <div className='py-0.5 px-2 rounded-full bg-violet-900 text-white font-semibold'>
                                        {modelLeg.mode.name}
                                      </div>
                                    );
                                  }

                                  return '';
                                })()}
                              </div>
                              <div />
                            </div>
                            <div />
                          </div>
                        );
                      })
                    );
                  })()}
                </Fragment>
              )
            );
          })()}
        </div>
      )}
    </div>
  );
};
