import { FieldProps } from 'formik';
import { Address, resolveAddress } from '../api';
import { SelectV3 } from './SelectV3';

interface Props {
  model: FieldProps<any>;
  countryId: string;
  disabled?: boolean;
  menuPortalTarget?: HTMLElement | null;
  hideInputBorder?: boolean;
}

export const AddressResolver = (props: Props) => (
  <SelectV3<Address>
    hideInputBorder={props.hideInputBorder}
    disabled={props.disabled}
    model={props.model}
    placeholder='Start typing…'
    getOptionLabel={({ text }) => text}
    getOptionValue={({ text }) => text}
    menuPortalTarget={props.menuPortalTarget}
    loadOptions={(input, callback) => {
      if (input.length >= 1) {
        resolveAddress({ address: input, countryId: props.countryId }).ok(({ locations }) => {
          callback(locations.map(({ address, coordinates }) => ({ text: address, coordinates: coordinates })));
        });
      } else {
        callback([]);
      }
    }}
  />
);
