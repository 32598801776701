import { forwardRef } from 'react';
import { GhgGraph } from '../../../../../components/charts/GhgGraph';
import { BaselineGhgReport, HistoricalGhgReport } from '../../../../../api';
import { TimeframeDisplay } from '../../components/TimeframeDisplay';

interface Props {
  title: string;
  data?: HistoricalGhgReport | BaselineGhgReport;
  timeframe?: {
    start: Date;
    end: Date;
  };
}

export const Ghg = forwardRef<HTMLDivElement, Props>((props, ref) => {
  if (!props.data) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500 px-2'>{props.title}</div>
        <div
          className='loading-skeleton'
          style={{
            height: '968px',
            width: '674px',
            borderRadius: '1rem',
          }}
        />
      </div>
    );
  }

  return (
    <div ref={ref} className='flex flex-col gap-4'>
      <div className='flex justify-between px-2'>
        <div className='uppercase text-xs tracking-uppercase text-zinc-500'>{props.title}</div>
        {props.timeframe && <TimeframeDisplay start={props.timeframe.start} end={props.timeframe.end} />}
      </div>
      <div className='p-6 bg-white rounded-2xl border'>
        <GhgGraph analysis={props.data.analysis} />
      </div>
    </div>
  );
});
