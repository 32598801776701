import { ProductReport } from '../../../../../../api';
import { ReactNode } from 'react';

interface Props {
  data: ProductReport;
  description: ReactNode;
  normalisationFactors: ReactNode;
}

export const Appendix = (props: Props) => {
  return (
    <div className='flex flex-col gap-6 mx-6'>
      {props.description}
      {props.normalisationFactors}
    </div>
  );
};
