import { Details as SustainedDetails } from '../../components/Details';
import { Lens, ProductReport } from '../../../../../../../api';

interface Props {
  data: ProductReport;
}

export const Details = (props: Props) => {
  return <SustainedDetails lens={Lens.Consumer} data={props.data} />;
};
