import { flip, offset, shift, useClick, useFloating, useInteractions, useRole } from '@floating-ui/react-dom-interactions';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { ProductV3 } from '../../../../api';
import { getFacilitiesWithSteps, getValidationMessages } from './dataModel';

interface Props {
  selectedNodeId?: string;
  ignoreLessImportant: boolean;
}

export const ValidationErrors = (props: Props) => {
  const formik = useFormikContext<ProductV3>();
  const list = getValidationMessages(formik, props).filter(
    ({ nodeId }) =>
      !props.selectedNodeId ||
      nodeId === props.selectedNodeId ||
      getFacilitiesWithSteps(formik)
        .filter(({ id }) => id === props.selectedNodeId)
        .flatMap(({ steps }) => steps)
        .some(({ id }) => id === nodeId),
  );

  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-end',
    open,
    onOpenChange: setOpen,
    middleware: [offset({ mainAxis: 8, crossAxis: 12 }), flip(), shift()],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useRole(context, { role: 'menu' })]);

  if (list.length === 0) {
    return <></>;
  }

  return (
    <>
      <button
        type='button'
        className='bg-red-50 text-red-500 rounded-lg flex items-center px-2.5 py-1.5 font-semibold active:scale-95'
        {...getReferenceProps({
          ref: reference,
        })}
      >
        {list.length} error{list.length === 1 ? '' : 's'}
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            transition={{ type: 'spring', bounce: 0.5, duration: 0.5 }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? '',
                top: y ?? '',
              },
            })}
            className={cn(
              'z-20 top-10 right-0 absolute flex flex-col gap-3 w-64 max-h-96 p-3 overflow-y-scroll',
              'shadow-lg bg-white border border-neutral-100 rounded-xl',
            )}
          >
            {list.map((item, i) => (
              <div key={`${item.text}.${item.nodeId}.${i}`} className='flex gap-2 items-start'>
                <div className='shrink-0 flex justify-center items-center bg-red-50 w-6 aspect-square rounded-full'>
                  <FontAwesomeIcon icon={regular('exclamation-triangle')} size='sm' className='text-red-500' />
                </div>
                <div>{item.text}</div>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
