import { light, regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Field, FieldArray, FieldProps, FormikContextType, useFormikContext } from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { PropsWithChildren, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import {
  Amount,
  Entity,
  GenericNode,
  GenericNodeWithEdges,
  ImpactDelta,
  LifeCycleStage,
  ModellingBadgeType,
  ModellingPayload,
  NodeType,
  OutputType,
  ProductModelV3,
  ProductType,
  RawMaterialSupplierNode,
  SupplierService,
  SupplierWithSelectedAgreement,
  TransportLeg,
  TransportNode,
  facilityNodeTypes,
  getNodeTypesForLifecycleStage,
  getSuppliers,
} from '../../../../api';
import { ModalForm, ModalFormSaveCallback } from '../../../../components/ModalForm';
import { SelectFooterAddButton } from '../../../../components/SelectFooterAddButton';
import { SelectV3 } from '../../../../components/SelectV3';
import { UnitInputV3 } from '../../../../components/UnitInputV3';
import { NewSupplierForm } from '../../Manage/Suppliers/NewSupplierForm';
import { useLists } from '../../../../hooks/useLists';
import { renderFromToOption } from '../Details/TransportDetails';
import { CardBadge } from './Badge';
import { InteractiveImpactBadge } from './InteractiveImpactBadge';
import { OriginalAwareField, joinWithDiff } from './OriginalAwareField';
import {
  getConsumptionLocations,
  getFacilityNodes,
  getIngredients,
  getOutputs,
  getPackagings,
  getPrimaryAndMaterialSuppliers,
  getProductionFacilities,
  getProductionWarehouses,
  getRawMaterialSuppliers,
  getRawMaterials,
  getStores,
  getTransports,
  getTransportsFromAll,
  newNodeId,
} from './dataModel';
import { useInteractiveImpact } from './useInteractiveImpact';
import { OriginalAwareProvider } from './useOriginalAware';

interface Good extends Entity {
  amount: Amount;
}

interface FormData {
  id: string;
  type: NodeType.Transport;
  from: Entity;
  to: Entity;
  conservation: Entity;
  supplier: SupplierWithSelectedAgreement;
  goods: Good[];
  legs: TransportLeg[];
}

type Props = PropsWithChildren<{
  fromId?: string;
  payload: ModellingPayload;
  data?: TransportNode;
  readOnlyMode: boolean;
  onSave: ModalFormSaveCallback<TransportNode, { fromId: string }>;
  onOpenChange?: (open: boolean) => void;
}>;

const getFromToNodeOptions = (formik: FormikContextType<ProductModelV3>) =>
  getFacilityNodes(formik).map((node) => ({ ...node, name: node.displayName }));

const isIntermediateProductionOption = (entity: Entity | undefined, formik: FormikContextType<ProductModelV3>) =>
  getProductionFacilities(formik).some(({ id, finalFacility }) => !finalFacility && id === entity?.id);

const isFinalProductionOption = (entity: Entity | undefined, formik: FormikContextType<ProductModelV3>) =>
  getProductionFacilities(formik).some(({ id, finalFacility }) => finalFacility && id === entity?.id);

const isProductionWarehouseOption = (entity: Entity | undefined, formik: FormikContextType<ProductModelV3>) =>
  getProductionWarehouses(formik).some(({ id }) => id === entity?.id);

const canAddLegs = (from: Entity | undefined, formik: FormikContextType<ProductModelV3>) =>
  !getStores(formik).some(({ id }) => id === from?.id);

const isSupplierOption = (entity: Entity | undefined) => !!(entity as any as RawMaterialSupplierNode)?.supplier;

const toSupplierOptionId = (node: RawMaterialSupplierNode) => `${node.supplier.id}.${node.location.id}`;

const toSupplierOption = (node: RawMaterialSupplierNode) => ({
  ...node,
  id: toSupplierOptionId(node),
  name: node.displayName,
  supplier: node.supplier,
});

const isSupplierRequired = (from: Entity | undefined, to: Entity | undefined, formik: FormikContextType<ProductModelV3>) =>
  !(getStores(formik).some(({ id }) => id === from?.id) && getConsumptionLocations(formik).some(({ id }) => id === to?.id));

const hasTransportedItems = (from: Entity | undefined, formik: FormikContextType<ProductModelV3>) =>
  isSupplierOption(from) || isIntermediateProductionOption(from, formik) || isProductionWarehouseOption(from, formik);

const getRawMaterialGoodsOptions = (formik: FormikContextType<ProductModelV3>) =>
  getRawMaterials(formik).map((node) => ({ ...node, name: node.displayName }));

const getFromNode = (transport: TransportNode, formik: FormikContextType<ProductModelV3>) =>
  getPrimaryAndMaterialSuppliers(formik).find(({ edges }) => edges.includes(transport.id));

const getToNode = (transport: TransportNode, formik: FormikContextType<ProductModelV3>) =>
  formik.values.nodes.find(({ id }) => id === transport.edges[0]);

const getFinalProduct = (payload: ModellingPayload) => ({
  ...payload.product,
  amount: payload.product.bruttoAmount,
  name: 'Final product',
});

const findFrom = (condition: (node: GenericNodeWithEdges) => boolean, formik: FormikContextType<ProductModelV3>) => {
  const supplierNode = getRawMaterialSuppliers(formik).find(condition);

  if (supplierNode) {
    return toSupplierOption(supplierNode);
  }

  return getFromToNodeOptions(formik).find(condition);
};

const toFormData = (node: TransportNode | undefined, formik: FormikContextType<ProductModelV3>, payload: ModellingPayload) =>
  node
    ? (() => {
        const from = findFrom(({ edges }) => edges.includes(node.id), formik)!;
        return {
          ...node,
          from,
          to: getFromToNodeOptions(formik).find(({ id }) => id === node.edges[0])!,
          goods: hasTransportedItems(from, formik)
            ? node.items.map((item) => {
                const good = [...getRawMaterialGoodsOptions(formik), ...getOutputs(formik)].find(({ id }) => id === item.id)!;
                return {
                  ...good,
                  amount: {
                    ...good.amount!,
                    value: item.amountValue,
                  },
                };
              })
            : [getFinalProduct(payload)],
        };
      })()
    : undefined;

const toNode = (data: FormData) => ({
  ...data,
  displayName: '',
  flagged: false,
  edges: [data.to.id],
  items: data.goods.map((good) => ({ ...good, amountValue: good.amount.value })),
});

const toFromId = (data: FormData, formik: FormikContextType<ProductModelV3>) => {
  if (isSupplierOption(data.from)) {
    return [
      ...(getIngredients(formik).find(({ id }) => id === data.goods[0].id)?.nodes ?? []),
      ...(getPackagings(formik).find(({ id }) => id === data.goods[0].id)?.nodes ?? []),
    ].find((supplierNode) => toSupplierOptionId(supplierNode) === data.from.id)!.id;
  }

  return data.from.id;
};

export const TransportDetails = (props: Props) => {
  const formRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<ProductModelV3>();
  const [impactDelta, setImpactDelta] = useState<ImpactDelta | undefined>();
  const [calculating, setCalculating] = useState(false);

  return (
    <ModalForm<FormData>
      formRef={formRef}
      title={props.data ? 'Editing transport link' : 'New transport link'}
      body={
        <Body
          payload={props.payload}
          productFormik={formik}
          formRef={formRef}
          fromId={props.fromId}
          edit={!!props.data}
          onImpactDelta={setImpactDelta}
          onCalculating={setCalculating}
        />
      }
      onOpenChange={props.onOpenChange}
      headerRight={props.readOnlyMode ? undefined : <InteractiveImpactBadge data={impactDelta} calculating={calculating} />}
      instructions={
        <div className='flex flex-col gap-4 p-2'>
          <div>Your product and its raw materials get transported across from one place to another throughout this life cycle.</div>
          <div>
            To create a new transport link, simply select the origin of the transport (ie. which supplier in which country, or which
            facility), and where it is going to. For this to work, you need to have added your locations in your main graph before!
          </div>
          <div>
            Specify what is being transported between these two places and who is responsible for this transport (this is key information
            for us to gather primary data in the future).
          </div>
          <div>
            If you have collected more detailed data about this journey, you can use the primary data section of the modal and create an
            unlimited number of legs. Simply specify the distance travelled (in km) and select a mode of transport.
          </div>
          <div>
            Just make sure you don’t forget any leg as there is currently no in-built validation to make sure the data entered is realistic.
            Whatever you add in there will override our secondary distance assumptions based on the ‘From’ and ‘To’ countries selected. If
            you know, you can also specify the names of the suppliers who transport the goods across the various legs of the journey.
          </div>
        </div>
      }
      emptyData={{
        id: newNodeId(),
        type: NodeType.Transport,
        from: findFrom(({ id }) => {
          const fromRawMaterial = getRawMaterials(formik).find(({ id }) => id === props.fromId);

          if (fromRawMaterial) {
            return fromRawMaterial.nodes.some((node) => node.edges.length === 0)
              ? fromRawMaterial.nodes.some((node) => id === node.id && node.edges.length === 0)
              : fromRawMaterial.nodes[0].id === id;
          }

          return id === props.fromId;
        }, formik) as any as Entity,
        to: undefined as any as Entity,
        conservation:
          getIngredients(formik).find(({ id }) => id === props.fromId)?.ingredient?.conservation ??
          (getPackagings(formik).find(({ id }) => id === props.fromId)?.packaging.conservation as Entity),
        supplier: undefined as any as SupplierWithSelectedAgreement,
        goods: new Array<Good>(),
        legs: new Array<TransportLeg>(),
      }}
      data={toFormData(props.data, formik, props.payload)}
      validationSchema={yup.object().shape({
        from: yup.object().required(),
        to: yup.object().required(),
        conservation: yup.object().when('from', {
          is: (from: Entity) => hasTransportedItems(from, formik),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
        supplier: yup.object().when(['from', 'to'], {
          is: (from: Entity, to: Entity) => isSupplierRequired(from, to, formik),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(),
        }),
        goods: yup
          .array()
          .when('from', {
            is: (from: Entity) => hasTransportedItems(from, formik),
            then: (schema) => schema.min(1),
          })
          .of(
            yup.object().shape({
              id: yup.string().required(),
              amount: yup.object().shape({
                value: yup.number().positive().required(),
              }),
            }),
          ),
        legs: yup.array().of(
          yup.object().shape({
            distance: yup.number().min(0).required(),
            mode: yup.object().required(),
            supplier: yup.object().nullable(),
          }),
        ),
      })}
      entityName='transport link'
      onSave={({ values, closeModal }) => {
        props.onSave({ values: toNode(values), fromId: toFromId(values, formik), closeModal });
      }}
      hideSave={props.readOnlyMode}
    >
      {props.children}
    </ModalForm>
  );
};

interface BodyProps {
  payload: ModellingPayload;
  productFormik: FormikContextType<ProductModelV3>;
  formRef: RefObject<HTMLDivElement>;
  fromId?: string;
  edit: boolean;
  onImpactDelta: (value?: ImpactDelta) => void;
  onCalculating: (value: boolean) => void;
}

const Body = (props: BodyProps) => {
  const lists = useLists();
  const formik = useFormikContext<FormData>();
  const { payload, productFormik } = props;
  const firstRender = useRef(true);
  const [legsExpanded, setLegsExpanded] = useState(formik.values.legs.length > 0);
  const internal = payload.product.productType === ProductType.Internal;

  const [newSupplierForm, setNewSupplierForm] = useState(false);
  const [newSupplierName, setNewSupplierName] = useState('');

  useInteractiveImpact<TransportNode>({
    payload,
    productFormik,
    toNode: (data) => toNode(data as any as FormData),
    toFromId: (data) => toFromId(data as any as FormData, productFormik),
    onChange: props.onImpactDelta,
    onCalculating: props.onCalculating,
  });

  const fromToTypes = [
    {
      from: [NodeType.Production],
      to: facilityNodeTypes,
    },
    { from: [NodeType.ProductionWarehouse], to: [NodeType.Production] },
    {
      from: [NodeType.Warehouse],
      to: [...getNodeTypesForLifecycleStage(LifeCycleStage.Distribution), ...getNodeTypesForLifecycleStage(LifeCycleStage.Use)],
    },
    { from: [NodeType.Store], to: [NodeType.Consumption] },
  ];

  const otherTransports = getTransports(productFormik).filter(({ id }) => id !== formik.values.id);
  const fromRawMaterial = getRawMaterials(productFormik).find(({ id }) => id === props.fromId);
  const from = formik.values.from;
  const to = formik.values.to;
  const fromOptions = [
    ...uniqBy(
      getRawMaterialSuppliers(productFormik)
        // 1. suppliers connect only to production
        .filter(() => !to || getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes((to as any as GenericNode).type))
        // 2. only suppliers from selected raw material
        .filter((option) => !fromRawMaterial || fromRawMaterial.nodes.some(({ id }) => id === option.id))
        .map(toSupplierOption),
      ({ id }) => id,
    ),
    ...getFromToNodeOptions(productFormik)
      .filter(({ id }) => !to || id !== to.id)
      // 3. only from supported types
      .filter((option) => fromToTypes.flatMap((item) => item.from).includes(option.type))
      // 4. only to from supported types
      .filter(
        ({ type }) => !to || fromToTypes.some((item) => item.from.includes(type) && item.to.includes((to as any as GenericNode).type)),
      )
      // 5. exclude existing transports
      .filter(
        ({ id }) =>
          !to ||
          !otherTransports.some(
            (transport) =>
              (getFromNode(transport, productFormik)?.id === id && getToNode(transport, productFormik)?.id === to.id) ||
              (getFromNode(transport, productFormik)?.id === to.id && getToNode(transport, productFormik)?.id === id),
          ),
      )
      .filter((option) => {
        if (to) {
          // 6. internal productions and warehouses connect to productions
          if ([NodeType.Production].includes((to as any as GenericNode).type)) {
            return isIntermediateProductionOption(option, productFormik) || isProductionWarehouseOption(option, productFormik);
          }

          // 7. internal productions and final productions in internal products connect to production warehouses
          if ([NodeType.ProductionWarehouse].includes((to as any as GenericNode).type)) {
            return isIntermediateProductionOption(option, productFormik) || (isFinalProductionOption(option, productFormik) && internal);
          }

          // 8. only final productions connect to next stages
          if (!getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes((to as any as GenericNode).type)) {
            return isFinalProductionOption(option, productFormik);
          }
        }

        return true;
      }),
  ];
  const toOptions = getFromToNodeOptions(productFormik)
    .filter(({ id }) => !from || id !== from.id)
    // 1. suppliers connect only to production
    .filter(({ type }) => !from || !isSupplierOption(from) || getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes(type))
    // 2. only to supported types
    .filter((option) => fromToTypes.flatMap((item) => item.to).includes(option.type))
    // 3. only to from supported types
    .filter(
      ({ type }) =>
        !from ||
        isSupplierOption(from) ||
        fromToTypes.some((item) => item.from.includes((from as any as GenericNode).type) && item.to.includes(type)),
    )
    // 4. exclude existing non-supplier transports
    .filter(
      ({ id }) =>
        !from ||
        isSupplierOption(from) ||
        !otherTransports.some(
          (transport) =>
            (getFromNode(transport, productFormik)?.id === from.id && getToNode(transport, productFormik)?.id === id) ||
            (getFromNode(transport, productFormik)?.id === id && getToNode(transport, productFormik)?.id === from.id),
        ),
    )
    .filter((option) => {
      if (!isSupplierOption(from)) {
        // 5. production warehouses connect to production facilities
        if (isProductionWarehouseOption(from, productFormik)) {
          return [NodeType.Production].includes(option.type);
        }

        // 6. internal productions connect to productions
        if (isIntermediateProductionOption(from, productFormik)) {
          return getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes(option.type);
        }

        // 7. final productions in internal products connect to production warehouses
        if (isFinalProductionOption(from, productFormik) && internal) {
          return [NodeType.ProductionWarehouse].includes(option.type);
        }

        // 8. only final productions connect to next stages
        if (isFinalProductionOption(from, productFormik) && !internal) {
          return !getNodeTypesForLifecycleStage(LifeCycleStage.Production).includes(option.type);
        }
      }

      return true;
    });
  const goodsOptions: Entity[] = (() => {
    if (from) {
      if (isSupplierOption(from)) {
        return to
          ? getRawMaterialGoodsOptions(productFormik)
              .filter(({ nodes }) => nodes.some((supplierNode) => toSupplierOptionId(supplierNode) === from.id))
              // exclude existing transports
              .filter(
                ({ nodes }) =>
                  !otherTransports.some((transport) =>
                    nodes.some(
                      (supplierNode) =>
                        toSupplierOptionId(supplierNode) === from.id && supplierNode.id === getFromNode(transport, productFormik)?.id,
                    ),
                  ),
              )
          : [];
      }

      if (isIntermediateProductionOption(from, productFormik)) {
        return getProductionFacilities(productFormik)
          .filter(({ id }) => id === formik.values.from?.id)
          .flatMap(({ steps }) =>
            steps
              .filter(({ finalStep }) => finalStep)
              .flatMap(({ outputs }) => outputs)
              .filter(({ outputType: { type } }) => type === OutputType.IntermediateProduct),
          );
      }

      if (isProductionWarehouseOption(from, productFormik)) {
        return getProductionWarehouses(productFormik)
          .filter(({ id }) => id === formik.values.from?.id)
          .flatMap(({ items }) => items)
          .map((item) => ({ ...item, amountValue: item.amount.value }));
      }

      return [getFinalProduct(payload)];
    }

    return [];
  })();

  useEffect(() => {
    if (!firstRender.current) {
      formik.setValues((oldValues) => {
        const newValues = cloneDeep(oldValues);
        newValues.goods = (isSupplierOption(from) ? (goodsOptions.length > 0 ? [goodsOptions[0]] : []) : goodsOptions) as Good[];
        newValues.legs = [];
        return newValues;
      });
    }

    firstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from?.id, to?.id]);

  const renderAlert = (content: ReactNode) => (
    <div className='col-span-2 self-start flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
      <FontAwesomeIcon className='text-amber-400' size='lg' icon={regular('triangle-exclamation')} />
      {content}
    </div>
  );

  useEffect(() => {
    if (!props.fromId) {
      if (formik.values.goods.length > 0) {
        const ingredientConservation = (formik.values.goods[0] as any)?.ingredient?.conservation;
        const packagingConservation = (formik.values.goods[0] as any)?.packaging?.conservation;

        formik.setFieldValue('conservation', ingredientConservation ?? packagingConservation);
      } else {
        formik.setFieldValue('conservation', undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.goods]);

  return (
    <OriginalAwareProvider nodeId={formik.values.id} payload={payload}>
      <div className='flex flex-col gap-4'>
        <div className='grid grid-cols-2 gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
          {payload.badges.some(({ id, type }) => id === formik.values.id && type === ModellingBadgeType.HighImpact) && (
            <div className='col-span-2 self-start flex items-center gap-2 bg-amber-50 border-amber-400 rounded-lg border p-2'>
              <FontAwesomeIcon className='text-amber-400' size='lg' icon={solid('seal-exclamation')} />
              This transport node is a major contributor because of the distance and assumed mode of transport between the From and To. Try
              reducing the distance by choosing locations closer to one another.
            </div>
          )}
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>From</div>
            <div>
              <Field name='from'>
                {(model: FieldProps<Entity>) => (
                  <SelectV3<Entity>
                    model={model}
                    disabled={props.edit}
                    menuPortalTarget={props.formRef.current}
                    options={fromOptions}
                    renderOptionInMenu={renderFromToOption}
                  />
                )}
              </Field>
            </div>
          </div>
          <div className='flex flex-col gap-1'>
            <div className='pl-1.5'>To</div>
            <div>
              <Field name='to'>
                {(model: FieldProps<Entity>) => (
                  <SelectV3<Entity>
                    model={model}
                    disabled={props.edit}
                    menuPortalTarget={props.formRef.current}
                    options={toOptions}
                    renderOptionInMenu={renderFromToOption}
                  />
                )}
              </Field>
            </div>
          </div>
          {!internal &&
            isIntermediateProductionOption(from, productFormik) &&
            renderAlert(
              <>Please note that only facilities set to “final” can be linked to facilities in the distribution life cycle stage.</>,
            )}
          {from &&
            to &&
            goodsOptions.length === 0 &&
            isSupplierOption(from) &&
            renderAlert(<>All transport links from {from.name} have been added already.</>)}
          {from &&
            to &&
            (() => {
              const storeNode = getStores(productFormik).find(({ id }) => id === from.id);
              const consumptionNode = getConsumptionLocations(productFormik).find(({ id }) => id === to.id);

              return (
                storeNode &&
                consumptionNode &&
                storeNode.facility.location.id !== consumptionNode.facility.location.id &&
                renderAlert(<>Store and Consumption location are in different countries.</>)
              );
            })()}
        </div>
        {from && to && goodsOptions.length > 0 && (
          <div className='flex flex-col gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
            <FieldArray
              name='goods'
              render={(arrayModel) => (
                <>
                  {formik.values.goods.map((good, i) => {
                    const finalProduct = good.id === getFinalProduct(payload).id;
                    return (
                      <div key={good.id ?? i} className='flex gap-4'>
                        <div className='flex flex-col gap-1'>
                          {i === 0 && <div className='pl-1.5'>Transported good</div>}
                          <div className='w-80'>
                            <Field name={`${arrayModel.name}.${i}.id`}>
                              {(model: FieldProps<string>) => (
                                <SelectV3<Entity>
                                  disabled={finalProduct || (isSupplierOption(from) && goodsOptions.length === 1)}
                                  model={model}
                                  menuPortalTarget={props.formRef.current}
                                  options={goodsOptions.filter((option) => !formik.values.goods.some((good) => good.id === option.id))}
                                  onActions={{
                                    add: (option: Entity) => arrayModel.replace(i, option),
                                    remove: () => arrayModel.remove(i),
                                    clear: () => arrayModel.replace(i, {}),
                                  }}
                                  convertOptions={{
                                    fromModel: () => (good.id ? good : null),
                                    toModel: (value: Entity) => value?.id,
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        {/*<div
                          className={cn('flex flex-col gap-1', {
                            invisible: finalProduct,
                          })}
                        >
                          {i === 0 && <div className='pl-1.5'>Quantity</div>}
                          <div className='w-44'>
                            <Field name={`${arrayModel.name}.${i}.amount.value`}>
                              {(model: FieldProps<number>) => (
                                <UnitInputV3 model={model} unit={{ options: good.amount ? [good.amount.unit] : [] }} />
                              )}
                            </Field>
                          </div>
                        </div>*/}
                        {!isSupplierOption(from) && !finalProduct && (
                          <button
                            type='button'
                            className='self-end flex bg-[#F5F7FA] rounded-lg justify-center items-center aspect-square h-8 p-0.5'
                            onClick={arrayModel.handleRemove(i)}
                          >
                            <FontAwesomeIcon className='text-zinc-400 hover:text-red-500' size='lg' icon={regular('trash-can')} />
                          </button>
                        )}
                      </div>
                    );
                  })}
                  {hasTransportedItems(from, productFormik) && !isSupplierOption(from) && formik.values.goods.length < goodsOptions.length && (
                    <Field name={arrayModel.name}>
                      {(model: FieldProps) => (
                        <button
                          type='button'
                          className={cn(
                            'pl-1.5 self-start flex items-center gap-2 hover:text-brand',
                            model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                          )}
                          onClick={arrayModel.handlePush({})}
                        >
                          <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                          <div className='font-semibold'>Add transported good</div>
                        </button>
                      )}
                    </Field>
                  )}
                </>
              )}
            />
          </div>
        )}
        {hasTransportedItems(from, productFormik) && (
          <div className='grid grid-cols-2 gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Transport conditions</div>
              <div>
                <OriginalAwareField name='conservation'>
                  {(model: FieldProps<Entity>) => (
                    <SelectV3<Entity> model={model} menuPortalTarget={props.formRef.current} options={lists.conservationRequirements} />
                  )}
                </OriginalAwareField>
              </div>
            </div>
          </div>
        )}
        {isSupplierRequired(from, to, props.productFormik) && (
          <div className='grid grid-cols-2 gap-4 p-4 rounded-xl bg-[#F5F7FA]'>
            <div className='flex flex-col gap-1'>
              <div className='pl-1.5'>Transport services supplier</div>
              <OriginalAwareField name='supplier'>
                {(model: FieldProps<SupplierWithSelectedAgreement>) => (
                  <SelectV3<SupplierWithSelectedAgreement>
                    model={model}
                    menuPortalTarget={props.formRef.current}
                    loadOptions={(input, callback) => {
                      setNewSupplierName(input);
                      getSuppliers({
                        contains: input,
                        service: SupplierService.Transport,
                      }).ok(({ suppliers }) => callback(suppliers));
                    }}
                    menuFooter={
                      !newSupplierForm && (
                        <SelectFooterAddButton onClick={() => setNewSupplierForm(true)} name={newSupplierName} label='new provider' />
                      )
                    }
                  />
                )}
              </OriginalAwareField>
            </div>
            {newSupplierForm && (
              <NewSupplierForm
                name={newSupplierName}
                formRef={props.formRef}
                requiredServices={[SupplierService.Transport]}
                onCancel={() => setNewSupplierForm(false)}
                onCreated={(newSupplier) => {
                  formik.setFieldValue('supplier', newSupplier);
                  setNewSupplierForm(false);
                }}
              />
            )}
          </div>
        )}
        {canAddLegs(from, productFormik) && (
          <FieldArray
            name='legs'
            render={(arrayModel) => (
              <div className='flex flex-col gap-4 p-4 bg-neutral-50 shadow-[inset_0_0_6px_rgba(0,0,0,0.05)] rounded-lg'>
                <button
                  type='button'
                  className='px-1.5 flex justify-between items-center font-semibold text-base text-neutral-900'
                  onClick={() => setLegsExpanded(!legsExpanded)}
                >
                  <div className='font-semibold'>Add primary data</div>
                  <FontAwesomeIcon icon={legsExpanded ? regular('chevron-up') : regular('chevron-down')} size='lg' />
                </button>
                {legsExpanded && (
                  <>
                    {joinWithDiff(
                      formik.values.legs,
                      getTransportsFromAll(payload.product.nodes).find(({ id }) => id === formik.values.id)?.legs,
                    ).map((item, i) => (
                      <div key={item.node.id} className='flex flex-col gap-4 p-4 bg-white shadow-[0_0_2px_rgba(0,0,0,0.2)] rounded-lg'>
                        <div className='flex justify-between font-semibold text-base text-neutral-900'>
                          <div className='flex items-center gap-4 pl-1.5'>
                            <div className={cn({ 'opacity-50': item.deleted })}>Leg {i + 1}</div>
                            <CardBadge item={item} />
                          </div>
                          {!item.deleted && (
                            <button
                              type='button'
                              className='flex justify-center items-center rounded-sm w-7 aspect-square'
                              onClick={arrayModel.handleRemove(item.index.current)}
                            >
                              <FontAwesomeIcon icon={regular('times')} size='lg' />
                            </button>
                          )}
                        </div>
                        {!item.deleted && (
                          <div className='grid grid-cols-3 gap-4'>
                            <div className='flex flex-col gap-1'>
                              <div className='pl-1.5'>Distance</div>
                              <div>
                                <OriginalAwareField
                                  itemName={{
                                    arrayModel,
                                    field: 'distance',
                                    ...item,
                                  }}
                                >
                                  {(model: FieldProps<number>) => (
                                    <UnitInputV3 model={model} unit={{ options: [{ id: '', name: 'km' }] }} />
                                  )}
                                </OriginalAwareField>
                              </div>
                            </div>
                            <div className='flex flex-col gap-1'>
                              <div className='pl-1.5'>Mode of transport</div>
                              <div>
                                <OriginalAwareField
                                  itemName={{
                                    arrayModel,
                                    field: 'mode',
                                    ...item,
                                  }}
                                >
                                  {(model: FieldProps<Entity>) => (
                                    <SelectV3 model={model} menuPortalTarget={props.formRef.current} options={lists.transportTypes} />
                                  )}
                                </OriginalAwareField>
                              </div>
                            </div>
                            <div className='flex flex-col gap-1'>
                              <div className='pl-1.5'>Supplier</div>
                              <div>
                                <OriginalAwareField
                                  itemName={{
                                    arrayModel,
                                    field: 'supplier',
                                    ...item,
                                  }}
                                >
                                  {(model: FieldProps<Entity>) => (
                                    <SelectV3
                                      model={model}
                                      menuPortalTarget={props.formRef.current}
                                      loadOptions={(input, callback) => {
                                        getSuppliers({
                                          contains: input,
                                          service: SupplierService.Transport,
                                        }).ok(({ suppliers }) => callback(suppliers));
                                      }}
                                    />
                                  )}
                                </OriginalAwareField>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <Field name={arrayModel.name}>
                      {(model: FieldProps) => (
                        <button
                          type='button'
                          className={cn(
                            'pl-1.5 self-start flex items-center gap-2 hover:text-brand',
                            model.meta.error ? 'text-[#FA4D0A]' : 'text-brandDarkPurple2',
                          )}
                          onClick={arrayModel.handlePush({
                            id: newNodeId(),
                          })}
                        >
                          <FontAwesomeIcon size='lg' icon={light('circle-plus')} />
                          <div className='font-semibold'>Add transport leg</div>
                        </button>
                      )}
                    </Field>
                  </>
                )}
              </div>
            )}
          />
        )}
      </div>
    </OriginalAwareProvider>
  );
};
